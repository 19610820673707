import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { isRight } from "fp-ts/lib/Either"
import {
  type,
  union,
  Type,
  TypeOf,
  literal,
  intersection,
  null as nullC,
  number as numberC,
  string as stringC,
  partial,
} from "io-ts"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { ExpManager, PageTypes } from "../../../../../modules/shared-modules/experienceManager/types/pageTypes"
import groupBy from "lodash/groupBy"
import flatMap from "lodash/flatMap"
import { nGlobalStyle } from "../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import { GS_TEXT } from "../../../../../modules/shared-modules/globalStyles/globalStylesTypes"
import { get2WithNull } from "../bobUtils"
import { console } from "fp-ts"
import { FontLabel, PageStylesheet } from "../../../../../modules/shared-modules/stylesheet/stylesheetTypes"
import { handleBobLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const FontTypeCodec = union([literal("custom"), literal("google-fonts")])
const FontTypeOpt = partial({ fontType: FontTypeCodec })
const TextCodec = intersection([
  FontTypeOpt,
  type({
    fontFamily: stringC,
    fontSize: numberC,
    fontWeight: numberC,
    letterSpacing: numberC,
    lineHeight: numberC,
  }),
])
const TextOptCodec = partial({
  fontFamily: nullable(stringC),
  fontType: nullable(FontTypeCodec),
  fontSize: nullable(numberC),
  fontWeight: nullable(numberC),
  letterSpacing: nullable(numberC),
  lineHeight: nullable(numberC),
})

const StylesTextCodec = intersection([
  TextCodec,
  partial({
    behaviour: partial({
      active: TextOptCodec,
      hover: TextOptCodec,
    }),
    mobile: intersection([
      TextOptCodec,
      partial({
        behaviour: partial({
          active: TextOptCodec,
          hover: TextOptCodec,
        }),
      }),
    ]),
    tablet: intersection([
      TextOptCodec,
      partial({
        behaviour: partial({
          active: TextOptCodec,
          hover: TextOptCodec,
        }),
      }),
    ]),
  }),
])

const GSTextCodec = intersection([
  TextCodec,
  partial({
    behaviour: partial({
      active: TextOptCodec,
      hover: TextOptCodec,
    }),
    mobile: intersection([
      TextOptCodec,
      partial({
        behaviour: partial({
          active: TextOptCodec,
          hover: TextOptCodec,
        }),
      }),
    ]),
    tablet: intersection([
      TextOptCodec,
      partial({
        behaviour: partial({
          active: TextOptCodec,
          hover: TextOptCodec,
        }),
      }),
    ]),
  }),
])

type Text = TypeOf<typeof TextCodec>
type TextProps = TypeOf<typeof TextCodec>
type TextOpt = TypeOf<typeof TextOptCodec>
type TextPropsOpt = TypeOf<typeof TextOptCodec>
type StylesText = TypeOf<typeof StylesTextCodec>
type GSText = TypeOf<typeof StylesTextCodec>
type FontType = TypeOf<typeof FontTypeCodec>
type TextCSS = {
  "font-family": string
  "font-weight": number
  "font-size": number
  "letter-spacing": number
  "line-height": number
  "font-type": FontType
}
type FontImport = { font: string; weights: Array<number>; type: FontType }

export function responsiveStyle(bobTextObj: any) {
  return {
    "font-family": `${objValueExists(bobTextObj, "fontFamily") ? `"${bobTextObj.fontFamily}" !important` : ""}`,
    "font-size": `${objValueExists(bobTextObj, "fontSize") ? bobTextObj.fontSize + "px !important" : ""}`,
    "font-weight": `${objValueExists(bobTextObj, "fontWeight") ? bobTextObj.fontWeight + " !important" : ""}`,
    "letter-spacing": `${
      objValueExists(bobTextObj, "letterSpacing") ? bobTextObj.letterSpacing + "px !important" : ""
    }`,
    "line-height": `${objValueExists(bobTextObj, "lineHeight") ? bobTextObj.lineHeight + " !important" : ""}`,
    "text-align": `${objValueExists(bobTextObj, "textAlign") ? bobTextObj.textAlign + " !important" : ""}`,
    "text-transform": `${objValueExists(bobTextObj, "textTransform") ? bobTextObj.textTransform + " !important" : ""}`,
    ...handleVerticalAlign(objValueExists(bobTextObj, "verticalAlign") ? bobTextObj.verticalAlign + " !important" : ""),
  }
}

export function handleVerticalAlign(verticalAlign: string) {
  let verticalStyle = {
    display: "flex",
    "justify-content": "center !important",
    "flex-direction": "column !important",
    "align-self": "center !important",
  }

  if (verticalAlign === "middle") {
    return verticalStyle
  }
  if (verticalAlign === "top") {
    verticalStyle["justify-content"] = "flex-start !important"
    verticalStyle["align-self"] = "flex-start !important"
    return verticalStyle
  }
  if (verticalAlign === "bottom") {
    verticalStyle["justify-content"] = "flex-end !important"
    verticalStyle["align-self"] = "flex-end !important"
    return verticalStyle
  }
}

export function renderCSSString(textStyle: TextCSS | {}): string {
  if (!("font-family" in textStyle)) {
    return ""
  } else {
    return `font-family: "${textStyle["font-family"]}" !important;
    font-size: ${textStyle["font-size"]}px !important;
    font-weight: ${textStyle["font-weight"]} !important;
    letter-spacing: ${textStyle["letter-spacing"]}px !important;
    line-height: ${textStyle["line-height"]} !important;`
  }
}

export function getFontImports(
  gsObj: PageTypes["nGlobalStyles"],
  pageLabels: PageStylesheet["labels"] | undefined,
  expManager: ExpManager
): string {
  if (!expManager.enable) return ""
  let importsString = ""
  Object.values(gsObj).forEach((nGs: nGlobalStyle) => {
    if (nGs.type === GS_TEXT) {
      const styles = StylesTextCodec.decode(nGs)
      if (isRight(styles)) {
        const label = handleBobLabel(nGs, pageLabels, "desktop", "default", "fontFamily") as FontLabel
        importsString += renderImportFont(
          getSetFonts([
            cssRender(styles.right, "desktop", "default"),
            cssRender(styles.right, "desktop", "hover"),
            cssRender(styles.right, "desktop", "active"),
            cssRender(styles.right, "mobile", "default"),
            cssRender(styles.right, "mobile", "hover"),
            cssRender(styles.right, "mobile", "active"),
            cssRender(styles.right, "tablet", "default"),
            cssRender(styles.right, "tablet", "hover"),
            cssRender(styles.right, "tablet", "active"),
          ]),
          label
        )
      } else {
        console.warn(decoderErrors(styles))
      }
    }
  })
  return importsString
}

export function dynamicFontLoader(fontObj: FontImport, label: FontLabel | undefined) {
  let nonItalWght = ""
  let weightsString = "wght@"
  fontObj.weights.forEach((element) => {
    nonItalWght += `${nonItalWght === "" ? "" : ","}` + element
  })
  weightsString += nonItalWght
  return `@import url('https://fonts.bunny.net/css?family=${
    label?.fontFamily || fontObj.font
  }:${weightsString}&display=swap');`
}

export function getSetFonts(fonts: Array<"" | TextCSS>): FontImport[] {
  const grouped = groupBy(
    flatMap(fonts, (x: "" | TextCSS) => (x === "" ? [] : [x])),
    (x: TextCSS) => x["font-family"]
  ) // { arial: [{font-family: arial,  weight: 300}] }

  return Object.entries(grouped).map((kv) => {
    return {
      font: kv[0],
      weights: Array.from(new Set(kv[1].map((x: TextCSS) => x["font-weight"]))),
      type: kv[1][0]["font-type"],
    }
  })
}

export function renderImportFont(fonts: Array<FontImport>, label: FontLabel | undefined): string {
  let stringImports = ""
  fonts.forEach((fontObj: FontImport) => {
    if (fontObj.type === "google-fonts") stringImports += `${dynamicFontLoader(fontObj, label)} `
  })
  return stringImports
}

export function cssRenderUnsafe(stylesObj: any, breakpoint: Breakpoint, behaviourState: BehaviourState_): TextCSS | {} {
  const styles = StylesTextCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(`TextStyle ${breakpoint} ${behaviourState} ${decoderErrors(styles)}`)
  return {}
}

export function globalStyleCssRenderUnsafe(
  gsObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): TextCSS | {} {
  const gs = GSTextCodec.decode(gsObj)
  if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState)
  console.warn(`TextGSStyle ${breakpoint} ${behaviourState} ${decoderErrors(gs)}`)
  return {}
}

export function cssRender(stylesObj: StylesText, breakpoint: Breakpoint, behaviourState: BehaviourState_): TextCSS {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj)
    }
    //hover | active
    else {
      return renderBob(merge2(stylesObj?.behaviour?.[behaviourState], stylesObj))
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBob(merge2(stylesObj?.[breakpoint], stylesObj))
    }
    //hover | active
    else {
      return renderBob(
        merge3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState],
          stylesObj?.behaviour?.[behaviourState],
          stylesObj?.[breakpoint],
          stylesObj
        )
      )
    }
  }
}

export function globalStyleCssRender(
  stylesObj: GSText,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): TextCSS {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj)
    }
    //hover | active
    else {
      return render(merge2(stylesObj?.behaviour?.[behaviourState], stylesObj))
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return render(merge2(stylesObj?.[breakpoint], stylesObj))
    }
    //hover | active
    else {
      return render(
        merge3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState],
          stylesObj.behaviour?.[behaviourState],
          stylesObj?.[breakpoint],
          stylesObj
        )
      )
    }
  }
}

export function renderBob(textObj: Text): TextCSS {
  return render(textObj)
}

export function render(textObj: TextProps): TextCSS {
  return {
    "font-family": textObj.fontFamily,
    "font-weight": textObj.fontWeight,
    "font-size": textObj.fontSize,
    "letter-spacing": textObj.letterSpacing,
    "line-height": textObj.lineHeight,
    "font-type": textObj.fontType ?? "google-fonts",
  }
}

export function merge2(textObj: TextPropsOpt | undefined, defaultTextObj: TextProps): TextProps {
  const fontFamily = get2WithNull(textObj?.fontFamily, defaultTextObj.fontFamily)
  const fontType = get2WithNull(textObj?.fontType, defaultTextObj.fontType)
  const fontSize = get2WithNull(textObj?.fontSize, defaultTextObj.fontSize)
  const fontWeight = get2WithNull(textObj?.fontWeight, defaultTextObj.fontWeight)
  const letterSpacing = get2WithNull(textObj?.letterSpacing, defaultTextObj.letterSpacing)
  const lineHeight = get2WithNull(textObj?.lineHeight, defaultTextObj.lineHeight)

  return {
    fontFamily,
    fontType,
    fontSize,
    fontWeight,
    letterSpacing,
    lineHeight,
  }
}

export function merge3(
  textObj: TextPropsOpt | undefined,
  shadowDesktopBehaviour: TextPropsOpt | undefined,
  shadowDefaultBreakpoint: TextPropsOpt | undefined,
  defaultTextObj: TextProps
): TextProps {
  const fontFamily =
    textObj?.fontFamily ??
    shadowDesktopBehaviour?.fontFamily ??
    shadowDefaultBreakpoint?.fontFamily ??
    defaultTextObj.fontFamily
  const fontType =
    textObj?.fontType ??
    shadowDesktopBehaviour?.fontType ??
    shadowDefaultBreakpoint?.fontType ??
    defaultTextObj.fontType
  const fontSize =
    textObj?.fontSize ??
    shadowDesktopBehaviour?.fontSize ??
    shadowDefaultBreakpoint?.fontSize ??
    defaultTextObj.fontSize
  const fontWeight =
    textObj?.fontWeight ??
    shadowDesktopBehaviour?.fontWeight ??
    shadowDefaultBreakpoint?.fontWeight ??
    defaultTextObj.fontWeight
  const letterSpacing =
    textObj?.letterSpacing ??
    shadowDesktopBehaviour?.letterSpacing ??
    shadowDefaultBreakpoint?.letterSpacing ??
    defaultTextObj.letterSpacing
  const lineHeight =
    textObj?.lineHeight ??
    shadowDesktopBehaviour?.lineHeight ??
    shadowDefaultBreakpoint?.lineHeight ??
    defaultTextObj.lineHeight

  return {
    fontFamily,
    fontType,
    fontSize,
    fontWeight,
    letterSpacing,
    lineHeight,
  }
}

export type { StylesText, GSText, TextProps, TextPropsOpt, Text, TextOpt, FontType }

export { StylesTextCodec, GSTextCodec }
import { cloneDeep } from "lodash"
import { Breakpoint } from "../experienceManager/finder/inputs/bobControllerTypes"
import { ObjectContent } from "../experienceManager/types/objectContentTypes"
import { EMEditorType, PageTypes } from "../experienceManager/types/pageTypes"
import { SelectedController } from "../experienceManager/types/reduxTypes"
import { SingleObject } from "../experienceManager/types/singleObjectTypes"
import {
  bobBreakpoint,
  BobFlex,
  BobInput,
  BobLabels,
  BobNmedia,
  nBobText,
} from "../experienceManager/types/stylesTypes"
import { handleLink } from "../mediaLibrary/mediaLibraryUtils"
import { handleObjectsListType } from "../utilities/components"
import { ColorLabel, MediaLabel, PageStylesheet, StylesheetLabels } from "./stylesheetTypes"
import { nGlobalStyle } from "../globalStyles/globalStylesTypes"

export const writeStylesheetCssVariables = (stylesheetLabels: PageStylesheet["labels"] | undefined): string => {
  let cssVariables = ""
  if (stylesheetLabels) {
    for (let key in stylesheetLabels) {
      const label = stylesheetLabels[key]
      if (label.type === "Color") cssVariables += `\n--${writeStylesheetVariable(label)}: ${label.value};`
      if (label.type === "Font") cssVariables += `\n--${writeStylesheetVariable(label)}: ${label.fontFamily};`
    }
  }
  return cssVariables
}

export const writeStylesheetVariable = (stylesheetLabel: StylesheetLabels): string => {
  return `${stylesheetLabel.type}-${stylesheetLabel.name}`
}

export const handleBobStylesheetLabel = (
  stylesheetLabel: StylesheetLabels | undefined,
  bobValue: string | undefined | null
): string => {
  if (stylesheetLabel) return `var(--${writeStylesheetVariable(stylesheetLabel)})`
  return bobValue || ""
}

export const handleMediaStylesheetLabel = (
  stylesheetLabel: MediaLabel | undefined,
  mediaValue: string | undefined | null
): string => {
  if (stylesheetLabel) return stylesheetLabel.value
  return mediaValue || ""
}

export const handleBobBorderLabel = (
  styles: (BobNmedia & BobLabels) | (BobFlex & BobLabels),
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  breakpoint: Breakpoint,
  state: "default" | "hover" | "active"
): {
  "top-color": ColorLabel | undefined
  "bottom-color": ColorLabel | undefined
  "left-color": ColorLabel | undefined
  "right-color": ColorLabel | undefined
} => {
  let stylesheetBorderTopColorLabel
  let stylesheetBorderBottomColorLabel
  let stylesheetBorderRightColorLabel
  let stylesheetBorderLeftColorLabel
  // DESKTOP
  if (breakpoint === "desktop") {
    // hover
    if (state !== "default") {
      stylesheetBorderTopColorLabel = stylesheetLabels?.[styles.behaviour?.[state]?.border?.labels?.topColor || ""]
      stylesheetBorderBottomColorLabel =
        stylesheetLabels?.[styles.behaviour?.[state]?.border?.labels?.bottomColor || ""]
      stylesheetBorderRightColorLabel = stylesheetLabels?.[styles.behaviour?.[state]?.border?.labels?.rightColor || ""]
      stylesheetBorderLeftColorLabel = stylesheetLabels?.[styles.behaviour?.[state]?.border?.labels?.leftColor || ""]
    }
    // default
    else {
      stylesheetBorderTopColorLabel = stylesheetLabels?.[styles.border.labels?.topColor || ""]
      stylesheetBorderBottomColorLabel = stylesheetLabels?.[styles.border.labels?.bottomColor || ""]
      stylesheetBorderRightColorLabel = stylesheetLabels?.[styles.border.labels?.rightColor || ""]
      stylesheetBorderLeftColorLabel = stylesheetLabels?.[styles.border.labels?.leftColor || ""]
    }
  }
  // default
  else {
    // hover
    if (state !== "default") {
      stylesheetBorderTopColorLabel =
        stylesheetLabels?.[styles[breakpoint]?.behaviour?.[state]?.border?.labels?.topColor || ""]
      stylesheetBorderBottomColorLabel =
        stylesheetLabels?.[styles[breakpoint]?.behaviour?.[state]?.border?.labels?.bottomColor || ""]
      stylesheetBorderRightColorLabel =
        stylesheetLabels?.[styles[breakpoint]?.behaviour?.[state]?.border?.labels?.rightColor || ""]
      stylesheetBorderLeftColorLabel =
        stylesheetLabels?.[styles[breakpoint]?.behaviour?.[state]?.border?.labels?.leftColor || ""]
    }
    // MOBILE/TABLET
    else {
      stylesheetBorderTopColorLabel = stylesheetLabels?.[styles[breakpoint]?.border?.labels?.topColor || ""]
      stylesheetBorderBottomColorLabel = stylesheetLabels?.[styles[breakpoint]?.border?.labels?.bottomColor || ""]
      stylesheetBorderRightColorLabel = stylesheetLabels?.[styles[breakpoint]?.border?.labels?.rightColor || ""]
      stylesheetBorderLeftColorLabel = stylesheetLabels?.[styles[breakpoint]?.border?.labels?.leftColor || ""]
    }
  }
  return {
    "top-color": stylesheetBorderTopColorLabel?.type === "Color" ? stylesheetBorderTopColorLabel : undefined,
    "bottom-color": stylesheetBorderBottomColorLabel?.type === "Color" ? stylesheetBorderBottomColorLabel : undefined,
    "left-color": stylesheetBorderLeftColorLabel?.type === "Color" ? stylesheetBorderLeftColorLabel : undefined,
    "right-color": stylesheetBorderRightColorLabel?.type === "Color" ? stylesheetBorderRightColorLabel : undefined,
  }
}

export const handleBobColorsLabel = (
  styles: (BobNmedia & BobLabels) | (BobFlex & BobLabels),
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  breakpoint: Breakpoint,
  state: "default" | "hover" | "active"
): {
  "color-first": ColorLabel | undefined
  "color-second": ColorLabel | undefined
} => {
  let stylesheetColorFirstLabel
  let stylesheetColorSecondLabel
  // DESKTOP
  if (breakpoint === "desktop") {
    // hover
    if (state !== "default") {
      stylesheetColorFirstLabel = stylesheetLabels?.[styles.behaviour?.[state]?.colors?.labels?.colorFirst || ""]
      stylesheetColorSecondLabel = stylesheetLabels?.[styles.behaviour?.[state]?.colors?.labels?.colorSecond || ""]
    } else {
      // default
      stylesheetColorFirstLabel = stylesheetLabels?.[styles.colors.labels?.colorFirst || ""]
      stylesheetColorSecondLabel = stylesheetLabels?.[styles.colors.labels?.colorSecond || ""]
    }
  }
  // MOBILE/TABLET
  else {
    // hover
    if (state !== "default") {
      stylesheetColorFirstLabel =
        stylesheetLabels?.[styles[breakpoint]?.behaviour?.[state]?.colors?.labels?.colorFirst || ""]
      stylesheetColorSecondLabel =
        stylesheetLabels?.[styles[breakpoint]?.behaviour?.[state]?.colors?.labels?.colorSecond || ""]
    } else {
      // default
      stylesheetColorFirstLabel = stylesheetLabels?.[styles[breakpoint]?.colors?.labels?.colorFirst || ""]
      stylesheetColorSecondLabel = stylesheetLabels?.[styles[breakpoint]?.colors?.labels?.colorSecond || ""]
    }
  }
  return {
    "color-first": stylesheetColorFirstLabel?.type === "Color" ? stylesheetColorFirstLabel : undefined,
    "color-second": stylesheetColorSecondLabel?.type === "Color" ? stylesheetColorSecondLabel : undefined,
  }
}

export const handleBobShadowLabel = (
  styles: (BobNmedia & BobLabels) | (BobFlex & BobLabels),
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  breakpoint: Breakpoint,
  state: "default" | "hover" | "active"
): ColorLabel | undefined => {
  // DESKTOP
  if (breakpoint === "desktop") {
    // hover
    if (state !== "default") {
      const label = stylesheetLabels?.[styles.behaviour?.[state]?.shadow?.labels?.color || ""]
      if (label?.type === "Color") return label
      return undefined
    }
    // default
    const label = stylesheetLabels?.[styles?.shadow?.labels?.color || ""]
    if (label?.type === "Color") return label
    return undefined
  }
  // MOBILE/TABLET
  // hover
  if (state !== "default") {
    const label = stylesheetLabels?.[styles?.[breakpoint]?.behaviour?.[state]?.shadow?.labels?.color || ""]
    if (label?.type === "Color") return label
    return undefined
  }
  // default
  const label = stylesheetLabels?.[styles?.[breakpoint]?.shadow?.labels?.color || ""]
  if (label?.type === "Color") return label
  return undefined
}

export const handleBobTextDecorationLabel = (
  styles: (nBobText | BobInput) & BobLabels,
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  breakpoint: Breakpoint,
  state: "default" | "hover" | "active"
): ColorLabel | undefined => {
  // DESKTOP
  if (breakpoint === "desktop") {
    // hover
    if (state !== "default") {
      const label = stylesheetLabels?.[styles.behaviour?.[state]?.textDecorationV2?.labels?.color || ""]
      if (label?.type === "Color") return label
      return undefined
    }
    // default
    const label = stylesheetLabels?.[styles?.textDecorationV2?.labels?.color || ""]
    if (label?.type === "Color") return label
    return undefined
  }
  // MOBILE/TABLET
  // hover
  if (state !== "default") {
    const label = stylesheetLabels?.[styles?.[breakpoint]?.behaviour?.[state]?.textDecorationV2?.labels?.color || ""]
    if (label?.type === "Color") return label
    return undefined
  }
  // default
  const label = stylesheetLabels?.[styles?.[breakpoint]?.textDecorationV2?.labels?.color || ""]
  if (label?.type === "Color") return label
  return undefined
}

export const handleBobTextShadowLabel = (
  styles: nBobText & BobLabels,
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  breakpoint: Breakpoint,
  state: "default" | "hover" | "active"
): ColorLabel | undefined => {
  // DESKTOP
  if (breakpoint === "desktop") {
    // hover
    if (state !== "default") {
      const label = stylesheetLabels?.[styles.behaviour?.[state]?.textShadow?.labels?.color || ""]
      if (label?.type === "Color") return label
      return undefined
    }
    // default
    const label = stylesheetLabels?.[styles?.textShadow?.labels?.color || ""]
    if (label?.type === "Color") return label
    return undefined
  }
  // MOBILE/TABLET
  // hover
  if (state !== "default") {
    const label = stylesheetLabels?.[styles?.[breakpoint]?.behaviour?.[state]?.textShadow?.labels?.color || ""]
    if (label?.type === "Color") return label
    return undefined
  }
  // default
  const label = stylesheetLabels?.[styles?.[breakpoint]?.textShadow?.labels?.color || ""]
  if (label?.type === "Color") return label
  return undefined
}

export const handleBobLabel = (
  styles: ((nBobText | BobInput) & BobLabels) | nGlobalStyle,
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  breakpoint: Breakpoint,
  state: "default" | "hover" | "active",
  type: "fontFamily" | "color"
): StylesheetLabels | undefined => {
  // DESKTOP
  if (breakpoint === "desktop") {
    // hover
    if (state !== "default") {
      return stylesheetLabels?.[styles.behaviour?.[state]?.labels?.[type] || ""]
    }
    // default
    return stylesheetLabels?.[styles.labels?.[type] || ""]
  }
  // MOBILE/TABLET
  // hover
  if (state !== "default") {
    return stylesheetLabels?.[styles[breakpoint]?.behaviour?.[state]?.labels?.[type] || ""]
  }
  // default
  return stylesheetLabels?.[styles[breakpoint]?.labels?.[type] || ""]
}

export const attachStylesheetLabel = (
  hubContent: PageTypes,
  label: StylesheetLabels,
  selectedObject: SingleObject,
  bobKey: string,
  controller: SelectedController["controller"],
  controllerProp: SelectedController["property"],
  breakpoint: Breakpoint,
  state: "default" | "hover" | "active",
  emEditorType: EMEditorType
): { hubContent: PageTypes; objects: any } => {
  let clonedHubContent = cloneDeep(hubContent) as PageTypes
  if (!clonedHubContent.stylesheet) {
    let objects = handleObjectsListType(clonedHubContent, selectedObject, emEditorType)
    console.warn("stylesheet doens't exist")
    return { hubContent: hubContent, objects }
  }

  //trigger publish btn
  clonedHubContent.isOutdated = true

  // attach to bob
  let objects = handleObjectsListType(clonedHubContent, selectedObject, emEditorType)
  let labelController = handleLabelController(
    controller,
    objects[selectedObject.uuid].styles.bobs[bobKey],
    breakpoint,
    state
  )
  labelController.labels = {
    ...labelController.labels,
    ...handleControllerProp(controllerProp, label),
  }

  return { hubContent: clonedHubContent, objects }
}

export const dettachStylesheetLabel = (
  hubContent: PageTypes,
  label: StylesheetLabels,
  selectedObject: SingleObject,
  bobKey: string,
  controller: SelectedController["controller"],
  controllerProp: SelectedController["property"],
  breakpoint: Breakpoint,
  state: "default" | "hover" | "active",
  emEditorType: EMEditorType
): { hubContent: PageTypes; objects: any } => {
  let clonedHubContent = cloneDeep(hubContent) as PageTypes
  //trigger publish btn
  clonedHubContent.isOutdated = true

  // dettach from bob
  let objects = handleObjectsListType(clonedHubContent, selectedObject, emEditorType)
  let labelController = handleLabelController(
    controller,
    objects[selectedObject.uuid].styles.bobs[bobKey],
    breakpoint,
    state
  )
  if (controllerProp === "allColor") {
    delete labelController.labels?.["topColor"]
    delete labelController.labels?.["bottomColor"]
    delete labelController.labels?.["leftColor"]
    delete labelController.labels?.["rightColor"]
  } else delete labelController.labels?.[controllerProp]

  return { hubContent: clonedHubContent, objects }
}

export const attachStylesheetLabelContent = (
  hubContent: PageTypes,
  label: MediaLabel,
  selectedObject: SingleObject,
  controller: "nmedia1.src" | string,
  emEditorType: EMEditorType
): { hubContent: PageTypes; objects: any } => {
  let clonedHubContent = cloneDeep(hubContent) as PageTypes
  if (!clonedHubContent.stylesheet) {
    let objects = handleObjectsListType(clonedHubContent, selectedObject, emEditorType)
    console.warn("stylesheet doens't exist")
    return { hubContent: hubContent, objects }
  }

  //trigger publish btn
  clonedHubContent.isOutdated = true

  // attach to page
  clonedHubContent.stylesheet = {
    ...clonedHubContent.stylesheet,
    labels: {
      ...clonedHubContent.stylesheet.labels,
      [getLabelKey(label.name, label.type)]: label,
    },
  }

  // attach to bob
  let objects = handleObjectsListType(clonedHubContent, selectedObject, emEditorType)
  objects[selectedObject.uuid].content.inlineContent.labels = {
    ...objects[selectedObject.uuid].content.inlineContent.labels,
    [controller]: getLabelKey(label.name, label.type),
  }

  return { hubContent: clonedHubContent, objects }
}

export const dettachStylesheetLabelContent = (
  hubContent: PageTypes,
  selectedObject: SingleObject,
  controller: "nmedia1.src" | string,
  emEditorType: EMEditorType
): { hubContent: PageTypes; objects: any } => {
  let clonedHubContent = cloneDeep(hubContent) as PageTypes
  //trigger publish btn
  clonedHubContent.isOutdated = true

  // dettach from bob
  let objects = handleObjectsListType(clonedHubContent, selectedObject, emEditorType)
  delete objects[selectedObject.uuid].content.inlineContent.labels[controller]

  return { hubContent: clonedHubContent, objects }
}

export const handleControllerProp = (
  controllerProp: SelectedController["property"],
  label: StylesheetLabels
): { [key: string]: string } => {
  if (controllerProp === "allColor") {
    return {
      topColor: getLabelKey(label.name, label.type),
      leftColor: getLabelKey(label.name, label.type),
      rightColor: getLabelKey(label.name, label.type),
      bottomColor: getLabelKey(label.name, label.type),
    }
  } else {
    return { [controllerProp]: getLabelKey(label.name, label.type) }
  }
}

export const handleStyleHelper = (
  a: bobBreakpoint<BobFlex | nBobText | BobNmedia> | undefined
): bobBreakpoint<BobFlex | nBobText | BobNmedia> => {
  if (a !== undefined) return a
  return {} as bobBreakpoint<BobFlex | nBobText | BobNmedia>
}

export const handleStyleControllerHelper = (a: StylesheetLabels | undefined): StylesheetLabels => {
  if (a !== undefined) return a
  return {} as StylesheetLabels
}

export const handleLabelController = (
  controller: SelectedController["controller"],
  styles: { [key: string]: any }, // TODO: style type
  breakpoint: Breakpoint,
  state: "default" | "hover" | "active"
): Partial<bobBreakpoint<BobFlex | nBobText | BobNmedia> | BobFlex | nBobText | BobNmedia> => {
  /**
   * handle controller on root of template
   */
  if (controller === "color" || controller === "") {
    // MOBILE/TABLET
    if (breakpoint !== "desktop") {
      // active/hover
      if (state !== "default") {
        return handleStyleHelper(styles[breakpoint]?.behaviour?.[state])
      }
      // normal
      return handleStyleHelper(styles[breakpoint])
    }
    // DESKTOP
    // active/hover
    if (state !== "default") {
      return styles.behaviour[state]
    }
    // normal
    return styles
  } else {
    /**
     * handle controller on controller prop of template
     */
    // MOBILE/TABLET
    if (breakpoint !== "desktop") {
      // active/hover
      if (state !== "default") {
        return handleStyleControllerHelper(styles[breakpoint]?.behaviour?.[state]?.[controller])
      }
      // normal
      return handleStyleControllerHelper(styles[breakpoint]?.[controller])
    }
    // DESKTOP
    // active/hover
    if (state !== "default") {
      return handleStyleControllerHelper(styles.behaviour[state][controller])
    }
    // normal
    return handleStyleControllerHelper(styles[controller])
  }
}

export const getAttachedStylesheetLabel = (
  labelController: any,
  pageResponse: PageTypes,
  selectedProperty: SelectedController["property"],
  globalStyle: nGlobalStyle | undefined
): StylesheetLabels | undefined => {
  if (labelController || globalStyle) {
    // global style label
    if (globalStyle && globalStyle.labels) {
      return pageResponse.stylesheet?.labels?.[
        globalStyle.labels[selectedProperty === "allColor" ? "topColor" : selectedProperty]
      ]
    }
    // object label
    if (labelController.labels)
      return pageResponse.stylesheet?.labels?.[
        labelController.labels[selectedProperty === "allColor" ? "topColor" : selectedProperty] // TODO: fix allColor condition
      ]
  }
}

export const getAttachedStylesheetLabelContent = (
  inlineContent: ObjectContent["inlineContent"],
  stylesheet: PageTypes["stylesheet"],
  prop: string
): MediaLabel | undefined => {
  return inlineContent && stylesheet?.labels?.[inlineContent?.labels?.[prop]]
}

export const getAttachedBobLabel = (
  labels: PageStylesheet["labels"],
  labelName: StylesheetLabels["name"],
  labelType: StylesheetLabels["type"]
): StylesheetLabels | undefined => {
  return labels?.[getLabelKey(labelName, labelType)]
}

export const getLabelKey = (labelName: StylesheetLabels["name"], labelType: StylesheetLabels["type"]): string => {
  return `${labelName}-${labelType}`
}

export const getLabelValue = (label: StylesheetLabels, valueKey: "value" | "fontFamily"): string => {
  if (valueKey === "fontFamily" && valueKey in label) return label[valueKey]
  if (valueKey === "value" && valueKey in label) return label[valueKey]
  console.warn("missing valueKey")
  return ""
}

export const getLabelsList = (
  labels: PageStylesheet["labels"] | undefined,
  type: StylesheetLabels["type"],
  valueKey: "value" | "fontFamily"
): [] | Array<{ value: string; label: string }> => {
  let mediaLabelsList: Array<{ value: string; label: string }> = []
  if (labels)
    for (let key in labels) {
      let label = labels[key]
      if (label.type === type && valueKey in label) {
        mediaLabelsList = [
          ...mediaLabelsList,
          {
            value: label.name,
            label: getLabelValue(label, valueKey),
          },
        ]
      }
    }

  return mediaLabelsList
}

export const getMediaLabelsList = (
  labels: PageStylesheet["labels"] | undefined,
  media: PageTypes["media"],
  profile: string
): [] | Array<{ value: string; label: string; icon: JSX.Element }> => {
  let mediaLabelsList: Array<{ value: string; label: string; icon: JSX.Element }> = []
  if (labels)
    for (let key in labels) {
      let label = labels[key]
      if (label.type === "Media") {
        const labelMedia = media?.[label?.value.replace(/media-/g, "") || ""]
        mediaLabelsList = [
          ...mediaLabelsList,
          {
            value: label.value,
            label: label.name,
            icon: (
              <img
                key={label.name}
                style={{
                  height: "15px",
                  width: "35px",
                  objectFit: "contain",
                  backgroundColor: "rgba(0,0,0,0.3)",
                  objectPosition: "center center",
                }}
                src={labelMedia ? handleLink(profile, labelMedia) : ""}
                alt={label.name}
              />
            ),
          },
        ]
      }
    }

  return mediaLabelsList
}
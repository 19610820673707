import { combineReducers } from 'redux'
import session from './sessionReducer'
import contentHubReducer from "./contentHubReducer"
import contentFormulaReducer from "./contentFormulaReducer"
import fontAwesomeServicesReducer from "./fontAwesomeServicesReducer"
import profileNetworkReducer from "./profileNetworkReducer"
import personLibraryReducer from "./personLibraryReducer"
import userProfilesReducer from "./userProfilesReducer"
import profileReducer from "./profileReducer"
import profileLibraryReducer from "./profileLibraryReducer"
import mqlReducer from "./mqlReducer"
import metaManagerReducer from "./metaManagerReducer"
import votingReducer from "./votingReducer"
import xFormulaReducer from "./xFormulaReducer"
import breadcrumbReducer from "./breadcrumbReducer"
import boardReducer from "./boardReducer"
import xFilterReducer from "./xFilterReducer"
import filterSelectorReducer from "./filterSelectorReducer"
import filterReducer from "./filterReducer"
import formulaTagsReducer from "./formulaTagsReducer"
import addTagReducer from "./addTagReducer"
import toolBoxReducer from "./toolBoxReducer"
import verticalMenuReducer from "./verticalMenuReducer"
import profileTagsReducer from "./profileTagsReducer"
import routesReducer from "./routesReducer"
import locale from "./localeReducer"
import skynetReducer from "./skynetReducer"
import experienceManagerReducer from "./experienceManagerReducer"
import formulalabReducer from "./formulalabReducer"
import marketplaceReducer from "./marketplaceReducer"
import globalStyleReducer from "./globalStyleReducer"
import foundationStyleReducer from "./foundationStyleReducer"
import stylesheetReducer from "./stylesheetReducer"
import pageTreeReducer from "./pageTreeReducer"
import symbolsReducer from "./symbolsReducer"
import formBuilderReducer from "./formBuilderReducer"
import formBuilderV2Reducer from "./formBuilderV2Reducer"
import settingsReducer from "./settingsReducer"
import publicPageReducer from "./publicPageReducer"
import dataSetBuilderReducer from "./dataSetBuilderReducer"
import academyReducer from "./academyReducer"
import tourReducer from "./tourReducer"
import inlineContentReducer from "./inlineContentReducer"
import cmsContentReducer from "./cmsContentReducer"
import mediaLibraryReducer from "./mediaLibraryReducer"
import libraryReducer from "./libraryReducer"
import contentLibraryReducer from "./contentLibraryReducer"
import ssoReducer from "./ssoReducer"
import externalServicesReducer from "./externalServicesReducer"

const rootReducer = combineReducers({
  // short hand property names
  session,
  locale,
  contentHubReducer,
  contentFormulaReducer,
  fontAwesomeServicesReducer,
  profileNetworkReducer,
  personLibraryReducer,
  userProfilesReducer,
  profileReducer,
  profileLibraryReducer,
  mqlReducer,
  metaManagerReducer,
  votingReducer,
  xFormulaReducer,
  breadcrumbReducer,
  boardReducer,
  xFilterReducer,
  filterSelectorReducer,
  filterReducer,
  formulaTagsReducer,
  addTagReducer,
  toolBoxReducer,
  verticalMenuReducer,
  profileTagsReducer,
  routesReducer,
  skynetReducer,
  experienceManagerReducer,
  formulalabReducer,
  marketplaceReducer,
  globalStyleReducer,
  foundationStyleReducer,
  stylesheetReducer,
  pageTreeReducer,
  symbolsReducer,
  formBuilderReducer,
  formBuilderV2Reducer,
  settingsReducer,
  publicPageReducer,
  dataSetBuilderReducer,
  academyReducer,
  tourReducer,
  inlineContentReducer,
  cmsContentReducer,
  mediaLibraryReducer,
  libraryReducer,
  contentLibraryReducer,
  ssoReducer,
  externalServicesReducer,
})

export default rootReducer

import { isSome, none, Option, some } from "fp-ts/lib/Option"

class ColorHandler {

  /**
   * Receives a color value
   * Returns an array with it's rgb values
   * @param {string} hex 
   */
  static hex2rgb(value: string): Array<number | string | undefined> | false {
    if (value.charAt(0) === '#') {
      value = value.substr(1)
    } else if (value.charAt(0) === 'r') {
      // got an rgb value, just return it's values. check for rgb or rgba
      let rgb = value.charAt(3) === 'a' ? value.replace('rgba(', '').replace(')', '').split(',') : value.replace('rgb(', '').replace(')', '').split(',')
      return [rgb[0], rgb[1], rgb[2]]
    } else {
      // not an hex value
      return false
    }
    if ((value.length < 2) || (value.length > 8)) {
      // invalid hex value characters
      return false
    }
    var values = value.split(''),
      r,
      g,
      b
    if (value.length === 2) {
      r = parseInt(values[0].toString() + values[1].toString(), 16)
      g = r
      b = r
    } else if (value.length === 3) {
      r = parseInt(values[0].toString() + values[0].toString(), 16)
      g = parseInt(values[1].toString() + values[1].toString(), 16)
      b = parseInt(values[2].toString() + values[2].toString(), 16)
    } else if (value.length >= 6) {
      r = parseInt(values[0].toString() + values[1].toString(), 16)
      g = parseInt(values[2].toString() + values[3].toString(), 16)
      b = parseInt(values[4].toString() + values[5].toString(), 16)
    }
    return [r, g, b]
  }

  static hex2decOctet(str: string, singleDigit: boolean = false): Option<number> {
    // https://www.w3schools.com/css/css_colors_hex.asp
    let v = singleDigit 
      ? parseInt(str[0] + str[0], 16)
      : parseInt(str[0] + str[1], 16)

    return isNaN(v) ? none : some(v)
  }

  // TODO: isto podia ser mais simples se fosse específico ao formato usado pelo rc-color-picker
  static hex2rgbStrict(str: string): { r: number, g: number, b: number } | false {
    let value = str.startsWith("#") ? str.substring(1) : str

    if (value.length === 2) {
      let r = this.hex2decOctet(value)

      return isSome(r)
        ? { r: r.value, g: r.value, b: r.value }
        : false
    } else if (value.length === 3) {
      let r = this.hex2decOctet(value.slice(0, 1), true)
      let g = this.hex2decOctet(value.slice(1, 2), true)
      let b = this.hex2decOctet(value.slice(2, 3), true)

      return isSome(r) && isSome(g) && isSome(b)
        ? { r: r.value, g: g.value, b: b.value }
        : false
    } else if (value.length === 6) {
      let r = this.hex2decOctet(value.slice(0, 2))
      let g = this.hex2decOctet(value.slice(2, 4))
      let b = this.hex2decOctet(value.slice(4, 6))

      return isSome(r) && isSome(g) && isSome(b)
        ? { r: r.value, g: g.value, b: b.value }
        : false
    } else {
      return false
    }
  }

  static rbga2hex(rgbaColor: string): { hexColor: string, alphaValue: number } {
    //We remove the text from the color code and leave only the numbers
    let dissectedColorValues = rgbaColor.replace('rgba(', '').replace(')', '')
    let dissectedColor: Array<any> = dissectedColorValues.split(',')
    if (dissectedColor && dissectedColor.length === 4) {
      let hexValue = '#'
      hexValue += ('0' + parseInt(dissectedColor[0], 10).toString(16)).slice(-2)
      hexValue += ('0' + parseInt(dissectedColor[1], 10).toString(16)).slice(-2)
      hexValue += ('0' + parseInt(dissectedColor[2], 10).toString(16)).slice(-2)
      return { hexColor: hexValue, alphaValue: dissectedColor[3] * 100 }
    }
    //When it fails to convert the color we return black
    return { hexColor: '#000000', alphaValue: 100 }
  }

  /**
   * Receives a rgb or rgba string(rgba(r,g,b,a)) or array([r,g,b,a])
   * Returns an array with the darker rgb values
   * @param {string | array} rgb 
   * @param {number} percent 
   */
  static darkenColor(value: any, percent: any): Array<number> {
    value = !value.length ?
      value.charAt(3) === 'a' ?
        value.replace('rgba(', '').replace(')', '').split(',') :
        value.replace('rgb(', '').replace(')', '').split(',')
      : value

    let red = value[0]
    let green = value[1]
    let blue = value[2]

    //If rgba is black set it to gray
    if (red === 0 && green === 0 && blue === 0) {
      red = '100'
      green = '100'
      blue = '100'
    }

    red = parseInt(red) * (100 - percent) / 100
    green = parseInt(green) * (100 - percent) / 100
    blue = parseInt(blue) * (100 - percent) / 100

    return [red, green, blue]
  }

  /**
   * Receives an hex value
   * Returns a lighten hex value
   * SHOULD CHECK FOR COLOR VALUE TYPE, hex, rgb, etc
   * @param {*} hex 
   * @param {*} lum 
   */
  static lightenColor(hex: string, lum: number): string {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '')
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    lum = lum || 0

    // convert to decimal and change luminosity
    var hexValue = "#", c, i
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16)
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
      hexValue += ("00" + c).substr(c.length)
    }

    return hexValue
  }

}

export default ColorHandler
import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function userProfilesReducer(state = initialState.userProfiles, action) {
  switch (action.type) {
    case types.GET_USER_PROFILES:
      return {
        ...state,
        loading: true,
        error: undefined
      }
    case types.RECEIVE_USER_PROFILES:
      return {
        ...state,
        loading: false,
        profiles: action.content,
        error: undefined,
      }
    case types.CLEAR_USER_PROFILES:
      return {
        ...state,
        loading: false,
        profiles: undefined,
        error: undefined,
      }
    case types.ERROR_GET_USER_PROFILES:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case types.REQUEST_CREATE_PROFILE:
      return {
        ...state,
        errorCreateProfile: false,
        loadingCreateProfile: true,
        createdProfile: false
      }
    case types.SUCCESS_CREATE_PROFILE:
      return {
        ...state,
        errorCreateProfile: false,
        loadingCreateProfile: false,
        createdProfile: action.content.data,
      }
    case types.CLEAR_CREATE_PROFILE:
      return {
        ...state,
        errorCreateProfile: false,
        loadingCreateProfile: false,
        createdProfile: undefined
      }
    case types.ERROR_CREATE_PROFILE:
      return {
        ...state,
        errorCreateProfile: action.error.response.data,
        loadingCreateProfile: false,
        createdProfile: false
      }
    case types.REQUEST_UPDATE_PROFILE:
      return {
        ...state,
        errorUpdateProfile: false,
        loadingUpdateProfile: true,
        updatedProfile: false
      }
    case types.SUCCESS_UPDATE_PROFILE:
      return {
        ...state,
        errorUpdateProfile: false,
        loadingUpdateProfile: false,
        updatedProfile: action.content
      }
    case types.CLEAR_UPDATE_PROFILE:
      return {
        ...state,
        errorUpdateProfile: false,
        loadingUpdateProfile: false,
        updatedProfile: undefined
      }
    case types.ERROR_UPDATE_PROFILE:

      let error2 = ''

      let keys2 = []
      keys2 = Object.keys(action.error)

      if (keys2.length > 0) {
        error2 = action.error[keys2[0]]
      } else if (typeof action.error === 'string' || action.error instanceof String) {
        error2 = action.error
      }

      return {
        ...state,
        errorUpdateProfile: error2,
        loadingUpdateProfile: false,
        updatedProfile: false
      }
    case types.REQUEST_CREATE_PROFILE_IMAGE:
      return {
        ...state,
        errorUploadAvatar: false,
        loadingUploadAvatar: true,
        uploadedAvatar: undefined
      }
    case types.SUCCESS_CREATE_PROFILE_IMAGE:
      return {
        ...state,
        errorUploadAvatar: false,
        loadingUploadAvatar: false,
        uploadedAvatar: action.response
      }
    case types.ERROR_CREATE_PROFILE_IMAGE:
      return {
        ...state,
        errorUploadAvatar: action.error,
        loadingUploadAvatar: false,
        uploadedAvatar: undefined
      }
    default:
      return state;
  }
}

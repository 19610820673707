import { getValue } from "../utilities/responsiveHandler"
import { handleGroupKey, handleStylesGroupCss } from "./globalStylesHandler"
import { v4 } from "uuid"
import { ResponsiveBreakpoint, ResponsiveData } from "../experienceManager/finder/inputs/bobControllerTypes"
import { nGlobalStyle } from "./globalStylesTypes"
import * as gsType from "./globalStylesTypes"
import { objValueExists } from "../utilities/utils"
import { EMEditorType, ExpManager, PageTypes } from "../experienceManager/types/pageTypes"
import cloneDeep from "lodash/cloneDeep"
import { SingleObject } from "../experienceManager/types/singleObjectTypes"
import { handleObjectsListType } from "../utilities/components"
import { PageStylesheet } from "../stylesheet/stylesheetTypes"

class GlobalStylesUtils {
  static hasGlobalStyleAttached(selectedObject: any, bobKey: string, type: nGlobalStyle["type"]) {
    const bob = selectedObject.styles.bobs[bobKey]
    return bob.nGlobalStyles?.[type]
  }

  /**
   * handle data structure for controller
   * @returns
   */
  static handleGlobalStyleToEdit(type: string, styles: any, responsiveBreakpoint: any) {
    if (!styles) return {}
    if (type === gsType.GS_SHADOW) {
      return {
        shadowOffsetH: getValue(responsiveBreakpoint, styles, "offsetH"),
        shadowOffsetV: getValue(responsiveBreakpoint, styles, "offsetV"),
        shadowBlur: getValue(responsiveBreakpoint, styles, "blur"),
        shadowSpread: getValue(responsiveBreakpoint, styles, "spread"),
        shadowColor: getValue(responsiveBreakpoint, styles, "color"),
        shadowInset: getValue(responsiveBreakpoint, styles, "inset"),
      }
    }
    if (type === gsType.GS_BOUNDARY) {
      return {
        marginRight: getValue(responsiveBreakpoint, styles.margin, "right"),
        marginBottom: getValue(responsiveBreakpoint, styles.margin, "bottom"),
        marginLeft: getValue(responsiveBreakpoint, styles.margin, "left"),
        marginTop: getValue(responsiveBreakpoint, styles.margin, "top"),
        paddingTop: getValue(responsiveBreakpoint, styles.padding, "top"),
        paddingRight: getValue(responsiveBreakpoint, styles.padding, "right"),
        paddingBottom: getValue(responsiveBreakpoint, styles.padding, "bottom"),
        paddingLeft: getValue(responsiveBreakpoint, styles.padding, "left"),
      }
    }
    if (type === gsType.GS_SCREEN_FLOW) {
      return {
        wrap: getValue(responsiveBreakpoint, styles, "wrap"),
      }
    }
    if (type === gsType.GS_HEIGHT) {
      return {
        height: getValue(responsiveBreakpoint, styles, "height"),
        heightUnit: getValue(responsiveBreakpoint, styles, "heightUnit"),
      }
    }
    if (type === gsType.GS_BORDER_RADIUS) {
      return {
        radiusBottomLeft: getValue(responsiveBreakpoint, styles, "bottomLeft"),
        radiusBottomRight: getValue(responsiveBreakpoint, styles, "bottomRight"),
        radiusTopLeft: getValue(responsiveBreakpoint, styles, "topLeft"),
        radiusTopRight: getValue(responsiveBreakpoint, styles, "topRight"),
      }
    }
    if (type === gsType.GS_BORDER) {
      return {
        borderTopStyle: getValue(responsiveBreakpoint, styles, "topStyle"),
        borderTopWidth: getValue(responsiveBreakpoint, styles, "topWidth"),
        borderTopColor: getValue(responsiveBreakpoint, styles, "topColor"),
        borderTopEnable: getValue(responsiveBreakpoint, styles, "topEnable"),
        borderRightStyle: getValue(responsiveBreakpoint, styles, "rightStyle"),
        borderRightWidth: getValue(responsiveBreakpoint, styles, "rightWidth"),
        borderRightColor: getValue(responsiveBreakpoint, styles, "rightColor"),
        borderRightEnable: getValue(responsiveBreakpoint, styles, "rightEnable"),
        borderBottomStyle: getValue(responsiveBreakpoint, styles, "bottomStyle"),
        borderBottomWidth: getValue(responsiveBreakpoint, styles, "bottomWidth"),
        borderBottomColor: getValue(responsiveBreakpoint, styles, "bottomColor"),
        borderBottomEnable: getValue(responsiveBreakpoint, styles, "bottomEnable"),
        borderLeftStyle: getValue(responsiveBreakpoint, styles, "leftStyle"),
        borderLeftWidth: getValue(responsiveBreakpoint, styles, "leftWidth"),
        borderLeftColor: getValue(responsiveBreakpoint, styles, "leftColor"),
        borderLeftEnable: getValue(responsiveBreakpoint, styles, "leftEnable"),
      }
    }
    if (type === gsType.GS_BG_COLORS) {
      return {
        isGradient: getValue(responsiveBreakpoint, styles, "colorsIsGradient"),
        colorFirst: getValue(responsiveBreakpoint, styles, "colorsColorFirst"),
        colorSecond: getValue(responsiveBreakpoint, styles, "colorsColorSecond"),
        gradientAngle: getValue(responsiveBreakpoint, styles, "colorsGradientAngle"),
      }
    }
    if (type === gsType.GS_COLUMNS) {
      return {
        columns: getValue(responsiveBreakpoint, styles, "columns"),
        gutters: getValue(responsiveBreakpoint, styles, "gutters"),
        newBehaviour: getValue(responsiveBreakpoint, styles, "newBehaviour"),
      }
    }
    if (type === gsType.GS_ALIGNMENT) {
      return {
        horizontal: getValue(responsiveBreakpoint, styles, "horizontal"),
        vertical: getValue(responsiveBreakpoint, styles, "vertical"),
      }
    }
    if (type === gsType.GS_FILTER) {
      return {
        saturate: getValue(responsiveBreakpoint, styles, "saturate"),
        brightness: getValue(responsiveBreakpoint, styles, "brightness"),
        contrast: getValue(responsiveBreakpoint, styles, "contrast"),
        blur: getValue(responsiveBreakpoint, styles, "blur"),
        sepia: getValue(responsiveBreakpoint, styles, "sepia"),
        invert: getValue(responsiveBreakpoint, styles, "invert"),
        hueRotate: getValue(responsiveBreakpoint, styles, "hueRotate"),
        grayscale: getValue(responsiveBreakpoint, styles, "grayscale"),
        opacity: getValue(responsiveBreakpoint, styles, "opacity"),
        shadow: getValue(responsiveBreakpoint, styles, "shadow"),
        overlay: getValue(responsiveBreakpoint, styles, "overlay"),
      }
    }
    if (type === gsType.GS_TEXT) {
      return {
        lineHeight: getValue(responsiveBreakpoint, styles, "lineHeight"),
        size: getValue(responsiveBreakpoint, styles, "size"),
        letterSpacing: getValue(responsiveBreakpoint, styles, "letterSpacing"),
        weight: getValue(responsiveBreakpoint, styles, "weight"),
        fontType: getValue(responsiveBreakpoint, styles, "fontType"),
        family: getValue(responsiveBreakpoint, styles, "family"),
      }
    }
    if (type === gsType.GS_TEXT_COLOR) {
      return {
        color: getValue(responsiveBreakpoint, styles, "color"),
      }
    }
    if (type === gsType.GS_ICON) {
      return {
        size: getValue(responsiveBreakpoint, styles, "size"),
        selectedIcon: getValue(responsiveBreakpoint, styles, "selectedIcon"),
        icon: getValue(responsiveBreakpoint, styles, "icon"),
        iconType: getValue(responsiveBreakpoint, styles, "iconType"),
      }
    }
    return undefined
  }

  /**
   * handle data structure for controller
   * @returns
   */
  static getStylesByType(type: string, styles: any) {
    if (!styles) return {}
    if (type === gsType.GS_SHADOW) {
      return {
        offsetH: styles.shadowOffsetH,
        offsetV: styles.shadowOffsetV,
        blur: styles.shadowBlur,
        spread: styles.shadowSpread,
        color: styles.shadowColor,
        inset: styles.shadowInset,
        mobile: {
          offsetH: this.null2undefined(styles.mobile.shadowOffsetH),
          offsetV: this.null2undefined(styles.mobile.shadowOffsetV),
          blur: this.null2undefined(styles.mobile.shadowBlur),
          spread: this.null2undefined(styles.mobile.shadowSpread),
          color: this.null2undefined(styles.mobile.shadowColor),
          inset: this.null2undefined(styles.mobile.shadowInset),
          behaviour: {
            active: {
              offsetH: this.null2undefined(styles.mobile.active?.shadowOffsetH),
              offsetV: this.null2undefined(styles.mobile.active?.shadowOffsetV),
              blur: this.null2undefined(styles.mobile.active?.shadowBlur),
              spread: this.null2undefined(styles.mobile.active?.shadowSpread),
              color: this.null2undefined(styles.mobile.active?.shadowColor),
              inset: this.null2undefined(styles.mobile.active?.shadowInset),
            },
            hover: {
              offsetH: this.null2undefined(styles.mobile.hover?.shadowOffsetH),
              offsetV: this.null2undefined(styles.mobile.hover?.shadowOffsetV),
              blur: this.null2undefined(styles.mobile.hover?.shadowBlur),
              spread: this.null2undefined(styles.mobile.hover?.shadowSpread),
              color: this.null2undefined(styles.mobile.hover?.shadowColor),
              inset: this.null2undefined(styles.mobile.hover?.shadowInset),
            },
          },
        },
        tablet: {
          offsetH: this.null2undefined(styles.tablet.shadowOffsetH),
          offsetV: this.null2undefined(styles.tablet.shadowOffsetV),
          blur: this.null2undefined(styles.tablet.shadowBlur),
          spread: this.null2undefined(styles.tablet.shadowSpread),
          color: this.null2undefined(styles.tablet.shadowColor),
          inset: this.null2undefined(styles.tablet.shadowInset),
          behaviour: {
            active: {
              offsetH: this.null2undefined(styles.tablet.active?.shadowOffsetH),
              offsetV: this.null2undefined(styles.tablet.active?.shadowOffsetV),
              blur: this.null2undefined(styles.tablet.active?.shadowBlur),
              spread: this.null2undefined(styles.tablet.active?.shadowSpread),
              color: this.null2undefined(styles.tablet.active?.shadowColor),
              inset: this.null2undefined(styles.tablet.active?.shadowInset),
            },
            hover: {
              offsetH: this.null2undefined(styles.tablet.hover?.shadowOffsetH),
              offsetV: this.null2undefined(styles.tablet.hover?.shadowOffsetV),
              blur: this.null2undefined(styles.tablet.hover?.shadowBlur),
              spread: this.null2undefined(styles.tablet.hover?.shadowSpread),
              color: this.null2undefined(styles.tablet.hover?.shadowColor),
              inset: this.null2undefined(styles.tablet.hover?.shadowInset),
            },
          },
        },
        behaviour: {
          active: {
            offsetH: this.null2undefined(styles.active?.shadowOffsetH),
            offsetV: this.null2undefined(styles.active?.shadowOffsetV),
            blur: this.null2undefined(styles.active?.shadowBlur),
            spread: this.null2undefined(styles.active?.shadowSpread),
            color: this.null2undefined(styles.active?.shadowColor),
            inset: this.null2undefined(styles.active?.shadowInset),
          },
          hover: {
            offsetH: this.null2undefined(styles.hover?.shadowOffsetH),
            offsetV: this.null2undefined(styles.hover?.shadowOffsetV),
            blur: this.null2undefined(styles.hover?.shadowBlur),
            spread: this.null2undefined(styles.hover?.shadowSpread),
            color: this.null2undefined(styles.hover?.shadowColor),
            inset: this.null2undefined(styles.hover?.shadowInset),
          },
        },
      }
    }
    if (type === gsType.GS_BOUNDARY) {
      return {
        margin: {
          top: styles.marginTop,
          bottom: styles.marginBottom,
          left: styles.marginLeft,
          right: styles.marginRight,
        },
        padding: {
          top: styles.paddingTop,
          bottom: styles.paddingBottom,
          left: styles.paddingLeft,
          right: styles.paddingRight,
        },
        mobile: {
          margin: {
            top: this.null2undefined(styles.mobile.marginTop),
            bottom: this.null2undefined(styles.mobile.marginBottom),
            left: this.null2undefined(styles.mobile.marginLeft),
            right: this.null2undefined(styles.mobile.marginRight),
          },
          padding: {
            top: this.null2undefined(styles.mobile.paddingTop),
            bottom: this.null2undefined(styles.mobile.paddingBottom),
            left: this.null2undefined(styles.mobile.paddingLeft),
            right: this.null2undefined(styles.mobile.paddingRight),
          },
          behaviour: {
            active: {
              margin: {
                top: this.null2undefined(styles.mobile.active?.marginTop),
                bottom: this.null2undefined(styles.mobile.active?.marginBottom),
                left: this.null2undefined(styles.mobile.active?.marginLeft),
                right: this.null2undefined(styles.mobile.active?.marginRight),
              },
              padding: {
                top: this.null2undefined(styles.mobile.active?.paddingTop),
                bottom: this.null2undefined(styles.mobile.active?.paddingBottom),
                left: this.null2undefined(styles.mobile.active?.paddingLeft),
                right: this.null2undefined(styles.mobile.active?.paddingRight),
              },
            },
            hover: {
              margin: {
                top: this.null2undefined(styles.mobile.hover?.marginTop),
                bottom: this.null2undefined(styles.mobile.hover?.marginBottom),
                left: this.null2undefined(styles.mobile.hover?.marginLeft),
                right: this.null2undefined(styles.mobile.hover?.marginRight),
              },
              padding: {
                top: this.null2undefined(styles.mobile.hover?.paddingTop),
                bottom: this.null2undefined(styles.mobile.hover?.paddingBottom),
                left: this.null2undefined(styles.mobile.hover?.paddingLeft),
                right: this.null2undefined(styles.mobile.hover?.paddingRight),
              },
            },
          },
        },
        tablet: {
          margin: {
            top: this.null2undefined(styles.tablet.marginTop),
            bottom: this.null2undefined(styles.tablet.marginBottom),
            left: this.null2undefined(styles.tablet.marginLeft),
            right: this.null2undefined(styles.tablet.marginRight),
          },
          padding: {
            top: this.null2undefined(styles.tablet.paddingTop),
            bottom: this.null2undefined(styles.tablet.paddingBottom),
            left: this.null2undefined(styles.tablet.paddingLeft),
            right: this.null2undefined(styles.tablet.paddingRight),
          },
          behaviour: {
            active: {
              margin: {
                top: this.null2undefined(styles.tablet.active?.marginTop),
                bottom: this.null2undefined(styles.tablet.active?.marginBottom),
                left: this.null2undefined(styles.tablet.active?.marginLeft),
                right: this.null2undefined(styles.tablet.active?.marginRight),
              },
              padding: {
                top: this.null2undefined(styles.tablet.active?.paddingTop),
                bottom: this.null2undefined(styles.tablet.active?.paddingBottom),
                left: this.null2undefined(styles.tablet.active?.paddingLeft),
                right: this.null2undefined(styles.tablet.active?.paddingRight),
              },
            },
            hover: {
              margin: {
                top: this.null2undefined(styles.tablet.hover?.marginTop),
                bottom: this.null2undefined(styles.tablet.hover?.marginBottom),
                left: this.null2undefined(styles.tablet.hover?.marginLeft),
                right: this.null2undefined(styles.tablet.hover?.marginRight),
              },
              padding: {
                top: this.null2undefined(styles.tablet.hover?.paddingTop),
                bottom: this.null2undefined(styles.tablet.hover?.paddingBottom),
                left: this.null2undefined(styles.tablet.hover?.paddingLeft),
                right: this.null2undefined(styles.tablet.hover?.paddingRight),
              },
            },
          },
        },
        behaviour: {
          active: {
            margin: {
              top: this.null2undefined(styles.active?.marginTop),
              bottom: this.null2undefined(styles.active?.marginBottom),
              left: this.null2undefined(styles.active?.marginLeft),
              right: this.null2undefined(styles.active?.marginRight),
            },
            padding: {
              top: this.null2undefined(styles.active?.paddingTop),
              bottom: this.null2undefined(styles.active?.paddingBottom),
              left: this.null2undefined(styles.active?.paddingLeft),
              right: this.null2undefined(styles.active?.paddingRight),
            },
          },
          hover: {
            margin: {
              top: this.null2undefined(styles.hover?.marginTop),
              bottom: this.null2undefined(styles.hover?.marginBottom),
              left: this.null2undefined(styles.hover?.marginLeft),
              right: this.null2undefined(styles.hover?.marginRight),
            },
            padding: {
              top: this.null2undefined(styles.hover?.paddingTop),
              bottom: this.null2undefined(styles.hover?.paddingBottom),
              left: this.null2undefined(styles.hover?.paddingLeft),
              right: this.null2undefined(styles.hover?.paddingRight),
            },
          },
        },
      }
    }
    if (type === gsType.GS_TEXT) {
      return {
        fontType: styles.fontType,
        fontFamily: styles.family,
        fontWeight: styles.weight,
        fontSize: styles.size,
        letterSpacing: styles.letterSpacing,
        lineHeight: styles.lineHeight,
        mobile: {
          fontType: this.null2undefined(styles.mobile.fontType),
          fontFamily: this.null2undefined(styles.mobile.family),
          fontWeight: this.null2undefined(styles.mobile.weight),
          fontSize: this.null2undefined(styles.mobile.size),
          letterSpacing: this.null2undefined(styles.mobile.letterSpacing),
          lineHeight: this.null2undefined(styles.mobile.lineHeight),
          behaviour: {
            active: {
              fontType: this.null2undefined(styles.mobile.active?.fontType),
              fontFamily: this.null2undefined(styles.mobile.active?.family),
              fontWeight: this.null2undefined(styles.mobile.active?.weight),
              fontSize: this.null2undefined(styles.mobile.active?.size),
              letterSpacing: this.null2undefined(styles.mobile.active?.letterSpacing),
              lineHeight: this.null2undefined(styles.mobile.active?.lineHeight),
            },
            hover: {
              fontType: this.null2undefined(styles.mobile.hover?.fontType),
              fontFamily: this.null2undefined(styles.mobile.hover?.family),
              fontWeight: this.null2undefined(styles.mobile.hover?.weight),
              fontSize: this.null2undefined(styles.mobile.hover?.size),
              letterSpacing: this.null2undefined(styles.mobile.hover?.letterSpacing),
              lineHeight: this.null2undefined(styles.mobile.hover?.lineHeight),
            },
          },
        },
        tablet: {
          fontType: this.null2undefined(styles.tablet.fontType),
          fontFamily: this.null2undefined(styles.tablet.family),
          fontWeight: this.null2undefined(styles.tablet.weight),
          fontSize: this.null2undefined(styles.tablet.size),
          letterSpacing: this.null2undefined(styles.tablet.letterSpacing),
          lineHeight: this.null2undefined(styles.tablet.lineHeight),
          behaviour: {
            active: {
              fontType: this.null2undefined(styles.tablet.active?.fontType),
              fontFamily: this.null2undefined(styles.tablet.active?.family),
              fontWeight: this.null2undefined(styles.tablet.active?.weight),
              fontSize: this.null2undefined(styles.tablet.active?.size),
              letterSpacing: this.null2undefined(styles.tablet.active?.letterSpacing),
              lineHeight: this.null2undefined(styles.tablet.active?.lineHeight),
            },
            hover: {
              fontType: this.null2undefined(styles.tablet.hover?.fontType),
              fontFamily: this.null2undefined(styles.tablet.hover?.family),
              fontWeight: this.null2undefined(styles.tablet.hover?.weight),
              fontSize: this.null2undefined(styles.tablet.hover?.size),
              letterSpacing: this.null2undefined(styles.tablet.hover?.letterSpacing),
              lineHeight: this.null2undefined(styles.tablet.hover?.lineHeight),
            },
          },
        },
        behaviour: {
          active: {
            fontType: this.null2undefined(styles.active?.fontType),
            fontFamily: this.null2undefined(styles.active?.family),
            fontWeight: this.null2undefined(styles.active?.weight),
            fontSize: this.null2undefined(styles.active?.size),
            letterSpacing: this.null2undefined(styles.active?.letterSpacing),
            lineHeight: this.null2undefined(styles.active?.lineHeight),
          },
          hover: {
            fontType: this.null2undefined(styles.hover?.fontType),
            fontFamily: this.null2undefined(styles.hover?.family),
            fontWeight: this.null2undefined(styles.hover?.weight),
            fontSize: this.null2undefined(styles.hover?.size),
            letterSpacing: this.null2undefined(styles.hover?.letterSpacing),
            lineHeight: this.null2undefined(styles.hover?.lineHeight),
          },
        },
      }
    }
    console.warn(`No export data for type ${type}`)
    return {}
  }

  /**
   *
   * @param value
   * @returns
   * Dirty fix for nulls
   */
  static null2undefined(value: any): any {
    if (value === null) return undefined
    return value
  }

  /**
   * idEstilo-controllador-brk-behaviour
   *
   * @param pageGlobalStyles
   * @returns
   */
  static handleStyles(
    pageGlobalStyles: any,
    stylesheetLabels: PageStylesheet["labels"] | undefined,
    expManager: ExpManager
  ) {
    let styles = ""
    if (!pageGlobalStyles) return styles
    for (let key in pageGlobalStyles) {
      const globalStyle = pageGlobalStyles[key]
      styles += `${handleStylesGroupCss(globalStyle, stylesheetLabels, expManager)}`
    }
    return styles
  }

  /**
   * idEstilo-controllador-brk-behaviour
   *
   * @param pageGlobalStyles
   * @returns
   */
  static handleClassName(
    pageGlobalStyles: any,
    template: any,
    propsToInclude?: Array<string>,
    extraClass?: string,
    classNameSufix?:
      | Partial<{ [key in typeof gsType.GS_TEXT | typeof gsType.GS_SHADOW | typeof gsType.GS_BOUNDARY]: string }>
      | undefined
  ) {
    const attachedGlobalStyles = template.nGlobalStyles
    let className = ""
    // no globalstyles or attached exist
    if (!pageGlobalStyles || !attachedGlobalStyles) return className
    // handle attach global styles classnames
    for (let key in attachedGlobalStyles) {
      const globalStyle = pageGlobalStyles[attachedGlobalStyles[key]]
      const styleProp = handleGroupKey(globalStyle.type)
      if (!propsToInclude) {
        className = this.writeClassName(className, template, globalStyle, styleProp, extraClass, classNameSufix)
      } else if (propsToInclude.includes(globalStyle.type)) {
        className = this.writeClassName(className, template, globalStyle, styleProp, extraClass, classNameSufix)
      }
    }
    return className
  }

  /**
   * idEstilo-controllador-brk-behaviour
   *
   * @param pageGlobalStyles
   * @returns
   */
  static handleClassNameV2(
    pageGlobalStyles: any,
    template: any,
    extraClass?: string,
    classNameSufix?:
      | Partial<{ [key in typeof gsType.GS_TEXT | typeof gsType.GS_SHADOW | typeof gsType.GS_BOUNDARY]: string }>
      | undefined
  ) {
    const attachedGlobalStyles = template.nGlobalStyles
    let className = ""
    // no globalstyles or attached exist
    if (!pageGlobalStyles || !attachedGlobalStyles) return className
    // handle attach global styles classnames
    for (let key in attachedGlobalStyles) {
      const globalStyle = pageGlobalStyles[attachedGlobalStyles[key]]
      className = this.writeClassNameV2(className, globalStyle, extraClass, classNameSufix)
    }
    return className
  }

  static writeClassName(
    className: string,
    template: any,
    globalStyle: any,
    styleProp: string,
    extraClass?: string,
    classNameSufix?:
      | Partial<{ [key in typeof gsType.GS_TEXT | typeof gsType.GS_SHADOW | typeof gsType.GS_BOUNDARY]: string }>
      | undefined
  ): string {
    //TODO: better check for gs that doesnt use enable
    if (globalStyle.type === gsType.GS_TEXT) {
      className += ` ${globalStyle.type}-${globalStyle.uuid}`

      // write classNameSufix
      if (classNameSufix && gsType.GS_TEXT in classNameSufix) {
        className += classNameSufix[gsType.GS_TEXT]
      }

      /**
       * write disable state class
       * hover state classes
       */
      // desktop
      const enableHover: boolean = template.behaviour?.hover?.enable || false
      if (enableHover) {
        className += " ngsHoverState"
      }
      // tablet
      const enableTabletHover: boolean =
        typeof template.tablet?.behaviour?.hover?.enable === "boolean"
          ? template.tablet.behaviour.hover.enable
          : enableHover
      if (enableTabletHover === true) {
        className += " ngsTabletHoverState"
      }
      // mobile
      const enableMobileHover: boolean =
        typeof template.mobile?.behaviour?.hover?.enable === "boolean"
          ? template.mobile.behaviour.hover.enable
          : enableHover
      if (enableMobileHover === true) {
        className += " ngsMobileHoverState"
      }
      /**
       * write disable state class
       * active state classes
       */
      // desktop
      const enableActive: boolean = template.behaviour?.active?.enable || false
      if (enableActive) {
        className += " ngsActiveState"
      }
      // tablet
      const enableTabletActive: boolean =
        typeof template.tablet?.behaviour?.active?.enable === "boolean"
          ? template.tablet.behaviour.active.enable
          : enableActive
      if (enableTabletActive === true) {
        className += " ngsTabletActiveState"
      }
      // mobile
      const enableMobileActive: boolean =
        typeof template.mobile?.behaviour?.active?.enable === "boolean"
          ? template.mobile.behaviour.active.enable
          : enableActive
      if (enableMobileActive === true) {
        className += " ngsMobileActiveState"
      }
    } else if (objValueExists(template[styleProp], "enable") && template[styleProp].enable) {
      className += ` ${globalStyle.type}-${globalStyle.uuid}`

      if (globalStyle.type === gsType.GS_SHADOW) {
        // write extra class
        if (extraClass) className += extraClass
      }

      // write classNameSufix
      if (classNameSufix && globalStyle.type in classNameSufix) {
        className += classNameSufix[globalStyle.type as Exclude<nGlobalStyle["type"], string>] // TODO: globalStyle type must be written, currently is any
      }

      /**
       * write disable state class
       * hover state classes
       */
      // desktop
      const enableHover: boolean = template.behaviour?.hover?.enable || false
      if (enableHover) {
        className += " ngsHoverState"
      }
      // tablet
      const enableTabletHover: boolean =
        typeof template.tablet?.behaviour?.hover?.enable === "boolean"
          ? template.tablet.behaviour.hover.enable
          : enableHover
      if (enableTabletHover === true) {
        className += " ngsTabletHoverState"
      }
      // mobile
      const enableMobileHover: boolean =
        typeof template.mobile?.behaviour?.hover?.enable === "boolean"
          ? template.mobile.behaviour.hover.enable
          : enableHover
      if (enableMobileHover === true) {
        className += " ngsMobileHoverState"
      }
      /**
       * write disable state class
       * active state classes
       */
      // desktop
      const enableActive: boolean = template.behaviour?.active?.enable || false
      if (enableActive) {
        className += " ngsActiveState"
      }
      // tablet
      const enableTabletActive: boolean =
        typeof template.tablet?.behaviour?.active?.enable === "boolean"
          ? template.tablet.behaviour.active.enable
          : enableActive
      if (enableTabletActive === true) {
        className += " ngsTabletActiveState"
      }
      // mobile
      const enableMobileActive: boolean =
        typeof template.mobile?.behaviour?.active?.enable === "boolean"
          ? template.mobile.behaviour.active.enable
          : enableActive
      if (enableMobileActive === true) {
        className += " ngsMobileActiveState"
      }
    }
    return className
  }

  static writeClassNameV2(
    className: string,
    globalStyle: any,
    extraClass?: string,
    classNameSufix?:
      | Partial<{ [key in typeof gsType.GS_TEXT | typeof gsType.GS_SHADOW | typeof gsType.GS_BOUNDARY]: string }>
      | undefined
  ): string {
    className += ` ${globalStyle.type}-${globalStyle.uuid}`
    // write extra class
    if (extraClass && globalStyle.type === gsType.GS_SHADOW) {
      className += extraClass
    }
    // write classNameSufix
    if (classNameSufix && globalStyle.type in classNameSufix) {
      className += classNameSufix[globalStyle.type as Exclude<nGlobalStyle["type"], string>] // TODO: globalStyle type must be written, currently is any
    }
    return className
  }

  static handleEditMode(
    editMode: boolean,
    controllerSetStateObject: any,
    stateHasChangesId: string,
    stateEnable?: Array<{ stateKey: string; value: ResponsiveData<boolean> }>,
    signalChanges?: boolean
  ) {
    const hasChangesId = signalChanges ? stateHasChangesId + v4() : stateHasChangesId
    let stateEnableObj = {}
    if (stateEnable) {
      for (let key in stateEnable) {
        let enable = stateEnable[key]
        stateEnableObj = { ...stateEnableObj, [enable.stateKey]: enable.value }
      }
    }
    return {
      isGlobalStyleEditMode: editMode,
      finderSelectedObjectUuid: hasChangesId,
      ...controllerSetStateObject,
      ...stateEnableObj, // maintain enable value, gs doenst have it
    }
  }

  /**
   * handle activated state template values
   * @param globalStyleState
   * @param responsiveBreakpoint
   * @param globalStyleTemplate
   * @param type
   * @returns
   */
  static handleGlobalStyleState(
    globalStyleState: string,
    responsiveBreakpoint: ResponsiveBreakpoint,
    globalStyleTemplate: any,
    type: any
  ) {
    let template
    let handledTemplate
    /**
     * when changed to active/hover state
     * use default when state template doenst exist
     */
    if (globalStyleState !== "default") {
      // when in responsive state
      if (responsiveBreakpoint.breakpoint !== "desktop") {
        template =
          globalStyleTemplate[responsiveBreakpoint.breakpoint].behaviour?.[globalStyleState] || globalStyleTemplate
        handledTemplate = GlobalStylesUtils.handleGlobalStyleToEdit(type, template, responsiveBreakpoint)
      }
      // when in desktop brkp
      else {
        template = globalStyleTemplate.behaviour?.[globalStyleState] || globalStyleTemplate
        handledTemplate = GlobalStylesUtils.handleGlobalStyleToEdit(type, template, responsiveBreakpoint)
      }
    } else {
      /**
       * when changed to default state
       */
      // when in default state
      template = globalStyleTemplate.behaviour?.[globalStyleState] || globalStyleTemplate
      handledTemplate = GlobalStylesUtils.handleGlobalStyleToEdit(type, template, responsiveBreakpoint)
    }
    return handledTemplate
  }

  /**
   * controllerStyles can receive an object with the style props only
   * or an object divided by breakpoint on wich each has it's own style props.
   * The second option happens for breakpoint values.
   * @param globalStyleState
   * @param globalStyleTemplate
   * @param responsiveBreakpoint
   * @param controllerStyles
   * @param stylesProp
   * @returns
   */
  static handleControllerValuesChange(
    globalStyleState: string,
    globalStyleTemplate: any,
    responsiveBreakpoint: ResponsiveBreakpoint,
    controllerStyles: any,
    stylesProp: string
  ) {
    let handledTemplate
    /**
     * handle state values
     */
    //TODO: create method
    if (globalStyleState !== "default") {
      // handle state responsive values
      // write values to responsive hover or active props
      if (responsiveBreakpoint.breakpoint !== "desktop") {
        handledTemplate = {
          ...globalStyleTemplate,
          [responsiveBreakpoint.breakpoint]: {
            ...globalStyleTemplate[responsiveBreakpoint.breakpoint],
            behaviour: {
              ...globalStyleTemplate[responsiveBreakpoint.breakpoint]?.behaviour,
              [globalStyleState]: controllerStyles[responsiveBreakpoint.breakpoint][stylesProp],
            },
          },
        }
      }
      // handle state default values
      // write values to active or hover props
      else {
        handledTemplate = {
          ...globalStyleTemplate,
          behaviour: {
            ...globalStyleTemplate.behaviour,
            [globalStyleState]: controllerStyles[stylesProp],
          },
        }
      }
    } else if (responsiveBreakpoint.breakpoint !== "desktop") {
      /**
       * handle responsive values
       * write values to mobile or tablet props
       */
      handledTemplate = {
        ...globalStyleTemplate,
        [responsiveBreakpoint.breakpoint]: {
          ...globalStyleTemplate[responsiveBreakpoint.breakpoint],
          ...controllerStyles[responsiveBreakpoint.breakpoint][stylesProp],
        },
      }
    } else {
      /**
       * handle default values
       * write values to template root
       */
      handledTemplate = {
        ...globalStyleTemplate,
        ...controllerStyles[stylesProp],
      }
    }
    return handledTemplate
  }

  static handleGlobalStyleDisabledState(globalStyleState: string, globalStyleType: string, type: string) {
    return (globalStyleState === "edit" || globalStyleState === "create") && globalStyleType !== type
  }

  static getAttachedGlobalStyle(
    pageResponse: PageTypes,
    selectedObject: SingleObject,
    bobKey: string,
    type: nGlobalStyle["type"]
  ): nGlobalStyle | undefined {
    const globalStyleId = this.hasGlobalStyleAttached(selectedObject, bobKey, type)
    const globalStyleObject = pageResponse.nGlobalStyles?.[globalStyleId]
    return globalStyleObject
  }

  static attachGlobalStyleToPage(
    hubContent: PageTypes,
    globalStyleObject: nGlobalStyle,
    selectedObject: SingleObject,
    type: string,
    bobKey: string,
    emEditorType: EMEditorType
  ): { hubContent: PageTypes; objects: any } {
    let clonedHubContent = cloneDeep(hubContent) as PageTypes
    //trigger publish btn
    clonedHubContent.isOutdated = true

    // keep compatibility when exists
    clonedHubContent.nGlobalStyles = {
      ...clonedHubContent.nGlobalStyles,
      [globalStyleObject.uuid]: {
        ...globalStyleObject,
        compatibility: { newBobs: true, oldBobs: true }, // inside pagebuilder gs actions must make the gs render for both versions allways, otherwise it's difficult to recalculate this values on the backend for every action that may need it
      },
    }
    let objects = handleObjectsListType(clonedHubContent, selectedObject, emEditorType)

    // attach gs to bob
    objects[selectedObject.uuid].styles.bobs[bobKey].nGlobalStyles = {
      ...objects[selectedObject.uuid].styles.bobs[bobKey].nGlobalStyles,
      [type]: globalStyleObject.uuid,
    }

    return { hubContent: clonedHubContent, objects }
  }

  static getGlobalStyleCompatibility(
    globalStyleId: string,
    pageResponse: PageTypes
  ): nGlobalStyle["compatibility"] | undefined {
    return pageResponse.nGlobalStyles?.[globalStyleId]?.compatibility
  }

  static updateGlobalStyleCompatibility(
    globalStyleId: string,
    compatibility: nGlobalStyle["compatibility"],
    hubContent: PageTypes
  ): { hubContent: PageTypes; globalStyle: nGlobalStyle } {
    let clonedHubContent = cloneDeep(hubContent) as PageTypes

    // update compatibility
    const updatedGlobalStyle = {
      ...clonedHubContent.nGlobalStyles[globalStyleId],
      compatibility: compatibility,
    }

    // update pageResponse globalStyle
    clonedHubContent.nGlobalStyles = {
      ...clonedHubContent.nGlobalStyles,
      [globalStyleId]: updatedGlobalStyle,
    }

    return { hubContent: clonedHubContent, globalStyle: updatedGlobalStyle }
  }

  static writeGlobalStyleListCompatibility(
    nGlobalStyles: PageTypes["nGlobalStyles"],
    newBobs: boolean,
    oldBobs: boolean
  ): PageTypes["nGlobalStyles"] | {} {
    if (nGlobalStyles) {
      let nGLobalStylesUpdated = cloneDeep(nGlobalStyles)
      for (let key in nGLobalStylesUpdated) {
        nGLobalStylesUpdated[key].compatibility = { newBobs, oldBobs }
      }
      return nGLobalStylesUpdated
    }
    return {}
  }
}

export default GlobalStylesUtils

import React, { useEffect } from "react"
import { JobTaskId } from "./jobsSchema"
import { useSSE, SSEProvider, Source } from "react-hooks-sse"
import { z } from "zod"
import { match } from "ts-pattern"

type DataEvent = {
  type: "data"
  text: string
}
type ErrorEvent = {
  type: "error"
  message: string
}
type DoneEvent = {
  type: "done"
}

type StreamEvent = DataEvent | ErrorEvent | DoneEvent
type EventType = StreamEvent["type"]

const HasLastEventIdSchema = z.object({ lastEventId: z.string() })

type State =
  | {
      type: "running"
      events: DataEvent[]
      lastEventId: string
    }
  | { type: "done"; msg: string }
  | { type: "error" }

const initState: () => State = () => ({ type: "running", events: [], lastEventId: "" })

function Stream(props: {
  udpateStreamMessage: (aMessage: string, type: EventType) => void
  messagesContainerRef?: React.RefObject<HTMLDivElement | null>
  renderEmpty?: JSX.Element
}): JSX.Element {
  const state = useSSE<State, StreamEvent>("data", initState(), {
    stateReducer(state, changes) {
      if (state.type !== "running") return state

      if (changes.data.type === "data") {
        const ev = HasLastEventIdSchema.safeParse(changes.event)
        // stream event ids are monotonic, only append if the new id is greater than the last one
        if (ev.success && ev.data.lastEventId > state.lastEventId)
          return { type: "running", lastEventId: ev.data.lastEventId, events: [...state.events, changes.data] }
        else return state
      } else if (changes.data.type === "done") {
        return { type: "done", msg: state.events.map((e) => e.text).join("") }
      } else if (changes.data.type === "error") {
        return { type: "error" }
      }
      return state
    },
  })
  //TODO TEST BETTER - working
  useEffect(() => {
    if (props.messagesContainerRef && props.messagesContainerRef.current) {
      props.messagesContainerRef.current.scrollTop = props.messagesContainerRef.current.scrollHeight
    }
  }, [state, props.messagesContainerRef])

  useEffect(() => {
    match(state)
      .with({ type: "running" }, (running) => {})
      .with({ type: "done" }, (done) => props.udpateStreamMessage(done.msg, "done"))
      .with({ type: "error" }, (error) => props.udpateStreamMessage("Failed To retrieve Message, try again", "error"))
      .exhaustive()
  }, [state, props])

  const loading = state.type === "running" && state.events.length === 0

  if (loading && props.renderEmpty) return props.renderEmpty

  if (state.type === "running")
    return <div className='streaming-message'>{state.events.map((e) => e.text).join("")}</div>
  else return <></>
}

function ReplySSE(props: {
  jobTaskId: JobTaskId
  jobsClient: { taskOutputStream(taskRunId: JobTaskId): Source }
  handleAssistantMessage: (aMessage: string, error: boolean) => void
  messagesContainerRef?: React.RefObject<HTMLDivElement | null>
  onDone?: (message: string) => void
  renderEmpty?: JSX.Element
}): JSX.Element {
  // we need an alternative impl because the regular implementation doesn't support request headers
  const source = () => props.jobsClient.taskOutputStream(props.jobTaskId)
  const updateStreamMessage = (message: string, type: EventType) => {
    const formattedMessage = message.replace(/\n/g, "<br>")
    props.handleAssistantMessage(formattedMessage, type === "error")
    if (type === "done" && props.onDone) props.onDone(formattedMessage)
  }
  return (
    <SSEProvider source={source}>
      <Stream
        udpateStreamMessage={updateStreamMessage}
        messagesContainerRef={props.messagesContainerRef}
        renderEmpty={props.renderEmpty}
      />
    </SSEProvider>
  )
}

export default ReplySSE

import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { isRight } from "fp-ts/lib/Either"
import {
  type,
  union,
  Type,
  TypeOf,
  intersection,
  null as nullC,
  number as numberC,
  boolean as booleanC,
  partial,
} from "io-ts"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { get2WithNull, get2WithNull4Enable, get3WithNull4Enable } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const FilterPropsCodec = type({
  blur: numberC,
  opacity: numberC,
  brightness: numberC,
  contrast: numberC,
  grayscale: numberC,
  hueRotation: numberC,
  invert: numberC,
  saturate: numberC,
  sepia: numberC,
  shadow: numberC,
  enable: booleanC,
})
const FilterCodec = intersection([type({ enable: booleanC }), FilterPropsCodec])

const FilterPropsOptCodec = partial({
  blur: nullable(numberC),
  opacity: nullable(numberC),
  brightness: nullable(numberC),
  contrast: nullable(numberC),
  grayscale: nullable(numberC),
  hueRotation: nullable(numberC),
  invert: nullable(numberC),
  saturate: nullable(numberC),
  sepia: nullable(numberC),
  shadow: nullable(numberC),
  enable: nullable(booleanC),
})
const FilterOptCodec = intersection([partial({ enable: nullable(booleanC) }), FilterPropsOptCodec])

const StylesFilterCodec = intersection([
  type({ filter: FilterCodec }),
  partial({
    behaviour: partial({
      active: partial({ filter: FilterOptCodec }),
      hover: partial({ filter: FilterOptCodec }),
    }),
    mobile: partial({
      filter: FilterOptCodec,
      behaviour: partial({
        active: partial({ filter: FilterOptCodec }),
        hover: partial({ filter: FilterOptCodec }),
      }),
    }),
    tablet: partial({
      filter: FilterOptCodec,
      behaviour: partial({
        active: partial({ filter: FilterOptCodec }),
        hover: partial({ filter: FilterOptCodec }),
      }),
    }),
  }),
])

type Filter = TypeOf<typeof FilterCodec>
type FilterProps = TypeOf<typeof FilterCodec>
type FilterOpt = TypeOf<typeof FilterOptCodec>
type FilterPropsOpt = TypeOf<typeof FilterOptCodec>
type StylesFilter = TypeOf<typeof StylesFilterCodec>
type FilterCSS = {
  filter: string
}

export function responsiveStyle(filterObj: any, optionalProperties: any) {
  // filter is disabled
  if (filterObj.hasOwnProperty("enable") && !filterObj.enable)
    return {
      filter: "none !important",
      "background-color": "transparent !important",
    }

  // retrun color overlay only
  if (optionalProperties && optionalProperties.type === "overlay") {
    let colorOverlay = `${
      filterObj.overlay && filterObj.overlay !== "" ? filterObj.overlay + "!important" : "none !important"
    }`
    return { "background-color": colorOverlay }
  }

  // return filter
  let blur = `${filterObj.blur && filterObj.blur !== 0 ? `blur(${filterObj.blur}px)` : ``}`
  let opacity = `${filterObj.opacity && filterObj.opacity !== 0 ? `opacity(${filterObj.opacity}%)` : ``}`
  let brightness = `${filterObj.brightness ? `brightness(${filterObj.brightness}%)` : ``}`
  let contrast = `${filterObj.contrast ? `contrast(${filterObj.contrast}%)` : ``}`
  let grayscale = `${filterObj.grayscale && filterObj.grayscale !== 0 ? `grayscale(${filterObj.grayscale}%)` : ``}`
  let hueRotate = `${filterObj.hueRotate && filterObj.hueRotate !== 0 ? `hue-rotate(${filterObj.hueRotate}deg)` : ``}`
  let invert = `${filterObj.invert && filterObj.invert !== 0 ? `invert(${filterObj.invert}%)` : ``}`
  let saturate = `${filterObj.saturate && filterObj.saturate !== 0 ? `saturate(${filterObj.saturate}%)` : ``}`
  let sepia = `${filterObj.sepia && filterObj.sepia !== 0 ? `sepia(${filterObj.sepia}%)` : ``}`
  let shadow = `${filterObj.shadow && filterObj.shadow !== 0 ? `box-shadow(${filterObj.shadow}%)` : ``}`
  let imageFilter = `${blur} ${opacity} ${brightness} ${contrast} ${grayscale} ${hueRotate} ${invert} ${saturate} ${sepia} ${shadow} !important`

  return { filter: imageFilter }
}
export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<FilterCSS> {
  const styles = StylesFilterCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(`FilterStyle ${breakpoint} ${behaviourState} ${decoderErrors(styles)}`)
  return {}
}

export function cssRender(
  stylesObj: StylesFilter,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<FilterCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.filter)
    }
    //hover | active
    else {
      return renderBobOpt(stylesObj.filter, mergeBob2(stylesObj?.behaviour?.[behaviourState]?.filter, stylesObj.filter))
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.filter, mergeBob2(stylesObj?.[breakpoint]?.filter, stylesObj.filter))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.filter,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.filter,
          stylesObj?.behaviour?.[behaviourState]?.filter,
          stylesObj?.[breakpoint]?.filter,
          stylesObj.filter
        )
      )
    }
  }
}

export function renderBob(filterObj: Filter): Partial<FilterCSS> {
  if (!filterObj.enable) return {}

  return render(filterObj)
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param filterObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(defaultFilterObj: Filter, filterObj: FilterOpt | undefined): Partial<FilterCSS> {
  if (filterObj?.enable === false) {
    if (defaultFilterObj.enable)
      return {
        filter: "initial",
      }

    return {}
  }

  if (filterObj?.enable) return renderOpt(filterObj)

  return {}
}

/**
 * Renders FilterOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param filterObj
 * @param foundationStyle
 * @returns
 */
export function renderOpt(filterObj: FilterPropsOpt): Partial<FilterCSS> {
  let css = {}
  if (
    objValueExists(filterObj, "blur") ||
    objValueExists(filterObj, "brightness") ||
    objValueExists(filterObj, "contrast") ||
    objValueExists(filterObj, "grayscale") ||
    objValueExists(filterObj, "hueRotate") ||
    objValueExists(filterObj, "invert") ||
    objValueExists(filterObj, "saturate") ||
    objValueExists(filterObj, "sepia") ||
    objValueExists(filterObj, "shadow") ||
    objValueExists(filterObj, "enable")
  ) {
    let blur = `blur(${filterObj.blur}px)`
    let brightness = `brightness(${filterObj.brightness}${filterObj.brightness !== 0 ? "%" : ""})`
    let contrast = `contrast(${filterObj.contrast}${filterObj.contrast !== 0 ? "%" : ""})`
    let grayscale = `grayscale(${filterObj.grayscale}${filterObj.grayscale !== 0 ? "%" : ""})`
    let hueRotation = `hue-rotate(${filterObj.hueRotation}deg)`
    let invert = `invert(${filterObj.invert}${filterObj.invert !== 0 ? "%" : ""})`
    let saturate = `saturate(${filterObj.saturate}${filterObj.saturate !== 0 ? "%" : ""})`
    let sepia = `sepia(${filterObj.sepia}${filterObj.sepia !== 0 ? "%" : ""})`

    css = {
      filter: `${blur} ${brightness} ${contrast} ${grayscale} ${hueRotation} ${invert} ${saturate} ${sepia}`,
    }
  }

  return css
}

export function render(filterObj: FilterProps): FilterCSS {
  let blur = `blur(${filterObj.blur}px)`
  let brightness = `brightness(${filterObj.brightness}${filterObj.brightness !== 0 ? "%" : ""})`
  let contrast = `contrast(${filterObj.contrast}${filterObj.contrast !== 0 ? "%" : ""})`
  let grayscale = `grayscale(${filterObj.grayscale}${filterObj.grayscale !== 0 ? "%" : ""})`
  let hueRotation = `hue-rotate(${filterObj.hueRotation}deg)`
  let invert = `invert(${filterObj.invert}${filterObj.invert !== 0 ? "%" : ""})`
  let saturate = `saturate(${filterObj.saturate}${filterObj.saturate !== 0 ? "%" : ""})`
  let sepia = `sepia(${filterObj.sepia}${filterObj.sepia !== 0 ? "%" : ""})`

  return {
    filter: `${blur} ${brightness} ${contrast} ${grayscale} ${hueRotation} ${invert} ${saturate} ${sepia}`,
  }
}

export function mergeBob2(filterObj: FilterOpt | undefined, defaultFilterObj: Filter): FilterOpt {
  // no filter object
  if (!filterObj) {
    return {
      enable: undefined,
      blur: undefined,
      brightness: undefined,
      contrast: undefined,
      grayscale: undefined,
      hueRotation: undefined,
      invert: undefined,
      saturate: undefined,
      sepia: undefined,
      shadow: undefined,
    }
  }

  const enable = get2WithNull4Enable(filterObj?.enable, defaultFilterObj.enable)

  //if one of the values is set in the breakpoint we render the entire shadow property
  if (
    objValueExists(filterObj, "blur") ||
    objValueExists(filterObj, "opacity") ||
    objValueExists(filterObj, "brightness") ||
    objValueExists(filterObj, "contrast") ||
    objValueExists(filterObj, "grayscale") ||
    objValueExists(filterObj, "hueRotation") ||
    objValueExists(filterObj, "invert") ||
    objValueExists(filterObj, "saturate") ||
    objValueExists(filterObj, "sepia") ||
    objValueExists(filterObj, "shadow") ||
    objValueExists(filterObj, "enable")
  ) {
    const blur = get2WithNull(filterObj?.blur, defaultFilterObj.blur)
    const brightness = get2WithNull(filterObj?.brightness, defaultFilterObj.brightness)
    const contrast = get2WithNull(filterObj?.contrast, defaultFilterObj.contrast)
    const grayscale = get2WithNull(filterObj?.grayscale, defaultFilterObj.grayscale)
    const hueRotation = get2WithNull(filterObj?.hueRotation, defaultFilterObj.hueRotation)
    const invert = get2WithNull(filterObj?.invert, defaultFilterObj.invert)
    const saturate = get2WithNull(filterObj?.saturate, defaultFilterObj.saturate)
    const sepia = get2WithNull(filterObj?.sepia, defaultFilterObj.sepia)
    const shadow = get2WithNull(filterObj?.shadow, defaultFilterObj.shadow)

    return {
      enable,
      blur,
      brightness,
      contrast,
      grayscale,
      hueRotation,
      invert,
      saturate,
      sepia,
      shadow,
    }
  }

  //only enable is written
  return {
    enable,
    blur: undefined,
    brightness: undefined,
    contrast: undefined,
    grayscale: undefined,
    hueRotation: undefined,
    invert: undefined,
    saturate: undefined,
    sepia: undefined,
    shadow: undefined,
  }
}

export function mergeBob3(
  filterObj: FilterOpt | undefined,
  filterDesktopBehaviour: FilterOpt | undefined,
  filterDefaultBreakpoint: FilterOpt | undefined,
  defaultFilterObj: Filter
): FilterOpt {
  // no filter object
  if (!filterObj) {
    return {
      enable: undefined,
      blur: undefined,
      brightness: undefined,
      contrast: undefined,
      grayscale: undefined,
      hueRotation: undefined,
      invert: undefined,
      saturate: undefined,
      sepia: undefined,
      shadow: undefined,
    }
  }

  const enable = get3WithNull4Enable(
    filterObj?.enable,
    filterDesktopBehaviour?.enable,
    filterDefaultBreakpoint?.enable,
    defaultFilterObj.enable
  )

  //if one of the values is set in the breakpoint we render the entire shadow property
  if (
    objValueExists(filterObj, "blur") ||
    objValueExists(filterObj, "brightness") ||
    objValueExists(filterObj, "contrast") ||
    objValueExists(filterObj, "grayscale") ||
    objValueExists(filterObj, "hueRotation") ||
    objValueExists(filterObj, "invert") ||
    objValueExists(filterObj, "saturate") ||
    objValueExists(filterObj, "sepia") ||
    objValueExists(filterObj, "shadow") ||
    objValueExists(filterObj, "enable")
  ) {
    const blur =
      filterObj?.blur ?? filterDesktopBehaviour?.blur ?? filterDefaultBreakpoint?.blur ?? defaultFilterObj.blur
    const brightness =
      filterObj?.brightness ??
      filterDesktopBehaviour?.brightness ??
      filterDefaultBreakpoint?.brightness ??
      defaultFilterObj.brightness
    const contrast =
      filterObj?.contrast ??
      filterDesktopBehaviour?.contrast ??
      filterDefaultBreakpoint?.contrast ??
      defaultFilterObj.contrast
    const grayscale =
      filterObj?.grayscale ??
      filterDesktopBehaviour?.grayscale ??
      filterDefaultBreakpoint?.grayscale ??
      defaultFilterObj.grayscale
    const hueRotation =
      filterObj?.hueRotation ??
      filterDesktopBehaviour?.hueRotation ??
      filterDefaultBreakpoint?.hueRotation ??
      defaultFilterObj.hueRotation
    const invert =
      filterObj?.invert ?? filterDesktopBehaviour?.invert ?? filterDefaultBreakpoint?.invert ?? defaultFilterObj.invert
    const saturate =
      filterObj?.saturate ??
      filterDesktopBehaviour?.saturate ??
      filterDefaultBreakpoint?.saturate ??
      defaultFilterObj.saturate
    const sepia =
      filterObj?.sepia ?? filterDesktopBehaviour?.sepia ?? filterDefaultBreakpoint?.sepia ?? defaultFilterObj.sepia
    const shadow =
      filterObj?.shadow ?? filterDesktopBehaviour?.shadow ?? filterDefaultBreakpoint?.shadow ?? defaultFilterObj.shadow

    return {
      enable,
      blur,
      brightness,
      contrast,
      grayscale,
      hueRotation,
      invert,
      saturate,
      sepia,
      shadow,
    }
  }

  //only enable is written
  return {
    enable,
    blur: undefined,
    brightness: undefined,
    contrast: undefined,
    grayscale: undefined,
    hueRotation: undefined,
    invert: undefined,
    saturate: undefined,
    sepia: undefined,
    shadow: undefined,
  }
}

export type { StylesFilter, FilterProps, FilterPropsOpt, Filter, FilterOpt, FilterCSS }


import { valueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { isRight } from "fp-ts/lib/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { GSText, GSTextCodec, StylesText, StylesTextCodec, Text, TextOpt, TextProps } from "./textStyle"
import { FontLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetTypes"
import { handleBobStylesheetLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetUtils"

type TextCSS = {
  "font-family": string
  "font-weight": string
  "font-size": number
  "letter-spacing": number
  "line-height": string
}

export function renderCSSString(textStyle: Partial<TextCSS> | {}): string {
  if (
    "font-family" in textStyle ||
    "font-size" in textStyle ||
    "font-weight" in textStyle ||
    "letter-spacing" in textStyle ||
    "line-height" in textStyle
  ) {
    let css = ""
    if (valueExists(textStyle?.["font-family"])) {
      css += `font-family: ${textStyle?.["font-family"]};`
    }
    if (valueExists(textStyle?.["font-weight"])) {
      css += `font-weight: ${textStyle?.["font-weight"]};`
    }
    if (valueExists(textStyle?.["font-size"])) {
      css += `font-size: ${textStyle?.["font-size"]}px;`
    }
    if (valueExists(textStyle?.["letter-spacing"])) {
      css += `letter-spacing: ${textStyle?.["letter-spacing"]}px;`
    }
    if (valueExists(textStyle?.["line-height"])) {
      css += `line-height: ${textStyle?.["line-height"]};`
    }

    return css
  }

  return ""
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  stylesheetLabel: FontLabel | undefined
): Partial<TextCSS> {
  const styles = StylesTextCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState, stylesheetLabel)
  console.warn(`TextStyle ${breakpoint} ${behaviourState} ${decoderErrors(styles)}`)
  return {}
}

export function cssRender(
  stylesObj: StylesText,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  stylesheetLabel: FontLabel | undefined
): Partial<TextCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj, stylesheetLabel)
    }
    //hover | active
    else {
      return renderBobOpt(stylesObj?.behaviour?.[behaviourState], stylesheetLabel)
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj?.[breakpoint], stylesheetLabel)
    }
    //hover | active
    else {
      return renderBobOpt(stylesObj?.[breakpoint]?.behaviour?.[behaviourState], stylesheetLabel)
    }
  }
}

export function globalStyleCssRenderUnsafe(
  gsObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  stylesheetLabel: FontLabel | undefined
): Partial<TextCSS> {
  const gs = GSTextCodec.decode(gsObj)
  if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState, stylesheetLabel)
  console.warn(`TextGSStyle ${breakpoint} ${behaviourState} ${decoderErrors(gs)}`)
  return {}
}

export function globalStyleCssRender(
  stylesObj: GSText,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  stylesheetLabel: FontLabel | undefined
): Partial<TextCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj, stylesheetLabel)
    }
    //hover | active
    else {
      return renderOpt(stylesObj?.behaviour?.[behaviourState], stylesheetLabel)
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderOpt(stylesObj?.[breakpoint], stylesheetLabel)
    }
    //hover | active
    else {
      return renderOpt(stylesObj?.[breakpoint]?.behaviour?.[behaviourState], stylesheetLabel)
    }
  }
}

export function renderBob(textObj: Text, stylesheetLabel: FontLabel | undefined): TextCSS {
  return render(textObj, stylesheetLabel)
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param textObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(textObj: TextOpt | undefined, stylesheetLabel: FontLabel | undefined): Partial<TextCSS> {
  if (textObj) {
    return renderOpt(textObj, stylesheetLabel)
  }
  return {}
}

/**
 * Renders TextOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param textObj
 * @param foundationStyle
 * @returns
 */
export function renderOpt(textObj: TextOpt | undefined, stylesheetLabel: FontLabel | undefined): Partial<TextCSS> {
  let css = {}
  if (valueExists(textObj?.fontFamily)) {
    css = { ...css, "font-family": handleBobStylesheetLabel(stylesheetLabel, `"${textObj?.fontFamily}"`) }
  }
  if (valueExists(textObj?.fontWeight)) {
    css = { ...css, "font-weight": `${textObj?.fontWeight}` }
  }
  if (valueExists(textObj?.fontSize)) {
    css = { ...css, "font-size": textObj?.fontSize }
  }
  if (valueExists(textObj?.letterSpacing)) {
    css = { ...css, "letter-spacing": textObj?.letterSpacing }
  }
  if (valueExists(textObj?.lineHeight)) {
    css = { ...css, "line-height": `${textObj?.lineHeight}` }
  }

  return css
}

export function render(textObj: TextProps, stylesheetLabel: FontLabel | undefined): TextCSS {
  return {
    "font-family": handleBobStylesheetLabel(stylesheetLabel, `"${textObj.fontFamily}"`),
    "font-weight": `${textObj.fontWeight}`,
    "font-size": textObj.fontSize,
    "letter-spacing": textObj.letterSpacing,
    "line-height": `${textObj.lineHeight}`,
  }
}

export type { TextCSS }

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function settingsReducer(state = initialState.settingsReducer, action) {
  switch (action.type) {
    case types.ERROR_UPLOAD_FONT:
      return {
        ...state,
        loadingUploadFont: false,
        errorUploadFont: action.error,
        successUploadFont: undefined,
      }
    case types.REQUEST_UPLOAD_FONT:
      return {
        ...state,
        loadingUploadFont: true,
        errorUploadFont: false,
        successUploadFont: undefined,
      }
    case types.SUCCESS_UPLOAD_FONT:
      return {
        ...state,
        loadingUploadFont: false,
        errorUploadFont: false,
        successUploadFont: action.response,
      }
    case types.CLEAR_UPLOAD_FONT:
      return {
        ...state,
        loadingUploadFont: false,
        errorUploadFont: false,
        successUploadFont: undefined,
      }
    default:
      return state
  }
}
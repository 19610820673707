import * as types from "../shared-store/actions/actionTypes"
import initialState from "../initialState"

export default function stylesheetReducer(state: any = initialState.stylesheetReducer, action: any) {
  switch (action.type) {
    /**
     * view state
     */
    case types.STYLESHEET_VIEW:
      return {
        ...state,
        stylesheetState: action.state,
        stylesheetIdInUse: action.stylesheetId,
      }
    /**
     * list
     */
    case types.CLEAR_FETCH_STYLESHEET_LIST:
      return {
        ...state,
        errorFetchStylesheetList: false,
        loadingFetchStylesheetList: false,
        successFetchStylesheetList: undefined,
      }
    case types.ERROR_FETCH_STYLESHEET_LIST:
      return {
        ...state,
        errorFetchStylesheetList: action.error,
        loadingFetchStylesheetList: undefined,
        successFetchStylesheetList: undefined,
      }
    case types.REQUEST_FETCH_STYLESHEET_LIST:
      return {
        ...state,
        errorFetchStylesheetList: false,
        loadingFetchStylesheetList: true,
      }
    case types.SUCCESS_FETCH_STYLESHEET_LIST:
      if (action.shouldAdd) {
        let clonedState = state.successFetchStylesheetList
        const newResponse = {
          ...clonedState,
          ...action.response,
          data: [...clonedState.data, ...action.response.data],
        }
        return {
          ...state,
          errorFetchStylesheetList: false,
          loadingFetchStylesheetList: false,
          successFetchStylesheetList: newResponse,
        }
      }
      return {
        ...state,
        errorFetchStylesheetList: false,
        loadingFetchStylesheetList: false,
        successFetchStylesheetList: action.response,
      }
    /**
     * info
     */
    case types.CLEAR_FETCH_STYLESHEET_INFO:
      return {
        ...state,
        errorFetchStylesheetInfo: false,
        loadingFetchStylesheetInfo: false,
        successFetchStylesheetInfo: undefined,
      }
    case types.ERROR_FETCH_STYLESHEET_INFO:
      return {
        ...state,
        errorFetchStylesheetInfo: action.error,
        loadingFetchStylesheetInfo: undefined,
        successFetchStylesheetInfo: undefined,
      }
    case types.REQUEST_FETCH_STYLESHEET_INFO:
      return {
        ...state,
        errorFetchStylesheetInfo: false,
        loadingFetchStylesheetInfo: true,
      }
    case types.SUCCESS_FETCH_STYLESHEET_INFO:
      return {
        ...state,
        errorFetchStylesheetInfo: false,
        loadingFetchStylesheetInfo: false,
        successFetchStylesheetInfo: action.response,
      }
    // edit stylesheet info
    case types.EDIT_STYLESHEET_INFO:
      return {
        ...state,
        successFetchStylesheetInfo: action.stylesheet,
      }
    /**
     * attach
     */
    case types.CLEAR_ATTACH_STYLESHEET_LABEL:
      return {
        ...state,
        errorAttachStylesheetLabel: false,
        loadingAttachStylesheetLabel: false,
        successAttachStylesheetLabel: undefined,
      }
    case types.ERROR_ATTACH_STYLESHEET_LABEL:
      return {
        ...state,
        errorAttachStylesheetLabel: action.error,
        loadingAttachStylesheetLabel: undefined,
        successAttachStylesheetLabel: undefined,
      }
    case types.REQUEST_ATTACH_STYLESHEET_LABEL:
      return {
        ...state,
        errorAttachStylesheetLabel: false,
        loadingAttachStylesheetLabel: true,
        successAttachStylesheetLabel: undefined,
      }
    case types.SUCCESS_ATTACH_STYLESHEET_LABEL:
      return {
        ...state,
        errorAttachStylesheetLabel: false,
        loadingAttachStylesheetLabel: false,
        successAttachStylesheetLabel: { ...action.response, label: action.label },
      }
    /**
     * dettach
     */
    case types.CLEAR_DETTACH_STYLESHEET_LABEL:
      return {
        ...state,
        errorDettachStylesheetLabel: false,
        loadingDettachStylesheetLabel: false,
        successDettachStylesheetLabel: undefined,
      }
    case types.ERROR_DETTACH_STYLESHEET_LABEL:
      return {
        ...state,
        errorDettachStylesheetLabel: action.error,
        loadingDettachStylesheetLabel: undefined,
        successDettachStylesheetLabel: undefined,
      }
    case types.REQUEST_DETTACH_STYLESHEET_LABEL:
      return {
        ...state,
        errorDettachStylesheetLabel: false,
        loadingDettachStylesheetLabel: true,
        successDettachStylesheetLabel: undefined,
      }
    case types.SUCCESS_DETTACH_STYLESHEET_LABEL:
      return {
        ...state,
        errorDettachStylesheetLabel: false,
        loadingDettachStylesheetLabel: false,
        successDettachStylesheetLabel: { ...action.response, label: action.label },
      }
    /**
     * update
     */
    case types.CLEAR_UPDATE_STYLESHEET_LABEL:
      return {
        ...state,
        errorUpdateStylesheetLabel: false,
        loadingUpdateStylesheetLabel: false,
        successUpdateStylesheetLabel: undefined,
      }
    case types.ERROR_UPDATE_STYLESHEET_LABEL:
      return {
        ...state,
        errorUpdateStylesheetLabel: action.error,
        loadingUpdateStylesheetLabel: undefined,
        successUpdateStylesheetLabel: undefined,
      }
    case types.REQUEST_UPDATE_STYLESHEET_LABEL:
      return {
        ...state,
        errorUpdateStylesheetLabel: false,
        loadingUpdateStylesheetLabel: { label: action.label },
        successUpdateStylesheetLabel: undefined,
      }
    case types.SUCCESS_UPDATE_STYLESHEET_LABEL:
      return {
        ...state,
        errorUpdateStylesheetLabel: false,
        loadingUpdateStylesheetLabel: false,
        successUpdateStylesheetLabel: action.response,
      }
    /**
     * attach content
     */
    case types.CLEAR_ATTACH_STYLESHEET_LABEL_CONTENT:
      return {
        ...state,
        errorAttachStylesheetLabelContent: false,
        loadingAttachStylesheetLabelContent: false,
        successAttachStylesheetLabelContent: undefined,
      }
    case types.ERROR_ATTACH_STYLESHEET_LABEL_CONTENT:
      return {
        ...state,
        errorAttachStylesheetLabelContent: action.error,
        loadingAttachStylesheetLabelContent: undefined,
        successAttachStylesheetLabelContent: undefined,
      }
    case types.REQUEST_ATTACH_STYLESHEET_LABEL_CONTENT:
      return {
        ...state,
        errorAttachStylesheetLabelContent: false,
        loadingAttachStylesheetLabelContent: true,
      }
    case types.SUCCESS_ATTACH_STYLESHEET_LABEL_CONTENT:
      return {
        ...state,
        errorAttachStylesheetLabelContent: false,
        loadingAttachStylesheetLabelContent: false,
        successAttachStylesheetLabelContent: { ...action.response, label: action.label },
      }
    /**
     * dettach content
     */
    case types.CLEAR_DETTACH_STYLESHEET_LABEL_CONTENT:
      return {
        ...state,
        errorDettachStylesheetLabelContent: false,
        loadingDettachStylesheetLabelContent: false,
        successDettachStylesheetLabelContent: undefined,
      }
    case types.ERROR_DETTACH_STYLESHEET_LABEL_CONTENT:
      return {
        ...state,
        errorDettachStylesheetLabelContent: action.error,
        loadingDettachStylesheetLabelContent: undefined,
        successDettachStylesheetLabelContent: undefined,
      }
    case types.REQUEST_DETTACH_STYLESHEET_LABEL_CONTENT:
      return {
        ...state,
        errorDettachStylesheetLabelContent: false,
        loadingDettachStylesheetLabelContent: true,
      }
    case types.SUCCESS_DETTACH_STYLESHEET_LABEL_CONTENT:
      return {
        ...state,
        errorDettachStylesheetLabelContent: false,
        loadingDettachStylesheetLabelContent: false,
        successDettachStylesheetLabelContent: { ...action.response, label: action.label },
      }
    /**
     * attach Gs
     */
    case types.CLEAR_ATTACH_GS_STYLESHEET_LABEL:
      return {
        ...state,
        errorAttachGsStylesheetLabel: false,
        loadingAttachGsStylesheetLabel: false,
        successAttachGsStylesheetLabel: undefined,
      }
    case types.ERROR_ATTACH_GS_STYLESHEET_LABEL:
      return {
        ...state,
        errorAttachGsStylesheetLabel: action.error,
        loadingAttachGsStylesheetLabel: undefined,
        successAttachGsStylesheetLabel: undefined,
      }
    case types.REQUEST_ATTACH_GS_STYLESHEET_LABEL:
      return {
        ...state,
        errorAttachGsStylesheetLabel: false,
        loadingAttachGsStylesheetLabel: true,
        successAttachGsStylesheetLabel: undefined,
      }
    case types.SUCCESS_ATTACH_GS_STYLESHEET_LABEL:
      return {
        ...state,
        errorAttachGsStylesheetLabel: false,
        loadingAttachGsStylesheetLabel: false,
        successAttachGsStylesheetLabel: { ...action.response, label: action.label },
      }
    /**
     * dettach Gs
     */
    case types.CLEAR_DETTACH_GS_STYLESHEET_LABEL:
      return {
        ...state,
        errorDettachGsStylesheetLabel: false,
        loadingDettachGsStylesheetLabel: false,
        successDettachGsStylesheetLabel: undefined,
      }
    case types.ERROR_DETTACH_GS_STYLESHEET_LABEL:
      return {
        ...state,
        errorDettachGsStylesheetLabel: action.error,
        loadingDettachGsStylesheetLabel: undefined,
        successDettachGsStylesheetLabel: undefined,
      }
    case types.REQUEST_DETTACH_GS_STYLESHEET_LABEL:
      return {
        ...state,
        errorDettachGsStylesheetLabel: false,
        loadingDettachGsStylesheetLabel: true,
        successDettachGsStylesheetLabel: undefined,
      }
    case types.SUCCESS_DETTACH_GS_STYLESHEET_LABEL:
      return {
        ...state,
        errorDettachGsStylesheetLabel: false,
        loadingDettachGsStylesheetLabel: false,
        successDettachGsStylesheetLabel: { ...action.response, label: action.label },
      }
    default:
      return state
  }
}

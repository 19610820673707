import z, { ZodType, ZodTypeDef } from "zod"
import {
  AgentId,
  BasePostEdit,
  ChatMessage,
  ContextPreview,
  ContextPreviewSchema,
  DocumentView,
  DocumentViewSchema,
  FluxioPostId,
  FluxioPostIdSchema,
  FullJob,
  FullJobSchema,
  IndexedId,
  JobId,
  JobRun,
  JobRunDetail,
  JobRunDetailSchema,
  JobRunId,
  JobRunSchema,
  JobTag,
  JobTagSchema,
  JobTaskId,
  JobTaskIdDecoder,
  JobTaskIdSchema,
  PostTree,
  PostTreeSchema,
  Process,
  ProcessRunId,
  ProcessSchema,
  ProfileName,
  ProfileNameSchema,
  RunningJobs,
  RunningJobsSchema,
  ShortRun,
  ShortRunSchema,
  StartProcessInput,
  StartProcessProxiedRequest,
  TaskOutputReady,
  TaskOutputReadySchema,
  ViewId,
  mkJobId,
} from "./jobsSchema"
import apiEnv from "../../../api/shared-api/apiEnv"
import { Json, JsonClient, JsonClientI, JsonObject } from "../../../lib/jsonRequest/JsonRequest"
import * as StringType from "@/lib/brand/StringType"
import { SDForm, SDFormSchema } from "../../../lib/server-driven-forms/sdftypes"
import { KnownForm } from "../../../lib/server-driven-forms/FormLoader"
import { Paginated, PaginatedSchema, Paging } from "../../../lib/jsonRequest/Paginated"
import { optionFromNullable } from "../../../lib/monads/option/option"
import { getLibrary } from "../../../api/shared-api/contentLibraryApi"
import { MediaObject } from "../../../modules/shared-modules/experienceManager/types/contentTypes"
import { OrderKey } from "../../../modules/shared-modules/content/contentLibraryTypes"
import { match } from "ts-pattern"
// @ts-ignore
import { EventSourcePolyfill } from "event-source-polyfill"
import { MkParser, Parser } from "../../../lib/Parser"
import { deleteFluxioPost } from "../../../api/shared-api/experienceManagerApi"
import { Source } from "react-hooks-sse"
import * as D from "io-ts/Decoder"

export interface JobRequest<A> {
  jobId: JobId
  input: JsonObject
  outputParser: Parser<A>
}

export class JobRequests {
  private static genericParser<A, D extends ZodTypeDef, I>(decoder: ZodType<A, D, I>): Parser<A> {
    // this is the last straw for zod, there's no fucking reason this should produce undefined, even
    // bringing the annoying 3 type parameters doesn't fix it
    // i'd like to use io-ts from now on
    return MkParser.zod(z.object({ jobOutput: decoder })).map((a) => a.jobOutput!)
  }

  private static genericParser2<A>(decoder: D.Decoder<unknown, A>): Parser<A> {
    return MkParser.decoder(D.struct({ jobOutput: decoder })).map((a) => a.jobOutput)
  }

  static chatLikeJobRequest(
    idStr: string,
    postId: FluxioPostId,
    context: ContextV[],
    messages: ChatMessage[]
  ): JobRequest<JobTaskId[]> {
    const decoder = D.struct({ tasks: D.array(JobTaskIdDecoder) })

    return {
      jobId: mkJobId(idStr),
      input: { postId: postId, context: context, messages: messages },
      outputParser: this.genericParser2(decoder).map((r) => r.tasks),
    }
  }

  static businessOverview2(
    postId: FluxioPostId,
    context: ContextV[],
    messages: ChatMessage[]
  ): JobRequest<JobTaskId[]> {
    return JobRequests.chatLikeJobRequest("businessOverview2", postId, context, messages)
  }

  static systemThinkingShort(
    postId: FluxioPostId,
    context: ContextV[],
    messages: ChatMessage[]
  ): JobRequest<JobTaskId[]> {
    return JobRequests.chatLikeJobRequest("systemThinkingShort", postId, context, messages)
  }

  static systemThinkingLong(
    postId: FluxioPostId,
    context: ContextV[],
    messages: ChatMessage[]
  ): JobRequest<JobTaskId[]> {
    return JobRequests.chatLikeJobRequest("systemThinkingLong", postId, context, messages)
  }

  static initiatives(postId: FluxioPostId, context: ContextV[], messages: ChatMessage[]): JobRequest<JobTaskId[]> {
    return JobRequests.chatLikeJobRequest("initiatives", postId, context, messages)
  }
}

export class JobsApi {
  profileName: string
  profileNameT: ProfileName
  cl: JsonClientI
  base: string
  _token: { access_token: string }

  constructor(profileName: string, token: string) {
    this.profileName = profileName
    this.profileNameT = StringType.wrap(ProfileNameSchema, profileName)
    this.cl = JsonClient.authorized(token)
    this.base = apiEnv()
    this._token = { access_token: token }
  }

  ProfileRunsResponseSchema = z.object({ runs: z.array(ShortRunSchema) })

  listRuns(): Promise<ShortRun[]> {
    return this.cl
      .get({
        url: `${this.base}v1/profiles/${this.profileName}/contentGeneration/processRuns/`,
        decoder: this.ProfileRunsResponseSchema,
      })
      .then((response) => response.runs)
  }

  deleteRun(runId: ProcessRunId): Promise<void> {
    return this.cl.delete({
      url: `${this.base}v1/profiles/${this.profileName}/contentGeneration/processRuns/${runId}`,
      decoder: z.any(),
    })
  }

  cancelRun(runId: ProcessRunId): Promise<void> {
    return this.cl.delete({
      url: `${this.base}v1/profiles/${this.profileName}/contentGeneration/processRunsX/${runId}`,
      decoder: z.any(),
    })
  }

  deleteJobRun(jobRunId: JobRunId): Promise<void> {
    const input: Json = { jobRunId: jobRunId }
    return this.proxied("deleteJobRun", input, z.any())
  }

  cancelJobRun(jobRunId: JobRunId): Promise<void> {
    const input: Json = { jobRunId: jobRunId }
    return this.proxied("cancelJobRun", input, z.any())
  }

  ProcessesResponseSchema = z.object({ processes: z.array(ProcessSchema) })
  listProcesses(): Promise<Process[]> {
    return this.cl
      .get({ url: `${this.base}v1/contentGeneration/processes`, decoder: this.ProcessesResponseSchema })
      .then((response) => response.processes)
  }

  listSitesDestinations(): Promise<[]> {
    return this.cl
      .get({
        url: `${apiEnv()}v1/profiles/${this.profileName}/sitesSelect?limit=50&orderby=created_at&order=desc&page=1`,
        decoder: z.any(),
      })
      .then((response) => response.data)
  }

  startProcess(processName: string, inputData: StartProcessInput): Promise<void> {
    let req: StartProcessProxiedRequest = {
      profileName: this.profileNameT,
      processName: processName,
      ...inputData,
    }

    return this.cl.proxied({
      url: `${this.base}v1/profiles/${this.profileName}/proxy`,
      name: "startProcessRun",
      body: req,
      decoder: z.any(),
    })
  }

  // TODO: dangerously close to being out of scope for this file
  proxied<A = void, Def extends ZodTypeDef = ZodTypeDef, Input = A>(
    endpointName: string,
    input: Json,
    decoder?: ZodType<A, Def, Input>
  ): Promise<A> {
    return this.cl.proxied({
      url: `${this.base}v1/profiles/${this.profileName}/proxy`,
      name: endpointName,
      body: input,
      decoder: decoder ?? z.any(),
    })
  }

  // TODO: dangerously close to being out of scope for this file
  proxied1<A>(endpointName: string, input: Json, decoder: Parser<A>): Promise<A> {
    return this.cl.proxied1({
      url: `${this.base}v1/profiles/${this.profileName}/proxy`,
      name: endpointName,
      body: input,
      decoder: decoder,
    })
  }

  form(formName: KnownForm): Promise<SDForm> {
    return this.proxied("form", { formName: formName }, SDFormSchema)
  }

  // TODO: for sure out of scope for this file
  submitForm_<ServerResponse = unknown>(
    endpoint: KnownForm,
    input: Json,
    decoder?: ZodType<ServerResponse>
  ): Promise<ServerResponse> {
    return this.proxied(endpoint, input, decoder ?? z.any())
  }

  submitForm(endpoint: string, input: Json): <A>(decoder?: ZodType<A>) => Promise<A> {
    return (decoder) => this.proxied(endpoint, input, decoder)
  }

  submit<A>(endpoint: string, input: Json, parser: Parser<A>): Promise<A> {
    return this.proxied1(endpoint, input, parser)
  }

  profileJobRuns(): Promise<JobRun[]> {
    const input: Json = { profileName: this.profileNameT }
    const decoder = z.object({ jobs: z.array(JobRunSchema) })
    return this.proxied("profileJobRuns", input, decoder).then((e) => e.jobs)
  }

  jobRunDetail(id: JobRunId): Promise<JobRunDetail> {
    const input: Json = { jobRunId: id }
    return this.proxied("jobRunDetail", input, JobRunDetailSchema)
  }

  runsOfJobAndProfile(jobId: JobId, profileName: ProfileName, paging: Paging): Promise<Paginated<JobRun>> {
    const input: Json = { jobId, profileName, ...paging }
    return this.proxied("runsOfJobAndProfile", input, PaginatedSchema(JobRunSchema))
  }

  //Hey this is not the way

  runsOfPostAndProfile(postId: FluxioPostId, profileName: ProfileName): Promise<RunningJobs> {
    const input: Json = { postId, profileName }

    return this.proxied("runsOfPostAndProfile", input, RunningJobsSchema)
  }

  listTags(): Promise<JobTag[]> {
    return this.proxied("listJobTags", {}, z.object({ tags: z.array(JobTagSchema) })).then((r) => r.tags)
  }

  listJobs(search?: string): Promise<FullJob[]> {
    const input = {
      search: optionFromNullable(search).unwrapOrNull(),
    }

    return this.proxied("listJobs", input, z.object({ jobs: z.array(FullJobSchema) })).then((r) => r.jobs)
  }

  getJobWithForm(jobId: JobId): Promise<{ job: FullJob; form: SDForm; contextPreview?: ContextPreview }> {
    const input = { jobId }

    return this.proxied(
      "getJobWithForm",
      input,
      z.object({ job: FullJobSchema, form: SDFormSchema, contextPreview: z.optional(ContextPreviewSchema) })
    )
  }

  // unsafe, not decoded
  getContentLibrary(order: OrderKey, limit: number, search: string, cursor?: string): Promise<ContentLibraryResponse> {
    const [_order, orderBy] = match(order)
      .with("created-asc", () => ["asc", "created"])
      .with("created-desc", () => ["desc", "created"])
      .exhaustive()
    return getLibrary(this.profileName, this._token, _order, orderBy, limit, search, cursor)
  }
  jobRunContent(jobRunId: JobRunId): Promise<{ posts: PostTree[] }> {
    const input: Json = { profileName: this.profileNameT, jobRunId: jobRunId }

    return this.proxied("jobContent", input, z.object({ posts: z.array(PostTreeSchema) }))
  }

  startChatJob(
    messages: ChatMessage[],
    lastMessage: string,
    postId: FluxioPostId,
    agent: AgentId | undefined,
    context: ContextV[]
  ): Promise<JobTaskId> {
    const body = {
      jobId: "postChat",
      profileName: this.profileNameT,
      postId: postId,
      messages: messages,
      lastMessage: lastMessage,
      context: context ? context : [],
      agent: agent,
    }

    const decoder = z.object({ jobOutput: z.object({ taskRunId: JobTaskIdSchema }) })

    return this.proxied("startJob", body, decoder).then((r) => r.jobOutput.taskRunId)
  }

  startJob<A>(jobRequest: JobRequest<A>): Promise<A> {
    const body = {
      jobId: jobRequest.jobId,
      profileName: this.profileNameT,
      ...jobRequest.input,
    }

    return this.proxied1("startJob", body, jobRequest.outputParser)
  }

  isTaskOutputReady(taskRunId: JobTaskId): Promise<TaskOutputReady> {
    return this.proxied("taskOutputReady", { taskRunId: taskRunId }, TaskOutputReadySchema)
  }

  taskOutputStream(taskRunId: JobTaskId): Source {
    const url = `https://service.internal.fluxio.cloud/public/workspaces/${this.profileName}/stream/${taskRunId}`
    return new EventSourcePolyfill(url, {
      headers: { Authorization: `Bearer ${this._token.access_token}` },
    })
  }

  //MOVE-ISOLATE?
  updateTopic(postId: string, fields: BasePostEdit): Promise<void> {
    const body = { fields: fields }
    return this.cl.patch({
      url: `${apiEnv()}v1/profiles/${this.profileName}/content3/posts/${postId}`,
      body: body,
      decoder: z.any(),
    })
  }

  createPostChild(parentId: FluxioPostId, post: BasePostEdit): Promise<{ id: FluxioPostId }> {
    const input: Json = { parentId, profileName: this.profileName, post }

    return this.proxied("createPost", input, z.object({ id: FluxioPostIdSchema }))
  }

  deletePost(postId: FluxioPostId): Promise<void> {
    return deleteFluxioPost(postId, this.profileName, this._token)
  }

  viewDocument(viewId: ViewId): Promise<{ posts: PostTree[] }> {
    const input: Json = { profileName: this.profileNameT, viewId: viewId }
    return this.proxied("viewDocument", input, z.object({ posts: z.array(PostTreeSchema) }))
  }

  listViews(): Promise<{ views: DocumentView[] }> {
    return this.proxied(
      "listViews",
      { profileName: this.profileNameT },
      z.object({ views: z.array(DocumentViewSchema) })
    )
  }
}

export interface Post1 {
  id: IndexedId
  tags_profile?: Array<string>
  title: string
  message: string
  description: string
  url: string
  picture_orig?: string
  sourceId: FluxioPostId
}
//cI = form renderiza +
export type ContextInput =
  | { type: "post"; value: Post1 }
  | { type: "manual"; value: { title: string; description: string } }

// SelectContextInput no backend
export type ContextV =
  | { type: "post"; value: FluxioPostId }
  | { type: "manual"; value: { title: string; description: string } }

// like ServerContent
export interface ContentLibraryResponse {
  hasMore: boolean
  nextCursor?: string
  items: Post1[]
  media?: { [x: string]: MediaObject }
}

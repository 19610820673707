import { isRight } from "fp-ts/lib/Either"
import {
  type,
  union,
  Type,
  TypeOf,
  intersection,
  null as nullC,
  number as numberC,
  partial,
  boolean as booleanC,
  literal,
} from "io-ts"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { valueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { get2WithNull, get2WithNull4Enable, get3WithNull4Enable, showIfWritten } from "../bobUtils"
import { decoderErrors } from "../codec/codecUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const BMarginPropsCodec = type({
  top: union([numberC, literal("auto")]),
  bottom: union([numberC, literal("auto")]),
  left: union([numberC, literal("auto")]),
  right: union([numberC, literal("auto")]),
})
const BPaddingPropsCodec = type({
  top: numberC,
  bottom: numberC,
  left: numberC,
  right: numberC,
})
const BoundaryPropsCodec = type({
  margin: BMarginPropsCodec,
  padding: BPaddingPropsCodec,
})

const BoundaryCodec = intersection([type({ enable: booleanC }), BoundaryPropsCodec])

const BMarginPropsOptCodec = partial({
  top: nullable(union([numberC, literal("auto")])),
  bottom: nullable(union([numberC, literal("auto")])),
  left: nullable(union([numberC, literal("auto")])),
  right: nullable(union([numberC, literal("auto")])),
})
const BPaddingPropsOptCodec = partial({
  top: nullable(numberC),
  bottom: nullable(numberC),
  left: nullable(numberC),
  right: nullable(numberC),
})
const BoundaryPropsOptCodec = partial({
  margin: BMarginPropsOptCodec,
  padding: BPaddingPropsOptCodec,
})

const BoundaryOptCodec = intersection([partial({ enable: nullable(booleanC) }), BoundaryPropsOptCodec])

const StylesBoundaryCodec = intersection([
  type({ spacing: BoundaryCodec }),
  partial({
    behaviour: partial({
      active: partial({
        spacing: BoundaryOptCodec,
      }),
      hover: partial({
        spacing: BoundaryOptCodec,
      }),
    }),
    mobile: partial({
      spacing: BoundaryOptCodec,
      behaviour: partial({
        active: partial({
          spacing: BoundaryOptCodec,
        }),
        hover: partial({
          spacing: BoundaryOptCodec,
        }),
      }),
    }),
    tablet: partial({
      spacing: BoundaryOptCodec,
      behaviour: partial({
        active: partial({
          spacing: BoundaryOptCodec,
        }),
        hover: partial({
          spacing: BoundaryOptCodec,
        }),
      }),
    }),
  }),
])

const GSBoundaryCodec = intersection([
  BoundaryPropsCodec,
  partial({
    behaviour: partial({
      active: BoundaryPropsOptCodec,
      hover: BoundaryPropsOptCodec,
    }),
    mobile: intersection([
      BoundaryPropsOptCodec,
      partial({
        behaviour: partial({
          active: BoundaryPropsOptCodec,
          hover: BoundaryPropsOptCodec,
        }),
      }),
    ]),
    tablet: intersection([
      BoundaryPropsOptCodec,
      partial({
        behaviour: partial({
          active: BoundaryPropsOptCodec,
          hover: BoundaryPropsOptCodec,
        }),
      }),
    ]),
  }),
])

type Boundary = TypeOf<typeof BoundaryCodec>
type BoundaryProps = TypeOf<typeof BoundaryPropsCodec>
type BoundaryOpt = TypeOf<typeof BoundaryOptCodec>
type BoundaryPropsOpt = TypeOf<typeof BoundaryPropsOptCodec>
type StylesBoundary = TypeOf<typeof StylesBoundaryCodec>
type GSBoundary = TypeOf<typeof GSBoundaryCodec>
type BoundaryCSS = {
  "margin-top": string
  "margin-right": string
  "margin-bottom": string
  "margin-left": string
  "padding-top": string
  "padding-right": string
  "padding-bottom": string
  "padding-left": string
}

export function responsiveStyle(boundaryObj: any) {
  if (boundaryObj.hasOwnProperty("enable") && !boundaryObj.enable)
    return {
      "margin-top": "0 !important",
      "margin-right": "0 !important",
      "margin-bottom": "0 !important",
      "margin-left": "0 !important",
      "padding-top": "0 !important",
      "padding-right": "0 !important",
      "padding-bottom": "0 !important",
      "padding-left": "0 !important",
    }
  let boundaryStyle = {}
  if (boundaryObj.margin) {
    for (let side in boundaryObj.margin) {
      boundaryStyle = {
        ...boundaryStyle,
        [`margin-${side}`]: boundaryObj.margin[side] + "px !important",
      }
    }
  }
  if (boundaryObj.padding) {
    for (let side in boundaryObj.padding) {
      boundaryStyle = {
        ...boundaryStyle,
        [`padding-${side}`]: boundaryObj.padding[side] + "px !important",
      }
    }
  }
  return boundaryStyle
}

export function renderCSSString(boundaryStyle: BoundaryCSS | {}): string {
  return `
    ${showIfWritten(boundaryStyle, "margin-bottom")} 
    ${showIfWritten(boundaryStyle, "margin-top")} 
    ${showIfWritten(boundaryStyle, "margin-left")} 
    ${showIfWritten(boundaryStyle, "margin-right")} 
    ${showIfWritten(boundaryStyle, "padding-bottom")} 
    ${showIfWritten(boundaryStyle, "padding-top")} 
    ${showIfWritten(boundaryStyle, "padding-left")} 
    ${showIfWritten(boundaryStyle, "padding-right")} 
    `
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): BoundaryCSS | {} {
  const styles = StylesBoundaryCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(`BoundaryStyle ${breakpoint} ${behaviourState} ${decoderErrors(styles)}`)
  return {}
}

export function globalStyleCssRenderUnsafe(
  gsObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): BoundaryCSS | {} {
  const gs = GSBoundaryCodec.decode(gsObj)
  if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState)
  console.warn(`BoundaryGSStyle ${breakpoint} ${behaviourState} ${decoderErrors(gs)}`)
  return {}
}

export function globalStyleCssRender(
  stylesObj: GSBoundary,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): BoundaryCSS | {} {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj)
    }
    //hover | active
    else {
      return renderOpt(stylesObj?.behaviour?.[behaviourState])
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderOpt(stylesObj?.[breakpoint])
    }
    //hover | active
    else {
      return renderOpt(stylesObj?.[breakpoint]?.behaviour?.[behaviourState])
    }
  }
}

export function cssRender(
  stylesObj: StylesBoundary,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): BoundaryCSS | {} {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.spacing)
    }
    //hover | active
    else {
      return renderBobOpt(mergeBob2(stylesObj?.behaviour?.[behaviourState]?.spacing, stylesObj.spacing))
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(mergeBob2(stylesObj?.[breakpoint]?.spacing, stylesObj.spacing))
    }
    //hover | active
    else {
      return renderBobOpt(
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.spacing,
          stylesObj?.behaviour?.[behaviourState]?.spacing,
          stylesObj?.[breakpoint]?.spacing,
          stylesObj.spacing
        )
      )
    }
  }
}

export function mergeBob3(
  boundaryObj: BoundaryOpt | undefined,
  boundaryDesktopBehaviour: BoundaryOpt | undefined,
  boundaryDefaultBreakpoint: BoundaryOpt | undefined,
  defaultBoundaryObj: Boundary
): BoundaryOpt {
  const enable = get3WithNull4Enable(
    boundaryObj?.enable,
    boundaryDesktopBehaviour?.enable,
    boundaryDefaultBreakpoint?.enable,
    defaultBoundaryObj.enable
  )

  return {
    enable,
    margin: boundaryObj?.margin,
    padding: boundaryObj?.padding,
  }
}

export function merge3(
  boundaryObj: BoundaryPropsOpt | undefined,
  boundaryDesktopBehaviour: BoundaryPropsOpt | undefined,
  boundaryDefaultBreakpoint: BoundaryPropsOpt | undefined,
  defaultBoundaryObj: BoundaryProps
): BoundaryProps {
  const mTop =
    boundaryObj?.margin?.top ??
    boundaryDesktopBehaviour?.margin?.top ??
    boundaryDefaultBreakpoint?.margin?.top ??
    defaultBoundaryObj.margin.top
  const mBottom =
    boundaryObj?.margin?.bottom ??
    boundaryDesktopBehaviour?.margin?.bottom ??
    boundaryDefaultBreakpoint?.margin?.bottom ??
    defaultBoundaryObj.margin.bottom
  const mLeft =
    boundaryObj?.margin?.left ??
    boundaryDesktopBehaviour?.margin?.left ??
    boundaryDefaultBreakpoint?.margin?.left ??
    defaultBoundaryObj.margin.left
  const mRight =
    boundaryObj?.margin?.right ??
    boundaryDesktopBehaviour?.margin?.right ??
    boundaryDefaultBreakpoint?.margin?.right ??
    defaultBoundaryObj.margin.right
  const pTop =
    boundaryObj?.padding?.top ??
    boundaryDesktopBehaviour?.padding?.top ??
    boundaryDefaultBreakpoint?.padding?.top ??
    defaultBoundaryObj.padding.top
  const pBottom =
    boundaryObj?.padding?.bottom ??
    boundaryDesktopBehaviour?.padding?.bottom ??
    boundaryDefaultBreakpoint?.padding?.bottom ??
    defaultBoundaryObj.padding.bottom
  const pLeft =
    boundaryObj?.padding?.left ??
    boundaryDesktopBehaviour?.padding?.left ??
    boundaryDefaultBreakpoint?.padding?.left ??
    defaultBoundaryObj.padding.left
  const pRight =
    boundaryObj?.padding?.right ??
    boundaryDesktopBehaviour?.padding?.right ??
    boundaryDefaultBreakpoint?.padding?.right ??
    defaultBoundaryObj.padding.right

  return {
    margin: {
      top: mTop,
      bottom: mBottom,
      left: mLeft,
      right: mRight,
    },
    padding: {
      top: pTop,
      bottom: pBottom,
      left: pLeft,
      right: pRight,
    },
  }
}

export function mergeBob2(boundaryObj: BoundaryOpt | undefined, defaultBoundaryObj: Boundary): BoundaryOpt {
  const enable = get2WithNull4Enable(boundaryObj?.enable, defaultBoundaryObj.enable)

  return {
    enable,
    margin: boundaryObj?.margin,
    padding: boundaryObj?.padding,
  }
}

export function merge2(boundaryObj: BoundaryPropsOpt | undefined, defaultBoundaryObj: BoundaryProps): BoundaryProps {
  const mTop = get2WithNull(boundaryObj?.margin?.top, defaultBoundaryObj.margin.top)
  const mBottom = get2WithNull(boundaryObj?.margin?.bottom, defaultBoundaryObj.margin.bottom)
  const mRight = get2WithNull(boundaryObj?.margin?.right, defaultBoundaryObj.margin.right)
  const mLeft = get2WithNull(boundaryObj?.margin?.left, defaultBoundaryObj.margin.left)
  const pTop = get2WithNull(boundaryObj?.padding?.top, defaultBoundaryObj.padding.top)
  const pBottom = get2WithNull(boundaryObj?.padding?.bottom, defaultBoundaryObj.padding.bottom)
  const pRight = get2WithNull(boundaryObj?.padding?.right, defaultBoundaryObj.padding.right)
  const pLeft = get2WithNull(boundaryObj?.padding?.left, defaultBoundaryObj.padding.left)

  return {
    margin: {
      top: mTop,
      bottom: mBottom,
      left: mLeft,
      right: mRight,
    },
    padding: {
      top: pTop,
      bottom: pBottom,
      left: pLeft,
      right: pRight,
    },
  }
}

export function renderBob(boundaryObj: Boundary): BoundaryCSS {
  if (boundaryObj.enable === false)
    return {
      "margin-top": "0px !important",
      "margin-right": "0px !important",
      "margin-bottom": "0px !important",
      "margin-left": "0px !important",
      "padding-top": "0px !important",
      "padding-right": "0px !important",
      "padding-bottom": "0px !important",
      "padding-left": "0px !important",
    }

  return render(boundaryObj)
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param colorsObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(boundaryObj: BoundaryOpt | undefined): BoundaryCSS | {} {
  if (boundaryObj?.enable === false)
    return {
      "margin-top": "0px !important",
      "margin-right": "0px !important",
      "margin-bottom": "0px !important",
      "margin-left": "0px !important",
      "padding-top": "0px !important",
      "padding-right": "0px !important",
      "padding-bottom": "0px !important",
      "padding-left": "0px !important",
    }

  if (boundaryObj?.enable === true) {
    return renderOpt(boundaryObj)
  }

  return {}
}

export function render(boundaryObj: BoundaryProps): BoundaryCSS {
  return {
    "margin-top": `${boundaryObj.margin.top}px !important`,
    "margin-right": `${boundaryObj.margin.right}px !important`,
    "margin-bottom": `${boundaryObj.margin.bottom}px !important`,
    "margin-left": `${boundaryObj.margin.left}px !important`,
    "padding-top": `${boundaryObj.padding.top}px !important`,
    "padding-right": `${boundaryObj.padding.right}px !important`,
    "padding-bottom": `${boundaryObj.padding.bottom}px !important`,
    "padding-left": `${boundaryObj.padding.left}px !important`,
  }
}

/**
 * Renders ColorOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param colorValue
 * @param foundationStyle
 * @returns
 */
export function renderOpt(boundaryObj: BoundaryPropsOpt | undefined): BoundaryCSS | {} {
  const margin = boundaryObj?.margin
  const padding = boundaryObj?.padding

  let css = {}
  if (valueExists(margin?.top)) css = { ...css, "margin-top": `${margin?.top}px !important` }
  if (valueExists(margin?.bottom)) css = { ...css, "margin-bottom": `${margin?.bottom}px !important` }
  if (valueExists(margin?.left)) css = { ...css, "margin-left": `${margin?.left}px !important` }
  if (valueExists(margin?.right)) css = { ...css, "margin-right": `${margin?.right}px !important` }

  if (valueExists(padding?.top)) css = { ...css, "padding-top": `${padding?.top}px !important` }
  if (valueExists(padding?.bottom)) css = { ...css, "padding-bottom": `${padding?.bottom}px !important` }
  if (valueExists(padding?.left)) css = { ...css, "padding-left": `${padding?.left}px !important` }
  if (valueExists(padding?.right)) css = { ...css, "padding-right": `${padding?.right}px !important` }

  return css
}

export type { BoundaryCSS, StylesBoundary, GSBoundary, BoundaryProps, BoundaryPropsOpt, Boundary, BoundaryOpt }

export { StylesBoundaryCodec, GSBoundaryCodec }

import { Validation } from "io-ts"
import { pipe } from "fp-ts/function"
import { fold } from "fp-ts/Either"

const decoderErrors = <A>(v: Validation<A>): Array<string> => {
  return pipe(
    v,
    fold(
      (errors) =>
        errors.map((error) => {
          const last = error.context[error.context.length - 1]
          const expecting = last.type.name
          const got = last.actual
          const path = error.context.map(({ key }) => key).join(".")
          const message = `${path}: expecting ${expecting}, got ${got}`
          return message
        }),
      () => []
    )
  )
}

export { decoderErrors }

import * as types from "../shared-store/actions/actionTypes"
import initialState from "../initialState"

export default function sessionReducer(state = initialState.session, action: any) {
  switch (action.type) {
    case types.LOGGING_IN:
      return {
        ...state,
        loading: !state.loading,
        error: undefined,
        msg: action.msg,
      }
    case types.CREDENTIALS_TOKEN:
      return {
        ...state,
        token: action.token,
        msg: action.msg,
      }
    case types.UNVERIFIED_USER_INFO:
      return {
        ...state,
        unverifiedInfo: action.unverifiedInfo,
        msg: action.msg,
      }
    case types.VERIFIED_USER_INFO:
      return {
        ...state,
        verifiedInfo: action.verifiedInfo,
        loading: !state.loading,
      }
    case types.CLEAR_VERIFIED_USER_INFO:
      return {
        ...state,
        verifiedInfo: undefined,
        loading: false,
      }
    case types.LOGIN_ERROR:
      return {
        ...state,
        verifiedInfo: undefined,
        error: action.error,
        loading: false,
      }
    case types.SUCCESSFUL_REGISTER:
      return {
        ...state,
        loading: false,
        registered: true,
        msg: action.msg,
      }
    case types.REGISTERING:
      return {
        ...state,
        loading: true,
        errorRegister: false,
        registered: false,
        msg: action.msg,
      }
    case types.REGISTER_ERROR:
      return {
        ...state,
        errorRegister: action.error,
        registered: false,
        loading: false,
      }
    case types.REQUEST_CONFIRM_ACOUNT:
      return {
        ...state,
        confirmAccountSuccess: undefined,
        confirmAccountLoading: true,
        confirmAccountError: false,
      }
    case types.RECEIVE_ACOUNT_SUCCESS:
      return {
        ...state,
        confirmAccountSuccess: action.response,
        confirmAccountLoading: false,
        confirmAccountError: false,
      }
    case types.INVALID_ACOUNT_CONFIRMATION:
      return {
        ...state,
        confirmAccountSuccess: undefined,
        confirmAccountLoading: false,
        confirmAccountError: action.error,
      }
    case types.CLEAR_LOGIN_ERROR:
      return {
        ...state,
        errorRegister: undefined,
        error: undefined,
      }
    case types.SOCIAL_CHECK_ERROR:
      return {
        ...state,
        checkedSocial: false,
        checkingSocial: false,
        checkSocialError: action.error,
      }
    case types.SOCIAL_CHECKING:
      return {
        ...state,
        checkedSocial: false,
        checkingSocial: true,
        checkSocialError: false,
      }
    case types.SOCIAL_CHECKED:
      return {
        ...state,
        checkedSocial: action.response,
        checkingSocial: false,
        checkSocialError: false,
      }
    case types.VALIDATE_SOCIAL_FACEBOOK_ERROR:
      return {
        ...state,
        validateFacebookError: action.error,
        validatingSocialFacebook: false,
        validatedSocialFacebook: false,
      }
    case types.VALIDATING_SOCIAL_FACEBOOK:
      return {
        ...state,
        validateFacebookError: false,
        validatingSocialFacebook: true,
        validatedSocialFacebook: false,
      }
    case types.VALIDATE_SOCIAL_FACEBOOK_SUCCESS:
      return {
        ...state,
        validateFacebookError: false,
        validatingSocialFacebook: false,
        validatedSocialFacebook: action.info,
      }

    case types.VALIDATE_SOCIAL_TWITTER_ERROR:
      return {
        ...state,
        validateTwitterError: action.error,
        validatingSocialTwitter: false,
        validatedSocialTwitter: false,
      }
    case types.VALIDATING_SOCIAL_TWITTER:
      return {
        ...state,
        validateTwitterError: false,
        validatingSocialTwitter: true,
        validatedSocialTwitter: false,
      }
    case types.VALIDATE_SOCIAL_TWITTER_SUCCESS:
      return {
        ...state,
        validateTwitterError: false,
        validatingSocialTwitter: false,
        validatedSocialTwitter: action.info,
      }

    case types.VALIDATE_SOCIAL_GOOGLE_ERROR:
      return {
        ...state,
        validateGoogleError: action.error,
        validatingSocialGoogle: false,
        validatedSocialGoogle: false,
      }
    case types.VALIDATING_GOOGLE_FACEBOOK:
      return {
        ...state,
        validateGoogleError: false,
        validatingSocialGoogle: true,
        validatedSocialGoogle: false,
      }
    case types.VALIDATE_SOCIAL_GOOGLE_SUCCESS:
      return {
        ...state,
        validateGoogleError: false,
        validatingSocialGoogle: false,
        validatedSocialGoogle: action.info,
      }

    case types.DELETE_SOCIAL_ERROR:
      return {
        ...state,
        deleteSocialError: action.error,
        deletingSocial: false,
        deletedSocial: false,
        deleteNetwork: action.network,
      }
    case types.DELETING_SOCIAL:
      return {
        ...state,
        deleteSocialError: false,
        deletingSocial: true,
        deletedSocial: false,
        deleteNetwork: action.network,
      }
    case types.DELETE_SOCIAL_SUCCESS:
      return {
        ...state,
        deleteSocialError: false,
        deletingSocial: false,
        deletedSocial: action.info,
        deleteNetwork: action.network,
      }
    case types.CLEAR_SOCIAL_ACTIONS:
      return {
        ...state,
        validateFacebookError: false,
        validatedSocialFacebook: false,
        validateTwitterError: false,
        validatedSocialTwitter: false,
        validateGoogleError: false,
        validatedSocialGoogle: false,
        deletedSocial: false,
        deleteNetwork: false,
      }
    case types.ERROR_REQUEST_ALL_ADMINS:
      return {
        ...state,
        errorAdmins: action.error,
        loadingAdmins: false,
        adminsList: state.adminsList,
      }
    case types.REQUEST_ALL_ADMINS:
      return {
        ...state,
        errorAdmins: false,
        loadingAdmins: true,
        adminsList: state.adminsList,
      }
    case types.SUCCESS_REQUEST_ALL_ADMINS:
      return {
        ...state,
        errorAdmins: undefined,
        loadingAdmins: false,
        adminsList: action.response,

        errorDeleteAdmin: undefined,
        loadingDeleteAdmin: false,
        adminDeleted: undefined,
      }
    case types.ERROR_SEARCH_ADMINS:
      return {
        ...state,
        errorSearchAdmins: action.error,
        loadingSearchAdmins: false,
        adminsSearch: undefined,
      }
    case types.REQUEST_SEARCH_ADMINS:
      return {
        ...state,
        errorSearchAdmins: false,
        loadingSearchAdmins: true,
        adminsSearch: undefined,
      }
    case types.SUCCESS_SEARCH_ADMINS:
      return {
        ...state,
        errorSearchAdmins: false,
        loadingSearchAdmins: false,
        adminsSearch: action.response,
      }
    case types.CLEAR_SEARCH_ADMINS:
      return {
        ...state,
        errorSearchAdmins: false,
        loadingSearchAdmins: false,
        adminsSearch: undefined,
      }
    case types.ERROR_ADD_ADMIN:
      return {
        ...state,
        errorAddAdmin: action.error,
        loadingAddAdmin: false,
        adminAdded: undefined,
      }
    case types.REQUEST_ADD_ADMIN:
      return {
        ...state,
        errorAddAdmin: false,
        loadingAddAdmin: true,
        adminAdded: undefined,
      }
    case types.SUCCESS_ADD_ADMIN:
      return {
        ...state,
        errorAddAdmin: undefined,
        loadingAddAdmin: false,
        adminAdded: action.response,
      }
    case types.CLEAR_ADD_ADMIN:
      return {
        ...state,
        errorAddAdmin: undefined,
        loadingAddAdmin: false,
        adminAdded: undefined,
      }
    case types.ERROR_DELETE_ADMIN:
      return {
        ...state,
        errorDeleteAdmin: action.error,
        loadingDeleteAdmin: false,
        adminDeleted: undefined,
      }
    case types.REQUEST_DELETE_ADMIN:
      return {
        ...state,
        errorDeleteAdmin: false,
        loadingDeleteAdmin: true,
        adminDeleted: undefined,
      }
    case types.SUCCESS_DELETE_ADMIN:
      return {
        ...state,
        errorDeleteAdmin: undefined,
        loadingDeleteAdmin: false,
        adminDeleted: action.response,
      }
    case types.ERROR_USER_INFO:
      return {
        ...state,
        errorUserInfo: action.error,
        loadingUserInfo: false,
        userInfo: undefined,
      }
    case types.REQUEST_USER_INFO:
      return {
        ...state,
        errorUserInfo: false,
        loadingUserInfo: true,
        userInfo: undefined,
      }
    case types.SUCCESS_USER_INFO:
      return {
        ...state,
        errorUserInfo: undefined,
        loadingUserInfo: false,
        userInfo: action.response,
      }
    case types.CLEAR_USER_INFO:
      return {
        ...state,
        errorUserInfo: undefined,
        loadingUserInfo: false,
        userInfo: undefined,
      }
    case types.ERROR_USER_FIRST_LOGIN:
      return {
        ...state,
        errorUserInfo: action.error,
        loadingUserInfo: false,
        userFirstLogin: undefined,
      }
    case types.REQUEST_USER_FIRST_LOGIN:
      return {
        ...state,
        errorUserInfo: false,
        loadingUserInfo: true,
        userFirstLogin: undefined,
      }
    case types.SUCCESS_USER_FIRST_LOGIN:
      return {
        ...state,
        errorUserInfo: undefined,
        loadingUserInfo: false,
        userInfo: action.response,
        userFirstLogin: true,
      }
    case types.CLEAR_USER_FIRST_LOGIN:
      return {
        ...state,
        errorUserInfo: undefined,
        loadingUserInfo: false,
        userInfo: undefined,
        userFirstLogin: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.LOADING_RESET_PASSWORD:
      return {
        ...state,
        loadingResetPassword: true,
        errorResetPassword: false,
        successResetPassword: undefined,
      }
    case types.SUCCESS_RESET_PASSWORD:
      return {
        ...state,
        loadingResetPassword: false,
        errorResetPassword: false,
        successResetPassword: action.response,
      }
    case types.FAILED_RESET_PASSWORD:
      return {
        ...state,
        loadingResetPassword: false,
        errorResetPassword: action.error,
        successResetPassword: undefined,
      }
    case types.CLEAR_RESET_PASSWORD:
      return {
        ...state,
        loadingResetPassword: false,
        errorResetPassword: false,
        successResetPassword: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.LOADING_CREATE_PASSWORD:
      return {
        ...state,
        loadingCreatePassword: true,
        errorCreatePassword: false,
        successCreatePassword: undefined,
      }
    case types.SUCCESS_CREATE_PASSWORD:
      return {
        ...state,
        loadingCreatePassword: false,
        errorCreatePassword: false,
        successCreatePassword: action.response,
      }
    case types.FAILED_CREATE_PASSWORD:
      return {
        ...state,
        loadingCreatePassword: false,
        errorCreatePassword: action.error,
        successCreatePassword: undefined,
      }
    case types.CLEAR_CREATE_PASSWORD:
      return {
        ...state,
        loadingCreatePassword: false,
        errorCreatePassword: false,
        successCreatePassword: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.LOADING_GET_REGISTER_LIMIT:
      return {
        ...state,
        loadingGetRegisterLimit: true,
        errorGetRegisterLimit: false,
        successGetRegisterLimit: undefined,
      }
    case types.SUCCESS_GET_REGISTER_LIMIT:
      return {
        ...state,
        loadingGetRegisterLimit: false,
        errorGetRegisterLimit: false,
        successGetRegisterLimit: action.response,
      }
    case types.ERROR_GET_REGISTER_LIMIT:
      return {
        ...state,
        loadingGetRegisterLimit: false,
        errorGetRegisterLimit: action.error,
        successGetRegisterLimit: undefined,
      }
    case types.CLEAR_GET_REGISTER_LIMIT:
      return {
        ...state,
        loadingGetRegisterLimit: false,
        errorGetRegisterLimit: false,
        successGetRegisterLimit: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.LOADING_GOOGLE_LOGIN:
      return {
        ...state,
        loadingGoogleLogin: true,
        errorGoogleLogin: false,
        successGoogleLogin: undefined,
      }
    case types.SUCCESS_GOOGLE_LOGIN:
      return {
        ...state,
        loadingGoogleLogin: false,
        errorGoogleLogin: false,
        successGoogleLogin: action.response,
      }
    case types.ERROR_GOOGLE_LOGIN:
      return {
        ...state,
        loadingGoogleLogin: false,
        errorGoogleLogin: action.error,
        successGoogleLogin: undefined,
      }
    case types.CLEAR_GOOGLE_LOGIN:
      return {
        ...state,
        loadingGoogleLogin: false,
        errorGoogleLogin: false,
        successGoogleLogin: undefined,
      }
    default:
      return state
  }
}

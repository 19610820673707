import { isRight } from "fp-ts/lib/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { valueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { showIfWritten } from "../bobUtils"
import { decoderErrors } from "../codec/codecUtils"
import {
  mergeBob2,
  mergeBob3,
  BoundaryCSS,
  GSBoundary,
  StylesBoundary,
  BoundaryOpt,
  Boundary,
  BoundaryPropsOpt,
  BoundaryProps,
  StylesBoundaryCodec,
  GSBoundaryCodec,
} from "./boundaryStyle"

export function renderCSSString(boundaryStyle: Partial<BoundaryCSS>): string {
  return `
    ${showIfWritten(boundaryStyle, "margin-bottom")} 
    ${showIfWritten(boundaryStyle, "margin-top")} 
    ${showIfWritten(boundaryStyle, "margin-left")} 
    ${showIfWritten(boundaryStyle, "margin-right")} 
    ${showIfWritten(boundaryStyle, "padding-bottom")} 
    ${showIfWritten(boundaryStyle, "padding-top")} 
    ${showIfWritten(boundaryStyle, "padding-left")} 
    ${showIfWritten(boundaryStyle, "padding-right")} 
    `
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<BoundaryCSS> {
  const styles = StylesBoundaryCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(`BoundaryStyle ${breakpoint} ${behaviourState} ${decoderErrors(styles)}`)
  return {}
}

export function globalStyleCssRenderUnsafe(
  gsObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<BoundaryCSS> {
  const gs = GSBoundaryCodec.decode(gsObj)
  if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState)
  console.warn(`BoundaryGSStyle ${breakpoint} ${behaviourState} ${decoderErrors(gs)}`)
  return {}
}

export function globalStyleCssRender(
  stylesObj: GSBoundary,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<BoundaryCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj)
    }
    //hover | active
    else {
      return renderOpt(stylesObj?.behaviour?.[behaviourState])
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderOpt(stylesObj?.[breakpoint])
    }
    //hover | active
    else {
      return renderOpt(stylesObj?.[breakpoint]?.behaviour?.[behaviourState])
    }
  }
}

export function cssRender(
  stylesObj: StylesBoundary,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<BoundaryCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.spacing)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.spacing,
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.spacing, stylesObj.spacing)
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.spacing, mergeBob2(stylesObj?.[breakpoint]?.spacing, stylesObj.spacing))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.spacing,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.spacing,
          stylesObj?.behaviour?.[behaviourState]?.spacing,
          stylesObj?.[breakpoint]?.spacing,
          stylesObj.spacing
        )
      )
    }
  }
}

export function renderBob(boundaryObj: Boundary): Partial<BoundaryCSS> {
  if (boundaryObj.enable === false) return {}

  return render(boundaryObj)
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param colorsObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(defaultBoundaryObj: Boundary, boundaryObj: BoundaryOpt | undefined): Partial<BoundaryCSS> {
  if (boundaryObj?.enable === false) {
    if (defaultBoundaryObj.enable) {
      return {
        "margin-top": `0px`,
        "margin-right": `0px`,
        "margin-bottom": `0px`,
        "margin-left": `0px`,
        "padding-top": `0px`,
        "padding-right": `0px`,
        "padding-bottom": `0px`,
        "padding-left": `0px`,
      }
    }

    return {}
  }

  if (boundaryObj?.enable === true) return renderOpt(boundaryObj)

  return {}
}

export function render(boundaryObj: BoundaryProps): BoundaryCSS {
  return {
    "margin-top": `${isNumberOrAuto(boundaryObj.margin.top)}`,
    "margin-right": `${isNumberOrAuto(boundaryObj.margin.right)}`,
    "margin-bottom": `${isNumberOrAuto(boundaryObj.margin.bottom)}`,
    "margin-left": `${isNumberOrAuto(boundaryObj.margin.left)}`,
    "padding-top": `${boundaryObj.padding.top}px`,
    "padding-right": `${boundaryObj.padding.right}px`,
    "padding-bottom": `${boundaryObj.padding.bottom}px`,
    "padding-left": `${boundaryObj.padding.left}px`,
  }
}

/**
 * Renders ColorOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param colorValue
 * @param foundationStyle
 * @returns
 */
export function renderOpt(boundaryObj: BoundaryPropsOpt | undefined): Partial<BoundaryCSS> {
  const margin = boundaryObj?.margin
  const padding = boundaryObj?.padding

  let css = {}
  if (valueExists(margin?.top)) css = { ...css, "margin-top": `${isNumberOrAuto(margin?.top)}` }
  if (valueExists(margin?.bottom)) css = { ...css, "margin-bottom": `${isNumberOrAuto(margin?.bottom)}` }
  if (valueExists(margin?.left)) css = { ...css, "margin-left": `${isNumberOrAuto(margin?.left)}` }
  if (valueExists(margin?.right)) css = { ...css, "margin-right": `${isNumberOrAuto(margin?.right)}` }

  if (valueExists(padding?.top)) css = { ...css, "padding-top": `${padding?.top}px` }
  if (valueExists(padding?.bottom)) css = { ...css, "padding-bottom": `${padding?.bottom}px` }
  if (valueExists(padding?.left)) css = { ...css, "padding-left": `${padding?.left}px` }
  if (valueExists(padding?.right)) css = { ...css, "padding-right": `${padding?.right}px` }

  return css
}

/**
 *
 * @param x
 * returns with px if its number else just the string auto, or empty
 */
export function isNumberOrAuto(x: number | "auto" | undefined | null): string {
  if (typeof x === "number") {
    return `${x}px`
  }
  return `${x}`
}

export type { BoundaryCSS, StylesBoundary, GSBoundary, BoundaryProps, BoundaryPropsOpt, Boundary, BoundaryOpt }

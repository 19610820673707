import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import { getTokenAndWorkspace, mkAppRedirect } from "../../lib/hooks/useWorkspace"
import "../../stylesheets/eina/_eina_font.scss"
import "../../stylesheets/main.scss"
import { AppNavigation } from "./sidebar/DoJobBoard"
import { ApiError, ApiErrorModule } from "../../lib/jsonRequest/ApiError"
import { ShowApiError } from "./sidebar/ShowApiError"

type RedirectProps = RouteComponentProps<RouteParams>

interface RouteParams {
  profile: string
}

/*
class RedirectApp extends React.Component<RedirectProps> {
  componentDidMount() {
    this.props.history.push(`/t/${this.props.match.params.profile}/dojob`)
  }

  render() {
    return <div></div>
  }
}
*/

export const RedirectApp: React.FC<RedirectProps> = (props) => {
  const profileName = props.match.params.profile
  const [error, setError] = useState<ApiError | undefined>(undefined)

  useEffect(() => {
    getTokenAndWorkspace(profileName)
      .then((e) => mkAppRedirect(props.history).redirect(AppNavigation.conditionalAppLink(e.ws)))
      .catch((e) => {
        if (ApiErrorModule.isApiError(e)) setError(e)
        else setError({ error: "Unknown error" })
      })
  }, [profileName, props.history])

  if (error) return <ShowApiError error={error} />
  return <></>
}

export default RedirectApp

// export default withRouter(RedirectApp)

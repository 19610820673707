import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function breadcrumbReducer(state = initialState.breadcrumbReducer, action) {
  switch (action.type) {
    case types.ADD_BREADCRUMB:
      return {
        ...state,
        breadcrumbs: [...state.breadcrumbs, action.breadcrumb]
      }
    case types.REMOVE_BREADCRUMB:
      state.breadcrumbs.length = action.index
      return {
        ...state,
        breadcrumbs: [...state.breadcrumbs],
        index: action.index
      }
    case types.HOME_BREADCRUMB:
      return {
        ...state,
        breadcrumbs: []
      }
    case types.SELECTED_FORMULA:
      return {
        ...state,
        selectedFormula: action.formula
      }
    case types.CLEAR_SELECTED_FORMULA:
      return {
        ...state,
        selectedFormula: false,
        clearId: action.id
      }
    case types.CLEAR_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: []
      }
    default:
      return state
  }
}

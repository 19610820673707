import * as types from "../shared-store/actions/actionTypes"
import sessionApi from "../../api/SessionApi"
import cookie from "react-cookies"
import { Dispatch } from "redux"

export function loggingIn() {
  return {
    type: types.LOGGING_IN,
    msg: "fetching token",
  }
}

export function registeringUser() {
  return {
    type: types.REGISTERING,
    msg: "Registering User",
  }
}

export function registeringSuccessful(response: any) {
  return {
    type: types.SUCCESSFUL_REGISTER,
    msg: "Register Successful",
    response,
  }
}

export function getToken(json: any) {
  return {
    type: types.CREDENTIALS_TOKEN,
    token: json,
    msg: "verifying user",
  }
}

export function unverifiedUserInfo(json: any) {
  return {
    type: types.UNVERIFIED_USER_INFO,
    unverifiedInfo: json,
    msg: "verifying user",
  }
}

export function verifiedUserInfo(json: any) {
  return {
    type: types.VERIFIED_USER_INFO,
    verifiedInfo: json,
  }
}

export function clearVerifiedUserInfo(json: any) {
  return {
    type: types.CLEAR_VERIFIED_USER_INFO,
    verifiedInfo: json,
  }
}

export function loginError(error: any) {
  return {
    type: types.LOGIN_ERROR,
    error: {
      status: error.status,
      msg: error.msg,
    },
  }
}

export function checkSocialError(error: any) {
  return {
    type: types.SOCIAL_CHECK_ERROR,
    error: error,
  }
}

export function checkingSocial() {
  return {
    type: types.SOCIAL_CHECKING,
  }
}

export function checkedSocial(response: any) {
  return {
    type: types.SOCIAL_CHECKED,
    response: response,
  }
}

export function checkExistingSocialPresence(profile: string, token: any) {
  return (dispatch: Dispatch) => {
    dispatch(checkingSocial())
    return sessionApi
      .checkSocialPresence(profile, token)
      .then((response) => {
        dispatch(checkedSocial(response))
      })
      .catch((error) => {
        dispatch(checkSocialError(error))
      })
  }
}

export function registerError(error: any) {
  return {
    type: types.REGISTER_ERROR,
    error,
  }
}

export function toValidateFacebook() {
  return {
    type: types.TO_VALIDATE_FACEBOOK,
  }
}

export function loginFacebook(info: any) {
  return {
    type: types.LOGIN_FACEBOOK,
    info: info,
  }
}

export function validateFacebook(credentials: any) {
  return (dispatch: Dispatch) => {
    dispatch(toValidateFacebook())
    sessionApi
      .facebookLogin(credentials)
      .then((response) => {
        dispatch(loginFacebook(response))
      })
      .catch((error) => {
        throw error
      })
  }
}

export function validateSocialGoogleError(error: any) {
  return {
    type: types.VALIDATE_SOCIAL_GOOGLE_ERROR,
    error: error,
  }
}

export function validatingSocialGoogle() {
  return {
    type: types.VALIDATING_GOOGLE_FACEBOOK,
  }
}

export function valid8SocialGoogle(info: any) {
  return {
    type: types.VALIDATE_SOCIAL_GOOGLE_SUCCESS,
    info: info,
  }
}

export function validateSocialGoogle(code: any, callback: any, scopes: any, token: any, profile: string) {
  return (dispatch: Dispatch) => {
    dispatch(validatingSocialGoogle())
    return sessionApi
      .validateSocialGoogle(code, callback, scopes, token, profile)
      .then((response) => {
        dispatch(valid8SocialGoogle(response))
      })
      .catch((error) => {
        dispatch(validateSocialGoogleError(error))
      })
  }
}

export function validateSocialFacebookError(error: any) {
  return {
    type: types.VALIDATE_SOCIAL_FACEBOOK_ERROR,
    error: error,
  }
}

export function validatingSocialFacebook() {
  return {
    type: types.VALIDATING_SOCIAL_FACEBOOK,
  }
}

export function valid8SocialFacebook(info: any) {
  return {
    type: types.VALIDATE_SOCIAL_FACEBOOK_SUCCESS,
    info: info,
  }
}

export function validateSocialFacebook(fbaccessTocken: any, profile: string, token: any) {
  return (dispatch: Dispatch) => {
    dispatch(validatingSocialFacebook())
    return sessionApi
      .validateSocialFacebook(fbaccessTocken, profile, token)
      .then((response) => {
        dispatch(valid8SocialFacebook(response))
      })
      .catch((error) => {
        dispatch(validateSocialFacebookError(error))
      })
  }
}

export function validateSocialTwitterError(error: any) {
  return {
    type: types.VALIDATE_SOCIAL_TWITTER_ERROR,
    error: error,
  }
}

export function validatingSocialTwitter() {
  return {
    type: types.VALIDATING_SOCIAL_TWITTER,
  }
}

export function valid8SocialTwitter(info: any) {
  return {
    type: types.VALIDATE_SOCIAL_TWITTER_SUCCESS,
    info: info,
  }
}

export function validateSocialTwitter(twaccessTocken: any, profile: string, token: any) {
  return (dispatch: Dispatch) => {
    dispatch(validatingSocialTwitter())
    return sessionApi
      .validateSocialTwitter(twaccessTocken, profile, token)
      .then((response) => {
        dispatch(valid8SocialTwitter(response))
      })
      .catch((error) => {
        dispatch(validateSocialTwitterError(error))
      })
  }
}

///

export function deleteSocialPresenceError(error: any, network: any) {
  return {
    type: types.DELETE_SOCIAL_ERROR,
    error: error,
    network: network,
  }
}

export function deletingSocialPresence(network: any) {
  return {
    type: types.DELETING_SOCIAL,
    network: network,
  }
}

export function valid8SocialPresence(info: any, network: any) {
  return {
    type: types.DELETE_SOCIAL_SUCCESS,
    info: info,
    network: network,
  }
}

export function removeSocialPresence(network: any, profile: string, token: any) {
  return (dispatch: Dispatch) => {
    dispatch(deletingSocialPresence(network))
    return sessionApi
      .removeSocialPresence(network, profile, token)
      .then((response) => {
        dispatch(valid8SocialPresence(response, network))
      })
      .catch((error) => {
        dispatch(deleteSocialPresenceError(error, network))
      })
  }
}

export function clearSocialActions() {
  return {
    type: types.CLEAR_SOCIAL_ACTIONS,
  }
}

export function registerUser(credentials: any) {
  return (dispatch: Dispatch) => {
    dispatch(registeringUser())
    sessionApi
      .registerValidate(credentials)
      .then((response: any) => {
        dispatch(registeringSuccessful(response))
      })
      .catch((error: any) => {
        dispatch(registerError(error))
        throw error
      })
  }
}

export function logInUser(credentials: any) {
  return (dispatch: Dispatch) => {
    dispatch(loggingIn())
    sessionApi
      .getToken(credentials)
      .then((response) => {
        //dispatch got token
        dispatch(getToken(response))
        let userInfo = response
        localStorage.setItem("userToken", JSON.stringify(userInfo))
        let encodedUserInfo = btoa(JSON.stringify(userInfo))
        cookie.save("_fluxio_user_token", encodedUserInfo, {
          path: "/",
          //@ts-ignore
          domain: `.${window.location.hostname}`,
          expires: new Date(Date.now() + userInfo.expires_in * 1000 - 3600000),
        })
        cookie.save("_fluxio_user_token", encodedUserInfo, {
          path: "/",
          //@ts-ignore
          domain: `.fluxio.cloud`,
          expires: new Date(Date.now() + userInfo.expires_in * 1000 - 3600000),
        })
        sessionApi
          .userInfo(userInfo)
          .then((response) => {
            //dispatch user info to validate
            dispatch(unverifiedUserInfo(response))
            let info = {
              uuid: response.UUID,
              idp: "meshapp",
              name: response.name,
              email: response.email,
              avatar: response.avatar,
            }
            sessionApi
              .personValidate(info, userInfo)
              .then((response) => {
                //save user info to cookie
                let encodedInfo = btoa(JSON.stringify(response))
                cookie.save("_fluxio_info", encodedInfo, {
                  path: "/",
                  //@ts-ignore
                  domain: `.${window.location.hostname}`,
                  expires: new Date(Date.now() + userInfo.expires_in * 1000 - 3600000),
                })
                //dispatch valid user info
                dispatch(verifiedUserInfo(response))
              })
              .catch((error) => {
                dispatch(loginError(error))
              })
          })
          .catch((error) => {
            dispatch(
              loginError(
                error.status === 403
                  ? { msg: "Your registration is unverified. Please go to your email account and verify it." }
                  : error
              )
            )
          })
      })
      .catch((error) => {
        if (error.status === 404) {
          //dispatch user not found
          dispatch(loginError(error))
        } else if (error.status === 401) {
          //dispatch invalid login
          dispatch(loginError({ status: 401, msg: "Invalid email/password" }))
        } else {
          dispatch(loginError(error))
        }
      })
  }
}

// Confirm Acount
export function invalidAcountConfirmation(error: any) {
  return {
    type: types.INVALID_ACOUNT_CONFIRMATION,
    error,
  }
}

export function requestConfirmAcount() {
  return {
    type: types.REQUEST_CONFIRM_ACOUNT,
  }
}

export function confirmAcountSuccess(response: any) {
  return {
    type: types.RECEIVE_ACOUNT_SUCCESS,
    response,
  }
}

export function confirmUserAcount(token: any) {
  return (dispatch: Dispatch) => {
    dispatch(requestConfirmAcount())
    return sessionApi
      .confirmAcount(token)
      .then((response) => {
        dispatch(confirmAcountSuccess(response))
      })
      .catch((error) => {
        dispatch(invalidAcountConfirmation(error))
        throw error
      })
  }
}

export function errorFetchAllAdmins(error: any) {
  return {
    type: types.ERROR_REQUEST_ALL_ADMINS,
    error,
  }
}

export function requestAllAdmins() {
  return {
    type: types.REQUEST_ALL_ADMINS,
  }
}

export function successRequestAllAdmins(response: any) {
  return {
    type: types.SUCCESS_REQUEST_ALL_ADMINS,
    response: response,
  }
}

export function fetchAllAdmins(profileId: string, token: any, page: any) {
  return (dispatch: Dispatch) => {
    dispatch(requestAllAdmins())
    return sessionApi
      .fetchAllAdmins(profileId, token, page)
      .then((response) => {
        dispatch(successRequestAllAdmins(response))
      })
      .catch((error) => {
        dispatch(errorFetchAllAdmins(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearSearchAdmins() {
  return {
    type: types.CLEAR_SEARCH_ADMINS,
  }
}

export function cleanSearchAdmins() {
  return (dispatch: Dispatch) => {
    dispatch(clearSearchAdmins())
  }
}

export function errorFetchSearchAdmins(error: any) {
  return {
    type: types.ERROR_SEARCH_ADMINS,
    error,
  }
}

export function requestSearchAdmins() {
  return {
    type: types.REQUEST_SEARCH_ADMINS,
  }
}

export function successRequestSearchAdmins(response: any) {
  return {
    type: types.SUCCESS_SEARCH_ADMINS,
    response: response,
  }
}

export function fetchSearchAdmins(profileId: string, query: string, limit: string, page: string, token: any) {
  return (dispatch: Dispatch) => {
    dispatch(requestSearchAdmins())
    return sessionApi
      .fetchSearchAdmins(profileId, query, limit, page, token)
      .then((response) => {
        dispatch(successRequestSearchAdmins(response))
      })
      .catch((error) => {
        dispatch(errorFetchSearchAdmins(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearAddAdmin() {
  return {
    type: types.CLEAR_ADD_ADMIN,
  }
}
export function cleanAddAdmin() {
  return (dispatch: Dispatch) => {
    dispatch(clearAddAdmin())
  }
}

export function errorAddAdmin(error: any) {
  return {
    type: types.ERROR_ADD_ADMIN,
    error,
  }
}

export function requestAddAdmin() {
  return {
    type: types.REQUEST_ADD_ADMIN,
  }
}

export function successRequestAddAdmin(response: any) {
  return {
    type: types.SUCCESS_ADD_ADMIN,
    response: response,
  }
}

export function fetchAddAdmin(profileId: string, personId: string, token: any) {
  return (dispatch: Dispatch) => {
    dispatch(requestAddAdmin())
    return sessionApi
      .fetchAddAdmin(profileId, personId, token)
      .then((response) => {
        dispatch(successRequestAddAdmin(response))
      })
      .catch((error) => {
        dispatch(errorAddAdmin(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorDeleteAdmin(error: any) {
  return {
    type: types.ERROR_DELETE_ADMIN,
    error,
  }
}

export function requestDeleteAdmin() {
  return {
    type: types.REQUEST_DELETE_ADMIN,
  }
}

export function successRequestDeleteAdmin(response: any) {
  return {
    type: types.SUCCESS_DELETE_ADMIN,
    response: response,
  }
}

export function fetchDeleteAdmin(profileId: string, adminId: string, token: any) {
  return (dispatch: Dispatch) => {
    dispatch(requestDeleteAdmin())
    return sessionApi
      .fetchDeleteAdmin(profileId, adminId, token)
      .then((response) => {
        dispatch(successRequestDeleteAdmin(response))
      })
      .catch((error) => {
        dispatch(errorDeleteAdmin(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUserFirstLogin(error: any) {
  return {
    type: types.ERROR_USER_FIRST_LOGIN,
    error,
  }
}

export function requestUserFirstLogin() {
  return {
    type: types.REQUEST_USER_FIRST_LOGIN,
  }
}

export function successRequestUserFirstLogin(response: any) {
  return {
    type: types.SUCCESS_USER_FIRST_LOGIN,
    response: response,
  }
}

export function userFirstLogin(token: any) {
  return (dispatch: Dispatch) => {
    dispatch(requestUserFirstLogin())
    return sessionApi
      .userFirstLogin(token)
      .then((response) => {
        dispatch(successRequestUserFirstLogin(response))
      })
      .catch((error) => {
        dispatch(errorUserFirstLogin(error))
        throw error
      })
  }
}

export function cleanUserFirstLogin() {
  return {
    type: types.CLEAR_USER_FIRST_LOGIN,
  }
}

export function clearUserFirstLogin() {
  return (dispatch: Dispatch) => {
    dispatch(cleanUserFirstLogin())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUserInfo(error: any) {
  return {
    type: types.ERROR_USER_INFO,
    error,
  }
}

export function requestUserInfo() {
  return {
    type: types.REQUEST_USER_INFO,
  }
}

export function successRequestUserInfo(response: any) {
  return {
    type: types.SUCCESS_USER_INFO,
    response: response,
  }
}

export function fetchUserInfo(token: any) {
  return (dispatch: Dispatch) => {
    dispatch(requestUserInfo())
    return sessionApi
      .userInfo(token)
      .then((response) => {
        dispatch(successRequestUserInfo(response))
      })
      .catch((error) => {
        dispatch(errorUserInfo(error))
        throw error
      })
  }
}

export function cleanUserInfo() {
  return {
    type: types.CLEAR_USER_INFO,
  }
}

export function clearUserInfo() {
  return (dispatch: Dispatch) => {
    dispatch(cleanUserInfo())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearErrorsFromLogin() {
  return {
    type: types.CLEAR_LOGIN_ERROR,
  }
}

export function clearLoginErros() {
  return (dispatch: Dispatch) => {
    dispatch(clearErrorsFromLogin())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function resetPassword(email: string) {
  return (dispatch: Dispatch) => {
    dispatch(loadingResetPassword())
    return sessionApi
      .resetPassword(email)
      .then((response) => {
        dispatch(successResetPassword(response))
      })
      .catch((error) => {
        dispatch(failedResetPassword(error))
      })
  }
}

export function loadingResetPassword() {
  return {
    type: types.LOADING_RESET_PASSWORD,
  }
}

export function successResetPassword(response: any) {
  return {
    type: types.SUCCESS_RESET_PASSWORD,
    response,
  }
}

export function failedResetPassword(error: any) {
  return {
    type: types.FAILED_RESET_PASSWORD,
    error,
  }
}

export function clearResetPassword() {
  return {
    type: types.CLEAR_RESET_PASSWORD,
  }
}

export function cleanResetPassword() {
  return (dispatch: Dispatch) => {
    dispatch(clearResetPassword())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function createPassword(email: string, password: string, password_confirm: string, token: any) {
  return (dispatch: Dispatch) => {
    dispatch(loadingCreatePassword())
    return sessionApi
      .createPassword(email, password, password_confirm, token)
      .then((response) => {
        dispatch(successCreatePassword(response))
      })
      .catch((error) => {
        dispatch(failedCreatePassword(error))
      })
  }
}

export function loadingCreatePassword() {
  return {
    type: types.LOADING_CREATE_PASSWORD,
  }
}

export function successCreatePassword(response: any) {
  return {
    type: types.SUCCESS_CREATE_PASSWORD,
    response,
  }
}

export function failedCreatePassword(error: any) {
  return {
    type: types.FAILED_CREATE_PASSWORD,
    error,
  }
}

export function clearCreatePassword() {
  return {
    type: types.CLEAR_CREATE_PASSWORD,
  }
}

export function cleanCreatePassword() {
  return (dispatch: Dispatch) => {
    dispatch(clearCreatePassword())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function getRegisterLimit(token: any) {
  return (dispatch: Dispatch) => {
    dispatch(loadingGetRegisterLimit())
    return sessionApi
      .getRegisterLimit(token)
      .then((response) => {
        dispatch(successGetRegisterLimit(response))
      })
      .catch((error) => {
        dispatch(errorGetRegisterLimit(error))
      })
  }
}

export function loadingGetRegisterLimit() {
  return {
    type: types.LOADING_GET_REGISTER_LIMIT,
  }
}

export function successGetRegisterLimit(response: any) {
  return {
    type: types.SUCCESS_GET_REGISTER_LIMIT,
    response,
  }
}

export function errorGetRegisterLimit(error: any) {
  return {
    type: types.ERROR_GET_REGISTER_LIMIT,
    error,
  }
}

export function clearGetRegisterLimit() {
  return {
    type: types.CLEAR_GET_REGISTER_LIMIT,
  }
}

export function cleanGetRegisterLimit() {
  return (dispatch: Dispatch) => {
    dispatch(clearGetRegisterLimit())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorGoogleLogin(error: any) {
  return {
    type: types.ERROR_GOOGLE_LOGIN,
    error,
  }
}

export function loadingGoogleLogin() {
  return {
    type: types.LOADING_GOOGLE_LOGIN,
  }
}

export function successGoogleLogin(response: any) {
  return {
    type: types.SUCCESS_GOOGLE_LOGIN,
    response,
  }
}

export function googleLogin(credential: string, app: string) {
  return (dispatch: Dispatch) => {
    dispatch(loadingGoogleLogin())
    return sessionApi
      .googleApiLogin(credential, app)
      .then((response) => {
        dispatch(successGoogleLogin(response))
        const userInfo = response
        localStorage.setItem("userToken", JSON.stringify(userInfo))
        let encodedUserInfo = btoa(JSON.stringify(userInfo))
        cookie.save("_fluxio_user_token", encodedUserInfo, {
          path: "/",
          //@ts-ignore
          domain: `.${window.location.hostname}`,
          expires: new Date(Date.now() + userInfo.expires_in * 1000 - 3600000),
        })
        cookie.save("_fluxio_user_token", encodedUserInfo, {
          path: "/",
          //@ts-ignore
          domain: `.fluxio.cloud`,
          expires: new Date(Date.now() + userInfo.expires_in * 1000 - 3600000),
        })
        sessionApi
          .userInfo(userInfo)
          .then((response) => {
            //dispatch user info to validate
            dispatch(unverifiedUserInfo(response))
            let info = {
              uuid: response.UUID,
              idp: "meshapp",
              name: response.name,
              email: response.email,
              avatar: response.avatar,
            }
            sessionApi
              .personValidate(info, userInfo)
              .then((response) => {
                //save user info to cookie
                let encodedInfo = btoa(JSON.stringify(response))
                cookie.save("_fluxio_info", encodedInfo, {
                  path: "/",
                  //@ts-ignore
                  domain: `.${window.location.hostname}`,
                  expires: new Date(Date.now() + userInfo.expires_in * 1000 - 3600000),
                })
                //dispatch valid user info
                dispatch(verifiedUserInfo(response))
              })
              .catch((error) => {
                dispatch(loginError(error))
              })
          })
          .catch((error) => {
            dispatch(
              loginError(
                error.status === 403
                  ? { msg: "Your registration is unverified. Please go to your email account and verify it." }
                  : error
              )
            )
          })
      })
      .catch((error) => {
        dispatch(errorGoogleLogin(error))
      })
  }
}

export function clearGoogleLogin() {
  return {
    type: types.CLEAR_GOOGLE_LOGIN,
  }
}

export function cleanGoogleLogin() {
  return (dispatch: Dispatch) => {
    dispatch(clearGoogleLogin())
  }
}

import { ENGLISH_TRANSLATION } from './shared-store/messages/en'
import LocalStorageHandler from '../modules/shared-modules/utilities/localStorage'
import * as viewPort from '../modules/shared-modules/experienceManager/viewPortTypes/viewPortTypes'
import { CONTENT_TYPE_INSTANCE } from '../modules/shared-modules/experienceManager/finder/symbols/symbolTypes'

const initialState = {
  session: {
    loading: false,
    error: undefined,
    adminsList: [],
  },
  locale: {
    lang: ENGLISH_TRANSLATION.lang,
    messages: ENGLISH_TRANSLATION.messages,
  },
  contentHubReducer: {
    isFetching: false,
    hubContent: undefined,
  },
  selectedHub: {
    hubId: null,
  },
  contentFormulaReducer: {
    isFetching: false,
  },
  contentPowerWallReducer: {
    isFetching: false,
  },
  fontAwesomeServices: {
    fetching: false,
  },
  profileReducer: {
    page: null,
    loading: false,
  },
  personLibraryReducer: {
    page: null,
    loading: false,
  },
  userProfiles: {
    loading: false,
  },
  profile: {
    loading: false,
  },
  profileLibraryReducer: {
    loading: false,
  },
  profilePowerWallReducer: {
    loading: false,
  },
  mqlReducer: {
    loading: false,
  },
  metaManagerReducer: {
    loading: false,
  },
  votingReducer: {
    loading: false,
  },
  xFormulaReducer: {
    loading: false,
    formulaGalleryChanges: [],
  },
  breadcrumbReducer: {
    breadcrumbs: [],
  },
  boardReducer: {
    profile: undefined,
    allPostContentLoading: false,
  },
  xFilterReducer: {
    mode: "build",
  },
  filterSelectorReducer: {
    lineSelected: false,
  },
  filterReducer: {
    filters: [],
  },
  formulaTagsReducer: {
    loading: false,
  },
  addTagReducer: {
    loadingAddTag: false,
  },
  toolBoxReducer: {
    setup: 1,
    feedlySources: [],
    sourceSubItems: [],
  },
  verticalMenuReducer: {
    loading: false,
  },
  profileTagsReducer: {
    loading: false,
    deletedProfileTagFromPost: [],
  },
  routesReducer: {
    tabId: undefined,
    topicId: undefined,
    pillId: undefined,
    postId: undefined,
  },
  skynetReducer: {
    formula: undefined,
    createFormula: false,
  },
  experienceManagerReducer: {
    loadingHubs: false,
    newPageModalState: false,
    eventPile: [],
    colorsObj: {
      first: "#000000",
      second: "#000000",
      background: "#000000",
      backgroundAlt: "#000000",
    },
    finderPreview: false,
    selectedPostsEM: [],
    objectSelectorModeEM: true,
    successResponsesEventPile: [],
    warningState: false,
    state: LocalStorageHandler.getValue("autoSelectOnCreate"),
    elementSelectorStateEM: true,
    objectSelectorStateEM: true,
    globalStyleState: "list",
    viewPortSizeState: viewPort.DESKTOP,
    viewPortScaleState: 100,
    responsiveTab: "responsive",
    arrangement_clp: true,
    content_clp: true,
    style_clp: true,
    behaviourState: "default",
    isContentPage: false,
  },
  emExtraReducer: {
    extraObj: [],
  },
  formulalabReducer: {
    queryGroupsArr: [],
  },
  marketplaceReducer: {
    modalTemplateActions: false,
    templateListFilters: {
      acquired: {},
      owned: {},
      published: {},
    },
  },
  globalStyleReducer: {
    globalStyleBehaviour: "default",
  },
  globalStylesReducer: {},
  stylesheetReducer: {},
  foundationStyleReducer: {},
  pageTreeReducer: {
    finderState: false,
    isExpanded: LocalStorageHandler.getValue("pageTreeIsExpanded") === "false" ? false : true,
  },
  symbolsReducer: {
    symbolContentType: CONTENT_TYPE_INSTANCE,
  },
  formBuilderReducer: {
    loadingSubmitForm: false,
  },
  formBuilderV2Reducer: {
    loadingProfileFormsList: false,
  },
  settingsReducer: {
    loadingUploadFont: false,
  },
  publicPageReducer: {
    fetchingDataSet: false,
  },
  dataSetBuilderReducer: {
    fetchingDataSetList: false,
  },
  academyReducer: {
    modalState: false,
    infoState: false,
  },
  tourReducer: {
    isEMTourOpen: false,
    emTourStep: 0,
  },
  inlineContentReducer: {
    toolbarId: "ntext1-toolbar",
  },
  cmsContentReducer: {
    successCreateFormula: undefined,
  },
  mediaLibraryReducer: {
    mediaLibraryState: false,
    deletedMediaList: [],
    replacedMediaList: {},
  },
  libraryReducer: {
    filterTypeGs: "GShadow",
    filterTypeFoundation: "FColor",
  },
  contentLibraryReducer: {
    contentLibraryForm: false,
    loadingContentLibrary: false,
    errorFetchContentLibrary: false,
    loadingPublicContentPagePost: false,
    loadingPreviewContentPagePost: false,
  },
  ssoReducer: {},
  externalServicesReducer: {
    loadingBunnyFontsList: false,
    receiveBunnyFontsListForSelect: [],
  },
}

export default initialState
import cloneDeep from 'lodash/cloneDeep'
import {
  ERROR_FETCH_MEDIA,
  REQUEST_FETCH_MEDIA,
  SUCCESS_FETCH_MEDIA,
  ERROR_DELETE_MEDIA,
  REQUEST_DELETE_MEDIA,
  SUCCESS_DELETE_MEDIA,
  CLEAN_DELETE_MEDIA,
  ERROR_DELETE_SINGLE_MEDIA,
  REQUEST_DELETE_SINGLE_MEDIA,
  SUCCESS_DELETE_SINGLE_MEDIA,
  CLEAN_DELETE_SINGLE_MEDIA,
  ERROR_UPDATE_META_SINGLE_MEDIA,
  REQUEST_UPDATE_META_SINGLE_MEDIA,
  SUCCESS_UPDATE_META_SINGLE_MEDIA,
  CLEAN_UPDATE_META_SINGLE_MEDIA,
  ERROR_BULK_UPDATE_META_SINGLE_MEDIA,
  REQUEST_BULK_UPDATE_META_SINGLE_MEDIA,
  SUCCESS_BULK_UPDATE_META_SINGLE_MEDIA,
  CLEAN_BULK_UPDATE_MEDIA_META,
  ERROR_UPLOAD_MEDIA,
  REQUEST_UPLOAD_MEDIA,
  SUCCESS_UPLOAD_MEDIA,
  CLEAN_UPLOAD_MEDIA,
  ERROR_BULK_UPLOAD_MEDIA,
  REQUEST_BULK_UPLOAD_MEDIA,
  SUCCESS_BULK_UPLOAD_MEDIA,
  CLEAN_BULK_UPLOAD_MEDIA,
  ERROR_REPLACE_SINGLE_MEDIA,
  REQUEST_REPLACE_SINGLE_MEDIA,
  SUCCESS_REPLACE_SINGLE_MEDIA,
  CLEAN_REPLACE_SINGLE_MEDIA,
  TRIGGER_MEDIA_LIBRARY_FORM,
  SELECTED_MEDIA,
  BOB_SELECTED_MEDIA,
  SEND_SELECTED_MEDIA_ID,
  UPDATE_PAGE_OBJECT_WITH_MEDIA,
  UPDATE_PAGE_OBJECT_WITH_MEDIA_LIST,
  CLEAN_PAGE_OBJECT_WITH_MEDIA_LIST,
  ERROR_MEDIA_USAGE_BY_PAGE,
  REQUEST_MEDIA_USAGE_BY_PAGE,
  SUCCESS_MEDIA_USAGE_BY_PAGE,
  CLEAN_MEDIA_USAGE_BY_PAGE,
  ERROR_MEDIA_USAGE_BY_POST,
  REQUEST_MEDIA_USAGE_BY_POST,
  SUCCESS_MEDIA_USAGE_BY_POST,
  CLEAN_MEDIA_USAGE_BY_POST,
  ERROR_MEDIA_USAGE_BY_TEMPLATE,
  REQUEST_MEDIA_USAGE_BY_TEMPLATE,
  SUCCESS_MEDIA_USAGE_BY_TEMPLATE,
  CLEAN_MEDIA_USAGE_BY_TEMPLATE,
  ERROR_MEDIA_USAGE_BY_SYMBOL,
  REQUEST_MEDIA_USAGE_BY_SYMBOL,
  SUCCESS_MEDIA_USAGE_BY_SYMBOL,
  CLEAN_MEDIA_USAGE_BY_SYMBOL,
  DELETED_MEDIA_LIST,
  CLEAR_DELETED_MEDIA_LIST,
  UPDATED_REPLACED_MEDIA_LIST,
  CLEAR_UPDATED_REPLACED_MEDIA_LIST,
  MEDIA_LIBRARY_BULK_UPLOAD_FORM,
  CLEAN_PAGE_OBJECT_WITH_POST_LIST,
  UPDATE_PAGE_OBJECT_WITH_POST_LIST,
  TRIGGER_MEDIA_LIBRARY_STATE,
} from "../shared-store/actions/actionTypes"
import initialState from "../initialState"

export default function mediaLibraryReducer(state: any = initialState.mediaLibraryReducer, action: any) {
  switch (action.type) {
    case ERROR_FETCH_MEDIA:
      return {
        ...state,
        errorFetchMedia: action.error,
        loadingFetchMedia: false,
        successFetchMedia: undefined,
      }
    case REQUEST_FETCH_MEDIA:
      return {
        ...state,
        errorFetchMedia: false,
        loadingFetchMedia: true,
        successFetchMedia: undefined,
      }
    case SUCCESS_FETCH_MEDIA:
      return {
        ...state,
        errorFetchMedia: false,
        loadingFetchMedia: false,
        successFetchMedia: action.response,
      }
    /******************************** */
    case ERROR_DELETE_MEDIA:
      return {
        ...state,
        errorDeleteMedia: action.error,
        loadingDeleteMedia: false,
        successDeleteMedia: undefined,
        deleteMediaId: action.arrMediaId,
      }
    case REQUEST_DELETE_MEDIA:
      return {
        ...state,
        errorDeleteMedia: false,
        loadingDeleteMedia: action.arrMediaId,
        successDeleteMedia: undefined,
        deleteMediaId: action.arrMediaId,
      }
    case SUCCESS_DELETE_MEDIA:
      return {
        ...state,
        errorDeleteMedia: false,
        loadingDeleteMedia: false,
        successDeleteMedia: action.response,
        deleteMediaId: action.arrMediaId,
      }
    case CLEAN_DELETE_MEDIA:
      return {
        ...state,
        errorDeleteMedia: false,
        loadingDeleteMedia: false,
        successDeleteMedia: undefined,
        deleteMediaId: undefined,
      }
    /******************************** */
    case ERROR_DELETE_SINGLE_MEDIA:
      return {
        ...state,
        errorDeleteSingleMedia: action.error,
        loadingDeleteSingleMedia: false,
        successDeleteSingleMedia: undefined,
        deleteSingleMediaId: action.mediaId,
      }
    case REQUEST_DELETE_SINGLE_MEDIA:
      return {
        ...state,
        errorDeleteSingleMedia: false,
        loadingDeleteSingleMedia: action.mediaId,
        successDeleteSingleMedia: undefined,
        deleteSingleMediaId: action.mediaId,
      }
    case SUCCESS_DELETE_SINGLE_MEDIA:
      return {
        ...state,
        errorDeleteSingleMedia: false,
        loadingDeleteSingleMedia: false,
        successDeleteSingleMedia: action.response,
        deleteSingleMediaId: action.mediaId,
      }
    case CLEAN_DELETE_SINGLE_MEDIA:
      return {
        ...state,
        errorDeleteSingleMedia: false,
        loadingDeleteSingleMedia: false,
        successDeleteSingleMedia: undefined,
        deleteSingleMediaId: undefined,
      }
    /******************************** */
    case ERROR_UPDATE_META_SINGLE_MEDIA:
      return {
        ...state,
        errorUpdateMetaSingleMedia: action.error,
        loadingUpdateMetaSingleMedia: false,
        successUpdateMetaSingleMedia: undefined,
        updateMetaSingleMediaId: action.mediaId,
      }
    case REQUEST_UPDATE_META_SINGLE_MEDIA:
      return {
        ...state,
        errorUpdateMetaSingleMedia: false,
        loadingUpdateMetaSingleMedia: true,
        successUpdateMetaSingleMedia: undefined,
        updateMetaSingleMediaId: action.mediaId,
      }
    case SUCCESS_UPDATE_META_SINGLE_MEDIA:
      return {
        ...state,
        errorUpdateMetaSingleMedia: false,
        loadingUpdateMetaSingleMedia: false,
        successUpdateMetaSingleMedia: action.response,
        updateMetaSingleMediaId: action.mediaId,
      }
    case CLEAN_UPDATE_META_SINGLE_MEDIA:
      return {
        ...state,
        errorUpdateMetaSingleMedia: false,
        loadingUpdateMetaSingleMedia: false,
        successUpdateMetaSingleMedia: undefined,
        updateMetaSingleMediaId: undefined,
      }
    /******************************** */
    case ERROR_BULK_UPDATE_META_SINGLE_MEDIA:
      return {
        ...state,
        errorBulkUpdateMetaSingleMedia: action.error,
        loadingBulkUpdateMetaSingleMedia: false,
        successBulkUpdateMetaSingleMedia: undefined,
      }
    case REQUEST_BULK_UPDATE_META_SINGLE_MEDIA:
      return {
        ...state,
        errorBulkUpdateMetaSingleMedia: false,
        loadingBulkUpdateMetaSingleMedia: true,
        successBulkUpdateMetaSingleMedia: undefined,
      }
    case SUCCESS_BULK_UPDATE_META_SINGLE_MEDIA:
      return {
        ...state,
        errorBulkUpdateMetaSingleMedia: false,
        loadingBulkUpdateMetaSingleMedia: false,
        successBulkUpdateMetaSingleMedia: action.response,
      }
    case CLEAN_BULK_UPDATE_MEDIA_META:
      return {
        ...state,
        errorBulkUpdateMetaSingleMedia: false,
        loadingBulkUpdateMetaSingleMedia: false,
        successBulkUpdateMetaSingleMedia: undefined,
      }
    /******************************** */
    case ERROR_UPLOAD_MEDIA:
      return {
        ...state,
        errorUploadMedia: action.error,
        loadingUploadMedia: false,
        successUploadMedia: undefined,
      }
    case REQUEST_UPLOAD_MEDIA:
      return {
        ...state,
        errorUploadMedia: false,
        loadingUploadMedia: true,
        successUploadMedia: undefined,
        mediaLibraryForm: true,
        selectedMedia: action.media,
      }
    case SUCCESS_UPLOAD_MEDIA:
      return {
        ...state,
        errorUploadMedia: false,
        loadingUploadMedia: false,
        successUploadMedia: action.response,
      }
    case CLEAN_UPLOAD_MEDIA:
      return {
        ...state,
        errorUploadMedia: false,
        loadingUploadMedia: false,
        successUploadMedia: undefined,
        selectedMedia: undefined,
      }
    /******************************** */
    case ERROR_BULK_UPLOAD_MEDIA:
      return {
        ...state,
        [`errorBulkUploadMedia${action.media.id}`]: action.error,
        [`loadingBulkUploadMedia${action.media.id}`]: false,
        [`successBulkUploadMedia${action.media.id}`]: undefined,
      }
    case REQUEST_BULK_UPLOAD_MEDIA:
      return {
        ...state,
        [`errorBulkUploadMedia${action.media.id}`]: false,
        [`loadingBulkUploadMedia${action.media.id}`]: true,
        [`successBulkUploadMedia${action.media.id}`]: undefined,
      }
    case SUCCESS_BULK_UPLOAD_MEDIA:
      return {
        ...state,
        [`errorBulkUploadMedia${action.media.id}`]: false,
        [`loadingBulkUploadMedia${action.media.id}`]: false,
        [`successBulkUploadMedia${action.media.id}`]: action.response,
      }
    case CLEAN_BULK_UPLOAD_MEDIA:
      return {
        ...state,
        [`errorBulkUploadMedia${action.media.id}`]: false,
        [`loadingBulkUploadMedia${action.media.id}`]: false,
        [`successBulkUploadMedia${action.media.id}`]: undefined,
      }
    /******************************** */
    case ERROR_REPLACE_SINGLE_MEDIA:
      return {
        ...state,
        errorReplaceSingleMedia: action.error,
        loadingReplaceSingleMedia: false,
        successReplaceSingleMedia: undefined,
        replacedMediaId: action.mediaId,
      }
    case REQUEST_REPLACE_SINGLE_MEDIA:
      return {
        ...state,
        errorReplaceSingleMedia: false,
        loadingReplaceSingleMedia: true,
        successReplaceSingleMedia: undefined,
        replacedMediaId: action.mediaId,
      }
    case SUCCESS_REPLACE_SINGLE_MEDIA:
      return {
        ...state,
        errorReplaceSingleMedia: false,
        loadingReplaceSingleMedia: false,
        successReplaceSingleMedia: action.response,
        replacedMediaId: action.mediaId,
      }
    case CLEAN_REPLACE_SINGLE_MEDIA:
      return {
        ...state,
        errorReplaceSingleMedia: false,
        loadingReplaceSingleMedia: false,
        successReplaceSingleMedia: undefined,
        replacedMediaId: action.mediaId,
      }
    /******************************** */
    case TRIGGER_MEDIA_LIBRARY_FORM:
      return {
        ...state,
        mediaLibraryForm: action.mediaLibraryForm,
      }
    /******************************** */
    case TRIGGER_MEDIA_LIBRARY_STATE:
      return {
        ...state,
        mediaLibraryState: action.mediaLibraryState,
        mediaLibraryStateId: action.id,
        mediaLibraryStateAllowedTypes: action.allowedTypes,
      }
    /******************************** */
    case SELECTED_MEDIA:
      return {
        ...state,
        selectedMedia: action.media,
      }
    /******************************** */
    case BOB_SELECTED_MEDIA:
      return {
        ...state,
        bobSelectedMedia: action.media,
      }
    /******************************** */
    case SEND_SELECTED_MEDIA_ID:
      return {
        ...state,
        selectedMediaId: action.mediaId,
        mediaCallId: action.mediaCallId,
      }
    case UPDATE_PAGE_OBJECT_WITH_MEDIA:
      return {
        ...state,
        selectedMediaObject: action.mediaObject,
      }
    case UPDATE_PAGE_OBJECT_WITH_MEDIA_LIST:
      const selectedMediaObjectList = state.selectedMediaObjectList ? state.selectedMediaObjectList : {}
      return {
        ...state,
        selectedMediaObjectList: {
          ...selectedMediaObjectList,
          [action.mediaObject.id]: action.mediaObject,
        },
      }
    case CLEAN_PAGE_OBJECT_WITH_MEDIA_LIST:
      return {
        ...state,
        selectedMediaObjectList: undefined,
      }
    /******************************** */
    case UPDATE_PAGE_OBJECT_WITH_POST_LIST:
      return {
        ...state,
        selectedPostObjectList: {
          ...state.selectedPostObjectList,
          ...action.postObject,
        },
      }
    case CLEAN_PAGE_OBJECT_WITH_POST_LIST:
      return {
        ...state,
        selectedPostObjectList: undefined,
      }
    /******************************** */
    case ERROR_MEDIA_USAGE_BY_PAGE:
      return {
        ...state,
        errorMediaUsageByPage: action.error,
        loadingMediaUsageByPage: false,
        mediaUsageByPageId: action.mediaId,
      }
    case REQUEST_MEDIA_USAGE_BY_PAGE:
      return {
        ...state,
        errorMediaUsageByPage: false,
        loadingMediaUsageByPage: true,
        mediaUsageByPageId: action.mediaId,
      }
    case SUCCESS_MEDIA_USAGE_BY_PAGE:
      const { response: mediaUsageByPageResponse } = action
      const stateMediaUsageByPageResponse = state.successMediaUsageByPage
      if (stateMediaUsageByPageResponse) {
        let clonedMediaUsageByPageResponse = cloneDeep(stateMediaUsageByPageResponse)
        clonedMediaUsageByPageResponse = {
          ...mediaUsageByPageResponse,
          data: [...clonedMediaUsageByPageResponse.data, ...mediaUsageByPageResponse.data],
        }
        return {
          ...state,
          successMediaUsageByPage: clonedMediaUsageByPageResponse,
          loadingGlobalStylePageUsageList: false,
          errorFetchGlobalStylePageUsageList: false,
          mediaUsageByPageId: action.mediaId,
        }
      }
      return {
        ...state,
        errorMediaUsageByPage: false,
        loadingMediaUsageByPage: false,
        successMediaUsageByPage: action.response,
        mediaUsageByPageId: action.mediaId,
      }
    case CLEAN_MEDIA_USAGE_BY_PAGE:
      return {
        ...state,
        errorMediaUsageByPage: false,
        loadingMediaUsageByPage: false,
        successMediaUsageByPage: undefined,
        mediaUsageByPageId: action.mediaId,
      }
    /******************************** */
    case ERROR_MEDIA_USAGE_BY_POST:
      return {
        ...state,
        errorMediaUsageByPost: action.error,
        loadingMediaUsageByPost: false,
        mediaUsageByPostId: action.mediaId,
      }
    case REQUEST_MEDIA_USAGE_BY_POST:
      return {
        ...state,
        errorMediaUsageByPost: false,
        loadingMediaUsageByPost: true,
        mediaUsageByPostId: action.mediaId,
      }
    case SUCCESS_MEDIA_USAGE_BY_POST:
      const { response: mediaUsageByPostResponse } = action
      const stateMediaUsageByPostResponse = state.successMediaUsageByPost
      if (stateMediaUsageByPostResponse) {
        let clonedMediaUsageByPostResponse = cloneDeep(stateMediaUsageByPostResponse)
        clonedMediaUsageByPostResponse = {
          ...mediaUsageByPostResponse,
          data: [...clonedMediaUsageByPostResponse.data, ...mediaUsageByPostResponse.data],
        }
        return {
          ...state,
          successMediaUsageByPost: clonedMediaUsageByPostResponse,
          loadingGlobalStylePostUsageList: false,
          errorFetchGlobalStylePostUsageList: false,
          mediaUsageByPostId: action.mediaId,
        }
      }
      return {
        ...state,
        errorMediaUsageByPost: false,
        loadingMediaUsageByPost: false,
        successMediaUsageByPost: action.response,
        mediaUsageByPostId: action.mediaId,
      }
    case CLEAN_MEDIA_USAGE_BY_POST:
      return {
        ...state,
        errorMediaUsageByPost: false,
        loadingMediaUsageByPost: false,
        successMediaUsageByPost: undefined,
        mediaUsageByPostId: action.mediaId,
      }
    /******************************** */
    case ERROR_MEDIA_USAGE_BY_TEMPLATE:
      return {
        ...state,
        errorMediaUsageByTemplate: action.error,
        loadingMediaUsageByTemplate: false,
        mediaUsageByTemplateId: action.mediaId,
      }
    case REQUEST_MEDIA_USAGE_BY_TEMPLATE:
      return {
        ...state,
        errorMediaUsageByTemplate: false,
        loadingMediaUsageByTemplate: true,
        mediaUsageByTemplateId: action.mediaId,
      }
    case SUCCESS_MEDIA_USAGE_BY_TEMPLATE:
      const { response: mediaUsageByTemplateResponse } = action
      const stateMediaUsageByTemplateResponse = state.successMediaUsageByTemplate
      if (stateMediaUsageByTemplateResponse) {
        let clonedMediaUsageByTemplateResponse = cloneDeep(stateMediaUsageByTemplateResponse)
        clonedMediaUsageByTemplateResponse = {
          ...mediaUsageByTemplateResponse,
          data: [...clonedMediaUsageByTemplateResponse.data, ...mediaUsageByTemplateResponse.data],
        }
        return {
          ...state,
          successMediaUsageByTemplate: clonedMediaUsageByTemplateResponse,
          loadingGlobalStyleTemplateUsageList: false,
          errorFetchGlobalStyleTemplateUsageList: false,
          mediaUsageByTemplateId: action.mediaId,
        }
      }
      return {
        ...state,
        errorMediaUsageByTemplate: false,
        loadingMediaUsageByTemplate: false,
        successMediaUsageByTemplate: action.response,
        mediaUsageByTemplateId: action.mediaId,
      }
    case CLEAN_MEDIA_USAGE_BY_TEMPLATE:
      return {
        ...state,
        errorMediaUsageByTemplate: false,
        loadingMediaUsageByTemplate: false,
        successMediaUsageByTemplate: undefined,
        mediaUsageByTemplateId: action.mediaId,
      }
    /******************************** */
    case ERROR_MEDIA_USAGE_BY_SYMBOL:
      return {
        ...state,
        errorMediaUsageBySymbol: action.error,
        loadingMediaUsageBySymbol: false,
        mediaUsageBySymbolId: action.mediaId,
      }
    case REQUEST_MEDIA_USAGE_BY_SYMBOL:
      return {
        ...state,
        errorMediaUsageBySymbol: false,
        loadingMediaUsageBySymbol: true,
        mediaUsageBySymbolId: action.mediaId,
      }
    case SUCCESS_MEDIA_USAGE_BY_SYMBOL:
      const { response: mediaUsageBySymbolResponse } = action
      const stateMediaUsageBySymbolResponse = state.successMediaUsageBySymbol
      if (stateMediaUsageBySymbolResponse) {
        let clonedMediaUsageBySymbolResponse = cloneDeep(stateMediaUsageBySymbolResponse)
        clonedMediaUsageBySymbolResponse = {
          ...mediaUsageBySymbolResponse,
          data: [...clonedMediaUsageBySymbolResponse.data, ...mediaUsageBySymbolResponse.data],
        }
        return {
          ...state,
          successMediaUsageBySymbol: clonedMediaUsageBySymbolResponse,
          loadingGlobalStyleSymbolUsageList: false,
          errorFetchGlobalStyleSymbolUsageList: false,
          mediaUsageBySymbolId: action.mediaId,
        }
      }
      return {
        ...state,
        errorMediaUsageBySymbol: false,
        loadingMediaUsageBySymbol: false,
        successMediaUsageBySymbol: action.response,
        mediaUsageBySymbolId: action.mediaId,
      }
    case CLEAN_MEDIA_USAGE_BY_SYMBOL:
      return {
        ...state,
        errorMediaUsageBySymbol: false,
        loadingMediaUsageBySymbol: false,
        successMediaUsageBySymbol: undefined,
        mediaUsageBySymbolId: action.mediaId,
      }
    /** */
    case DELETED_MEDIA_LIST:
      return {
        ...state,
        deletedMediaList: [...state.deletedMediaList, action.deletedMediaObj],
      }
    case CLEAR_DELETED_MEDIA_LIST:
      return {
        ...state,
        deletedMediaList: [],
      }
    case UPDATED_REPLACED_MEDIA_LIST:
      return {
        ...state,
        replacedMediaList: {
          ...state.replacedMediaList,
          [action.mediaObj.id]: action.mediaObj,
        },
      }
    case CLEAR_UPDATED_REPLACED_MEDIA_LIST:
      return {
        ...state,
        replacedMediaList: {},
      }
    case MEDIA_LIBRARY_BULK_UPLOAD_FORM:
      return {
        ...state,
        bulkMediaObjArr: action.mediaObjArr,
        bulkModalState: action.modalState,
      }
    default:
      return state
  }
}
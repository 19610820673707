import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'
import cloneDeep from 'lodash/cloneDeep'

export default function globalStyleReducer(state = initialState.globalStyleReducer, action) {
  switch (action.type) {
    // view state
    case types.GLOBAL_STYLE_VIEW:
      return {
        ...state,
        globalStyleState: action.state,
        globalStyleType: action.gsType,
        globalStyleIdInUse: action.gsId,
      }
    // list
    case types.CLEAR_FETCH_GLOBAL_STYLE_LIST:
      return {
        ...state,
        [`errorFetchGlobalStyleList-${action.gsType}`]: false,
        [`loadingFetchGlobalStyleList-${action.gsType}`]: false,
        [`successFetchGlobalStyleList-${action.gsType}`]: undefined,
      }
    case types.ERROR_FETCH_GLOBAL_STYLE_LIST:
      return {
        ...state,
        [`errorFetchGlobalStyleList-${action.gsType}`]: false,
        [`loadingFetchGlobalStyleList-${action.gsType}`]: undefined,
        [`successFetchGlobalStyleList-${action.gsType}`]: undefined,
      }
    case types.REQUEST_FETCH_GLOBAL_STYLE_LIST:
      return {
        ...state,
        [`errorFetchGlobalStyleList-${action.gsType}`]: false,
        [`loadingFetchGlobalStyleList-${action.gsType}`]: true,
      }
    case types.SUCCESS_FETCH_GLOBAL_STYLE_LIST:
      if (action.shouldAdd) {
        let clonedState = state[`successFetchGlobalStyleList-${action.gsType}`]
        const newResponse = {
          ...clonedState,
          ...action.response,
          data: [...clonedState.data, ...action.response.data],
        }
        return {
          ...state,
          [`errorFetchGlobalStyleList-${action.gsType}`]: false,
          [`loadingFetchGlobalStyleList-${action.gsType}`]: false,
          [`successFetchGlobalStyleList-${action.gsType}`]: newResponse,
        }
      }
      return {
        ...state,
        [`errorFetchGlobalStyleList-${action.gsType}`]: false,
        [`loadingFetchGlobalStyleList-${action.gsType}`]: false,
        [`successFetchGlobalStyleList-${action.gsType}`]: action.response,
      }
    // update list
    case types.UPDATE_GLOBAL_STYLE_LIST:
      return {
        ...state,
        [`successFetchGlobalStyleList-${action.gsType}`]: {
          ...state[`successFetchGlobalStyleList-${action.gsType}`],
          data: { ...state[`successFetchGlobalStyleList-${action.gsType}`]?.data, ...action.globalStyle },
        },
      }
    // create
    case types.CLEAR_CREATE_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorCreateGlobalStyle: false,
        loadingCreateGlobalStyle: false,
        successCreateGlobalStyle: undefined,
      }
    case types.ERROR_CREATE_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorCreateGlobalStyle: action.error,
        loadingCreateGlobalStyle: false,
        successCreateGlobalStyle: undefined,
      }
    case types.REQUEST_CREATE_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorCreateGlobalStyle: false,
        loadingCreateGlobalStyle: true,
        successCreateGlobalStyle: undefined,
      }
    case types.SUCCESS_CREATE_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorCreateGlobalStyle: false,
        loadingCreateGlobalStyle: false,
        successCreateGlobalStyle: action.response,
        handledObjectId: action.handledObjectId,
      }
    // update
    case types.CLEAR_UPDATE_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorUpdateGlobalStyle: false,
        loadingUpdateGlobalStyle: false,
        successUpdateGlobalStyle: undefined,
      }
    case types.ERROR_UPDATE_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorUpdateGlobalStyle: action.error,
        loadingUpdateGlobalStyle: false,
        successUpdateGlobalStyle: undefined,
      }
    case types.REQUEST_UPDATE_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorUpdateGlobalStyle: false,
        loadingUpdateGlobalStyle: action.globalStyle,
        successUpdateGlobalStyle: undefined,
      }
    case types.SUCCESS_UPDATE_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorUpdateGlobalStyle: false,
        loadingUpdateGlobalStyle: false,
        successUpdateGlobalStyle: action.response,
        handledObjectId: action.handledObjectId,
      }
    // attach
    case types.CLEAR_ATTACH_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorAttachGlobalStyle: false,
        loadingAttachGlobalStyle: false,
        successAttachGlobalStyle: undefined,
      }
    case types.ERROR_ATTACH_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorAttachGlobalStyle: action.error,
        loadingAttachGlobalStyle: false,
        successAttachGlobalStyle: undefined,
        handledObjectId: action.handledObjectId,
        handledGsType: action.handledGsType,
      }
    case types.REQUEST_ATTACH_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorAttachGlobalStyle: false,
        loadingAttachGlobalStyle: action.globalStyle,
        successAttachGlobalStyle: undefined,
        handledObjectId: action.handledObjectId,
        handledGsType: action.handledGsType,
      }
    case types.SUCCESS_ATTACH_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorAttachGlobalStyle: false,
        loadingAttachGlobalStyle: false,
        successAttachGlobalStyle: action.response,
        handledObjectId: action.handledObjectId,
        handledGsType: action.handledGsType,
      }
    // detach
    case types.CLEAR_DETACH_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorDetachGlobalStyle: false,
        loadingDetachGlobalStyle: false,
        successDetachGlobalStyle: undefined,
      }
    case types.ERROR_DETACH_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorDetachGlobalStyle: action.error,
        loadingDetachGlobalStyle: false,
        successDetachGlobalStyle: undefined,
        handledObjectId: action.handledObjectId,
        handledGsType: action.handledGsType,
      }
    case types.REQUEST_DETACH_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorDetachGlobalStyle: false,
        loadingDetachGlobalStyle: true,
        successDetachGlobalStyle: undefined,
        handledObjectId: action.handledObjectId,
        handledGsType: action.handledGsType,
      }
    case types.SUCCESS_DETACH_GLOBAL_STYLE_V2:
      return {
        ...state,
        errorDetachGlobalStyle: false,
        loadingDetachGlobalStyle: false,
        successDetachGlobalStyle: action.response,
        lastDetachdGlobalStyle: action.response,
        handledObjectId: action.handledObjectId,
        handledGsType: action.handledGsType,
      }
    case types.SAVE_ATTACHED_GLOBAL_STYLE:
      return {
        ...state,
        savedAttachedGlobalStyle: action.gsObj,
      }
    case types.CLEAR_SAVED_ATTACHED_GLOBAL_STYLE:
      return {
        ...state,
        savedAttachedGlobalStyle: undefined,
      }
    case types.SET_GLOBAL_STYLE_BEHAVIOUR:
      return {
        ...state,
        globalStyleBehaviour: action.gsBehaviour,
      }
    case types.EDIT_LIST_SINGLE_GLOBAL_STYLE:
      let clonedGsList = cloneDeep(state[`successFetchGlobalStyleList-${action.gsType}`])
      if (clonedGsList?.data && action.newGsToAdd) {
        //get index of element in the array
        const posEl = clonedGsList.data.findIndex((el) => el.uuid === action.newGsToAdd.uuid)
        clonedGsList.data[posEl] = action.newGsToAdd
      }
      return {
        ...state,
        [`successFetchGlobalStyleList-${action.gsType}`]: clonedGsList,
      }
    /*** */
    case types.SUCCESS_FETCH_GLOBAL_STYLE_PAGE_USAGE_LIST:
      const { response: pageResponse } = action
      const statePageResponse = state.globalStylePageUsageList
      if (statePageResponse) {
        let clonedPageResponse = cloneDeep(statePageResponse)
        clonedPageResponse = {
          ...pageResponse,
          data: [...clonedPageResponse.data, ...pageResponse.data],
        }
        return {
          ...state,
          globalStylePageUsageList: clonedPageResponse,
          loadingGlobalStylePageUsageList: false,
          errorFetchGlobalStylePageUsageList: false,
        }
      }
      return {
        ...state,
        globalStylePageUsageList: action.response,
        loadingGlobalStylePageUsageList: false,
        errorFetchGlobalStylePageUsageList: false,
      }
    case types.REQUEST_GLOBAL_STYLE_PAGE_USAGE_LIST:
      return {
        ...state,
        loadingGlobalStylePageUsageList: action.globalStyleId,
        errorFetchGlobalStylePageUsageList: false,
      }
    case types.ERROR_FETCH_GLOBAL_STYLE_PAGE_USAGE_LIST:
      return {
        ...state,
        loadingGlobalStylePageUsageList: false,
        errorFetchGlobalStylePageUsageList: { error: action.error, globalStyleId: action.globalStyleId },
      }
    case types.CLEAN_GLOBAL_STYLE_PAGE_USAGE_LIST:
      return {
        ...state,
        globalStylePageUsageList: undefined,
        loadingGlobalStylePageUsageList: false,
        errorFetchGlobalStylePageUsageList: false,
      }
    /*** */
    case types.SUCCESS_FETCH_GLOBAL_STYLE_SYMBOL_USAGE_LIST:
      const { response: symbolResponse } = action
      const stateSymbolResponse = state.globalStyleSymbolUsageList
      if (stateSymbolResponse) {
        let clonedSymbolResponse = cloneDeep(stateSymbolResponse)
        clonedSymbolResponse = {
          ...symbolResponse,
          data: [...clonedSymbolResponse.data, ...symbolResponse.data],
        }
        return {
          ...state,
          globalStyleSymbolUsageList: clonedSymbolResponse,
          loadingGlobalStyleSymbolUsageList: false,
          errorFetchGlobalStyleSymbolUsageList: false,
        }
      }
      return {
        ...state,
        globalStyleSymbolUsageList: action.response,
        loadingGlobalStyleSymbolUsageList: false,
        errorFetchGlobalStyleSymbolUsageList: false,
      }
    case types.REQUEST_GLOBAL_STYLE_SYMBOL_USAGE_LIST:
      return {
        ...state,
        loadingGlobalStyleSymbolUsageList: action.globalStyleId,
        errorFetchGlobalStyleSymbolUsageList: false,
      }
    case types.ERROR_FETCH_GLOBAL_STYLE_SYMBOL_USAGE_LIST:
      return {
        ...state,
        loadingGlobalStyleSymbolUsageList: false,
        errorFetchGlobalStyleSymbolUsageList: { error: action.error, globalStyleId: action.globalStyleId },
      }
    case types.CLEAN_GLOBAL_STYLE_SYMBOL_USAGE_LIST:
      return {
        ...state,
        globalStyleSymbolUsageList: undefined,
        loadingGlobalStyleSymbolUsageList: false,
        errorFetchGlobalStyleSymbolUsageList: false,
      }
    /*** */
    case types.SUCCESS_FETCH_GLOBAL_STYLE_TEMPLATE_USAGE_LIST:
      const { response: templateResponse } = action
      const stateTemplateResponse = state.globalStyleTemplateUsageList
      if (stateTemplateResponse) {
        let clonedTemplateResponse = cloneDeep(stateTemplateResponse)
        clonedTemplateResponse = {
          ...templateResponse,
          data: [...clonedTemplateResponse.data, ...templateResponse.data],
        }
        return {
          ...state,
          globalStyleTemplateUsageList: clonedTemplateResponse,
          loadingGlobalStyleTemplateUsageList: false,
          errorFetchGlobalStyleTemplateUsageList: false,
        }
      }
      return {
        ...state,
        globalStyleTemplateUsageList: action.response,
        loadingGlobalStyleTemplateUsageList: false,
        errorFetchGlobalStyleTemplateUsageList: false,
      }
    case types.REQUEST_GLOBAL_STYLE_TEMPLATE_USAGE_LIST:
      return {
        ...state,
        loadingGlobalStyleTemplateUsageList: action.globalStyleId,
        errorFetchGlobalStyleTemplateUsageList: false,
      }
    case types.ERROR_FETCH_GLOBAL_STYLE_TEMPLATE_USAGE_LIST:
      return {
        ...state,
        loadingGlobalStyleTemplateUsageList: false,
        errorFetchGlobalStyleTemplateUsageList: { error: action.error, globalStyleId: action.globalStyleId },
      }
    case types.CLEAN_GLOBAL_STYLE_TEMPLATE_USAGE_LIST:
      return {
        ...state,
        globalStyleTemplateUsageList: undefined,
        loadingGlobalStyleTemplateUsageList: false,
        errorFetchGlobalStyleTemplateUsageList: false,
      }
    /*** */
    case types.SUCCESS_FETCH_HANDLE_GLOBAL_STYLE_COMPATIBILITY:
      return {
        ...state,
        successHandleGlobalStyleCompatibility: action.response,
        loadingHandleGlobalStyleCompatibility: false,
        errorHandleGlobalStyleCompatibility: false,
      }
    case types.REQUEST_HANDLE_GLOBAL_STYLE_COMPATIBILITY:
      return {
        ...state,
        successHandleGlobalStyleCompatibility: undefined,
        loadingHandleGlobalStyleCompatibility: true,
        errorHandleGlobalStyleCompatibility: false,
      }
    case types.ERROR_FETCH_HANDLE_GLOBAL_STYLE_COMPATIBILITY:
      return {
        ...state,
        successHandleGlobalStyleCompatibility: undefined,
        loadingHandleGlobalStyleCompatibility: false,
        errorHandleGlobalStyleCompatibility: true,
      }
    case types.CLEAN_HANDLE_GLOBAL_STYLE_COMPATIBILITY:
      return {
        ...state,
        successHandleGlobalStyleCompatibility: undefined,
        loadingHandleGlobalStyleCompatibility: false,
        errorHandleGlobalStyleCompatibility: false,
      }
    /*** */
    default:
      return state
  }
}
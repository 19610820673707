import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function pageTreeReducer(state = initialState.pageTreeReducer, action: any) {
  switch (action.type) {
    case types.PAGE_TREE_FINDER_CONTROLLER:
      return {
        ...state,
        finderState: action.finderState
      }
    case types.PAGE_TREE_EDIT_SYMBOL_STATE:
      return {
        ...state,
        symbolObj: action.symbolObj,
        symbolFinderTab: action.tabToOpen
      }
    case types.PAGE_TREE_FINDER_EXPANDED:
      return {
        ...state,
        isExpanded: action.isExpanded
      }
    default:
      return state;
  }
}
import apiEnv from './shared-api/apiEnv'
import axios from 'axios'

class profileApi {
  static userProfiles(token, page = 1) {
    const request = new Request(`${apiEnv()}v1/persons/profiles/admin?limit=12&page=${page}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token}`,
      }),
    })

    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  /*
   * Fetch single profile info
   * token - user login token access
   * profile - profile name to fetch
   */
  static Profile(token, profile) {
    const request = new Request(`${apiEnv()}v1/profiles/${profile}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })

    return fetch(request)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response.json().then((response) => {
          throw response
        })
      })
      .catch((error) => {
        throw error
      })
  }

  static profilePowerWall(token, profile) {
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/library/power`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })

    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static ProfileLibrary(token, profile, page) {
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/library?until=${page}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })

    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static networkLibrary(page = 1, token) {
    const request = new Request(`${apiEnv()}v1/persons/network?page=${page}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })

    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static libraryContent(page = "", token) {
    const request = new Request(`${apiEnv()}v1/persons/library?until=${page}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })

    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static ProfileXformula(profile, token, orderby = "created_at", limit = 12, order = "asc", search, page = 1) {
    let url = `${apiEnv()}v1/profiles/${profile}/xformulas?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}`
    if (search) {
      url = `${apiEnv()}v1/profiles/${profile}/xformulas?limit=${limit}&orderby=${orderby}&order=${order}&search=${search}`
    }
    const request = new Request(url, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })

    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static ProfileSubXformula(profile, uuid, token) {
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/xformulas/${uuid}/xformulas`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })

    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static requestProfileTags(profile, token, limit = 10, page = 1, order = "desc", search = "") {
    const request = new Request(
      `${apiEnv()}v1/profiles/${profile}/profiletags?limit=${limit}&page=${page}&order=${order}&search=${search}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          "MeshApp-host": "meshapp",
          Authorization: `Bearer ${token.access_token}`,
        }),
      }
    )
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static createProfileTag(profile, isPublic, name, token) {
    let body = { id: profile, isPublic: isPublic === "on" ? true : false, name: name }
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/profiletags`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(body),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static createAndAddToPostProfileTag(profile, name, itemId, token) {
    let body = { id: profile, isPublic: true, name: name, itemId: itemId }
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/profiletags`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(body),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static editProfileTag(profile, isPublic, name, tagId, token) {
    let body = { id: profile, isPublic: isPublic, name: name, tagId: tagId }
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/profiletags/${tagId}`, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(body),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static deleteProfileTag(profile, tagId, token) {
    let body = { id: profile, tagId: tagId }
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/profiletags/${tagId}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(body),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static deleteMultiProfileTags(profile, tags, postId, token) {
    let body = { profileTags: tags }

    const request = new Request(`${apiEnv()}v1/profiles/${profile}/items/${postId}/profileTags`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(body),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static deleteItemProfileTag(profile, tagId, itemId, token) {
    let body = { id: profile, tagId: tagId, itemId: itemId }
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/profiletags/${tagId}/${itemId}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(body),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static addItemProfileTag(profile, tagId, itemId, token) {
    let body = { id: profile, tagId: tagId, itemId: itemId }
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/profiletags/${tagId}/${itemId}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(body),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static createProfile(name, label, personal, avatar, image, domain, token) {
    let imageForm = new FormData()
    image && image !== "" && imageForm.append("image", image)
    imageForm.append("name", name)
    imageForm.append("label", label)
    imageForm.append("workspaceDomain", domain)
    imageForm.append("personal", personal)
    avatar && avatar !== "" && imageForm.append("avatar", avatar)
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      },
    }
    return axios
      .post(`${apiEnv()}v1/workspaces`, imageForm, headers)
      .then((response) => {
        if (response.status === 200) {
          return response
        }
        return response.json().then((response) => {
          throw response
        })
      })
      .catch((error) => {
        throw error
      })
  }

  static updateProfile(obj, profileName, token) {
    const request = new Request(`${apiEnv()}v1/profiles/${profileName}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(obj),
    })
    return fetch(request)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response.json().then((response) => {
          throw response
        })
      })
      .catch((error) => {
        throw error
      })
  }

  static profileAvatarUpload(imageFile, token) {
    let urlApi = `${apiEnv()}v1/profiles/avatar`
    let image = new FormData()
    image.append("image", imageFile)
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token.access_token}`,
        "MeshApp-host": "meshapp",
      },
    }
    return axios
      .post(urlApi, image, headers)
      .then((response) => {
        if (response.status === 200) {
          return response.data
        }
        return response.then((response) => {
          throw response
        })
      })
      .catch((error) => {
        throw error
      })
  }
}

export default profileApi

import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'
import mergeWith from 'lodash/mergeWith'
import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

function customizer(objValue: any, srcValue: any) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue)
  }
}

export default function contentLibraryReducer(state: any = initialState.contentLibraryReducer, action: any) {
  switch (action.type) {
    case types.TOGGLE_CONTENT_FORM_STATE:
      return {
        ...state,
        contentLibraryForm: action.contentLibraryForm,
        formAction: action.formAction,
        postObj: action.postObj,
      }
    case types.TOGGLE_DYNAMIC_CONTENT_FORM_STATE:
      return {
        ...state,
        dynamicContentLibraryForm: action.contentLibraryForm,
        dynamicFormAction: action.formAction,
        dynamicPostObjId: action.postObjId,
      }
    case types.TOGGLE_CONTENT_LIBRARY_PANEL:
      return {
        ...state,
        contentLibraryPanel: action.contentLibraryPanel,
      }
    case types.FETCHING_CONTENT_LIBRARY:
      return {
        ...state,
        loadingContentLibrary: true,
        errorFetchContentLibrary: false,
      }
    case types.ERROR_FETCH_CONTENT_LIBRARY:
      return {
        ...state,
        loadingContentLibrary: false,
        errorFetchContentLibrary: action.error,
      }
    case types.CLEAN_CONTENT_LIBRARY:
      return {
        ...state,
        loadingContentLibrary: false,
        errorFetchContentLibrary: false,
        contentLibrary: undefined,
        contentLibraryParams: undefined,
      }
    case types.RECEIVE_CONTENT_LIBRARY:
      const { params, response } = action
      if (!params.shouldRefresh) {
        const { contentLibraryParams, contentLibrary } = state
        if (
          contentLibraryParams?.search === params.search &&
          contentLibraryParams?.order === params.order &&
          contentLibraryParams?.orderBy === params.orderBy &&
          contentLibraryParams?.limit === params.limit &&
          contentLibraryParams?.cursor !== params.cursor
        ) {
          const clonedContentLibrary = cloneDeep(contentLibrary)
          return {
            ...state,
            loadingContentLibrary: false,
            errorFetchContentLibrary: false,
            contentLibrary: mergeWith(clonedContentLibrary, response, customizer),
            contentLibraryParams: params,
          }
        }
      }
      return {
        ...state,
        loadingContentLibrary: false,
        errorFetchContentLibrary: false,
        contentLibrary: response,
        contentLibraryParams: params,
      }
    /** */
    case types.VALID_CONTENT_PAGE_POST_LINK:
      return {
        ...state,
        loadingValidContentPagePostLink: false,
        errorValidateContentPagePostLink: false,
        validContentPagePostLink: action.response,
      }
    case types.INVALID_CONTENT_PAGE_POST_LINK:
      return {
        ...state,
        loadingValidContentPagePostLink: false,
        errorValidateContentPagePostLink: action.eror,
        validContentPagePostLink: undefined,
      }
    case types.REQUEST_VALIDATE_CONTENT_PAGE_POST_LINK:
      return {
        ...state,
        loadingValidContentPagePostLink: true,
        errorValidateContentPagePostLink: false,
        validContentPagePostLink: undefined,
      }
    case types.CLEAN_VALIDATE_CONTENT_PAGE_POST_LINK:
      return {
        ...state,
        loadingValidContentPagePostLink: false,
        errorValidateContentPagePostLink: false,
        validContentPagePostLink: undefined,
      }
    /** */
    case types.SUCCESS_FETCH_POSTS_TO_TEST_CONTENT_PAGE:
      return {
        ...state,
        loadingPostsToTest: false,
        errorFetchPostsToTest: false,
        successPostsToTest: action.response,
      }
    case types.ERROR_FETCH_POSTS_TO_TEST_CONTENT_PAGE:
      return {
        ...state,
        loadingPostsToTest: false,
        errorFetchPostsToTest: action.error,
        successPostsToTest: undefined,
      }
    case types.REQUEST_POSTS_TO_TEST_CONTENT_PAGE:
      return {
        ...state,
        loadingPostsToTest: true,
        errorFetchPostsToTest: false,
        successPostsToTest: undefined,
      }
    /** */
    case types.SUCCESS_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE:
      return {
        ...state,
        loadingSetDefaultPostToTest: false,
        errorSetDefaultPostToTest: false,
        successSetDefaultPostToTest: action.response,
      }
    case types.ERROR_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE:
      return {
        ...state,
        loadingSetDefaultPostToTest: false,
        errorSetDefaultPostToTest: action.error,
        successSetDefaultPostToTest: undefined,
      }
    case types.REQUEST_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE:
      return {
        ...state,
        loadingSetDefaultPostToTest: true,
        errorSetDefaultPostToTest: false,
        successSetDefaultPostToTest: undefined,
      }
    case types.CLEAN_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE:
      return {
        ...state,
        loadingSetDefaultPostToTest: false,
        errorSetDefaultPostToTest: false,
        successSetDefaultPostToTest: undefined,
      }
    /** */
    case types.SUCCESS_FETCH_PREVIEW_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPreviewContentPagePost: false,
        errorFetchPreviewContentPagePost: false,
        successFetchPreviewContentPagePost: action.response,
      }
    case types.ERROR_FETCH_PREVIEW_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPreviewContentPagePost: false,
        errorFetchPreviewContentPagePost: action.error,
        successFetchPreviewContentPagePost: undefined,
      }
    case types.REQUEST_PREVIEW_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPreviewContentPagePost: true,
        errorFetchPreviewContentPagePost: false,
        successFetchPreviewContentPagePost: undefined,
      }
    case types.CLEAN_FETCH_PREVIEW_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPreviewContentPagePost: false,
        errorFetchPreviewContentPagePost: false,
        successFetchPreviewContentPagePost: undefined,
      }
    /** */
    case types.SUCCESS_FETCH_PUBLIC_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPublicContentPagePost: false,
        errorFetchPublicContentPagePost: false,
        successFetchPublicContentPagePost: action.response,
      }
    case types.ERROR_FETCH_PUBLIC_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPublicContentPagePost: false,
        errorFetchPublicContentPagePost: action.error,
        successFetchPublicContentPagePost: undefined,
      }
    case types.REQUEST_PUBLIC_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPublicContentPagePost: true,
        errorFetchPublicContentPagePost: false,
        successFetchPublicContentPagePost: undefined,
      }
    case types.CLEAN_FETCH_PUBLIC_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPublicContentPagePost: false,
        errorFetchPublicContentPagePost: false,
        successFetchPublicContentPagePost: undefined,
      }
    /** */
    case types.SUCCESS_DETACH_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingDetachContentPagePost: false,
        errorFetchDetachContentPagePost: false,
        successFetchDetachContentPagePost: action.response,
      }
    case types.ERROR_DETACH_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingDetachContentPagePost: false,
        errorFetchDetachContentPagePost: action.error,
        successFetchDetachContentPagePost: undefined,
      }
    case types.REQUEST_DETACH_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingDetachContentPagePost: true,
        errorFetchDetachContentPagePost: false,
        successFetchDetachContentPagePost: undefined,
      }
    case types.CLEAN_DETACH_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingDetachContentPagePost: false,
        errorFetchDetachContentPagePost: false,
        successFetchDetachContentPagePost: undefined,
      }
    /** */
    case types.SUCCESS_FETCH_CONTENT_PAGE_LIST_BY_POST:
      return {
        ...state,
        loadingContentPageListByPost: false,
        errorFetchContentPageListByPost: false,
        successFetchContentPageListByPost: action.response,
      }
    case types.ERROR_FETCH_CONTENT_PAGE_LIST_BY_POST:
      return {
        ...state,
        loadingContentPageListByPost: false,
        errorFetchContentPageListByPost: action.error,
        successFetchContentPageListByPost: undefined,
      }
    case types.REQUEST_FETCH_CONTENT_PAGE_LIST_BY_POST:
      return {
        ...state,
        loadingContentPageListByPost: true,
        errorFetchContentPageListByPost: false,
        successFetchContentPageListByPost: undefined,
      }
    case types.CLEAN_FETCH_CONTENT_PAGE_LIST_BY_POST:
      return {
        ...state,
        loadingContentPageListByPost: false,
        errorFetchContentPageListByPost: false,
        successFetchContentPageListByPost: undefined,
      }
    /** */
    case types.SUCCESS_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE:
      return {
        ...state,
        loadingContentPageListByWorkspace: false,
        errorFetchContentPageListByWorkspace: false,
        successFetchContentPageListByWorkspace: action.response,
      }
    case types.ERROR_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE:
      return {
        ...state,
        loadingContentPageListByWorkspace: false,
        errorFetchContentPageListByWorkspace: action.error,
        successFetchContentPageListByWorkspace: undefined,
      }
    case types.REQUEST_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE:
      return {
        ...state,
        loadingContentPageListByWorkspace: true,
        errorFetchContentPageListByWorkspace: false,
        successFetchContentPageListByWorkspace: undefined,
      }
    case types.CLEAN_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE:
      return {
        ...state,
        loadingContentPageListByWorkspace: false,
        errorFetchContentPageListByWorkspace: false,
        successFetchContentPageListByWorkspace: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_CREATE_POST:
      return {
        ...state,
        loadingCreatePost: false,
        errorCreatePost: action.error,
        successCreatePost: undefined,
      }
    case types.REQUEST_CREATE_POST:
      return {
        ...state,
        loadingCreatePost: true,
        errorCreatePost: false,
        successCreatePost: undefined,
      }
    case types.SUCCESS_CREATE_POST:
      return {
        ...state,
        loadingCreatePost: false,
        errorCreatePost: false,
        successCreatePost: action.response,
      }
    case types.CLEAN_CREATE_POST:
      return {
        ...state,
        loadingCreatePost: false,
        errorCreatePost: false,
        successCreatePost: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_UPDATE_POST:
      return {
        ...state,
        loadingUpdatePost: false,
        errorUpdatePost: action.error,
        successUpdatePost: undefined,
      }
    case types.REQUEST_UPDATE_POST:
      return {
        ...state,
        loadingUpdatePost: true,
        errorUpdatePost: false,
        successUpdatePost: undefined,
      }
    case types.SUCCESS_UPDATE_POST:
      return {
        ...state,
        loadingUpdatePost: false,
        errorUpdatePost: false,
        successUpdatePost: action.response,
      }
    case types.CLEAN_UPDATE_POST:
      return {
        ...state,
        loadingUpdatePost: false,
        errorUpdatePost: false,
        successUpdatePost: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_UPDATE_POST_WITH_SCHEMA:
      return {
        ...state,
        loadingUpdatePostWithSchema: false,
        errorUpdatePostWithSchema: action.error,
        successUpdatePostWithSchema: undefined,
      }
    case types.REQUEST_UPDATE_POST_WITH_SCHEMA:
      return {
        ...state,
        loadingUpdatePostWithSchema: true,
        errorUpdatePostWithSchema: false,
        successUpdatePostWithSchema: undefined,
      }
    case types.SUCCESS_UPDATE_POST_WITH_SCHEMA:
      return {
        ...state,
        loadingUpdatePostWithSchema: false,
        errorUpdatePostWithSchema: false,
        successUpdatePostWithSchema: action.response,
      }
    case types.CLEAN_UPDATE_POST_WITH_SCHEMA:
      return {
        ...state,
        loadingUpdatePostWithSchema: false,
        errorUpdatePostWithSchema: false,
        successUpdatePostWithSchema: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ADD_MEDIA_TO_CONTENT_LIBRARY_RESPONSE:
      const oldContentLibrary = state.contentLibrary
      if (oldContentLibrary) {
        let clonedOldContentLibrary = cloneDeep(state.contentLibrary)
        clonedOldContentLibrary = {
          ...clonedOldContentLibrary,
          media: {
            ...clonedOldContentLibrary.media,
            [action.mediaObj.id]: action.mediaObj,
          },
        }
        return {
          ...state,
          contentLibrary: clonedOldContentLibrary,
        }
      }
      return {
        ...state,
      }
    case types.DRAFT_POST:
      return {
        ...state,
        draftPost: action.postObj,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_FETCH_SINGLE_POST:
      return {
        ...state,
        loadingFetchSinglePost: false,
        errorFetchSinglePost: action.error,
        successFetchSinglePost: undefined,
      }
    case types.REQUEST_SINGLE_POST:
      return {
        ...state,
        loadingFetchSinglePost: true,
        errorFetchSinglePost: false,
        successFetchSinglePost: undefined,
      }
    case types.SUCCESS_FETCH_SINGLE_POST:
      return {
        ...state,
        loadingFetchSinglePost: false,
        errorFetchSinglePost: false,
        successFetchSinglePost: action.response,
      }
    case types.CLEAN_FETCH_SINGLE_POST:
      return {
        ...state,
        loadingFetchSinglePost: false,
        errorFetchSinglePost: false,
        successFetchSinglePost: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    default:
      return state
  }
}
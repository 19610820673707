import React, { ChangeEvent, Fragment, useState } from "react"
import { match } from "ts-pattern"
import { Objects } from "../immutable"
import {
  InjectKey,
  SDFCheckbox,
  SDFGroup,
  SDFRadio,
  SDFSelect,
  SDFSelectContext,
  SDFSelectPosts,
  SDFTextArea,
  SDFTextInput,
  SDForm,
  SDFormField,
} from "./sdftypes"
import { ProvideInjectValues } from "./provideInjectValues"
import { Promises } from "../promises"
// @ts-ignore
import { Toasts } from "../toasts/toasts"
import Select from "./FixRequiredSelect"
import PostSearch from "../../areas/skynet/content/PostSearch"
import { ContextInput, ContextV, JobsApi, Post1 } from "../../areas/skynet/jobs/jobsApi"
import { FluxioPostId, IndexedId, ProfileName } from "../../areas/skynet/jobs/jobsSchema"
import { Modal } from "react-bootstrap"
import { MkParser, Parser } from "../Parser"

export type FormRenderClient = {
  getContentLibrary: JobsApi["getContentLibrary"]
  // which (proxied) endpoint to submit to
  submit: JobsApi["submit"]
}

type SDFormRenderProps<ServerResponse = void> = {
  form: SDForm
  submitProps?: { label?: string; labelElement?: JSX.Element }
  client: FormRenderClient
  profile: ProfileName
  provideInjectValues: ProvideInjectValues
  responseHandler: SDFResponseHandler<ServerResponse>
  submitStyle?: string[]
  groupStyle?: string[]
  containerStyle?: string[]
  onUserSubmit?: () => void
}

// handle the response returned after submitting
export interface SDFResponseHandler<ServerResponse> {
  decode: Parser<ServerResponse>
  onResponse: (e: ServerResponse) => Promise<void>
}

export function mkVoidSDFResponseHandler<A>(): SDFResponseHandler<A> {
  return {
    decode: MkParser.any(),
    onResponse(e: A) {
      return Promises.void()
    },
  }
}

export function renderTextInput(p: SDFTextInput, value: string, setValue: (v: string) => void): JSX.Element {
  const required = !(p.optional ?? false)
  return (
    <div key={p.id} className='form-group w-100'>
      <p>{p.label}</p>
      <input
        value={value}
        name={p.id}
        id={p.id}
        type='text'
        className='form-control'
        required={required}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
      ></input>
    </div>
  )
}

function renderCheckbox(p: SDFCheckbox, value: boolean, setValue: (v: boolean) => void): JSX.Element {
  // TODO: required????
  return (
    <div key={p.id} className='form-group w-100'>
      <span>{p.label}</span>
      <input
        value={value.toString()}
        name={p.id}
        id={p.id}
        type='checkbox'
        className='custom-control-input'
        onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(!value)}
      ></input>
    </div>
  )
}

function renderGroup(
  p: SDFGroup,
  value: Record<string, any>,
  setValue: (value: Record<string, any>) => void,
  groupStyle?: string[]
): JSX.Element {
  const updateText = (id: string) => (v: string) => {
    setValue(Objects.set(value, id, v))
  }

  const updateBool = (id: string) => (v: boolean) => {
    setValue(Objects.set(value, id, v))
  }
  const groupClass: string = groupStyle ? groupStyle.map((c: string) => c).join(" ") : ""

  return (
    <div key={p.id} className={"form-group"} style={{ padding: "20px 10px 20px 10px", border: "1px #ffffffe3 solid" }}>
      <span>{p.label}</span>
      <div className={groupClass}>
        {p.fields.map((field) =>
          match(field)
            .with({ type: "text-input" }, (txt) => renderTextInput(txt, value[txt.id], updateText(txt.id)))
            .with({ type: "textarea" }, (txta) => renderTextArea(txta, value[txta.id], updateText(txta.id)))
            .with({ type: "checkbox" }, (chk) => renderCheckbox(chk, value[chk.id], updateBool(chk.id)))
            .with({ type: "select" }, (sel) => renderSelect(sel, value[sel.id], updateText(sel.id)))
            .with(
              { type: "radio" },
              (rad) => renderRadio(p.id, rad, value[rad.id], updateText(rad.id))
              //<Radio fieldId={p.id} key={rad.id} p={rad} value={value[rad.id]} setValue={updateText(rad.id)} />
            )
            .exhaustive()
        )}
      </div>
    </div>
  )
}

type SelectOption = { label: string; value: string; selected?: boolean }

function renderSelect(p: SDFSelect, value: string | undefined, setValue: (v: string) => void): JSX.Element {
  const required = !(p.optional ?? false)
  const fullValue: SelectOption | undefined = p.options.find((opt) => opt.value === value)
  return (
    <div className='form-group w-100' style={{ marginTop: "10px" }} key={p.id}>
      <span>{p.label}</span>
      <div>
        <Select
          required={required}
          onChange={(e: any) => setValue(e.value)}
          value={fullValue}
          name={p.label}
          options={p.options}
        />
      </div>
    </div>
  )
}

function renderRadio(
  fieldId: string,
  p: SDFRadio,
  value: string | undefined,
  setValue: (v: string) => void
): JSX.Element {
  const required = !(p.optional ?? false)

  return (
    <div className='form-group w-100' style={{ marginTop: "10px" }} key={p.id}>
      <span>{p.label}</span>
      <div className='form-group radio-row'>
        {p.options.map((opt, idx) => (
          <div key={p.id + idx}>
            <input
              type='radio'
              id={fieldId + opt.value + idx}
              name={fieldId + "radioGrp"}
              value={opt.value}
              required={required}
              checked={value === opt.value}
              onChange={(e: any) => setValue(opt.value)}
            />

            <label htmlFor={p.id + idx}>{opt.label}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export function renderTextArea(p: SDFTextArea, value: string, setValue: (v: string) => void): JSX.Element {
  return (
    <div key={p.id} className='form-group' style={{ marginTop: "10px" }}>
      <p>{p.label}</p>
      <textarea
        value={value}
        name={p.id}
        id={p.id}
        className='form-control w-100'
        style={{ minHeight: "150px" }}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)}
      />
    </div>
  )
}

interface ContextProps {
  field: SDFSelectContext
  value: ContextInput[]
  profile: ProfileName
  cl: {
    getContentLibrary: JobsApi["getContentLibrary"]
  }
  setValue: (v: ContextInput[]) => void
}

function mergePostList(arr1: ContextInput[], arr2: ContextInput[]) {
  const arr1Posts: IndexedId[] = arr1.flatMap((p) =>
    match(p)
      .with({ type: "post" }, (pv) => [pv.value.id])
      .with({ type: "manual" }, () => [])
      .exhaustive()
  )
  const arr2Posts: ContextInput[] = arr2.flatMap((p) =>
    match(p)
      .with({ type: "post" }, (pv) => (arr1Posts.includes(pv.value.id) ? [] : [pv]))
      .with({ type: "manual" }, () => [])
      .exhaustive()
  )
  return [...arr1, ...arr2Posts]
}

export const SelectedContext: React.FC<ContextProps> = (props) => {
  const p = props.field
  const value = props.value
  const [open, setOpen] = useState(false)
  const [mode, setMode] = useState("search")

  const [manualValue, setManualValue] = useState<{ title: string; description: string } | undefined>(undefined)

  const setManualTitle = (title: string) =>
    setManualValue({ title: title, description: manualValue ? manualValue.description : "" })
  const setManualDescription = (description: string) =>
    setManualValue({ description: description, title: manualValue ? manualValue.title : "" })

  const renderSearch = () => (
    <Modal
      className='jobs-modal'
      backdropClassName='modal-component-backdrop'
      size='xl'
      show={open}
      onHide={() => setOpen(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
        <span style={{}} onClick={() => setMode(mode === "manual" ? "search" : "manual")}>
          {mode === "manual" ? "Manual" : "Search"}
        </span>
      </Modal.Header>
      <Modal.Body>
        {mode === "search" && (
          <PostSearch
            client={props.cl}
            profile={props.profile}
            selected={value.flatMap((p) =>
              match(p)
                .with({ type: "post" }, (pv) => [pv.value.id])
                .with({ type: "manual" }, () => [])
                .exhaustive()
            )}
            onPostSelected={(e) => {
              setOpen(false)
              const selE: ContextInput[] = e.map((p) => ({ type: "post", value: p }))

              props.setValue(mergePostList(value, selE))
              //  props.setValue(value.concat(e.map((p) => ({ type: "post", value: p }))))
            }}
          />
        )}
        {mode === "manual" && (
          <div>
            <div className='form-group'>
              {renderTextInput(
                { type: "text-input", id: p.id + "-title", label: "Title" },
                manualValue ? manualValue.title : "",
                setManualTitle
              )}
              {renderTextArea(
                { type: "textarea", id: p.id + "-desc", label: "Description" },
                manualValue ? manualValue.description : "",
                setManualDescription
              )}
            </div>
            <button
              onClick={() => {
                setOpen(false)
                const manualEntry: ContextInput[] = manualValue ? [{ type: "manual", value: manualValue }] : []
                setManualValue(undefined)
                props.setValue([...value, ...manualEntry])
              }}
            >
              {" "}
              Add
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )

  const renderContextEntry = (p: ContextInput) =>
    match<ContextInput, JSX.Element>(p)
      .with({ type: "manual" }, (p) => (
        <li
          key={p.value.title}
          className='list-group-item'
          style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block" }}
        >
          {p.value.title}
          <button type='button' className='close' onClick={() => removeContextEntry(p)}>
            <span aria-hidden='true'>×</span>
            <span className='sr-only'>Close</span>
          </button>
        </li>
      ))
      .with({ type: "post" }, (p) => (
        <li
          key={p.value.id}
          className='list-group-item'
          style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block" }}
        >
          {p.value.title}
          <button type='button' className='close' onClick={() => removeContextEntry(p)}>
            <span aria-hidden='true'>×</span>
            <span className='sr-only'>Close</span>
          </button>
        </li>
      ))
      .exhaustive()

  const renderList = () => (
    <>
      <button type='button' className='w-50 btn btn-fluxio btn-primary btn-block' onClick={() => setOpen(true)}>
        {`+ Add Context`}
      </button>
      {value.length > 0 && (
        <div className='content-library py-2 w-50'>
          <ul className='list-group'>{value.map(renderContextEntry)}</ul>
        </div>
      )}
    </>
  )
  const removeContextEntry = (entry: ContextInput) => {
    var newList = [...value] // make a separate copy of the array
    var index = newList.indexOf(entry)
    if (index !== -1) {
      newList.splice(index, 1)
      props.setValue(newList)
    }
  }

  return (
    <div className='form-group' style={{ marginTop: "10px" }}>
      {open && renderSearch()}
      <p>{p.label}</p>
      {renderList()}
    </div>
  )
}

interface Props {
  field: SDFSelectPosts
  value: Post1[]
  profile: ProfileName
  cl: {
    getContentLibrary: JobsApi["getContentLibrary"]
  }
  setValue: (v: Post1[]) => void
}

const SelectedPosts: React.FC<Props> = (props) => {
  const p = props.field
  const value = props.value
  const [open, setOpen] = useState(false)

  const selectionValid = (selected: IndexedId[]) =>
    (p.min === undefined || selected.length >= p.min) && //
    (p.max === undefined || selected.length <= p.max)

  const renderSearch = () => (
    <Modal
      className='jobs-modal'
      backdropClassName='modal-component-backdrop'
      size='lg'
      style={{ height: "90vh" }}
      show={open}
      onHide={() => setOpen(false)}
    >
      <Modal.Header closeButton style={{ height: "10%" }}>
        <Modal.Title>Select posts</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "90%" }}>
        <PostSearch
          client={props.cl}
          profile={props.profile}
          selected={value.map((p) => p.id)}
          onPostSelected={(e) => {
            setOpen(false)
            props.setValue(e)
          }}
          selectionValid={selectionValid}
        />
      </Modal.Body>
    </Modal>
  )

  const renderPost = (p: Post1) => (
    <li
      key={p.id}
      className='list-group-item'
      style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block" }}
    >
      {p.title}
      <button type='button' className='close' onClick={() => removePost(p)}>
        <span aria-hidden='true'>×</span>
        <span className='sr-only'>Close</span>
      </button>
    </li>
  )

  const renderList = () => (
    <>
      <button type='button' className='w-50 btn btn-fluxio btn-primary btn-block' onClick={() => setOpen(true)}>
        {`Select posts (${value.length} selected)`}
      </button>
      {value.length > 0 && (
        <div className='content-library py-2 w-50'>
          <ul className='list-group'>{value.map(renderPost)}</ul>
        </div>
      )}
    </>
  )
  const removePost = (post: Post1) => {
    var newList = [...value] // make a separate copy of the array
    var index = newList.indexOf(post)
    if (index !== -1) {
      newList.splice(index, 1)
      props.setValue(newList)
    }
  }

  return (
    <div className='form-group' style={{ marginTop: "10px" }}>
      {open && renderSearch()}
      <p>{p.label}</p>
      {renderList()}
    </div>
  )
}

// Since every input is controlled, we have to provide default values for every field,
// we pick defautlts based on the type, but we could get them from the field definition.
function valueDefaults(fields: SDFormField[]): Record<string, any> {
  let obj: Record<string, any> = {}
  for (const field of fields) {
    const [k, v] = match<SDFormField, [string, any]>(field)
      .with({ type: "text-input" }, (s) => [s.id, ""])
      .with({ type: "checkbox" }, (s) => [s.id, false])
      .with({ type: "select" }, (s) => [s.id, null])
      .with({ type: "radio" }, (s) => [s.id, s.options.find((opt) => opt.selected === true)?.value])
      .with({ type: "select-sites" }, (s) => [s.id, null])
      .with({ type: "textarea" }, (s) => [s.id, ""])
      .with({ type: "select-posts" }, (s) => [s.id, []])
      .with({ type: "group" }, (s) => [s.id, valueDefaults(s.fields)])
      .with({ type: "select-context" }, (s) => [s.id, []])
      .exhaustive()
    obj[k] = v
  }
  return obj
}

function outputDefaults(
  injectFields: Record<string, InjectKey>,
  injectValues: ProvideInjectValues,
  injectLiteral: Record<string, string>,
  fields: SDFormField[]
): Record<string, any> {
  let obj: Record<string, any> = {}

  for (const injectKey in injectFields) {
    obj[injectKey] = injectValues[injectFields[injectKey]]
  }

  for (const injectKey in injectLiteral) {
    obj[injectKey] = injectLiteral[injectKey]
  }

  for (const field of fields) {
    const [k, v] = match<SDFormField, [string, any]>(field)
      .with({ type: "text-input" }, (s) => [s.id, ""])
      .with({ type: "checkbox" }, (s) => [s.id, false])
      .with({ type: "select" }, (s) => [s.id, null])
      .with({ type: "radio" }, (s) => [s.id, s.options.find((opt) => opt.selected === true)?.value])
      .with({ type: "select-sites" }, (s) => [s.id, null])
      .with({ type: "textarea" }, (s) => [s.id, ""])
      .with({ type: "select-posts" }, (s) => [s.id, []])
      .with({ type: "group" }, (s) => [s.id, valueDefaults(s.fields)])
      .with({ type: "select-context" }, (s) => [s.id, []])
      .exhaustive()
    obj[k] = v
  }
  return obj
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TestForm: SDForm = {
  label: "Drilldown",
  fields: [
    { type: "select-context", id: "context", label: "" },
    { type: "text-input", id: "title", label: "title" },
    { type: "text-input", id: "prompt", label: "description" },

    /* {
      type: "group",
      id: "6",
      label: "Pergunta 1",
      fields: [
        {
          type: "select",
          id: "7",
          label: "yes/no",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
        },
        { type: "textarea", id: "8", label: "Why?" },
      ],
    },
    {
      type: "group",
      id: "9",
      label: "Pergunta 2",
      fields: [
        {
          type: "select",
          id: "10",
          label: "yes/no",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
        },
        { type: "text-input", id: "11", label: "Why?" },
      ],
    },*/
  ],
  submit: { type: "proxied", endpointName: "startJob" },
  injectDynamic: {},
  injectLiteral: { jobId: "drillDown" },
}

// Render a form from a SDForm spec
export function FormRender2<ServerResponse>(props: SDFormRenderProps<ServerResponse>): JSX.Element {
  const form: SDForm = props.form

  // the internal state of the form
  const [values, setValues] = useState<Record<string, any>>(valueDefaults(form.fields))

  // the final output when submitted
  const [output, setOutput] = useState<Record<string, any>>(
    outputDefaults(form.injectDynamic, props.provideInjectValues, form.injectLiteral, form.fields)
  )

  const [loading, setLoading] = useState(false)

  const updateText = (id: string) => (v: string) => {
    setValues(Objects.set(values, id, v))
    const out: string = v
    setOutput(Objects.set(output, id, out))
  }

  const updateBool = (id: string) => (v: boolean) => {
    setValues(Objects.set(values, id, v))
    const out: boolean = v
    setOutput(Objects.set(output, id, out))
  }

  const updatePosts = (id: string) => (v: Post1[]) => {
    setValues(Objects.set(values, id, v))
    const out: FluxioPostId[] = v.map((p) => p.sourceId)
    setOutput(Objects.set(output, id, out))
  }

  const updateContext = (id: string) => (v: ContextInput[]) => {
    setValues(Objects.set(values, id, v))
    const out: ContextV[] = v.map((p) =>
      match<ContextInput, ContextV>(p)
        .with({ type: "manual" }, (p) => ({ type: "manual", value: p.value }))
        .with({ type: "post" }, (p) => ({ type: "post", value: p.value.sourceId }))
        .exhaustive()
    )

    setOutput(Objects.set(output, id, out))
  }

  const updateGroup = (id: string) => (v: Record<string, any>) => {
    setValues(Objects.set(values, id, v))
    const out: Record<string, any> = v
    setOutput(Objects.set(output, id, out))
  }

  const fields = form.fields.map((field) =>
    match(field)
      .with({ type: "select-context" }, (sContext) => (
        <SelectedContext
          key={sContext.id}
          field={sContext}
          profile={props.profile}
          cl={props.client}
          value={values[sContext.id]}
          setValue={updateContext(sContext.id)}
        />
      ))
      .with({ type: "text-input" }, (txt) => renderTextInput(txt, values[txt.id], updateText(txt.id)))
      .with({ type: "textarea" }, (txta) => renderTextArea(txta, values[txta.id], updateText(txta.id)))
      .with({ type: "checkbox" }, (chk) => renderCheckbox(chk, values[chk.id], updateBool(chk.id)))
      .with({ type: "select" }, (sel) => renderSelect(sel, values[sel.id], updateText(sel.id)))
      .with(
        { type: "radio" },
        (rad) => renderRadio(field.id, rad, values[rad.id], updateText(rad.id))
        //<Radio fieldId={field.id} key={rad.id} p={rad} value={values[rad.id]} setValue={updateText(rad.id)} />
      )
      .with({ type: "select-sites" }, (sel) => <Fragment key={sel.id} />)
      .with({ type: "select-posts" }, (sPosts) => (
        <SelectedPosts
          key={sPosts.id}
          field={sPosts}
          profile={props.profile}
          cl={props.client}
          value={values[sPosts.id]}
          setValue={updatePosts(sPosts.id)}
        />
      ))
      .with({ type: "group" }, (sel) =>
        renderGroup(sel, values[sel.id], updateGroup(sel.id), props.groupStyle ? props.groupStyle : undefined)
      )
      .exhaustive()
  )

  const submit: () => void = () => {
    setLoading(true)
    return match(form.submit)
      .with({ type: "echo" }, () => {
        setLoading(false)
      })
      .with({ type: "proxied" }, (p) => {
        if (props.onUserSubmit) props.onUserSubmit()
        props.client.submit(p.endpointName, output, props.responseHandler.decode).then(
          // success
          (e) => {
            props.responseHandler.onResponse(e).then(() => {
              setLoading(false)
              Toasts.success("Submitted")
            })
          },
          // failure
          (e) => {
            setLoading(false)
            Toasts.handleError("Error")(e)
          }
        )
      })
      .exhaustive()
  }

  const submitClass: string = props.submitStyle ? props.submitStyle.map((c: string) => c).join(" ") : ""
  const containerClass: string = props.containerStyle ? props.containerStyle.map((c: string) => c).join(" ") : ""

  return (
    <form
      style={{ display: "flex", flexDirection: "column" }}
      onSubmit={(e) => {
        e.preventDefault()
        submit()
      }}
      className={containerClass}
    >
      {/* 
      https://stackoverflow.com/a/51507806
      Disable submit with "enter"
      According to https://www.w3.org/TR/2018/SPSD-html5-20180327/forms.html#implicit-submission
      the default button for the form is the first one
      */}
      <button type='submit' disabled style={{ display: "none" }} aria-hidden='true'></button>
      <div className={"form-group"}>{fields}</div>

      <div style={{ maxWidth: "70%" }}>
        <button
          disabled={loading}
          style={{ padding: "2px", marginTop: "10px", maxWidth: "30%" }}
          className={`${submitClass}${loading ? " disabled" : ""}`}
          type='submit'
        >
          {props.submitProps ? (props.submitProps.labelElement ? props.submitProps.labelElement : "Submit") : "Submit"}
        </button>
      </div>
    </form>
  )
}

interface Formula {
  type: "posts" | "expression"
  name: string
  limit?: number
  postIds?: Array<string>
}

interface CmsSettings {
  limit: number
  pagination: boolean
}

export type { Formula, CmsSettings }

export type RawUrl = string & { __brand: "RawUrl" }
export function mkRawUrl(s: string): RawUrl {
  return s as RawUrl // no validation
}

export type MediaItemId = string & { __brand: "MediaItemId" }
export function mkMediaItemId(s: string): MediaItemId {
  return s as MediaItemId // no validation
}

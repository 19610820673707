import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function routesReducer(state = initialState.routesReducer, action) {
  switch (action.type) {
    case types.GET_ROUTES_USED:
      return {
        ...state,
      }
    case types.GET_LAST_ROUTE_USED:
      return {
        route: action.route
      }
    case types.ADD_ROUTE_TAB_ID:
      return {
        ...state,
        component: action.component,
        tabId: action.value,
      }
    case types.ADD_ROUTE_TOPIC_ID:
      return {
        ...state,
        component: action.component,
        topicId: action.value,
      }
    case types.ADD_ROUTE_PILL_ID:
      return {
        ...state,
        component: action.component,
        pillId: action.value,
      }
    case types.ADD_ROUTE_POST_ID:
      return {
        ...state,
        component: action.component,
        postId: action.value,
      }
    default:
      return state
  }
}
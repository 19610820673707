import React from "react"

type Status = "completed" | "running" | "failed" | "cancelled" | "pending"
type Type = "short" | "complete"
interface StateButtonProps {
  status: Status
  type?: Type
}

function failedSvg() {
  return (
    <svg
      role='img'
      focusable='false'
      viewBox='0 0 24 24'
      aria-label='Status Failed'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: "100%", display: "block" }}
    >
      <path d='M12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22ZM12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18ZM12 14C12.5523 14 13 13.5523 13 13L13 7C13 6.44771 12.5523 6 12 6C11.4477 6 11 6.44771 11 7L11 13C11 13.5523 11.4477 14 12 14Z'></path>
    </svg>
  )
}

function successSvg() {
  return (
    <svg
      role='img'
      focusable='false'
      viewBox='0 0 24 24'
      aria-label='Status Passed'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: "100%", display: "block" }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 14.5858L7.70711 12.2929C7.31658 11.9024 6.68342 11.9024 6.29289 12.2929C5.90237 12.6834 5.90237 13.3166 6.29289 13.7071L9.29289 16.7071C9.68342 17.0976 10.3166 17.0976 10.7071 16.7071L17.7071 9.70711C18.0976 9.31658 18.0976 8.68342 17.7071 8.29289C17.3166 7.90237 16.6834 7.90237 16.2929 8.29289L10 14.5858Z'
      ></path>
    </svg>
  )
}
function runningSvg() {
  return (
    <svg
      role='img'
      focusable='false'
      viewBox='0 0 24 24'
      aria-label='Status Running Dark'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: "100%", display: "block" }}
    >
      <circle fill='#6A6A6A' cx='12' cy='12' r='10'></circle>
      <path
        fill='#FFFFFF'
        stroke='#FFFFFF'
        d='M22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12'
        style={{
          transformOrigin: "center center",
          animation: "8s linear -1s infinite normal none running animation-cnf17l",
        }}
      ></path>
      <path
        fill='#FFFFFF'
        stroke='#FFFFFF'
        d='M22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12'
        style={{
          transformOrigin: "center center",
          animation: "8s linear -3s infinite normal none running animation-cnf17l",
        }}
      ></path>
    </svg>
  )
}

function pendingSvg() {
  return (
    <svg
      role='img'
      focusable='false'
      viewBox='0 0 24 24'
      aria-label='Status Queued'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: "100%", display: "block" }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12ZM7 10.5C6.17 10.5 5.5 11.17 5.5 12C5.5 12.83 6.17 13.5 7 13.5C7.83 13.5 8.5 12.83 8.5 12C8.5 11.17 7.83 10.5 7 10.5ZM17 10.5C16.17 10.5 15.5 11.17 15.5 12C15.5 12.83 16.17 13.5 17 13.5C17.83 13.5 18.5 12.83 18.5 12C18.5 11.17 17.83 10.5 17 10.5ZM12 10.5C11.17 10.5 10.5 11.17 10.5 12C10.5 12.83 11.17 13.5 12 13.5C12.83 13.5 13.5 12.83 13.5 12C13.5 11.17 12.83 10.5 12 10.5Z'
      ></path>
    </svg>
  )
}

function buttonStateComplete(status: Status, type?: Type): JSX.Element {
  let className = "buttonJ running"
  let label = "Waiting"
  let svg = runningSvg()
  if (status === "completed") {
    className = "buttonJ completed"
    label = "Completed"
    svg = successSvg()
  } else if (status === "running") {
    className = "buttonJ running"
    label = "Running"
    svg = runningSvg()
  } else if (status === "failed" || status === "cancelled") {
    //this needs to be in match
    className = "buttonJ failed"
    label = status.toUpperCase()
    svg = failedSvg()
  } else if (status === "pending") {
    // TODO: complete
    className = "buttonJ pending"
    label = "Pending"
    svg = pendingSvg()
  }

  if (type === "short") className += " short"
  return (
    <div title={status} className={className}>
      <div color='#FFFFFF' className='buttonJText'>
        {svg}
      </div>
      {type && type === "complete" && label}
    </div>
  )
}

export default class StateButton extends React.Component<StateButtonProps> {
  render(): JSX.Element {
    const status = this.props.status
    const type = this.props.type ? this.props.type : "complete"
    return buttonStateComplete(status, type)
  }
}

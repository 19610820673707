import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function profileTagsReducer(state = initialState.profileTagsReducer, action) {
  switch (action.type) {
    case types.GET_POST_PROFILE_TAGS_BY_POST_TO_EDIT:
      return {
        ...state,
        postId: action.postId,
        loading: true
      }
    case types.CONFIRM_GET_POST_PROFILE_TAGS_BY_POST_TO_EDIT:
      return {
        ...state,
        profileTagsByPostToEdit: action.content,
        postId: action.postId,
        loading: false
      }
    case types.ERROR_GET_POST_PROFILE_TAGS_BY_POST_TO_EDIT:
      return {
        ...state,
        error: action.error,
        loading: false
      }
    case types.CLEAN_GET_POST_PROFILE_TAGS_BY_POST_TO_EDIT:
      return {
        ...state,
        profileTagsByPostToEdit: undefined,
        postId: undefined,
        loading: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_DELETE_ITEM_PROFILE_TAGS:
      return {
        ...state,
        errorDeleteProfileTagFromPost: false,
        loadingDeleteProfileTagFromPost: true
      }
    case types.CONFIRMED_DELETE_ITEM_PROFILE_TAGS:
      return {
        ...state,
        deletedProfileTagFromPost: [...state.deletedProfileTagFromPost, action.content],
        errorDeleteProfileTagFromPost: false,
        loadingDeleteProfileTagFromPost: false
      }
    case types.ERROR_DELETE_ITEM_PROFILE_TAGS:
      return {
        ...state,
        errorDeleteProfileTagFromPost: action.error,
        loadingDeleteProfileTagFromPost: false
      }
    default:
      return state
  }
}
import React, { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from "react"
import { AgentId, ChatMessage, FluxioPostId, ProfileName } from "./jobsSchema"
import DOMPurify from "dompurify"
import { JobTaskId } from "./jobsSchema"
import ReplySSE from "./replySSE"
import { Arrays, Objects } from "../../../lib/immutable"
import CopyToClipboard from "react-copy-to-clipboard"
import { ContextV, JobsApi } from "./jobsApi"
import { ResponseLoader, StreamingReponseState } from "./assistantPanel"
import { match } from "ts-pattern"
import { DocumentViewerActions } from "./documentViewerActions"
import { Promises } from "../../../lib/promises"
import { FaEffectButton } from "../../../lib/components/FaEffectButton"
import { Popover } from "react-text-selection-popover"

export type ChatItem = StreamingReponseState | ChatMessage | ResponseLoader

function itemIsMessage(c: ChatItem): c is ChatMessage {
  return c.type === "assistant" || c.type === "user" || c.type === "error"
}

const StreamingReponse: React.FC<{
  state: StreamingReponseState
  cl: ChatConversationProps["jobsClient"]
  messagesContainerRef: RefObject<HTMLDivElement | null>
}> = (props) => {
  return (
    <ReplySSE
      jobsClient={props.cl}
      jobTaskId={props.state.task}
      // TODO: errors
      handleAssistantMessage={(message: string, error: boolean) => {}}
      messagesContainerRef={props.messagesContainerRef}
      onDone={(msg) => props.state.completed(msg)}
      renderEmpty={
        <div className='loading-indicator'>
          <div className='loading-dot'></div>
          <div className='loading-dot'></div>
          <div className='loading-dot'></div>
        </div>
      }
    />
  )
}

export function dropNonMensages(msgs: ChatItem[]): ChatMessage[] {
  return msgs.flatMap((m) =>
    match<ChatItem, ChatMessage[]>(m)
      .with({ type: "error" }, (p) => [p])
      .with({ type: "assistant" }, (p) => [p])
      .with({ type: "user" }, (p) => [p])
      .with({ type: "response-loader" }, (p) => [])
      .with({ type: "streaming-response" }, (p) => [])
      .exhaustive()
  )
}

interface ChatConversationProps {
  postId: FluxioPostId
  profileName: ProfileName
  jobsClient: JobsApi
  setMessages: Dispatch<SetStateAction<ChatItem[]>>
  messages: ChatItem[]
  context?: ContextV[]
  actions: DocumentViewerActions.All
  agent: {
    selected: AgentId | undefined
  }
}

const ChatConversation: React.FC<ChatConversationProps> = (props) => {
  const messages = props.messages
  const setMessages = props.setMessages
  const messagesContainerRef = React.useRef<HTMLDivElement | null>(null)
  const [replierResponded, setReplierResponded] = useState(true)
  const [errorMessage, setErrorMessage] = useState<ChatMessage | undefined>(undefined)
  const [jobTaskId, setJobTaskId] = useState<JobTaskId | undefined>(undefined)

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
    }
  }, [props.messages])

  const handleAssistantMessage = (aMessage: string, error: boolean = false) => {
    if (error) {
      setErrorMessage({ message: aMessage, type: "error" })
    } else {
      const replyMessage: ChatMessage = { message: aMessage, type: "assistant" }
      setMessages((prevState) => [...prevState, replyMessage])
    }
    setReplierResponded(true)

    setJobTaskId(undefined)
  }

  const handleRetryMessage = () => {
    setReplierResponded(false)
    setErrorMessage(undefined)

    //get last user message in chat
    const onlyMessages = dropNonMensages(messages)
    const userMessage = onlyMessages[onlyMessages.length - 1].message
    props.jobsClient
      .startChatJob(onlyMessages, userMessage, props.postId, props.agent.selected, props.context ?? [])
      .then((t) => setJobTaskId(t))
  }

  const inputActions: UserInputActions = {
    addUserMessage: (msg) => setMessages((prev) => [...prev, { type: "user", message: msg }]),
    submit: (msg?: string) => {
      setReplierResponded(false)
      setErrorMessage(undefined)
      if (msg !== undefined) setMessages((prev) => [...prev, { type: "user", message: msg }])

      const message = msg ?? ""
      // ignore waiters, those don't need to be sent in a chat log
      props.jobsClient // the empty message works because the backend drops the empty message, but we should fix the model
        .startChatJob(dropNonMensages(messages), message, props.postId, props.agent.selected, props.context ?? [])
        .then((t) => setJobTaskId(t))
    },
  }

  const messageActions: MessageActions = {
    removeMessage: (idx: number) => setMessages((prevState) => Arrays.removeAt(prevState, idx)),
    updateMessage: (idx: number, msg: string) =>
      setMessages((prevState) =>
        Arrays.udpateAtWhen(prevState, idx, itemIsMessage, (item) => Objects.set(item, "message", msg))
      ),
  }

  return (
    <div className='tt-show-actions' style={{ width: "100%" }}>
      <div className='messages-container' ref={messagesContainerRef}>
        <SimpleMessage content={"What do you want to know or change about this topic?"} type={"assistant"} />
        {messages.map((message, index) =>
          match(message)
            .with({ type: "streaming-response" }, (state) => (
              <StreamingReponse
                key={index}
                cl={props.jobsClient}
                messagesContainerRef={messagesContainerRef}
                state={state}
              />
            ))
            .with({ type: "response-loader" }, () => (
              <div key={index} className='loading-indicator'>
                <div className='loading-dot'></div>
                <div className='loading-dot'></div>
                <div className='loading-dot'></div>
              </div>
            ))
            .otherwise((message) => (
              <Message
                key={index}
                content={message.message}
                type={message.type}
                msgIdx={index}
                actions={props.actions}
                selectedPost={props.postId}
                messageActions={messageActions}
              />
            ))
        )}
        {!replierResponded && jobTaskId && (
          <ReplySSE
            jobsClient={props.jobsClient}
            jobTaskId={jobTaskId}
            handleAssistantMessage={handleAssistantMessage}
            messagesContainerRef={messagesContainerRef}
          />
        )}
        {errorMessage && (
          <div>
            <SimpleMessage content={errorMessage.message} type={errorMessage.type} />
            <i className='fas fa-redo' onClick={handleRetryMessage}></i>
          </div>
        )}

        {!replierResponded && (
          <div className='loading-indicator'>
            <div className='loading-dot'></div>
            <div className='loading-dot'></div>
            <div className='loading-dot'></div>
          </div>
        )}
      </div>
      <UserInput replierResponded={replierResponded} actions={inputActions} />
    </div>
  )
}

const SendMessageAsChild: React.FC<{ submitMessage: () => Promise<void> }> = ({ submitMessage }) => {
  const [loading, setLoading] = useState(false)
  if (loading) return <i style={{ color: "#2125298a" }} className='fas fa-spinner fa-spin' />
  return (
    <i
      className='fas fa-plus-circle'
      style={{ color: "#2125298a" }}
      onClick={(e) => {
        e.preventDefault()
        setLoading(true)
        submitMessage().then(() => setLoading(false))
      }}
    ></i>
  )
}

function splitAtColon(text: string): { title: string; description: string } | undefined {
  const split = text.split(":")
  if (split.length !== 2) return undefined
  else return { title: split[0], description: split[1].trimStart() }
}

type MessageType = "user" | "assistant" | "error"

const EditedMessage: React.FC<{ initial: string; type: MessageType; saveMessage: (msg: string) => void }> = ({
  initial,
  type,
  saveMessage,
}) => {
  const [value, setValue] = useState(initial)

  // background color has alpha so it fucks up elements inside, fix someday i guess
  const color = type === "user" ? "#788cdc" : type === "assistant" ? "#ffdc64" : "inherit"
  const save: () => void = () => saveMessage(value)

  return (
    <div className={type === "user" ? "user-container" : "assistant-container"}>
      <div className={type === "user" ? "user-message" : "assistant-message"} style={{ minWidth: "100%" }}>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
          {<i className={`far fa-save`} style={{ color: "#2125298a" }} onClick={() => save()}></i>}
        </div>
        <textarea
          style={{ minWidth: "100%", minHeight: "200px", backgroundColor: color }}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey) {
              save()
            }
          }}
        />
      </div>
    </div>
  )
}

export const SimpleMessage: React.FC<{ content: string; type: MessageType }> = ({ content, type }) => {
  return (
    <div className={type === "user" ? "user-container" : "assistant-container"}>
      <div className={type === "user" ? "user-message" : "assistant-message"}>
        <p>{content}</p>
      </div>
    </div>
  )
}

interface MessageActions {
  removeMessage: (idx: number) => void
  updateMessage: (idx: number, msg: string) => void
}

interface MessageProps {
  content: string
  type: MessageType
  msgIdx: number
  actions: DocumentViewerActions.All
  messageActions: MessageActions
  selectedPost: FluxioPostId
}

export const Message: React.FC<MessageProps> = ({ content, type, msgIdx, actions, messageActions, selectedPost }) => {
  const [copied, setCopied] = useState(false)
  const [editing, setEditing] = useState(false)
  const targetPopoverRef = useRef(null)
  const mountPopoverRef = useRef(null)
  const featureFlagSelectionCreate = false // TODO: remove once this shit is done

  const contentCleaned = DOMPurify.sanitize(content)

  const copyClicked = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  const submitMessage: () => Promise<void> = () => actions.addAsChild(selectedPost, content)

  if (editing) {
    return (
      <EditedMessage
        initial={content}
        type={type}
        saveMessage={(msg) => {
          setEditing(false)
          messageActions.updateMessage(msgIdx, msg)
        }}
      />
    )
  }

  return (
    <div className={type === "user" ? "user-container" : "assistant-container"} ref={targetPopoverRef}>
      <div ref={mountPopoverRef} className={type === "user" ? "user-message" : "assistant-message"}>
        {featureFlagSelectionCreate && (
          <Popover
            render={({ textContent }) => {
              if (textContent === undefined || textContent.length === 0) {
                return null
              }

              const text: string = textContent
              const splitText: { title: string; description: string } | undefined = splitAtColon(text)

              return (
                <div
                  id={`popover-${msgIdx}`}
                  role='tooltip'
                  style={{ background: "red", padding: "5px", borderRadius: "5px" }}
                >
                  <FaEffectButton
                    classDefault='fas fa-i-cursor'
                    action={() => actions.addAsChild(selectedPost, text)}
                    title={`Create post from selection '${text}'`}
                    failMsg='Failed creating post'
                    style={{ color: "#2125298a" }}
                  />{" "}
                  {splitText !== undefined && (
                    <span className='fa-layers fa-fw'>
                      <FaEffectButton
                        classDefault='fas fa-i-cursor'
                        action={() => actions.addAsChild(selectedPost, text)}
                        title={`Create post with title '${splitText.title}' and description '${splitText.description}'`}
                        failMsg='Failed creating post'
                        style={{ color: "#2125298a" }}
                      />
                      <span className='fas fa-layers-text' style={{ color: "#2125298a" }}>
                        :
                      </span>
                    </span>
                  )}
                </div>
              )
            }}
            mount={mountPopoverRef.current!}
            target={targetPopoverRef.current!}
          />
        )}
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
          <FaEffectButton
            action={() => Promises.pure(setEditing(true))}
            classDefault='fas fa-i-cursor'
            failMsg=''
            title='Edit'
          />
          <SendMessageAsChild submitMessage={submitMessage} />
          <i
            className={`fas fa-times`}
            style={{ color: "#2125298a" }}
            onClick={(e) => {
              e.preventDefault()
              messageActions.removeMessage(msgIdx)
            }}
          ></i>
        </div>
        <p dangerouslySetInnerHTML={{ __html: contentCleaned }}></p>
      </div>
      <div className={`copy-message ${copied ? "" : " copy-hidden"}`}>
        <CopyToClipboard onCopy={copyClicked} text={contentCleaned} options={{ format: "text/html", debug: true }}>
          {!copied ? (
            <i className={`fas fa-copy`} style={{ color: "#2125298a" }} title='Copy text'></i>
          ) : (
            <i className={`fas fa-check`} style={{ color: "#2125298a" }} title='Copied'></i>
          )}
        </CopyToClipboard>
      </div>
    </div>
  )
}

interface UserInputActions {
  addUserMessage(msg: string): void
  submit(lastMessage?: string): void
}
interface UserInputProps {
  replierResponded: boolean
  defaultUserMessage?: string
  actions: UserInputActions
}

const UserInput: React.FC<UserInputProps> = ({ replierResponded, actions }) => {
  const [userMessage, setUserMessage] = useState("")

  const addMessage = () => {
    if (userMessage === "" || !replierResponded) return
    const formattedUserMessage = userMessage.replace(/\n/g, "<br>")
    setUserMessage("")
    actions.addUserMessage(formattedUserMessage)
  }

  const submit = () => {
    if (!replierResponded) return
    if (userMessage === "") return actions.submit(undefined)
    const formattedUserMessage = userMessage.replace(/\n/g, "<br>")
    setUserMessage("")
    actions.submit(formattedUserMessage)
  }

  // if the textarea is empty the submit action only submits the log, if not it adds the message then submits
  const submitLabel = userMessage === "" ? "Submit" : "Add message and submit"

  return (
    <form
      id='message-input'
      style={{ display: "flex", columnGap: "2px", alignItems: "flex-end", padding: "10px" }}
      onSubmit={(e) => e.preventDefault()}
    >
      <textarea
        value={userMessage}
        placeholder='Prompt'
        className='form-control'
        disabled={!replierResponded}
        style={{ maxWidth: "90%", minHeight: "100px" }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && e.shiftKey) {
            e.preventDefault()
            addMessage()
          }
          if (e.key === "Enter" && e.ctrlKey) {
            e.preventDefault()
            submit()
          }
        }}
        onChange={(e) => setUserMessage(e.target.value)}
      />

      <div style={{ display: "flex", rowGap: "2px", flexDirection: "column" }}>
        <i
          className={`fas fa-plus fa-lg${replierResponded ? "" : " disabled"}`}
          title='Add message (Shift+Enter)'
          style={{ minHeight: "22px" }}
          onClick={(e) => addMessage()}
        ></i>
        <i
          className={`fas fa-paper-plane fa-lg${replierResponded ? "" : " disabled"}`}
          style={{ minHeight: "22px" }}
          title={`${submitLabel} (Control+Enter)`}
          onClick={(e) => submit()}
        ></i>
      </div>
    </form>
  )
}

export default ChatConversation

import { z } from "zod"

const KnownFormSchema = z.enum(["start-tiktok"])
export type KnownForm = z.infer<typeof KnownFormSchema>

const InjectKeySchema = z.enum(["profileName", "fluxioPostId"])
export type InjectKey = z.infer<typeof InjectKeySchema>

const sdfFieldBase = {
  id: z.string(),
  label: z.string(),
  optional: z.optional(z.boolean()),
}

const SDFTextInputSchema = z.object({
  ...sdfFieldBase,
  type: z.literal("text-input"),
})
export type SDFTextInput = z.infer<typeof SDFTextInputSchema>

const SDFTextAreaSchema = z.object({
  ...sdfFieldBase,
  type: z.literal("textarea"),
})
export type SDFTextArea = z.infer<typeof SDFTextAreaSchema>

const SDFCheckboxSchema = z.object({
  ...sdfFieldBase,
  type: z.literal("checkbox"),
})
export type SDFCheckbox = z.infer<typeof SDFCheckboxSchema>

const SDFSelectSchema = z.object({
  ...sdfFieldBase,
  type: z.literal("select"),
  options: z.array(z.object({ value: z.string(), label: z.string(), selected: z.optional(z.boolean()) })),
})
export type SDFSelect = z.infer<typeof SDFSelectSchema>

const SDFRadioSchema = z.object({
  ...sdfFieldBase,
  type: z.literal("radio"),
  options: z.array(z.object({ value: z.string(), label: z.string(), selected: z.optional(z.boolean()) })),
})
export type SDFRadio = z.infer<typeof SDFRadioSchema>

const SDFSelectSitesSchema = z.object({
  type: z.literal("select-sites"),
  id: z.string(),
  label: z.string(),
  optional: z.optional(z.boolean()),
})

export type SDFSelectSites = z.infer<typeof SDFSelectSitesSchema>

const SDFSelectPostsSchema = z.object({
  ...sdfFieldBase,
  type: z.literal("select-posts"),
  min: z.optional(z.number().int()),
  max: z.optional(z.number().int()),
})
export type SDFSelectPosts = z.infer<typeof SDFSelectPostsSchema>

const SDFSelectContextSchema = z.object({
  ...sdfFieldBase,
  type: z.literal("select-context"),
})
export type SDFSelectContext = z.infer<typeof SDFSelectContextSchema>

// SDFormFieldSchema without Group, SelectSites, SelectPosts, SelectContext
const SDFormSubFieldSchema = z.discriminatedUnion("type", [
  // WARNING: evolve in conjunction with SDFormFieldSchema
  SDFTextInputSchema,
  SDFTextAreaSchema,
  SDFCheckboxSchema,
  SDFSelectSchema,
  SDFRadioSchema,
])

export type SDFormSubField = z.infer<typeof SDFormSubFieldSchema>

const SDFGroupSchema = z.object({
  ...sdfFieldBase,
  type: z.literal("group"),
  fields: z.array(SDFormSubFieldSchema),
})

export type SDFGroup = z.infer<typeof SDFGroupSchema>

const SDFormFieldSchema = z.discriminatedUnion("type", [
  // WARNING: evolve in conjunction with SDFormSubFieldSchema
  SDFTextInputSchema,
  SDFTextAreaSchema,
  SDFCheckboxSchema,
  SDFSelectSchema,
  SDFSelectSitesSchema,
  SDFSelectPostsSchema,
  SDFGroupSchema,
  SDFRadioSchema,
  SDFSelectContextSchema,
])

export type SDFormField = z.infer<typeof SDFormFieldSchema>

const SDFSubmitSchema = z.discriminatedUnion("type", [
  z.object({ type: z.literal("echo") }),
  z.object({ type: z.literal("proxied"), endpointName: z.string() }),
])
export type SDFSubmit = z.infer<typeof SDFSubmitSchema>

export const SDFormSchema = z.object({
  label: z.string(),
  fields: z.array(SDFormFieldSchema),
  submit: SDFSubmitSchema,
  injectDynamic: z.record(z.string(), InjectKeySchema),
  injectLiteral: z.record(z.string(), z.string()),
})

export type SDForm = z.infer<typeof SDFormSchema>

export function clickEvent(eventLabel: string, eventCat = "CTA") {
  //Google Event
  if (window.gtag) {
    GoogleEvent("click", eventLabel, eventCat)
  }

  if (window.fbq) {
    FacebookEvent("click", eventLabel)
  }

  //MixPanel
  if (window.mixpanel) {
    MixPanelEvent("click", eventLabel)
  }
}

export function pageView(eventType: string, route: any) {
  //Google Event
  if (window.gtag) {
    GoogleEvent(eventType, route, eventType)
  }

  //MixPanel
  if (window.mixpanel) {
    MixPanelEvent(eventType, route)
  }
}

export function pageScroll(percentage: number) {
  if (window.gtag) {
    GoogleEvent("scroll", "Page scroll", "Page scroll", percentage)
  }
}

export function elementInViewport(el: any) {
  if (window.gtag) {
    GoogleEvent("element_in_viewport", `Viewport: ${el.id}`, "Element in Viewport", el.id)
  }
}

export function elementLoaded(el: any) {
  if (window.gtag) {
    GoogleEvent("element_loaded", `Loaded: ${el.id}`, "Element Loaded", el.id)
  }
}

export function formInput(el: any, formId: string) {
  if (window.gtag) {
    GoogleEvent("form_input", `Input: ${el.target.placeholder}`, `Form ${formId}`, el.target.placeholder)
  }
}

export function GoogleEvent(eventType: string, eventLabel: string, eventCat?: string, value = 1) {
  window.gtag("event", eventType, {
    event_label: removeHTMLTags(eventLabel),
    event_category: eventCat,
    value: value,
    nonInteraction: false,
  })
}

export function removeHTMLTags(possibleHtml: string): string {
  return possibleHtml.replace(/<\/?[^>]+(>|$)/g, "")
}

export function MixPanelEvent(eventType: string, eventLabel: string) {
  window.mixpanel.track(eventType, { where: eventLabel })
}

export function FacebookEvent(eventType: string, eventLabel: string) {
  window.fbq("track", eventType, {
    content_name: eventLabel,
    content_category: "CTA",
  })
}

//utils
export function isElementInViewport(el: any) {
  const rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

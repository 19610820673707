import React from "react"
import { JobId, JobInput, JobRun, JobRunDetail, JobRunId, JobStatus, JobTask, ProfileName } from "./jobsSchema"
import { Table, Accordion } from "react-bootstrap"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { RouteParams } from "./jobsManager"
import { JobsNavigation } from "./jobsNavigation"
import ConfirmAction from "../marketplace/components/confirmAction"
import { Toasts } from "../../../lib/toasts/toasts"
import StateButton from "./stateButton"
import { Paginated, Paging } from "../../../lib/jsonRequest/Paginated"
import { useLoader } from "../../../lib/hooks/useLoader"
import { isOk } from "../../../lib/monads/result/result"
import { RelativeTimestamp } from "../../../lib/components/RelativeTimestamp"
import { match } from "ts-pattern"
import { Instant } from "../../../lib/brand/Instant"

interface Client {
  runsOfJobAndProfile(jobId: JobId, profileName: ProfileName, paging: Paging): Promise<Paginated<JobRun>>
  listSitesDestinations(): Promise<[]>
  jobRunDetail(jobRunId: JobRunId): Promise<JobRunDetail>
  deleteJobRun(jobRunId: JobRunId): Promise<void>
  cancelJobRun(jobRunId: JobRunId): Promise<void>
}
interface OwnProps {
  jobsClient: Client
  jobId: JobId
  profileName: ProfileName
  navigation: JobsNavigation
  // onRunClick: (runId: JobRunId) => void // Add the click handler prop
  handleTabChange: (tabKey: string) => void // Add the click handler prop
  handleJumpToDocument: (jobRunId: JobRunId) => void
}

function completeDuration(startedAt: Instant, completedAt: Instant): string {
  const startTime = new Date(startedAt).getTime()
  const endTime = new Date(completedAt).getTime()

  if (isNaN(startTime) || isNaN(endTime)) {
    alert("Please enter valid start and end dates.")
    return "-"
  }

  if (startTime > endTime) {
    alert("End date and time must be after start date and time.")
    return "-"
  }

  const timeDifference = Math.abs(endTime - startTime)
  const days = Math.floor(timeDifference / (1000 * 60 * 60) / 24)
  const hours = Math.floor(timeDifference / (1000 * 60 * 60))
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((timeDifference / 1000) % 60)

  let time = ""
  if (days > 0) time = days + "d "
  if (hours > 0) time = time + hours + "h "
  if (minutes > 0) time = time + minutes + "m "
  if (seconds > 0) time = time + seconds + "s"

  return time
}

type JobRunsTableProps = OwnProps & RouteComponentProps<RouteParams>

interface JobRunsTableState {
  expandedRow?: number
  runs?: JobRun[]
  sortOrder: string
}

const Task: React.FC<{ task: JobTask }> = (props) => {
  const task = props.task
  const time = match(task.status)
    .with({ type: "pending" }, (e) => <></>)
    .with({ type: "cancelled" }, (e) => (
      <span title={"Reason"} data-for='pipeline-action-tooltip' data-place='bottom'>
        {e.reason}
      </span>
    ))
    .with({ type: "completed" }, (e) => (
      <span title={"Started:" + e.start + " Ended:" + e.end} data-for='pipeline-action-tooltip' data-place='bottom'>
        {completeDuration(e.start, e.end)}
      </span>
    ))
    .with({ type: "failed" }, (e) => (
      <>
        <span title={"Started:" + e.start + " Ended:" + e.end} data-for='pipeline-action-tooltip' data-place='bottom'>
          {completeDuration(e.start, e.end)}
        </span>
        <p>Error: {e.error}</p>
      </>
    ))
    .with({ type: "running" }, (e) => (
      <span title={"Started:" + e.start} data-for='pipeline-action-tooltip' data-place='bottom'>
        <RelativeTimestamp at={e.start} />
      </span>
    ))
    .exhaustive()

  const status = match(task.status)
    .with({ type: "pending" }, (e) => <StateButton status='pending' type='short' />)
    .with({ type: "cancelled" }, (e) => <StateButton status='cancelled' type='short' />)
    .with({ type: "completed" }, (e) => <StateButton status='completed' type='short' />)
    .with({ type: "failed" }, (e) => <StateButton status='failed' type='short' />)
    .with({ type: "running" }, (e) => <StateButton status='running' type='short' />)
    .exhaustive()

  return (
    <tr key={task.id}>
      <td></td>
      <td style={{ display: "flex", flexDirection: "row", columnGap: "6px" }}>
        {status}
        {task.label}
      </td>
      <td>{time}</td>
      <td>
        {task.run && task.run.shortRun && task.run.shortRun.preview ? (
          <a href={task.run.shortRun.preview} rel='noreferrer' target='_blank'>
            Preview
          </a>
        ) : (
          "--"
        )}
      </td>
    </tr>
  )
}

const JobRunDetailExpand: React.FC<{ jobRunId: JobRunId; input: JobInput[]; client: Client }> = (props) => {
  const detail = useLoader(() => props.client.jobRunDetail(props.jobRunId), [props.jobRunId])

  if (detail === undefined) return <></>
  if (!isOk(detail)) return <></>

  /*
  const jobInputInformation: JSX.Element = (
    <div>
      {
        <p>
          {props.input
            .filter((i) => i.key !== "jobId" && i.key !== "profileName")
            .map<string>((i) => i.value)
            .join(", ")}
        </p>
      }
    </div>
  )*/

  return (
    <tr>
      <td colSpan={5}>
        <Accordion>
          <Table striped={false}>
            <tbody>
              {detail.value().tasks.map((task) => (
                <Task key={task.id} task={task} />
              ))}
            </tbody>
          </Table>
        </Accordion>
      </td>
    </tr>
  )
}

class JobRunsTable extends React.Component<JobRunsTableProps, JobRunsTableState> {
  constructor(props: JobRunsTableProps) {
    super(props)
    this.state = {
      sortOrder: "desc",
    }
  }

  componentDidMount() {
    this.initializeListRun(this.props)
  }

  initializeListRun(props: JobRunsTableProps) {
    const pagination: Paging = { limit: 20, page: 1 } //bring the page state
    props.jobsClient.runsOfJobAndProfile(props.jobId, props.profileName, pagination).then((l) =>
      this.setState({
        ...this.state,
        runs: l.data,
      })
    )
  }

  toggleRow = (rowId: number) => {
    if (this.state.expandedRow !== rowId) {
      this.setState({ expandedRow: rowId })
    } else {
      this.setState((prevState) => ({
        expandedRow: prevState.expandedRow === rowId ? undefined : rowId,
      }))
    }
  }

  setSortOrder = () => {
    this.setState((prevState) => ({
      sortOrder: prevState.sortOrder === "asc" ? "desc" : "asc",
    }))
  }

  deleteRun = (jobRunId: JobRunId) =>
    this.props.jobsClient
      .deleteJobRun(jobRunId)
      .catch(Toasts.handleError("Error Deleting Job Run"))
      .then(() => this.initializeListRun(this.props))

  deleteButton = (jobRunId: JobRunId) => (
    <ConfirmAction message='Are you sure you want to delete this run?' hasConfirmation={() => this.deleteRun(jobRunId)}>
      <i className='far fa-trash-alt'></i>
    </ConfirmAction>
  )

  cancelButton = (jobRunId: JobRunId) => (
    <ConfirmAction message='Are you sure you want to cancel this run?' hasConfirmation={() => this.cancelRun(jobRunId)}>
      <i className='fas fa-ban'></i>
    </ConfirmAction>
  )
  cancelRun = (jobRunId: JobRunId) =>
    this.props.jobsClient
      .cancelJobRun(jobRunId)
      .catch(Toasts.handleError("Error canceling Run"))
      .then(() => this.initializeListRun(this.props))

  //onClick={() => this.handleRunDetailClick(row.id)} if we want detail
  /*handleRunDetailClick = (runId: JobRunId) => {
    this.props.onRunClick(runId)
  }*/

  actionsAvailable = (jobRunId: JobRunId, runStatus: JobStatus): JSX.Element => {
    if (runStatus.type === "completed" || runStatus.type === "cancelled")
      return <div className='hidden-actions'>{this.deleteButton(jobRunId)}</div>
    //running
    else return <div className='hidden-actions'>{this.cancelButton(jobRunId)}</div>
  }

  render(): JSX.Element {
    const { sortOrder, expandedRow, runs } = this.state

    const transformedData = (runs || []).map((item) => ({
      ...item,
      TransformedStartedAt: new Date(item.started).getTime(), // Convert to timestamp
    }))

    const sortedData = transformedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a.TransformedStartedAt - b.TransformedStartedAt
      } else {
        return b.TransformedStartedAt - a.TransformedStartedAt
      }
    })
    if (runs === undefined) return <></>
    if (sortedData.length > 0)
      return (
        <Table striped bordered w-auto='true' hover>
          <thead>
            <tr>
              <th>Job</th>
              <th>Status</th>
              <th>Started</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row, rowKey) => (
              <React.Fragment key={row.id}>
                <tr onClick={() => this.toggleRow(rowKey)} style={{ cursor: "pointer" }}>
                  <td>
                    <div>{row.name}</div>
                  </td>
                  <td>
                    <button className='buttonJTransparent'>
                      <StateButton status={row.status.type}></StateButton>
                    </button>
                  </td>
                  <td>
                    <span>
                      <RelativeTimestamp at={row.started} />
                    </span>
                  </td>
                  <td>
                    <span>
                      <span
                        title={"Completed at:" + row.completed ? row.completed : ""}
                        data-for='pipeline-action-tooltip'
                        data-place='bottom'
                      >
                        {row.completed && completeDuration(row.started, row.completed)}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span onClick={() => this.props.handleJumpToDocument(row.id)}>Document</span>
                  </td>
                  <td>
                    <div className='actions'>{this.actionsAvailable(row.id, row.status)}</div>
                  </td>
                </tr>
                {expandedRow === rowKey && (
                  <JobRunDetailExpand key={row.id} client={this.props.jobsClient} input={row.input} jobRunId={row.id} />
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )

    return (
      <div className='jobs-block-flex'>
        <div style={{ width: "50%" }} className='jobs-block-flex'>
          <h2 className='title'>No Job Runs yet</h2>
          <p className='description'> When you run a Job, your runs and results will appear here</p>

          <button className='buttonDoJob' onClick={() => this.props.handleTabChange("input")}>
            <span>
              Do<span className='dojob-color'>Job</span>
            </span>
          </button>
        </div>
      </div>
    )
  }
}

export default withRouter(JobRunsTable)

import cloneDeep from 'lodash/cloneDeep'
import {
  CHANGE_DATA_SET,
  CLEAR_SELECTED_FINDER,
  ERROR_FETCH_DATASET,
  ERROR_FETCH_LOADMORE_DATASET,
  FETCHING_DATASET,
  FETCHING_LOADMORE_DATASET,
  FINDER_TYPE_PUBLIC_PAGE_REDUCER,
  OBJECT_SELECTOR_HOVER,
  RECEIVE_DATASET,
  RECEIVE_LOADMORE_DATASET,
  REPLACE_FINDER_OBJECT,
  RESPONSIVE_TAB,
  SELECTED_FINDER,
  SELECTED_FINDER_TYPE,
  SELECTED_HUB_HANDLER_TO_EDIT,
  VIEW_PORT_SIZE_STATE_EXPERIENCE_MANAGER,
  WARNING_STATE_EM,
} from "../shared-store/actions/actionTypes"
import initialState from "../initialState"

export default function publicPageReducer(state: any = initialState.publicPageReducer, action: any) {
  switch (action.type) {
    case FETCHING_DATASET:
      return {
        ...state,
        fetchingDataSet: true,
        dataSet: undefined,
        errorFetchingDataSet: false,
      }
    case ERROR_FETCH_DATASET:
      return {
        ...state,
        fetchingDataSet: false,
        dataSet: undefined,
        errorFetchingDataSet: action.error,
      }
    case RECEIVE_DATASET:
      return {
        ...state,
        fetchingDataSet: false,
        dataSet: action.response,
        dataSetFilters: state.dataSetFilters ? state.dataSetFilters : action.response.filters, // only populate fiters on first call
        dataSetFilterParams: action.filters,
        errorFetchingDataSet: false,
      }
    case FETCHING_LOADMORE_DATASET:
      return {
        ...state,
        fetchingLoadMoreDataSet: true,
        errorFetchingLoadMoreDataSet: false,
      }
    case ERROR_FETCH_LOADMORE_DATASET:
      return {
        ...state,
        fetchingLoadMoreDataSet: false,
        errorFetchingLoadMoreDataSet: action.error,
      }
    case RECEIVE_LOADMORE_DATASET:
      let dataSet = cloneDeep(state.dataSet)
      dataSet.nextCursor = action.response.nextCursor
      dataSet.posts = [...state.dataSet.posts, ...action.response.posts]
      return {
        ...state,
        fetchingLoadMoreDataSet: false,
        dataSet,
        errorFetchingLoadMoreDataSet: false,
      }
    case CHANGE_DATA_SET:
      return {
        ...state,
        dataSet: action.dataset,
        dataSetFilters: action.dataset.filters,
      }
    case WARNING_STATE_EM:
      return {
        ...state,
        warningState: action.value,
      }
    case SELECTED_HUB_HANDLER_TO_EDIT:
      return {
        ...state,
        hubHandlerId: action.id,
        hubHandlerObject: action.object,
        hubHandlerParentId: action.parentId,
      }
    case SELECTED_FINDER:
      return {
        ...state,
        finderType: action.typeSelected,
        finderSelectedObject: action.selectedObject,
      }
    case CLEAR_SELECTED_FINDER:
      return {
        ...state,
        finderType: false,
        finderSelectedObject: undefined,
      }
    case OBJECT_SELECTOR_HOVER:
      return {
        ...state,
        objectSelectorHover: action.value,
      }
    case VIEW_PORT_SIZE_STATE_EXPERIENCE_MANAGER:
      return {
        ...state,
        viewPortSizeState: action.viewPortSize,
        viewPortScaleState: action.viewPortScale,
      }
    case RESPONSIVE_TAB:
      return {
        ...state,
        responsiveTab: action.tab,
      }
    case SELECTED_FINDER_TYPE:
      return {
        ...state,
        finderType: action.typeSelected,
      }
    case REPLACE_FINDER_OBJECT:
      return {
        ...state,
        finderSelectedObject: action.object,
      }
    case FINDER_TYPE_PUBLIC_PAGE_REDUCER:
      return {
        ...state,
        finderType:
          action.event !== "no-event-register"
            ? action.finderTab
              ? action.finderTab
              : action.event
            : state.finderType,
      }
    default:
      return state
  }
}
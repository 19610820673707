export const DOMAINS = "domains"
export const FORMULA_LAB = "formulalab"
export const PAGES = "pages"
export const OBJECTTEMPLATES = "object-templates"
export const SYMBOLS = "symbols"
export const GRAFLY = "grafly"
export const CUSTOM_EDITOR = "customeditor"
export const AUDIENCES = "audiences"
export const TOOLBOX = "toolbox"
export const MARKETPLACE = "marketplace"
export const SETTINGS = "settings"
export const WORKSPACE_SETTINGS = "workspace-settings"
export const PROJECTS = "projects"
export const SYSTEM = "system"
export const DESIGN = "design"
export const MEDIA_LIBRARY = "media-library"
export const CONTENT_LIBRARY = "content-library"
export const MARKETING = "marketing"
export const JOBS = "jobs"
export const DOJOB = "dojob"

const all = [
  DOMAINS,
  FORMULA_LAB,
  PAGES,
  OBJECTTEMPLATES,
  SYMBOLS,
  GRAFLY,
  CUSTOM_EDITOR,
  AUDIENCES,
  TOOLBOX,
  MARKETPLACE,
  SETTINGS,
  WORKSPACE_SETTINGS,
  PROJECTS,
  SYSTEM,
  DESIGN,
  MEDIA_LIBRARY,
  CONTENT_LIBRARY,
  MARKETING,
  JOBS,
  DOJOB,
]

const fluxioExclusive = all.filter((i) => !(i === SETTINGS || i === DOJOB || i === WORKSPACE_SETTINGS || i === JOBS))

export function isFluxioExclusiveSection(section /*: string*/) /*: boolean*/ {
  return fluxioExclusive.includes(section)
}

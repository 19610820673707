// @ts-ignore
import amplitude from 'amplitude-js'

class Metrics {
  static AmplitudeNavigation(type: string, properties: object) {
    //console.warn("AmplitudeNavigation", type, properties)
    amplitude.getInstance().logEvent(type, properties)
  }
}

export default Metrics
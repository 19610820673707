import apiEnv from "./shared-api/apiEnv"

const clientId = 2
const clientSecret = "oPyIlyhPRzPqf8LUUIroRsyaKdo9tKoR6sDBVgch"

/*
 * Gets access token for user
 * @param {object} credentials -  username & password
 */

class SessionApi {
  static getToken(credentials: any) {
    let getToken = {
      ...credentials,
      grant_type: "password",
      client_id: clientId,
      client_secret: clientSecret,
    }
    const request = new Request(`${apiEnv()}oauth/token`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(getToken),
    })
    return fetch(request)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }

        throw response
      })
      .catch((error) => {
        throw error
      })
  }

  static userInfo(token: any) {
    const request = new Request(`${apiEnv()}v1/user/info`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.access_token}`,
        Accept: "application/json",
      }),
    })
    return fetch(request)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        throw response
      })
      .catch((error) => {
        throw error
      })
  }

  static userFirstLogin(token: any) {
    const request = new Request(`${apiEnv()}v1/user/firstLogin`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })
    return fetch(request)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        throw response
      })
      .catch((error) => {
        throw error
      })
  }

  static personValidate(info: any, token: any) {
    const request = new Request(`${apiEnv()}v1/persons/validate`, {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token.access_token}`,
        "MeshApp-host": "meshapp",
      }),
      body: JSON.stringify(info),
    })
    return fetch(request)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        throw response
      })
      .catch((error) => {
        throw error
      })
  }

  static resetPassword(email: any) {
    const request = new Request(`${apiEnv()}v1/user/password/email`, {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({ email }),
    })
    return fetch(request)
      .then((response: any) => {
        if (response.status === 200) {
          return response.json()
        }
        return response.json().then((response: any) => {
          throw response
        })
      })
      .catch((error: any) => {
        throw error
      })
  }

  static createPassword(email: string, password: string, password_confirm: string, token: any) {
    let body = {
      email: email,
      password: password,
      password_confirmation: password_confirm,
      token: token,
    }
    const request = new Request(`${apiEnv()}v1/user/password/reset`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
      }),
      body: JSON.stringify(body),
    })
    return fetch(request)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response.json().then((response) => {
          throw response
        })
      })
      .catch((error) => {
        throw error
      })
  }

  static registerValidate(info: any) {
    const request = new Request(`${apiEnv()}v1/user/register`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
      }),
      body: JSON.stringify(info),
    })
    return fetch(request)
      .then((response: any) => {
        if (response.status === 200) {
          return response.json()
        }
        return response.json().then((response: any) => {
          throw response
        })
      })
      .catch((error: any) => {
        throw error
      })
  }

  static checkSocialPresence(profile: string, token: any) {
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/socials`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })
    return fetch(request)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        throw response
      })
      .catch((error) => {
        throw error
      })
  }

  static facebookLogin(info: any) {
    const request = new Request(`${apiEnv()}v1/persons/validate`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify(info),
    })
    return fetch(request)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        throw response
      })
      .catch((error) => {
        throw error
      })
  }

  static confirmAcount(token: any) {
    let body = { token: token }
    const request = new Request(`${apiEnv()}v1/user/activate`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(body),
    })
    return fetch(request)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response.json().then((response) => {
          throw response
        })
      })
      .catch((error) => {
        throw error
      })
  }

  static validateSocialGoogle(code: any, callback: any, scopes: any, token: any, profile: string) {
    let body = { code: code, scopes: scopes, callback }

    const request = new Request(`${apiEnv()}v1/profiles/${profile}/socials/google`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(body),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static validateSocialFacebook(fbaccessToken: any, profile: string, token: any) {
    let body = { accessToken: fbaccessToken }
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/socials/facebook`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(body),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static validateSocialTwitter(twaccessToken: any, profile: string, token: any) {
    let body = { accessToken: twaccessToken }
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/socials/twitter`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(body),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static removeSocialPresence(network: string, profile: string, token: any) {
    const request = new Request(`${apiEnv()}v1/profiles/${profile}/socials/${network}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static fetchAllAdmins(profileId: string, token: any, page: string) {
    const request = new Request(`${apiEnv()}v1/profiles/${profileId}/admins?page=${page}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static fetchSearchAdmins(profileId: string, query: string, limit: string, page: string, token: any) {
    const request = new Request(
      `${apiEnv()}v1/profiles/${profileId}/persons/search?query=${query}&limit=${limit}&page=${page}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          "MeshApp-host": "meshapp",
          Authorization: `Bearer ${token.access_token}`,
        }),
      }
    )
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static fetchAddAdmin(profileId: string, adminId: string, token: any) {
    const request = new Request(`${apiEnv()}v1/profiles/${profileId}/admins/${adminId}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static fetchDeleteAdmin(profileId: string, adminId: string, token: any) {
    const request = new Request(`${apiEnv()}v1/profiles/${profileId}/admins/${adminId}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
    })
    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        throw error
      })
  }

  static getRegisterLimit(token: string) {
    const url = `${apiEnv()}v1/inviterequired`
    const request = new Request(url, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    })
    return fetch(request)
      .then((response: any) => {
        if (response.status === 200) {
          return response.json()
        }
        return response.json().then((response: any) => {
          throw response
        })
      })
      .catch((error: any) => {
        throw error
      })
  }

  static googleApiLogin(credential: string, app: string) {
    const url = `${apiEnv()}v1/login/google`
    const request = new Request(url, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        access_token: credential,
        app: app,
      }),
    })
    return fetch(request)
      .then((response: any) => {
        if (response.status === 200) {
          return response.json()
        }
        return response.json().then((response: any) => {
          throw response
        })
      })
      .catch((error: any) => {
        throw error
      })
  }
}

export default SessionApi

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function cmsContentReducer(state: any = initialState.cmsContentReducer, action: any) {
  switch (action.type) {
    case types.ERROR_CREATE_FORMULA:
      return {
        ...state,
        errorCreateFormula: action.error,
        successCreateFormula: undefined,
        loadingCreateFormula: false,
      }
    case types.REQUEST_CREATE_FORMULA:
      return {
        ...state,
        errorCreateFormula: false,
        successCreateFormula: undefined,
        loadingCreateFormula: true,
      }
    case types.SUCCESS_CREATE_FORMULA:
      return {
        ...state,
        errorCreateFormula: false,
        successCreateFormula: action.response,
        loadingCreateFormula: false,
      }
    case types.CLEAR_CREATE_FORMULA:
      return {
        ...state,
        errorCreateFormula: false,
        successCreateFormula: undefined,
        loadingCreateFormula: false,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_UPDATE_FORMULA:
      return {
        ...state,
        errorUpdateFormula: action.error,
        successUpdateFormula: undefined,
        loadingUpdateFormula: false,
      }
    case types.REQUEST_UPDATE_FORMULA:
      return {
        ...state,
        errorUpdateFormula: false,
        successUpdateFormula: undefined,
        loadingUpdateFormula: true,
      }
    case types.SUCCESS_UPDATE_FORMULA:
      return {
        ...state,
        errorUpdateFormula: false,
        successUpdateFormula: action.response,
        loadingUpdateFormula: false,
      }
    case types.CLEAR_UPDATE_FORMULA:
      return {
        ...state,
        errorUpdateFormula: false,
        successUpdateFormula: undefined,
        loadingUpdateFormula: false,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_SET_OBJECT_FORMULA:
      return {
        ...state,
        errorSetObjectFormula: action.error,
        successSetObjectFormula: undefined,
        loadingSetObjectFormula: false,
      }
    case types.REQUEST_SET_OBJECT_FORMULA:
      return {
        ...state,
        errorSetObjectFormula: false,
        successSetObjectFormula: undefined,
        loadingSetObjectFormula: true,
      }
    case types.SUCCESS_SET_OBJECT_FORMULA:
      return {
        ...state,
        errorSetObjectFormula: false,
        successSetObjectFormula: action.response,
        loadingSetObjectFormula: false,
      }
    case types.CLEAR_SET_OBJECT_FORMULA:
      return {
        ...state,
        errorSetObjectFormula: false,
        successSetObjectFormula: undefined,
        loadingSetObjectFormula: false,
      }
    case types.ERROR_SET_INSTANCE_FORMULA:
      return {
        ...state,
        errorSetInstanceFormula: action.error,
        successSetInstanceFormula: undefined,
        loadingSetInstanceFormula: false,
      }
    case types.REQUEST_SET_INSTANCE_FORMULA:
      return {
        ...state,
        errorSetInstanceFormula: false,
        successSetInstanceFormula: undefined,
        loadingSetInstanceFormula: true,
      }
    case types.SUCCESS_SET_INSTANCE_FORMULA:
      return {
        ...state,
        errorSetInstanceFormula: false,
        successSetInstanceFormula: action.response,
        loadingSetInstanceFormula: false,
      }
    case types.CLEAR_SET_INSTANCE_FORMULA:
      return {
        ...state,
        errorSetInstanceFormula: false,
        successSetInstanceFormula: undefined,
        loadingSetInstanceFormula: false,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_UNSET_OBJECT_FORMULA:
      return {
        ...state,
        errorUnSetObjectFormula: action.error,
        successUnSetObjectFormula: undefined,
        loadingUnSetObjectFormula: false,
      }
    case types.REQUEST_UNSET_OBJECT_FORMULA:
      return {
        ...state,
        errorUnSetObjectFormula: false,
        successUnSetObjectFormula: undefined,
        loadingUnSetObjectFormula: true,
      }
    case types.SUCCESS_UNSET_OBJECT_FORMULA:
      return {
        ...state,
        errorUnSetObjectFormula: false,
        successUnSetObjectFormula: action.response,
        loadingUnSetObjectFormula: false,
      }
    case types.CLEAR_UNSET_OBJECT_FORMULA:
      return {
        ...state,
        errorUnSetObjectFormula: false,
        successUnSetObjectFormula: undefined,
        loadingUnSetObjectFormula: false,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_UNSET_INSTANCE_FORMULA:
      return {
        ...state,
        errorUnSetInstanceFormula: action.error,
        successUnSetInstanceFormula: undefined,
        loadingUnSetInstanceFormula: false,
      }
    case types.REQUEST_UNSET_INSTANCE_FORMULA:
      return {
        ...state,
        errorUnSetInstanceFormula: false,
        successUnSetInstanceFormula: undefined,
        loadingUnSetInstanceFormula: true,
      }
    case types.SUCCESS_UNSET_INSTANCE_FORMULA:
      return {
        ...state,
        errorUnSetInstanceFormula: false,
        successUnSetInstanceFormula: action.response,
        loadingUnSetInstanceFormula: false,
      }
    case types.CLEAR_UNSET_INSTANCE_FORMULA:
      return {
        ...state,
        errorUnSetInstanceFormula: false,
        successUnSetInstanceFormula: undefined,
        loadingUnSetInstanceFormula: false,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_UPDATE_OBJECT_CMS_SETTINGS:
      return {
        ...state,
        errorUpdateObjectCmsSettings: action.error,
        successUpdateObjectCmsSettings: undefined,
        loadingUpdateObjectCmsSettings: false,
      }
    case types.REQUEST_UPDATE_OBJECT_CMS_SETTINGS:
      return {
        ...state,
        errorUpdateObjectCmsSettings: false,
        successUpdateObjectCmsSettings: undefined,
        loadingUpdateObjectCmsSettings: true,
      }
    case types.SUCCESS_UPDATE_OBJECT_CMS_SETTINGS:
      return {
        ...state,
        errorUpdateObjectCmsSettings: false,
        successUpdateObjectCmsSettings: action.response,
        loadingUpdateObjectCmsSettings: false,
      }
    case types.CLEAR_UPDATE_OBJECT_CMS_SETTINGS:
      return {
        ...state,
        errorUpdateObjectCmsSettings: false,
        successUpdateObjectCmsSettings: undefined,
        loadingUpdateObjectCmsSettings: false,
      }
    default:
      return state
  }
}
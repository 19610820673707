class stringToColor {

  static generate(string) {

    let hash = 0

    if (!string) {
      return '#' + (Math.random() * 0xFFFFFF << 0).toString(16)
    }


    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2)
    }

    return color

  }

  static generateDark(string) {

    let hash = 0

    if (!string) {
      return '#' + (Math.random() * 0xFFFFFF << 0).toString(16)
    }


    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2)
    }

    return color

  }

}

export default stringToColor

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function dataSetBuilderReducer(state: any = initialState.dataSetBuilderReducer, action: any) {
  switch (action.type) {
    case types.FETCHING_DATASET_LIST:
      return {
        ...state,
        fetchingDataSetList: true,
        dataSetList: undefined,
        errorFetchingDataSetList: false
      }
    case types.ERROR_FETCH_DATASET_LIST:
      return {
        ...state,
        fetchingDataSetList: false,
        dataSetList: undefined,
        errorFetchingDataSetList: action.error
      }
    case types.RECEIVE_DATASET_LIST:
      return {
        ...state,
        fetchingDataSetList: false,
        dataSetList: action.response,
        errorFetchingDataSetList: false
      }
    case types.CLEAN_DATASET_LIST:
      return {
        ...state,
        fetchingDataSetList: false,
        dataSetList: undefined,
        errorFetchingDataSetList: false
      }
    /****************************** */
    case types.FETCHING_DATASET_FILTERS_LIST:
      return {
        ...state,
        fetchingDataSetFiltersList: true,
        dataSetFiltersList: undefined,
        errorFetchingDataSetFiltersList: false
      }
    case types.ERROR_FETCH_DATASET_FILTERS_LIST:
      return {
        ...state,
        fetchingDataSetFiltersList: false,
        dataSetFiltersList: undefined,
        errorFetchingDataSetFiltersList: action.error
      }
    case types.RECEIVE_DATASET_FILTERS_LIST:
      return {
        ...state,
        fetchingDataSetFiltersList: false,
        dataSetFiltersList: action.response,
        errorFetchingDataSetFiltersList: false
      }
    default:
      return state
  }
}
import React, { useEffect, useRef, useState } from "react"
import {
  BasePostEdit,
  FluxioPostId,
  FullJob,
  JobId,
  JobTaskId,
  JobTaskIdSchema,
  PostTree,
  ProfileName,
  RunningJobs,
  mkBasePostFromPost,
  mkJobId,
} from "./jobsSchema"
import DOMPurify from "dompurify"
import ReactQuill from "react-quill"
import { Toasts } from "../../../lib/toasts/toasts"
import { SDForm } from "../../../lib/server-driven-forms/sdftypes"
import { useLoader } from "../../../lib/hooks/useLoader"
import { isErr, isOk } from "../../../lib/monads/result/result"
import { FormRender2, mkVoidSDFResponseHandler, SDFResponseHandler } from "../../../lib/server-driven-forms/FormRender2"
import { mkProvideInjectValues } from "../../../lib/server-driven-forms/provideInjectValues"
import { JobsApi } from "./jobsApi"
import ConfirmAction from "../marketplace/components/confirmAction"
import { Promises } from "../../../lib/promises"
import ReplySSE from "./replySSE"
import { MkParser } from "../../../lib/Parser"
import z from "zod"
import { SimpleMessage } from "./chatConversation"
import { Source } from "react-hooks-sse"
import { DocumentViewerActions } from "./documentViewerActions"
import { FaEffectButton } from "../../../lib/components/FaEffectButton"

interface PostTreeHeaderProps {
  post: PostTree //temporary
  postSelected: FluxioPostId | undefined
  postType: "quizz" | "normal"
  toggleTopicMode: (mode: PostTreeMode) => void
  handleSelectPost?: (postId?: FluxioPostId) => void
  actions: DocumentViewerActions.RemovePost
}

function PostTreeActions(props: PostTreeHeaderProps) {
  const deletePost = () => props.actions.removePost(props.post.id)

  if (props.postType === "quizz")
    return (
      <div className={`tt-actions-bar ${props.postSelected && props.postSelected === props.post.id ? `show` : ``}`}>
        <i className='far fa-edit' onClick={() => props.toggleTopicMode("edit")}></i>
        {props.handleSelectPost && (
          <i
            className={`${props.postSelected && props.postSelected === props.post.id ? `fas` : `far`} fa-check-circle`}
            title='Select Topic'
            onClick={() =>
              props.handleSelectPost &&
              props.handleSelectPost(
                props.postSelected !== undefined && props.postSelected === props.post.id ? undefined : props.post.id
              )
            }
          ></i>
        )}
        <i className='fas fa-hand-sparkles' onClick={() => props.toggleTopicMode("quizz")}></i>
      </div>
    )
  return (
    <div className={`tt-actions-bar ${props.postSelected && props.postSelected === props.post.id ? `show` : ``}`}>
      <i className='far fa-edit' title='Edit Topic' onClick={() => props.toggleTopicMode("edit")}></i>
      {props.handleSelectPost && (
        <i
          className={`${props.postSelected && props.postSelected === props.post.id ? `fas` : `far`} fa-check-circle`}
          title='Select Topic'
          onClick={() =>
            props.handleSelectPost &&
            props.handleSelectPost(
              props.postSelected !== undefined && props.postSelected === props.post.id ? undefined : props.post.id
            )
          }
        ></i>
      )}
      {/*<i className='fas fa-rainbow' title='Ai Drill Down' onClick={() => props.toggleTopicMode("drill")}></i>*/}
      <i className='fas fa-plus' title='User Drill Down' onClick={() => props.toggleTopicMode("createChild")}></i>
      <FaEffectButton
        action={deletePost}
        classDefault='far fa-trash-alt'
        title='Delete'
        failMsg='Failed deleting post'
      />
    </div>
  )
}

function PostTreeSaveActions(props: { postId: string; clickSave: () => void; clickCancel: () => void }) {
  return (
    <div className='tt-actions-bar'>
      <i className='fas fa-times' onClick={() => props.clickCancel()}></i>
      <i className='fas fa-check' onClick={() => props.clickSave()}></i>
    </div>
  )
}

function PostTreeCloseAIActions(props: { postId: string; handleClose: () => void }) {
  return (
    <div className='tt-actions-bar show' onClick={props.handleClose}>
      <i className='fas fa-times'></i>
    </div>
  )
}

type PostTreeMode = "topic" | "detail" | "edit" | "drill" | "quizz" | "createChild"

function BaseTopicTree(props: {
  topicPost: PostTree
  postSelected?: FluxioPostId
  toggleTopicMode: (mode: PostTreeMode) => void
  mode: PostTreeMode
  handleSelectPost?: (postId?: FluxioPostId) => void
  actions: DocumentViewerActions.RemovePost
}): JSX.Element {
  if (props.mode === "topic" || props.mode === "detail") {
    return (
      <div className='topic-tree-header'>
        <PostTreeActions
          post={props.topicPost}
          toggleTopicMode={props.toggleTopicMode}
          postType={props.topicPost._quiz ? "quizz" : "normal"}
          handleSelectPost={props.handleSelectPost} //this would evolve to send the list of actions available instead
          postSelected={props.postSelected} //this would evolve to send the list of actions available instead
          actions={props.actions}
        ></PostTreeActions>
        <h4>{props.topicPost.title}</h4>

        {props.topicPost.description && (
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.topicPost.description) }} />
        )}
      </div>
    )
  }
  return (
    <div className='topic-tree-header'>
      <h4>{props.topicPost.title}</h4>

      {props.topicPost.description && (
        <div
          style={{ maxHeight: "250px", overflowY: "scroll" }}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.topicPost.description) }}
        />
      )}
    </div>
  )
}

interface PostTreeProps {
  post: PostTree
  profileName: ProfileName
  jobsClient: JobsApi
  handleSelectPost: (postId?: FluxioPostId) => void
  selectedPost?: FluxioPostId
  updatePost: (postId: FluxioPostId, post: PostTree) => void
  actions: DocumentViewerActions.RemovePost
}

const PostTreeC: React.FC<PostTreeProps> = ({
  post,
  jobsClient,
  profileName,
  handleSelectPost,
  selectedPost,
  updatePost,
  actions,
}) => {
  const [treeMode, setTreeMode] = useState<PostTreeMode>("detail")
  const topicPost = post

  const setTopicPost = (p: PostTree) => updatePost(post.id, p)

  const toggleMode = (mode: PostTreeMode) => {
    setTreeMode(mode)
  }
  const topicMode = (mode: PostTreeMode): boolean => {
    return mode === treeMode
  }
  const toggleDefaultMode = (): void => {
    setTreeMode("detail")
  }

  const toggleEdit = (updatedPost?: BasePostEdit) => {
    if (updatedPost) {
      const updatedTopicPost = { ...topicPost, ...updatedPost }
      setTopicPost(updatedTopicPost)
    }
    // Toggle the showChildren state when the button/icon is clicked
    toggleMode(topicMode("edit") ? "detail" : "edit") //Default changed to detail
  }
  const toggleCreatedChild = (updatedPost?: PostTree) => {
    if (updatedPost) {
      const updatedTopicPost = { ...topicPost, ...updatedPost }
      setTopicPost(updatedTopicPost)
    }
    // Toggle the showChildren state when the button/icon is clicked
    toggleMode(topicMode("createChild") ? "detail" : "createChild")
  }

  const toggleQuizzAnswer = (updatedPost?: BasePostEdit) => {
    if (updatedPost) {
      const updatedTopicPost = { ...topicPost, ...updatedPost }
      setTopicPost(updatedTopicPost)
    }
    toggleMode(topicMode("quizz") ? "detail" : "quizz")
  }

  if (topicMode("drill"))
    return (
      <PostTreeDrillMode
        post={post}
        toggleDefaultMode={toggleDefaultMode}
        profileName={profileName}
        jobsClient={jobsClient}
        actions={actions}
      ></PostTreeDrillMode>
    )
  if (topicMode("createChild"))
    return (
      <PostTreeCreateChild
        post={topicPost}
        toggleDefaultMode={toggleDefaultMode}
        toggleCreatedChild={toggleCreatedChild}
        jobsClient={jobsClient}
        actions={actions}
      ></PostTreeCreateChild>
    )
  if (topicMode("quizz"))
    return (
      <PostQuizz
        post={post}
        toggleDefaultMode={toggleDefaultMode}
        toggleQuizzAnswer={toggleQuizzAnswer}
        profileName={profileName}
        jobsClient={jobsClient}
        actions={actions}
      ></PostQuizz>
    )

  if (topicMode("edit"))
    return <PostTreeEditMode post={topicPost} toggleEdit={toggleEdit} jobsClient={jobsClient}></PostTreeEditMode>

  return (
    <div className='topic-tree-head' id={"post-item-" + post.id}>
      <BaseTopicTree
        topicPost={topicPost}
        toggleTopicMode={toggleMode}
        mode={treeMode}
        handleSelectPost={handleSelectPost}
        postSelected={selectedPost}
        actions={actions}
      ></BaseTopicTree>

      {!topicMode("edit") && topicPost.items && topicPost.items.length > 0 && (
        <div className='tt-show-detail'>
          <div className='tt-detail-button' onClick={() => toggleMode(topicMode("detail") ? "topic" : "detail")}>
            {!topicMode("detail") ? <i className='fas fa-chevron-right'></i> : <i className='fas fa-chevron-down'></i>}
          </div>
          {topicMode("detail") && (
            <div className='tt-detail'>
              {/* Render child Post components recursively */}
              {topicPost.items.map((childPost) => (
                <PostTreeC
                  key={childPost.id}
                  post={childPost}
                  jobsClient={jobsClient}
                  profileName={profileName}
                  handleSelectPost={handleSelectPost}
                  selectedPost={selectedPost}
                  updatePost={updatePost}
                  actions={actions}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

interface PostTreeEditProps {
  post: PostTree
  toggleEdit: (editedPost?: BasePostEdit) => void
  jobsClient: {
    updateTopic(postId: string, body: BasePostEdit): Promise<void>
  }
}

const PostTreeEditMode: React.FC<PostTreeEditProps> = ({ post, toggleEdit, jobsClient }) => {
  const basePostEdit: BasePostEdit = mkBasePostFromPost(post)
  const [editedPost, setEditedPost] = useState<BasePostEdit>(basePostEdit) // Store the description in state

  const savePost: () => void = () => {
    return jobsClient
      .updateTopic(post.id, editedPost)
      .catch((e) => {
        Toasts.handleError("Error updating Topic")
      })
      .then(() => {
        Toasts.success("Saved")
      })
  }
  //TODO SEND NEW EDITED POST TO PREVIOUS COMPONENT
  const clickSave = () => {
    savePost()
    toggleEdit(editedPost)
  }
  const clickCancel = () => {
    toggleEdit()
  }

  return (
    <div className='topic-tree-head' id={"post-item-" + post.id}>
      <div className='topic-tree-header'>
        <PostTreeSaveActions postId={post.id} clickSave={clickSave} clickCancel={clickCancel}></PostTreeSaveActions>
        <input
          type='text'
          value={editedPost.title}
          onChange={(t) => setEditedPost({ ...editedPost, title: t.target.value })}
          style={{ fontSize: "18px" }}
        ></input>
        {
          <ReactQuill
            value={editedPost.description ? editedPost.description : ""}
            onChange={(e) => setEditedPost({ ...editedPost, description: e })}
          />
        }
      </div>
    </div>
  )
}

interface PostTreeCreateChildProps {
  post: PostTree
  toggleDefaultMode: () => void //TODO Re-render with childs
  toggleCreatedChild: (createdPost?: PostTree) => void //TODO Re-render with childs
  jobsClient: {
    createPostChild(parentId: FluxioPostId, post: BasePostEdit): Promise<{ id: FluxioPostId }>
  }
  actions: DocumentViewerActions.RemovePost
}
const PostTreeCreateChild: React.FC<PostTreeCreateChildProps> = (props) => {
  const [contentFilled, setContentFilled] = useState<boolean>(false)
  const [editedPost, setEditedPost] = useState<BasePostEdit>({ title: "", description: "" }) // Store the description in state
  const [loading, setLoading] = useState<boolean>(false)

  const savePost: () => void = () => {
    setLoading(true)
    return props.jobsClient
      .createPostChild(props.post.id, editedPost)
      .then((i) => {
        Toasts.success("Created")

        const createdPost: PostTree = {
          ...editedPost,
          id: i.id,
          title: editedPost.title || "", // Provide a default value if title is undefined in editedPost
        }
        const newItems: PostTree[] = props.post.items ? props.post.items.concat([createdPost]) : [createdPost]

        const updatedPost: PostTree = { ...props.post, items: newItems } //THIS MUST ADD THE NEW ITEM AS CHILD WHEN ID IS RETURNED
        props.toggleCreatedChild(updatedPost)
      })
      .catch((e) => {
        Toasts.handleError("Error creating sub Topic")
        //  setLoading(false)

        props.toggleCreatedChild()
      })
  }
  const fillContent = (editedPost: BasePostEdit) => {
    setContentFilled(true)
    setEditedPost(editedPost)
  }

  const handleConfirm = () => {
    //RECEIVE POST IF EDITED
    props.toggleDefaultMode()
  }
  return (
    <div className='topic-tree-head' id={"post-item-" + props.post.id}>
      <BaseTopicTree
        topicPost={props.post}
        toggleTopicMode={handleConfirm}
        mode={"drill"}
        actions={props.actions}
      ></BaseTopicTree>
      <div style={{ height: "2px", border: "1px solid black" }}></div>
      <div className='tt-show-actions'>
        {contentFilled && (
          <ConfirmAction
            className='tt-actions-bar show'
            message='Are you sure you want to close this quizz? You will lose the responses given so far.'
            hasConfirmation={() => handleConfirm()}
          >
            <i className='fas fa-times'></i>
          </ConfirmAction>
        )}
        {!contentFilled && (
          <PostTreeCloseAIActions postId={props.post.id} handleClose={handleConfirm}></PostTreeCloseAIActions>
        )}

        {renderPostCreateForm(fillContent, savePost, editedPost, loading)}
      </div>
    </div>
  )
}

function renderPostCreateForm(
  setEditedPost: (editedPost: BasePostEdit) => void,
  savePost: () => void,
  postContent: BasePostEdit,
  loading: boolean
): JSX.Element {
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: "10px", width: "60%" }}>
      <h4> Add Sub-Topic </h4>
      <div className='form-group'>
        <label>Title: </label>
        <input
          className='form-control'
          type='text'
          value={postContent.title}
          onChange={(t) => setEditedPost({ ...postContent, title: t.target.value })}
          style={{ fontSize: "18px" }}
        ></input>
      </div>

      <div className='form-group'>
        <label>Description: </label>
        {
          <ReactQuill
            className='form-control'
            style={{ minHeight: "200px" }}
            value={postContent.description ? postContent.description : ""}
            onChange={(e) => setEditedPost({ ...postContent, description: e })}
          />
        }
      </div>

      <button
        disabled={loading}
        style={{ padding: "2px", marginTop: "10px", maxWidth: "30%" }}
        className={`buttonDoJob ${loading ? " disabled" : ""}`}
        type='submit'
        onClick={() => savePost()}
      >
        Create
      </button>
    </div>
  )
}

interface PostTreeDrillModeProps {
  post: PostTree
  profileName: ProfileName
  toggleDefaultMode: () => void
  jobsClient: {
    getJobWithForm(jobId: JobId): Promise<{ job: FullJob; form: SDForm }>
    submitForm: JobsApi["submitForm"]
    submit: JobsApi["submit"]
    getContentLibrary: JobsApi["getContentLibrary"]
    runsOfPostAndProfile(posId: FluxioPostId, profileName: ProfileName): Promise<RunningJobs>
  }
  actions: DocumentViewerActions.RemovePost
}
const PostTreeDrillMode: React.FC<PostTreeDrillModeProps> = ({
  post,
  toggleDefaultMode,
  jobsClient,
  profileName,
  actions,
}) => {
  const runningJobsCount = useLoader(
    () => jobsClient.runsOfPostAndProfile(post.id, profileName).then((l) => l.runningJobs.length),
    [post, jobsClient, profileName]
  )
  const drillDownJob = useLoader(() => jobsClient.getJobWithForm(mkJobId("drillDown")), [jobsClient])

  const injectValues = mkProvideInjectValues(profileName, post.id)

  const responseHandler: SDFResponseHandler<void> = mkVoidSDFResponseHandler()
  const scrollRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  const handleClose = () => {
    toggleDefaultMode()
  }

  const doJobButton: JSX.Element = (
    <span>
      {" "}
      Do<span className='dojob-color'>Job</span>{" "}
    </span>
  )

  if (drillDownJob === undefined || !isOk(drillDownJob) || isErr(drillDownJob)) {
    return (
      <div className='topic-tree-head' id={"post-item-" + post.id}>
        <BaseTopicTree topicPost={post} toggleTopicMode={handleClose} mode={"drill"} actions={actions} />

        <div ref={scrollRef} className='tt-show-actions'>
          <PostTreeCloseAIActions postId={post.id} handleClose={handleClose}></PostTreeCloseAIActions>

          <span>An error occurred please try again</span>
        </div>
      </div>
    )
  }
  //Todo form dojob submmit catch: show progress bar,
  return (
    <div className='topic-tree-head' id={"post-item-" + post.id}>
      <BaseTopicTree topicPost={post} toggleTopicMode={handleClose} mode={"drill"} actions={actions} />
      <div style={{ height: "2px", border: "1px solid black" }}></div>
      <div ref={scrollRef} className='tt-show-actions'>
        <PostTreeCloseAIActions postId={post.id} handleClose={handleClose}></PostTreeCloseAIActions>

        {runningJobsCount !== undefined && isOk(runningJobsCount) && runningJobsCount.value() > 0 && (
          <h4>
            {runningJobsCount.value()} {runningJobsCount.value() === 1 ? "drilldown is" : "drilldowns are"} running. Be
            careful not to repeat yourself{" "}
          </h4>
        )}
        {drillDownJob.value().job && <h3>{drillDownJob.value().job.name}</h3>}
        {drillDownJob.value().form && (
          <FormRender2<void>
            form={drillDownJob.value().form}
            client={jobsClient}
            profile={profileName}
            provideInjectValues={injectValues}
            responseHandler={responseHandler}
            submitProps={{ labelElement: doJobButton }}
            submitStyle={["buttonDoJob"]}
            containerStyle={["form-w70"]}
          />
        )}
      </div>
    </div>
  )
}

interface PostQuizzProps {
  post: PostTree
  profileName: ProfileName
  jobsClient: {
    submit: JobsApi["submit"]
    getContentLibrary: JobsApi["getContentLibrary"]
    taskOutputStream(taskRunId: JobTaskId): Source
  }
  toggleDefaultMode: () => void
  toggleQuizzAnswer: (editedPost?: BasePostEdit) => void
  actions: DocumentViewerActions.RemovePost
}

const JobOutputTaskIdSchema = z.object({ jobOutput: z.object({ taskRunId: JobTaskIdSchema }) })
type JobOutputTaskId = z.infer<typeof JobOutputTaskIdSchema>

const PostQuizz: React.FC<PostQuizzProps> = ({ post, toggleQuizzAnswer, jobsClient, profileName, actions }) => {
  const [quizzReplied, setQuizzReplied] = useState<boolean>(false)
  const [replierIsResponding, setReplierIsResponding] = useState(false)
  const [replierResponse, setReplierResponse] = useState<string | undefined>(undefined)
  const messagesContainerRef = React.useRef<HTMLDivElement | null>(null)
  const basePostEdit: BasePostEdit = post
  const [editedPost, setEditedPost] = useState<BasePostEdit>(basePostEdit) // Store the description in state

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [replierIsResponding, replierResponse])

  const [jobTaskId, setJobTaskId] = useState<JobTaskId | undefined>(undefined)
  const handleConfirm = () => {
    // User confirmed the mode change
    toggleQuizzAnswer(editedPost)
  }

  const responseHandler: SDFResponseHandler<JobOutputTaskId> = {
    decode: MkParser.zod(JobOutputTaskIdSchema),
    onResponse: (e: JobOutputTaskId) => {
      // Your custom logic here
      if (e.jobOutput.taskRunId) {
        setJobTaskId(e.jobOutput.taskRunId)
        setReplierIsResponding(true)
      } else {
        setReplierResponse("Something went wrong")
        setReplierIsResponding(false)
      }
      setQuizzReplied(true)
      return Promises.void()
    },
  }
  const handleAssistantMessage = (aMessage: string, error: boolean) => {
    setReplierResponse(aMessage)
    setReplierIsResponding(false)
    setEditedPost({ ...editedPost, description: aMessage })
    //setJobTaskId(undefined)
    //toggleQuizzAnswer(editedPost)
  }

  const injectValues = mkProvideInjectValues(profileName, post.id)
  const doJobButton: JSX.Element = (
    <span>
      {" "}
      Do<span className='dojob-color'>Job</span>{" "}
    </span>
  )
  //Todo form dojob submmit catch: show progress bar,
  return (
    <div className='topic-tree-head' id={"post-item-" + post.id} ref={messagesContainerRef}>
      <BaseTopicTree
        topicPost={{ ...post, ...editedPost }}
        toggleTopicMode={handleConfirm}
        mode={"quizz"}
        actions={actions}
      />

      <div style={{ height: "2px", border: "1px solid black" }}></div>
      <div
        style={{
          minHeight: "300px",
          display: "flex",
          flexDirection: "column",
          rowGap: "10px",
        }}
        className='tt-show-actions'
      >
        {!quizzReplied && (
          <ConfirmAction
            className='tt-actions-bar show'
            message='Are you sure you want to close this quizz? You will lose the responses given so far.'
            hasConfirmation={() => handleConfirm()}
          >
            <i className='fas fa-times'></i>
          </ConfirmAction>
        )}
        {quizzReplied && <PostTreeCloseAIActions postId={post.id} handleClose={handleConfirm}></PostTreeCloseAIActions>}

        {quizzReplied && <h3>Your Response...</h3>}
        {!quizzReplied && <h3>Quizz</h3>}

        <div className='messages-container'>
          {replierIsResponding && jobTaskId && (
            <ReplySSE jobsClient={jobsClient} jobTaskId={jobTaskId} handleAssistantMessage={handleAssistantMessage} />
          )}
          {replierIsResponding && (
            <div className='loading-indicator'>
              <div className='loading-dot'></div>
              <div className='loading-dot'></div>
              <div className='loading-dot'></div>
            </div>
          )}
          {!replierIsResponding && replierResponse && <SimpleMessage content={replierResponse} type={"assistant"} />}
        </div>

        {post._quiz && post._quiz.form && !quizzReplied && (
          <FormRender2<JobOutputTaskId>
            form={post._quiz.form}
            client={jobsClient}
            profile={profileName}
            provideInjectValues={injectValues}
            responseHandler={responseHandler}
            submitProps={{ labelElement: doJobButton }}
            submitStyle={["buttonDoJob"]}
            groupStyle={["rowQuizz"]}
            containerStyle={["form-w50"]}
          />
        )}
      </div>
    </div>
  )
}
export default PostTreeC

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function formBuilderReducer(state = initialState.formBuilderReducer, action) {
  switch (action.type) {
    case types.CLEAR_FETCH_FORMS_LIST:
      return {
        ...state,
        errorFetchFormsList: false,
        loadingFetchFormsList: false,
        successFetchFormsList: undefined,
      }
    case types.ERROR_FETCH_FORMS_LIST:
      return {
        ...state,
        errorFetchFormsList: action.error,
        loadingFetchFormsList: false,
        successFetchFormsList: undefined
      }
    case types.REQUEST_FETCH_FORMS_LIST:
      return {
        ...state,
        errorFetchFormsList: false,
        loadingFetchFormsList: true,
        successFetchFormsList: undefined
      }
    case types.SUCCESS_FETCH_FORMS_LIST:
      return {
        ...state,
        errorFetchFormsList: false,
        loadingFetchFormsList: false,
        successFetchFormsList: action.response
      }
    case types.CLEAR_FETCH_FORM:
      return {
        ...state,
        errorFetchForm: false,
        loadingFetchForm: false,
        successFetchForm: undefined,
      }
    case types.ERROR_FETCH_FORM:
      return {
        ...state,
        errorFetchForm: action.error,
        loadingFetchForm: false,
        successFetchForm: undefined
      }
    case types.REQUEST_FETCH_FORM:
      return {
        ...state,
        errorFetchForm: false,
        loadingFetchForm: true,
        successFetchForm: undefined
      }
    case types.SUCCESS_FETCH_FORM:
      return {
        ...state,
        errorFetchForm: false,
        loadingFetchForm: false,
        successFetchForm: action.response
      }
    default:
      return state
  }
} 
import {
  CLEAR_HOST_INFO_ACTIONS,
  ERROR_UPDATE_HOST,
  REQUEST_UPDATE_HOST,
  SUCCESS_UPDATE_HOST,
  ERROR_CREATE_HOST,
  REQUEST_CREATE_HOST,
  SUCCESS_CREATE_HOST,
  ERROR_FETCH_HOSTS,
  REQUEST_HOSTS,
  RECEIVE_HOSTS,
  CLEAR_AUDIENCE_INFO_ACTIONS,
  ERROR_FETCH_AUDIENCES,
  REQUEST_AUDIENCES,
  RECEIVE_AUDIENCES,
  ERROR_CREATE_AUDIENCE,
  REQUEST_CREATE_AUDIENCE,
  SUCCESS_CREATE_AUDIENCE,
  ERROR_UPDATE_AUDIENCE,
  REQUEST_UPDATE_AUDIENCE,
  SUCCESS_UPDATE_AUDIENCE,
  ERROR_DELETE_AUDIENCE,
  REQUEST_DELETE_AUDIENCE,
  SUCCESS_DELETE_AUDIENCE,
  ERROR_FETCH_HUBS,
  REQUEST_HUBS,
  RECEIVE_HUBS,
  ERROR_FETCH_DOMAIN_PAGES,
  REQUEST_DOMAIN_PAGES,
  RECEIVE_DOMAIN_PAGES,
  CLEAR_DOMAIN_PAGES,
  EDITED_HUB_TEMPLATE,
  REQUEST_EDIT_HUB_TEMPLATE,
  ERROR_EDIT_HUB_TEMPLATE,
  CLEAR_HUB_HANDLER_PARENTID,
  ERROR_FETCHING_FORMULAS_LIST,
  REQUEST_FORMULAS_LIST,
  RECEIVE_FORMULAS_LIST,
  CLEAR_FORMULAS_LIST,
  TOGGLE_FINDER_PREVIEW,
  DELETE_EVENT_EXPERIENCE_MANAGER,
  EXPERIENCE_MANAGER_EVENT_PILE,
  CLEAR_EXPERIENCE_MANAGER_EVENT_PILE,
  ERROR_EDITING_EXPERIENCE_MANAGER_TEMPLATE,
  REQUEST_EDIT_EXPERIENCE_MANAGER_TEMPLATE,
  SUCCESS_EDIT_EXPERIENCE_MANAGER_TEMPLATE,
  EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER,
  CLEAR_EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER,
  ERROR_INSERTING_OBJECT_EXPERIENCE_MANAGER,
  REQUEST_INSERT_OBJECT_EXPERIENCE_MANAGER,
  SUCCESS_INSERT_OBJECT_EXPERIENCE_MANAGER,
  ERROR_CREATE_OBJECT_EXPERIENCE_MANAGER,
  REQUEST_CREATE_OBJECT_EXPERIENCE_MANAGER,
  SUCCESS_CREATE_OBJECT_EXPERIENCE_MANAGER,
  CLEAR_CREATE_OBJECT_EXPERIENCE_MANAGER,
  ERROR_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  REQUEST_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  SUCCESS_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  CLEAR_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  ERROR_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  REQUEST_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  SUCCESS_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  CLEAR_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  ERROR_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  REQUEST_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  SUCCESS_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  CLEAR_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  ERROR_INSERTING_FIRST_OBJECT_EXPERIENCE_MANAGER,
  REQUEST_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER,
  SUCCESS_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER,
  CLEAR_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER,
  ERROR_ADDING_TEMPLATE_TO_OBJECT,
  REQUEST_ADD_TEMPLATE_TO_OBJECT,
  SUCCESS_ADD_TEMPLATE_TO_OBJECT,
  ERROR_ADDING_FORMULA_TO_OBJECT,
  REQUEST_ADD_FORMULA_TO_OBJECT,
  SUCCESS_ADD_FORMULA_TO_OBJECT,
  ERROR_VALIDATING_URI,
  REQUEST_VALIDATE_URI,
  SUCCESS_VALIDATE_URI,
  CLEAR_VALIDATED_URI,
  ERROR_VALIDATING_OBJECT_URI,
  REQUEST_VALIDATE_OBJECT_URI,
  SUCCESS_VALIDATE_OBJECT_URI,
  CLEAR_VALIDATED_OBJECT_URI,
  ERROR_VALIDATING_FIRST_LEVEL_URI,
  REQUEST_VALIDATE_FIRST_LEVEL_URI,
  SUCCESS_VALIDATE_FIRST_LEVEL_URI,
  CLEAR_VALIDATED_FIRST_LEVEL_URI,
  ERROR_FETCH_FORMULA_CONTENT_EM,
  REQUEST_FORMULA_CONTENT_EM,
  SUCCESS_FETCH_FORMULA_CONTENT_EM,
  CLEAR_FETCH_FORMULA_CONTENT_EM,
  ERROR_FETCH_FORMULA_CONTENT_PREVIEW_EM,
  REQUEST_FORMULA_CONTENT_PREVIEW_EM,
  SUCCESS_FETCH_FORMULA_CONTENT_PREVIEW_EM,
  CLEAR_FETCH_FORMULA_CONTENT_PREVIEW_EM,
  ERROR_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM,
  REQUEST_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM,
  SUCCESS_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM,
  CLEAR_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM,
  ERROR_FETCH_MORE_POST_SEARCH_PREVIEW_EM,
  REQUEST_FETCH_MORE_POST_SEARCH_PREVIEW_EM,
  SUCCESS_FETCH_MORE_POST_SEARCH_PREVIEW_EM,
  CLEAR_FETCH_MORE_POST_SEARCH_PREVIEW_EM,
  CLEAR_FETCH_OBJECT_FORMULA_CONTENT_EM,
  ERROR_UPDATING_OBJECT_EXPERIENCE_MANAGER,
  REQUEST_UPDATE_OBJECT_EXPERIENCE_MANAGER,
  SUCCESS_UPDATE_OBJECT_EXPERIENCE_MANAGER,
  CLEAR_UPDATE_OBJECT_EXPERIENCE_MANAGER,
  CLEAR_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER,
  ERROR_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER,
  REQUEST_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER,
  SUCCESS_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER,
  CLEAR_UPDATE_PAGE_OBJECT_EXPERIENCE_MANAGER,
  REQUEST_UPDATE_PAGE_SETTINGS,
  RECEIVE_UPDATE_PAGE_SETTINGS,
  ERROR_UPDATE_PAGE_SETTINGS,
  ERROR_CREATE_FORMULA_BY_POSTS_QUERY_EM,
  REQUEST_CREATE_FORMULA_BY_POSTS_QUERY_EM,
  SUCCESS_CREATE_FORMULA_BY_POSTS_QUERY_EM,
  ERROR_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM,
  REQUEST_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM,
  SUCCESS_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM,
  CLEAR_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM,
  ERROR_REORDER_OBJECTS_EXPERIENCE_MANAGER,
  REQUEST_REORDER_OBJECTS_EXPERIENCE_MANAGER,
  SUCCESS_REORDER_OBJECTS_EXPERIENCE_MANAGER,
  ERROR_MOVE_OBJECTS_EXPERIENCE_MANAGER,
  REQUEST_MOVE_OBJECTS_EXPERIENCE_MANAGER,
  SUCCESS_MOVE_OBJECTS_EXPERIENCE_MANAGER,
  ERROR_DELETING_OBJECT_FORMULA_EXPERIENCE_MANAGER,
  REQUEST_DELETE_OBJECT_FORMULA_EXPERIENCE_MANAGER,
  SUCCESS_DELETE_OBJECT_FORMULA_EXPERIENCE_MANAGER,
  ERROR_DELETING_OBJECT_EXPERIENCE_MANAGER,
  REQUEST_DELETE_OBJECT_EXPERIENCE_MANAGER,
  SUCCESS_DELETE_OBJECT_EXPERIENCE_MANAGER,
  OPEN_EDITOR_MODAL,
  ERROR_DELETE_ENTIRE_EXPERIENCE_MANAGER,
  REQUEST_DELETE_ENTIRE_EXPERIENCE_MANAGER,
  SUCCESS_DELETE_ENTIRE_EXPERIENCE_MANAGER,
  CLEAR_DELETE_ENTIRE_EXPERIENCE_MANAGER,
  ERROR_FETCH_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER,
  REQUEST_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER,
  SUCCESS_FETCH_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER,
  CLEAR_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER,
  ERROR_FETCHING_COMPONENTS_BY_CAT_FAM,
  REQUEST_COMPONENTS_BY_CAT_FAM,
  SUCCESS_FETCH_COMPONENTS_BY_CAT_FAM,
  CLEAR_COMPONENTS_LIST_BY_CAT_FAM,
  CLEAR_SELECTED_COMPONENT_FAMILY,
  DEFAULT_OBJECT_COLORS_EXPERIENCE_MANAGER,
  ERROR_SUBMIT_FAVICON_NEW_EXPERIENCE,
  REQUEST_SUBMIT_FAVICON_NEW_EXPERIENCE,
  SUCCESS_SUBMIT_FAVICON_NEW_EXPERIENCE,
  CLEAR_FAVICON_NEW_EXPERIENCE,
  ERROR_UPDATE_FAVICON_NEW_EXPERIENCE,
  REQUEST_UPDATE_FAVICON_NEW_EXPERIENCE,
  SUCCESS_UPDATE_FAVICON_NEW_EXPERIENCE,
  ERROR_CHECK_CREATED_HUB,
  REQUEST_CHECK_CREATED_HUB,
  SUCCESS_CHECK_CREATED_HUB,
  CLEAR_FAVICON_UPLOAD_EM,
  CLEAR_CHECK_CREATED_EM,
  COPY_OBJECT_EM,
  OBJECT_TO_PASTE_EM,
  PASTED_SERVER_OBJECT_EM,
  PASTE_OBJECT_EM,
  CLEAR_PASTE_OBJECT_EM,
  CLEAR_SERVER_COPY_PASTE_EM,
  CLEAR_COPY_OBJECT_EM,
  COPY_OBJECT_TEMPLATE_EM,
  PASTE_OBJECT_TEMPLATE_EM,
  CLEAR_COPY_OBJECT_TEMPLATE_EM,
  ADD_BULK_EVENT,
  ERROR_DELETE_HOST_OUTPUTTERS,
  REQUEST_DELETE_HOST_OUTPUTTERS,
  SUCCESS_DELETE_HOST_OUTPUTTERS,
  CLEAR_DELETE_HOST_OUTPUTTERS,
  SELECTED_FORMULA_TO_PREVIEW,
  ADD_BULK_POSTS_EXPERIENCE_MANAGER,
  ADD_SINGLE_POST_EXPERIENCE_MANAGER,
  ADD_SINGLE_POST_BY_EMBED_EXPERIENCE_MANAGER,
  REMOVE_SINGLE_POST_EXPERIENCE_MANAGER,
  CLEAR_POSTS_EXPERIENCE_MANAGER,
  CLEAR_EDITED_POSTS_EXPERIENCE_MANAGER,
  ERROR_FETCHING_FORMULA_INFO,
  REQUEST_FORMULA_INFO,
  SUCCESS_FETCH_FORMULA_INFO,
  ERROR_FETCH_HOST,
  FETCHING_HOST,
  SUCCESS_FETCH_HOST,
  ERROR_REGISTER_DOMAIN_GOOGLE,
  REQUEST_REGISTER_DOMAIN_GOOGLE,
  SUCCESS_REGISTER_DOMAIN_GOOGLE,
  ERROR_SEND_SITEMAP,
  REQUEST_SEND_SITEMAP,
  SUCCESS_SEND_SITEMAP,
  ERROR_SUBMIT_SOURCE_TO_POST,
  REQUEST_SUBMIT_SOURCE_TO_POST,
  SUCCESS_SUBMIT_SOURCE_TO_POST,
  CLEAR_SOURCE_TO_POST,
  ERROR_CHANGE_POSTS_EXPERIENCE_ORDER,
  REQUEST_CHANGE_POSTS_EXPERIENCE_ORDER,
  SUCCESS_CHANGE_POSTS_EXPERIENCE_ORDER,
  CLEAR_POSTS_EXPERIENCE_ORDER,
  ERROR_SEARCH_BY_POSTS_EXPERIENCE_MANAGER,
  REQUEST_SEARCH_BY_POSTS_EXPERIENCE_MANAGER,
  SUCCESS_SEARCH_BY_POSTS_EXPERIENCE_MANAGER,
  CLEAR_SEARCH_BY_POSTS_EXPERIENCE_MANAGER,
  ERROR_VALIDATE_GOOGLE,
  REQUEST_VALIDATE_GOOGLE,
  SUCCESS_VALIDATE_GOOGLE,
  CLEAR_DOMAIN_ACTIONS,
  OBJECT_SELECTOR_MODE_EM,
  EVENT_PILE_SUCCESS_RESPONSES_EM,
  ERROR_HUB_TO_CLONE_EXPERERIENCE_MANAGER,
  REQUEST_HUB_TO_CLONE_EXPERIENCE_MANAGER,
  SUCCESS_HUB_TO_CLONE_EXPERIENCE_MANAGER,
  ERROR_OG_IMAGE_UPLOAD,
  REQUEST_OG_IMAGE_UPLOAD,
  SUCCESS_OG_IMAGE_UPLOAD,
  CLEAR_OG_IMAGE_UPLOAD,
  ERROR_LAYOUTS_EXPERIENCE_MANAGER,
  REQUEST_LAYOUTS_EXPERIENCE_MANAGER,
  SUCCESS_LAYOUTS_EXPERIENCE_MANAGER,
  CLEAR_LAYOUTS_EXPERIENCE_MANAGER,
  ERROR_SELECTED_LAYOUT_EXPERIENCE_MANAGER,
  REQUEST_SELECTED_LAYOUT_EXPERIENCE_MANAGER,
  SUCCESS_SELECTED_LAYOUT_EXPERIENCE_MANAGER,
  CLEAR_SELECTED_LAYOUT_EXPERIENCE_MANAGER,
  CLEAR_SAVED_HUB_FOR_PREVIEW,
  SAVED_HUB_FOR_PREVIEW,
  HANDLE_NEW_PAGE_MODAL,
  ERROR_CLONE_PAGE_EXPERIENCE_MANAGER,
  REQUEST_CLONE_PAGE_EXPERIENCE_MAGAGER,
  SUCCESS_CLONE_PAGE_EXPERIENCE_MANAGER,
  CLEAR_CLONE_PAGE_EXPERIENCE_MANAGER,
  ERROR_COPY_PASTE_EM,
  REQUEST_COPY_PASTE_EM,
  SUCCESS_COPY_PASTE_EM,
  CLEAR_COPY_PASTE_EM,
  ERROR_COPY_PASTE_STYLES_EM,
  REQUEST_COPY_PASTE_STYLES_EM,
  SUCCESS_COPY_PASTE_STYLES_EM,
  CLEAR_COPY_PASTE_STYLES_EM,
  EVENT_ID_EM,
  AUTO_SELECT_ON_CREATE_MODE_EM,
  SELECTED_BOB_ELEMENT_EM,
  ELEMENT_SELECTOR_STATE,
  OBJECT_SELECTOR_STATE,
  ERROR_STAGING_PAGE_EXPERIENCE_MANAGER,
  REQUEST_STAGING_PAGE_EXPERIENCE_MANAGER,
  SUCCESS_STAGING_PAGE_EXPERIENCE_MANAGER,
  OPEN_COLOR_PICKER_STYLES,
  COLOR_PICKER_EXPORT_SELECTION,
  ERROR_PUBLISH_PAGE,
  REQUEST_PUBLISH_PAGE,
  SUCCESS_PUBLISH_PAGE,
  CLEAR_PUBLISH_PAGE,
  ERROR_UNPUBLISH_PAGE,
  REQUEST_UNPUBLISH_PAGE,
  SUCCESS_UNPUBLISH_PAGE,
  CLEAR_UNPUBLISH_PAGE,
  ERROR_PUBLISH_SITE_PAGES,
  REQUEST_PUBLISH_SITE_PAGES,
  SUCCESS_PUBLISH_SITE_PAGES,
  CLEAR_PUBLISH_SITE_PAGES,
  ERROR_PUBLISH_SITE_PAGES_STATUS,
  REQUEST_PUBLISH_SITE_PAGES_STATUS,
  SUCCESS_PUBLISH_SITE_PAGES_STATUS,
  CLEAR_PUBLISH_SITE_PAGES_STATUS,
  ERROR_PUBLISH_OBJECT_TEMPLATE,
  REQUEST_PUBLISH_OBJECT_TEMPLATE,
  SUCCESS_PUBLISH_OBJECT_TEMPLATE,
  CLEAR_PUBLISH_OBJECT_TEMPLATE,
  ERROR_UNPUBLISH_OBJECT_TEMPLATE,
  REQUEST_UNPUBLISH_OBJECT_TEMPLATE,
  SUCCESS_UNPUBLISH_OBJECT_TEMPLATE,
  CLEAR_UNPUBLISH_OBJECT_TEMPLATE,
  RECEIVE_CREATE_SYMBOL_INSTANCE,
  REQUEST_CREATE_SYMBOL_INSTANCE,
  ERROR_CREATE_SYMBOL_INSTANCE,
  CLEAR_CREATE_SYMBOL_INSTANCE,
  RECEIVE_SET_SYMBOL,
  REQUEST_SET_SYMBOL,
  ERROR_SET_SYMBOL,
  CLEAR_SET_SYMBOL,
  RECEIVE_UNSET_SYMBOL,
  REQUEST_UNSET_SYMBOL,
  ERROR_UNSET_SYMBOL,
  CLEAR_UNSET_SYMBOL,
  ERROR_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  REQUEST_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  SUCCESS_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  CLEAR_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  ERROR_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  REQUEST_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  SUCCESS_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  REQUEST_SET_CONTROLLER_STATE,
  ADD_OBJECT_TEMPLATE,
  CLEAR_ADD_OBJECT_TEMPLATE,
  ERROR_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER,
  REQUEST_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER,
  SUCCESS_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER,
  CLEAR_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER,
  ERROR_DELETE_OBJECT_TEMPLATE,
  REQUEST_DELETE_OBJECT_TEMPLATE,
  SUCCESS_DELETE_OBJECT_TEMPLATE,
  CLEAR_DELETE_OBJECT_TEMPLATE,
  ERROR_UPDATE_OBJECT_TEMPLATE,
  REQUEST_UPDATE_OBJECT_TEMPLATE,
  SUCCESS_UPDATE_OBJECT_TEMPLATE,
  SET_BEHAVIOUR_STATE,
  VIEW_PORT_ORIGINAL_SIZE_STATE_EXPERIENCE_MANAGER,
  REQUEST_STAGING_OBJECT_TEMPLATE_BY_ID,
  SUCCESS_STAGING_OBJECT_TEMPLATE_BY_ID,
  ERROR_FETCH_STAGING_OBJECT_TEMPLATE_BY_ID,
  REQUEST_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID,
  SUCCESS_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID,
  ERROR_FETCH_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID,
  REQUEST_STAGING_SYMBOL_BY_ID,
  SUCCESS_STAGING_SYMBOL_BY_ID,
  ERROR_FETCH_STAGING_SYMBOL_BY_ID,
  ERROR_OBJECT_TEMPLATE_SCREEN_SHOT,
  REQUEST_OBJECT_TEMPLATE_SCREEN_SHOT,
  SUCCESS_OBJECT_TEMPLATE_SCREEN_SHOT,
  CLEAR_OBJECT_TEMPLATE_SCREEN_SHOT,
  ERROR_CREATE_FLUXIO_POST,
  REQUEST_CREATE_FLUXIO_POST,
  SUCCESS_CREATE_FLUXIO_POST,
  CLEAN_CREATE_FLUXIO_POST,
  ERROR_UPDATE_FLUXIO_POST,
  REQUEST_UPDATE_FLUXIO_POST,
  SUCCESS_UPDATE_FLUXIO_POST,
  CLEAN_UPDATE_FLUXIO_POST,
  ERROR_DELETE_FLUXIO_POST,
  REQUEST_DELETE_FLUXIO_POST,
  SUCCESS_DELETE_FLUXIO_POST,
  CLEAN_DELETE_FLUXIO_POST,
  ERROR_FETCH_IS_PAGE_PUBLISHED_STATUS,
  REQUEST_IS_PAGE_PUBLISHED_STATUS,
  SUCCESS_FETCH_IS_PAGE_PUBLISHED_STATUS,
  CLEAR_FETCH_IS_PAGE_PUBLISHED_STATUS,
  UPDATE_SINGLE_OBJECT_PAGE_TREE,
  ERROR_FETCH_UPLOADED_FONTS,
  REQUEST_UPLOADED_FONTS,
  SUCCESS_FETCH_UPLOADED_FONTS,
  ERROR_FETCH_UPLOADED_FONTS_EM,
  REQUEST_UPLOADED_FONTS_EM,
  SUCCESS_FETCH_UPLOADED_FONTS_EM,
  ERROR_DELETE_FONT,
  REQUEST_DELETE_FONT,
  SUCCESS_DELETE_FONT,
  CLEAR_DELETE_FONT,
  ERROR_ADD_DATASET_TO_OBJECT,
  REQUEST_ADD_DATASET_TO_OBJECT,
  SUCCESS_ADD_DATASET_TO_OBJECT,
  CLEAR_ADD_DATASET_TO_OBJECT,
  UPDATE_STYLES_COLLAPSE,
  CHANGE_EXPERIENCE_MANAGER_TYPE,
  LOG_FINDER_OPEN,
  ERROR_FETCH_PAGE_LINKS,
  REQUEST_FETCH_PAGE_LINKS,
  SUCCESS_FETCH_PAGE_LINKS,
  CLEAR_FETCH_PAGE_LINKS,
  ERROR_FETCH_MAPPED_FIELDS,
  REQUEST_FETCH_MAPPED_FIELDS,
  SUCCESS_FETCH_MAPPED_FIELDS,
  CLEAR_FETCH_MAPPED_FIELDS,
  ERROR_FETCH_LINK_MAPPED_FIELDS,
  REQUEST_FETCH_LINK_MAPPED_FIELDS,
  SUCCESS_FETCH_LINK_MAPPED_FIELDS,
  CLEAR_FETCH_LINK_MAPPED_FIELDS,
  CLEAR_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER,
  ERROR_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER,
  REQUEST_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER,
  SUCCESS_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER,
  CLEAR_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS,
  SUCCESS_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS,
  REQUEST_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS,
  ERROR_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS,
  CLEAR_SET_OBJECT_MAPPED_FIELD,
  ERROR_SET_OBJECT_MAPPED_FIELD,
  REQUEST_SET_OBJECT_MAPPED_FIELD,
  SUCCESS_SET_OBJECT_MAPPED_FIELD,
  CLEAR_UNSET_OBJECT_MAPPED_FIELD,
  ERROR_UNSET_OBJECT_MAPPED_FIELD,
  REQUEST_UNSET_OBJECT_MAPPED_FIELD,
  SUCCESS_UNSET_OBJECT_MAPPED_FIELD,
  SET_EXPERIENCE_MANAGER_EDITOR_TYPE,
  CLEAR_FETCHED_HOSTS,
  CLEAR_GET_PLATFORM_PAGE_TEMPLATES,
  ERROR_GET_PLATFORM_PAGE_TEMPLATES,
  REQUEST_GET_PLATFORM_PAGE_TEMPLATES,
  SUCCESS_GET_PLATFORM_PAGE_TEMPLATES,
} from "../shared-store/actions/actionTypes"
import initialState from "../initialState"
import CheckDuplicated from "../../modules/shared-modules/utilities/checkDuplicate"
import { customFontsListForFilter } from "../../modules/shared-modules/utilities/components"
import cloneDeep from "lodash/cloneDeep"
import { AnyAction } from "redux"

export default function experienceManagerReducer(
  state: any = initialState.experienceManagerReducer,
  action: AnyAction
) {
  switch (action.type) {
    case CLEAR_HOST_INFO_ACTIONS:
      return {
        ...state,
        //update
        errorUpdateHost: false,
        loadingUpdateHost: false,
        updatedHost: undefined,
        //update
        errorCreateHost: false,
        loadingCreateHost: false,
        createdHost: undefined,
      }
    case ERROR_UPDATE_HOST:
      return {
        ...state,
        errorUpdateHost: action.error,
        loadingUpdateHost: false,
        updatedHost: undefined,
      }
    case REQUEST_UPDATE_HOST:
      return {
        ...state,
        errorUpdateHost: false,
        loadingUpdateHost: true,
        updatedHost: undefined,
      }
    case SUCCESS_UPDATE_HOST:
      return {
        ...state,
        errorUpdateHost: false,
        loadingUpdateHost: false,
        updatedHost: action.response,
      }
    case ERROR_CREATE_HOST:
      return {
        ...state,
        errorCreateHost: action.error,
        loadingCreateHost: false,
        createdHost: undefined,
      }
    case REQUEST_CREATE_HOST:
      return {
        ...state,
        errorCreateHost: false,
        loadingCreateHost: true,
        createdHost: undefined,
      }
    case SUCCESS_CREATE_HOST:
      return {
        ...state,
        errorCreateHost: false,
        loadingCreateHost: false,
        createdHost: action.response,
      }
    case ERROR_FETCH_HOSTS:
      return {
        ...state,
        errorFetchHosts: action.error,
        loadingHosts: false,
        hosts: undefined,
      }
    case REQUEST_HOSTS:
      return {
        ...state,
        errorFetchHosts: false,
        loadingHosts: true,
        hosts: undefined,
      }
    case RECEIVE_HOSTS:
      return {
        ...state,
        errorFetchHosts: false,
        loadingHosts: false,
        hosts: action.response,
      }
    case CLEAR_FETCHED_HOSTS:
      return {
        ...state,
        errorFetchHosts: false,
        loadingHosts: false,
        hosts: undefined,
      }
    case CLEAR_AUDIENCE_INFO_ACTIONS:
      return {
        //update
        errorUpdateAudience: false,
        loadingUpdateAudience: false,
        updatedAudience: undefined,
        //create
        errorCreateAudience: false,
        loadingCreateAudience: false,
        createdAudience: undefined,
      }
    case ERROR_FETCH_AUDIENCES:
      return {
        ...state,
        errorFetchAudiences: action.error,
        loadingAudiences: false,
        audiences: undefined,
      }
    case REQUEST_AUDIENCES:
      return {
        ...state,
        errorFetchAudiences: false,
        loadingAudiences: true,
        audiences: undefined,
      }
    case RECEIVE_AUDIENCES:
      return {
        ...state,
        errorFetchAudiences: false,
        loadingAudiences: false,
        audiences: action.response,
      }
    case ERROR_CREATE_AUDIENCE:
      return {
        ...state,
        errorCreateAudience: action.error,
        loadingCreateAudience: false,
        createdAudience: undefined,
      }
    case REQUEST_CREATE_AUDIENCE:
      return {
        ...state,
        errorCreateAudience: false,
        loadingCreateAudience: true,
        createdAudience: undefined,
      }
    case SUCCESS_CREATE_AUDIENCE:
      return {
        ...state,
        errorCreateAudience: false,
        loadingCreateAudience: false,
        createdAudience: action.response,
      }
    case ERROR_UPDATE_AUDIENCE:
      return {
        ...state,
        errorUpdateAudience: action.error,
        loadingUpdateAudience: false,
        updatedAudience: undefined,
      }
    case REQUEST_UPDATE_AUDIENCE:
      return {
        ...state,
        errorUpdateAudience: false,
        loadingUpdateAudience: true,
        updatedAudience: undefined,
      }
    case SUCCESS_UPDATE_AUDIENCE:
      return {
        ...state,
        errorUpdateAudience: false,
        loadingUpdateAudience: false,
        updatedAudience: action.response,
      }
    case ERROR_DELETE_AUDIENCE:
      return {
        ...state,
        errorDeleteAudience: action.error,
        loadingDeleteAudience: false,
        deletedAudience: undefined,
      }
    case REQUEST_DELETE_AUDIENCE:
      return {
        ...state,
        errorDeleteAudience: false,
        loadingDeleteAudience: true,
        deletedAudience: undefined,
      }
    case SUCCESS_DELETE_AUDIENCE:
      return {
        ...state,
        errorDeleteAudience: false,
        loadingDeleteAudience: false,
        deletedAudience: action.response,
      }
    case ERROR_FETCH_HUBS:
      return {
        ...state,
        errorFetchHubs: action.error,
        loadingHubs: false,
        hubs: undefined,
      }
    case REQUEST_HUBS:
      return {
        ...state,
        errorFetchHubs: false,
        loadingHubs: true,
        hubs: undefined,
      }
    case RECEIVE_HUBS:
      return {
        ...state,
        errorFetchHubs: false,
        loadingHubs: false,
        hubs: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_DOMAIN_PAGES:
      return {
        ...state,
        [`errorFetchDomainPages-${action.hostId}`]: action.error,
        [`loadingDomainPages-${action.hostId}`]: false,
        [`receiveDomainPages-${action.hostId}`]: undefined,
      }
    case REQUEST_DOMAIN_PAGES:
      return {
        ...state,
        [`errorFetchDomainPages-${action.hostId}`]: false,
        [`loadingDomainPages-${action.hostId}`]: true,
        [`receiveDomainPages-${action.hostId}`]: undefined,
      }
    case RECEIVE_DOMAIN_PAGES:
      return {
        ...state,
        [`errorFetchDomainPages-${action.hostId}`]: false,
        [`loadingDomainPages-${action.hostId}`]: false,
        [`receiveDomainPages-${action.hostId}`]: action.response,
        [`uniqueDomainPagesId-${action.hostId}`]: action.uniqueDomainPagesId,
      }
    case CLEAR_DOMAIN_PAGES:
      return {
        ...state,
        [`errorFetchDomainPages-${action.hostId}`]: false,
        [`loadingDomainPages-${action.hostId}`]: false,
        [`receiveDomainPages-${action.hostId}`]: undefined,
        [`uniqueDomainPagesId-${action.hostId}`]: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case EDITED_HUB_TEMPLATE:
      return {
        ...state,
        editedHub: action.response,
        editedHubLoading: false,
        editedHubError: false,
      }
    case REQUEST_EDIT_HUB_TEMPLATE:
      return {
        ...state,
        editedHub: false,
        editedHubLoading: true,
        editedHubError: false,
      }
    case ERROR_EDIT_HUB_TEMPLATE:
      return {
        ...state,
        editedHub: false,
        editedHubLoading: false,
        editedHubError: action.error,
      }
    case CLEAR_HUB_HANDLER_PARENTID:
      return {
        ...state,
        hubHandlerParentId: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCHING_FORMULAS_LIST:
      return {
        ...state,
        loadingFormulasList: false,
        errorFormulasList: action.error,
        formulasList: undefined,
      }
    case REQUEST_FORMULAS_LIST:
      return {
        ...state,
        loadingFormulasList: true,
        errorFormulasList: false,
        formulasList: undefined,
      }
    case RECEIVE_FORMULAS_LIST:
      return {
        ...state,
        loadingFormulasList: false,
        errorFormulasList: false,
        formulasList: action.response,
      }
    case CLEAR_FORMULAS_LIST:
      return {
        ...state,
        loadingFormulasList: false,
        errorFormulasList: false,
        formulasList: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case TOGGLE_FINDER_PREVIEW:
      if (
        (state.finderPreview === "contentLibrary" || state.finderPreview === "formulaPosts") &&
        action.value === "mediaLibrary"
      ) {
        return {
          ...state,
          logFinderOpen: {
            finderPreview: state.finderPreview,
            finderPreviewKey: state.finderPreviewKey,
            finderPreviewParams: state.finderPreviewParams,
          },
          finderPreview: action.value,
          finderPreviewKey: action.key,
          finderPreviewParams: action.params,
        }
      }
      return {
        ...state,
        logFinderOpen: undefined,
        finderPreview: action.value,
        finderPreviewKey: action.key,
        finderPreviewParams: action.params,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case DELETE_EVENT_EXPERIENCE_MANAGER:
      let removedEvent = state.eventPile.filter((eventFromPile: any) => {
        return (
          eventFromPile.content.objectUuid !== action.command.content.objectUuid ||
          eventFromPile.type !== action.command.type
        )
      })
      return {
        ...state,
        eventPile: [...removedEvent],
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case EXPERIENCE_MANAGER_EVENT_PILE:
      let counter = 0
      let editedPosition: any = false
      //Dont write new event
      let returnWithoutNewEvent = false
      let contentToReplace = state.eventPile.filter((stuff: any) => {
        /**
         * In this condition we first check if we are dealing with attach/detach of globalStyles
         * With that, the only problem we can have is when dealing with the same bob(uuid)
         * We first check if they are the same, then check if the action for the
         * registered event is diferent from the new event, if so we must
         * remove both events, if they are the same event from an old
         * event to the new event we check if the globalStyleID
         * is the same, if they are we just need to replace
         * else we dont write them at all.
         */
        if (action.event.type === "globalStyle_attach" || action.event.type === "globalStyle_detach") {
          if (stuff.content.bobUuid === action.event.content.bobUuid) {
            if (stuff.type === "globalStyle_attach" && action.event.type === "globalStyle_detach") {
              if (
                stuff.content.globalStyleObj.globalStyle.uuid === action.event.content.globalStyleObj.globalStyle.uuid
              ) {
                returnWithoutNewEvent = true
                return false
              }
            }
            if (stuff.type === "globalStyle_attach" && action.event.type === "globalStyle_attach") {
              if (
                stuff.content.globalStyleObj.globalStyle.uuid !== action.event.content.globalStyleObj.globalStyle.uuid
              ) {
                returnWithoutNewEvent = false
                return false
              } else {
                returnWithoutNewEvent = true
                return true
              }
            }
            if (stuff.type === "globalStyle_detach" && action.event.type === "globalStyle_attach") {
              if (
                stuff.content.globalStyleObj.globalStyle.uuid === action.event.content.globalStyleObj.globalStyle.uuid
              ) {
                returnWithoutNewEvent = true
                return false
              }
            }
          }
          return true
        }
        //End global style event logic
        /**
         * We save the index for the same action for the same object
         * to edit the event array
         */
        if (stuff.content.objectUuid === action.event.content.objectUuid && stuff.type === action.event.type) {
          editedPosition = counter
        }
        counter++
        return stuff.content.objectUuid !== action.event.content.objectUuid || stuff.type !== action.event.type
      })
      /**
       * This will be true when the condition for the globalStyles is matched
       */
      if (returnWithoutNewEvent) {
        return {
          ...state,
          eventPile: [...contentToReplace],
        }
      }
      /**
       * When we edit an object that is still not created
       * we MUST maintain its position in the array
       * so it isn't the last one being created and
       * by consequence break the creation logic
       */
      if (editedPosition !== false) {
        let changedArr: any = [...state.eventPile]
        changedArr[editedPosition] = action.event
        return {
          ...state,
          eventPile: [...changedArr],
        }
      }
      return {
        ...state,
        eventPile: [...contentToReplace, action.event],
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_EXPERIENCE_MANAGER_EVENT_PILE:
      return {
        ...state,
        eventPile: [],
        errorEditingExpMTemplate: false,
        loadingEditingExpMTemplate: false,
        editedExpMTemplate: false,
        countEditingStep: undefined,
        errorAddingFormulaToObject: false,
        loadingAddFormulaToObject: false,
        addedFormulaToObject: false,
        insertedObjectEM: false,
        loadingInsertObjectEM: false,
        errorInsertingObjectEM: false,
        loadingUpdateObjectEM: false,
        updateObjectEM: false,
        errorDeletingObjectFormulaEM: false,
        loadingDeleteObjectFormulaEM: false,
        deleteObjectFormulaEM: false,
        errorDeletingObjectEM: false,
        loadingDeleteObjectEM: false,
        deleteObjectEM: false,
        createdFormulaByPostsQueryEM: false,
        successReOrderObjectsEM: false,
        successMoveObjectsEM: false,
        errorAttachGlobalStyle: false,
        loadingAttachGlobalStyle: false,
        successAttachGlobalStyle: undefined,
        errorDetachGlobalStyle: false,
        loadingDetachGlobalStyle: false,
        successDetachGlobalStyle: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_EDITING_EXPERIENCE_MANAGER_TEMPLATE:
      return {
        ...state,
        errorEditingExpMTemplate: action.error,
        loadingEditingExpMTemplate: false,
        editedExpMTemplate: false,
      }
    case REQUEST_EDIT_EXPERIENCE_MANAGER_TEMPLATE:
      return {
        ...state,
        errorEditingExpMTemplate: false,
        loadingEditingExpMTemplate: true,
        editedExpMTemplate: false,
      }
    case SUCCESS_EDIT_EXPERIENCE_MANAGER_TEMPLATE:
      return {
        ...state,
        errorEditingExpMTemplate: false,
        loadingEditingExpMTemplate: false,
        editedExpMTemplate: action.response,
        countEditingStep: action.step,
        successResponsesEventPile: [
          ...state.successResponsesEventPile,
          {
            event: action.event,
            object: action.object,
            response: action.response,
          },
        ],
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        editingFinderObjectEM: action.object,
        editingFinderObjectEventEM: action.event,
        finderType:
          action.event !== "no-event-register"
            ? action.finderTab
              ? action.finderTab
              : action.event
            : state.finderType,
        finderSelectedObject: action.selectFinderObject ? action.object : state.finderSelectedObject,
      }
    case CLEAR_EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        editingFinderObjectEM: undefined,
        editingFinderObjectEventEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_INSERTING_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorInsertingObjectEM: action.error,
        loadingInsertObjectEM: false,
        insertedObjectEM: undefined,
      }
    case REQUEST_INSERT_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorInsertingObjectEM: false,
        loadingInsertObjectEM: true,
        insertedObjectEM: undefined,
      }
    case SUCCESS_INSERT_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorInsertingObjectEM: false,
        loadingInsertObjectEM: false,
        insertedObjectEM: action.response,
        countEditingStep: action.step,
        successResponsesEventPile: [
          ...state.successResponsesEventPile,
          {
            event: action.event,
            object: action.object,
            response: action.response,
          },
        ],
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_CREATE_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorCreateObjectEM: action.error,
        loadingCreateObjectEM: false,
        successCreateObjectEM: undefined,
      }
    case REQUEST_CREATE_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorCreateObjectEM: false,
        loadingCreateObjectEM: true,
        successCreateObjectEM: undefined,
      }
    case SUCCESS_CREATE_OBJECT_EXPERIENCE_MANAGER:
      /**
       * When we are dealing with object creation in symbol edit mode
       * the ids are composed with the instanceID _ objectUuid
       */
      if (action.composedId) {
        return {
          ...state,
          errorCreateObjectEM: false,
          loadingCreateObjectEM: false,
          successCreateObjectEM: {
            composedId: action.composedId,
            ...action.response,
          },
          createObjectBehaviourTypeEM: action.addBehaviourType,
        }
      }
      return {
        ...state,
        errorCreateObjectEM: false,
        loadingCreateObjectEM: false,
        successCreateObjectEM: action.response,
        createObjectBehaviourTypeEM: action.addBehaviourType,
      }
    case CLEAR_CREATE_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorCreateObjectEM: false,
        loadingCreateObjectEM: false,
        successCreateObjectEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_CHANGE_COMPONENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorChangeComponentEM: action.error,
        loadingChangeComponentEM: false,
        successChangeComponentEM: undefined,
      }
    case REQUEST_CHANGE_COMPONENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorChangeComponentEM: false,
        loadingChangeComponentEM: true,
        successChangeComponentEM: undefined,
      }
    case SUCCESS_CHANGE_COMPONENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorChangeComponentEM: false,
        loadingChangeComponentEM: false,
        successChangeComponentEM: action.response,
      }
    case CLEAR_CHANGE_COMPONENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorChangeComponentEM: false,
        loadingChangeComponentEM: false,
        successChangeComponentEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorApplyChangeComponentEM: action.error,
        loadingApplyChangeComponentEM: false,
        successApplyChangeComponentEM: undefined,
      }
    case REQUEST_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorApplyChangeComponentEM: false,
        loadingApplyChangeComponentEM: true,
        successApplyChangeComponentEM: undefined,
      }
    case SUCCESS_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorApplyChangeComponentEM: false,
        loadingApplyChangeComponentEM: false,
        successApplyChangeComponentEM: action.response,
      }
    case CLEAR_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorApplyChangeComponentEM: false,
        loadingApplyChangeComponentEM: false,
        successApplyChangeComponentEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUndoChangeComponentEM: action.error,
        loadingUndoChangeComponentEM: false,
        successUndoChangeComponentEM: undefined,
      }
    case REQUEST_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUndoChangeComponentEM: false,
        loadingUndoChangeComponentEM: true,
        successUndoChangeComponentEM: undefined,
      }
    case SUCCESS_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUndoChangeComponentEM: false,
        loadingUndoChangeComponentEM: false,
        successUndoChangeComponentEM: action.response,
      }
    case CLEAR_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUndoChangeComponentEM: false,
        loadingUndoChangeComponentEM: false,
        successUndoChangeComponentEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_INSERTING_FIRST_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorInsertingFirstObjectEM: action.error,
        loadingInsertFirstObjectEM: false,
        insertedFirstObjectEM: undefined,
      }
    case REQUEST_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorInsertingFirstObjectEM: false,
        loadingInsertFirstObjectEM: true,
        insertedFirstObjectEM: undefined,
      }
    case SUCCESS_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorInsertingFirstObjectEM: false,
        loadingInsertFirstObjectEM: false,
        insertedFirstObjectEM: action.response,
      }
    case CLEAR_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorInsertingFirstObjectEM: false,
        loadingInsertFirstObjectEM: false,
        insertedFirstObjectEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_ADDING_TEMPLATE_TO_OBJECT:
      return {
        ...state,
        errorAddingTemplateToObjectEM: action.error,
        loadingAddTemplateToObjectEM: false,
        addedTemplateToObject: undefined,
      }
    case REQUEST_ADD_TEMPLATE_TO_OBJECT:
      return {
        ...state,
        errorAddingTemplateToObjectEM: false,
        loadingAddTemplateToObjectEM: true,
        addedTemplateToObject: undefined,
      }
    case SUCCESS_ADD_TEMPLATE_TO_OBJECT:
      return {
        ...state,
        errorAddingTemplateToObjectEM: false,
        loadingAddTemplateToObjectEM: false,
        addedTemplateToObject: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_ADDING_FORMULA_TO_OBJECT:
      return {
        ...state,
        errorAddingFormulaToObject: action.error,
        loadingAddFormulaToObject: false,
        addedFormulaToObject: undefined,
      }
    case REQUEST_ADD_FORMULA_TO_OBJECT:
      return {
        ...state,
        errorAddingFormulaToObject: false,
        loadingAddFormulaToObject: true,
        addedFormulaToObject: undefined,
      }
    case SUCCESS_ADD_FORMULA_TO_OBJECT:
      return {
        ...state,
        errorAddingFormulaToObject: false,
        loadingAddFormulaToObject: false,
        addedFormulaToObject: action.response,
        countEditingStep: action.step,
        successResponsesEventPile: [
          ...state.successResponsesEventPile,
          {
            event: action.event,
            object: action.object,
            response: action.response,
          },
        ],
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_VALIDATING_URI:
      return {
        ...state,
        errorValidatingUri: action.error,
        loadingValidateUri: false,
        validateUri: undefined,
      }
    case REQUEST_VALIDATE_URI:
      return {
        ...state,
        errorValidatingUri: false,
        loadingValidateUri: true,
        validateUri: undefined,
      }
    case SUCCESS_VALIDATE_URI:
      return {
        ...state,
        errorValidatingUri: false,
        loadingValidateUri: false,
        validateUri: action.response,
      }
    case CLEAR_VALIDATED_URI:
      return {
        ...state,
        errorValidatingUri: false,
        loadingValidateUri: false,
        validateUri: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_VALIDATING_OBJECT_URI:
      return {
        ...state,
        errorValidatingObjectUri: action.error,
        loadingValidateObjectUri: false,
        validateObjectUri: undefined,
      }
    case REQUEST_VALIDATE_OBJECT_URI:
      return {
        ...state,
        errorValidatingObjectUri: false,
        loadingValidateObjectUri: true,
        validateObjectUri: undefined,
      }
    case SUCCESS_VALIDATE_OBJECT_URI:
      return {
        ...state,
        errorValidatingObjectUri: false,
        loadingValidateObjectUri: false,
        validateObjectUri: action.response,
      }
    case CLEAR_VALIDATED_OBJECT_URI:
      return {
        ...state,
        errorValidatingObjectUri: false,
        loadingValidateObjectUri: false,
        validateObjectUri: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_VALIDATING_FIRST_LEVEL_URI:
      return {
        ...state,
        errorValidatingFirstLevelUri: action.error,
        loadingValidateFirstLevelUri: false,
        validateFirstLevelUri: undefined,
      }
    case REQUEST_VALIDATE_FIRST_LEVEL_URI:
      return {
        ...state,
        errorValidatingFirstLevelUri: false,
        loadingValidateFirstLevelUri: true,
        validateFirstLevelUri: undefined,
      }
    case SUCCESS_VALIDATE_FIRST_LEVEL_URI:
      return {
        ...state,
        errorValidatingFirstLevelUri: false,
        loadingValidateFirstLevelUri: false,
        validateFirstLevelUri: action.response,
      }
    case CLEAR_VALIDATED_FIRST_LEVEL_URI:
      return {
        ...state,
        errorValidatingFirstLevelUri: false,
        loadingValidateFirstLevelUri: false,
        validateFirstLevelUri: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_FORMULA_CONTENT_EM:
      return {
        ...state,
        errorFetchFormulaContentEM: action.error,
        loadingFetchFormulaContentEM: false,
        formulaContentEM: undefined,
      }
    case REQUEST_FORMULA_CONTENT_EM:
      return {
        ...state,
        errorFetchFormulaContentEM: false,
        loadingFetchFormulaContentEM: true,
        formulaContentEM: undefined,
      }
    case SUCCESS_FETCH_FORMULA_CONTENT_EM:
      return {
        ...state,
        errorFetchFormulaContentEM: false,
        loadingFetchFormulaContentEM: false,
        formulaContentEM: action.response,
      }
    case CLEAR_FETCH_FORMULA_CONTENT_EM:
      return {
        ...state,
        errorFetchFormulaContentEM: false,
        loadingFetchFormulaContentEM: false,
        formulaContentEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_FORMULA_CONTENT_PREVIEW_EM:
      return {
        ...state,
        errorFetchFormulaContentPreviewEM: action.error,
        loadingFetchFormulaContentPreviewEM: false,
        formulaContentPreviewEM: undefined,
      }
    case REQUEST_FORMULA_CONTENT_PREVIEW_EM:
      return {
        ...state,
        errorFetchFormulaContentPreviewEM: false,
        loadingFetchFormulaContentPreviewEM: true,
        formulaContentPreviewEM: undefined,
      }
    case SUCCESS_FETCH_FORMULA_CONTENT_PREVIEW_EM:
      return {
        ...state,
        errorFetchFormulaContentPreviewEM: false,
        loadingFetchFormulaContentPreviewEM: false,
        formulaContentPreviewEM: action.response,
      }
    case CLEAR_FETCH_FORMULA_CONTENT_PREVIEW_EM:
      return {
        ...state,
        errorFetchFormulaContentPreviewEM: false,
        loadingFetchFormulaContentPreviewEM: false,
        formulaContentPreviewEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM:
      return {
        ...state,
        errorFetchFormulaContentPreviewEM: action.error,
        loadingFetchFormulaContentPreviewEM: false,
        formulaContentPreviewEM: undefined,
      }
    case REQUEST_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM:
      return {
        ...state,
        errorFetchFormulaContentPreviewEM: false,
        loadingFetchFormulaContentPreviewEM: true,
        formulaContentPreviewEM: undefined,
      }
    case SUCCESS_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM:
      return {
        ...state,
        errorFetchFormulaContentPreviewEM: false,
        loadingFetchFormulaContentPreviewEM: false,
        formulaContentPreviewEM: action.response,
      }
    case CLEAR_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM:
      return {
        ...state,
        errorFetchFormulaContentPreviewEM: false,
        loadingFetchFormulaContentPreviewEM: false,
        formulaContentPreviewEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_MORE_POST_SEARCH_PREVIEW_EM:
      return {
        ...state,
        errorSearchByPostsEM: action.error,
        loadingSearchByPostsEM: false,
        searchByPostsEM: undefined,
      }
    case REQUEST_FETCH_MORE_POST_SEARCH_PREVIEW_EM:
      return {
        ...state,
        errorSearchByPostsEM: false,
        loadingSearchByPostsEM: true,
        searchByPostsEM: undefined,
      }
    case SUCCESS_FETCH_MORE_POST_SEARCH_PREVIEW_EM:
      return {
        ...state,
        errorSearchByPostsEM: false,
        loadingSearchByPostsEM: false,
        searchByPostsEM: action.response,
      }
    case CLEAR_FETCH_MORE_POST_SEARCH_PREVIEW_EM:
      return {
        ...state,
        errorSearchByPostsEM: false,
        loadingSearchByPostsEM: false,
        searchByPostsEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_FETCH_OBJECT_FORMULA_CONTENT_EM:
      return {
        ...state,
        errorFetchObjectFormulaContentEM: false,
        loadingFetchObjectFormulaContentEM: false,
        objectFormulaContentEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_UPDATING_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdatingObjectEM: action.error,
        loadingUpdateObjectEM: false,
        updateObjectEM: undefined,
      }
    case REQUEST_UPDATE_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdatingObjectEM: false,
        loadingUpdateObjectEM: action.objectUuid,
        updateObjectEM: undefined,
      }
    case SUCCESS_UPDATE_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdatingObjectEM: false,
        loadingUpdateObjectEM: false,
        updateObjectEM: action.object,
      }
    case CLEAR_UPDATE_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdatingObjectEM: false,
        loadingUpdateObjectEM: false,
        updateObjectEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectContentEM: false,
        loadingUpdateObjectContentEM: false,
        successUpdateObjectContentEM: undefined,
      }
    case ERROR_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectContentEM: action.error,
        loadingUpdateObjectContentEM: false,
        successUpdateObjectContentEM: undefined,
      }
    case REQUEST_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectContentEM: false,
        loadingUpdateObjectContentEM: true,
        successUpdateObjectContentEM: undefined,
      }
    case SUCCESS_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectContentEM: false,
        loadingUpdateObjectContentEM: false,
        successUpdateObjectContentEM: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectSettingsEM: false,
        loadingUpdateObjectSettingsEM: false,
        successUpdateObjectSettingsEM: undefined,
      }
    case ERROR_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectSettingsEM: action.error,
        loadingUpdateObjectSettingsEM: false,
        successUpdateObjectSettingsEM: undefined,
      }
    case REQUEST_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectSettingsEM: false,
        loadingUpdateObjectSettingsEM: true,
        successUpdateObjectSettingsEM: undefined,
      }
    case SUCCESS_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectSettingsEM: false,
        loadingUpdateObjectSettingsEM: false,
        successUpdateObjectSettingsEM: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_UPDATE_PAGE_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdatingPageObjectEM: false,
        loadingUpdatePageObjectEM: false,
        updatePageObjectEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case REQUEST_UPDATE_PAGE_SETTINGS:
      return {
        ...state,
        updatePageSettings: undefined,
        loadingUpdatePageSettings: true,
        errorUpdatePageSettings: undefined,
      }
    case RECEIVE_UPDATE_PAGE_SETTINGS:
      return {
        ...state,
        updatePageSettings: action.response,
        loadingUpdatePageSettings: false,
        errorUpdatePageSettings: undefined,
      }
    case ERROR_UPDATE_PAGE_SETTINGS:
      return {
        ...state,
        updatePageSettings: undefined,
        loadingUpdatePageSettings: false,
        errorUpdatePageSettings: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_CREATE_FORMULA_BY_POSTS_QUERY_EM:
      return {
        ...state,
        errorCreateFormulaByPostsQueryEM: action.error,
        loadingCreateFormulaByPostsQueryEM: false,
        createdFormulaByPostsQueryEM: undefined,
      }
    case REQUEST_CREATE_FORMULA_BY_POSTS_QUERY_EM:
      return {
        ...state,
        errorCreateFormulaByPostsQueryEM: false,
        loadingCreateFormulaByPostsQueryEM: true,
        createdFormulaByPostsQueryEM: undefined,
      }
    case SUCCESS_CREATE_FORMULA_BY_POSTS_QUERY_EM:
      return {
        ...state,
        errorCreateFormulaByPostsQueryEM: false,
        loadingCreateFormulaByPostsQueryEM: false,
        createdFormulaByPostsQueryEM: action.response,
        countEditingStep: action.step,
        successResponsesEventPile: [
          ...state.successResponsesEventPile,
          {
            event: action.event,
            object: action.object,
            response: action.response,
          },
        ],
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM:
      return {
        ...state,
        errorCreateDirectFormulaByPostsQueryEM: action.error,
        loadingCreateDirectFormulaByPostsQueryEM: false,
        createdDirectFormulaByPostsQueryEM: undefined,
      }
    case REQUEST_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM:
      return {
        ...state,
        errorCreateDirectFormulaByPostsQueryEM: false,
        loadingCreateDirectFormulaByPostsQueryEM: true,
        createdDirectFormulaByPostsQueryEM: undefined,
      }
    case SUCCESS_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM:
      return {
        ...state,
        errorCreateDirectFormulaByPostsQueryEM: false,
        loadingCreateDirectFormulaByPostsQueryEM: false,
        createdDirectFormulaByPostsQueryEM: action.response,
      }
    case CLEAR_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM:
      return {
        ...state,
        errorCreateDirectFormulaByPostsQueryEM: false,
        loadingCreateDirectFormulaByPostsQueryEM: false,
        createdDirectFormulaByPostsQueryEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_REORDER_OBJECTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorReOrderObjectsEM: action.error,
        loadingReOrderObjectsEM: false,
        successReOrderObjectsEM: undefined,
      }
    case REQUEST_REORDER_OBJECTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorReOrderObjectsEM: false,
        loadingReOrderObjectsEM: true,
        successReOrderObjectsEM: undefined,
      }
    case SUCCESS_REORDER_OBJECTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorReOrderObjectsEM: false,
        loadingReOrderObjectsEM: false,
        successReOrderObjectsEM: action.response,
        countEditingStep: action.step,
        successResponsesEventPile: [
          ...state.successResponsesEventPile,
          {
            event: action.event,
            object: action.object,
            response: action.response,
          },
        ],
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_MOVE_OBJECTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorMoveObjectsEM: action.error,
        loadingMoveObjectsEM: false,
        successMoveObjectsEM: undefined,
      }
    case REQUEST_MOVE_OBJECTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorMoveObjectsEM: false,
        loadingMoveObjectsEM: true,
        successMoveObjectsEM: undefined,
      }
    case SUCCESS_MOVE_OBJECTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorMoveObjectsEM: false,
        loadingMoveObjectsEM: false,
        successMoveObjectsEM: action.response,
        countEditingStep: action.step,
        successResponsesEventPile: [
          ...state.successResponsesEventPile,
          {
            event: action.event,
            object: action.object,
            response: action.response,
          },
        ],
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_DELETING_OBJECT_FORMULA_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorDeletingObjectFormulaEM: action.error,
        loadingDeleteObjectFormulaEM: false,
        deleteObjectFormulaEM: undefined,
      }
    case REQUEST_DELETE_OBJECT_FORMULA_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorDeletingObjectFormulaEM: false,
        loadingDeleteObjectFormulaEM: true,
        deleteObjectFormulaEM: undefined,
      }
    case SUCCESS_DELETE_OBJECT_FORMULA_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorDeletingObjectFormulaEM: false,
        loadingDeleteObjectFormulaEM: false,
        deleteObjectFormulaEM: action.response,
        countEditingStep: action.step,
        successResponsesEventPile: [
          ...state.successResponsesEventPile,
          {
            event: action.event,
            object: action.object,
            response: action.response,
          },
        ],
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_DELETING_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorDeletingObjectEM: action.error,
        loadingDeleteObjectEM: false,
        deleteObjectEM: undefined,
      }
    case REQUEST_DELETE_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorDeletingObjectEM: false,
        loadingDeleteObjectEM: true,
        deleteObjectEM: undefined,
      }
    case SUCCESS_DELETE_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorDeletingObjectEM: false,
        loadingDeleteObjectEM: false,
        deleteObjectEM: action.response,
        countEditingStep: action.step,
        successResponsesEventPile: [
          ...state.successResponsesEventPile,
          {
            event: action.event,
            object: action.object,
            response: action.response,
          },
        ],
      }
    case OPEN_EDITOR_MODAL:
      return {
        ...state,
        openStateEditorModal: action.open,
        editorModalId: action.id,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_DELETE_ENTIRE_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorDeleteExperienceManager: action.error,
        loadingDeleteExperienceManager: false,
        deletedEntireExperienceManager: undefined,
      }
    case REQUEST_DELETE_ENTIRE_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorDeleteExperienceManager: false,
        loadingDeleteExperienceManager: true,
        deletedEntireExperienceManager: undefined,
      }
    case SUCCESS_DELETE_ENTIRE_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorDeleteExperienceManager: false,
        loadingDeleteExperienceManager: false,
        deletedEntireExperienceManager: action.response,
      }
    case CLEAR_DELETE_ENTIRE_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorDeleteExperienceManager: false,
        loadingDeleteExperienceManager: false,
        deletedEntireExperienceManager: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorFetchComponentsCategoriesEM: action.error,
        loadingFetchComponentsCategoriesEM: false,
        componentsCategoriesEM: undefined,
      }
    case REQUEST_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorFetchComponentsCategoriesEM: false,
        loadingFetchComponentsCategoriesEM: true,
        componentsCategoriesEM: undefined,
      }
    case SUCCESS_FETCH_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorFetchComponentsCategoriesEM: false,
        loadingFetchComponentsCategoriesEM: false,
        componentsCategoriesEM: action.response,
      }
    case CLEAR_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorFetchComponentsCategoriesEM: false,
        loadingFetchComponentsCategoriesEM: false,
        componentsCategoriesEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCHING_COMPONENTS_BY_CAT_FAM:
      return {
        ...state,
        errorFetchingComponentsByCatFam: action.error,
        loadingComponentsByCatFam: false,
        componentsByCatFam: undefined,
      }
    case REQUEST_COMPONENTS_BY_CAT_FAM:
      return {
        ...state,
        errorFetchingComponentsByCatFam: false,
        loadingComponentsByCatFam: true,
        componentsByCatFam: undefined,
      }
    case SUCCESS_FETCH_COMPONENTS_BY_CAT_FAM:
      return {
        ...state,
        errorFetchingComponentsByCatFam: false,
        loadingComponentsByCatFam: false,
        componentsByCatFam: action.response,
      }
    case CLEAR_COMPONENTS_LIST_BY_CAT_FAM:
      return {
        ...state,
        errorFetchingComponentsByCatFam: false,
        loadingComponentsByCatFam: false,
        componentsByCatFam: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_SELECTED_COMPONENT_FAMILY:
      return {
        ...state,
        familyIdExperienceManager: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case DEFAULT_OBJECT_COLORS_EXPERIENCE_MANAGER:
      return {
        ...state,
        colorsObj: action.colorsObj,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_SUBMIT_FAVICON_NEW_EXPERIENCE:
      return {
        ...state,
        errorSubmitFaviconEM: action.error,
        loadingSubmitFaviconEM: false,
        submittedFaviconEM: undefined,
      }
    case REQUEST_SUBMIT_FAVICON_NEW_EXPERIENCE:
      return {
        ...state,
        errorSubmitFaviconEM: false,
        loadingSubmitFaviconEM: true,
        submittedFaviconEM: undefined,
      }
    case SUCCESS_SUBMIT_FAVICON_NEW_EXPERIENCE:
      return {
        ...state,
        errorSubmitFaviconEM: false,
        loadingSubmitFaviconEM: false,
        submittedFaviconEM: action.response,
      }
    case CLEAR_FAVICON_NEW_EXPERIENCE:
      return {
        ...state,
        errorSubmitFaviconEM: false,
        loadingSubmitFaviconEM: false,
        submittedFaviconEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_UPDATE_FAVICON_NEW_EXPERIENCE:
      return {
        ...state,
        errorUpdateFaviconEM: action.error,
        loadingUpdateFaviconEM: false,
        updatedFaviconEM: undefined,
      }
    case REQUEST_UPDATE_FAVICON_NEW_EXPERIENCE:
      return {
        ...state,
        errorUpdateFaviconEM: false,
        loadingUpdateFaviconEM: true,
        updatedFaviconEM: undefined,
      }
    case SUCCESS_UPDATE_FAVICON_NEW_EXPERIENCE:
      return {
        ...state,
        errorUpdateFaviconEM: false,
        loadingUpdateFaviconEM: false,
        updatedFaviconEM: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_CHECK_CREATED_HUB:
      return {
        ...state,
        errorCheckCreatedHubEM: action.error,
        loadingCheckCreatedHubEM: false,
        checkCreatedHubEM: undefined,
      }
    case REQUEST_CHECK_CREATED_HUB:
      return {
        ...state,
        errorCheckCreatedHubEM: false,
        loadingCheckCreatedHubEM: true,
        checkCreatedHubEM: undefined,
      }
    case SUCCESS_CHECK_CREATED_HUB:
      return {
        ...state,
        errorCheckCreatedHubEM: false,
        loadingCheckCreatedHubEM: false,
        checkCreatedHubEM: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_FAVICON_UPLOAD_EM:
      return {
        ...state,
        errorUpdateFaviconEM: false,
        loadingUpdateFaviconEM: false,
        updatedFaviconEM: undefined,
        submittedFaviconEM: false,
        errorSubmitFaviconEM: false,
        loadingSubmitFaviconEM: false,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_CHECK_CREATED_EM:
      return {
        ...state,
        errorCheckCreatedHubEM: false,
        loadingCheckCreatedHubEM: false,
        checkCreatedHubEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case COPY_OBJECT_EM:
      return {
        ...state,
        clipboard: action.obj,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case OBJECT_TO_PASTE_EM:
      return {
        ...state,
        objIdToPaste: action.objId,
        objToPasteOrder: action.order,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case PASTED_SERVER_OBJECT_EM:
      return {
        ...state,
        serverObjIdToReceivePaste: action.objId,
        serverObjOrder: action.order,
        serverObjToPaste: action.clipboard,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case PASTE_OBJECT_EM:
      return {
        ...state,
        pasteObj: action.obj,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_PASTE_OBJECT_EM:
      return {
        ...state,
        pasteObj: undefined,
        objIdToPaste: undefined,
        objToPasteOrder: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_SERVER_COPY_PASTE_EM:
      return {
        ...state,
        serverObjIdToReceivePaste: undefined,
        serverObjOrder: undefined,
        serverObjToPaste: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_COPY_OBJECT_EM:
      return {
        ...state,
        clipboard: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case COPY_OBJECT_TEMPLATE_EM:
      return {
        ...state,
        clipboardStylesObjectId: action.objId,
      }
    case PASTE_OBJECT_TEMPLATE_EM:
      return {
        ...state,
        pasteObjTemplate: action.obj,
      }
    case CLEAR_COPY_OBJECT_TEMPLATE_EM:
      return {
        ...state,
        clipboardStylesObjectId: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ADD_BULK_EVENT:
      return {
        ...state,
        eventPile: [...state.eventPile, ...action.events],
      }
    case ERROR_DELETE_HOST_OUTPUTTERS:
      return {
        ...state,
        errorDeleteHostOutputters: action.error,
        loadingDeleteHostOutputters: false,
        deletedHostOutputters: undefined,
      }
    case REQUEST_DELETE_HOST_OUTPUTTERS:
      return {
        ...state,
        errorDeleteHostOutputters: false,
        loadingDeleteHostOutputters: true,
        deletedHostOutputters: undefined,
      }
    case SUCCESS_DELETE_HOST_OUTPUTTERS:
      return {
        ...state,
        errorDeleteHostOutputters: false,
        loadingDeleteHostOutputters: false,
        deletedHostOutputters: action.response,
      }
    case CLEAR_DELETE_HOST_OUTPUTTERS:
      return {
        ...state,
        errorDeleteHostOutputters: false,
        loadingDeleteHostOutputters: false,
        deletedHostOutputters: undefined,
        deletedHostReference: action.host,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case SELECTED_FORMULA_TO_PREVIEW:
      return {
        ...state,
        selectedFormulaToPreview: action.selectedFormula,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ADD_BULK_POSTS_EXPERIENCE_MANAGER:
      if (action.clearContent) {
        return {
          ...state,
          selectedPostsEM: [...action.posts],
        }
      }
      return {
        ...state,
        selectedPostsEM: [...state.selectedPostsEM, ...action.posts],
      }
    case ADD_SINGLE_POST_EXPERIENCE_MANAGER:
      if (CheckDuplicated.posts(state.selectedPostsEM, action.post)) {
        return state
      }
      return {
        ...state,
        selectedPostsEM: [...state.selectedPostsEM, action.post],
        editedSelectedPostsEM: true,
      }
    case ADD_SINGLE_POST_BY_EMBED_EXPERIENCE_MANAGER:
      if (CheckDuplicated.posts(state.selectedPostsEM, action.post)) {
        return state
      }
      return {
        ...state,
        selectedPostsEM: [...state.selectedPostsEM, action.post],
        editedSelectedPostsEM: true,
        searchByPostsEM: { response: { content: [action.post] } },
      }
    case REMOVE_SINGLE_POST_EXPERIENCE_MANAGER:
      let arrWithoutPost = state.selectedPostsEM.filter((post: any) => post.id !== action.post.id)
      return {
        ...state,
        selectedPostsEM: [...arrWithoutPost],
        editedSelectedPostsEM: true,
      }
    case CLEAR_POSTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        selectedPostsEM: [],
      }
    case CLEAR_EDITED_POSTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        editedSelectedPostsEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCHING_FORMULA_INFO:
      return {
        ...state,
        errorFetchingFormulaInfo: action.error,
        loadingFormulaInfo: false,
        formulaInfo: undefined,
      }
    case REQUEST_FORMULA_INFO:
      return {
        ...state,
        errorFetchingFormulaInfo: false,
        loadingFormulaInfo: true,
        formulaInfo: undefined,
      }
    case SUCCESS_FETCH_FORMULA_INFO:
      return {
        ...state,
        errorFetchingFormulaInfo: false,
        loadingFormulaInfo: false,
        formulaInfo: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_HOST:
      return {
        ...state,
        errorFetchHost: action.error,
        fetchingHost: false,
        fetchedHost: undefined,
      }
    case FETCHING_HOST:
      return {
        ...state,
        errorFetchHost: false,
        fetchingHost: true,
        fetchedHost: undefined,
      }
    case SUCCESS_FETCH_HOST:
      return {
        ...state,
        errorFetchHost: false,
        fetchingHost: false,
        fetchedHost: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_REGISTER_DOMAIN_GOOGLE:
      return {
        ...state,
        errorRegisterDomainGoogle: action.error,
        loadingRegisterDomainGoogle: false,
        registerDomainGoogle: undefined,
      }
    case REQUEST_REGISTER_DOMAIN_GOOGLE:
      return {
        ...state,
        errorRegisterDomainGoogle: false,
        loadingRegisterDomainGoogle: action.hostId,
        registerDomainGoogle: undefined,
      }
    case SUCCESS_REGISTER_DOMAIN_GOOGLE:
      return {
        ...state,
        errorRegisterDomainGoogle: false,
        loadingRegisterDomainGoogle: false,
        registerDomainGoogle: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_SEND_SITEMAP:
      return {
        ...state,
        errorSendSitemap: action.error,
        loadingSendSitemap: false,
        sendSitemap: undefined,
      }
    case REQUEST_SEND_SITEMAP:
      return {
        ...state,
        errorSendSitemap: false,
        loadingSendSitemap: action.hostId,
        sendSitemap: undefined,
      }
    case SUCCESS_SEND_SITEMAP:
      return {
        ...state,
        errorSendSitemap: false,
        loadingSendSitemap: false,
        sendSitemap: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_SUBMIT_SOURCE_TO_POST:
      return {
        ...state,
        errorSubmitSourceToPost: action.error,
        loadingSubmitSourceToPost: false,
        submittedSourceToPost: undefined,
      }
    case REQUEST_SUBMIT_SOURCE_TO_POST:
      return {
        ...state,
        errorSubmitSourceToPost: false,
        loadingSubmitSourceToPost: true,
        submittedSourceToPost: undefined,
        //Clear all posts from postPreview
        searchByPostsEM: undefined,
      }
    case SUCCESS_SUBMIT_SOURCE_TO_POST:
      return {
        ...state,
        errorSubmitSourceToPost: false,
        loadingSubmitSourceToPost: false,
        submittedSourceToPost: action.response,
      }
    case CLEAR_SOURCE_TO_POST:
      return {
        ...state,
        errorSubmitSourceToPost: false,
        loadingSubmitSourceToPost: false,
        submittedSourceToPost: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_CHANGE_POSTS_EXPERIENCE_ORDER:
      return {
        ...state,
        errorChangePostsExperienceOrder: action.error,
        loadingChangePostsExperienceOrder: false,
        changedPostsExperienceOrder: undefined,
      }
    case REQUEST_CHANGE_POSTS_EXPERIENCE_ORDER:
      return {
        ...state,
        errorChangePostsExperienceOrder: false,
        loadingChangePostsExperienceOrder: true,
        changedPostsExperienceOrder: undefined,
      }
    case SUCCESS_CHANGE_POSTS_EXPERIENCE_ORDER:
      return {
        ...state,
        errorChangePostsExperienceOrder: false,
        loadingChangePostsExperienceOrder: false,
        changedPostsExperienceOrder: action.response,
        countEditingStep: action.step,
        successResponsesEventPile: [
          ...state.successResponsesEventPile,
          {
            event: action.event,
            object: action.object,
            response: action.response,
          },
        ],
      }
    case CLEAR_POSTS_EXPERIENCE_ORDER:
      return {
        ...state,
        errorChangePostsExperienceOrder: false,
        loadingChangePostsExperienceOrder: false,
        changedPostsExperienceOrder: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_SEARCH_BY_POSTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorSearchByPostsEM: action.error,
        loadingSearchByPostsEM: false,
        searchByPostsEM: undefined,
        searchTermEM: undefined,
      }
    case REQUEST_SEARCH_BY_POSTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorSearchByPostsEM: false,
        loadingSearchByPostsEM: true,
        searchByPostsEM: undefined,
        searchTermEM: undefined,
      }
    case SUCCESS_SEARCH_BY_POSTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorSearchByPostsEM: false,
        loadingSearchByPostsEM: false,
        searchByPostsEM: action.response,
        searchTermEM: action.searchTerm,
      }
    case CLEAR_SEARCH_BY_POSTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorSearchByPostsEM: false,
        loadingSearchByPostsEM: false,
        searchByPostsEM: undefined,
        searchTermEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    case ERROR_VALIDATE_GOOGLE:
      return {
        ...state,
        errorValidateGoogle: action.error,
        loadingValidateGoogle: false,
        validateGoogle: undefined,
      }
    case REQUEST_VALIDATE_GOOGLE:
      return {
        ...state,
        errorValidateGoogle: false,
        loadingValidateGoogle: action.hostId,
        validateGoogle: undefined,
      }
    case SUCCESS_VALIDATE_GOOGLE:
      return {
        ...state,
        errorValidateGoogle: false,
        loadingValidateGoogle: false,
        validateGoogle: action.response,
      }

    case CLEAR_DOMAIN_ACTIONS:
      return {
        ...state,
        errorValidateGoogle: false,
        loadingValidateGoogle: false,
        validateGoogle: false,

        errorSendSitemap: false,
        loadingSendSitemap: false,
        sendSitemap: false,

        errorRegisterDomainGoogle: false,
        loadingRegisterDomainGoogle: false,
        registerDomainGoogle: false,
      }

    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case OBJECT_SELECTOR_MODE_EM:
      return {
        ...state,
        objectSelectorModeEM: action.value,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case EVENT_PILE_SUCCESS_RESPONSES_EM:
      return {
        ...state,
        successResponsesEventPile: [...state.successResponsesEventPile, action.eventResponse],
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_HUB_TO_CLONE_EXPERERIENCE_MANAGER:
      return {
        ...state,
        loadingHubToCloneEM: false,
        errorHubToCloneEM: action.error,
        hubToCloneEM: undefined,
      }
    case REQUEST_HUB_TO_CLONE_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingHubToCloneEM: true,
        errorHubToCloneEM: false,
        hubToCloneEM: undefined,
      }
    case SUCCESS_HUB_TO_CLONE_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingHubToCloneEM: false,
        errorHubToCloneEM: false,
        hubToCloneEM: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_OG_IMAGE_UPLOAD:
      return {
        ...state,
        loadingOGImageUpload: false,
        errorOGImageUpload: action.error,
        oGImageUpload: undefined,
      }
    case REQUEST_OG_IMAGE_UPLOAD:
      return {
        ...state,
        loadingOGImageUpload: true,
        errorOGImageUpload: false,
        oGImageUpload: undefined,
      }
    case SUCCESS_OG_IMAGE_UPLOAD:
      return {
        ...state,
        loadingOGImageUpload: false,
        errorOGImageUpload: false,
        oGImageUpload: action.response,
      }
    case CLEAR_OG_IMAGE_UPLOAD:
      return {
        ...state,
        loadingOGImageUpload: false,
        errorOGImageUpload: false,
        oGImageUpload: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_LAYOUTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingLayoutsEM: false,
        errorLayoutsEM: action.error,
        layoutsEM: undefined,
      }
    case REQUEST_LAYOUTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingLayoutsEM: true,
        errorLayoutsEM: false,
        layoutsEM: undefined,
      }
    case SUCCESS_LAYOUTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingLayoutsEM: false,
        errorLayoutsEM: false,
        layoutsEM: action.response,
      }
    case CLEAR_LAYOUTS_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingLayoutsEM: false,
        errorLayoutsEM: false,
        layoutsEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_SELECTED_LAYOUT_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingSelectedLayoutEM: false,
        errorSelectedLayoutEM: action.error,
        selectedLayoutEM: undefined,
        selectedLayoutActionEM: undefined,
      }
    case REQUEST_SELECTED_LAYOUT_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingSelectedLayoutEM: true,
        errorSelectedLayoutEM: false,
        selectedLayoutEM: undefined,
        selectedLayoutActionEM: undefined,
      }
    case SUCCESS_SELECTED_LAYOUT_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingSelectedLayoutEM: false,
        errorSelectedLayoutEM: false,
        selectedLayoutEM: action.response,
        selectedLayoutActionEM: action.actionType,
      }
    case CLEAR_SELECTED_LAYOUT_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingSelectedLayoutEM: false,
        errorSelectedLayoutEM: false,
        selectedLayoutEM: undefined,
        selectedLayoutActionEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_SAVED_HUB_FOR_PREVIEW:
      return {
        ...state,
        savedHubForPreview: undefined,
      }
    case SAVED_HUB_FOR_PREVIEW:
      return {
        ...state,
        savedHubForPreview: action.hubObj,
      }
    case HANDLE_NEW_PAGE_MODAL:
      return {
        ...state,
        newPageModalState: action.isOpen,
        selectedHost: action.host,
        selectedSite: action.site,
        selectedTemplate: action.template,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_CLONE_PAGE_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingClonePageEM: false,
        errorClonePageEM: action.error,
        clonedPageEM: undefined,
      }
    case REQUEST_CLONE_PAGE_EXPERIENCE_MAGAGER:
      return {
        ...state,
        loadingClonePageEM: true,
        errorClonePageEM: false,
        clonedPageEM: undefined,
      }
    case SUCCESS_CLONE_PAGE_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingClonePageEM: false,
        errorClonePageEM: false,
        clonedPageEM: action.response,
      }
    case CLEAR_CLONE_PAGE_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingClonePageEM: false,
        errorClonePageEM: false,
        clonedPageEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_COPY_PASTE_EM:
      return {
        ...state,
        loadingCopyPasteEM: false,
        errorCopyPasteEM: action.error,
        copyPastedEM: undefined,
      }
    case REQUEST_COPY_PASTE_EM:
      return {
        ...state,
        loadingCopyPasteEM: true,
        errorCopyPasteEM: false,
        copyPastedEM: undefined,
      }
    case SUCCESS_COPY_PASTE_EM:
      return {
        ...state,
        loadingCopyPasteEM: false,
        errorCopyPasteEM: false,
        copyPastedEM: action.response,
      }
    case CLEAR_COPY_PASTE_EM:
      return {
        ...state,
        loadingCopyPasteEM: false,
        errorCopyPasteEM: false,
        copyPastedEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_COPY_PASTE_STYLES_EM:
      return {
        ...state,
        loadingCopyPasteStylesEM: false,
        errorCopyPasteStylesEM: action.error,
        copyPastedStylesEM: undefined,
      }
    case REQUEST_COPY_PASTE_STYLES_EM:
      return {
        ...state,
        loadingCopyPasteStylesEM: true,
        errorCopyPasteStylesEM: false,
        copyPastedStylesEM: undefined,
      }
    case SUCCESS_COPY_PASTE_STYLES_EM:
      return {
        ...state,
        loadingCopyPasteStylesEM: false,
        errorCopyPasteStylesEM: false,
        copyPastedStylesEM: action.response,
      }
    case CLEAR_COPY_PASTE_STYLES_EM:
      return {
        ...state,
        loadingCopyPasteStylesEM: false,
        errorCopyPasteStylesEM: false,
        copyPastedStylesEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case EVENT_ID_EM:
      return {
        ...state,
        eventIdEM: action.newId,
      }
    case AUTO_SELECT_ON_CREATE_MODE_EM:
      return {
        ...state,
        autoSelectState: action.state,
      }
    case SELECTED_BOB_ELEMENT_EM:
      return {
        ...state,
        selectedBobEM: action.element,
      }
    case ELEMENT_SELECTOR_STATE:
      return {
        ...state,
        elementSelectorStateEM: action.elementState,
      }
    case OBJECT_SELECTOR_STATE:
      return {
        ...state,
        objectSelectorStateEM: action.objectState,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_STAGING_PAGE_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingStagingPage: false,
        errorStagingPage: action.error,
        stagingPage: undefined,
      }
    case REQUEST_STAGING_PAGE_EXPERIENCE_MANAGER:
      return {
        ...state,
        loadingStagingPage: true,
        errorStagingPage: false,
        stagingPage: undefined,
      }
    case SUCCESS_STAGING_PAGE_EXPERIENCE_MANAGER:
      let pageResponse = action.experienceContent.response || action.experienceContent
      return {
        ...state,
        loadingStagingPage: false,
        errorStagingPage: false,
        stagingPage: pageResponse,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case OPEN_COLOR_PICKER_STYLES:
      return {
        ...state,
        elementSelected: action.element,
        elementColor: action.color,
        colorResponsiveData: action.colorResponsiveData,
        selectedController: action.selectedController,
        foundationStylesList: action.foundationStylesList,
        finderPreview: action.finderPreview,
      }
    case COLOR_PICKER_EXPORT_SELECTION:
      return {
        ...state,
        exportedColorElement: action.element,
        exportedColor: action.color,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_PUBLISH_PAGE:
      return {
        ...state,
        loadingPublishPage: false,
        errorPublishPage: action.error,
        successPublishPage: undefined,
      }
    case REQUEST_PUBLISH_PAGE:
      return {
        ...state,
        loadingPublishPage: true,
        errorPublishPage: false,
        successPublishPage: undefined,
      }
    case SUCCESS_PUBLISH_PAGE:
      return {
        ...state,
        loadingPublishPage: false,
        errorPublishPage: false,
        successPublishPage: action.response,
      }
    case CLEAR_PUBLISH_PAGE:
      return {
        ...state,
        loadingPublishPage: false,
        errorPublishPage: false,
        successPublishPage: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_UNPUBLISH_PAGE:
      return {
        ...state,
        loadingUnpublishPage: false,
        errorUnpublishPage: action.error,
        successUnpublishPage: undefined,
      }
    case REQUEST_UNPUBLISH_PAGE:
      return {
        ...state,
        loadingUnpublishPage: true,
        errorUnpublishPage: false,
        successUnpublishPage: undefined,
      }
    case SUCCESS_UNPUBLISH_PAGE:
      return {
        ...state,
        loadingUnpublishPage: false,
        errorUnpublishPage: false,
        successUnpublishPage: action.response,
      }
    case CLEAR_UNPUBLISH_PAGE:
      return {
        ...state,
        loadingUnpublishPage: false,
        errorUnpublishPage: false,
        successUnpublishPage: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_PUBLISH_SITE_PAGES:
      return {
        ...state,
        loadingPublishSitePages: false,
        errorPublishSitePages: action.error,
        successPublishSitePages: undefined,
        publishSitePagesId: action.siteId,
      }
    case REQUEST_PUBLISH_SITE_PAGES:
      return {
        ...state,
        loadingPublishSitePages: true,
        errorPublishSitePages: false,
        successPublishSitePages: undefined,
        publishSitePagesId: action.siteId,
      }
    case SUCCESS_PUBLISH_SITE_PAGES:
      return {
        ...state,
        loadingPublishSitePages: false,
        errorPublishSitePages: false,
        successPublishSitePages: action.response,
        publishSitePagesId: action.siteId,
      }
    case CLEAR_PUBLISH_SITE_PAGES:
      return {
        ...state,
        loadingPublishSitePages: false,
        errorPublishSitePages: false,
        successPublishSitePages: undefined,
        publishSitePagesId: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_PUBLISH_SITE_PAGES_STATUS:
      return {
        ...state,
        loadingPublishSitePagesStatus: false,
        errorPublishSitePagesStatus: action.error,
        successPublishSitePagesStatus: undefined,
        publishSitePagesId: action.siteId,
      }
    case REQUEST_PUBLISH_SITE_PAGES_STATUS:
      return {
        ...state,
        loadingPublishSitePagesStatus: true,
        errorPublishSitePagesStatus: false,
        successPublishSitePagesStatus: undefined,
        publishSitePagesId: action.siteId,
      }
    case SUCCESS_PUBLISH_SITE_PAGES_STATUS:
      return {
        ...state,
        loadingPublishSitePagesStatus: false,
        errorPublishSitePagesStatus: false,
        successPublishSitePagesStatus: action.response,
        publishSitePagesId: action.siteId,
      }
    case CLEAR_PUBLISH_SITE_PAGES_STATUS:
      return {
        ...state,
        loadingPublishSitePagesStatus: false,
        errorPublishSitePagesStatus: false,
        successPublishSitePagesStatus: undefined,
        publishSitePagesId: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_PUBLISH_OBJECT_TEMPLATE:
      return {
        ...state,
        loadingPublishObjectTemplate: false,
        errorPublishObjectTemplate: action.error,
        successPublishObjectTemplate: undefined,
      }
    case REQUEST_PUBLISH_OBJECT_TEMPLATE:
      return {
        ...state,
        loadingPublishObjectTemplate: true,
        errorPublishObjectTemplate: false,
        successPublishObjectTemplate: undefined,
      }
    case SUCCESS_PUBLISH_OBJECT_TEMPLATE:
      return {
        ...state,
        loadingPublishObjectTemplate: false,
        errorPublishObjectTemplate: false,
        successPublishObjectTemplate: action.response,
      }
    case CLEAR_PUBLISH_OBJECT_TEMPLATE:
      return {
        ...state,
        loadingPublishObjectTemplate: false,
        errorPublishObjectTemplate: false,
        successPublishObjectTemplate: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_UNPUBLISH_OBJECT_TEMPLATE:
      return {
        ...state,
        loadingUnpublishObjectTemplate: false,
        errorUnpublishObjectTemplate: action.error,
        successUnpublishObjectTemplate: undefined,
      }
    case REQUEST_UNPUBLISH_OBJECT_TEMPLATE:
      return {
        ...state,
        loadingUnpublishObjectTemplate: true,
        errorUnpublishObjectTemplate: false,
        successUnpublishObjectTemplate: undefined,
      }
    case SUCCESS_UNPUBLISH_OBJECT_TEMPLATE:
      return {
        ...state,
        loadingUnpublishObjectTemplate: false,
        errorUnpublishObjectTemplate: false,
        successUnpublishObjectTemplate: action.response,
      }
    case CLEAR_UNPUBLISH_OBJECT_TEMPLATE:
      return {
        ...state,
        loadingUnpublishObjectTemplate: false,
        errorUnpublishObjectTemplate: false,
        successUnpublishObjectTemplate: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    //Symbols
    //create instance
    case RECEIVE_CREATE_SYMBOL_INSTANCE:
      return {
        ...state,
        loadingCreateSymbolInstance: false,
        errorCreateSymbolInstance: false,
        successCreateSymbolInstance: action.response,
        countEditingStep: action.step,
        successResponsesEventPile: [
          ...state.successResponsesEventPile,
          {
            event: action.event,
            object: action.object,
            response: action.response,
          },
        ],
      }
    case REQUEST_CREATE_SYMBOL_INSTANCE:
      return {
        ...state,
        loadingCreateSymbolInstance: true,
        errorCreateSymbolInstance: false,
        successCreateSymbolInstance: undefined,
      }
    case ERROR_CREATE_SYMBOL_INSTANCE:
      return {
        ...state,
        loadingCreateSymbolInstance: false,
        errorCreateSymbolInstance: action.error,
        successCreateSymbolInstance: undefined,
      }
    case CLEAR_CREATE_SYMBOL_INSTANCE:
      return {
        ...state,
        loadingCreateSymbolInstance: false,
        errorCreateSymbolInstance: false,
        successCreateSymbolInstance: undefined,
      }
    //Set
    case RECEIVE_SET_SYMBOL:
      return {
        ...state,
        loadingSetSymbol: false,
        errorSetSymbol: false,
        successSetSymbol: action.response,
      }
    case REQUEST_SET_SYMBOL:
      return {
        ...state,
        loadingSetSymbol: action.oldObjectUuid,
        errorSetSymbol: false,
        successSetSymbol: undefined,
      }
    case ERROR_SET_SYMBOL:
      return {
        ...state,
        loadingSetSymbol: false,
        errorSetSymbol: action.error,
        successSetSymbol: undefined,
      }
    case CLEAR_SET_SYMBOL:
      return {
        ...state,
        loadingSetSymbol: false,
        errorSetSymbol: undefined,
        successSetSymbol: undefined,
      }
    //Unset
    case RECEIVE_UNSET_SYMBOL:
      return {
        ...state,
        loadingUnsetSymbol: false,
        errorUnsetSymbol: false,
        successUnsetSymbol: action.response,
      }
    case REQUEST_UNSET_SYMBOL:
      return {
        ...state,
        loadingUnsetSymbol: action.objectUuid,
        errorUnsetSymbol: false,
        successUnsetSymbol: undefined,
      }
    case ERROR_UNSET_SYMBOL:
      return {
        ...state,
        loadingUnsetSymbol: false,
        errorUnsetSymbol: action.error,
        successUnsetSymbol: undefined,
      }
    case CLEAR_UNSET_SYMBOL:
      return {
        ...state,
        loadingUnsetSymbol: false,
        errorUnsetSymbol: undefined,
        successUnsetSymbol: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectContentEM: action.error,
        loadingUpdateObjectContentEM: false,
        successUpdateObjectContentEM: undefined,
      }
    case REQUEST_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectContentEM: false,
        loadingUpdateObjectContentEM: true,
        successUpdateObjectContentEM: undefined,
      }
    case SUCCESS_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectContentEM: false,
        loadingUpdateObjectContentEM: false,
        successUpdateObjectContentEM: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case CLEAR_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectContentEM: false,
        loadingUpdateObjectContentEM: false,
        successUpdateObjectContentEM: undefined,
        instanceContentReset: false,
      }
    case ERROR_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectContentEM: action.error,
        loadingUpdateObjectContentEM: false,
        successUpdateObjectContentEM: undefined,
        instanceContentReset: false,
      }
    case REQUEST_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectContentEM: false,
        loadingUpdateObjectContentEM: true,
        successUpdateObjectContentEM: undefined,
        instanceContentReset: false,
      }
    case SUCCESS_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorUpdateObjectContentEM: false,
        loadingUpdateObjectContentEM: false,
        successUpdateObjectContentEM: action.response,
        instanceContentReset: true,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case REQUEST_SET_CONTROLLER_STATE:
      return {
        ...state,
        controllerState: action.state,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ADD_OBJECT_TEMPLATE:
      return {
        ...state,
        addObjectTemplate: action.object,
        addObjectTemplateType: action.addBehaviourType,
      }
    case CLEAR_ADD_OBJECT_TEMPLATE:
      return {
        ...state,
        addObjectTemplate: undefined,
        addObjectTemplateType: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorCreateObjectTemplateEM: action.error,
        loadingCreateObjectTemplateEM: false,
        successCreateObjectTemplateEM: undefined,
      }
    case REQUEST_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorCreateObjectTemplateEM: false,
        loadingCreateObjectTemplateEM: true,
        successCreateObjectTemplateEM: undefined,
      }
    case SUCCESS_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER:
      /**
       * When we are dealing with object creation in symbol edit mode
       * the ids are composed with the instanceID _ objectUuid
       */
      if (action.composedId) {
        let clonedTree = cloneDeep(action.response.tree)
        clonedTree.parentId = action.composedId

        return {
          ...state,
          errorCreateObjectTemplateEM: false,
          loadingCreateObjectTemplateEM: false,
          successCreateObjectTemplateEM: {
            commands: action.response.commands,
            tree: clonedTree,
          },
        }
      }
      return {
        ...state,
        errorCreateObjectTemplateEM: false,
        loadingCreateObjectTemplateEM: false,
        successCreateObjectTemplateEM: action.response,
      }
    case CLEAR_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER:
      return {
        ...state,
        errorCreateObjectTemplateEM: false,
        loadingCreateObjectTemplateEM: false,
        successCreateObjectTemplateEM: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_DELETE_OBJECT_TEMPLATE:
      return {
        ...state,
        errorDeleteObjectTemplate: action.error,
        loadingDeleteObjectTemplate: false,
        deletedObjectTemplate: undefined,
      }
    case REQUEST_DELETE_OBJECT_TEMPLATE:
      return {
        ...state,
        errorDeleteObjectTemplate: false,
        loadingDeleteObjectTemplate: true,
        deletedObjectTemplate: undefined,
      }
    case SUCCESS_DELETE_OBJECT_TEMPLATE:
      return {
        ...state,
        errorDeleteObjectTemplate: false,
        loadingDeleteObjectTemplate: false,
        deletedObjectTemplate: action.response,
      }
    case CLEAR_DELETE_OBJECT_TEMPLATE:
      return {
        ...state,
        errorDeleteObjectTemplate: false,
        loadingDeleteObjectTemplate: false,
        deletedObjectTemplate: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_UPDATE_OBJECT_TEMPLATE:
      return {
        ...state,
        errorUpdateObjectTemplate: action.error,
        loadingUpdateObjectTemplate: false,
        updatedObjectTemplate: undefined,
      }
    case REQUEST_UPDATE_OBJECT_TEMPLATE:
      return {
        ...state,
        errorUpdateObjectTemplate: false,
        loadingUpdateObjectTemplate: true,
        updatedObjectTemplate: undefined,
      }
    case SUCCESS_UPDATE_OBJECT_TEMPLATE:
      return {
        ...state,
        errorUpdateObjectTemplate: false,
        loadingUpdateObjectTemplate: false,
        updatedObjectTemplate: action.response,
        countEditingStep: action.step,
        successResponsesEventPile: [
          ...state.successResponsesEventPile,
          {
            event: action.event,
            object: action.object,
            response: action.response,
          },
        ],
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case SET_BEHAVIOUR_STATE:
      return {
        ...state,
        behaviourState: action.behaviourState,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case VIEW_PORT_ORIGINAL_SIZE_STATE_EXPERIENCE_MANAGER:
      return {
        ...state,
        viewPortOriginalSizeState: action.viewPortSize,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case REQUEST_STAGING_OBJECT_TEMPLATE_BY_ID:
      return {
        ...state,
        loadingStagingPage: true,
        errorStagingPage: undefined,
        stagingPage: undefined,
      }
    case SUCCESS_STAGING_OBJECT_TEMPLATE_BY_ID:
      return {
        ...state,
        loadingStagingPage: false,
        stagingPage: action.response,
        errorStagingPage: undefined,
      }
    case ERROR_FETCH_STAGING_OBJECT_TEMPLATE_BY_ID:
      return {
        ...state,
        loadingStagingPage: false,
        errorStagingPage: action.error,
        stagingPage: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case REQUEST_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID:
      return {
        ...state,
        loadingStagingPage: true,
        errorStagingPage: undefined,
        stagingPage: undefined,
      }
    case SUCCESS_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID:
      return {
        ...state,
        loadingStagingPage: false,
        stagingPage: action.response,
        errorStagingPage: undefined,
      }
    case ERROR_FETCH_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID:
      return {
        ...state,
        loadingStagingPage: false,
        errorStagingPage: action.error,
        stagingPage: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case REQUEST_STAGING_SYMBOL_BY_ID:
      return {
        ...state,
        loadingStagingPage: true,
        errorStagingPage: undefined,
        stagingPage: undefined,
      }
    case SUCCESS_STAGING_SYMBOL_BY_ID:
      return {
        ...state,
        loadingStagingPage: false,
        stagingPage: action.response,
        errorStagingPage: undefined,
      }
    case ERROR_FETCH_STAGING_SYMBOL_BY_ID:
      return {
        ...state,
        loadingStagingPage: false,
        errorStagingPage: action.error,
        stagingPage: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_OBJECT_TEMPLATE_SCREEN_SHOT:
      return {
        ...state,
        loadingObjectTemplateScreenShot: false,
        errorObjectTemplateScreenShot: action.error,
        successObjectTemplateScreenShot: undefined,
      }
    case REQUEST_OBJECT_TEMPLATE_SCREEN_SHOT:
      return {
        ...state,
        loadingObjectTemplateScreenShot: true,
        errorObjectTemplateScreenShot: false,
        successObjectTemplateScreenShot: undefined,
      }
    case SUCCESS_OBJECT_TEMPLATE_SCREEN_SHOT:
      return {
        ...state,
        loadingObjectTemplateScreenShot: false,
        errorObjectTemplateScreenShot: false,
        successObjectTemplateScreenShot: action.response,
      }
    case CLEAR_OBJECT_TEMPLATE_SCREEN_SHOT:
      return {
        ...state,
        loadingObjectTemplateScreenShot: false,
        errorObjectTemplateScreenShot: false,
        successObjectTemplateScreenShot: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_GET_PLATFORM_PAGE_TEMPLATES:
      return {
        ...state,
        loadingPlatformPageTemplatesList: false,
        errorPlatformPageTemplatesList: action.error,
        successPlatformPageTemplatesList: undefined,
      }
    case REQUEST_GET_PLATFORM_PAGE_TEMPLATES:
      return {
        ...state,
        loadingPlatformPageTemplatesList: true,
        errorPlatformPageTemplatesList: false,
        successPlatformPageTemplatesList: undefined,
      }
    case SUCCESS_GET_PLATFORM_PAGE_TEMPLATES:
      return {
        ...state,
        loadingPlatformPageTemplatesList: false,
        errorPlatformPageTemplatesList: false,
        successPlatformPageTemplatesList: action.response,
      }
    case CLEAR_GET_PLATFORM_PAGE_TEMPLATES:
      return {
        ...state,
        loadingPlatformPageTemplatesList: false,
        errorPlatformPageTemplatesList: false,
        successPlatformPageTemplatesList: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_CREATE_FLUXIO_POST:
      return {
        ...state,
        loadingCreateFluxioPost: false,
        errorCreateFluxioPost: action.error,
        successCreateFluxioPost: undefined,
      }
    case REQUEST_CREATE_FLUXIO_POST:
      return {
        ...state,
        loadingCreateFluxioPost: true,
        errorCreateFluxioPost: false,
        successCreateFluxioPost: undefined,
      }
    case SUCCESS_CREATE_FLUXIO_POST:
      return {
        ...state,
        loadingCreateFluxioPost: false,
        errorCreateFluxioPost: false,
        successCreateFluxioPost: action.response,
      }
    case CLEAN_CREATE_FLUXIO_POST:
      return {
        ...state,
        loadingCreateFluxioPost: false,
        errorCreateFluxioPost: false,
        successCreateFluxioPost: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_UPDATE_FLUXIO_POST:
      return {
        ...state,
        loadingUpdateFluxioPost: false,
        errorUpdateFluxioPost: action.error,
        successUpdateFluxioPost: undefined,
      }
    case REQUEST_UPDATE_FLUXIO_POST:
      return {
        ...state,
        loadingUpdateFluxioPost: true,
        errorUpdateFluxioPost: false,
        successUpdateFluxioPost: undefined,
      }
    case SUCCESS_UPDATE_FLUXIO_POST:
      return {
        ...state,
        loadingUpdateFluxioPost: false,
        errorUpdateFluxioPost: false,
        successUpdateFluxioPost: action.response,
      }
    case CLEAN_UPDATE_FLUXIO_POST:
      return {
        ...state,
        loadingUpdateFluxioPost: false,
        errorUpdateFluxioPost: false,
        successUpdateFluxioPost: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_DELETE_FLUXIO_POST:
      return {
        ...state,
        loadingDeleteFluxioPost: false,
        errorDeleteFluxioPost: action.error,
        successDeleteFluxioPost: undefined,
      }
    case REQUEST_DELETE_FLUXIO_POST:
      return {
        ...state,
        loadingDeleteFluxioPost: true,
        errorDeleteFluxioPost: false,
        successDeleteFluxioPost: undefined,
      }
    case SUCCESS_DELETE_FLUXIO_POST:
      return {
        ...state,
        loadingDeleteFluxioPost: false,
        errorDeleteFluxioPost: false,
        successDeleteFluxioPost: action.response,
      }
    case CLEAN_DELETE_FLUXIO_POST:
      return {
        ...state,
        loadingDeleteFluxioPost: false,
        errorDeleteFluxioPost: false,
        successDeleteFluxioPost: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_IS_PAGE_PUBLISHED_STATUS:
      return {
        ...state,
        loadingFetchIsPagePublished: false,
        errorFetchIsPagePublished: action.error,
        successFetchIsPagePublished: undefined,
      }
    case REQUEST_IS_PAGE_PUBLISHED_STATUS:
      return {
        ...state,
        loadingFetchIsPagePublished: true,
        errorFetchIsPagePublished: false,
        successFetchIsPagePublished: undefined,
      }
    case SUCCESS_FETCH_IS_PAGE_PUBLISHED_STATUS:
      return {
        ...state,
        loadingFetchIsPagePublished: false,
        errorFetchIsPagePublished: false,
        successFetchIsPagePublished: action.response,
      }
    case CLEAR_FETCH_IS_PAGE_PUBLISHED_STATUS:
      return {
        ...state,
        loadingFetchIsPagePublished: false,
        errorFetchIsPagePublished: false,
        successFetchIsPagePublished: undefined,
      }
    case UPDATE_SINGLE_OBJECT_PAGE_TREE:
      return {
        ...state,
        updateSingleObjectPageTree: action.singleObject,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_UPLOADED_FONTS:
      return {
        ...state,
        loadingUploadedFonts: false,
        errorFetchUploadedFonts: action.error,
        successFetchUploadedFonts: undefined,
      }
    case REQUEST_UPLOADED_FONTS:
      return {
        ...state,
        loadingUploadedFonts: true,
        errorFetchUploadedFonts: false,
        successFetchUploadedFonts: undefined,
      }
    case SUCCESS_FETCH_UPLOADED_FONTS:
      return {
        ...state,
        loadingUploadedFonts: false,
        errorFetchUploadedFonts: false,
        successFetchUploadedFonts: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_UPLOADED_FONTS_EM:
      return {
        ...state,
        loadingUploadedFontsEM: false,
        errorFetchUploadedFontsEM: action.error,
        successFetchUploadedFontsEM: undefined,
      }
    case REQUEST_UPLOADED_FONTS_EM:
      return {
        ...state,
        loadingUploadedFontsEM: true,
        errorFetchUploadedFontsEM: false,
        successFetchUploadedFontsEM: undefined,
      }
    case SUCCESS_FETCH_UPLOADED_FONTS_EM:
      return {
        ...state,
        loadingUploadedFontsEM: false,
        errorFetchUploadedFontsEM: false,
        successFetchUploadedFontsEM: action.response,
        fetchUploadedFontsFiltered: customFontsListForFilter(action.response.data),
        fetchUploadedFontsSelectList: action.response.data.map((font: any) => {
          return { value: font.fontFamily, label: font.fontFamily, ...font }
        }),
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_DELETE_FONT:
      return {
        ...state,
        loadingDeleteFont: false,
        errorDeleteFont: action.error,
        successDeleteFont: undefined,
      }
    case REQUEST_DELETE_FONT:
      return {
        ...state,
        loadingDeleteFont: true,
        errorDeleteFont: false,
        successDeleteFont: undefined,
      }
    case SUCCESS_DELETE_FONT:
      return {
        ...state,
        loadingDeleteFont: false,
        errorDeleteFont: false,
        successDeleteFont: action.response,
      }
    case CLEAR_DELETE_FONT:
      return {
        ...state,
        loadingDeleteFont: false,
        errorDeleteFont: false,
        successDeleteFont: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_ADD_DATASET_TO_OBJECT:
      return {
        ...state,
        loadingAddDataSetToObject: false,
        errorAddDataSetToObject: action.error,
        successAddDataSetToObject: undefined,
      }
    case REQUEST_ADD_DATASET_TO_OBJECT:
      return {
        ...state,
        loadingAddDataSetToObject: true,
        errorAddDataSetToObject: false,
        successAddDataSetToObject: undefined,
      }
    case SUCCESS_ADD_DATASET_TO_OBJECT:
      return {
        ...state,
        loadingAddDataSetToObject: false,
        errorAddDataSetToObject: false,
        successAddDataSetToObject: action.response,
      }
    case CLEAR_ADD_DATASET_TO_OBJECT:
      return {
        ...state,
        loadingAddDataSetToObject: false,
        errorAddDataSetToObject: false,
        successAddDataSetToObject: undefined,
      }
    case UPDATE_STYLES_COLLAPSE:
      return {
        ...state,
        arrangement_clp: action.arrangement_clp,
        content_clp: action.content_clp,
        style_clp: action.style_clp,
      }
    case CHANGE_EXPERIENCE_MANAGER_TYPE:
      return {
        ...state,
        isContentPage: action.isContentPage,
      }
    case LOG_FINDER_OPEN:
      return {
        ...state,
        logFinderOpen: action.finderPreviewObject,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_PAGE_LINKS:
      return {
        ...state,
        loadingPageLinks: false,
        errorFetchPageLinks: action.error,
      }
    case REQUEST_FETCH_PAGE_LINKS:
      return {
        ...state,
        loadingPageLinks: true,
        errorFetchPageLinks: false,
      }
    case SUCCESS_FETCH_PAGE_LINKS:
      return {
        ...state,
        loadingPageLinks: false,
        errorFetchPageLinks: false,
        successFetchPageLinks: action.response,
        uniqueIdPageLinks: action.uniqueId,
      }
    case CLEAR_FETCH_PAGE_LINKS:
      return {
        ...state,
        loadingPageLinks: false,
        errorFetchPageLinks: false,
        successFetchPageLinks: undefined,
        uniqueIdPageLinks: "",
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_MAPPED_FIELDS:
      return {
        ...state,
        loadingMappedFields: false,
        errorFetchingMappedFields: action.error,
        successFetchMappedFields: undefined,
      }
    case REQUEST_FETCH_MAPPED_FIELDS:
      return {
        ...state,
        loadingMappedFields: true,
        errorFetchingMappedFields: false,
        successFetchMappedFields: undefined,
      }
    case SUCCESS_FETCH_MAPPED_FIELDS:
      return {
        ...state,
        loadingMappedFields: false,
        errorFetchingMappedFields: false,
        successFetchMappedFields: action.response,
      }
    case CLEAR_FETCH_MAPPED_FIELDS:
      return {
        ...state,
        loadingMappedFields: false,
        errorFetchingMappedFields: false,
        successFetchMappedFields: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_LINK_MAPPED_FIELDS:
      return {
        ...state,
        loadingLinkMappedFields: false,
        errorFetchingLinkMappedFields: action.error,
        successFetchLinkMappedFields: undefined,
      }
    case REQUEST_FETCH_LINK_MAPPED_FIELDS:
      return {
        ...state,
        loadingLinkMappedFields: true,
        errorFetchingLinkMappedFields: false,
        successFetchLinkMappedFields: undefined,
      }
    case SUCCESS_FETCH_LINK_MAPPED_FIELDS:
      return {
        ...state,
        loadingLinkMappedFields: false,
        errorFetchingLinkMappedFields: false,
        successFetchLinkMappedFields: action.response,
      }
    case CLEAR_FETCH_LINK_MAPPED_FIELDS:
      return {
        ...state,
        loadingLinkMappedFields: false,
        errorFetchingLinkMappedFields: false,
        successFetchLinkMappedFields: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS:
      return {
        ...state,
        loadingExtraMappedFields: false,
        errorFetchExtraMappedFields: action.error,
        successFetchExtraMappedFields: undefined,
      }
    case REQUEST_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS:
      return {
        ...state,
        loadingExtraMappedFields: true,
        errorFetchExtraMappedFields: false,
        successFetchExtraMappedFields: undefined,
      }
    case SUCCESS_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS:
      return {
        ...state,
        loadingExtraMappedFields: false,
        errorFetchExtraMappedFields: false,
        successFetchExtraMappedFields: action.response,
      }
    case CLEAR_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS:
      return {
        ...state,
        loadingExtraMappedFields: false,
        errorFetchExtraMappedFields: false,
        successFetchExtraMappedFields: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_SET_OBJECT_MAPPED_FIELD:
      return {
        ...state,
        loadingSetObjectMappedField: false,
        errorSetObjectMappedField: action.error,
        successSetObjectMappedField: undefined,
      }
    case REQUEST_SET_OBJECT_MAPPED_FIELD:
      return {
        ...state,
        loadingSetObjectMappedField: true,
        errorSetObjectMappedField: false,
        successSetObjectMappedField: undefined,
      }
    case SUCCESS_SET_OBJECT_MAPPED_FIELD:
      return {
        ...state,
        loadingSetObjectMappedField: false,
        errorSetObjectMappedField: false,
        successSetObjectMappedField: action.response,
      }
    case CLEAR_SET_OBJECT_MAPPED_FIELD:
      return {
        ...state,
        loadingSetObjectMappedField: false,
        errorSetObjectMappedField: false,
        successSetObjectMappedField: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case ERROR_UNSET_OBJECT_MAPPED_FIELD:
      return {
        ...state,
        loadingUnSetObjectMappedField: false,
        errorUnSetObjectMappedField: action.error,
        successUnSetObjectMappedField: undefined,
      }
    case REQUEST_UNSET_OBJECT_MAPPED_FIELD:
      return {
        ...state,
        loadingUnSetObjectMappedField: true,
        errorUnSetObjectMappedField: false,
        successUnSetObjectMappedField: undefined,
      }
    case SUCCESS_UNSET_OBJECT_MAPPED_FIELD:
      return {
        ...state,
        loadingUnSetObjectMappedField: false,
        errorUnSetObjectMappedField: false,
        successUnSetObjectMappedField: action.response,
      }
    case CLEAR_UNSET_OBJECT_MAPPED_FIELD:
      return {
        ...state,
        loadingUnSetObjectMappedField: false,
        errorUnSetObjectMappedField: false,
        successUnSetObjectMappedField: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case SET_EXPERIENCE_MANAGER_EDITOR_TYPE:
      return {
        ...state,
        emEditorType: action.emEditorType,
      }
    default:
      return state
  }
}

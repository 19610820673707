import { ResponsiveData } from "../bobControllerTypes"


export function stateWrite(textControllerObject: TextContentState) {
  return {
    mappedValue: textControllerObject.mappedValue.value,
    contentSrc: textControllerObject.contentSrc.value,
  }
}

export const CMS = "post"
export const WRITE = "custom"
export const CONTENT_PAGE = "content-page"
export const FORM = "form"

export const mvPage = [
  { label: "Insert", value: WRITE },
  { label: "CMS", value: CMS },
]

export const mvContentPage = [
  { label: "Insert", value: WRITE },
  { label: "CMS", value: CMS },
  { label: "Content Page", value: CONTENT_PAGE },
]

interface TextContentProps {
  mappedValue: ResponsiveData<string>
  contentSrc: ResponsiveData<string>
}

type TextContentState = {
  mappedValue: ResponsiveData<string>
  contentSrc: ResponsiveData<string>
}

export const TEXTMAPPEDVALUES = [
  { label: "Title", value: "$.title" },
  { label: "Description", value: "$.description" },
  { label: "Description Preview", value: "$.description_preview" },
  { label: "Message", value: "$.message" },
  { label: "Object Label", value: "$.objectLabel" },
  { label: "Formula Label", value: "$.label" },
  { label: "Social Network", value: "$.network" },
  { label: "Entity Name", value: "$['contacts.from'].name_full" },
  { label: "Created Time", value: "$['created.unix']" },
  { label: "Entity Description", value: "$['contacts.from'].description" },
]
export type { TextContentProps, TextContentState }
import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function libraryReducer(state: any = initialState.libraryReducer, action: any) {
  switch (action.type) {
    case types.FILTER_BY_TYPE_GS:
      return {
        ...state,
        filterTypeGs: action.filterType,
      }
    case types.FILTER_BY_TYPE_FOUNDATION:
      return {
        ...state,
        filterTypeFoundation: action.filterType,
      }
    default:
      return state
  }
}
import { RouteComponentProps } from "react-router"
import { JobsPage, RouteParams } from "./jobsManager"
import { JobRunId } from "./jobsSchema"

export class JobsNavigation {
  profileName: string
  defaultPage: JobsPage
  constructor(profileName: string, defaultPage: JobsPage) {
    this.profileName = profileName
    this.defaultPage = defaultPage
  }

  path(page: JobsPage): string {
    return `/d/${this.profileName}/dojob/${page}`
  }
  pathWithSearch(page: JobsPage, search: string): string {
    return `/d/${this.profileName}/dojob/${page}?search=${search}`
  }
  pathWithId(runId: JobRunId): string {
    return `/d/${this.profileName}/dojob/runs/${runId}`
  }

  shouldRedirect(params: RouteComponentProps<RouteParams>): boolean {
    return !params.match.params.page
  }

  redirect(params: RouteComponentProps<RouteParams>, to: JobsPage): void {
    return params.history.push(this.path(to))
  }

  redirectSearch(params: RouteComponentProps<RouteParams>, to: JobsPage, search: string): void {
    return params.history.push(this.pathWithSearch(to, search))
  }

  redirectDefault(params: RouteComponentProps<RouteParams>): void {
    this.redirect(params, this.defaultPage)
  }
}

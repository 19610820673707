import React from "react"
import { Link } from "react-router-dom"
import { AppNavigation } from "../skynet/sidebar/DoJobBoard"

export const AppNotAllowed: React.FC<{ app: string }> = (props) => {
  return (
    <div className='page-container'>
      <div className='alert alert-light w-75 ml-auto mr-auto mt-5' role='alert'>
        <div className='alert-group small p-4'>
          <i className='alert-graphic fas fa-info-circle'></i>
          <div className='alert-message'>
            <div className='alert-description'>
              <p>
                You don't have access to the {props.app} app. To request access contact us at{" "}
                <a href='mailto:success@fluxio.net'>success@fluxio.net</a>.{" "}
                <Link to={AppNavigation.userDashboard}>Go back.</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

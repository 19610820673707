import { Fragment } from "react"
import { Link } from "react-router-dom"
import DoJobNegativeSVG from "./dojoblognegative.svg"
import JobsManager from "../jobs/jobsManager"
import { User, Workspace } from "../profileTypes"
import { ApplicationState } from "../../../store/ApplicationState"
import { bindActionCreators } from "redux"
import * as sessionActions from "../../../store/actions/sessionActions"
import { useDispatch, useSelector } from "react-redux"
import ErrorBoundary from "../../../components/shared-components/error/ErrorBoundary"
import { JobsApi } from "../jobs/jobsApi"
import LetterPlaceholder from "../../../modules/letterPlaceholder/letterPlaceholder"
import { Access } from "../../authentication/Access"
import { AppNotAllowed } from "../../authentication/AppNotAllowed"
import ToggleApp from "./ToggleApp"
import * as dashboardTypes from "./dashboardTypes"
import { match } from "ts-pattern"
import { Promises } from "../../../lib/promises"
import { useLoader } from "../../../lib/hooks/useLoader"
import { isErr, isOk } from "../../../lib/monads/result/result"
import { DocumentView, ViewId } from "../jobs/jobsSchema"

/*function renderNavbarMenus(profileName: ProfileName): JSX.Element {
  return (
    <Fragment>
      <li className='nav-item dropdown'>
       
        <Link to={`/t/${profileName}/projects`} className='nav-link '>
          Fluxio Projects
        </Link>
      </li>
    </Fragment>
  )
}*/

function renderUserInfoShort(userInfo: User): JSX.Element {
  return <Fragment>{userAvatarLetter(userInfo)}</Fragment>
}

function renderUserInfoFull(userInfo: User): JSX.Element {
  return (
    <Fragment>
      <span
        style={{ display: "flex", flexDirection: "row", alignItems: "top", columnGap: "5px" }}
        className='dropdown-item'
      >
        {userAvatarLetter(userInfo)}

        <div>
          {userInfo.name}
          <br />
          <span style={{ fontSize: "12px" }}>{userInfo.email} </span>
        </div>
      </span>
    </Fragment>
  )
}

function dropdownItem(icon: JSX.Element, label: string): JSX.Element {
  return (
    <Fragment>
      <button className='nav-link' id='navbarDropdown' role='menu' data-toggle='dropdown'>
        <div className='dropdown-item' title={"Workspace Name"} data-for='pipeline-action-tooltip' data-place='bottom'>
          {icon}
          {label}
        </div>
      </button>
    </Fragment>
  )
}

function userAvatarLetter(userInfo: User): JSX.Element {
  return (
    <LetterPlaceholder
      className='fluxio-list-group-image'
      squareWidth='30px'
      squareHeight='30px'
      fontColor='#fff'
      fontSize='14px'
      radius='50%'
      numberOfLetters={2}
      phrase={userInfo.name}
      backgroundColor='#4253fc'
    />
  )
}

function DoJobNav(props: {
  profile: Workspace
  userInfo: User
  logout: () => void
  tempViews: DocumentView[] | undefined
}): JSX.Element {
  return (
    <nav className='navbar navbar-expand-lg navbar-dark bg-dark skynet-navbar '>
      <ToggleApp profile={props.profile}></ToggleApp>

      <Link to={AppNavigation.userDashboard}>
        {<img className='fluxio-logo navbar-brand' src={DoJobNegativeSVG} alt='dojob logo' />}
      </Link>

      <li className='nav-item dropdown' style={{ borderRight: "none" }}>
        {/* Document (Job Runs)*/}
        <Link to={AppNavigation.documentRuns(props.profile.name)} className='nav-link'>
          Documents
        </Link>
      </li>
      {props.tempViews && (
        <li className='nav-item dropdown' style={{ borderRight: "none" }}>
          <button className='nav-link dropdown-toggle' id='navbarDropdown' role='menu' data-toggle='dropdown'>
            Departments*
          </button>
          <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
            {props.tempViews &&
              props.tempViews.map((v) => (
                <Link key={v.id} to={AppNavigation.documentView(props.profile.name, v.id)} className='dropdown-item'>
                  {v.name}
                </Link>
              ))}
          </div>
        </li>
      )}

      <div className='collapse navbar-collapse' id='navbarSupportedContent'>
        <div className='my-lg-0 mr-auto'></div>
        <div className='my-lg-0'>
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item dropdown'>
              <button className='nav-link dropdown-toggle' id='navbarDropdown' role='menu' data-toggle='dropdown'>
                {props.userInfo && renderUserInfoShort(props.userInfo)}
              </button>
              <div
                className='dropdown-menu dropdown-menu-right'
                style={{ padding: "5px", maxHeight: "calc(100vh - 200px)" }}
                aria-labelledby='navbarDropdown'
              >
                <div style={{ fontSize: "11px", color: "#5E6C84" }}>WORKSPACE</div>

                {dropdownItem(<i className='fas fa-check'></i>, props.profile.label)}
                <div className='dropdown-divider'></div>
                <div style={{ fontSize: "11px", color: "#5E6C84" }}>ACCOUNT</div>
                {props.userInfo && renderUserInfoFull(props.userInfo)}

                <div className='dropdown-divider'></div>
                <Link to={AppNavigation.userDashboard} className='dropdown-item'>
                  <i className='fas fa-laptop-house'></i>
                  Other Workspaces{" "}
                </Link>
                <div className='dropdown-divider'></div>
                <span onClick={() => props.logout()} className='dropdown-item'>
                  <i className='fas fa-sign-out-alt'></i>
                  Logout
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

// all links within the app
export class AppNavigation {
  static login = "/login"
  static userDashboard = "/t"

  static profileProjects = (profileName: string) => `/t/${profileName}/${dashboardTypes.PROJECTS}`
  static profileDoJob = (profileName: string) => `/d/${profileName}/dojob`
  static documentView = (profileName: string, viewId: ViewId) => `/d/${profileName}/dojob/document/${viewId}`
  static documentRuns = (profileName: string) => `/d/${profileName}/dojob/documentRun/`
  static conditionalAppLink(p: Workspace): string {
    const access = Access.access(p)
    if (p.name === "goldenwm" && access.doJob === true) return AppNavigation.profileDoJob(p.name)
    return match(access)
      .with({ fluxio: true }, () => AppNavigation.profileProjects(p.name))
      .with({ fluxio: false, doJob: true }, () => AppNavigation.profileDoJob(p.name))
      .with({ fluxio: false, doJob: false }, () => AppNavigation.userDashboard)
      .exhaustive()
  }
}

// TODO: testar logout
// TODO: no any
export function DoJobBoard(props: { match: any; profile: Workspace; logout: () => void; token: any }): JSX.Element {
  const userInfo: User | undefined = useSelector((state: ApplicationState) => state.session.userInfo)
  const loadingUserInfo = useSelector((state: ApplicationState) => state.session.loadingUserInfo)
  const jobsClient = new JobsApi(props.profile.name, props.token.access_token)

  /*const olisipoViews: DocumentView[] = [
    { id: mkViewId("marketing"), name: "marketing" },
    { id: mkViewId("innovation"), name: "innovation" },
  ]*/
  const olisipoDocs = useLoader(
    () => (props.profile.name === "olisipo" ? jobsClient.listViews() : Promises.pure(undefined)),
    [props.profile]
  )

  const viewsList =
    olisipoDocs && !isErr(olisipoDocs) && isOk(olisipoDocs) && olisipoDocs.value() !== undefined
      ? olisipoDocs.value()?.views
      : undefined

  // Access the dispatch function to dispatch actions
  const dispatch = useDispatch()

  // Bind your actions to dispatch
  const actions = bindActionCreators({ ...sessionActions }, dispatch)

  if (!userInfo && !loadingUserInfo) {
    actions.fetchUserInfo(props.token)
  }

  if (!userInfo) return <></>

  if (!Access.canAccessDoJob(props.profile)) {
    return <AppNotAllowed app={"DoJob"} />
  }

  return (
    <Fragment>
      <DoJobNav profile={props.profile} userInfo={userInfo} logout={props.logout} tempViews={viewsList} />
      <div className='main-em'>
        <div className='small-screen-warning'>
          <h1>Your browser is to small to use this web app!</h1>
          <p>Our app has the best experience when using screens bigger than 1000px</p>
        </div>
        <div className='work'>
          <ErrorBoundary>
            <JobsManager jobsClient={jobsClient} />
          </ErrorBoundary>
        </div>
      </div>
    </Fragment>
  )
}

export default DoJobBoard

import * as types from "../shared-store/actions/actionTypes"
import initialState from "../initialState"
import cloneDeep from "lodash/cloneDeep"

export default function foundationStyleReducer(
  state = initialState.foundationStyleReducer,
  action
) {
  switch (action.type) {
    // view state
    case types.FOUNDATION_STYLE_VIEW:
      return {
        ...state,
        foundationStyleState: action.state,
        foundationStyleType: action.fsType,
        foundationStyleIdInUse: action.gsId,
      }
    // list
    case types.CLEAR_FETCH_FOUNDATION_STYLE_LIST:
      return {
        ...state,
        [`errorFetchFoundationStyleList-${action.fsType}`]: false,
        [`loadingFetchFoundationStyleList-${action.fsType}`]: false,
        [`successFetchFoundationStyleList-${action.fsType}`]: undefined,
      }
    case types.ERROR_FETCH_FOUNDATION_STYLE_LIST:
      return {
        ...state,
        [`errorFetchFoundationStyleList-${action.fsType}`]: false,
        [`loadingFetchFoundationStyleList-${action.fsType}`]: undefined,
        [`successFetchFoundationStyleList-${action.fsType}`]: undefined,
      }
    case types.REQUEST_FETCH_FOUNDATION_STYLE_LIST:
      return {
        ...state,
        [`errorFetchFoundationStyleList-${action.fsType}`]: false,
        [`loadingFetchFoundationStyleList-${action.fsType}`]: true,
      }
    case types.SUCCESS_FETCH_FOUNDATION_STYLE_LIST:
      if (action.shouldAdd) {
        let clonedState =
          state[`successFetchFoundationStyleList-${action.fsType}`]
        const newResponse = {
          ...clonedState,
          ...action.response,
          data: [...clonedState.data, ...action.response.data],
        }
        return {
          ...state,
          [`errorFetchFoundationStyleList-${action.fsType}`]: false,
          [`loadingFetchFoundationStyleList-${action.fsType}`]: false,
          [`successFetchFoundationStyleList-${action.fsType}`]: newResponse,
        }
      }
      return {
        ...state,
        [`errorFetchFoundationStyleList-${action.fsType}`]: false,
        [`loadingFetchFoundationStyleList-${action.fsType}`]: false,
        [`successFetchFoundationStyleList-${action.fsType}`]: action.response,
      }
    // update list
    case types.UPDATE_FOUNDATION_STYLE_LIST:
      return {
        ...state,
        [`successFetchFoundationStyleList-${action.fsType}`]: {
          ...state[`successFetchFoundationStyleList-${action.fsType}`],
          data: {
            ...state[`successFetchFoundationStyleList-${action.fsType}`]?.data,
            ...action.foundationStyle,
          },
        },
      }
    // create
    case types.CLEAR_CREATE_FOUNDATION_STYLE:
      return {
        ...state,
        errorCreateFoundationStyle: false,
        loadingCreateFoundationStyle: false,
        successCreateFoundationStyle: undefined,
      }
    case types.ERROR_CREATE_FOUNDATION_STYLE:
      return {
        ...state,
        errorCreateFoundationStyle: action.error,
        loadingCreateFoundationStyle: false,
        successCreateFoundationStyle: undefined,
      }
    case types.REQUEST_CREATE_FOUNDATION_STYLE:
      return {
        ...state,
        errorCreateFoundationStyle: false,
        loadingCreateFoundationStyle: true,
        successCreateFoundationStyle: undefined,
      }
    case types.SUCCESS_CREATE_FOUNDATION_STYLE:
      return {
        ...state,
        errorCreateFoundationStyle: false,
        loadingCreateFoundationStyle: false,
        successCreateFoundationStyle: action.response,
      }
    // update
    case types.CLEAR_UPDATE_FOUNDATION_STYLE_V2:
      return {
        ...state,
        errorUpdateFoundationStyle: false,
        loadingUpdateFoundationStyle: false,
        successUpdateFoundationStyle: undefined,
      }
    case types.ERROR_UPDATE_FOUNDATION_STYLE_V2:
      return {
        ...state,
        errorUpdateFoundationStyle: action.error,
        loadingUpdateFoundationStyle: false,
        successUpdateFoundationStyle: undefined,
      }
    case types.REQUEST_UPDATE_FOUNDATION_STYLE_V2:
      return {
        ...state,
        errorUpdateFoundationStyle: false,
        loadingUpdateFoundationStyle: action.foundationStyle,
        successUpdateFoundationStyle: undefined,
      }
    case types.SUCCESS_UPDATE_FOUNDATION_STYLE_V2:
      return {
        ...state,
        errorUpdateFoundationStyle: false,
        loadingUpdateFoundationStyle: false,
        successUpdateFoundationStyle: action.response,
        handledObjectId: action.handledObjectId,
      }
    // attach
    case types.CLEAR_ATTACH_FOUNDATION_STYLE_V2:
      return {
        ...state,
        errorAttachFoundationStyle: false,
        loadingAttachFoundationStyle: false,
        successAttachFoundationStyle: undefined,
      }
    case types.ERROR_ATTACH_FOUNDATION_STYLE_V2:
      return {
        ...state,
        errorAttachFoundationStyle: action.error,
        loadingAttachFoundationStyle: false,
        successAttachFoundationStyle: undefined,
      }
    case types.REQUEST_ATTACH_FOUNDATION_STYLE_V2:
      return {
        ...state,
        errorAttachFoundationStyle: false,
        loadingAttachFoundationStyle: action.foundationStyle,
        successAttachFoundationStyle: undefined,
      }
    case types.SUCCESS_ATTACH_FOUNDATION_STYLE_V2:
      return {
        ...state,
        errorAttachFoundationStyle: false,
        loadingAttachFoundationStyle: false,
        successAttachFoundationStyle: action.response,
      }
    // detach
    case types.CLEAR_DETACH_FOUNDATION_STYLE_V2:
      return {
        ...state,
        errorDetachFoundationStyle: false,
        loadingDetachFoundationStyle: false,
        successDetachFoundationStyle: undefined,
      }
    case types.ERROR_DETACH_FOUNDATION_STYLE_V2:
      return {
        ...state,
        errorDetachFoundationStyle: action.error,
        loadingDetachFoundationStyle: false,
        successDetachFoundationStyle: undefined,
      }
    case types.REQUEST_DETACH_FOUNDATION_STYLE_V2:
      return {
        ...state,
        errorDetachFoundationStyle: false,
        loadingDetachFoundationStyle: true,
        successDetachFoundationStyle: undefined,
      }
    case types.SUCCESS_DETACH_FOUNDATION_STYLE_V2:
      return {
        ...state,
        errorDetachFoundationStyle: false,
        loadingDetachFoundationStyle: false,
        successDetachFoundationStyle: action.response,
        lastDetachdFoundationStyle: action.response,
      }
    case types.SAVE_ATTACHED_FOUNDATION_STYLE:
      return {
        ...state,
        savedAttachedFoundationStyle: action.gsObj,
      }
    case types.CLEAR_SAVED_ATTACHED_FOUNDATION_STYLE:
      return {
        ...state,
        savedAttachedFoundationStyle: undefined,
      }
    case types.SET_FOUNDATION_STYLE_BEHAVIOUR:
      return {
        ...state,
        foundationStyleBehaviour: action.gsBehaviour,
      }
    case types.EDIT_LIST_SINGLE_FOUNDATION_STYLE:
      let clonedFsList = cloneDeep(
        state[`successFetchFoundationStyleList-${action.fsType}`]
      )
      if (clonedFsList?.data && action.newFsToAdd) {
        // add create fs to list
        if (action.actionType === "create") {
          // add 1 to total
          clonedFsList.total = clonedFsList.total + 1
          // handle last_page
          clonedFsList.last_page = Math.ceil(
            clonedFsList.total / clonedFsList.per_page
          )
          // add new element to data list
          clonedFsList.data = [
            action.newFsToAdd.foundation,
            ...clonedFsList.data,
          ]
        }
        // update updated fs on list
        else if (action.actionType === "update") {
          //get index of element in the array
          const posEl = clonedFsList.data.findIndex(
            (el) => el.uuid === action.newFsToAdd.uuid
          )
          clonedFsList.data[posEl] = action.newFsToAdd
        }
      }
      return {
        ...state,
        [`successFetchFoundationStyleList-${action.fsType}`]: clonedFsList,
      }
    /*** */
    case types.SUCCESS_FETCH_FOUNDATION_STYLE_PAGE_USAGE_LIST:
      const { response: pageResponse } = action
      const statePageResponse = state.foundationStylePageUsageList
      if (statePageResponse) {
        let clonedPageResponse = cloneDeep(statePageResponse)
        clonedPageResponse = {
          ...pageResponse,
          data: [...clonedPageResponse.data, ...pageResponse.data],
        }
        return {
          ...state,
          foundationStylePageUsageList: clonedPageResponse,
          loadingFoundationStylePageUsageList: false,
          errorFetchFoundationStylePageUsageList: false,
        }
      }
      return {
        ...state,
        foundationStylePageUsageList: action.response,
        loadingFoundationStylePageUsageList: false,
        errorFetchFoundationStylePageUsageList: false,
      }
    case types.REQUEST_FOUNDATION_STYLE_PAGE_USAGE_LIST:
      return {
        ...state,
        loadingFoundationStylePageUsageList: action.foundationStyleId,
        errorFetchFoundationStylePageUsageList: false,
      }
    case types.ERROR_FETCH_FOUNDATION_STYLE_PAGE_USAGE_LIST:
      return {
        ...state,
        loadingFoundationStylePageUsageList: false,
        errorFetchFoundationStylePageUsageList: {
          error: action.error,
          foundationStyleId: action.foundationStyleId,
        },
      }
    case types.CLEAN_FOUNDATION_STYLE_PAGE_USAGE_LIST:
      return {
        ...state,
        foundationStylePageUsageList: undefined,
        loadingFoundationStylePageUsageList: false,
        errorFetchFoundationStylePageUsageList: false,
      }
    /*** */
    case types.SUCCESS_FETCH_FOUNDATION_STYLE_SYMBOL_USAGE_LIST:
      const { response: symbolResponse } = action
      const stateSymbolResponse = state.foundationStyleSymbolUsageList
      if (stateSymbolResponse) {
        let clonedSymbolResponse = cloneDeep(stateSymbolResponse)
        clonedSymbolResponse = {
          ...symbolResponse,
          data: [...clonedSymbolResponse.data, ...symbolResponse.data],
        }
        return {
          ...state,
          foundationStyleSymbolUsageList: clonedSymbolResponse,
          loadingFoundationStyleSymbolUsageList: false,
          errorFetchFoundationStyleSymbolUsageList: false,
        }
      }
      return {
        ...state,
        foundationStyleSymbolUsageList: action.response,
        loadingFoundationStyleSymbolUsageList: false,
        errorFetchFoundationStyleSymbolUsageList: false,
      }
    case types.REQUEST_FOUNDATION_STYLE_SYMBOL_USAGE_LIST:
      return {
        ...state,
        loadingFoundationStyleSymbolUsageList: action.foundationStyleId,
        errorFetchFoundationStyleSymbolUsageList: false,
      }
    case types.ERROR_FETCH_FOUNDATION_STYLE_SYMBOL_USAGE_LIST:
      return {
        ...state,
        loadingFoundationStyleSymbolUsageList: false,
        errorFetchFoundationStyleSymbolUsageList: {
          error: action.error,
          foundationStyleId: action.foundationStyleId,
        },
      }
    case types.CLEAN_FOUNDATION_STYLE_SYMBOL_USAGE_LIST:
      return {
        ...state,
        foundationStyleSymbolUsageList: undefined,
        loadingFoundationStyleSymbolUsageList: false,
        errorFetchFoundationStyleSymbolUsageList: false,
      }
    /*** */
    case types.SUCCESS_FETCH_FOUNDATION_STYLE_TEMPLATE_USAGE_LIST:
      const { response: templateResponse } = action
      const stateTemplateResponse = state.foundationStyleTemplateUsageList
      if (stateTemplateResponse) {
        let clonedTemplateResponse = cloneDeep(stateTemplateResponse)
        clonedTemplateResponse = {
          ...templateResponse,
          data: [...clonedTemplateResponse.data, ...templateResponse.data],
        }
        return {
          ...state,
          foundationStyleTemplateUsageList: clonedTemplateResponse,
          loadingFoundationStyleTemplateUsageList: false,
          errorFetchFoundationStyleTemplateUsageList: false,
        }
      }
      return {
        ...state,
        foundationStyleTemplateUsageList: action.response,
        loadingFoundationStyleTemplateUsageList: false,
        errorFetchFoundationStyleTemplateUsageList: false,
      }
    case types.REQUEST_FOUNDATION_STYLE_TEMPLATE_USAGE_LIST:
      return {
        ...state,
        loadingFoundationStyleTemplateUsageList: action.foundationStyleId,
        errorFetchFoundationStyleTemplateUsageList: false,
      }
    case types.ERROR_FETCH_FOUNDATION_STYLE_TEMPLATE_USAGE_LIST:
      return {
        ...state,
        loadingFoundationStyleTemplateUsageList: false,
        errorFetchFoundationStyleTemplateUsageList: {
          error: action.error,
          foundationStyleId: action.foundationStyleId,
        },
      }
    case types.CLEAN_FOUNDATION_STYLE_TEMPLATE_USAGE_LIST:
      return {
        ...state,
        foundationStyleTemplateUsageList: undefined,
        loadingFoundationStyleTemplateUsageList: false,
        errorFetchFoundationStyleTemplateUsageList: false,
      }
    default:
      return state
  }
}

import { ZodType, ZodTypeDef, z } from "zod"

export type Paginated<A> = {
  current_page: number // page number, 1-based
  data: A[]
  last_page: number // page number, 1-based
  per_page: number // number of items per page
  total: number // number of pages
}

export function PaginatedSchema<A, Def extends ZodTypeDef = ZodTypeDef, Input = A>(
  inner: ZodType<A, Def, Input>
): ZodType<Paginated<A>, any, any> {
  // i'm going to regret these 'any'
  return z.object({
    current_page: z.number(),
    data: z.array(inner),
    last_page: z.number(),
    per_page: z.number(),
    total: z.number(),
  })
}

export interface Paging {
  limit: number // number of items per page
  page: number // page number, 1-based
}

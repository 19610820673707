import apiEnv from './apiEnv'

export function getSymbolsList(
  profile: any,
  token: any,
  orderby: string = "created_at",
  limit: number = 10,
  order: string = "asc",
  search?: any,
  page: number = 1
) {
  let url = `${apiEnv()}v1/profiles/${profile}/symbols?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": "meshapp",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function createSymbolInstance(obj: any, profile: any, token: any, eventId: any) {
  let body = {
    parentId: obj.parentId,
    order: obj.order,
    instanceId: obj.instanceId,
  }
  let url = `${apiEnv()}v1/profiles/${profile}/symbols/${obj.symbolId}/instance2?eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": "meshapp",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function validateSetSymbol(obj: any, profile: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profile}/objects/${obj.objectUuid}/validateSymbolTree?eventId=${eventId}`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": "meshapp",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function setSymbol(profile: any, symbolObject: any, instanceObject: any, eventId: any, token: any) {
  let body = {
    objectId: symbolObject.oldObjectUuid,
    symbolId: symbolObject.objectUuid,
    instanceId: instanceObject.objectUuid,
    name: symbolObject.label,
  }
  let url = `${apiEnv()}v1/profiles/${profile}/symbols2/?eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": "meshapp",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function changeSymbolLabel(symbolLabel: any, symbolId: any, profile: any, token: any) {
  let body = {
    name: symbolLabel,
  }
  let url = `${apiEnv()}v1/profiles/${profile}/symbols/${symbolId}`
  const request = new Request(url, {
    method: "PATCH",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": "meshapp",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function unsetSymbol(object: any, profile: any, token: any, eventId: any) {
  const request = new Request(
    `${apiEnv()}v1/profiles/${profile}/objects/${object.objectUuid}/symbols2?eventId=${eventId}`,
    {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
    }
  )
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function deleteSymbol(symbol: any, profile: any, token: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/symbols/${symbol.uuid}`, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": "meshapp",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function updateInstanceContent(profileId: any, instance: any, object: any, eventId: any, token: any) {
  let obj = {
    inlineContent: instance.content.overrides[object.uuid]?.inlineContent,
    formulaId: instance.content.overrides[object.uuid]?.formula?.uuid,
    isContentBlock: instance.content.overrides[object.uuid]?.isContentBlock,
  }
  let url = `${apiEnv()}v1/profiles/${profileId}/instances/${instance.uuid}/content/${object.uuid}?eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function deleteInstanceContent(profileId: any, instance: any, object: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/instances/${instance.uuid}/content/${object.uuid}?eventId=${eventId}`
  const request = new Request(url, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function symbolScreenshot(symbolId: any, profile: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profile}/symbols/${symbolId}/preview`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

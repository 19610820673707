export const defaultSelect = {
  // control container
  control: (provided, state) => {
    return {
      ...provided,
      border: state.isFocused ? 'none' : 'none',
      boxShadow: state.isFocused ? 'none' : 'none',
      minHeight: '20px',
    }
  },
  // input container
  valueContainer: provided => {
    return {
      ...provided,
      padding: '0',
    }
  },
  // input
  input: base => ({
    ...base,
    margin: '0',
    padding: '0',
  }),
  // input placeholder
  placeholder: (provided, state) => {
    return {
      ...provided,
      margin: '0',
      color: '#27272d',
      fontWeight: '600',
      fontSize: '12'
    }
  },
  // input dropdown indicator
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      padding: '0',
      color: state.isFocused ? '#4253FC' : '#4253FC',
      '&:hover': {
        color: '#4253FC'
      }
    }
  },
  // input clear indicator
  clearIndicator: (provided, state) => {
    return {
      ...provided,
      padding: '0'
    }
  },
  // input placeholer/indicator separator 
  indicatorSeparator: provided => {
    return {
      ...provided,
      display: 'none',
    }
  },
  // input selected multivalue 
  multiValue: provided => {
    return {
      ...provided,
      backgroundColor: '#4253FC',
      color: '#ffffff',
      margin: '0',
      marginRight: '3px'
    }
  },
  // input selected multivalue label
  multiValueLabel: provided => {
    return {
      ...provided,
      color: '#ffffff'
    }
  },
  // dropdown menu
  menu: provided => {
    return {
      ...provided,
      borderRadius: '0',
      boxShadow: '0 4px 11px hsla(0,0%,0%,0.1)',
      border: '1px solid #e9ebef',
      borderTop: 'none'
    }
  },
  // dropdown menu list
  menuList: provided => {
    return {
      ...provided,
      borderTop: 'none'
    }
  },
  // dropdown menu list option
  option: (provided, state) => {
    return {
      ...provided,
      padding: '5px 12px',
      background: state.isFocused && 'transparent',
      boxShadow: state.isFocused && '0px 7px 0px -5px #4253FC',
      color: '#27272d',
      fontWeight: '600',
      fontSize: '12'
    }
  }
}
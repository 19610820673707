export const PORTUGUESE_TRANSLATION = {
  lang: 'pt',
  messages: {
    'session.logIn': 'Entrar',
    'session.logOut': 'Terminar Sessão',
    'session.language': 'Lingua',
    'app.translatedMessage': 'Português',
    'app.loginWithoutCredentials': 'Proceder sem login',
  }
};

import React from 'react'
import { valueExists } from "../../../modules/shared-modules/utilities/utils"

let WAIT_INTERVAL
const ENTER_KEY = 13

class InputHandler extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value ? props.value : "",
      readOnly: this.props.enableDoubleClick ? true : false,
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleInputReadOnly = this.handleInputReadOnly.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.timer = null
    if (this.props.delay) {
      WAIT_INTERVAL = this.props.delay
    }

    this.props.enableDoubleClick && document.addEventListener("mousedown", this.handleInputReadOnly, false)
  }

  componentWillUnmount() {
    this.props.enableDoubleClick && document.removeEventListener("mousedown", this.handleInputReadOnly, false)
    this.handleActions("clearTimeout")
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // && (this.state.value === '' && nextProps.value !== 0) may be necessary, input was cleared and prop value(same as api) is 0
    if (this.state.value !== nextProps.value) {
      this.setState({
        ...this.state,
        value: valueExists(nextProps.value) ? nextProps.value : "",
      })
    }
  }

  handlerConverter(value) {
    if (!this.props.converter) return this.props.inputType === "number" && (value === "" || !value) ? 0 : value
    if (this.props.converter === "parseInt") return isNaN(parseInt(value)) ? 0 : parseInt(value)
    if (this.props.converter === "parseFloat") return isNaN(parseFloat(value)) ? 0 : parseFloat(value)
  }

  handleOnChange(e) {
    this.handleActions("clearTimeout")
    this.setState(
      {
        ...this.state,
        value: e.target.value,
      },
      () => {
        this.handleActions("callbackDelay")
      }
    )
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.handleActions("clearTimeout")
      this.handleActions("callback")
      // make input read only
      if (!this.state.readOnly)
        this.setState({
          readOnly: !this.state.readOnly,
        })
    }
  }

  handleActions(type) {
    switch (type) {
      case "clearTimeout":
        if (this.props.delay) {
          clearTimeout(this.timer)
        }
        break
      case "callback":
        this.props.handleValueChange && this.props.handleValueChange(this.handlerConverter(this.state.value))
        break
      case "callbackDelay":
        if (this.props.delay) {
          this.timer = setTimeout(
            () => this.props.handleValueChange && this.props.handleValueChange(this.handlerConverter(this.state.value)),
            WAIT_INTERVAL
          )
          break
        }
        this.props.handleValueChange && this.props.handleValueChange(this.handlerConverter(this.state.value))
        break
      default:
        return console.warn("No action do Exeggutor")
    }
  }

  handleInputReadOnly = (e) => {
    if (this.objRef && this.objRef.contains(e.target)) return

    this.setState({
      ...this.state,
      readOnly: true,
    })
  }

  handleDoubleClick(e) {
    if (this.state.readOnly)
      this.setState({
        readOnly: !this.state.readOnly,
      })
  }

  handleButtonPress() {
    if (this.props.enableDoubleClick) this.buttonPressTimer = setTimeout(() => this.handleDoubleClick(), 1000)
  }
  handleButtonRelease() {
    if (this.props.enableDoubleClick) clearTimeout(this.buttonPressTimer)
  }

  render() {
    return (
      <input
        onDoubleClick={(e) => this.handleDoubleClick(e)}
        onTouchStart={() => this.handleButtonPress()}
        onTouchEnd={() => this.handleButtonRelease()}
        ref={(node) => {
          this.objRef = node
        }}
        type={this.props.inputType}
        value={this.state.value}
        name={this.props.inputName}
        className={`${this.props.inputClassName} ${
          this.props.enableDoubleClick && (this.state.readOnly ? "input-disabled" : "input-enabled")
        }`}
        onChange={this.handleOnChange}
        onKeyDown={this.handleKeyDown}
        onKeyPress={this.props.onKeyPress}
        onClick={this.props.onClick}
        placeholder={this.props.placeholder}
        id={this.props.inputId}
        style={this.props.inputStyle}
        disabled={this.props.disabled}
        readOnly={this.props.enableDoubleClick && this.state.readOnly}
        autoComplete={this.props.autocomplete}
        onBlur={() => {
          if (!this.props.delay && !this.props.onBlur) {
            this.handleActions("callback")
          } else if (this.props.delay && !this.props.onBlur) {
            /**
             * We are waiting for the delay to write the value,
             * but the user is no longer with the focus on this input,
             * so we send the value and clear the timeout
             */
            if (this.state.value !== this.props.value) {
              this.handleActions("clearTimeout")
              this.handleActions("callback")
            }
          } else if (this.props.onBlur) {
            if (this.props.value !== this.state.value) this.props.onBlur(this.state.value)
          }
        }}
      />
    )
  }
}

export default InputHandler
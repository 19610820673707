import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function formulalabReducer(state = initialState.formulalabReducer, action) {
  switch (action.type) {
    case types.ADD_QUERY_GROUP:
      let counter = 0
      let positionToEdit = false
      for (let group of state.queryGroupsArr) {
        if (group.id === action.group.id) {
          positionToEdit = counter
          break
        }
        counter++
      }
      if (positionToEdit !== false) {
        let arrToEdit = [...state.queryGroupsArr]
        arrToEdit[positionToEdit] = action.group
        return {
          ...state,
          queryGroupsArr: [...arrToEdit]
        }
      }
      return {
        ...state,
        queryGroupsArr: [...state.queryGroupsArr, action.group]
      }
    case types.ADD_BULK_QUERY_GROUP:
      return {
        ...state,
        queryGroupsArr: action.groups ? [...action.groups] : []
      }
    case types.REMOVE_QUERY_GROUP:
      let arrWithoutGroup = state.queryGroupsArr.filter(group => group.id !== action.group.id)
      return {
        ...state,
        queryGroupsArr: [...arrWithoutGroup]
      }
    case types.CLEAN_QUERY_GROUP:
      return {
        ...state,
        queryGroupsArr: [action.cleanGroup]
      }
    case types.SELECTED_REDUX_GROUP:
      return {
        ...state,
        selectedReduxGroup: action.reduxGroup,
        selectedGroupType: action.groupType,
        selectedParentReduxGroup: action.parentReduxGroup,
      }
    case types.COLLAPSE_STATE:
      return {
        ...state,
        collapseValue: action.value,
      }
    default:
      return state
  }
}
// import { createStore, applyMiddleware, compose, Store } from "redux"
// import rootReducer from "./reducers/rootReducer"
// import { createLogger } from "redux-logger"
// import thunk from "redux-thunk"
// import { ApplicationState } from "./ApplicationState"

// const middleware = applyMiddleware(
//   thunk,
//   createLogger({
//     predicate: (getState: any, action: any) => process.env.NODE_ENV === `development` && false,
//   })
// )

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// const composeEnhancersMiddleware = composeEnhancers(middleware)

// export default function configureStore() {
//   return createStore(rootReducer, composeEnhancersMiddleware)
// }
import { createStore, applyMiddleware, compose, Store } from "redux"
import rootReducer from "./reducers/rootReducer"
import { createLogger } from "redux-logger"
import thunk from "redux-thunk"
import { ApplicationState } from "./ApplicationState"

const middleware = applyMiddleware(
  thunk,
  createLogger({
    predicate: (getState: () => ApplicationState, action: any) => process.env.NODE_ENV === "development" && false,
  })
)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store: Store<ApplicationState> = createStore(rootReducer, composeEnhancers(middleware))

export default function configureStore() {
  return store
}

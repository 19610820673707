import { FluxioPostId } from "../../areas/skynet/jobs/jobsSchema"
import { Json } from "../jsonRequest/JsonRequest"
import { InjectKey } from "./sdftypes"

export type ProvideInjectValues = Partial<Record<InjectKey, Json>>

export function mkProvideInjectValues(profileName: string, fluxioPostId?: FluxioPostId): ProvideInjectValues {
  return {
    profileName: profileName,
    fluxioPostId: fluxioPostId,
  }
}

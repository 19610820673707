export const main = {
  label: null,
  parentId: null,
  objectLabel: "New Object",
  objectFormula: null,
  uri: null,
  uuid: "",
  objectUuid: null,
  picture: null,
  picture_cover: null,
  picture_icon: null,
  type: "object",
  uniqueUuid: null,
  content: [],
  order: 0,
  hasMore: null,
  totalItems: null,
  nextCursor: null,
  createNow: false,
  insertEM: true,
  parentObjectUuid: null,
  firstObjectUuid: "",
  changedOrder: false,
  mql: null,
  description: null,
  styles: {
    uuid: null,
    component: {
      name: "grid_container",
      uuid: "f9d3a6a1-aea6-4455-9022-c6e5342e8126",
      preview: "https://meshapp-cdn.s3-eu-west-1.amazonaws.com/fluxio/components/grid_container.png",
      label: "Grid Container",
      bob_map: ["code1", "background1"],
      bob: {
        code1: {
          controls: true,
          type: "bobCode",
        },
        background1: {
          shadow: true,
          name: "container",
          border: true,
          label: "Main Background",
          scrollBehaviour: true,
          radius: true,
          linkMappedValues: ["custom", "page", "anchor", "$.originUrl", '$["contacts.from"].link'],
          grid: true,
          filter: true,
          wrap: true,
          alignment: true,
          mappedValues: ["imageSrc"],
          lean: true,
          type: "bobBackground",
          spacing: true,
          controls: true,
        },
      },
      content: {
        formulas: false,
        posts: false,
        embedCode: false,
        embed: false,
      },
    },
    bobs: {
      background1: {
        enable: true,
        shadow: {
          enable: false,
          spread: 0,
          offsetV: 0,
          color: "rgba(0, 0, 0, 0.5)",
          offsetH: 0,
          inset: false,
          blur: 0,
        },
        scroll: {
          enable: false,
          value: "static",
        },
        size: "cover",
        border: {
          enable: false,
          bottomStyle: "solid",
          leftWidth: 1,
          topColor: "#ffffff",
          rightColor: "#ffffff",
          rightEnable: false,
          topWidth: 1,
          bottomEnable: false,
          leftStyle: "solid",
          leftEnable: false,
          bottomColor: "#ffffff",
          rightWidth: 1,
          topEnable: false,
          rightStyle: "solid",
          bottomWidth: 1,
          leftColor: "#ffffff",
          topStyle: "solid",
        },
        image: {
          enable: false,
          size: "cover",
          mappedValue: "imageSrc",
          src: "",
          position: "center center",
          repeat: "no-repeat",
        },
        height: {
          value: 100,
          enable: false,
          unit: "auto",
        },
        radius: {
          enable: false,
          bottomRight: 0,
          topRight: 0,
          bottomLeft: 0,
          topLeft: 0,
        },
        grid: {
          rows: 1,
          enable: true,
          columns: ["12"],
        },
        filter: {
          enable: false,
          shadow: 0,
          sepia: 0,
          hueRotation: 0,
          overlay: "#ffffff",
          blur: 0,
          grayscale: 0,
          brightness: 50,
          contrast: 50,
          opacity: 0,
          invert: 0,
          saturate: 50,
        },
        colors: {
          enable: false,
          isGradient: false,
          gradientAngle: 0,
          colorSecond: "#fff",
          colorFirst: "#fff",
          gradientDirection: "left-right",
        },
        wrap: "container",
        fill: "color",
        alignment: {
          enable: false,
          horizontal: "left",
          vertical: "top",
        },
        attachment: "scroll",
        lean: {
          enable: false,
          bottomLefty: 0,
          topLeftx: 0,
          bottomLeftx: 0,
          direction: "left",
          bottomRighty: 0,
          topRightx: 0,
          bottomRightx: 0,
          topRighty: 0,
          position: "both",
          topLefty: 0,
        },
        spacing: {
          enable: true,
          margin: {
            left: 0,
            right: 0,
            bottom: 60,
            top: 60,
          },
          padding: {
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
          },
        },
        behaviour: {
          hover: {
            enable: false,
          },
          link: {
            enable: false,
            mappedValue: "custom",
            label: "",
            customUrl: "",
            linkedObject: "",
          },
          active: {
            enable: false,
          },
        },
      },
      code1: {
        code: "",
        enable: true,
      },
    },
  },
  staticPost: {
    title: "",
    description: "",
    description_preview: "",
    picture_orig: "https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png",
    network: "",
    "contacts.from": {
      ID: "",
      iname: "",
      source_id: "",
      name_full: "",
    },
    "indexed.unix": 0,
    source: [],
    content_type: "",
    message: "",
    originUrl: "",
    slug: "",
    "created.unix": 0,
    url: "",
    picture_medium: "",
    sourceId: "",
    experience_order: 0,
    picture_large: "",
    id: "",
    type: "",
    contacts: {
      from: {
        ID: "",
        iname: "",
        source_id: "",
        name_full: "",
      },
    },
  },
}

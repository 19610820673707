import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import {
  type,
  union,
  Type,
  TypeOf,
  intersection,
  null as nullC,
  number as numberC,
  string as stringC,
  partial,
  boolean as booleanC,
} from "io-ts"
import { isRight } from "fp-ts/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { FColor } from "../../../../../modules/shared-modules/foundationStyles/foundationStylesTypes"
import { get2WithNull, get2WithNull4Enable, get3WithNull4Enable, showIfWritten } from "../bobUtils"
import { ColorLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetTypes"
import { handleBobStylesheetLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])
/**
 * We should only check for property and undefined
 * this solution is only until we fix the null being send around the page object
 */

// props only, no enable
const ShadowPropsCodec = type({
  offsetH: numberC,
  offsetV: numberC,
  blur: numberC,
  color: stringC,
})
const ShadowCodec = intersection([type({ enable: booleanC }), ShadowPropsCodec])

// props only, no enable
const ShadowPropsOptCodec = partial({
  offsetH: nullable(numberC),
  offsetV: nullable(numberC),
  blur: nullable(numberC),
  color: nullable(stringC),
})
const ShadowOptCodec = intersection([partial({ enable: nullable(booleanC) }), ShadowPropsOptCodec])

const StylesShadowCodec = intersection([
  type({ textShadow: ShadowCodec }),
  partial({
    behaviour: partial({
      active: partial({
        textShadow: ShadowOptCodec,
      }),
      hover: partial({
        textShadow: ShadowOptCodec,
      }),
    }),
    mobile: partial({
      textShadow: ShadowOptCodec,
      behaviour: partial({
        active: partial({
          textShadow: ShadowOptCodec,
        }),
        hover: partial({
          textShadow: ShadowOptCodec,
        }),
      }),
    }),
    tablet: partial({
      textShadow: ShadowOptCodec,
      behaviour: partial({
        active: partial({
          textShadow: ShadowOptCodec,
        }),
        hover: partial({
          textShadow: ShadowOptCodec,
        }),
      }),
    }),
  }),
])

const GSShadowCodec = intersection([
  ShadowPropsCodec,
  partial({
    behaviour: partial({
      active: ShadowPropsOptCodec,
      hover: ShadowPropsOptCodec,
    }),
    mobile: intersection([
      ShadowPropsOptCodec,
      partial({
        behaviour: partial({
          active: ShadowPropsOptCodec,
          hover: ShadowPropsOptCodec,
        }),
      }),
    ]),
    tablet: intersection([
      ShadowPropsOptCodec,
      partial({
        behaviour: partial({
          active: ShadowPropsOptCodec,
          hover: ShadowPropsOptCodec,
        }),
      }),
    ]),
  }),
])

type Shadow = TypeOf<typeof ShadowCodec>
type ShadowProps = TypeOf<typeof ShadowPropsCodec>
type ShadowOpt = TypeOf<typeof ShadowOptCodec>
type ShadowPropsOpt = TypeOf<typeof ShadowPropsOptCodec>
type StylesShadow = TypeOf<typeof StylesShadowCodec>
type GSShadow = TypeOf<typeof GSShadowCodec>
type ShadowCSS = { "text-shadow": string }

export function textShadowCss(css: Partial<ShadowCSS>): string {
  return showIfWritten(css, "text-shadow")
}

export function responsiveStyle(shadowObj: any, desktopDefaultValues?: any, breakpointDefaultValues?: any) {
  const hasEnable = shadowObj.hasOwnProperty("enable")
  if (hasEnable && !objValueExists(shadowObj, "enable")) return ""

  /**
   * Check if property is available, and fallback to breakpointDefault or the desktopDefault
   */
  const offsetH = shadowObj?.offsetH || breakpointDefaultValues?.offsetH || desktopDefaultValues?.offsetH
  const offsetV = shadowObj?.offsetV || breakpointDefaultValues?.offsetV || desktopDefaultValues?.offsetV
  const blur = shadowObj?.blur || breakpointDefaultValues?.blur || desktopDefaultValues?.blur
  const color = shadowObj?.color || breakpointDefaultValues?.color || desktopDefaultValues?.color
  // other props like text-shadow
  if (hasEnable && !shadowObj.enable)
    return {
      [`text-shadow`]: "none",
    }
  return {
    [`text-shadow`]: `${offsetH}px ${offsetV}px ${blur}px ${color}`,
  }
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined = undefined, // TODO: fix when working on other bobs
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  const styles = StylesShadowCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState, foundationStyle, stylesheetLabel)
  console.warn(decoderErrors(styles))
  return {}
}

export function globalStyleCssRenderUnsafe(
  gsObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined = undefined, // TODO: Work on when foundation is added to global styles,
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  const gs = GSShadowCodec.decode(gsObj)
  if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState, foundationStyle, stylesheetLabel)
  console.warn(decoderErrors(gs))
  return {}
}

export function cssRender(
  stylesObj: StylesShadow,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.textShadow, foundationStyle, stylesheetLabel)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.textShadow,
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.textShadow, stylesObj.textShadow, stylesheetLabel),
        foundationStyle,
        stylesheetLabel
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(
        stylesObj.textShadow,
        mergeBob2(stylesObj?.[breakpoint]?.textShadow, stylesObj.textShadow, stylesheetLabel),
        foundationStyle,
        stylesheetLabel
      )
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.textShadow,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.textShadow,
          stylesObj?.behaviour?.[behaviourState]?.textShadow,
          stylesObj?.[breakpoint]?.textShadow,
          stylesObj.textShadow,
          stylesheetLabel
        ),
        foundationStyle,
        stylesheetLabel
      )
    }
  }
}

export function globalStyleCssRender(
  stylesObj: GSShadow,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj, foundationStyle, stylesheetLabel)
    }
    //hover | active
    else {
      return renderOpt(
        merge2(stylesObj?.behaviour?.[behaviourState], stylesObj, stylesheetLabel),
        foundationStyle,
        stylesheetLabel
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderOpt(merge2(stylesObj?.[breakpoint], stylesObj, stylesheetLabel), foundationStyle, stylesheetLabel)
    }
    //hover | active
    else {
      return renderOpt(
        merge3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState],
          stylesObj.behaviour?.[behaviourState],
          stylesObj?.[breakpoint],
          stylesObj,
          stylesheetLabel
        ),
        foundationStyle,
        stylesheetLabel
      )
    }
  }
}

export function renderBob(
  shadowObj: Shadow,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  if (!shadowObj.enable) return {}

  return render(shadowObj, foundationStyle, stylesheetLabel)
}

export function renderBobOpt(
  defaultShadowObj: Shadow,
  shadowObj: ShadowOpt,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  if (shadowObj?.enable === false) {
    if (defaultShadowObj.enable) return cssProperty("none", undefined)

    return {}
  }
  if (shadowObj?.enable) return renderOpt(shadowObj, foundationStyle, stylesheetLabel)
  return {}
}

export function render(
  shadowObj: ShadowProps,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined
): ShadowCSS {
  const cssValue = `${shadowObj.offsetH}px ${shadowObj.offsetV}px ${shadowObj.blur}px ${handleBobStylesheetLabel(
    stylesheetLabel,
    shadowObj.color
  )}`
  return cssProperty(cssValue, stylesheetLabel)
}

export function renderOpt(
  shadowObj: ShadowPropsOpt,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  if (
    objValueExists(shadowObj, "offsetH") ||
    objValueExists(shadowObj, "offsetV") ||
    objValueExists(shadowObj, "blur") ||
    objValueExists(shadowObj, "color") ||
    stylesheetLabel
  ) {
    const cssValue = `${shadowObj.offsetH}px ${shadowObj.offsetV}px ${shadowObj.blur}px ${handleBobStylesheetLabel(
      stylesheetLabel,
      shadowObj.color
    )}`
    return cssProperty(cssValue, stylesheetLabel)
  }
  return {}
}

export function cssProperty(cssValue: string, stylesheetLabel: ColorLabel | undefined): ShadowCSS {
  return { "text-shadow": cssValue }
}

/**
 *
 * @param shadowObj
 * @param defaultShadowObj
 * @returns Shadow
 *
 */
export function mergeBob2(
  shadowObj: ShadowOpt | undefined,
  defaultShadowObj: Shadow,
  stylesheetLabel: ColorLabel | undefined
): ShadowOpt {
  if (!shadowObj) {
    return {
      enable: undefined,
      offsetH: undefined,
      offsetV: undefined,
      blur: undefined,
      color: undefined,
    }
  }

  const enable = get2WithNull4Enable(shadowObj?.enable, defaultShadowObj.enable)
  //if one of the values is set in the breakpoint we render the entire shadow property
  if (
    objValueExists(shadowObj, "offsetH") ||
    objValueExists(shadowObj, "offsetV") ||
    objValueExists(shadowObj, "blur") ||
    objValueExists(shadowObj, "color") ||
    stylesheetLabel
  ) {
    const offsetH = get2WithNull(shadowObj?.offsetH, defaultShadowObj.offsetH)
    const offsetV = get2WithNull(shadowObj?.offsetV, defaultShadowObj.offsetV)
    const blur = get2WithNull(shadowObj?.blur, defaultShadowObj.blur)
    const color = get2WithNull(shadowObj?.color, defaultShadowObj.color)

    return {
      enable,
      offsetH,
      offsetV,
      blur,
      color,
    }
  }
  return {
    enable: shadowObj?.enable,
    offsetH: undefined,
    offsetV: undefined,
    blur: undefined,
    color: undefined,
  }
}

/**
 *
 * @param shadowObj
 * @param defaultShadowObj
 * @returns ShadowProps
 *
 */
export function merge2(
  shadowObj: ShadowPropsOpt | undefined,
  defaultShadowObj: ShadowProps,
  stylesheetLabel: ColorLabel | undefined
): ShadowPropsOpt {
  if (!shadowObj) {
    return {
      offsetH: undefined,
      offsetV: undefined,
      blur: undefined,
      color: undefined,
    }
  }

  if (
    objValueExists(shadowObj, "offsetH") ||
    objValueExists(shadowObj, "offsetV") ||
    objValueExists(shadowObj, "blur") ||
    objValueExists(shadowObj, "color") ||
    stylesheetLabel
  ) {
    const offsetH = get2WithNull(shadowObj?.offsetH, defaultShadowObj.offsetH)
    const offsetV = get2WithNull(shadowObj?.offsetV, defaultShadowObj.offsetV)
    const blur = get2WithNull(shadowObj?.blur, defaultShadowObj.blur)
    const color = get2WithNull(shadowObj?.color, defaultShadowObj.color)

    return {
      offsetH,
      offsetV,
      blur,
      color,
    }
  }
  return {
    offsetH: undefined,
    offsetV: undefined,
    blur: undefined,
    color: undefined,
  }
}

/**
 *
 * @param shadowObj
 * @param shadowDefaultBreakpoint
 * @param defaultShadowObj
 * @returns Shadow
 */
export function mergeBob3(
  shadowObj: ShadowOpt | undefined,
  shadowDesktopBehaviour: ShadowOpt | undefined,
  shadowDefaultBreakpoint: ShadowOpt | undefined,
  defaultShadowObj: Shadow,
  stylesheetLabel: ColorLabel | undefined
): ShadowOpt {
  if (!shadowObj) {
    return {
      enable: undefined,
      offsetH: undefined,
      offsetV: undefined,
      blur: undefined,
      color: undefined,
    }
  }
  const enable = get3WithNull4Enable(
    shadowObj?.enable,
    shadowDesktopBehaviour?.enable,
    shadowDefaultBreakpoint?.enable,
    defaultShadowObj.enable
  )
  if (
    objValueExists(shadowObj, "offsetH") ||
    objValueExists(shadowObj, "offsetV") ||
    objValueExists(shadowObj, "blur") ||
    objValueExists(shadowObj, "color") ||
    stylesheetLabel
  ) {
    const offsetH =
      shadowObj?.offsetH ??
      shadowDesktopBehaviour?.offsetH ??
      shadowDefaultBreakpoint?.offsetH ??
      defaultShadowObj.offsetH
    const offsetV =
      shadowObj?.offsetV ??
      shadowDesktopBehaviour?.offsetV ??
      shadowDefaultBreakpoint?.offsetV ??
      defaultShadowObj.offsetV
    const blur =
      shadowObj?.blur ?? shadowDesktopBehaviour?.blur ?? shadowDefaultBreakpoint?.blur ?? defaultShadowObj.blur
    const color =
      shadowObj?.color ?? shadowDesktopBehaviour?.color ?? shadowDefaultBreakpoint?.color ?? defaultShadowObj.color

    return {
      enable,
      offsetH,
      offsetV,
      blur,
      color,
    }
  }
  return {
    enable: shadowObj?.enable,
    offsetH: undefined,
    offsetV: undefined,
    blur: undefined,
    color: undefined,
  }
}

/**
 *
 * @param shadowObj
 * @param shadowDefaultBreakpoint
 * @param defaultShadowObj
 * @returns Shadow
 */
export function merge3(
  shadowObj: ShadowPropsOpt | undefined,
  shadowDesktopBehaviour: ShadowPropsOpt | undefined,
  shadowDefaultBreakpoint: ShadowPropsOpt | undefined,
  defaultShadowObj: ShadowProps,
  stylesheetLabel: ColorLabel | undefined
): ShadowPropsOpt {
  if (!shadowObj) {
    return {
      offsetH: undefined,
      offsetV: undefined,
      blur: undefined,
      color: undefined,
    }
  }

  if (
    objValueExists(shadowObj, "offsetH") ||
    objValueExists(shadowObj, "offsetV") ||
    objValueExists(shadowObj, "blur") ||
    objValueExists(shadowObj, "color") ||
    stylesheetLabel
  ) {
    const offsetH =
      shadowObj?.offsetH ??
      shadowDesktopBehaviour?.offsetH ??
      shadowDefaultBreakpoint?.offsetH ??
      defaultShadowObj.offsetH
    const offsetV =
      shadowObj?.offsetV ??
      shadowDesktopBehaviour?.offsetV ??
      shadowDefaultBreakpoint?.offsetV ??
      defaultShadowObj.offsetV
    const blur =
      shadowObj?.blur ?? shadowDesktopBehaviour?.blur ?? shadowDefaultBreakpoint?.blur ?? defaultShadowObj.blur
    const color =
      shadowObj?.color ?? shadowDesktopBehaviour?.color ?? shadowDefaultBreakpoint?.color ?? defaultShadowObj.color

    return {
      offsetH,
      offsetV,
      blur,
      color,
    }
  }
  return {
    offsetH: undefined,
    offsetV: undefined,
    blur: undefined,
    color: undefined,
  }
}

export type { ShadowCSS, StylesShadow, GSShadow, ShadowProps, ShadowPropsOpt, Shadow, ShadowOpt }

import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom" // Import from React Router
import { JobId, ProfileName, FullJob, JobRunId } from "./jobsSchema"
import { RouteParams } from "./jobsManager"
import { Button, Tab, TabProps, Tabs } from "react-bootstrap"
import { SDForm } from "../../../lib/server-driven-forms/sdftypes"
import { fillSelects } from "../../../lib/server-driven-forms/FormLoader"
import { FormRender2, SDFResponseHandler, mkVoidSDFResponseHandler } from "../../../lib/server-driven-forms/FormRender2"
import { ProvideInjectValues } from "../../../lib/server-driven-forms/provideInjectValues"
import styled, { css } from "styled-components"
import JobRunsTable from "./jobRunsTable"
import { JobsNavigation } from "./jobsNavigation"
import { JobsApi } from "./jobsApi"

/* WIP JOBRUNSTABLES TO THIS
function JobRuns(props: {
  jobId: JobId
  profileName: ProfileName
  client: { runsOfJobAndProfile(jobId: JobId, profileName: ProfileName, paging: Paging): Promise<Paginated<JobRun>> }
}): JSX.Element {
  const [jobRuns, setJobRuns] = useState<JobRun[] | undefined>([])

  useEffect(() => {
    const pagination: Paging = { limit: 20, page: 1 } //bring the page state
    props.client.runsOfJobAndProfile(props.jobId, props.profileName, pagination).then((e) => setJobRuns(e.data))
  }, [props.client, props.jobId, props.profileName])

  //WIP HERE
  return (
    <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Job</th>
            <th>Status</th>
            <th>Started</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {jobRuns &&
            jobRuns.map((row, rowKey) => (
              <React.Fragment key={rowKey}>
                <tr>
                  <td>{row.job}</td>
                  <td>
                    <button className='buttonJTransparent'>
                      <StateButton status={!row.completed ? "running" : "completed"}></StateButton>
                    </button>
                  </td>
                  <td>
                    <span>
                      <span title={"Started:" + row.started} data-for='pipeline-action-tooltip' data-place='bottom'>
                        {row.started}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span>
                      <span
                        title={"Completed at:" + row.completed ? row.completed : ""}
                        data-for='pipeline-action-tooltip'
                        data-place='bottom'
                      >
                        {row.completed}
                      </span>
                    </span>
                  </td>
                  <td>
                    <div className='actions'>
                      <div className='hidden-actions'>{}</div>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </Table>
    </div>
  )
}*/

export const CustomTabs = styled(Tabs)`
  /* Your custom styles for the Tabs component */
`

export const CustomTab = styled(Tab)<TabProps>`
  /* Your custom styles for the Tab component */
  color: #495057;
  ${({ props }) => {
    return (
      props.selected &&
      css`
        color: #0095ff;

        ::after {
          content: "";
          position: relative;
          height: 5px;
          margin-top: 0.5rem;
          border-radius: 5px 5px 0 0;
          background-color: #0095ff;
          display: block;
        }
      `
    )
  }}
  & .nav-item .nav-link.active {
    /* Your custom styles for the active Tab */
    border-color-bottom: #dee2e6;
    background-color: #007bff;
    color: red;
  }
`

interface OwnProps {
  jobsClient: JobsApi
  nav: JobsNavigation
  jobId: JobId
  profileName: ProfileName
  handleGoBackToJCatalogue: (searchQuery?: string) => void
  handleJumpToDocument: (jobRunId: JobRunId) => void
  responseHandler?: SDFResponseHandler<void>
  injectValues: ProvideInjectValues
  searchQuery?: string
}

type JobDetailProps = OwnProps & RouteComponentProps<RouteParams>

function JobDetail(props: JobDetailProps) {
  const [job, setJob] = useState<FullJob | undefined>(undefined)
  const [form, setForm] = useState<SDForm | undefined>(undefined)
  const [tab, setTab] = useState<String>("input")

  useEffect(() => {
    props.jobsClient.getJobWithForm(props.jobId).then((f) => {
      fillSelects(f.form, props.jobsClient).then((e) => setForm(e))
      setJob(f.job)
    })
  }, [props.jobId, props.jobsClient])

  const responseHandler: SDFResponseHandler<void> = props.responseHandler ?? mkVoidSDFResponseHandler()

  function handleTabChange(tabKey: string) {
    setTab(tabKey)
  }

  const doJobButton: JSX.Element = (
    <span>
      {" "}
      Do<span className='dojob-color'>Job</span>{" "}
    </span>
  )
  return (
    <div className='jobs-main-panel'>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",

          //backgroundColor: "rgb(233, 233, 240)",
          //   backgroundImage: "url(" + job?.job.image + ")",
          backgroundImage: "url('https://static-media.fluxio.cloud/dojobdemo/xGX4zY4s_3.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "150px",
          padding: "15px 30px 30px 30px",
        }}
      >
        <Button className='buttonLink' onClick={() => props.handleGoBackToJCatalogue(props.searchQuery)}>
          &lt; All Jobs
        </Button>
      </div>

      <div className='jobs-library main-panel-full main-panel-85' style={{ marginTop: 0 }}>
        <div style={{ backgroundColor: "" }}>
          <h1 className='file-title'>{job?.name}</h1>
          <p className='description'>{job?.description}</p>
        </div>
        <CustomTabs
          className='job-tab'
          mountOnEnter={true}
          unmountOnExit={true}
          defaultActiveKey={tab}
          activeKey={tab}
          onSelect={handleTabChange}
        >
          <CustomTab selected={tab === "input"} eventKey='input' title='Input'>
            {form && (
              <FormRender2<void>
                form={form}
                client={props.jobsClient}
                profile={props.profileName}
                provideInjectValues={props.injectValues}
                responseHandler={responseHandler}
                submitProps={{ labelElement: doJobButton }}
                submitStyle={["buttonDoJob"]}
                containerStyle={["form-w70"]}
              />
            )}
          </CustomTab>
          <CustomTab selected={tab === "runs"} eventKey='runs' title='Runs'>
            <JobRunsTable
              jobsClient={props.jobsClient}
              navigation={props.nav}
              jobId={props.jobId}
              profileName={props.profileName}
              handleTabChange={handleTabChange}
              handleJumpToDocument={props.handleJumpToDocument}
            ></JobRunsTable>
          </CustomTab>
        </CustomTabs>
      </div>
    </div>
  )
}

export default JobDetail

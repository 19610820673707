import {
  CLEAR_HOST_INFO_ACTIONS,
  ERROR_UPDATE_HOST,
  REQUEST_UPDATE_HOST,
  SUCCESS_UPDATE_HOST,
  ERROR_CREATE_HOST,
  REQUEST_CREATE_HOST,
  SUCCESS_CREATE_HOST,
  ERROR_FETCH_HOSTS,
  REQUEST_HOSTS,
  RECEIVE_HOSTS,
  CLEAR_AUDIENCE_INFO_ACTIONS,
  ERROR_FETCH_AUDIENCES,
  REQUEST_AUDIENCES,
  RECEIVE_AUDIENCES,
  ERROR_CREATE_AUDIENCE,
  REQUEST_CREATE_AUDIENCE,
  SUCCESS_CREATE_AUDIENCE,
  ERROR_UPDATE_AUDIENCE,
  REQUEST_UPDATE_AUDIENCE,
  SUCCESS_UPDATE_AUDIENCE,
  ERROR_DELETE_AUDIENCE,
  REQUEST_DELETE_AUDIENCE,
  SUCCESS_DELETE_AUDIENCE,
  ERROR_FETCH_HUBS,
  REQUEST_HUBS,
  RECEIVE_HUBS,
  ERROR_FETCH_DOMAIN_PAGES,
  REQUEST_DOMAIN_PAGES,
  RECEIVE_DOMAIN_PAGES,
  CLEAR_DOMAIN_PAGES,
  ERROR_EDIT_HUB_TEMPLATE,
  REQUEST_EDIT_HUB_TEMPLATE,
  EDITED_HUB_TEMPLATE,
  EDIT_HUB_TEMPLATE_INTERNAL_REDUX,
  REPLACE_EXPERIENCE,
  CLEAR_HUB_HANDLER_PARENTID,
  ERROR_FETCHING_FORMULAS_LIST,
  REQUEST_FORMULAS_LIST,
  RECEIVE_FORMULAS_LIST,
  CLEAR_FORMULAS_LIST,
  REPLACE_FINDER_OBJECT,
  TOGGLE_FINDER_PREVIEW,
  EXPERIENCE_MANAGER_EVENT_PILE,
  CLEAR_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER,
  CLEAR_EXPERIENCE_MANAGER_EVENT_PILE,
  ERROR_EDITING_EXPERIENCE_MANAGER_TEMPLATE,
  REQUEST_EDIT_EXPERIENCE_MANAGER_TEMPLATE,
  SUCCESS_EDIT_EXPERIENCE_MANAGER_TEMPLATE,
  ERROR_FETCHING_COMPONENTS_BY_CAT_FAM,
  REQUEST_COMPONENTS_BY_CAT_FAM,
  SUCCESS_FETCH_COMPONENTS_BY_CAT_FAM,
  CLEAR_COMPONENTS_LIST_BY_CAT_FAM,
  EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER,
  CLEAR_EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER,
  ERROR_INSERTING_OBJECT_EXPERIENCE_MANAGER,
  REQUEST_INSERT_OBJECT_EXPERIENCE_MANAGER,
  SUCCESS_INSERT_OBJECT_EXPERIENCE_MANAGER,
  ERROR_CREATE_OBJECT_EXPERIENCE_MANAGER,
  REQUEST_CREATE_OBJECT_EXPERIENCE_MANAGER,
  SUCCESS_CREATE_OBJECT_EXPERIENCE_MANAGER,
  CLEAR_CREATE_OBJECT_EXPERIENCE_MANAGER,
  ERROR_INSERTING_FIRST_OBJECT_EXPERIENCE_MANAGER,
  REQUEST_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER,
  SUCCESS_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER,
  CLEAR_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER,
  ERROR_ADDING_FORMULA_TO_OBJECT,
  REQUEST_ADD_FORMULA_TO_OBJECT,
  SUCCESS_ADD_FORMULA_TO_OBJECT,
  ERROR_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  REQUEST_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  SUCCESS_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  CLEAR_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  ERROR_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  REQUEST_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  SUCCESS_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  CLEAR_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  ERROR_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  REQUEST_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  SUCCESS_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  CLEAR_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  ERROR_VALIDATING_URI,
  REQUEST_VALIDATE_URI,
  SUCCESS_VALIDATE_URI,
  ERROR_VALIDATING_FIRST_LEVEL_URI,
  REQUEST_VALIDATE_FIRST_LEVEL_URI,
  SUCCESS_VALIDATE_FIRST_LEVEL_URI,
  CLEAR_VALIDATED_FIRST_LEVEL_URI,
  ERROR_VALIDATING_OBJECT_URI,
  REQUEST_VALIDATE_OBJECT_URI,
  SUCCESS_VALIDATE_OBJECT_URI,
  CLEAR_VALIDATED_OBJECT_URI,
  CLEAR_VALIDATED_URI,
  CLEAR_FETCH_FORMULA_CONTENT_EM,
  DELETE_EVENT_EXPERIENCE_MANAGER,
  ERROR_FETCH_FORMULA_CONTENT_EM,
  REQUEST_FORMULA_CONTENT_EM,
  SUCCESS_FETCH_FORMULA_CONTENT_EM,
  ERROR_FETCH_FORMULA_CONTENT_PREVIEW_EM,
  REQUEST_FORMULA_CONTENT_PREVIEW_EM,
  SUCCESS_FETCH_FORMULA_CONTENT_PREVIEW_EM,
  CLEAR_FETCH_FORMULA_CONTENT_PREVIEW_EM,
  CLEAR_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM,
  ERROR_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM,
  REQUEST_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM,
  SUCCESS_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM,
  CLEAR_FETCH_MORE_POST_SEARCH_PREVIEW_EM,
  ERROR_FETCH_MORE_POST_SEARCH_PREVIEW_EM,
  REQUEST_FETCH_MORE_POST_SEARCH_PREVIEW_EM,
  SUCCESS_FETCH_MORE_POST_SEARCH_PREVIEW_EM,
  CLEAR_FETCH_OBJECT_FORMULA_CONTENT_EM,
  OPEN_EDITOR_MODAL,
  ERROR_CREATE_FORMULA_BY_POSTS_QUERY_EM,
  REQUEST_CREATE_FORMULA_BY_POSTS_QUERY_EM,
  SUCCESS_CREATE_FORMULA_BY_POSTS_QUERY_EM,
  ERROR_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM,
  REQUEST_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM,
  SUCCESS_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM,
  CLEAR_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM,
  ERROR_REORDER_OBJECTS_EXPERIENCE_MANAGER,
  REQUEST_REORDER_OBJECTS_EXPERIENCE_MANAGER,
  SUCCESS_REORDER_OBJECTS_EXPERIENCE_MANAGER,
  ERROR_MOVE_OBJECTS_EXPERIENCE_MANAGER,
  REQUEST_MOVE_OBJECTS_EXPERIENCE_MANAGER,
  SUCCESS_MOVE_OBJECTS_EXPERIENCE_MANAGER,
  ERROR_UPDATING_OBJECT_EXPERIENCE_MANAGER,
  REQUEST_UPDATE_OBJECT_EXPERIENCE_MANAGER,
  SUCCESS_UPDATE_OBJECT_EXPERIENCE_MANAGER,
  CLEAR_UPDATE_OBJECT_EXPERIENCE_MANAGER,
  ERROR_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER,
  REQUEST_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER,
  SUCCESS_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER,
  CLEAR_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER,
  CLEAR_UPDATE_PAGE_OBJECT_EXPERIENCE_MANAGER,
  REQUEST_UPDATE_PAGE_SETTINGS,
  RECEIVE_UPDATE_PAGE_SETTINGS,
  ERROR_UPDATE_PAGE_SETTINGS,
  ERROR_DELETING_OBJECT_FORMULA_EXPERIENCE_MANAGER,
  REQUEST_DELETE_OBJECT_FORMULA_EXPERIENCE_MANAGER,
  SUCCESS_DELETE_OBJECT_FORMULA_EXPERIENCE_MANAGER,
  ERROR_DELETING_OBJECT_EXPERIENCE_MANAGER,
  REQUEST_DELETE_OBJECT_EXPERIENCE_MANAGER,
  SUCCESS_DELETE_OBJECT_EXPERIENCE_MANAGER,
  ERROR_DELETE_ENTIRE_EXPERIENCE_MANAGER,
  REQUEST_DELETE_ENTIRE_EXPERIENCE_MANAGER,
  SUCCESS_DELETE_ENTIRE_EXPERIENCE_MANAGER,
  CLEAR_DELETE_ENTIRE_EXPERIENCE_MANAGER,
  ERROR_FETCH_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER,
  REQUEST_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER,
  SUCCESS_FETCH_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER,
  CLEAR_SELECTED_COMPONENT_FAMILY,
  DEFAULT_OBJECT_COLORS_EXPERIENCE_MANAGER,
  ERROR_SUBMIT_FAVICON_NEW_EXPERIENCE,
  REQUEST_SUBMIT_FAVICON_NEW_EXPERIENCE,
  SUCCESS_SUBMIT_FAVICON_NEW_EXPERIENCE,
  CLEAR_FAVICON_NEW_EXPERIENCE,
  ERROR_CHECK_CREATED_HUB,
  REQUEST_CHECK_CREATED_HUB,
  SUCCESS_CHECK_CREATED_HUB,
  CLEAR_CHECK_CREATED_EM,
  CLEAR_FAVICON_UPLOAD_EM,
  COPY_OBJECT_EM,
  OBJECT_TO_PASTE_EM,
  PASTED_SERVER_OBJECT_EM,
  PASTE_OBJECT_EM,
  CLEAR_PASTE_OBJECT_EM,
  CLEAR_SERVER_COPY_PASTE_EM,
  CLEAR_COPY_OBJECT_EM,
  COPY_OBJECT_TEMPLATE_EM,
  PASTE_OBJECT_TEMPLATE_EM,
  CLEAR_COPY_OBJECT_TEMPLATE_EM,
  ERROR_COPY_PASTE_EM,
  REQUEST_COPY_PASTE_EM,
  SUCCESS_COPY_PASTE_EM,
  CLEAR_COPY_PASTE_EM,
  ERROR_COPY_PASTE_STYLES_EM,
  REQUEST_COPY_PASTE_STYLES_EM,
  SUCCESS_COPY_PASTE_STYLES_EM,
  CLEAR_COPY_PASTE_STYLES_EM,
  ADD_BULK_EVENT,
  ERROR_DELETE_HOST_OUTPUTTERS,
  REQUEST_DELETE_HOST_OUTPUTTERS,
  SUCCESS_DELETE_HOST_OUTPUTTERS,
  CLEAR_DELETE_HOST_OUTPUTTERS,
  ERROR_FETCH_HOST,
  FETCHING_HOST,
  SUCCESS_FETCH_HOST,
  ERROR_FETCHING_FORMULA_INFO,
  REQUEST_FORMULA_INFO,
  SUCCESS_FETCH_FORMULA_INFO,
  ERROR_REGISTER_DOMAIN_GOOGLE,
  REQUEST_REGISTER_DOMAIN_GOOGLE,
  SUCCESS_REGISTER_DOMAIN_GOOGLE,
  ERROR_SEND_SITEMAP,
  REQUEST_SEND_SITEMAP,
  SUCCESS_SEND_SITEMAP,
  ERROR_VALIDATE_GOOGLE,
  REQUEST_VALIDATE_GOOGLE,
  SUCCESS_VALIDATE_GOOGLE,
  CLEAR_DOMAIN_ACTIONS,
  NEW_OBJECT_ORDER,
  EMPTY_NEW_OBJECT_ORDER,
  SELECTED_FORMULA_TO_PREVIEW,
  ADD_BULK_POSTS_EXPERIENCE_MANAGER,
  ADD_SINGLE_POST_EXPERIENCE_MANAGER,
  ADD_SINGLE_POST_BY_EMBED_EXPERIENCE_MANAGER,
  REMOVE_SINGLE_POST_EXPERIENCE_MANAGER,
  CLEAR_POSTS_EXPERIENCE_MANAGER,
  CLEAR_EDITED_POSTS_EXPERIENCE_MANAGER,
  ERROR_SUBMIT_SOURCE_TO_POST,
  REQUEST_SUBMIT_SOURCE_TO_POST,
  SUCCESS_SUBMIT_SOURCE_TO_POST,
  CLEAR_SOURCE_TO_POST,
  ERROR_CHANGE_POSTS_EXPERIENCE_ORDER,
  REQUEST_CHANGE_POSTS_EXPERIENCE_ORDER,
  SUCCESS_CHANGE_POSTS_EXPERIENCE_ORDER,
  CLEAR_POSTS_EXPERIENCE_ORDER,
  ERROR_SEARCH_BY_POSTS_EXPERIENCE_MANAGER,
  REQUEST_SEARCH_BY_POSTS_EXPERIENCE_MANAGER,
  SUCCESS_SEARCH_BY_POSTS_EXPERIENCE_MANAGER,
  CLEAR_SEARCH_BY_POSTS_EXPERIENCE_MANAGER,
  OBJECT_SELECTOR_MODE_EM,
  EVENT_PILE_SUCCESS_RESPONSES_EM,
  ERROR_HUB_TO_CLONE_EXPERERIENCE_MANAGER,
  REQUEST_HUB_TO_CLONE_EXPERIENCE_MANAGER,
  SUCCESS_HUB_TO_CLONE_EXPERIENCE_MANAGER,
  ERROR_OG_IMAGE_UPLOAD,
  REQUEST_OG_IMAGE_UPLOAD,
  SUCCESS_OG_IMAGE_UPLOAD,
  CLEAR_OG_IMAGE_UPLOAD,
  ERROR_LAYOUTS_EXPERIENCE_MANAGER,
  REQUEST_LAYOUTS_EXPERIENCE_MANAGER,
  SUCCESS_LAYOUTS_EXPERIENCE_MANAGER,
  CLEAR_LAYOUTS_EXPERIENCE_MANAGER,
  ERROR_SELECTED_LAYOUT_EXPERIENCE_MANAGER,
  REQUEST_SELECTED_LAYOUT_EXPERIENCE_MANAGER,
  SUCCESS_SELECTED_LAYOUT_EXPERIENCE_MANAGER,
  CLEAR_SELECTED_LAYOUT_EXPERIENCE_MANAGER,
  CLEAR_SAVED_HUB_FOR_PREVIEW,
  SAVED_HUB_FOR_PREVIEW,
  HANDLE_NEW_PAGE_MODAL,
  ERROR_CLONE_PAGE_EXPERIENCE_MANAGER,
  REQUEST_CLONE_PAGE_EXPERIENCE_MAGAGER,
  SUCCESS_CLONE_PAGE_EXPERIENCE_MANAGER,
  CLEAR_CLONE_PAGE_EXPERIENCE_MANAGER,
  EVENT_ID_EM,
  AUTO_SELECT_ON_CREATE_MODE_EM,
  SELECTED_BOB_ELEMENT_EM,
  ELEMENT_SELECTOR_STATE,
  OBJECT_SELECTOR_STATE,
  ERROR_STAGING_PAGE_EXPERIENCE_MANAGER,
  REQUEST_STAGING_PAGE_EXPERIENCE_MANAGER,
  SUCCESS_STAGING_PAGE_EXPERIENCE_MANAGER,
  OPEN_COLOR_PICKER_STYLES,
  COLOR_PICKER_EXPORT_SELECTION,
  ERROR_PUBLISH_PAGE,
  REQUEST_PUBLISH_PAGE,
  SUCCESS_PUBLISH_PAGE,
  CLEAR_PUBLISH_PAGE,
  ERROR_UNPUBLISH_PAGE,
  REQUEST_UNPUBLISH_PAGE,
  SUCCESS_UNPUBLISH_PAGE,
  CLEAR_UNPUBLISH_PAGE,
  ERROR_PUBLISH_SITE_PAGES,
  REQUEST_PUBLISH_SITE_PAGES,
  SUCCESS_PUBLISH_SITE_PAGES,
  CLEAR_PUBLISH_SITE_PAGES,
  ERROR_PUBLISH_SITE_PAGES_STATUS,
  REQUEST_PUBLISH_SITE_PAGES_STATUS,
  SUCCESS_PUBLISH_SITE_PAGES_STATUS,
  CLEAR_PUBLISH_SITE_PAGES_STATUS,
  ERROR_PUBLISH_OBJECT_TEMPLATE,
  REQUEST_PUBLISH_OBJECT_TEMPLATE,
  SUCCESS_PUBLISH_OBJECT_TEMPLATE,
  CLEAR_PUBLISH_OBJECT_TEMPLATE,
  ERROR_UNPUBLISH_OBJECT_TEMPLATE,
  REQUEST_UNPUBLISH_OBJECT_TEMPLATE,
  SUCCESS_UNPUBLISH_OBJECT_TEMPLATE,
  CLEAR_UNPUBLISH_OBJECT_TEMPLATE,
  REQUEST_CREATE_SYMBOL_INSTANCE,
  RECEIVE_CREATE_SYMBOL_INSTANCE,
  ERROR_CREATE_SYMBOL_INSTANCE,
  CLEAR_CREATE_SYMBOL_INSTANCE,
  REQUEST_SET_SYMBOL,
  RECEIVE_SET_SYMBOL,
  ERROR_SET_SYMBOL,
  CLEAR_SET_SYMBOL,
  REQUEST_UNSET_SYMBOL,
  RECEIVE_UNSET_SYMBOL,
  ERROR_UNSET_SYMBOL,
  CLEAR_UNSET_SYMBOL,
  ERROR_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  REQUEST_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  SUCCESS_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  ERROR_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  REQUEST_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  SUCCESS_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  CLEAR_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  REQUEST_SET_CONTROLLER_STATE,
  ADD_OBJECT_TEMPLATE,
  CLEAR_ADD_OBJECT_TEMPLATE,
  ERROR_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER,
  REQUEST_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER,
  SUCCESS_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER,
  CLEAR_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER,
  ERROR_DELETE_OBJECT_TEMPLATE,
  REQUEST_DELETE_OBJECT_TEMPLATE,
  SUCCESS_DELETE_OBJECT_TEMPLATE,
  CLEAR_DELETE_OBJECT_TEMPLATE,
  ERROR_UPDATE_OBJECT_TEMPLATE,
  REQUEST_UPDATE_OBJECT_TEMPLATE,
  SUCCESS_UPDATE_OBJECT_TEMPLATE,
  VIEW_PORT_ORIGINAL_SIZE_STATE_EXPERIENCE_MANAGER,
  SET_BEHAVIOUR_STATE,
  ERROR_FETCH_STAGING_OBJECT_TEMPLATE_BY_ID,
  REQUEST_STAGING_OBJECT_TEMPLATE_BY_ID,
  SUCCESS_STAGING_OBJECT_TEMPLATE_BY_ID,
  ERROR_FETCH_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID,
  REQUEST_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID,
  SUCCESS_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID,
  ERROR_FETCH_STAGING_SYMBOL_BY_ID,
  REQUEST_STAGING_SYMBOL_BY_ID,
  SUCCESS_STAGING_SYMBOL_BY_ID,
  ERROR_OBJECT_TEMPLATE_SCREEN_SHOT,
  REQUEST_OBJECT_TEMPLATE_SCREEN_SHOT,
  SUCCESS_OBJECT_TEMPLATE_SCREEN_SHOT,
  CLEAR_OBJECT_TEMPLATE_SCREEN_SHOT,
  ERROR_CREATE_FLUXIO_POST,
  REQUEST_CREATE_FLUXIO_POST,
  SUCCESS_CREATE_FLUXIO_POST,
  CLEAN_CREATE_FLUXIO_POST,
  ERROR_UPDATE_FLUXIO_POST,
  REQUEST_UPDATE_FLUXIO_POST,
  SUCCESS_UPDATE_FLUXIO_POST,
  CLEAN_UPDATE_FLUXIO_POST,
  ERROR_DELETE_FLUXIO_POST,
  REQUEST_DELETE_FLUXIO_POST,
  SUCCESS_DELETE_FLUXIO_POST,
  CLEAN_DELETE_FLUXIO_POST,
  ERROR_FETCH_IS_PAGE_PUBLISHED_STATUS,
  REQUEST_IS_PAGE_PUBLISHED_STATUS,
  SUCCESS_FETCH_IS_PAGE_PUBLISHED_STATUS,
  CLEAR_FETCH_IS_PAGE_PUBLISHED_STATUS,
  UPDATE_SINGLE_OBJECT_PAGE_TREE,
  ERROR_FETCH_UPLOADED_FONTS,
  REQUEST_UPLOADED_FONTS,
  SUCCESS_FETCH_UPLOADED_FONTS,
  ERROR_FETCH_UPLOADED_FONTS_EM,
  REQUEST_UPLOADED_FONTS_EM,
  SUCCESS_FETCH_UPLOADED_FONTS_EM,
  ERROR_DELETE_FONT,
  REQUEST_DELETE_FONT,
  SUCCESS_DELETE_FONT,
  CLEAR_DELETE_FONT,
  ERROR_ADD_DATASET_TO_OBJECT,
  REQUEST_ADD_DATASET_TO_OBJECT,
  SUCCESS_ADD_DATASET_TO_OBJECT,
  CLEAR_ADD_DATASET_TO_OBJECT,
  UPDATE_STYLES_COLLAPSE,
  CHANGE_EXPERIENCE_MANAGER_TYPE,
  LOG_FINDER_OPEN,
  ERROR_FETCH_PAGE_LINKS,
  REQUEST_FETCH_PAGE_LINKS,
  SUCCESS_FETCH_PAGE_LINKS,
  CLEAR_FETCH_PAGE_LINKS,
  ERROR_FETCH_LINK_MAPPED_FIELDS,
  REQUEST_FETCH_LINK_MAPPED_FIELDS,
  SUCCESS_FETCH_LINK_MAPPED_FIELDS,
  CLEAR_FETCH_LINK_MAPPED_FIELDS,
  CLEAR_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER,
  ERROR_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER,
  REQUEST_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER,
  SUCCESS_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER,
  ERROR_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS,
  REQUEST_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS,
  SUCCESS_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS,
  CLEAR_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS,
  ERROR_FETCH_MAPPED_FIELDS,
  REQUEST_FETCH_MAPPED_FIELDS,
  SUCCESS_FETCH_MAPPED_FIELDS,
  CLEAR_FETCH_MAPPED_FIELDS,
  CLEAR_SET_OBJECT_MAPPED_FIELD,
  ERROR_SET_OBJECT_MAPPED_FIELD,
  REQUEST_SET_OBJECT_MAPPED_FIELD,
  SUCCESS_SET_OBJECT_MAPPED_FIELD,
  CLEAR_UNSET_OBJECT_MAPPED_FIELD,
  ERROR_UNSET_OBJECT_MAPPED_FIELD,
  REQUEST_UNSET_OBJECT_MAPPED_FIELD,
  SUCCESS_UNSET_OBJECT_MAPPED_FIELD,
  SET_EXPERIENCE_MANAGER_EDITOR_TYPE,
  CLEAR_FETCHED_HOSTS,
  CLEAR_GET_PLATFORM_PAGE_TEMPLATES,
  ERROR_GET_PLATFORM_PAGE_TEMPLATES,
  REQUEST_GET_PLATFORM_PAGE_TEMPLATES,
  SUCCESS_GET_PLATFORM_PAGE_TEMPLATES,
  FINDER_TYPE_PUBLIC_PAGE_REDUCER,
  REQUEST_SET_OBJECT_CPSRC,
  SUCCESS_SET_OBJECT_CPSRC,
  ERROR_SET_OBJECT_CPSRC,
  REQUEST_UNSET_OBJECT_CPSRC,
  ERROR_UNSET_OBJECT_CPSRC,
  SUCCESS_UNSET_OBJECT_CPSRC,
  CLEAR_UNSET_OBJECT_CPSRC,
} from "../shared-store/actions/actionTypes"
import {
  addComponentToObjectServerSide,
  applyComponentToObjectServerSide,
  checkHub,
  clonePage,
  componentsCategories,
  copyPasteSS,
  copyPasteStylesSS,
  createAudience,
  createFirstObject,
  createHost,
  createObject,
  createObjectServerSide,
  createObjectTemplate,
  deleteEntireExperience,
  deleteFont,
  deleteObject,
  deleteObjectFormula,
  fetchComponentsCatFam,
  fetchFormulas,
  fetchHub,
  fetchMoreFormulaContentEM,
  getAudiences,
  getDomainPages,
  getHosts,
  getHubs,
  isPagePublished,
  moveObject,
  postsExperienceOrder,
  reOrderObjects,
  requestLayouts,
  requestSelectedLayout,
  sourceToPost,
  stagingObjectTemplateById,
  stagingPage,
  stagingPublishedObjectTemplateById,
  stagingSymbolById,
  submitFavicon,
  submitFormulaWithQuery,
  undoAddComponentToObject,
  updateObject,
  updateObjectContent,
  uploadedFonts,
  uploadedFontsEM,
  uploadOGImage,
  fetchPageLinks as fetchPageLinksApi,
  addDataSetToObject as addDataSetToObjectApi,
  addFormulaToObject as addFormulaToObjectApi,
  updateHost as updateHostApi,
  validateFirstLevelUri as validateFirstLevelUriApi,
  validateUri as validateUriApi,
  searchByPosts as searchByPostsApi,
  deleteFluxioPost as deleteFluxioPostApi,
  updateFluxioPost as updateFluxioPostApi,
  createFluxioPost as createFluxioPostApi,
  objectTemplateScreenShot as objectTemplateScreenShotApi,
  updateObjectTemplate as updateObjectTemplateApi,
  deleteObjectTemplate as deleteObjectTemplateApi,
  unpublishObjectTemplate as unpublishObjectTemplateApi,
  publishObjectTemplate as publishObjectTemplateApi,
  publishSitePagesStatus as publishSitePagesStatusApi,
  publishSitePages as publishSitePagesApi,
  unpublishPage as unpublishPageApi,
  publishPage as publishPageApi,
  validateGoogle as validateGoogleApi,
  registerDomainGoogle as registerDomainGoogleApi,
  sendSitemap as sendSitemapApi,
  fetchFormulaInfo as fetchFormulaInfoApi,
  requestDeleteHost as requestDeleteHostApi,
  updatePageSettings as updatePageSettingsApi,
  fetchFormulaContentEM as fetchFormulaContentEMApi,
  validateObjectUri as validateObjectUriApi,
  editExperienceManagerTemplate as editExperienceManagerTemplateApi,
  editHubTemplate as editHubTemplateApi,
  updateAudience as updateAudienceApi,
  deleteAudience as deleteAudienceApi,
  fetchMappedFieldsApi,
  updateObjectSettings,
  fetchMappedFieldsExtraApi,
  setObjectMappedFieldApi,
  unsetObjectMappedFieldApi,
  setObjectCPSrcApi,
  unsetObjectCPSrcApi,
  getPlatformPageTemplatesApi,
} from "../../api/shared-api/experienceManagerApi"
import {
  setSymbol as setSymbolApi,
  createSymbolInstance as createSymbolInstanceApi,
  updateInstanceContent,
  unsetSymbol as unsetSymbolApi,
  deleteInstanceContent,
} from "../../api/shared-api/symbolsApi"
import { deepLevelHubObj } from "../../modules/shared-modules/utilities/arrayOrder"
import Metrics from "../../modules/shared-modules/metrics/metrics"
import { AppDispatch } from "../shared-store/types"
import { ResponsiveData } from "../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { SelectedController } from "../../modules/shared-modules/experienceManager/types/reduxTypes"
import { PageTypes } from "../../modules/shared-modules/experienceManager/types/pageTypes"
import { field } from "fp-ts"

export function clearHostInfo() {
  return {
    type: CLEAR_HOST_INFO_ACTIONS,
  }
}

export function clearHostInfoActions() {
  return (dispatch: AppDispatch) => {
    dispatch(clearHostInfo())
  }
}

export function errorUpdateHost(error: any) {
  return {
    type: ERROR_UPDATE_HOST,
    error,
  }
}

export function requestUpdateHost(profileId: string) {
  return {
    type: REQUEST_UPDATE_HOST,
    profileId,
  }
}

export function successUpdateHost(response: any) {
  return {
    type: SUCCESS_UPDATE_HOST,
    response,
  }
}

export function updateHost(
  profileId: string,
  hostId: string,
  cookie: string,
  color: string,
  url: string,
  token: string,
  objectId: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUpdateHost(profileId))
    return updateHostApi(profileId, hostId, cookie, color, url, token, objectId)
      .then((response: any) => {
        dispatch(successUpdateHost(response))
      })
      .catch((error: any) => {
        dispatch(errorUpdateHost(error))
      })
  }
}

export function errorCreateHost(error: any) {
  return {
    type: ERROR_CREATE_HOST,
    error,
  }
}

export function requestCreateHost(profileId: string) {
  return {
    type: REQUEST_CREATE_HOST,
    profileId,
  }
}

export function successCreateHost(response: any) {
  return {
    type: SUCCESS_CREATE_HOST,
    response,
  }
}

export function createHosts(
  profileId: string,
  name: string,
  url: string,
  txtRecord: any,
  themeColor: any,
  cookie: any,
  loader: any,
  token: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestCreateHost(profileId))
    return createHost(profileId, name, url, txtRecord, themeColor, cookie, loader, token)
      .then((response: any) => {
        dispatch(successCreateHost(response))
      })
      .catch((error: any) => {
        dispatch(errorCreateHost(error))
      })
  }
}

export function errorFetchHosts(error: any) {
  return {
    type: ERROR_FETCH_HOSTS,
    error,
  }
}

export function requestHosts(profileId: string) {
  return {
    type: REQUEST_HOSTS,
    profileId,
  }
}

export function receiveHosts(response: any) {
  return {
    type: RECEIVE_HOSTS,
    response,
  }
}

export function fetchHosts(
  profileId: string,
  limit: any,
  orderby: any,
  order: any,
  search: any,
  page: any,
  token: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestHosts(profileId))
    return getHosts(profileId, limit, orderby, order, search, page, token)
      .then((response: any) => {
        dispatch(receiveHosts(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchHosts(error))
      })
  }
}

export function clearFetchedHosts() {
  return {
    type: CLEAR_FETCHED_HOSTS,
  }
}

export function cleanFetchedHosts() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchedHosts())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearAudienceInfo() {
  return {
    type: CLEAR_AUDIENCE_INFO_ACTIONS,
  }
}

export function clearAudienceInfoActions() {
  return (dispatch: AppDispatch) => {
    dispatch(clearAudienceInfo())
  }
}

export function errorFetchAudiences(error: any) {
  return {
    type: ERROR_FETCH_AUDIENCES,
    error,
  }
}

export function requestAudiences(profileId: string) {
  return {
    type: REQUEST_AUDIENCES,
    profileId,
  }
}

export function receiveAudiences(response: any) {
  return {
    type: RECEIVE_AUDIENCES,
    response,
  }
}

export function fetchAudiences(
  profileId: string,
  limit: any,
  orderby: any,
  order: any,
  search: any,
  page: any,
  token: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestAudiences(profileId))
    return getAudiences(profileId, limit, orderby, order, search, page, token)
      .then((response: any) => {
        dispatch(receiveAudiences(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchAudiences(error))
      })
  }
}

export function errorCreateAudience(error: any) {
  return {
    type: ERROR_CREATE_AUDIENCE,
    error,
  }
}

export function requestCreateAudience(profileId: string) {
  return {
    type: REQUEST_CREATE_AUDIENCE,
    profileId,
  }
}

export function successCreateAudience(response: any) {
  return {
    type: SUCCESS_CREATE_AUDIENCE,
    response,
  }
}

export function createAudiences(profileId: string, name: any, type: any, min: any, max: any, token: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestCreateAudience(profileId))
    return createAudience(profileId, name, type, min, max, token)
      .then((response: any) => {
        dispatch(successCreateAudience(response))
      })
      .catch((error: any) => {
        dispatch(errorCreateAudience(error))
      })
  }
}

export function errorUpdateAudience(error: any) {
  return {
    type: ERROR_UPDATE_AUDIENCE,
    error,
  }
}

export function requestUpdateAudience(profileId: string, audienceId: string) {
  return {
    type: REQUEST_UPDATE_AUDIENCE,
    profileId,
  }
}

export function successUpdateAudience(response: any) {
  return {
    type: SUCCESS_UPDATE_AUDIENCE,
    response,
  }
}

export function updateAudience(
  profileId: string,
  audienceId: string,
  name: string,
  type: any,
  min: number,
  max: number,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUpdateAudience(profileId, audienceId))
    return updateAudienceApi(profileId, audienceId, name, type, min, max, token)
      .then((response: any) => {
        dispatch(successUpdateAudience(response))
      })
      .catch((error: any) => {
        dispatch(errorUpdateAudience(error))
      })
  }
}

export function errorDeleteAudience(error: any) {
  return {
    type: ERROR_DELETE_AUDIENCE,
    error,
  }
}

export function requestDeleteAudience(profileId: string, audienceId: string) {
  return {
    type: REQUEST_DELETE_AUDIENCE,
    profileId,
  }
}

export function successDeleteAudience(response: any) {
  return {
    type: SUCCESS_DELETE_AUDIENCE,
    response,
  }
}

export function deleteAudience(profileId: string, audienceId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestDeleteAudience(profileId, audienceId))
    return deleteAudienceApi(profileId, audienceId, token)
      .then((response: any) => {
        dispatch(successDeleteAudience(response))
      })
      .catch((error: any) => {
        dispatch(errorDeleteAudience(error))
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchHubs(error: any) {
  return {
    type: ERROR_FETCH_HUBS,
    error,
  }
}

export function requestHubs(profileId: string) {
  return {
    type: REQUEST_HUBS,
    profileId,
  }
}

export function receiveHubs(response: any) {
  return {
    type: RECEIVE_HUBS,
    response,
  }
}

export function fetchHubs(
  profile: any,
  token: string,
  orderby: any,
  limit: any,
  order: any,
  search: string,
  page: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestHubs(profile))
    return getHubs(profile, token, orderby, limit, order, search, page)
      .then((response: any) => {
        dispatch(receiveHubs(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchHubs(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchDomainPages(error: any, hostId: string) {
  return {
    type: ERROR_FETCH_DOMAIN_PAGES,
    error,
    hostId,
  }
}

export function requestDomainPages(profileId: string, hostId: string) {
  return {
    type: REQUEST_DOMAIN_PAGES,
    profileId,
    hostId,
  }
}

export function receiveDomainPages(response: any, uniqueDomainPagesId: string, hostId: string) {
  return {
    type: RECEIVE_DOMAIN_PAGES,
    response,
    uniqueDomainPagesId,
    hostId,
  }
}

export function fetchDomainPages(
  hostId: string,
  profile: string,
  token: any,
  uniqueDomainPagesId: string,
  orderby: string,
  limit: number,
  order: string,
  search: string,
  page: number
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestDomainPages(profile, hostId))
    return getDomainPages(hostId, profile, token, orderby, limit, order, search, page)
      .then((response: any) => {
        dispatch(receiveDomainPages(response, uniqueDomainPagesId, hostId))
      })
      .catch((error: any) => {
        dispatch(errorFetchDomainPages(error, hostId))
        throw error
      })
  }
}

export function clearDomainPages(hostId: string) {
  return {
    type: CLEAR_DOMAIN_PAGES,
    hostId,
  }
}

export function cleanDomainPages(hostId: string) {
  return (dispatch: AppDispatch) => {
    dispatch(clearDomainPages(hostId))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorEditHubTemplate(error: any) {
  return {
    type: ERROR_EDIT_HUB_TEMPLATE,
    error,
  }
}

export function requestEditHubTemplate() {
  return {
    type: REQUEST_EDIT_HUB_TEMPLATE,
  }
}

export function editedHubTemplate(response: any) {
  return {
    type: EDITED_HUB_TEMPLATE,
    response,
  }
}

export function editHubTemplate(profile: any, token: string, obj: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestEditHubTemplate())
    return editHubTemplateApi(profile, token, obj)
      .then((response: any) => {
        dispatch(editedHubTemplate(response))
      })
      .catch((error: any) => {
        dispatch(errorEditHubTemplate(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function editHubTemplateInternalRedux(hub: any) {
  return {
    type: EDIT_HUB_TEMPLATE_INTERNAL_REDUX,
    hub: deepLevelHubObj(hub),
  }
}

export function editHubTemplateRedux(hub: any) {
  return (dispatch: AppDispatch) => {
    dispatch(editHubTemplateInternalRedux(hub))
  }
}
/* -- * -- * -- * -- * -- * -- * -- * -- */

export function replaceExperience(experience: any) {
  return {
    type: REPLACE_EXPERIENCE,
    experience,
  }
}

export function editExperience(experience: any) {
  return (dispatch: AppDispatch) => {
    dispatch(replaceExperience(experience))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearAndEditHubTemplateRedux(hub: any) {
  return (dispatch: AppDispatch) => {
    dispatch(clearPasteObjectEM())
    dispatch(cleanServerCopyPasteEM())
    dispatch(cleanCopyPasteEM())
    dispatch(clearCopyObjectEM())
    dispatch(editHubTemplateInternalRedux(hub))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearHubHandlerParentId() {
  return {
    type: CLEAR_HUB_HANDLER_PARENTID,
  }
}

export function cleanHubHandlerParentId() {
  return (dispatch: AppDispatch) => {
    dispatch(clearHubHandlerParentId())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchingFormulasList(error: any) {
  return {
    type: ERROR_FETCHING_FORMULAS_LIST,
    error,
  }
}

export function requestFormulasList() {
  return {
    type: REQUEST_FORMULAS_LIST,
  }
}

export function receiveFormulasList(response: any) {
  return {
    type: RECEIVE_FORMULAS_LIST,
    response,
  }
}

export function getFormulasList(
  profile: any,
  token: string,
  orderby: any,
  limit: any,
  order: any,
  search: string,
  page: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestFormulasList())
    return fetchFormulas(profile, token, orderby, limit, order, search, page)
      .then((response: any) => {
        dispatch(receiveFormulasList(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchingFormulasList(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearFormulasList() {
  return {
    type: CLEAR_FORMULAS_LIST,
  }
}

export function cleanFirmulasList() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFormulasList())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function replaceFinderObject(object: any) {
  return {
    type: REPLACE_FINDER_OBJECT,
    object,
  }
}

export function updateFinderObject(object: any) {
  return (dispatch: AppDispatch) => {
    dispatch(replaceFinderObject(object))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function toggleFinderPreview(value: any, key: any, params: any) {
  return {
    type: TOGGLE_FINDER_PREVIEW,
    value,
    key,
    params,
  }
}

export function changeFinderPreview(value: any, key: any, params: any) {
  return (dispatch: AppDispatch) => {
    dispatch(toggleFinderPreview(value, key, params))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function experienceManagerEventPile(event: any) {
  return {
    type: EXPERIENCE_MANAGER_EVENT_PILE,
    event,
  }
}

export function eventPileExperienceManager(event: any) {
  return (dispatch: AppDispatch) => {
    dispatch(experienceManagerEventPile(event))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearComponentsCategoriesExperienceManager() {
  return {
    type: CLEAR_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER,
  }
}

export function cleanComponentsCategoriesExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(clearComponentsCategoriesExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearExperienceManagerEventPile() {
  return {
    type: CLEAR_EXPERIENCE_MANAGER_EVENT_PILE,
  }
}

export function cleanEventPileExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(clearExperienceManagerEventPile())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorEditingExperienceManagerTemplate(error: any) {
  return {
    type: ERROR_EDITING_EXPERIENCE_MANAGER_TEMPLATE,
    error,
  }
}

export function requestEditExperienceManagerTemplate() {
  return {
    type: REQUEST_EDIT_EXPERIENCE_MANAGER_TEMPLATE,
  }
}

export function successEditExperienceManagerTemplate(response: any, step: any, object: any, event: any) {
  return {
    type: SUCCESS_EDIT_EXPERIENCE_MANAGER_TEMPLATE,
    response,
    step,
    object,
    event,
  }
}

export function editExperienceManagerTemplate(
  profileId: any,
  object: any,
  step: any,
  event: any,
  publish: any,
  eventId: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestEditExperienceManagerTemplate())
    return editExperienceManagerTemplateApi(profileId, object, publish, eventId, token)
      .then((response: any) => {
        dispatch(successEditExperienceManagerTemplate(response, step, object, event))
      })
      .catch((error: any) => {
        dispatch(errorEditingExperienceManagerTemplate(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchingComponentsByCatFam(error: any) {
  return {
    type: ERROR_FETCHING_COMPONENTS_BY_CAT_FAM,
    error,
  }
}

export function requestComponentsByCatFam() {
  return {
    type: REQUEST_COMPONENTS_BY_CAT_FAM,
  }
}

export function successFetchComponentsByCatFam(response: any) {
  return {
    type: SUCCESS_FETCH_COMPONENTS_BY_CAT_FAM,
    response,
  }
}

export function fetchComponentsByCatFam(
  profileId: string,
  categories: any,
  families: any,
  search: string,
  token: string,
  orderby: any,
  limit: any,
  order: any,
  page: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestComponentsByCatFam())
    return fetchComponentsCatFam(profileId, categories, families, search, token, orderby, limit, order, page)
      .then((response: any) => {
        dispatch(successFetchComponentsByCatFam(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchingComponentsByCatFam(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearComponentsListByCatFam() {
  return {
    type: CLEAR_COMPONENTS_LIST_BY_CAT_FAM,
  }
}

export function clearComponentsList() {
  return (dispatch: AppDispatch) => {
    dispatch(clearComponentsListByCatFam())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function editingFinderObjectExperienceManager(event: any, object: any, finderTab: any, selectFinderObject: any) {
  return {
    type: EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER,
    event,
    object,
    finderTab,
    selectFinderObject,
  }
}

export function finderTypePublicPageReducer(event: any, object: any, finderTab: any, selectFinderObject: any) {
  return {
    type: FINDER_TYPE_PUBLIC_PAGE_REDUCER,
    event,
    object,
    finderTab,
    selectFinderObject,
  }
}

export function editFinderObjectExperienceManager(
  event: any,
  object: any,
  finderTab: string | undefined = undefined,
  selectFinderObject = true
) {
  return (dispatch: AppDispatch) => {
    dispatch(editingFinderObjectExperienceManager(event, object, finderTab, selectFinderObject))
    dispatch(finderTypePublicPageReducer(event, object, finderTab, selectFinderObject))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearEditingFinderObjectExperienceManager() {
  return {
    type: CLEAR_EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER,
  }
}

export function clearEditFinderObjectExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(clearEditingFinderObjectExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorInsertingObjectExperienceManager(error: any) {
  return {
    type: ERROR_INSERTING_OBJECT_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestInsertOjectExperienceManager() {
  return {
    type: REQUEST_INSERT_OBJECT_EXPERIENCE_MANAGER,
  }
}

export function successInsertObjectExperienceManager(
  response: any,
  step: any,
  object: any,
  event: any,
  hubUuid: string
) {
  return {
    type: SUCCESS_INSERT_OBJECT_EXPERIENCE_MANAGER,
    response,
    step,
    object,
    event,
    hubUuid,
  }
}

export function insertObjectExperienceManager(
  profileId: string,
  object: any,
  step: any,
  event: any,
  publish: any,
  eventId: string,
  token: any,
  hubUuid: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestInsertOjectExperienceManager())
    return createObject(profileId, object, publish, eventId, token, hubUuid)
      .then((response: any) => {
        dispatch(successInsertObjectExperienceManager(response, step, object, event, hubUuid))
      })
      .catch((error: any) => {
        dispatch(errorInsertingObjectExperienceManager(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorCreateObjectExperienceManager(error: any) {
  return {
    type: ERROR_CREATE_OBJECT_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestCreateOjectExperienceManager() {
  return {
    type: REQUEST_CREATE_OBJECT_EXPERIENCE_MANAGER,
  }
}

export function successCreateObjectExperienceManager(response: any, composedId: any, addBehaviourType: any) {
  return {
    type: SUCCESS_CREATE_OBJECT_EXPERIENCE_MANAGER,
    response,
    composedId,
    addBehaviourType,
  }
}

export function cleanCreateObjectExperienceManager() {
  return {
    type: CLEAR_CREATE_OBJECT_EXPERIENCE_MANAGER,
  }
}

export function createObjectExperienceManager(
  profileId: string,
  obj: any,
  eventId: string,
  token: string,
  composedId = undefined,
  addBehaviourType = "inside"
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestCreateOjectExperienceManager())
    return createObjectServerSide(profileId, obj, eventId, token)
      .then((response: any) => {
        dispatch(successCreateObjectExperienceManager(response, composedId, addBehaviourType))
      })
      .catch((error: any) => {
        dispatch(errorCreateObjectExperienceManager(error))
        throw error
      })
  }
}

export function clearCreateObjectExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanCreateObjectExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorInsertingFirstObjectExperienceManager(error: any) {
  return {
    type: ERROR_INSERTING_FIRST_OBJECT_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestInsertFirstOjectExperienceManager() {
  return {
    type: REQUEST_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER,
  }
}

export function successInsertFirstObjectExperienceManager(response: any) {
  return {
    type: SUCCESS_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER,
    response,
  }
}

export function insertFirstObjectExperienceManager(
  profileId: string,
  hostId: string,
  siteId: string,
  uuid: string,
  order: any,
  uri: string,
  name: string,
  articleLayout: any,
  pageTemplate: any,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestInsertFirstOjectExperienceManager())
    return createFirstObject(
      profileId,
      hostId,
      siteId,
      uuid,
      (order = 0),
      uri,
      name,
      articleLayout,
      pageTemplate,
      token
    )
      .then((response: any) => {
        dispatch(successInsertFirstObjectExperienceManager(response))
      })
      .catch((error: any) => {
        dispatch(errorInsertingFirstObjectExperienceManager(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearInsertFirstObjectExperienceManager() {
  return {
    type: CLEAR_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER,
  }
}

export function cleanUnsertFirstObjectExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(clearInsertFirstObjectExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorAddingFormulaToObject(error: any) {
  return {
    type: ERROR_ADDING_FORMULA_TO_OBJECT,
    error,
  }
}

export function requestAddFormulaToObject() {
  return {
    type: REQUEST_ADD_FORMULA_TO_OBJECT,
  }
}

export function successAddFormulaToObject(response: any, step: any, object: any, event: any) {
  return {
    type: SUCCESS_ADD_FORMULA_TO_OBJECT,
    response,
    step,
    object,
    event,
  }
}

export function addFormulaToObject(
  profileId: any,
  object: any,
  step: any,
  event: any,
  publish: any,
  eventId: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestAddFormulaToObject())
    return addFormulaToObjectApi(profileId, object, publish, eventId, token)
      .then((response: any) => {
        dispatch(successAddFormulaToObject(response, step, object, event))
      })
      .catch((error: any) => {
        dispatch(errorAddingFormulaToObject(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorChangeComponentExperienceManager(error: any) {
  return {
    type: ERROR_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestChangeComponentExperienceManager() {
  return {
    type: REQUEST_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  }
}

export function successChangeComponentExperienceManager(response: any) {
  return {
    type: SUCCESS_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
    response,
  }
}

export function cleanChangeComponentExperienceManager() {
  return {
    type: CLEAR_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  }
}

export function changeComponentExperienceManager(profileId: string, obj: any, eventId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestChangeComponentExperienceManager())
    return addComponentToObjectServerSide(profileId, obj, eventId, token)
      .then((response: any) => {
        dispatch(successChangeComponentExperienceManager(response))
      })
      .catch((error: any) => {
        dispatch(errorChangeComponentExperienceManager(error))
        throw error
      })
  }
}

export function clearChangeComponentExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanChangeComponentExperienceManager())
  }
}
/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorApplyChangeComponentExperienceManager(error: any) {
  return {
    type: ERROR_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestApplyChangeComponentExperienceManager() {
  return {
    type: REQUEST_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  }
}

export function successApplyChangeComponentExperienceManager(response: any) {
  return {
    type: SUCCESS_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
    response,
  }
}

export function cleanApplyChangeComponentExperienceManager() {
  return {
    type: CLEAR_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  }
}

export function applyChangeComponentExperienceManager(profileId: string, obj: any, eventId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestApplyChangeComponentExperienceManager())
    return applyComponentToObjectServerSide(profileId, obj, eventId, token)
      .then((response: any) => {
        dispatch(successApplyChangeComponentExperienceManager(response))
      })
      .catch((error: any) => {
        dispatch(errorApplyChangeComponentExperienceManager(error))
        throw error
      })
  }
}

export function clearApplyChangeComponentExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanApplyChangeComponentExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUndoChangeComponentExperienceManager(error: any) {
  return {
    type: ERROR_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestUndoChangeComponentExperienceManager() {
  return {
    type: REQUEST_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  }
}

export function successUndoChangeComponentExperienceManager(response: any) {
  return {
    type: SUCCESS_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
    response,
  }
}

export function cleanUndoChangeComponentExperienceManager() {
  return {
    type: CLEAR_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER,
  }
}

export function undoChangeComponent(profileId: string, obj: any, eventId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUndoChangeComponentExperienceManager())
    return undoAddComponentToObject(profileId, obj, eventId, token)
      .then((response: any) => {
        dispatch(successUndoChangeComponentExperienceManager(response))
      })
      .catch((error: any) => {
        dispatch(errorUndoChangeComponentExperienceManager(error))
        throw error
      })
  }
}

export function clearUndoChangeComponentExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanUndoChangeComponentExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorValidatingUri(error: any) {
  return {
    type: ERROR_VALIDATING_URI,
    error,
  }
}

export function requestValidateUri() {
  return {
    type: REQUEST_VALIDATE_URI,
  }
}

export function successValidateUri(response: any) {
  return {
    type: SUCCESS_VALIDATE_URI,
    response,
  }
}

export function validateUri(profileId: string, objectId: string, uri: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestValidateUri())
    return validateUriApi(profileId, objectId, uri, token)
      .then((response: any) => {
        dispatch(successValidateUri(response))
      })
      .catch((error: any) => {
        dispatch(errorValidatingUri(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorValidatingFirstLevelUri(error: any) {
  return {
    type: ERROR_VALIDATING_FIRST_LEVEL_URI,
    error,
  }
}

export function requestValidateFirstLevelUri() {
  return {
    type: REQUEST_VALIDATE_FIRST_LEVEL_URI,
  }
}

export function successValidateFirstLevelUri(response: any) {
  return {
    type: SUCCESS_VALIDATE_FIRST_LEVEL_URI,
    response,
  }
}

export function validateFirstLevelUri(profileId: string, hostId: string, uri: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestValidateFirstLevelUri())
    return validateFirstLevelUriApi(profileId, hostId, uri, token)
      .then((response: any) => {
        dispatch(successValidateFirstLevelUri(response))
      })
      .catch((error: any) => {
        dispatch(errorValidatingFirstLevelUri(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearValidatedFirstLevelUri() {
  return {
    type: CLEAR_VALIDATED_FIRST_LEVEL_URI,
  }
}

export function clearValidFirstLevelUri() {
  return (dispatch: AppDispatch) => {
    dispatch(clearValidatedFirstLevelUri())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorValidatingObjectUri(error: any) {
  return {
    type: ERROR_VALIDATING_OBJECT_URI,
    error,
  }
}

export function requestValidateObjectUri() {
  return {
    type: REQUEST_VALIDATE_OBJECT_URI,
  }
}

export function successValidateObjectUri(response: any) {
  return {
    type: SUCCESS_VALIDATE_OBJECT_URI,
    response,
  }
}

export function validateObjectUri(profileId: string, objectId: string, uri: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestValidateObjectUri())
    return validateObjectUriApi(profileId, objectId, uri, token)
      .then((response: any) => {
        dispatch(successValidateObjectUri(response))
      })
      .catch((error: any) => {
        dispatch(errorValidatingObjectUri(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearValidatedObjectUri() {
  return {
    type: CLEAR_VALIDATED_OBJECT_URI,
  }
}

export function clearValidObjectUri() {
  return (dispatch: AppDispatch) => {
    dispatch(clearValidatedObjectUri())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearValidatedUri() {
  return {
    type: CLEAR_VALIDATED_URI,
  }
}

export function clearValidUri() {
  return (dispatch: AppDispatch) => {
    dispatch(clearValidatedUri())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearFetchFormulaContentEM() {
  return {
    type: CLEAR_FETCH_FORMULA_CONTENT_EM,
  }
}

export function cleanFetchFormulaContentEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchFormulaContentEM())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function deleteEventExperienceManager(command: any) {
  return {
    type: DELETE_EVENT_EXPERIENCE_MANAGER,
    command,
  }
}

export function removeEventPileExperienceManager(command: any) {
  return (dispatch: AppDispatch) => {
    dispatch(deleteEventExperienceManager(command))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchFormulaContentEM(error: any) {
  return {
    type: ERROR_FETCH_FORMULA_CONTENT_EM,
    error,
  }
}

export function requestFormulaContentEM() {
  return {
    type: REQUEST_FORMULA_CONTENT_EM,
  }
}

export function successFetchFormulaContentEM(response: any) {
  return {
    type: SUCCESS_FETCH_FORMULA_CONTENT_EM,
    response,
  }
}

export function fetchFormulaContentEM(profileId: string, formulaId: string, token: string, until = "") {
  return (dispatch: AppDispatch) => {
    dispatch(requestFormulaContentEM())
    return fetchFormulaContentEMApi(profileId, formulaId, token, until)
      .then((response: any) => {
        dispatch(successFetchFormulaContentEM(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchFormulaContentEM(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchFormulaContentPreviewEM(error: any) {
  return {
    type: ERROR_FETCH_FORMULA_CONTENT_PREVIEW_EM,
    error,
  }
}

export function requestFormulaContentPreviewEM() {
  return {
    type: REQUEST_FORMULA_CONTENT_PREVIEW_EM,
  }
}

export function successFetchFormulaContentPreviewEM(response: any) {
  return {
    type: SUCCESS_FETCH_FORMULA_CONTENT_PREVIEW_EM,
    response,
  }
}

export function fetchFormulaContentPreviewEM(profileId: string, formulaId: string, token: string, until = "") {
  return (dispatch: AppDispatch) => {
    dispatch(requestFormulaContentPreviewEM())
    return fetchFormulaContentEMApi(profileId, formulaId, token, until)
      .then((response: any) => {
        dispatch(successFetchFormulaContentPreviewEM(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchFormulaContentPreviewEM(error))
        throw error
      })
  }
}

export function clearFetchFormulaContentPreviewEM() {
  return {
    type: CLEAR_FETCH_FORMULA_CONTENT_PREVIEW_EM,
  }
}

export function cleanFetchFormulaContentPreviewEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchFormulaContentPreviewEM())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */
export function clearFetchMoreFormulaContentPreviewEM() {
  return {
    type: CLEAR_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM,
  }
}

export function cleanFetchMoreFormulaContentPreviewEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchMoreFormulaContentPreviewEM())
  }
}

export function errorFetchMoreFormulaContentPreviewEM(error: any) {
  return {
    type: ERROR_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM,
    error,
  }
}

export function requestFetchMoreFormulaContentPreviewEM() {
  return {
    type: REQUEST_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM,
  }
}

export function successFetchMoreFormulaContentPreviewEM(response: any) {
  return {
    type: SUCCESS_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM,
    response,
  }
}

export function fetchMoreFormulaContentPreviewEM(profileId: string, formulaId: string, token: string, until = "") {
  return (dispatch: AppDispatch) => {
    dispatch(requestFetchMoreFormulaContentPreviewEM())
    return fetchMoreFormulaContentEM(profileId, formulaId, token, until)
      .then((response: any) => {
        dispatch(successFetchMoreFormulaContentPreviewEM(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchMoreFormulaContentPreviewEM(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */
export function clearFetchMorePostSearchPreviewEM() {
  return {
    type: CLEAR_FETCH_MORE_POST_SEARCH_PREVIEW_EM,
  }
}

export function cleanFetchMorePostSearchPreviewEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchMorePostSearchPreviewEM())
  }
}

export function errorFetchMorePostSearchPreviewEM(error: any) {
  return {
    type: ERROR_FETCH_MORE_POST_SEARCH_PREVIEW_EM,
    error,
  }
}

export function requestFetchMorePostSearchPreviewEM() {
  return {
    type: REQUEST_FETCH_MORE_POST_SEARCH_PREVIEW_EM,
  }
}

export function successFetchMorePostSearchPreviewEM(response: any) {
  return {
    type: SUCCESS_FETCH_MORE_POST_SEARCH_PREVIEW_EM,
    response,
  }
}

export function fetchMorePostSearchPreviewEM(
  profile: any,
  token: string,
  orderby: any,
  limit: any,
  order: any,
  searchTerm: any,
  until: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestFetchMorePostSearchPreviewEM())
    return searchByPostsApi(profile, token, orderby, limit, order, searchTerm, until)
      .then((response: any) => {
        dispatch(successFetchMorePostSearchPreviewEM(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchMorePostSearchPreviewEM(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearFetchObjectFormulaContentEM() {
  return {
    type: CLEAR_FETCH_OBJECT_FORMULA_CONTENT_EM,
  }
}

export function cleanFetchObjectFormulaContentEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchObjectFormulaContentEM())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function openEditorModal(open: boolean, id: any) {
  return {
    type: OPEN_EDITOR_MODAL,
  }
}

export function editorModalState(open: boolean, id: any) {
  return (dispatch: AppDispatch) => {
    dispatch(openEditorModal(open, id))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorCreateFormulaByPostsQueryExperienceManager(error: any) {
  return {
    type: ERROR_CREATE_FORMULA_BY_POSTS_QUERY_EM,
    error,
  }
}

export function requestCreateFormulaByPostsQueryExperienceManager() {
  return {
    type: REQUEST_CREATE_FORMULA_BY_POSTS_QUERY_EM,
  }
}

export function successCreateFormulaByPostsQueryExperienceManager(response: any, step: any, object: any, event: any) {
  return {
    type: SUCCESS_CREATE_FORMULA_BY_POSTS_QUERY_EM,
    response,
    step,
    object,
    event,
  }
}

export function createFormulaByPostsQueryExperienceManager(
  profileId: any,
  object: any,
  step: any,
  event: any,
  publish: any,
  eventId: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestCreateFormulaByPostsQueryExperienceManager())
    return submitFormulaWithQuery(profileId, object, publish, eventId, token)
      .then((response: any) => {
        dispatch(successCreateFormulaByPostsQueryExperienceManager(response, step, object, event))
      })
      .catch((error: any) => {
        dispatch(errorCreateFormulaByPostsQueryExperienceManager(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorCreateDirectFormulaByPostsQueryExperienceManager(error: any) {
  return {
    type: ERROR_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM,
    error,
  }
}

export function requestCreateDirectFormulaByPostsQueryExperienceManager() {
  return {
    type: REQUEST_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM,
  }
}

export function successCreateDirectFormulaByPostsQueryExperienceManager(
  response: any,
  step: any,
  object: any,
  event: any
) {
  return {
    type: SUCCESS_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM,
    response,
    step,
    object,
    event,
  }
}

export function createDirectFormulaByPostsQueryExperienceManager(
  profileId: any,
  object: any,
  step: any,
  event: any,
  publish: any,
  eventId: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestCreateDirectFormulaByPostsQueryExperienceManager())
    return submitFormulaWithQuery(profileId, object, publish, eventId, token)
      .then((response: any) => {
        dispatch(successCreateDirectFormulaByPostsQueryExperienceManager(response, step, object, event))
      })
      .catch((error: any) => {
        dispatch(errorCreateDirectFormulaByPostsQueryExperienceManager(error))
        throw error
      })
  }
}

export function clearCreateDirectFormulaByPostsQueryExperienceManager() {
  return {
    type: CLEAR_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM,
  }
}

export function cleanCreateDirectFormulaByPostsQueryExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(clearCreateDirectFormulaByPostsQueryExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorReOrderObjectsExperienceManager(error: any) {
  return {
    type: ERROR_REORDER_OBJECTS_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestReOrderObjectsExperienceManager() {
  return {
    type: REQUEST_REORDER_OBJECTS_EXPERIENCE_MANAGER,
  }
}

export function successReOrderObjectsExperienceManager(response: any, object: any) {
  return {
    type: SUCCESS_REORDER_OBJECTS_EXPERIENCE_MANAGER,
    response,
    object,
  }
}

export function reOrderObjectsExperienceManager(profileId: any, object: any, eventId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestReOrderObjectsExperienceManager())
    return reOrderObjects(profileId, object, eventId, token)
      .then((response: any) => {
        dispatch(successReOrderObjectsExperienceManager(response, object))
      })
      .catch((error: any) => {
        dispatch(errorReOrderObjectsExperienceManager(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorMoveObjectsExperienceManager(error: any) {
  return {
    type: ERROR_MOVE_OBJECTS_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestMoveObjectsExperienceManager() {
  return {
    type: REQUEST_MOVE_OBJECTS_EXPERIENCE_MANAGER,
  }
}

export function successMoveObjectsExperienceManager(response: any, object: any) {
  return {
    type: SUCCESS_MOVE_OBJECTS_EXPERIENCE_MANAGER,
    response,
    object,
  }
}

export function moveObjectExperienceManager(profileId: string, object: any, eventId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestMoveObjectsExperienceManager())
    return moveObject(profileId, object, eventId, token)
      .then((response: any) => {
        dispatch(successMoveObjectsExperienceManager(response, object))
      })
      .catch((error: any) => {
        dispatch(errorMoveObjectsExperienceManager(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUpdatingObjectExperienceManager(error: any) {
  return {
    type: ERROR_UPDATING_OBJECT_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestUpdateOjectExperienceManager(objectUuid: any) {
  return {
    type: REQUEST_UPDATE_OBJECT_EXPERIENCE_MANAGER,
    objectUuid,
  }
}

export function successUpdateObjectExperienceManager(response: any, object: any) {
  return {
    type: SUCCESS_UPDATE_OBJECT_EXPERIENCE_MANAGER,
    response,
    object,
  }
}

export function updateObjectExperienceManager(profileId: string, object: any, eventId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUpdateOjectExperienceManager(object.objectUuid))
    return updateObject(profileId, object, eventId, token)
      .then((response: any) => {
        dispatch(successUpdateObjectExperienceManager(response, object))
      })
      .catch((error: any) => {
        dispatch(errorUpdatingObjectExperienceManager(error))
        throw error
      })
  }
}

export function clearUpdateObjectExperienceManager() {
  return {
    type: CLEAR_UPDATE_OBJECT_EXPERIENCE_MANAGER,
  }
}

export function cleanUpdateObjectExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(clearUpdateObjectExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUpdateObjectContentExperienceManager(error: any) {
  return {
    type: ERROR_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestUpdateObjectContentExperienceManager(objectUuid: any) {
  return {
    type: REQUEST_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER,
    objectUuid,
  }
}

export function successUpdateObjectContentExperienceManager(response: any, object: any) {
  return {
    type: SUCCESS_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER,
    response,
    object,
  }
}

export function updateObjectContentExperienceManager(profileId: string, object: any, eventId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUpdateObjectContentExperienceManager(object.objectUuid))
    return updateObjectContent(profileId, object, eventId, token)
      .then((response: any) => {
        dispatch(successUpdateObjectContentExperienceManager(response, object))
      })
      .catch((error: any) => {
        dispatch(errorUpdateObjectContentExperienceManager(error))
        throw error
      })
  }
}

export function clearUpdateObjectContentExperienceManager() {
  return {
    type: CLEAR_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER,
  }
}

export function cleanUpdateObjectContentExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(clearUpdateObjectContentExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUpdateObjectSettingsExperienceManager(error: any) {
  return {
    type: ERROR_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestUpdateObjectSettingsExperienceManager(objectUuid: any) {
  return {
    type: REQUEST_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER,
    objectUuid,
  }
}

export function successUpdateObjectSettingsExperienceManager(response: any, object: any) {
  return {
    type: SUCCESS_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER,
    response,
    object,
  }
}

export function updateObjectSettingsExperienceManager(profileId: string, object: any, eventId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUpdateObjectSettingsExperienceManager(object.objectUuid))
    return updateObjectSettings(profileId, object, eventId, token)
      .then((response: any) => {
        dispatch(successUpdateObjectSettingsExperienceManager(response, object))
      })
      .catch((error: any) => {
        dispatch(errorUpdateObjectSettingsExperienceManager(error))
        throw error
      })
  }
}

export function clearUpdateObjectSettingsExperienceManager() {
  return {
    type: CLEAR_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER,
  }
}

export function cleanUpdateObjectSettingsExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(clearUpdateObjectContentExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearUpdatePageObjectExperienceManager() {
  return {
    type: CLEAR_UPDATE_PAGE_OBJECT_EXPERIENCE_MANAGER,
  }
}

export function cleanUpdatePageObjectExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(clearUpdatePageObjectExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */
export function requestUpdatePageSettings(pageId: string) {
  return {
    type: REQUEST_UPDATE_PAGE_SETTINGS,
    pageId,
  }
}

export function receiveUpdatePageSettings(response: any) {
  return {
    type: RECEIVE_UPDATE_PAGE_SETTINGS,
    response,
  }
}

export function errorUpdatePageSettings(error: any) {
  return {
    type: ERROR_UPDATE_PAGE_SETTINGS,
    error,
  }
}

export function updatePageSettings(
  pageId: string,
  settings: { [key: string]: string },
  profile: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUpdatePageSettings(pageId))
    return updatePageSettingsApi(pageId, settings, profile, token)
      .then((response: any) => {
        dispatch(receiveUpdatePageSettings(response))
      })
      .catch((error: any) => {
        dispatch(errorUpdatePageSettings(error))
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorDeletingObjectFormulaExperienceManager(error: any) {
  return {
    type: ERROR_DELETING_OBJECT_FORMULA_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestDeleteObjectFormulaExperienceManager() {
  return {
    type: REQUEST_DELETE_OBJECT_FORMULA_EXPERIENCE_MANAGER,
  }
}

export function successDeleteObjectFormulaExperienceManager(response: any, step: any, object: any, event: any) {
  return {
    type: SUCCESS_DELETE_OBJECT_FORMULA_EXPERIENCE_MANAGER,
    response,
    step,
    object,
    event,
  }
}

export function deleteObjectFormulaExperienceManager(
  profileId: any,
  object: any,
  step: any,
  event: any,
  publish: any,
  eventId: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestDeleteObjectFormulaExperienceManager())
    return deleteObjectFormula(profileId, object, publish, eventId, token)
      .then((response: any) => {
        dispatch(successDeleteObjectFormulaExperienceManager(response, step, object, event))
      })
      .catch((error: any) => {
        dispatch(errorDeletingObjectFormulaExperienceManager(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorDeletingObjectExperienceManager(error: any) {
  return {
    type: ERROR_DELETING_OBJECT_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestDeleteObjectExperienceManager() {
  return {
    type: REQUEST_DELETE_OBJECT_EXPERIENCE_MANAGER,
  }
}

export function successDeleteObjectExperienceManager(response: any, step: any, object: any, event: any) {
  return {
    type: SUCCESS_DELETE_OBJECT_EXPERIENCE_MANAGER,
    response,
    step,
    object,
    event,
  }
}

export function deleteObjectExperienceManager(
  profileId: any,
  object: any,
  step: any,
  event: any,
  publish: any,
  eventId: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestDeleteObjectExperienceManager())
    return deleteObject(profileId, object, publish, eventId, token)
      .then((response: any) => {
        dispatch(successDeleteObjectExperienceManager(response, step, object, event))
      })
      .catch((error: any) => {
        dispatch(errorDeletingObjectExperienceManager(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorDeleteEntireExperienceManager(error: any) {
  return {
    type: ERROR_DELETE_ENTIRE_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestDeleteEntireExperienceManager() {
  return {
    type: REQUEST_DELETE_ENTIRE_EXPERIENCE_MANAGER,
  }
}

export function successDeleteEntireExperienceManager(response: any) {
  return {
    type: SUCCESS_DELETE_ENTIRE_EXPERIENCE_MANAGER,
    response,
  }
}

export function deleteEntireExperienceManager(profile: any, objectId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestDeleteEntireExperienceManager())
    return deleteEntireExperience(profile, objectId, token)
      .then((response: any) => {
        dispatch(successDeleteEntireExperienceManager(response))
      })
      .catch((error: any) => {
        dispatch(errorDeleteEntireExperienceManager(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function cleardeleteEntireExperienceManager() {
  return {
    type: CLEAR_DELETE_ENTIRE_EXPERIENCE_MANAGER,
  }
}

export function cleanDeleteEntireExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(cleardeleteEntireExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchComponentsCategoriesExperienceManager(error: any) {
  return {
    type: ERROR_FETCH_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestComponentsCategoriesExperienceManager() {
  return {
    type: REQUEST_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER,
  }
}

export function successFetchComponentsCategoriesExperienceManager(response: any) {
  return {
    type: SUCCESS_FETCH_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER,
    response,
  }
}

export function fetchComponentsCategoriesExperienceManager(
  profile: any,
  token: string,
  orderby: any,
  limit: any,
  order: any,
  search: string,
  page: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestComponentsCategoriesExperienceManager())
    return componentsCategories(profile, token, orderby, limit, order, search, page)
      .then((response: any) => {
        dispatch(successFetchComponentsCategoriesExperienceManager(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchComponentsCategoriesExperienceManager(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearSelectedComponentFamily() {
  return {
    type: CLEAR_SELECTED_COMPONENT_FAMILY,
  }
}

export function cleanSelectedComponentFamily() {
  return (dispatch: AppDispatch) => {
    dispatch(clearSelectedComponentFamily())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function defaultObjectColorsExperienceManager(colorsObj: any) {
  return {
    type: DEFAULT_OBJECT_COLORS_EXPERIENCE_MANAGER,
    colorsObj,
  }
}

export function topLevelObjectColorsExperienceManager(colorsObj: any) {
  return (dispatch: AppDispatch) => {
    dispatch(defaultObjectColorsExperienceManager(colorsObj))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorSubmitFaviconNewExperience(error: any) {
  return {
    type: ERROR_SUBMIT_FAVICON_NEW_EXPERIENCE,
    error,
  }
}

export function requestSubmitFaviconNewExperience() {
  return {
    type: REQUEST_SUBMIT_FAVICON_NEW_EXPERIENCE,
  }
}

export function successSubmitFaviconNewExperience(response: any) {
  return {
    type: SUCCESS_SUBMIT_FAVICON_NEW_EXPERIENCE,
    response,
  }
}

export function submitFaviconNewExperience(
  profile: any,
  favicon: any,
  apple: any,
  microsoft: any,
  android: any,
  seoImage: any,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSubmitFaviconNewExperience())
    return submitFavicon(profile, favicon, apple, microsoft, android, seoImage, token)
      .then((response: any) => {
        dispatch(successSubmitFaviconNewExperience(response))
      })
      .catch((error: any) => {
        dispatch(errorSubmitFaviconNewExperience(error))
        throw error
      })
  }
}

export function clearFaviconNewExperience() {
  return {
    type: CLEAR_FAVICON_NEW_EXPERIENCE,
  }
}

export function cleanFaviconNewExperience() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFaviconNewExperience())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorCheckCreatedHub(error: any) {
  return {
    type: ERROR_CHECK_CREATED_HUB,
    error,
  }
}

export function requestCheckCreatedHub() {
  return {
    type: REQUEST_CHECK_CREATED_HUB,
  }
}

export function successCheckCreatedHub(response: any) {
  return {
    type: SUCCESS_CHECK_CREATED_HUB,
    response,
  }
}

export function checkCreatedHub(hubId: string, local: any, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestCheckCreatedHub())
    return checkHub(local, hubId, token)
      .then((response: any) => {
        dispatch(successCheckCreatedHub(response))
      })
      .catch((error: any) => {
        dispatch(errorCheckCreatedHub(error))
        throw error
      })
  }
}

export function clearCheckCreatedEM() {
  return {
    type: CLEAR_CHECK_CREATED_EM,
  }
}

export function cleanCheckCreatedEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearCheckCreatedEM())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearFaviconUploadEM() {
  return {
    type: CLEAR_FAVICON_UPLOAD_EM,
  }
}

export function cleanFaviconUploadEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFaviconUploadEM())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function copyObjectEM(obj: any) {
  return {
    type: COPY_OBJECT_EM,
    obj,
  }
}

export function makeCopyObjectEM(obj: any) {
  return (dispatch: AppDispatch) => {
    dispatch(copyObjectEM(obj))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function objectToPasteEM(objId: any, order: any) {
  return {
    type: OBJECT_TO_PASTE_EM,
    objId,
    order,
  }
}

export function objToPasteEM(objId: any, order: any) {
  return (dispatch: AppDispatch) => {
    dispatch(objectToPasteEM(objId, order))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function pastedServerObjectEM(objId: any, clipboard: any, order: any) {
  return {
    type: PASTED_SERVER_OBJECT_EM,
    objId,
    clipboard,
    order,
  }
}

export function pasteServerObjectEM(objId: any, clipboard: any, order: any) {
  return (dispatch: AppDispatch) => {
    dispatch(pastedServerObjectEM(objId, clipboard, order))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function pasteObjectEM(obj: any) {
  return {
    type: PASTE_OBJECT_EM,
    obj,
  }
}

export function makePasteObjectEM(obj: any) {
  return (dispatch: AppDispatch) => {
    dispatch(pasteObjectEM(obj))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearPasteObjectEM() {
  return {
    type: CLEAR_PASTE_OBJECT_EM,
  }
}

export function cleanPasteObjectEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearPasteObjectEM())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearServerCopyPasteEM() {
  return {
    type: CLEAR_SERVER_COPY_PASTE_EM,
  }
}

export function cleanServerCopyPasteEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearServerCopyPasteEM())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearCopyObjectEM() {
  return {
    type: CLEAR_COPY_OBJECT_EM,
  }
}

export function cleanCopyObjectEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearCopyObjectEM())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function copyObjectTemplateEM2(objId: any) {
  return {
    type: COPY_OBJECT_TEMPLATE_EM,
    objId,
  }
}

export function copyObjectTemplateEM(objId: any) {
  return (dispatch: AppDispatch) => {
    dispatch(copyObjectTemplateEM2(objId))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function pasteObjectTemplateEM(obj: any) {
  return {
    type: PASTE_OBJECT_TEMPLATE_EM,
    obj,
  }
}

export function makePasteObjectTemplateEM(obj: any) {
  return (dispatch: AppDispatch) => {
    dispatch(pasteObjectTemplateEM(obj))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearCopyObjectTeplateEM() {
  return {
    type: CLEAR_COPY_OBJECT_TEMPLATE_EM,
  }
}

export function cleanCopyObjectTemplateEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearCopyObjectTeplateEM())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorCopyPasteEM(error: any) {
  return {
    type: ERROR_COPY_PASTE_EM,
    error,
  }
}

export function requestCopyPasteEM() {
  return {
    type: REQUEST_COPY_PASTE_EM,
  }
}

export function successCopyPasteEM(response: any) {
  return {
    type: SUCCESS_COPY_PASTE_EM,
    response,
  }
}

export function clearCopyPasteEM() {
  return {
    type: CLEAR_COPY_PASTE_EM,
  }
}

export function cleanCopyPasteEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearCopyPasteEM())
  }
}

export function copyPasteObjectsEM(
  copiedId: string,
  pastedParentObj: any,
  order: any,
  withContent: any,
  eventId: string,
  profile: any,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestCopyPasteEM())
    return copyPasteSS(copiedId, pastedParentObj, order, withContent, eventId, profile, token)
      .then((response: any) => {
        dispatch(successCopyPasteEM({ ...response, pastedParentObj }))
      })
      .catch((error: any) => {
        dispatch(errorCopyPasteEM(error))
        throw error
      })
  }
}
/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorCopyPasteStylesEM(error: any) {
  return {
    type: ERROR_COPY_PASTE_STYLES_EM,
    error,
  }
}

export function requestCopyPasteStylesEM() {
  return {
    type: REQUEST_COPY_PASTE_STYLES_EM,
  }
}

export function successCopyPasteStylesEM(response: any) {
  return {
    type: SUCCESS_COPY_PASTE_STYLES_EM,
    response,
  }
}

export function clearCopyPasteStylesEM2() {
  return {
    type: CLEAR_COPY_PASTE_STYLES_EM,
  }
}

export function clearCopyPasteStylesEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearCopyPasteStylesEM2())
  }
}

export function copyPasteStylesEM(copiedId: string, pastedId: string, profile: any, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestCopyPasteStylesEM())
    return copyPasteStylesSS(copiedId, pastedId, profile, token)
      .then((response: any) => {
        dispatch(successCopyPasteStylesEM(response))
      })
      .catch((error: any) => {
        dispatch(errorCopyPasteStylesEM(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function addBulkEvent(events: any) {
  return {
    type: ADD_BULK_EVENT,
    events,
  }
}

export function eventBulkSender(events: any) {
  return (dispatch: AppDispatch) => {
    dispatch(addBulkEvent(events))
  }
}

/* -- * -- * -- DELETE HOST OUPUTTERS -- * -- * -- */

export function errorDeletingHost(error: any) {
  return {
    type: ERROR_DELETE_HOST_OUTPUTTERS,
    error,
  }
}

export function requestDeleteHost() {
  return {
    type: REQUEST_DELETE_HOST_OUTPUTTERS,
  }
}

export function successDeleteHost(response: any) {
  return {
    type: SUCCESS_DELETE_HOST_OUTPUTTERS,
    response,
  }
}

export function clearDeleteHostOutputters(host: any) {
  return {
    type: CLEAR_DELETE_HOST_OUTPUTTERS,
    host,
  }
}

export function cleanDeleteHostOutputters(host: any) {
  return (dispatch: AppDispatch) => {
    dispatch(clearDeleteHostOutputters(host))
  }
}

export function fetchRequestDeleteHost(profile: any, hostId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestDeleteHost())
    return requestDeleteHostApi(profile, hostId, token)
      .then((response: any) => {
        dispatch(successDeleteHost(response))
      })
      .catch((error: any) => {
        dispatch(errorDeletingHost(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchHost(error: any) {
  return {
    type: ERROR_FETCH_HOST,
    error,
  }
}

export function fetchingHost() {
  return {
    type: FETCHING_HOST,
  }
}

export function successFetchHost(response: any) {
  return {
    type: SUCCESS_FETCH_HOST,
    response,
  }
}

export function fetchHost(profile: any, hostId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(errorFetchHost("Missing requestHost logic"))
    // dispatch(fetchingHost())
    // return experienceManagerApi.requestHost(profile, hostId, token)
    //   .then((response: any)=> {
    //     dispatch(successFetchHost(response))
    //   }).catch((error: any)=> {
    //     dispatch(errorFetchHost(error))
    //     throw error
    //   })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchingFormulaInfo(error: any) {
  return {
    type: ERROR_FETCHING_FORMULA_INFO,
    error,
  }
}

export function requestFormulaInfo() {
  return {
    type: REQUEST_FORMULA_INFO,
  }
}

export function successFetchFormulaInfo(response: any) {
  return {
    type: SUCCESS_FETCH_FORMULA_INFO,
    response,
  }
}

export function fetchFormulaInfo(profile: any, formulaId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestFormulaInfo())
    return fetchFormulaInfoApi(profile, formulaId, token)
      .then((response: any) => {
        dispatch(successFetchFormulaInfo(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchingFormulaInfo(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorRegisterDomainGoogle(error: any) {
  return {
    type: ERROR_REGISTER_DOMAIN_GOOGLE,
    error,
  }
}

export function requestRegisterDomainGoogle(hostId: string) {
  return {
    type: REQUEST_REGISTER_DOMAIN_GOOGLE,
    hostId,
  }
}

export function successRegisterDomainGoogle(response: any) {
  return {
    type: SUCCESS_REGISTER_DOMAIN_GOOGLE,
    response,
  }
}

export function registerDomainGoogle(profile: any, hostId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestRegisterDomainGoogle(hostId))
    return registerDomainGoogleApi(profile, hostId, token)
      .then((response: any) => {
        dispatch(successRegisterDomainGoogle(response))
      })
      .catch((error: any) => {
        dispatch(errorRegisterDomainGoogle(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorSendSitemap(error: any) {
  return {
    type: ERROR_SEND_SITEMAP,
    error,
  }
}

export function requestSendSitemap(hostId: string) {
  return {
    type: REQUEST_SEND_SITEMAP,
    hostId,
  }
}

export function successSendSitemap(response: any) {
  return {
    type: SUCCESS_SEND_SITEMAP,
    response,
  }
}

export function sendSitemap(profile: any, hostId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSendSitemap(hostId))
    return sendSitemapApi(profile, hostId, token)
      .then((response: any) => {
        dispatch(successSendSitemap(response))
      })
      .catch((error: any) => {
        dispatch(errorSendSitemap(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorValidateGoogle(error: any) {
  return {
    type: ERROR_VALIDATE_GOOGLE,
    error,
  }
}

export function requestValidateGoogle(hostId: string) {
  return {
    type: REQUEST_VALIDATE_GOOGLE,
    hostId,
  }
}

export function successValidateGoogle(response: any) {
  return {
    type: SUCCESS_VALIDATE_GOOGLE,
    response,
  }
}

export function validateGoogle(profile: any, hostId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestValidateGoogle(hostId))
    return validateGoogleApi(profile, hostId, token)
      .then((response: any) => {
        dispatch(successValidateGoogle(response))
      })
      .catch((error: any) => {
        dispatch(errorValidateGoogle(error))
        throw error
      })
  }
}

export function clearDomainActions() {
  return {
    type: CLEAR_DOMAIN_ACTIONS,
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function newObjectOrder(orderValue: any, objectId: string) {
  return {
    type: NEW_OBJECT_ORDER,
    orderValue,
    objectId,
  }
}

export function dragOrderChange(orderValue: any, objectId: string) {
  return (dispatch: AppDispatch) => {
    dispatch(addBulkEvent(orderValue))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function emptyNewObjectOrder() {
  return {
    type: EMPTY_NEW_OBJECT_ORDER,
  }
}

export function clearDragOrderChange() {
  return (dispatch: AppDispatch) => {
    dispatch(emptyNewObjectOrder())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function selectedFormulaToPreview(selectedFormula: any) {
  return {
    type: SELECTED_FORMULA_TO_PREVIEW,
    selectedFormula,
  }
}

export function selectFormulaToPreview(selectedFormula: any) {
  return (dispatch: AppDispatch) => {
    dispatch(selectedFormulaToPreview(selectedFormula))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function addBulkPostsExperienceManager(posts: any, clearContent: any) {
  return {
    type: ADD_BULK_POSTS_EXPERIENCE_MANAGER,
    posts,
    clearContent,
  }
}

export function addBulkPosts(posts: any, clearContent = false) {
  return (dispatch: AppDispatch) => {
    dispatch(addBulkPostsExperienceManager(posts, clearContent))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function addSinglePostExperienceManager(post: any) {
  return {
    type: ADD_SINGLE_POST_EXPERIENCE_MANAGER,
    post,
  }
}

export function addSinglePost(post: any) {
  return (dispatch: AppDispatch) => {
    dispatch(addSinglePostExperienceManager(post))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function addSinglePostByEmbedExperienceManager(post: any) {
  return {
    type: ADD_SINGLE_POST_BY_EMBED_EXPERIENCE_MANAGER,
    post,
  }
}

export function addSinglePostByEmbed(post: any) {
  return (dispatch: AppDispatch) => {
    dispatch(addSinglePostByEmbedExperienceManager(post))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function removeSinglePostExperienceManager(post: any) {
  return {
    type: REMOVE_SINGLE_POST_EXPERIENCE_MANAGER,
    post,
  }
}

export function removeSinglePost(post: any) {
  return (dispatch: AppDispatch) => {
    dispatch(removeSinglePostExperienceManager(post))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function cleanPostsExperienceManager() {
  return {
    type: CLEAR_POSTS_EXPERIENCE_MANAGER,
  }
}

export function cleanEditedPostsExperienceManager() {
  return {
    type: CLEAR_EDITED_POSTS_EXPERIENCE_MANAGER,
  }
}

export function cleanPostsSelection() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanPostsExperienceManager())
  }
}

export function cleanEditedPostsSelection() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanEditedPostsExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorSubmitSourceToPost(error: any) {
  return {
    type: ERROR_SUBMIT_SOURCE_TO_POST,
    error,
  }
}

export function requestSubmitSourceToPost(hostId: string) {
  return {
    type: REQUEST_SUBMIT_SOURCE_TO_POST,
    hostId,
  }
}

export function successSubmitSourceToPost(response: any) {
  return {
    type: SUCCESS_SUBMIT_SOURCE_TO_POST,
    response,
  }
}

export function submitSourceToPost(profile: any, url: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSubmitSourceToPost("undefined"))
    return sourceToPost(profile, url, token)
      .then((response: any) => {
        dispatch(successSubmitSourceToPost(response))
      })
      .catch((error: any) => {
        dispatch(errorSubmitSourceToPost(error))
        throw error
      })
  }
}

export function clearSourceToPost() {
  return {
    type: CLEAR_SOURCE_TO_POST,
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorChangePostsExperienceOrder(error: any) {
  return {
    type: ERROR_CHANGE_POSTS_EXPERIENCE_ORDER,
    error,
  }
}

export function requestChangePostsExperienceOrder() {
  return {
    type: REQUEST_CHANGE_POSTS_EXPERIENCE_ORDER,
  }
}

export function successChangePostsExperienceOrder(response: any, step: any, object: any, event: any) {
  return {
    type: SUCCESS_CHANGE_POSTS_EXPERIENCE_ORDER,
    response,
    step,
    object,
    event,
  }
}

export function changePostsExperienceOrder(
  profile: any,
  object: any,
  step: any,
  event: any,
  publish: any,
  eventId: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestChangePostsExperienceOrder())
    return postsExperienceOrder(profile, object, publish, eventId, token)
      .then((response: any) => {
        dispatch(successChangePostsExperienceOrder(response, step, object, event))
      })
      .catch((error: any) => {
        dispatch(errorChangePostsExperienceOrder(error))
        throw error
      })
  }
}

export function clearPostsExperienceOrder() {
  return {
    type: CLEAR_POSTS_EXPERIENCE_ORDER,
  }
}
/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorSearchByPostsExperienceManager(error: any) {
  return {
    type: ERROR_SEARCH_BY_POSTS_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestSearchByPostsExperienceManager() {
  return {
    type: REQUEST_SEARCH_BY_POSTS_EXPERIENCE_MANAGER,
  }
}

export function successSearchByPostsExperienceManager(response: any, searchTerm: any) {
  return {
    type: SUCCESS_SEARCH_BY_POSTS_EXPERIENCE_MANAGER,
    response,
    searchTerm,
  }
}

export function searchByPosts(profile: any, token: any, orderby: any, limit: any, order: any, searchTerm: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSearchByPostsExperienceManager())
    return searchByPostsApi(profile, token, orderby, limit, order, searchTerm)
      .then((response: any) => {
        dispatch(successSearchByPostsExperienceManager(response, searchTerm))
      })
      .catch((error: any) => {
        dispatch(errorSearchByPostsExperienceManager(error))
        throw error
      })
  }
}

export function clearSearchByPostsExperienceManager() {
  return {
    type: CLEAR_SEARCH_BY_POSTS_EXPERIENCE_MANAGER,
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function objectSelectorModeEM(value: any) {
  return {
    type: OBJECT_SELECTOR_MODE_EM,
    value,
  }
}

export function objectSelectionModeEM(value: any, profile: any) {
  Metrics.AmplitudeNavigation("Page Builder Action", {
    label: "Trigger Object Selector",
    section: "page-preview",
    profile: profile,
  })
  return (dispatch: AppDispatch) => {
    dispatch(objectSelectorModeEM(value))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function eventPileSuccessResponsesEM(eventResponse: any) {
  return {
    type: EVENT_PILE_SUCCESS_RESPONSES_EM,
    eventResponse,
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorHubToCloneEM(error: any) {
  return {
    type: ERROR_HUB_TO_CLONE_EXPERERIENCE_MANAGER,
    error,
  }
}

export function requestHubToCloneEM() {
  return {
    type: REQUEST_HUB_TO_CLONE_EXPERIENCE_MANAGER,
  }
}

export function successHubToCloneEM(response: any) {
  return {
    type: SUCCESS_HUB_TO_CLONE_EXPERIENCE_MANAGER,
    response,
  }
}

export function hubToCloneEM(profile: any, hubUuid: any, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestHubToCloneEM())
    return fetchHub(profile, hubUuid, token)
      .then((response: any) => {
        dispatch(successHubToCloneEM(response))
      })
      .catch((error: any) => {
        dispatch(errorHubToCloneEM(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorOgImageUpload(error: any) {
  return {
    type: ERROR_OG_IMAGE_UPLOAD,
    error,
  }
}

export function requestOgImageUpload() {
  return {
    type: REQUEST_OG_IMAGE_UPLOAD,
  }
}

export function successOgImageUpload(response: any) {
  return {
    type: SUCCESS_OG_IMAGE_UPLOAD,
    response,
  }
}

export function ogImageUpload(profile: any, image: any, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestOgImageUpload())
    return uploadOGImage(profile, image, token)
      .then((response: any) => {
        dispatch(successOgImageUpload(response))
      })
      .catch((error: any) => {
        dispatch(errorOgImageUpload(error))
        throw error
      })
  }
}

export function clearOgImageUpload() {
  return {
    type: CLEAR_OG_IMAGE_UPLOAD,
  }
}

export function cleanOgImageUpload() {
  return (dispatch: AppDispatch) => {
    dispatch(clearOgImageUpload())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorLayoutsExperienceManager(error: any) {
  return {
    type: ERROR_LAYOUTS_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestLayoutsExperienceManager() {
  return {
    type: REQUEST_LAYOUTS_EXPERIENCE_MANAGER,
  }
}

export function successLayoutsExperienceManager(response: any) {
  return {
    type: SUCCESS_LAYOUTS_EXPERIENCE_MANAGER,
    response,
  }
}

export function fetchLayoutsEM(profile: any, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestLayoutsExperienceManager())
    return requestLayouts(profile, token)
      .then((response: any) => {
        dispatch(successLayoutsExperienceManager(response))
      })
      .catch((error: any) => {
        dispatch(errorLayoutsExperienceManager(error))
        throw error
      })
  }
}

export function clearLayoutsEM() {
  return {
    type: CLEAR_LAYOUTS_EXPERIENCE_MANAGER,
  }
}

export function cleanLayoutsEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearLayoutsEM())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorSelectedLayoutExperienceManager(error: any) {
  return {
    type: ERROR_SELECTED_LAYOUT_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestSelectedLayoutExperienceManager() {
  return {
    type: REQUEST_SELECTED_LAYOUT_EXPERIENCE_MANAGER,
  }
}

export function successSelectedLayoutExperienceManager(response: any, actionType: any) {
  return {
    type: SUCCESS_SELECTED_LAYOUT_EXPERIENCE_MANAGER,
    response,
    actionType,
  }
}

export function fetchselectedLayoutEM(profile: any, layoutId: string, actionType: any, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSelectedLayoutExperienceManager())
    return requestSelectedLayout(profile, layoutId, token)
      .then((response: any) => {
        dispatch(successSelectedLayoutExperienceManager(response, actionType))
      })
      .catch((error: any) => {
        dispatch(errorSelectedLayoutExperienceManager(error))
        throw error
      })
  }
}

export function clearSelectedLayoutEM() {
  return {
    type: CLEAR_SELECTED_LAYOUT_EXPERIENCE_MANAGER,
  }
}

export function cleanSelectedLayoutEM() {
  return (dispatch: AppDispatch) => {
    dispatch(clearSelectedLayoutEM())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearSavedHubForPreview() {
  return {
    type: CLEAR_SAVED_HUB_FOR_PREVIEW,
  }
}

export function cleanSavedHubForPreview() {
  return (dispatch: AppDispatch) => {
    dispatch(clearSavedHubForPreview())
  }
}

export function savedHubForPreview(hubObj: any) {
  return {
    type: SAVED_HUB_FOR_PREVIEW,
    hubObj,
  }
}

export function saveHubForPreview(hubObj: any) {
  return (dispatch: AppDispatch) => {
    dispatch(savedHubForPreview(hubObj))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function handleNewPageModal(isOpen: boolean, host: any, site: any, template: any) {
  return {
    type: HANDLE_NEW_PAGE_MODAL,
    isOpen,
    host,
    site,
    template,
  }
}

export function newPageModal(isOpen: boolean, host = undefined, site = undefined, template = undefined) {
  return (dispatch: AppDispatch) => {
    dispatch(handleNewPageModal(isOpen, host, site, template))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorClonePageExperienceManager(error: any, pageId: string) {
  return {
    type: ERROR_CLONE_PAGE_EXPERIENCE_MANAGER,
    error,
    pageId,
  }
}

export function requestClonePageExperienceManager(pageId: string) {
  return {
    type: REQUEST_CLONE_PAGE_EXPERIENCE_MAGAGER,
    pageId,
  }
}

export function successClonePageExperienceManager(response: any, pageId: string) {
  return {
    type: SUCCESS_CLONE_PAGE_EXPERIENCE_MANAGER,
    response,
    pageId,
  }
}

export function clonePageExperienceManager(
  pageId: string,
  hostId: string,
  site: any,
  uuid: any,
  order: any,
  pageUri: string,
  pageName: string,
  articleLayout: any,
  template: any,
  eventId: string,
  profile: any,
  token: string,
  isTemplate?: boolean
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestClonePageExperienceManager(pageId))
    return clonePage(
      pageId,
      hostId,
      site,
      uuid,
      order,
      pageUri,
      pageName,
      articleLayout,
      template,
      eventId,
      profile,
      token,
      isTemplate
    )
      .then((response: any) => {
        dispatch(successClonePageExperienceManager(response, pageId))
      })
      .catch((error: any) => {
        dispatch(errorClonePageExperienceManager(error, pageId))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function clearClonePageExperienceManager() {
  return {
    type: CLEAR_CLONE_PAGE_EXPERIENCE_MANAGER,
  }
}

export function cleanClonePageExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(clearClonePageExperienceManager())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function eventIdEM(newId: string) {
  return {
    type: EVENT_ID_EM,
    newId,
  }
}

export function uniqEventIdEM(newId: string) {
  return (dispatch: AppDispatch) => {
    dispatch(eventIdEM(newId))
  }
}
/* -- * -- * -- * -- * -- * -- * -- * -- */

export function autoSelectOnCreateModeEM(state: any) {
  return {
    type: AUTO_SELECT_ON_CREATE_MODE_EM,
    state,
  }
}

export function autoSelectOnCreateEM(state: any) {
  return (dispatch: AppDispatch) => {
    dispatch(autoSelectOnCreateModeEM(state))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function selectedBobElementEM(element: any) {
  return {
    type: SELECTED_BOB_ELEMENT_EM,
    element,
  }
}

export function selectBobElementEM(element: any) {
  return (dispatch: AppDispatch) => {
    dispatch(selectedBobElementEM(element))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function elementSelectorStateEM(elementState: any) {
  return {
    type: ELEMENT_SELECTOR_STATE,
    elementState,
  }
}

export function changeElementSelectorState(elementState: any, profile: any) {
  Metrics.AmplitudeNavigation("Page Builder Action", {
    label: "Trigger Element Highlight",
    section: "page-preview",
    profile: profile,
  })
  return (dispatch: AppDispatch) => {
    dispatch(elementSelectorStateEM(elementState))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function objectSelectorStateEM(objectState: any) {
  return {
    type: OBJECT_SELECTOR_STATE,
    objectState,
  }
}

export function changeObjectSelectorState(objectState: any, profile: any) {
  Metrics.AmplitudeNavigation("Page Builder Action", {
    label: "Trigger Object Highlight",
    section: "page-preview",
    profile: profile,
  })
  return (dispatch: AppDispatch) => {
    dispatch(objectSelectorStateEM(objectState))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorStagingPageExperienceManager(error: any, pageId: string) {
  return {
    type: ERROR_STAGING_PAGE_EXPERIENCE_MANAGER,
    error,
    pageId,
  }
}

export function requestStagingPageExperienceManager(pageId: string) {
  return {
    type: REQUEST_STAGING_PAGE_EXPERIENCE_MANAGER,
    pageId,
  }
}

export function successStagingPageExperienceManager(experienceContent: any, pageId: string) {
  return {
    type: SUCCESS_STAGING_PAGE_EXPERIENCE_MANAGER,
    experienceContent,
    pageId,
  }
}

export function stagingPageExperienceManager(pageId: string, cache: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestStagingPageExperienceManager(pageId))
    return stagingPage(pageId, cache)
      .then((response: any) => {
        dispatch(successStagingPageExperienceManager(response, pageId))
      })
      .catch((error: any) => {
        dispatch(errorStagingPageExperienceManager(error, pageId))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function openColorPickerStyles(
  element: any,
  color: any,
  colorResponsiveData: ResponsiveData<string>,
  selectedController: SelectedController,
  foundationStylesList: PageTypes["foundations"],
  finderPreview: any
) {
  return {
    type: OPEN_COLOR_PICKER_STYLES,
    element,
    color,
    colorResponsiveData,
    selectedController,
    foundationStylesList,
    finderPreview,
  }
}

export function openColorPicker(
  element: any,
  color: any,
  colorResponsiveData: ResponsiveData<string>,
  selectedController: SelectedController,
  foundationStylesList: PageTypes["foundations"],
  finderPreview: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(
      openColorPickerStyles(
        element,
        color,
        colorResponsiveData,
        selectedController,
        foundationStylesList,
        finderPreview
      )
    )
  }
}
/* -- * -- * -- * -- * -- * -- * -- * -- */

export function colorPickerExportSelection(element: any, color: any) {
  return {
    type: COLOR_PICKER_EXPORT_SELECTION,
    element,
    color,
  }
}

export function exportColorPicker(element: any, color: any) {
  return (dispatch: AppDispatch) => {
    dispatch(colorPickerExportSelection(element, color))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorPublishPage(error: any, pageId: string) {
  return {
    type: ERROR_PUBLISH_PAGE,
    error,
    pageId,
  }
}

export function requestPublishPage(pageId: string) {
  return {
    type: REQUEST_PUBLISH_PAGE,
    pageId,
  }
}

export function successPublishPage(response: any, pageId: string) {
  return {
    type: SUCCESS_PUBLISH_PAGE,
    response,
    pageId,
  }
}

export function publishPage(profileId: string, pageId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestPublishPage(pageId))
    return publishPageApi(profileId, pageId, token)
      .then((response: any) => {
        dispatch(successPublishPage(response, pageId))
      })
      .catch((error: any) => {
        dispatch(errorPublishPage(error, pageId))
        throw error
      })
  }
}

export function cleanPublishPage() {
  return {
    type: CLEAR_PUBLISH_PAGE,
  }
}

export function clearPublishPage() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanPublishPage())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUnpublishPage(error: any, pageId: string) {
  return {
    type: ERROR_UNPUBLISH_PAGE,
    error,
    pageId,
  }
}

export function requestUnpublishPage(pageId: string) {
  return {
    type: REQUEST_UNPUBLISH_PAGE,
    pageId,
  }
}

export function successUnpublishPage(response: any, pageId: string) {
  return {
    type: SUCCESS_UNPUBLISH_PAGE,
    response,
    pageId,
  }
}

export function unpublishPage(profileId: string, pageId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUnpublishPage(pageId))
    return unpublishPageApi(profileId, pageId, token)
      .then((response: any) => {
        dispatch(successUnpublishPage(response, pageId))
      })
      .catch((error: any) => {
        dispatch(errorUnpublishPage(error, pageId))
        throw error
      })
  }
}

export function cleanUnpublishPage() {
  return {
    type: CLEAR_UNPUBLISH_PAGE,
  }
}

export function clearUnpublishPage() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanUnpublishPage())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorPublishSitePages(error: any, siteId: string) {
  return {
    type: ERROR_PUBLISH_SITE_PAGES,
    error,
    siteId,
  }
}

export function requestPublishSitePages(siteId: string) {
  return {
    type: REQUEST_PUBLISH_SITE_PAGES,
    siteId,
  }
}

export function successPublishSitePages(response: any, siteId: string) {
  return {
    type: SUCCESS_PUBLISH_SITE_PAGES,
    response,
    siteId,
  }
}

export function publishSitePages(siteId: string, exceptions: any, profileId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestPublishSitePages(siteId))
    return publishSitePagesApi(siteId, exceptions, profileId, token)
      .then((response: any) => {
        dispatch(successPublishSitePages(response, siteId))
      })
      .catch((error: any) => {
        dispatch(errorPublishSitePages(error, siteId))
        throw error
      })
  }
}

export function cleanPublishSitePages() {
  return {
    type: CLEAR_PUBLISH_SITE_PAGES,
  }
}

export function clearPublishSitePages() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanPublishSitePages())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorPublishSitePagesStatus(error: any, siteId: string) {
  return {
    type: ERROR_PUBLISH_SITE_PAGES_STATUS,
    error,
    siteId,
  }
}

export function requestPublishSitePagesStatus(siteId: string) {
  return {
    type: REQUEST_PUBLISH_SITE_PAGES_STATUS,
    siteId,
  }
}

export function successPublishSitePagesStatus(response: any, siteId: string) {
  return {
    type: SUCCESS_PUBLISH_SITE_PAGES_STATUS,
    response,
    siteId,
  }
}

export function publishSitePagesStatus(siteId: string, profileId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestPublishSitePagesStatus(siteId))
    return publishSitePagesStatusApi(siteId, profileId, token)
      .then((response: any) => {
        dispatch(successPublishSitePagesStatus(response, siteId))
      })
      .catch((error: any) => {
        dispatch(errorPublishSitePagesStatus(error, siteId))
        throw error
      })
  }
}

export function clearPublishSitePagesStatus() {
  return {
    type: CLEAR_PUBLISH_SITE_PAGES_STATUS,
  }
}

export function cleanPublishSitePagesStatus() {
  return (dispatch: AppDispatch) => {
    dispatch(clearPublishSitePagesStatus())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorPublishObjectTemplate(error: any, objectTemplateId: string) {
  return {
    type: ERROR_PUBLISH_OBJECT_TEMPLATE,
    error,
    objectTemplateId,
  }
}

export function requestPublishObjectTemplate(objectTemplateId: string) {
  return {
    type: REQUEST_PUBLISH_OBJECT_TEMPLATE,
    objectTemplateId,
  }
}

export function successPublishObjectTemplate(response: any, objectTemplateId: string) {
  return {
    type: SUCCESS_PUBLISH_OBJECT_TEMPLATE,
    response,
    objectTemplateId,
  }
}

export function publishObjectTemplate(
  profileId: string,
  objectTemplateId: string,
  categoryName: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestPublishObjectTemplate(objectTemplateId))
    return publishObjectTemplateApi(profileId, objectTemplateId, categoryName, token)
      .then((response: any) => {
        dispatch(successPublishObjectTemplate(response, objectTemplateId))
      })
      .catch((error: any) => {
        dispatch(errorPublishObjectTemplate(error, objectTemplateId))
        throw error
      })
  }
}

export function cleanPublishObjectTemplate() {
  return {
    type: CLEAR_PUBLISH_OBJECT_TEMPLATE,
  }
}

export function clearPublishObjectTemplate() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanPublishObjectTemplate())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUnpublishObjectTemplate(error: any, objectTemplateId: string) {
  return {
    type: ERROR_UNPUBLISH_OBJECT_TEMPLATE,
    error,
    objectTemplateId,
  }
}

export function requestUnpublishObjectTemplate(objectTemplateId: string) {
  return {
    type: REQUEST_UNPUBLISH_OBJECT_TEMPLATE,
    objectTemplateId,
  }
}

export function successUnpublishObjectTemplate(response: any, objectTemplateId: string) {
  return {
    type: SUCCESS_UNPUBLISH_OBJECT_TEMPLATE,
    response,
    objectTemplateId,
  }
}

export function unpublishObjectTemplate(profileId: string, objectTemplateId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUnpublishObjectTemplate(objectTemplateId))
    return unpublishObjectTemplateApi(profileId, objectTemplateId, token)
      .then((response: any) => {
        dispatch(successUnpublishObjectTemplate(response, objectTemplateId))
      })
      .catch((error: any) => {
        dispatch(errorUnpublishObjectTemplate(error, objectTemplateId))
        throw error
      })
  }
}

export function cleanUnpublishObjectTemplate() {
  return {
    type: CLEAR_UNPUBLISH_OBJECT_TEMPLATE,
  }
}

export function clearUnpublishObjectTemplate() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanUnpublishObjectTemplate())
  }
}

/************************************************* */

export function requestCreateSymbolInstance() {
  return {
    type: REQUEST_CREATE_SYMBOL_INSTANCE,
  }
}

export function receiveCreateSymbolInstance(response: any, object: any) {
  return {
    type: RECEIVE_CREATE_SYMBOL_INSTANCE,
    response,
    object,
  }
}

export function errorCreateSymbolInstance(error: any) {
  return {
    type: ERROR_CREATE_SYMBOL_INSTANCE,
    error,
  }
}

export function createSymbolInstance(profile: any, object: any, eventId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestCreateSymbolInstance())
    return createSymbolInstanceApi(object, profile, token, eventId)
      .then((response: any) => {
        dispatch(receiveCreateSymbolInstance(response, object))
      })
      .catch((error: any) => {
        dispatch(errorCreateSymbolInstance(error))
        throw error
      })
  }
}

export function cleanCreateSymbolInstance() {
  return (dispatch: AppDispatch) => {
    dispatch(clearCreateSymbolInstance())
  }
}

export function clearCreateSymbolInstance() {
  return {
    type: CLEAR_CREATE_SYMBOL_INSTANCE,
  }
}

/************************************************* */

export function requestSetSymbol(oldObjectUuid: any) {
  return {
    type: REQUEST_SET_SYMBOL,
    oldObjectUuid,
  }
}

export function receiveSetSymbol(response: any, object: any) {
  return {
    type: RECEIVE_SET_SYMBOL,
    response,
    object,
  }
}

export function errorSetSymbol(error: any) {
  return {
    type: ERROR_SET_SYMBOL,
    error,
  }
}

export function setSymbol(profile: any, symbolObject: any, instanceObject: any, eventId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSetSymbol(symbolObject.oldObjectUuid))
    return setSymbolApi(profile, symbolObject, instanceObject, eventId, token)
      .then((response: any) => {
        dispatch(receiveSetSymbol({ ...response, symbolObject, instanceObject }, false))
      })
      .catch((error: any) => {
        dispatch(errorSetSymbol({ ...error, symbolObject, instanceObject }))
        throw error
      })
  }
}

export function cleanSetSymbol() {
  return (dispatch: AppDispatch) => {
    dispatch(clearSetSymbol())
  }
}

export function clearSetSymbol() {
  return {
    type: CLEAR_SET_SYMBOL,
  }
}

/************************************************* */

export function requestUnsetSymbol(objectUuid: any) {
  return {
    type: REQUEST_UNSET_SYMBOL,
    objectUuid,
  }
}

export function receiveUnsetSymbol(response: any, object: any) {
  return {
    type: RECEIVE_UNSET_SYMBOL,
    response,
    object,
  }
}

export function errorUnsetSymbol(error: any) {
  return {
    type: ERROR_UNSET_SYMBOL,
    error,
  }
}

export function clearUnsetSymbol() {
  return {
    type: CLEAR_UNSET_SYMBOL,
  }
}

export function unsetSymbol(profile: any, object: any, eventId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUnsetSymbol(object.objectUuid))
    return unsetSymbolApi(object, profile, token, eventId)
      .then((response: any) => {
        dispatch(receiveUnsetSymbol(response, object))
      })
      .catch((error: any) => {
        dispatch(errorUnsetSymbol(error))
        throw error
      })
  }
}

export function cleanUnsetSymbol() {
  return (dispatch: AppDispatch) => {
    dispatch(clearUnsetSymbol())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUpdateInstanceContentExperienceManager(error: any) {
  return {
    type: ERROR_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestUpdateInstanceContentExperienceManager(objectUuid: any) {
  return {
    type: REQUEST_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
    objectUuid,
  }
}

export function successUpdateInstanceContentExperienceManager(response: any, object: any) {
  return {
    type: SUCCESS_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
    response,
    object,
  }
}

export function updateInstanceContentExperienceManager(
  profileId: string,
  instance: any,
  object: any,
  eventId: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUpdateInstanceContentExperienceManager(object.objectUuid))
    return updateInstanceContent(profileId, instance, object, eventId, token)
      .then((response: any) => {
        dispatch(successUpdateInstanceContentExperienceManager(response, object))
      })
      .catch((error: any) => {
        dispatch(errorUpdateInstanceContentExperienceManager(error))
        throw error
      })
  }
}

/************************************************* */

export function errorDeleteInstanceContentExperienceManager(error: any) {
  return {
    type: ERROR_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestDeleteInstanceContentExperienceManager(objectUuid: any) {
  return {
    type: REQUEST_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
    objectUuid,
  }
}

export function successDeleteInstanceContentExperienceManager(response: any, object: any) {
  return {
    type: SUCCESS_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
    response,
    object,
  }
}

export function deleteInstanceContentExperienceManager(
  profileId: string,
  instance: any,
  object: any,
  eventId: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestDeleteInstanceContentExperienceManager(object.objectUuid))
    return deleteInstanceContent(profileId, instance, object, eventId, token)
      .then((response: any) => {
        dispatch(successDeleteInstanceContentExperienceManager(response, object))
      })
      .catch((error: any) => {
        dispatch(errorDeleteInstanceContentExperienceManager(error))
        throw error
      })
  }
}

export function clearDeleteInstanceContentExperienceManager() {
  return {
    type: CLEAR_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER,
  }
}

export function cleanDeleteInstanceContentExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(clearDeleteInstanceContentExperienceManager())
  }
}

/************************************************* */

export function requestSetControllerState(state: any) {
  return {
    type: REQUEST_SET_CONTROLLER_STATE,
    state,
  }
}

export function setControllerState(state: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSetControllerState(state))
  }
}

/************************************************* */

export function addTemplateObjectToTree(object: any, addBehaviourType: any) {
  return {
    type: ADD_OBJECT_TEMPLATE,
    object,
    addBehaviourType,
  }
}
export function cleanAddTemplateObject() {
  return {
    type: CLEAR_ADD_OBJECT_TEMPLATE,
  }
}

export function addTemplateObject(object: any, addBehaviourType: any) {
  return (dispatch: AppDispatch) => {
    return dispatch(addTemplateObjectToTree(object, addBehaviourType))
  }
}

export function clearAddTemplateObject() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanAddTemplateObject())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorCreateObjectTemplateExperienceManager(error: any) {
  return {
    type: ERROR_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER,
    error,
  }
}

export function requestCreateObjectTemplateExperienceManager() {
  return {
    type: REQUEST_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER,
  }
}

export function successCreateObjectTemplateExperienceManager(response: any, composedId: any) {
  return {
    type: SUCCESS_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER,
    response,
    composedId,
  }
}

export function cleanCreateObjectTemplateExperienceManager() {
  return {
    type: CLEAR_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER,
  }
}

export function createObjectTemplateExperienceManager(
  profileId: string,
  templateUuid: any,
  createFrom: any,
  lastChildParentID: any,
  afterBrotherId: string,
  eventId: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestCreateObjectTemplateExperienceManager())
    return createObjectTemplate(profileId, templateUuid, createFrom, lastChildParentID, afterBrotherId, eventId, token)
      .then((response: any) => {
        dispatch(successCreateObjectTemplateExperienceManager(response, undefined))
      })
      .catch((error: any) => {
        dispatch(errorCreateObjectTemplateExperienceManager(error))
        throw error
      })
  }
}

export function clearCreateObjectTemplateExperienceManager() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanCreateObjectTemplateExperienceManager())
  }
}

/************************************************* */

export function errorDeleteObjectTemplate(error: any) {
  return {
    type: ERROR_DELETE_OBJECT_TEMPLATE,
    error,
  }
}

export function requestDeleteObjectTemplate() {
  return {
    type: REQUEST_DELETE_OBJECT_TEMPLATE,
  }
}

export function successDeleteObjectTemplate(response: any) {
  return {
    type: SUCCESS_DELETE_OBJECT_TEMPLATE,
    response,
  }
}

export function deleteObjectTemplate(profile: any, objectId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestDeleteObjectTemplate())
    return deleteObjectTemplateApi(profile, objectId, token)
      .then((response: any) => {
        dispatch(successDeleteObjectTemplate(response))
      })
      .catch((error: any) => {
        dispatch(errorDeleteObjectTemplate(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function cleardeleteObjectTemplate() {
  return {
    type: CLEAR_DELETE_OBJECT_TEMPLATE,
  }
}

export function cleanDeleteObjectTemplate() {
  return (dispatch: AppDispatch) => {
    dispatch(cleardeleteObjectTemplate())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUpdateObjectTemplate(error: any) {
  return {
    type: ERROR_UPDATE_OBJECT_TEMPLATE,
    error,
  }
}

export function requestUpdateObjectTemplate() {
  return {
    type: REQUEST_UPDATE_OBJECT_TEMPLATE,
  }
}

export function successUpdateObjectTemplate(response: any) {
  return {
    type: SUCCESS_UPDATE_OBJECT_TEMPLATE,
    response,
  }
}

export function updateObjectTemplate(object: any, profile: string, token: string, eventId: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUpdateObjectTemplate())
    return updateObjectTemplateApi(object, profile, token, eventId)
      .then((response: any) => {
        dispatch(successUpdateObjectTemplate(response))
      })
      .catch((error: any) => {
        dispatch(errorUpdateObjectTemplate(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

// Viewport State
export function viewPortOriginalSizeExperienceManager(viewPortSize: any) {
  return {
    type: VIEW_PORT_ORIGINAL_SIZE_STATE_EXPERIENCE_MANAGER,
    viewPortSize,
  }
}

export function viewPortOriginalSizeStateExperienceManager(viewPortSize: any) {
  return (dispatch: AppDispatch) => {
    dispatch(viewPortOriginalSizeExperienceManager(viewPortSize))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */
// Behaviour State
export function setBehaviourState(behaviourState: any) {
  return {
    type: SET_BEHAVIOUR_STATE,
    behaviourState,
  }
}

export function behaviourStateChange(behaviourState: any) {
  return (dispatch: AppDispatch) => {
    dispatch(setBehaviourState(behaviourState))
  }
}
/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchStagingObjectTemplateById(error: any) {
  return {
    type: ERROR_FETCH_STAGING_OBJECT_TEMPLATE_BY_ID,
    error,
  }
}

export function requestStagingObjectTemplateById() {
  return {
    type: REQUEST_STAGING_OBJECT_TEMPLATE_BY_ID,
  }
}

export function successFetchStagingObjectTemplateById(response: any) {
  return {
    type: SUCCESS_STAGING_OBJECT_TEMPLATE_BY_ID,
    response,
  }
}

export function fetchStagingObjectTemplateById(objTemplateId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestStagingObjectTemplateById())
    return stagingObjectTemplateById(objTemplateId, token)
      .then((response: any) => {
        dispatch(successFetchStagingObjectTemplateById(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchStagingObjectTemplateById(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchStagingPublishedObjectTemplateById(error: any) {
  return {
    type: ERROR_FETCH_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID,
    error,
  }
}

export function requestStagingPublishedObjectTemplateById() {
  return {
    type: REQUEST_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID,
  }
}

export function successFetchStagingPublishedObjectTemplateById(response: any) {
  return {
    type: SUCCESS_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID,
    response,
  }
}

export function fetchStagingPublishedObjectTemplateById(publishedOTemplateId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestStagingPublishedObjectTemplateById())
    return stagingPublishedObjectTemplateById(publishedOTemplateId, token)
      .then((response: any) => {
        dispatch(successFetchStagingPublishedObjectTemplateById(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchStagingPublishedObjectTemplateById(error))
        throw error
      })
  }
}
/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchStagingSymbolById(error: any) {
  return {
    type: ERROR_FETCH_STAGING_SYMBOL_BY_ID,
    error,
  }
}

export function requestStagingSymbolById() {
  return {
    type: REQUEST_STAGING_SYMBOL_BY_ID,
  }
}

export function successFetchStagingSymbolById(response: any) {
  return {
    type: SUCCESS_STAGING_SYMBOL_BY_ID,
    response,
  }
}

export function fetchStagingSymbolById(publishedOTemplateId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestStagingSymbolById())
    return stagingSymbolById(publishedOTemplateId, token)
      .then((response: any) => {
        dispatch(successFetchStagingSymbolById(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchStagingSymbolById(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorObjectTemplateScreenShot(error: any, objectTemplateId: string) {
  return {
    type: ERROR_OBJECT_TEMPLATE_SCREEN_SHOT,
    error,
    objectTemplateId,
  }
}

export function requestObjectTemplateScreenShot(objectTemplateId: string) {
  return {
    type: REQUEST_OBJECT_TEMPLATE_SCREEN_SHOT,
    objectTemplateId,
  }
}

export function successObjectTemplateScreenShot(response: any, objectTemplateId: string) {
  return {
    type: SUCCESS_OBJECT_TEMPLATE_SCREEN_SHOT,
    response,
    objectTemplateId,
  }
}

export function objectTemplateScreenShot(profileId: string, objectTemplateId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestObjectTemplateScreenShot(objectTemplateId))
    return objectTemplateScreenShotApi(profileId, objectTemplateId, token)
      .then((response: any) => {
        dispatch(successObjectTemplateScreenShot(response, objectTemplateId))
      })
      .catch((error: any) => {
        dispatch(errorObjectTemplateScreenShot(error, objectTemplateId))
        throw error
      })
  }
}

export function cleanObjectTemplateScreenShot() {
  return {
    type: CLEAR_OBJECT_TEMPLATE_SCREEN_SHOT,
  }
}

export function clearObjectTemplateScreenShot() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanObjectTemplateScreenShot())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorGetPlatformPageTemplates(error: any) {
  return {
    type: ERROR_GET_PLATFORM_PAGE_TEMPLATES,
    error,
  }
}

export function requestGetPlatformPageTemplates() {
  return {
    type: REQUEST_GET_PLATFORM_PAGE_TEMPLATES,
  }
}

export function successGetPlatformPageTemplates(response: any) {
  return {
    type: SUCCESS_GET_PLATFORM_PAGE_TEMPLATES,
    response,
  }
}

export function getPlatformPageTemplates(profileId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestGetPlatformPageTemplates())
    return getPlatformPageTemplatesApi(profileId, token)
      .then((response: any) => {
        dispatch(successGetPlatformPageTemplates(response))
      })
      .catch((error: any) => {
        dispatch(errorGetPlatformPageTemplates(error))
        throw error
      })
  }
}

export function cleanGetPlatformPageTemplates() {
  return {
    type: CLEAR_GET_PLATFORM_PAGE_TEMPLATES,
  }
}

export function clearGetPlatformPageTemplates() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanGetPlatformPageTemplates())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorCreateFluxioPost(error: any) {
  return {
    type: ERROR_CREATE_FLUXIO_POST,
    error,
  }
}

export function requestCreateFluxioPost() {
  return {
    type: REQUEST_CREATE_FLUXIO_POST,
  }
}

export function successCreateFluxioPost(response: any) {
  return {
    type: SUCCESS_CREATE_FLUXIO_POST,
    response,
  }
}

export function createFluxioPost(postObj: any, profileId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestCreateFluxioPost())
    return createFluxioPostApi(postObj, profileId, token)
      .then((response: any) => {
        dispatch(successCreateFluxioPost(response))
      })
      .catch((error: any) => {
        dispatch(errorCreateFluxioPost(error))
        throw error
      })
  }
}

export function cleanCreateFluxioPost() {
  return {
    type: CLEAN_CREATE_FLUXIO_POST,
  }
}

export function clearCreateFluxioPost() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanCreateFluxioPost())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUpdateFluxioPost(error: any) {
  return {
    type: ERROR_UPDATE_FLUXIO_POST,
    error,
  }
}

export function requestUpdateFluxioPost() {
  return {
    type: REQUEST_UPDATE_FLUXIO_POST,
  }
}

export function successUpdateFluxioPost(response: any) {
  return {
    type: SUCCESS_UPDATE_FLUXIO_POST,
    response,
  }
}

export function updateFluxioPost(postObj: any, profileId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUpdateFluxioPost())
    return updateFluxioPostApi(postObj, profileId, token)
      .then((response: any) => {
        dispatch(successUpdateFluxioPost(response))
      })
      .catch((error: any) => {
        dispatch(errorUpdateFluxioPost(error))
        throw error
      })
  }
}

export function cleanUpdateFluxioPost() {
  return {
    type: CLEAN_UPDATE_FLUXIO_POST,
  }
}

export function clearUpdateFluxioPost() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanUpdateFluxioPost())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorDeleteFluxioPost(error: any) {
  return {
    type: ERROR_DELETE_FLUXIO_POST,
    error,
  }
}

export function requestDeleteFluxioPost() {
  return {
    type: REQUEST_DELETE_FLUXIO_POST,
  }
}

export function successDeleteFluxioPost(response: any) {
  return {
    type: SUCCESS_DELETE_FLUXIO_POST,
    response,
  }
}

export function deleteFluxioPost(postId: string, profileId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestDeleteFluxioPost())
    return deleteFluxioPostApi(postId, profileId, token)
      .then((response: any) => {
        dispatch(successDeleteFluxioPost(response))
      })
      .catch((error: any) => {
        dispatch(errorDeleteFluxioPost(error))
        throw error
      })
  }
}

export function cleanDeleteFluxioPost() {
  return {
    type: CLEAN_DELETE_FLUXIO_POST,
  }
}

export function clearDeleteFluxioPost() {
  return (dispatch: AppDispatch) => {
    dispatch(cleanDeleteFluxioPost())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchIsPagePublishedStatus(error: any) {
  return {
    type: ERROR_FETCH_IS_PAGE_PUBLISHED_STATUS,
    error,
  }
}

export function requestIsPagePublishedStatus() {
  return {
    type: REQUEST_IS_PAGE_PUBLISHED_STATUS,
  }
}

export function successFetchIsPagePublishedStatus(response: any) {
  return {
    type: SUCCESS_FETCH_IS_PAGE_PUBLISHED_STATUS,
    response,
  }
}

export function fetchIsPagePublishedStatus(pageId: string, profileId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestIsPagePublishedStatus())
    return isPagePublished(pageId, profileId, token)
      .then((response: any) => {
        dispatch(successFetchIsPagePublishedStatus(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchIsPagePublishedStatus(error))
        throw error
      })
  }
}

export function clearFetchIsPagePublishedStatus() {
  return {
    type: CLEAR_FETCH_IS_PAGE_PUBLISHED_STATUS,
  }
}

export function cleanFetchIsPagePublishedStatus() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchIsPagePublishedStatus())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function updateSingleObjectPageTree(singleObject: any) {
  return {
    type: UPDATE_SINGLE_OBJECT_PAGE_TREE,
    singleObject,
  }
}

export function updatePageTreeSingleObject(singleObject: any) {
  return (dispatch: AppDispatch) => {
    dispatch(updateSingleObjectPageTree(singleObject))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchUploadedFonts(error: any) {
  return {
    type: ERROR_FETCH_UPLOADED_FONTS,
    error,
  }
}

export function requestUploadedFonts() {
  return {
    type: REQUEST_UPLOADED_FONTS,
  }
}

export function successFetchUploadedFonts(response: any) {
  return {
    type: SUCCESS_FETCH_UPLOADED_FONTS,
    response,
  }
}

export function fetchUploadedFonts(profileId: string, token: string, page = 1) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUploadedFonts())
    return uploadedFonts(profileId, token, page)
      .then((response: any) => {
        dispatch(successFetchUploadedFonts(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchUploadedFonts(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchUploadedFontsEM(error: any) {
  return {
    type: ERROR_FETCH_UPLOADED_FONTS_EM,
    error,
  }
}

export function requestUploadedFontsEM() {
  return {
    type: REQUEST_UPLOADED_FONTS_EM,
  }
}

export function successFetchUploadedFontsEM(response: any) {
  return {
    type: SUCCESS_FETCH_UPLOADED_FONTS_EM,
    response,
  }
}

export function fetchUploadedFontsEM(
  profileId: string,
  token: string,
  limit: number = 12,
  page: number = 1,
  search?: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUploadedFontsEM())
    return uploadedFontsEM(profileId, token, limit, page, search)
      .then((response: any) => {
        dispatch(successFetchUploadedFontsEM(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchUploadedFontsEM(error))
        throw error
      })
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorDeleteFont(error: any) {
  return {
    type: ERROR_DELETE_FONT,
    error,
  }
}

export function requestDeleteFont() {
  return {
    type: REQUEST_DELETE_FONT,
  }
}

export function successDeleteFont(response: any) {
  return {
    type: SUCCESS_DELETE_FONT,
    response,
  }
}

export function deleteUploadedFont(fontId: string, profileId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestDeleteFont())
    return deleteFont(fontId, profileId, token)
      .then((response: any) => {
        dispatch(successDeleteFont(response))
      })
      .catch((error: any) => {
        dispatch(errorDeleteFont(error))
        throw error
      })
  }
}

export function clearDeleteFont() {
  return {
    type: CLEAR_DELETE_FONT,
  }
}

export function cleanDeleteFont() {
  return (dispatch: AppDispatch) => {
    dispatch(clearDeleteFont())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorAddDataSetToObject(error: any) {
  return {
    type: ERROR_ADD_DATASET_TO_OBJECT,
    error,
  }
}

export function requestAddDataSetToObject() {
  return {
    type: REQUEST_ADD_DATASET_TO_OBJECT,
  }
}

export function successAddDataSetToObject(response: any) {
  return {
    type: SUCCESS_ADD_DATASET_TO_OBJECT,
    response,
  }
}

export function addDataSetToObject(profileId: string, object: any, datasetId: string, eventId: string, token: string) {
  return (dispatch: AppDispatch) => {
    return addDataSetToObjectApi(profileId, object, datasetId, eventId, token)
      .then((response: any) => {
        dispatch(successAddDataSetToObject(response))
      })
      .catch((error: any) => {
        dispatch(errorAddDataSetToObject(error))
        throw error
      })
  }
}

export function clearAddDataSetToObject() {
  return {
    type: CLEAR_ADD_DATASET_TO_OBJECT,
  }
}

export function cleanAddDataSetToObject() {
  return (dispatch: AppDispatch) => {
    dispatch(clearAddDataSetToObject())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function updateStylesCollapse(arrangement_clp: any, content_clp: any, style_clp: any) {
  return {
    type: UPDATE_STYLES_COLLAPSE,
    arrangement_clp,
    content_clp,
    style_clp,
  }
}

export function changeStylesCollapse(arrangement_clp: any, content_clp: any, style_clp: any) {
  return (dispatch: AppDispatch) => {
    dispatch(updateStylesCollapse(arrangement_clp, content_clp, style_clp))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function changeExperienceManagerType(isContentPage: any) {
  return {
    type: CHANGE_EXPERIENCE_MANAGER_TYPE,
    isContentPage,
  }
}

export function experienceManagerType(isContentPage: any) {
  return (dispatch: AppDispatch) => {
    dispatch(changeExperienceManagerType(isContentPage))
  }
}
/* -- * -- * -- * -- * -- * -- * -- * -- */

export function logFinderOpen(finderPreviewObject?: {
  finderPreview: string
  finderPreviewKey: string
  finderPreviewParams: string
}) {
  return {
    type: LOG_FINDER_OPEN,
    finderPreviewObject,
  }
}

export function changeLogFinderOpen(finderPreviewObject?: {
  finderPreview: string
  finderPreviewKey: string
  finderPreviewParams: string
}) {
  return (dispatch: AppDispatch) => {
    dispatch(logFinderOpen(finderPreviewObject))
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchPageLinks(error: any) {
  return {
    type: ERROR_FETCH_PAGE_LINKS,
    error,
  }
}

export function requestFetchPageLinks() {
  return {
    type: REQUEST_FETCH_PAGE_LINKS,
  }
}

export function successFetchPageLinks(response: any, uniqueId: string) {
  return {
    type: SUCCESS_FETCH_PAGE_LINKS,
    response,
    uniqueId,
  }
}

export function fetchPageLinks(
  profileId: string,
  objectId: string,
  token: string,
  uniqueId: string,
  orderBy?: string,
  limit?: number,
  order?: string,
  search?: string,
  page?: number
) {
  return (dispatch: AppDispatch) => {
    return fetchPageLinksApi(profileId, objectId, token, orderBy, limit, order, search, page)
      .then((response: any) => {
        dispatch(successFetchPageLinks(response, uniqueId))
      })
      .catch((error: any) => {
        dispatch(errorFetchPageLinks(error))
        throw error
      })
  }
}

export function clearFetchPageLinks() {
  return {
    type: CLEAR_FETCH_PAGE_LINKS,
  }
}

export function cleanFetchPageLinks() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchPageLinks())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchExtraMappedFields(error: any, fieldType: string) {
  return {
    type: ERROR_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS,
    error,
    fieldType,
  }
}

export function requestFetchExtraMappedFields(fieldType: string) {
  return {
    type: REQUEST_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS,
    fieldType,
  }
}

export function successFetchExtraMappedFields(response: any, fieldType: string) {
  return {
    type: SUCCESS_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS,
    response,
    fieldType,
  }
}

export function fetchExtraMappedFields(profileId: string, fieldType: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestFetchExtraMappedFields(fieldType))
    return fetchMappedFieldsExtraApi(profileId, fieldType, token)
      .then((response: any) => {
        dispatch(successFetchExtraMappedFields(response, fieldType))
      })
      .catch((error: any) => {
        dispatch(errorFetchExtraMappedFields(error, fieldType))
        throw error
      })
  }
}

export function clearFetchExtraMappedFields() {
  return {
    type: CLEAR_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS,
  }
}

export function cleanFetchExtraMappedFields() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchExtraMappedFields())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchLinkMappedFields(error: any) {
  return {
    type: ERROR_FETCH_LINK_MAPPED_FIELDS,
    error,
  }
}

export function requestFetchLinkMappedFields() {
  return {
    type: REQUEST_FETCH_LINK_MAPPED_FIELDS,
  }
}

export function successFetchLinkMappedFields(response: any) {
  return {
    type: SUCCESS_FETCH_LINK_MAPPED_FIELDS,
    response,
  }
}

export function fetchLinkMappedFields(
  profile: string,
  bobType: string,
  variant: string,
  postId: string | undefined,
  token: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestFetchLinkMappedFields())
    return fetchMappedFieldsApi(profile, bobType, variant, postId, token)
      .then((response: any) => {
        dispatch(successFetchLinkMappedFields(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchLinkMappedFields(error))
        throw error
      })
  }
}

export function clearFetchLinkMappedFields() {
  return {
    type: CLEAR_FETCH_LINK_MAPPED_FIELDS,
  }
}

export function cleanFetchLinkMappedFields() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchLinkMappedFields())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorFetchMappedFields(error: any) {
  return {
    type: ERROR_FETCH_MAPPED_FIELDS,
    error,
  }
}

export function requestFetchMappedFields() {
  return {
    type: REQUEST_FETCH_MAPPED_FIELDS,
  }
}

export function successFetchMappedFields(response: any) {
  return {
    type: SUCCESS_FETCH_MAPPED_FIELDS,
    response,
  }
}

export function fetchMappedFields(
  profile: string,
  bobType: string,
  variant: string,
  postId: string | undefined,
  token: any
) {
  return (dispatch: AppDispatch) => {
    dispatch(requestFetchMappedFields())
    return fetchMappedFieldsApi(profile, bobType, variant, postId, token)
      .then((response: any) => {
        dispatch(successFetchMappedFields(response))
      })
      .catch((error: any) => {
        dispatch(errorFetchMappedFields(error))
        throw error
      })
  }
}

export function clearFetchMappedFields() {
  return {
    type: CLEAR_FETCH_MAPPED_FIELDS,
  }
}

export function cleanFetchMappedFields() {
  return (dispatch: AppDispatch) => {
    dispatch(clearFetchMappedFields())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorSetObjectMappedField(error: any, field: string) {
  return {
    type: ERROR_SET_OBJECT_MAPPED_FIELD,
    error,
    field,
  }
}
export function errorSetObjectCPSrc(error: any) {
  return {
    type: ERROR_SET_OBJECT_CPSRC,
    error,
  }
}

export function requestSetObjectMappedField(field: string) {
  return {
    type: REQUEST_SET_OBJECT_MAPPED_FIELD,
    field,
  }
}

export function requestSetObjectCPSrc() {
  return {
    type: REQUEST_SET_OBJECT_CPSRC,
  }
}

export function successSetObjectMappedField(response: any, field: string) {
  return {
    type: SUCCESS_SET_OBJECT_MAPPED_FIELD,
    response,
    field,
  }
}

export function successSetObjectCPSrc(response: any) {
  return {
    type: SUCCESS_SET_OBJECT_CPSRC,
    response,
  }
}

export function setObjectMappedField(profileId: string, objectId: string, field: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSetObjectMappedField(field))
    return setObjectMappedFieldApi(profileId, objectId, field, token)
      .then((response: any) => {
        dispatch(successSetObjectMappedField(response, field))
      })
      .catch((error: any) => {
        dispatch(errorSetObjectMappedField(error, field))
        throw error
      })
  }
}

export function setObjectCPSrc(profileId: string, objectId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestSetObjectCPSrc())
    return setObjectCPSrcApi(profileId, objectId, token)
      .then((response: any) => {
        dispatch(successSetObjectCPSrc(response))
      })
      .catch((error: any) => {
        dispatch(errorSetObjectCPSrc(error))
        throw error
      })
  }
}
export function clearSetObjectMappedField() {
  return {
    type: CLEAR_SET_OBJECT_MAPPED_FIELD,
  }
}

export function cleanSetObjectMappedField() {
  return (dispatch: AppDispatch) => {
    dispatch(clearSetObjectMappedField())
  }
}

/* -- * -- * -- * -- * -- * -- * -- * -- */

export function errorUnSetObjectMappedField(error: any) {
  return {
    type: ERROR_UNSET_OBJECT_MAPPED_FIELD,
    error,
    field,
  }
}
export function errorUnSetObjectCPSrc(error: any) {
  return {
    type: ERROR_UNSET_OBJECT_CPSRC,
    error,
    field,
  }
}

export function requestUnSetObjectMappedField() {
  return {
    type: REQUEST_UNSET_OBJECT_MAPPED_FIELD,
    field,
  }
}
export function requestUnsetObjectCPSrc() {
  return {
    type: REQUEST_UNSET_OBJECT_CPSRC,
  }
}

export function successUnSetObjectMappedField(response: any) {
  return {
    type: SUCCESS_UNSET_OBJECT_MAPPED_FIELD,
    response,
    field,
  }
}
export function successUnSetObjectCPSrc(response: any) {
  return {
    type: SUCCESS_UNSET_OBJECT_CPSRC,
    response,
    field,
  }
}

export function unsetObjectMappedField(profileId: string, objectId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUnSetObjectMappedField())
    return unsetObjectMappedFieldApi(profileId, objectId, token)
      .then((response: any) => {
        dispatch(successUnSetObjectMappedField(response))
      })
      .catch((error: any) => {
        dispatch(errorUnSetObjectMappedField(error))
        throw error
      })
  }
}
export function unsetObjectCPSrc(profileId: string, objectId: string, token: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUnsetObjectCPSrc())
    return unsetObjectCPSrcApi(profileId, objectId, token)
      .then((response: any) => {
        dispatch(successUnSetObjectCPSrc(response))
      })
      .catch((error: any) => {
        dispatch(errorUnSetObjectCPSrc(error))
        throw error
      })
  }
}

export function clearUnSetObjectMappedField() {
  return {
    type: CLEAR_UNSET_OBJECT_MAPPED_FIELD,
  }
}

export function cleanUnSetObjectMappedField() {
  return (dispatch: AppDispatch) => {
    dispatch(clearUnSetObjectMappedField())
  }
}
export function clearUnSetObjectCPSrc() {
  return {
    type: CLEAR_UNSET_OBJECT_CPSRC,
  }
}

export function cleanUnSetObjectCPSrc() {
  return (dispatch: AppDispatch) => {
    dispatch(clearUnSetObjectCPSrc())
  }
}

export function setExperienceManagerEditorType(emEditorType: string) {
  return {
    type: SET_EXPERIENCE_MANAGER_EDITOR_TYPE,
    emEditorType,
  }
}

export function experienceManagerEditorType(emEditorType: string) {
  return (dispatch: AppDispatch) => {
    dispatch(setExperienceManagerEditorType(emEditorType))
  }
}

import { handleAlignment, handleBorder, handleHeight, handleImageFilter, handleRadius } from "../utilities/components"
import { nGlobalStyle, NGS_CLASS_SUFIX } from "./globalStylesTypes"
import * as gsType from "./globalStylesTypes"
import GlobalStylesResponsiveHandler from "./globalStylesResponsiveHandler"
import {
  globalStyleCssRenderUnsafe as ShadowStyleGsCss,
  boxShadowCss as ShadowStyleBoxShadowCss,
  textShadowCss as ShadowStyleTextShadowCss,
} from "../../../areas/public-page/shared-public-page/bob/bobStyles/shadowStyle"
import {
  globalStyleCssRenderUnsafe as ShadowStyleGsCssV2,
  boxShadowCss as ShadowStyleBoxShadowCssV2,
} from "../../../areas/public-page/shared-public-page/bob/bobStyles/shadowStyleV2"
import {
  globalStyleCssRenderUnsafe as TShadowGsCss,
  textShadowCss as TextShadowStyleCss,
} from "../../../areas/public-page/shared-public-page/bob/bobStyles/textShadowStyle"
import {
  globalStyleCssRenderUnsafe as globalStyleCssRenderUnsafeBoundary,
  renderCSSString as renderCSSStringBoundary,
} from "../../../areas/public-page/shared-public-page/bob/bobStyles/boundaryStyle"
import {
  globalStyleCssRenderUnsafe as globalStyleCssRenderUnsafeBoundaryV2,
  renderCSSString as renderCSSStringBoundaryV2,
} from "../../../areas/public-page/shared-public-page/bob/bobStyles/boundaryStyleV2"
import {
  globalStyleCssRenderUnsafe as TextStyleGlobalStyleCssRenderUnsafe,
  renderCSSString as TextStyleRenderCSSString,
} from "../../../areas/public-page/shared-public-page/bob/bobStyles/textStyle"
import {
  globalStyleCssRenderUnsafe as TextGsCssV2,
  renderCSSString as TextStyleRenderCSSStringV2,
} from "../../../areas/public-page/shared-public-page/bob/bobStyles/textStyleV2"
import { ExpManager } from "../experienceManager/types/pageTypes"
import { handleBobLabel } from "../stylesheet/stylesheetUtils"
import { ColorLabel, FontLabel, PageStylesheet } from "../stylesheet/stylesheetTypes"

/**
 * idEstilo-controllador-brk-behaviour
 *
 * @param globalStyle
 * @returns
 */
export function handleStylesGroupCss(
  globalStyle: nGlobalStyle,
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  expManager: ExpManager
) {
  /**
   * format object for responsiveProperties methods
   */
  /**
   * handle className
   */
  const className = `.${globalStyle.type}-${globalStyle.uuid}`

  /**
   * handle styles to css
   */
  const cssStyles: any = handleStylesToCss(globalStyle.type, globalStyle, stylesheetLabels, className, expManager)

  return cssStyles
}

export function handleGroupKey(type: string) {
  if (type === gsType.GS_BOUNDARY) return "spacing"
  if (type === gsType.GS_SHADOW) return "shadow"
  if (type === gsType.GS_SCREEN_FLOW) return "wrap"
  if (type === gsType.GS_HEIGHT) return "height"
  if (type === gsType.GS_BORDER_RADIUS) return "radius"
  if (type === gsType.GS_BORDER) return "border"
  if (type === gsType.GS_BG_COLORS) return "backgroundColor"
  if (type === gsType.GS_COLUMNS) return "grid"
  if (type === gsType.GS_ALIGNMENT) return "alignment"
  if (type === gsType.GS_FILTER) return "filter"
  if (type === gsType.GS_TEXT) return "text"
  if (type === gsType.GS_ICON) return "icon"
  return "unknownGroup"
}

// TODO: every controller should have 'enable' prop on it's own state
export function handleEnableProp(type: string) {
  if (type === gsType.GS_BOUNDARY) return "enable"
  if (type === gsType.GS_SHADOW) return "enable"
  if (type === gsType.GS_SCREEN_FLOW) return "enable"
  if (type === gsType.GS_HEIGHT) return "enable"
  if (type === gsType.GS_BORDER_RADIUS) return "enable"
  if (type === gsType.GS_BORDER) return "enable"
  if (type === gsType.GS_BG_COLORS) return "enable"
  if (type === gsType.GS_COLUMNS) return "enable"
  if (type === gsType.GS_ALIGNMENT) return "enable"
  if (type === gsType.GS_FILTER) return "enable"
  if (type === gsType.GS_TEXT) return "enable"
  if (type === gsType.GS_ICON) return "enable"
  return "unknownGroup"
}

export function writeCss(
  desktopCss: string,
  tabletCss: string,
  mobileCss: string,
  tabletStyles: Array<boolean>,
  mobileStyles: Array<boolean>
) {
  /**
   * write css for box shadow
   */
  const boxStyles = `
      ${desktopCss}

      ${
        tabletStyles[0] || tabletStyles[1] || tabletStyles[2]
          ? `@media all and (max-width: 992px) and (min-width: 767px){
            ${tabletCss}
          }`
          : ""
      }

      ${
        mobileStyles[0] || mobileStyles[1] || mobileStyles[2]
          ? `@media all and (max-width: 766px){
            ${mobileCss}
          }`
          : ""
      }
      `
  return boxStyles
}

export function handleStylesToCss(
  type: string,
  groupStyle: any,
  stylesheetLabels: PageStylesheet["labels"] | undefined,
  className: string,
  expManager: ExpManager
) {
  if (type === gsType.GS_SHADOW) {
    /**
     * handle api values to css values for box shadow
     */
    const boxDesktopStyles = ShadowStyleGsCss(groupStyle, "desktop", "default", "box", undefined)
    const boxHoverStyles = ShadowStyleGsCss(groupStyle, "desktop", "hover", "box", undefined)
    const boxActiveStyles = ShadowStyleGsCss(groupStyle, "desktop", "active", "box", undefined)
    // mobile
    const boxMobileStyles = ShadowStyleGsCss(groupStyle, "mobile", "default", "box", undefined)
    const boxMobileHoverStyles = ShadowStyleGsCss(groupStyle, "mobile", "hover", "box", undefined)
    const boxMobileActiveStyles = ShadowStyleGsCss(groupStyle, "mobile", "active", "box", undefined)
    // tablet
    const boxTabletStyles = ShadowStyleGsCss(groupStyle, "tablet", "default", "box", undefined)
    const boxTabletHoverStyles = ShadowStyleGsCss(groupStyle, "tablet", "hover", "box", undefined)
    const boxTabletActiveStyles = ShadowStyleGsCss(groupStyle, "tablet", "active", "box", undefined)
    /**
     * v2
     */
    const shadowColorLabelDesktop = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "desktop",
      "default",
      "color"
    ) as ColorLabel
    const shadowColorLabelHover = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "desktop",
      "hover",
      "color"
    ) as ColorLabel
    const shadowColorLabelActive = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "desktop",
      "active",
      "color"
    ) as ColorLabel
    const boxDesktopStylesV2 = ShadowStyleGsCssV2(groupStyle, "desktop", "default", undefined, shadowColorLabelDesktop)
    const boxHoverStylesV2 = ShadowStyleGsCssV2(groupStyle, "desktop", "hover", undefined, shadowColorLabelHover)
    const boxActiveStylesV2 = ShadowStyleGsCssV2(groupStyle, "desktop", "active", undefined, shadowColorLabelActive)
    // mobile
    const shadowColorLabelMobile = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "mobile",
      "default",
      "color"
    ) as ColorLabel
    const shadowColorLabelMobileHover = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "mobile",
      "hover",
      "color"
    ) as ColorLabel
    const shadowColorLabelMobileActive = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "mobile",
      "active",
      "color"
    ) as ColorLabel
    const boxMobileStylesV2 = ShadowStyleGsCssV2(groupStyle, "mobile", "default", undefined, shadowColorLabelMobile)
    const boxMobileHoverStylesV2 = ShadowStyleGsCssV2(
      groupStyle,
      "mobile",
      "hover",
      undefined,
      shadowColorLabelMobileHover
    )
    const boxMobileActiveStylesV2 = ShadowStyleGsCssV2(
      groupStyle,
      "mobile",
      "active",
      undefined,
      shadowColorLabelMobileActive
    )
    // tablet
    const shadowColorLabelTablet = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "tablet",
      "default",
      "color"
    ) as ColorLabel
    const shadowColorLabelTabletHover = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "tablet",
      "hover",
      "color"
    ) as ColorLabel
    const shadowColorLabelTabletActive = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "tablet",
      "active",
      "color"
    ) as ColorLabel
    const boxTabletStylesV2 = ShadowStyleGsCssV2(groupStyle, "tablet", "default", undefined, shadowColorLabelTablet)
    const boxTabletHoverStylesV2 = ShadowStyleGsCssV2(
      groupStyle,
      "tablet",
      "hover",
      undefined,
      shadowColorLabelTabletHover
    )
    const boxTabletActiveStylesV2 = ShadowStyleGsCssV2(
      groupStyle,
      "tablet",
      "active",
      undefined,
      shadowColorLabelTabletActive
    )

    /**
     * write css for box shadow
     * desktop hover is writen n it's own breakpoint,
     * but on the bob side the diferent breakpoint classes use the desktop default value
     */
    let boxStyles = ""

    /**
     * Render gs css for old bobs
     * support bobs in pages that still don't have compatibility in globalstyles
     */
    if (!groupStyle.compatibility || (groupStyle.compatibility && groupStyle.compatibility.oldBobs))
      boxStyles = `
        ${`${className}-box`}{
          ${ShadowStyleBoxShadowCss(boxDesktopStyles)}
        }
        @media all and (min-width: 993px){
          ${className}-box.ngsHoverState:hover{
            ${ShadowStyleBoxShadowCss(boxHoverStyles)}
          }
          ${`${className}-box`}.ngsActiveState.active{
            ${ShadowStyleBoxShadowCss(boxActiveStyles)}
          }
        }
        @media all and (max-width: 992px) and (min-width: 767px){
          ${className}-box{
            ${ShadowStyleBoxShadowCss(boxTabletStyles)}
          }
          ${className}-box.ngsTabletHoverState:hover{
            ${ShadowStyleBoxShadowCss(boxTabletHoverStyles)}
          }
          ${className}-box.ngsTabletActiveState.active{
            ${ShadowStyleBoxShadowCss(boxTabletActiveStyles)}
          }
        }
        @media all and (max-width: 766px){
          ${className}-box{
            ${ShadowStyleBoxShadowCss(boxMobileStyles)}
          }
          ${className}-box.ngsMobileHoverState:hover{
            ${ShadowStyleBoxShadowCss(boxMobileHoverStyles)}
          }
          ${className}-box.ngsMobileActiveState.active{
            ${ShadowStyleBoxShadowCss(boxMobileActiveStyles)}
          }
        }`

    /**
     * Render gs css for new bobs
     */
    if (groupStyle.compatibility?.newBobs)
      boxStyles += `
        ${`${className}-box${NGS_CLASS_SUFIX}`}{
          ${ShadowStyleBoxShadowCssV2(boxDesktopStylesV2)}
        }
        ${className}-box${NGS_CLASS_SUFIX}:hover{
          ${ShadowStyleBoxShadowCssV2(boxHoverStylesV2)}
        }
        ${`${className}-box${NGS_CLASS_SUFIX}`}.active{
          ${ShadowStyleBoxShadowCssV2(boxActiveStylesV2)}
        }
        @media all and (max-width: 992px) and (min-width: 767px){
          ${className}-box${NGS_CLASS_SUFIX}{
            ${ShadowStyleBoxShadowCssV2(boxTabletStylesV2)}
          }
          ${className}-box${NGS_CLASS_SUFIX}:hover{
            ${ShadowStyleBoxShadowCssV2(boxTabletHoverStylesV2)}
          }
          ${className}-box${NGS_CLASS_SUFIX}.active{
            ${ShadowStyleBoxShadowCssV2(boxTabletActiveStylesV2)}
          }
        }
        @media all and (max-width: 766px){
          ${className}-box${NGS_CLASS_SUFIX}{
            ${ShadowStyleBoxShadowCssV2(boxMobileStylesV2)}
          }
          ${className}-box${NGS_CLASS_SUFIX}:hover{
            ${ShadowStyleBoxShadowCssV2(boxMobileHoverStylesV2)}
          }
          ${className}-box${NGS_CLASS_SUFIX}.active{
            ${ShadowStyleBoxShadowCssV2(boxMobileActiveStylesV2)}
          }
        }`

    /**
     * handle api values to css values for text shadow
     */
    const textDesktopStyles = ShadowStyleGsCss(groupStyle, "desktop", "default", "text", undefined)
    const textHoverStyles = ShadowStyleGsCss(groupStyle, "desktop", "hover", "text", undefined)
    const textActiveStyles = ShadowStyleGsCss(groupStyle, "desktop", "active", "text", undefined)
    // mobile
    const textMobileStyles = ShadowStyleGsCss(groupStyle, "mobile", "active", "text", undefined)
    const textMobileHoverStyles = ShadowStyleGsCss(groupStyle, "mobile", "hover", "text", undefined)
    const textMobileActiveStyles = ShadowStyleGsCss(groupStyle, "mobile", "active", "text", undefined)
    // tablet
    const textTabletStyles = ShadowStyleGsCss(groupStyle, "tablet", "active", "text", undefined)
    const textTabletHoverStyles = ShadowStyleGsCss(groupStyle, "tablet", "hover", "text", undefined)
    const textTabletActiveStyles = ShadowStyleGsCss(groupStyle, "tablet", "active", "text", undefined)
    /**
     * v2
     */
    const tShadowColorLabelDesktop = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "desktop",
      "default",
      "color"
    ) as ColorLabel
    const tShadowColorLabelHover = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "desktop",
      "hover",
      "color"
    ) as ColorLabel
    const tShadowColorLabelActive = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "desktop",
      "active",
      "color"
    ) as ColorLabel
    const textDesktopStylesV2 = TShadowGsCss(groupStyle, "desktop", "default", undefined, tShadowColorLabelDesktop)
    const textHoverStylesV2 = TShadowGsCss(groupStyle, "desktop", "hover", undefined, tShadowColorLabelHover)
    const textActiveStylesV2 = TShadowGsCss(groupStyle, "desktop", "active", undefined, tShadowColorLabelActive)
    // mobile
    const tShadowColorLabelMobile = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "mobile",
      "default",
      "color"
    ) as ColorLabel
    const tShadowColorLabelMobileHover = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "mobile",
      "hover",
      "color"
    ) as ColorLabel
    const tShadowColorLabelMobileActive = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "mobile",
      "active",
      "color"
    ) as ColorLabel
    const textMobileStylesV2 = TShadowGsCss(groupStyle, "mobile", "default", undefined, tShadowColorLabelMobile)
    const textMobileHoverStylesV2 = TShadowGsCss(groupStyle, "mobile", "hover", undefined, tShadowColorLabelMobileHover)
    const textMobileActiveStylesV2 = TShadowGsCss(
      groupStyle,
      "mobile",
      "active",
      undefined,
      tShadowColorLabelMobileActive
    )
    // tablet
    const tShadowColorLabelTablet = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "tablet",
      "default",
      "color"
    ) as ColorLabel
    const tShadowColorLabelTabletHover = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "tablet",
      "hover",
      "color"
    ) as ColorLabel
    const tShadowColorLabelTabletActive = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "tablet",
      "active",
      "color"
    ) as ColorLabel
    const textTabletStylesV2 = TShadowGsCss(groupStyle, "tablet", "default", undefined, tShadowColorLabelTablet)
    const textTabletHoverStylesV2 = TShadowGsCss(groupStyle, "tablet", "hover", undefined, tShadowColorLabelTabletHover)
    const textTabletActiveStylesV2 = TShadowGsCss(
      groupStyle,
      "tablet",
      "active",
      undefined,
      tShadowColorLabelTabletActive
    )

    /**
     * write css for text shadow
     */
    let textStyles = ""

    /**
     * Render gs css for old bobs
     * support bobs in pages that still don't have compatibility in globalstyles
     */
    if (!groupStyle.compatibility || (groupStyle.compatibility && groupStyle.compatibility.oldBobs))
      textStyles = `
      ${className}-text{
        ${ShadowStyleTextShadowCss(textDesktopStyles)};
      }
      @media all and (min-width: 993px){
        ${className}-text.ngsHoverState:hover{
          ${ShadowStyleTextShadowCss(textHoverStyles)};
        }
        ${className}-text.ngsActiveState.active{
          ${ShadowStyleTextShadowCss(textActiveStyles)};
        }
      }
       @media all and (max-width: 992px) and (min-width: 767px){
        ${className}-text{
          ${ShadowStyleTextShadowCss(textTabletStyles)};
        }
        ${className}-text.ngsTabletHoverState:hover{
          ${ShadowStyleTextShadowCss(textTabletHoverStyles)};
        }
        ${className}-text.ngsTabletActiveState.active{
          ${ShadowStyleTextShadowCss(textTabletActiveStyles)};
        }
      }
      @media all and (max-width: 766px){
        ${className}-text{
          ${ShadowStyleTextShadowCss(textMobileStyles)};
        }
        ${className}-text.ngsMobileHoverState:hover{
          ${ShadowStyleTextShadowCss(textMobileHoverStyles)};
        }
        ${className}-text.ngsMobileActiveState.active{
          ${ShadowStyleTextShadowCss(textMobileActiveStyles)};
        }
      }`

    /**
     * Render gs css for new bobs
     */
    if (groupStyle.compatibility?.newBobs)
      textStyles += `
      ${className}-text${NGS_CLASS_SUFIX}{
        ${TextShadowStyleCss(textDesktopStylesV2)};
      }
      ${className}-text${NGS_CLASS_SUFIX}:hover{
        ${TextShadowStyleCss(textHoverStylesV2)};
      }
      ${className}-text${NGS_CLASS_SUFIX}.active{
        ${TextShadowStyleCss(textActiveStylesV2)};
      }
       @media all and (max-width: 992px) and (min-width: 767px){
        ${className}-text${NGS_CLASS_SUFIX}{
          ${TextShadowStyleCss(textTabletStylesV2)};
        }
        ${className}-text${NGS_CLASS_SUFIX}:hover{
          ${TextShadowStyleCss(textTabletHoverStylesV2)};
        }
        ${className}-text${NGS_CLASS_SUFIX}.active{
          ${TextShadowStyleCss(textTabletActiveStylesV2)};
        }
      }
      @media all and (max-width: 766px){
        ${className}-text${NGS_CLASS_SUFIX}{
          ${TextShadowStyleCss(textMobileStylesV2)};
        }
        ${className}-text${NGS_CLASS_SUFIX}:hover{
          ${TextShadowStyleCss(textMobileHoverStylesV2)};
        }
        ${className}-text${NGS_CLASS_SUFIX}.active{
          ${TextShadowStyleCss(textMobileActiveStylesV2)};
        }
      }`
    return `
        ${boxStyles}
        ${textStyles}
      `
  }
  if (type === gsType.GS_BOUNDARY) {
    /**
     * handle api values to css values
     */
    const desktopStyles = globalStyleCssRenderUnsafeBoundary(groupStyle, "desktop", "default")
    const hoverStyles = globalStyleCssRenderUnsafeBoundary(groupStyle, "desktop", "hover")
    const activeStyles = globalStyleCssRenderUnsafeBoundary(groupStyle, "desktop", "active")
    // mobile
    const mobileStyles = globalStyleCssRenderUnsafeBoundary(groupStyle, "mobile", "default")
    const mobileHoverStyles = globalStyleCssRenderUnsafeBoundary(groupStyle, "mobile", "hover")
    const mobileActiveStyles = globalStyleCssRenderUnsafeBoundary(groupStyle, "mobile", "active")
    // tablet
    const tabletStyles = globalStyleCssRenderUnsafeBoundary(groupStyle, "tablet", "default")
    const tabletHoverStyles = globalStyleCssRenderUnsafeBoundary(groupStyle, "tablet", "hover")
    const tabletActiveStyles = globalStyleCssRenderUnsafeBoundary(groupStyle, "tablet", "active")
    /**
     * v2
     */
    const desktopStylesV2 = globalStyleCssRenderUnsafeBoundaryV2(groupStyle, "desktop", "default")
    const hoverStylesV2 = globalStyleCssRenderUnsafeBoundaryV2(groupStyle, "desktop", "hover")
    const activeStylesV2 = globalStyleCssRenderUnsafeBoundaryV2(groupStyle, "desktop", "active")
    // mobile
    const mobileStylesV2 = globalStyleCssRenderUnsafeBoundaryV2(groupStyle, "mobile", "default")
    const mobileHoverStylesV2 = globalStyleCssRenderUnsafeBoundaryV2(groupStyle, "mobile", "hover")
    const mobileActiveStylesV2 = globalStyleCssRenderUnsafeBoundaryV2(groupStyle, "mobile", "active")
    // tablet
    const tabletStylesV2 = globalStyleCssRenderUnsafeBoundaryV2(groupStyle, "tablet", "default")
    const tabletHoverStylesV2 = globalStyleCssRenderUnsafeBoundaryV2(groupStyle, "tablet", "hover")
    const tabletActiveStylesV2 = globalStyleCssRenderUnsafeBoundaryV2(groupStyle, "tablet", "active")

    /**
     * write css
     * desktop hover is writen n it's own breakpoint,
     * but on the bob side the diferent breakpoint classes use the desktop default value
     */
    let styles = ""

    /**
     * Render gs css for old bobs
     * support bobs in pages that still don't have compatibility in globalstyles
     */
    if (!groupStyle.compatibility || (groupStyle.compatibility && groupStyle.compatibility.oldBobs))
      styles = `
      ${className}{
       ${renderCSSStringBoundary(desktopStyles)}
      }
      @media all and (min-width: 993px){
        ${className}.ngsHoverState:hover{
          ${renderCSSStringBoundary(hoverStyles)}
        }
        ${className}.ngsACtiveState.active{
          ${renderCSSStringBoundary(activeStyles)}
        }
      }
      @media all and (max-width: 992px) and (min-width: 767px){
        ${className}{
          ${renderCSSStringBoundary(tabletStyles)}
        }
        ${className}.ngsTabletHoverState:hover{
          ${renderCSSStringBoundary(tabletHoverStyles)}
        }
        ${className}.ngsTabletActiveState.active{
          ${renderCSSStringBoundary(tabletActiveStyles)}
        }
      }
      @media all and (max-width: 766px){
        ${className}{
          ${renderCSSStringBoundary(mobileStyles)}
        }
        ${className}.ngsMobileHoverState:hover{
          ${renderCSSStringBoundary(mobileHoverStyles)}
        }
        ${className}.ngsMobileActiveState.active{
          ${renderCSSStringBoundary(mobileActiveStyles)}
        }
      }`

    /**
     * Render gs css for new bobs
     */
    if (groupStyle.compatibility?.newBobs)
      styles += `
      ${className}${NGS_CLASS_SUFIX}{
        ${renderCSSStringBoundaryV2(desktopStylesV2)}
      }
      ${className}${NGS_CLASS_SUFIX}:hover{
        ${renderCSSStringBoundaryV2(hoverStylesV2)}
      }
      ${className}${NGS_CLASS_SUFIX}.active{
        ${renderCSSStringBoundaryV2(activeStylesV2)}
      }
      @media all and (max-width: 992px) and (min-width: 767px){
        ${className}${NGS_CLASS_SUFIX}{
          ${renderCSSStringBoundaryV2(tabletStylesV2)}
        }
        ${className}${NGS_CLASS_SUFIX}:hover{
          ${renderCSSStringBoundaryV2(tabletHoverStylesV2)}
        }
        ${className}${NGS_CLASS_SUFIX}.active{
          ${renderCSSStringBoundaryV2(tabletActiveStylesV2)}
        }
      }
      @media all and (max-width: 766px){
        ${className}${NGS_CLASS_SUFIX}{
          ${renderCSSStringBoundaryV2(mobileStylesV2)}
        }
        ${className}${NGS_CLASS_SUFIX}:hover{
          ${renderCSSStringBoundaryV2(mobileHoverStylesV2)}
        }
        ${className}${NGS_CLASS_SUFIX}.active{
          ${renderCSSStringBoundaryV2(mobileActiveStylesV2)}
        }
      }`

    return styles
  }
  if (type === gsType.GS_TEXT) {
    /**
     * handle api values to css values for text
     */
    const textDesktopStyles = TextStyleGlobalStyleCssRenderUnsafe(groupStyle, "desktop", "default")
    const textHoverStyles = TextStyleGlobalStyleCssRenderUnsafe(groupStyle, "desktop", "hover")
    const textActiveStyles = TextStyleGlobalStyleCssRenderUnsafe(groupStyle, "desktop", "active")
    // mobile
    const textMobileStyles = TextStyleGlobalStyleCssRenderUnsafe(groupStyle, "mobile", "active")
    const textMobileHoverStyles = TextStyleGlobalStyleCssRenderUnsafe(groupStyle, "mobile", "hover")
    const textMobileActiveStyles = TextStyleGlobalStyleCssRenderUnsafe(groupStyle, "mobile", "active")
    // tablet
    const textTabletStyles = TextStyleGlobalStyleCssRenderUnsafe(groupStyle, "tablet", "active")
    const textTabletHoverStyles = TextStyleGlobalStyleCssRenderUnsafe(groupStyle, "tablet", "hover")
    const textTabletActiveStyles = TextStyleGlobalStyleCssRenderUnsafe(groupStyle, "tablet", "active")
    /**
     * v2
     */
    const textLabelDesktop = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "desktop",
      "default",
      "fontFamily"
    ) as FontLabel
    const textLabelHover = handleBobLabel(groupStyle, stylesheetLabels, "desktop", "hover", "fontFamily") as FontLabel
    const textLabelActive = handleBobLabel(groupStyle, stylesheetLabels, "desktop", "active", "fontFamily") as FontLabel
    const textDesktopStylesV2 = TextGsCssV2(groupStyle, "desktop", "default", textLabelDesktop)
    const textHoverStylesV2 = TextGsCssV2(groupStyle, "desktop", "hover", textLabelHover)
    const textActiveStylesV2 = TextGsCssV2(groupStyle, "desktop", "active", textLabelActive)
    // mobile
    const textLabelMobile = handleBobLabel(groupStyle, stylesheetLabels, "mobile", "default", "fontFamily") as FontLabel
    const textLabelMobileHover = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "mobile",
      "hover",
      "fontFamily"
    ) as FontLabel
    const textLabelMobileActive = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "mobile",
      "active",
      "fontFamily"
    ) as FontLabel
    const textMobileStylesV2 = TextGsCssV2(groupStyle, "mobile", "default", textLabelMobile)
    const textMobileHoverStylesV2 = TextGsCssV2(groupStyle, "mobile", "hover", textLabelMobileHover)
    const textMobileActiveStylesV2 = TextGsCssV2(groupStyle, "mobile", "active", textLabelMobileActive)
    // tablet
    const textLabelTablet = handleBobLabel(groupStyle, stylesheetLabels, "tablet", "default", "fontFamily") as FontLabel
    const textLabelTabletHover = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "tablet",
      "hover",
      "fontFamily"
    ) as FontLabel
    const textLabelTabletActive = handleBobLabel(
      groupStyle,
      stylesheetLabels,
      "tablet",
      "active",
      "fontFamily"
    ) as FontLabel
    const textTabletStylesV2 = TextGsCssV2(groupStyle, "tablet", "default", textLabelTablet)
    const textTabletHoverStylesV2 = TextGsCssV2(groupStyle, "tablet", "hover", textLabelTabletHover)
    const textTabletActiveStylesV2 = TextGsCssV2(groupStyle, "tablet", "active", textLabelTabletActive)

    /**
     * write css for text shadow
     */
    let textStyles = ""

    /**
     * Render gs css for old bobs
     * support bobs in pages that still don't have compatibility in globalstyles
     */
    if (!groupStyle.compatibility || (groupStyle.compatibility && groupStyle.compatibility.oldBobs))
      textStyles = `
      ${className}{
        ${TextStyleRenderCSSString(textDesktopStyles)}
      }
      @media all and (min-width: 993px){
        ${className}.ngsHoverState:hover{
          ${TextStyleRenderCSSString(textHoverStyles)}
        }
        ${className}.ngsActiveState.active{
          ${TextStyleRenderCSSString(textActiveStyles)}
        }
      }
      @media all and (max-width: 992px) and (min-width: 767px){
        ${className}{
          ${TextStyleRenderCSSString(textTabletStyles)}
        }
        ${className}.ngsTabletHoverState:hover{
          ${TextStyleRenderCSSString(textTabletHoverStyles)}
        }
        ${className}.ngsTabletActiveState.active{
          ${TextStyleRenderCSSString(textTabletActiveStyles)}
        }
      }
      @media all and (max-width: 766px){
        ${className}{
          ${TextStyleRenderCSSString(textMobileStyles)}
        }
        ${className}.ngsMobileHoverState:hover{
          ${TextStyleRenderCSSString(textMobileHoverStyles)}
        }
        ${className}.ngsMobileActiveState.active{
          ${TextStyleRenderCSSString(textMobileActiveStyles)}
        }
      }`

    /**
     * Render gs css for new bobs
     */
    if (groupStyle.compatibility?.newBobs)
      textStyles += `
      ${className}${NGS_CLASS_SUFIX}{
        ${TextStyleRenderCSSStringV2(textDesktopStylesV2)}
      }
      ${className}${NGS_CLASS_SUFIX}:hover{
        ${TextStyleRenderCSSStringV2(textHoverStylesV2)}
      }
      ${className}${NGS_CLASS_SUFIX}.active{
        ${TextStyleRenderCSSStringV2(textActiveStylesV2)}
      }
      @media all and (max-width: 992px) and (min-width: 767px){
        ${className}${NGS_CLASS_SUFIX} {
          ${TextStyleRenderCSSStringV2(textTabletStylesV2)}}
        }
        ${className}${NGS_CLASS_SUFIX}:hover{
          ${TextStyleRenderCSSStringV2(textTabletHoverStylesV2)}
        }
        ${className}${NGS_CLASS_SUFIX}.active{
          ${TextStyleRenderCSSStringV2(textTabletActiveStylesV2)}
        }
      }
      @media all and (max-width: 766px){
        ${className}${NGS_CLASS_SUFIX} {
          ${TextStyleRenderCSSStringV2(textMobileStylesV2)}}
        }
        ${className}${NGS_CLASS_SUFIX}:hover{
          ${TextStyleRenderCSSStringV2(textMobileHoverStylesV2)}
        }
        ${className}${NGS_CLASS_SUFIX}.active{
          ${TextStyleRenderCSSStringV2(textMobileActiveStylesV2)}
        }
      }`

    return textStyles
  }

  /**
   * Still not used by bobs/controllers
   */
  if (type === gsType.GS_SCREEN_FLOW) {
    /**
     * Handled in every component that writes the container Class
     * ComponentBoilerplate.js
     * BobBackgroundImage.tsx
     */
    return ""
  }
  if (type === gsType.GS_COLUMNS) {
    /**
     * Handled in every component that writes the columns Class
     * handleColumns
     */
    return ""
  }
  if (type === gsType.GS_BG_COLORS) {
    /**
     * Handled in every bobBackgroundImage with the following properties:
     * background color with gradients
     * background image position
     */
    return ""
  }
  if (type === gsType.GS_ICON) {
    /**
     * Modify responsive handler to be used here and in the bobIcon with the following properties
     * size
     */
    return ""
  }
  if (type === gsType.GS_HEIGHT) {
    /**
     * handle api values to css values
     */
    const desktopStyles = handleHeight(groupStyle)
    const hoverStyles: any = handleHeight(groupStyle.behaviour.hover)
    const activeStyles: any = handleHeight(groupStyle.behaviour?.active)
    // mobile
    const mobileStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(groupStyle, type, "mobile")
    const mobileHoverStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { mobile: groupStyle.mobile?.behaviour?.hover },
      type,
      "mobile"
    )
    const mobileActiveStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { mobile: groupStyle.mobile?.behaviour?.active },
      type,
      "mobile"
    )
    // tablet
    const tabletStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(groupStyle, type, "tablet")
    const tabletHoverStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { tablet: groupStyle.tablet?.behaviour?.hover },
      type,
      "tablet"
    )
    const tabletActiveStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { tablet: groupStyle.tablet?.behaviour?.active },
      type,
      "tablet"
    )
    /**
     * write css
     */
    let styles = `
      ${className}{
        height: ${desktopStyles.height};
      }
      ${
        hoverStyles
          ? `${className}:hover{
            height: ${hoverStyles.height};
          }`
          : ""
      }
      ${
        activeStyles
          ? `${className}.active{
            height: ${activeStyles.height};
          }`
          : ""
      }

      ${
        tabletStyles || tabletHoverStyles || tabletActiveStyles
          ? `@media all and (max-width: 992px) and (min-width: 767px){
            ${className}{
              height: ${tabletStyles.height};
            }
            ${
              tabletHoverStyles
                ? `${className}:hover{
              height: ${tabletHoverStyles.height};
                }`
                : ""
            }
            ${
              tabletActiveStyles
                ? `${className}.active{
              height: ${tabletActiveStyles.height};
                }`
                : ""
            }
          }`
          : ""
      }

      ${
        mobileStyles || mobileHoverStyles || mobileActiveStyles
          ? `@media all and (max-width: 766px){
            ${className}{
              height: ${mobileStyles.height};
            }
            ${
              mobileHoverStyles
                ? `${className}:hover{
              height: ${mobileHoverStyles.height};
                }`
                : ""
            }
            ${
              mobileActiveStyles
                ? `${className}.active{
              height: ${mobileActiveStyles.height};
                }`
                : ""
            }
          }`
          : ""
      }
      `
    return styles
  }
  if (type === gsType.GS_BORDER_RADIUS) {
    /**
     * handle api values to css values
     */
    const desktopStyles = handleRadius({ radius: groupStyle })
    const hoverStyles: any = handleRadius({ radius: groupStyle.behaviour?.hover })
    const activeStyles: any = handleRadius({ radius: groupStyle.behaviour?.active })
    // mobile
    const mobileStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(groupStyle, type, "mobile")
    const mobileHoverStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { mobile: groupStyle.mobile?.behaviour?.hover },
      type,
      "mobile"
    )
    const mobileActiveStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { mobile: groupStyle.mobile?.behaviour?.active },
      type,
      "mobile"
    )
    // tablet
    const tabletStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(groupStyle, type, "tablet")
    const tabletHoverStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { tablet: groupStyle.tablet?.behaviour?.hover },
      type,
      "tablet"
    )
    const tabletActiveStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { tablet: groupStyle.tablet?.behaviour?.active },
      type,
      "tablet"
    )
    /**
     * write css
     */
    let styles = `
      ${className}{
        border-top-left-radius: ${desktopStyles.borderTopLeftRadius};
        border-top-right-radius: ${desktopStyles.borderTopRightRadius};
        border-bottom-left-radius: ${desktopStyles.borderBottomLeftRadius};
        border-bottom-right-radius: ${desktopStyles.borderBottomRightRadius};
      }
      ${
        hoverStyles
          ? `${className}:hover{
            border-top-left-radius: ${hoverStyles.borderTopLeftRadius};
            border-top-right-radius: ${hoverStyles.borderTopRightRadius};
            border-bottom-left-radius: ${hoverStyles.borderBottomLeftRadius};
            border-bottom-right-radius: ${hoverStyles.borderBottomRightRadius};
          }`
          : ""
      }
      ${
        activeStyles
          ? `${className}.active{
            border-top-left-radius: ${activeStyles.borderTopLeftRadius};
            border-top-right-radius: ${activeStyles.borderTopRightRadius};
            border-bottom-left-radius: ${activeStyles.borderBottomLeftRadius};
            border-bottom-right-radius: ${activeStyles.borderBottomRightRadius};
          }`
          : ""
      }

      ${
        tabletStyles || tabletHoverStyles || tabletActiveStyles
          ? `@media all and (max-width: 992px) and (min-width: 767px){
            ${className}{
              border-top-left-radius: ${tabletStyles["border-top-left-radius"]};
              border-top-right-radius: ${tabletStyles["border-top-right-radius"]};
              border-bottom-left-radius: ${tabletStyles["border-bottom-left-radius"]};
              border-bottom-right-radius: ${tabletStyles["border-bottom-right-radius"]};
            }
            ${
              tabletHoverStyles
                ? `${className}:hover{
              border-top-left-radius: ${tabletHoverStyles["border-top-left-radius"]};
              border-top-right-radius: ${tabletHoverStyles["border-top-right-radius"]};
              border-bottom-left-radius: ${tabletHoverStyles["border-bottom-left-radius"]};
              border-bottom-right-radius: ${tabletHoverStyles["border-bottom-right-radius"]};
                }`
                : ""
            }
            ${
              tabletActiveStyles
                ? `${className}.active{
              border-top-left-radius: ${tabletActiveStyles["border-top-left-radius"]};
              border-top-right-radius: ${tabletActiveStyles["border-top-right-radius"]};
              border-bottom-left-radius: ${tabletActiveStyles["border-bottom-left-radius"]};
              border-bottom-right-radius: ${tabletActiveStyles["border-bottom-right-radius"]};
                }`
                : ""
            }
          }`
          : ""
      }

      ${
        mobileStyles || mobileHoverStyles || mobileActiveStyles
          ? `@media all and (max-width: 766px){
            ${className}{
              border-top-left-radius: ${mobileStyles["border-top-left-radius"]};
              border-top-right-radius: ${mobileStyles["border-top-right-radius"]};
              border-bottom-left-radius: ${mobileStyles["border-bottom-left-radius"]};
              border-bottom-right-radius: ${mobileStyles["border-bottom-right-radius"]};
            }
            ${
              mobileHoverStyles
                ? `${className}:hover{
              border-top-left-radius: ${mobileHoverStyles["border-top-left-radius"]};
              border-top-right-radius: ${mobileHoverStyles["border-top-right-radius"]};
              border-bottom-left-radius: ${mobileHoverStyles["border-bottom-left-radius"]};
              border-bottom-right-radius: ${mobileHoverStyles["border-bottom-right-radius"]};
                }`
                : ""
            }
            ${
              mobileActiveStyles
                ? `${className}.active{
              border-top-left-radius: ${mobileActiveStyles["border-top-left-radius"]};
              border-top-right-radius: ${mobileActiveStyles["border-top-right-radius"]};
              border-bottom-left-radius: ${mobileActiveStyles["border-bottom-left-radius"]};
              border-bottom-right-radius: ${mobileActiveStyles["border-bottom-right-radius"]};
                }`
                : ""
            }
          }`
          : ""
      }
      `
    return styles
  }
  if (type === gsType.GS_BORDER) {
    /**
     * handle api values to css values
     */
    const desktopStyles = handleBorder({ border: groupStyle })
    const hoverStyles: any = handleBorder({ border: groupStyle.behaviour?.hover })
    const activeStyles: any = handleBorder({ border: groupStyle.behaviour?.active })
    // mobile
    const mobileStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(groupStyle, type, "mobile")
    const mobileHoverStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { mobile: groupStyle.mobile?.behaviour?.hover },
      type,
      "mobile"
    )
    const mobileActiveStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { mobile: groupStyle.mobile?.behaviour?.active },
      type,
      "mobile"
    )
    // tablet
    const tabletStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(groupStyle, type, "tablet")
    const tabletHoverStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { tablet: groupStyle.tablet?.behaviour?.hover },
      type,
      "tablet"
    )
    const tabletActiveStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { tablet: groupStyle.tablet?.behaviour?.active },
      type,
      "tablet"
    )
    /**
     * write css
     */
    let styles = `
      ${className}{
        border-top: ${desktopStyles.borderTop};
        border-left: ${desktopStyles.borderLeft};
        border-bottom: ${desktopStyles.borderBottom};
        border-right: ${desktopStyles.borderRight};
      }
      ${
        hoverStyles
          ? `${className}:hover{
            border-top: ${hoverStyles.borderTop};
            border-left: ${hoverStyles.borderLeft};
            border-bottom: ${hoverStyles.borderBottom};
            border-right: ${hoverStyles.borderRight};
          }`
          : ""
      }
      ${
        activeStyles
          ? `${className}.active{
            border-top: ${activeStyles.borderTop};
            border-left: ${activeStyles.borderLeft};
            border-bottom: ${activeStyles.borderBottom};
            border-right: ${activeStyles.borderRight};
          }`
          : ""
      }

      ${
        tabletStyles || tabletHoverStyles || tabletActiveStyles
          ? `@media all and (max-width: 992px) and (min-width: 767px){
            ${className}{
              border-top: ${tabletStyles["border-top"]};
              border-left: ${tabletStyles["border-left"]};
              border-bottom: ${tabletStyles["border-bottom"]};
              border-right: ${tabletStyles["border-right"]};
            }
            ${
              tabletHoverStyles
                ? `${className}:hover{
              border-top: ${tabletHoverStyles["border-top"]};
              border-left: ${tabletHoverStyles["border-left"]};
              border-bottom: ${tabletHoverStyles["border-bottom"]};
              border-right: ${tabletHoverStyles["border-right"]};
                }`
                : ""
            }
            ${
              tabletActiveStyles
                ? `${className}.active{
              border-top: ${tabletActiveStyles["border-top"]};
              border-left: ${tabletActiveStyles["border-left"]};
              border-bottom: ${tabletActiveStyles["border-bottom"]};
              border-right: ${tabletActiveStyles["border-right"]};
                }`
                : ""
            }
          }`
          : ""
      }

      ${
        mobileStyles || mobileHoverStyles || mobileActiveStyles
          ? `@media all and (max-width: 766px){
            ${className}{
              border-top: ${mobileStyles["border-top"]};
              border-left: ${mobileStyles["border-left"]};
              border-bottom: ${mobileStyles["border-bottom"]};
              border-right: ${mobileStyles["border-right"]};
            }
            ${
              mobileHoverStyles
                ? `${className}:hover{
              border-top: ${mobileHoverStyles["border-top"]};
              border-left: ${mobileHoverStyles["border-left"]};
              border-bottom: ${mobileHoverStyles["border-bottom"]};
              border-right: ${mobileHoverStyles["border-right"]};
                }`
                : ""
            }
            ${
              mobileActiveStyles
                ? `${className}.active{
              border-top: ${mobileActiveStyles["border-top"]};
              border-left: ${mobileActiveStyles["border-left"]};
              border-bottom: ${mobileActiveStyles["border-bottom"]};
              border-right: ${mobileActiveStyles["border-right"]};
                }`
                : ""
            }
          }`
          : ""
      }
      `
    return styles
  }
  if (type === gsType.GS_FILTER) {
    /**
     * handle api values to css values
     */
    const desktopStyles = handleImageFilter({ filter: groupStyle })
    const hoverStyles: any = handleImageFilter({ filter: groupStyle.behaviour?.hover })
    const activeStyles: any = handleImageFilter({ filter: groupStyle.behaviour?.active })
    // mobile
    const mobileStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(groupStyle, type, "mobile")
    const mobileHoverStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { mobile: groupStyle.mobile?.behaviour?.hover },
      type,
      "mobile"
    )
    const mobileActiveStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { mobile: groupStyle.mobile?.behaviour?.active },
      type,
      "mobile"
    )
    // tablet
    const tabletStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(groupStyle, type, "tablet")
    const tabletHoverStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { tablet: groupStyle.tablet?.behaviour?.hover },
      type,
      "tablet"
    )
    const tabletActiveStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { tablet: groupStyle.tablet?.behaviour?.active },
      type,
      "tablet"
    )
    /**
     * write css
     */
    let styles = `
      ${className}{
        filter: ${desktopStyles.filter.filter};
        WebkitFilter: ${desktopStyles.filter.WebkitFilter};
        background-color: ${desktopStyles.overlay.backgroundColor};
      }
      ${
        hoverStyles
          ? `${className}:hover{
            filter: ${hoverStyles.filter.filter};
            WebkitFilter: ${hoverStyles.filter.WebkitFilter};
            background-color: ${hoverStyles.overlay.backgroundColor};
          }`
          : ""
      }
      ${
        activeStyles
          ? `${className}.active{
            filter: ${activeStyles.filter.filter};
            WebkitFilter: ${activeStyles.filter.WebkitFilter};
            background-color: ${activeStyles.overlay.backgroundColor};
          }`
          : ""
      }

      ${
        tabletStyles || tabletHoverStyles || tabletActiveStyles
          ? `@media all and (max-width: 992px) and (min-width: 767px){
            ${className}{
              filter: ${tabletStyles.filter};
              background-color: ${tabletStyles["background-color"]};
            }
            ${
              tabletHoverStyles
                ? `${className}:hover{
              filter: ${tabletHoverStyles.filter};
              background-color: ${tabletHoverStyles["background-color"]};
                }`
                : ""
            }
            ${
              tabletActiveStyles
                ? `${className}.active{
              filter: ${tabletActiveStyles.filter};
              background-color: ${tabletActiveStyles["background-color"]};
                }`
                : ""
            }
          }`
          : ""
      }

      ${
        mobileStyles || mobileHoverStyles || mobileActiveStyles
          ? `@media all and (max-width: 766px){
            ${className}{
              filter: ${mobileStyles.filter};
              background-color: ${mobileStyles["background-color"]};
            }
            ${
              mobileHoverStyles
                ? `${className}:hover{
              filter: ${mobileHoverStyles.filter};
              background-color: ${mobileHoverStyles["background-color"]};
                }`
                : ""
            }
            ${
              mobileActiveStyles
                ? `${className}.active{
              filter: ${mobileActiveStyles.filter};
              background-color: ${mobileActiveStyles["background-color"]};
                }`
                : ""
            }
          }`
          : ""
      }
      `
    return styles
  }
  if (type === gsType.GS_ALIGNMENT) {
    /**
     * Every property from the json is computed at the head of the page
     * every component writes the specific flexDirection
     */
    /**
     * handle api values to css values
     */
    const desktopStyles = handleAlignment(groupStyle)
    const hoverStyles: any = handleAlignment(groupStyle.behaviour.hover)
    const activeStyles: any = handleAlignment(groupStyle.behaviour?.active)
    // mobile
    const mobileStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(groupStyle, type, "mobile", {
      alignment: { flexDirection: "" },
    })
    const mobileHoverStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { mobile: groupStyle.mobile?.behaviour?.hover },
      type,
      "mobile",
      { alignment: { flexDirection: "" } }
    )
    const mobileActiveStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { mobile: groupStyle.mobile?.behaviour?.active },
      type,
      "mobile",
      { alignment: { flexDirection: "" } }
    )
    // tablet
    const tabletStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(groupStyle, type, "tablet", {
      alignment: { flexDirection: "" },
    })
    const tabletHoverStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { tablet: groupStyle.tablet?.behaviour?.hover },
      type,
      "tablet",
      { alignment: { flexDirection: "" } }
    )
    const tabletActiveStyles: any = GlobalStylesResponsiveHandler.responsiveProperties(
      { tablet: groupStyle.tablet?.behaviour?.active },
      type,
      "tablet",
      { alignment: { flexDirection: "" } }
    )
    /**
     * write css
     * Both alignSelf and flexDirection are written in each component
     */
    let styles = `
      ${className}{
        justify-content: ${desktopStyles.justifyContent};
        align-items: ${desktopStyles.alignItems};
        display: ${desktopStyles.display};
      }
      ${
        hoverStyles
          ? `${className}:hover{
            justify-content: ${hoverStyles.justifyContent};
            align-items: ${hoverStyles.alignItems};
            display: ${hoverStyles.display};
          }`
          : ""
      }
      ${
        activeStyles
          ? `${className}.active{
            justify-content: ${activeStyles.justifyContent};
            align-items: ${activeStyles.alignItems};
            display: ${activeStyles.display};
          }`
          : ""
      }

      ${
        tabletStyles || tabletHoverStyles || tabletActiveStyles
          ? `@media all and (max-width: 992px) and (min-width: 767px){
            ${className}{
              justify-content: ${tabletStyles["justify-content"]};
              align-items: ${tabletStyles["align-items"]};
              display: ${tabletStyles.display};
            }
            ${
              tabletHoverStyles
                ? `${className}:hover{
              justify-content: ${tabletHoverStyles["justify-content"]};
              align-items: ${tabletHoverStyles["align-items"]};
              display: ${tabletHoverStyles.display};
                }`
                : ""
            }
            ${
              tabletActiveStyles
                ? `${className}.active{
              justify-content: ${tabletActiveStyles["justify-content"]};
              align-items: ${tabletActiveStyles["align-items"]};
              display: ${tabletActiveStyles.display};
                }`
                : ""
            }
          }`
          : ""
      }

      ${
        mobileStyles || mobileHoverStyles || mobileActiveStyles
          ? `@media all and (max-width: 766px){
            ${className}{
              justify-content: ${mobileStyles["justify-content"]};
              align-items: ${mobileStyles["align-items"]};
              display: ${mobileStyles.display};
            }
            ${
              mobileHoverStyles
                ? `${className}:hover{
              justify-content: ${mobileHoverStyles["justify-content"]};
              align-items: ${mobileHoverStyles["align-items"]};
              display: ${mobileHoverStyles.display};
                }`
                : ""
            }
            ${
              mobileActiveStyles
                ? `${className}.active{
              justify-content: ${mobileActiveStyles["justify-content"]};
              align-items: ${mobileActiveStyles["align-items"]};
              display: ${mobileActiveStyles.display};
                }`
                : ""
            }
          }`
          : ""
      }
      `
    return styles
  }
}

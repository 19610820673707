import React from "react"
import Circles from "../../../components/loadings/circles"
import {
  OrderDesc,
  OrderKey,
  OrderMap,
  OrderOptions,
  parseOrderKey,
} from "../../../modules/shared-modules/content/contentLibraryTypes"
import { Post } from "../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { handleContentSrcLink } from "../../../modules/shared-modules/mediaLibrary/mediaLibraryUtils"
import SelectController from "../experienceManager/finder/inputs/SelectController"
import { ContentLibraryResponse, JobsApi, Post1 } from "../jobs/jobsApi"
import { IndexedId, ProfileName } from "../jobs/jobsSchema"
import TagManager from "./tagManager"

interface OwnProps {
  profile: ProfileName
  selected: IndexedId[]
  onPostSelected: (post: Post1[]) => void
  client: {
    getContentLibrary: JobsApi["getContentLibrary"]
  }
  selectionValid?: (selected: IndexedId[]) => boolean
}

type ContentLibraryState = {
  search: string
  order: OrderKey
  limit: number
  selected: IndexedId[]
  response: {
    loading: boolean
    error: boolean
    contentLibrary: ContentLibraryResponse | undefined
  }
  posts: Post1[]
  page: number
  timeout: any
  expandedPost: IndexedId | undefined
}

type ContentLibraryProps = OwnProps

const defaultOrder = OrderDesc

class PostSearch extends React.Component<ContentLibraryProps, ContentLibraryState> {
  constructor(props: ContentLibraryProps) {
    super(props)
    this.state = {
      search: "",
      order: defaultOrder.value,
      limit: 30,
      selected: props.selected,
      response: {
        loading: false,
        error: false,
        contentLibrary: undefined,
      },
      posts: [],
      page: 1,
      timeout: undefined,
      expandedPost: undefined,
    }
    this.loadMore = this.loadMore.bind(this)
    this.onScrollEnd = this.onScrollEnd.bind(this)
    this.selectPost = this.selectPost.bind(this)
    this.fetchInitial = this.fetchInitial.bind(this)
  }

  componentDidMount(): void {
    this.fetchInitial()
  }

  get(withCursor: boolean): Promise<ContentLibraryResponse> {
    return this.props.client.getContentLibrary(
      this.state.order,
      this.state.limit,
      this.state.search,
      withCursor ? this.state.response.contentLibrary?.nextCursor : undefined
    )
  }

  fetchInitial(): void {
    this.setState(
      {
        response: {
          loading: true,
          error: false,
          contentLibrary: undefined,
        },
      },
      () => this.get(false).then((r) => this.updatePosts(r, true))
    )
  }

  fetchMore(): void {
    this.setState(
      (prev) => ({
        response: {
          loading: true,
          error: false,
          contentLibrary: prev.response.contentLibrary,
        },
      }),
      () => this.get(true).then((r) => this.updatePosts(r, false))
    )
  }

  updatePosts(sv: ContentLibraryResponse, resetResultSet: boolean): void {
    this.setState((prev) => {
      const posts: Post1[] = resetResultSet ? sv.items : [...prev.posts, ...sv.items]
      return {
        response: { loading: false, error: false, contentLibrary: sv },
        posts,
      }
    })
  }

  onScrollEnd(e: any): void {
    if (e.target.offsetHeight + e.target.scrollTop + 200 >= e.target.scrollHeight) {
      this.loadMore()
    }
  }

  selectPost(post: Post1, currentlySelected: boolean): void {
    // if selected, unselect, if not selected, select
    return currentlySelected
      ? this.setState((prev) => ({ selected: prev.selected.filter((id) => id !== post.id) }))
      : this.setState((prev) => ({ selected: [...prev.selected, post.id] }))
  }

  /**
   *
   * @param e
   * We force the page to be 1 so the search starts at page 1
   */
  onSearchBoxTextChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value
    //Fetch content when user deletes search input
    const shouldUpdate = value === ""
    this.setState(
      {
        ...this.state,
        search: value,
        page: 1,
      },
      () => {
        if (shouldUpdate) this.fetchInitial()
      }
    )
  }

  onSearchBoxKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === "Enter") this.fetchInitial()
  }

  onSearchButtonClick(): void {
    if (this.state.search !== "") this.fetchInitial()
  }

  isLoading(): boolean {
    return this.state.response.loading
  }

  isLoadingContent(): boolean {
    return this.state.response.loading
  }

  noMorePosts(): boolean {
    return this.state.response.contentLibrary?.hasMore === false
  }

  onOrderChange(option: any): void {
    const newOrder = parseOrderKey(option?.value) ?? defaultOrder.value
    this.setState({ ...this.state, page: 1, order: newOrder }, () => this.fetchInitial())
  }

  loadMore(): void {
    if (!this.isLoading() && !this.noMorePosts()) this.fetchMore()
  }

  renderMedia(post: Post1): string {
    if (post?.picture_orig && post?.picture_orig !== "") {
      return handleContentSrcLink(this.state.response.contentLibrary?.media, this.props.profile, post.picture_orig)
        .mediaSrc
    }
    return "https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png"
  }

  getCover(post: Post): string | boolean {
    if (post?.cover && post?.cover !== "") {
      return handleContentSrcLink(this.state.response.contentLibrary?.media, this.props.profile, post.cover).mediaSrc
    }
    return false
  }

  renderSearch(): JSX.Element {
    return (
      <div className='form-group mr-2'>
        <div className='input-group'>
          <input
            name='search'
            value={this.state.search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSearchBoxTextChange(e)}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => this.onSearchBoxKeyPress(e)}
            placeholder='Search'
            type='text'
            className='form-control'
          />
          <div className='input-group-append'>
            <div onClick={() => this.onSearchButtonClick()} className='input-group-text'>
              <i className='fas fa-search'></i>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderFilter(): JSX.Element {
    return (
      <div className='form-group'>
        <SelectController
          placeholder='Order by'
          options={OrderOptions}
          onChange={(e: any) => this.onOrderChange(e)}
          selectedValue={OrderMap[this.state.order]}
          value={OrderMap[this.state.order]}
        />
      </div>
    )
  }

  renderSearchFilters(): JSX.Element {
    return (
      <div className='fluxio-form fluxio-form-compact d-flex'>
        <div className='w-100'>{this.renderSearch()}</div>
        <div className='w-100'>{this.renderFilter()}</div>
      </div>
    )
  }

  renderSchemaInfo(post: any): JSX.Element {
    if (post.schemaId) {
      return <i className='tag'>Custom Schema</i>
    }
    return <></>
  }

  renderCover(post: Post): JSX.Element {
    const coverUrl = this.getCover(post)
    if (coverUrl && typeof coverUrl === "string") {
      return (
        <div className='cover'>
          <img src={coverUrl} alt='post media' className='card-img-top' />
        </div>
      )
    }
    return <></>
  }

  toggleShow(indexedId: IndexedId) {
    if (this.state.expandedPost !== indexedId) {
      this.setState({ expandedPost: indexedId })
    } else {
      this.setState((prevState) => ({
        expandedPost: prevState.expandedPost === indexedId ? undefined : indexedId,
      }))
    }
  }

  /**
   *
   * @param response
   * @returns post html
   *
   * Disable posts if they exist
   * on the disabledPosts prop list
   */
  renderPosts(posts: Post1[]): JSX.Element {
    if (posts.length > 0) {
      return (
        <>
          {posts.map((post: Post1) => {
            const selected = this.state.selected.some((selectedPost: IndexedId) => selectedPost === post.id)
            //const detailOpen = this.state.selected.some((selectedPost: IndexedId) => selectedPost === post.id)
            return (
              <div key={post.id} onClick={() => this.selectPost(post, selected)} className='card-s'>
                <div className={`card-s-grid`}>
                  <div className='card-s-media-box'>
                    {/*<img src={this.renderMedia(post)} alt='post media' className='card-media' />*/}
                    {/* {this.renderCover(post)} */}
                    <div className='actions'>
                      <i className={`fas fa-check-circle ${selected ? "selected" : "not-selected"}`}></i>
                    </div>
                    {post.description && post.description !== "" && (
                      <i
                        className={`fas ${
                          this.state.expandedPost === post.id ? "fa-chevron-down" : "fa-chevron-right"
                        }`}
                        onClick={() => this.toggleShow(post.id)}
                      ></i>
                    )}
                  </div>

                  <div className='card-s-body'>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h2 className='title title-overflow' title={post.title}>
                        {post.title}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className={`card-s-body-expand ${this.state.expandedPost === post.id ? "show" : ""}`}>
                  <div className='description' dangerouslySetInnerHTML={{ __html: post.description }} />
                  {post.url && (
                    <p className='link'>
                      Link:{" "}
                      <a href={post.url} rel='noopener noreferrer' target='_blank' className='post-link'>
                        {post.url}
                      </a>
                    </p>
                  )}
                  <div className='card-s-tags'>
                    {this.renderSchemaInfo(post)}
                    {post.tags_profile && <TagManager postTags={post.tags_profile} />}
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )
    }
    return <p className='msg p-4'>No posts to render!</p>
  }

  renderContent(posts: Post1[]): JSX.Element {
    return (
      <div
        className={`
    `}
      >
        {this.renderPosts(posts)}
      </div>
    )
  }

  renderLoadMore(): JSX.Element {
    if (!this.noMorePosts()) {
      if (this.isLoadingContent()) {
        return (
          <div>
            <i className='fas fa-spin fa-spinner'></i>
          </div>
        )
      }
    } else if (
      this.state.response.contentLibrary?.hasMore === false &&
      this.state.response.contentLibrary.items &&
      this.state.response.contentLibrary.items.length > 0
    ) {
      return (
        <div className='msg pt-4 pb-4'>
          <i className='fas fa-exclamation-circle'></i> No more content to load.
        </div>
      )
    }
    return <></>
  }

  renderConfirm(): JSX.Element {
    const validator = this.props.selectionValid ?? ((s) => s.length > 0)
    const disabled: boolean = !validator(this.state.selected)

    const n: number = this.state.selected.length
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          type='button'
          disabled={disabled}
          className='w-50 btn btn-fluxio btn-primary btn-block'
          onClick={() => {
            const selected: Set<IndexedId> = new Set(this.state.selected)
            const selectedPosts = this.state.posts.filter((post) => selected.has(post.id))
            this.props.onPostSelected(selectedPosts)
          }}
        >
          Confirm selection ({n} selected)
        </button>
      </div>
    )
  }

  render(): JSX.Element {
    return (
      <div className='content-library'>
        <div className='fh-screen fh-screen-em pl-2 pb-2 pr-2}' onScroll={this.onScrollEnd}>
          <div
            className='fh-screen-actions pt-2 pb-2'
            style={{ backgroundColor: "rgba(255,255,255,1)" /**override alpha */ }}
          >
            <div>
              <div className='w-100 mr-2'>{this.renderSearchFilters()}</div>
            </div>
          </div>
          {this.isLoadingContent() ?? <Circles />}
          {this.state.response.contentLibrary && this.renderContent(this.state.posts)}
          {this.renderLoadMore()}
        </div>
        {this.renderConfirm()}
      </div>
    )
  }
}

export default PostSearch

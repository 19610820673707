import { useEffect, useState } from "react"
import { Err, Ok, Result } from "../monads/result/result"

export function useLoader<A>(promise: () => Promise<A>, dependencyList: any[]): Result<A, any> | undefined {
  const [state, setState] = useState<Result<A, any> | undefined>(undefined)

  useEffect(() => {
    promise()
      .then((a) => setState(Ok(a)))
      .catch((e) => setState(Err(e)))
  }, dependencyList) // eslint-disable-line react-hooks/exhaustive-deps

  return state
}

import React, { Fragment, useEffect } from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Router, Route, Switch, Redirect, RouteComponentProps } from "react-router-dom"
import loadable from "@loadable/component"
//import registerServiceWorker from './registerServiceWorker'

// @ts-ignore
import { Frontload } from "react-frontload"

//Language location
import ReduxConnectIntlProvider from "./reduxConnectIntlProvider"

//Self origin
import configureStore from "./store/configureStore"
import RedirectApp from "./areas/skynet/RedirectApp"
import { DoJobRoutes, mkAppRedirect } from "./areas/skynet/sidebar/DoJobRoutes"

//css fixed
const AsyncLoginPage = loadable(() => import("./areas/authentication/loginPage/login"))
const AsyncSSOLogin = loadable(() => import("./sso/login"))
const AsyncSSOSignup = loadable(() => import("./sso/signup"))
const AsyncSSOSignupConfirmation = loadable(() => import("./sso/signupConfirmation"))
const AsyncSSOCommunityRegistration = loadable(() => import("./sso/communityRegistration"))
const AsyncRegister = loadable(() => import("./areas/authentication/registerPage/register"))
const AsyncResetPasswordPage = loadable(() => import("./areas/authentication/recover/ResetPasswordPage"))
const AsyncRecover = loadable(() => import("./areas/authentication/recover/recoverPassword"))
const AsyncConfirm = loadable(() => import("./areas/authentication/registerPage/Confirm"))
const AsyncError404 = loadable(() => import("./modules/error/Error404"))
//css not fixed
const AsyncExperience = loadable(() => import("./areas/public-page/experience"))
const AsyncSkynet = loadable(() => import("./areas/skynet/Skynet"))
const AsyncTwitterCallback = loadable(() => import("./areas/twitter/callback"))
const AsyncStaging = loadable(() => import("./areas/skynet/experienceManager/stagingPreview"))

//Middlware with logger
const store = configureStore()
const appSubdomainPattern = /^app\./
const demoSubdomainPattern = /^demo\./
// Check if request has subdomain and points to the app
let shouldRedirect = false
if (appSubdomainPattern.test(window.location.hostname)) {
  shouldRedirect = true
}

// Check if the request is for a demo of a template
let subDomain: boolean | string = false
if (demoSubdomainPattern.test(window.location.hostname)) {
  subDomain = "demo"
}

// if we hit a path starting with /d, redirect (forcing a reload) to that path
// but add a hash so that we don't loop infinitely (would happend in local dev since there is not other app)
const RedirectDoJob: React.FC<RouteComponentProps> = (props) => {
  useEffect(() => {
    if (window.location.hash === "fluxio" || window.location.hash === "#fluxio") return
    else {
      window.location.replace(props.location.pathname + "#fluxio")
      window.location.reload()
    }
  }, [props.location.pathname])

  return <></>
}
class Client extends React.Component {
  render() {
    return (
      <Fragment>
        <Provider store={store}>
          <ReduxConnectIntlProvider>
            <Router>
              <Switch>
                <Route path='/d' component={RedirectDoJob} />
                <Route path='/register/:app' component={AsyncRegister} />
                <Route path='/register' component={AsyncRegister} />
                <Route path='/recover' component={AsyncRecover} />
                <Route path='/login/:app' component={AsyncLoginPage} />
                <Route path='/login' component={AsyncLoginPage} />
                <Route path='/reset/:token' component={AsyncResetPasswordPage} />
                <Route path='/reset' component={AsyncResetPasswordPage} />
                <Route path='/confirm/:token' component={AsyncConfirm} />
                <Route path='/confirm' component={() => <Redirect to={"/login"} />} />
                <Route path='/log-in' component={AsyncSSOLogin} />
                <Route path='/sign-up' component={AsyncSSOSignup} />
                <Route path='/sign-up-confirmation/:id' component={AsyncSSOSignupConfirmation} />
                <Route path='/sign-up-confirmation' component={AsyncSSOSignupConfirmation} />
                <Route path='/community-registration' component={AsyncSSOCommunityRegistration} />
                <Route
                  path='/t/:profile/dojob_'
                  render={(r) => <DoJobRoutes profile={r.match.params.profile} redirect={mkAppRedirect(r)} />}
                />
                <Route path='/t/:profile/:section/:page/:subpage/:pageId' component={AsyncSkynet} />
                <Route path='/t/:profile/:section/:page/:subpage' component={AsyncSkynet} />
                <Route path='/t/:profile/:section/:page' component={AsyncSkynet} />
                <Route path='/t/:profile/:section' component={AsyncSkynet} />
                <Route path='/t/:profile' component={RedirectApp} />
                <Route path='/t' component={AsyncSkynet} />
                <Route path='/visualtest/:profile/:section/:page/:subpage/:pageId' component={AsyncStaging} />
                <Route path='/visualtest/:profile/:section/:page/:subpage' component={AsyncStaging} />
                <Route path='/visualtest/:profile/:section/:page' component={AsyncStaging} />
                <Route path='/visualtest/:profile/:section' component={AsyncStaging} />
                <Route path='/visualtest/:profile' component={AsyncStaging} />
                <Route path='/visualtest' component={AsyncStaging} />
                <Route exact path='/404' component={AsyncError404} />
                <Route path='/socials/callback' component={AsyncTwitterCallback} />
                {window.location.hostname === "localhost" && (
                  <Route path='/cypress/page/:id' component={AsyncStaging} />
                )}
                <Frontload noServerRender={true}>
                  <Switch>
                    <Route path='/:localId/:tabId/:pillId/:topicId/:postId/:euroId' component={AsyncExperience} />
                    <Route path='/:localId/:tabId/:pillId/:topicId/:postId' component={AsyncExperience} />
                    <Route path='/:localId/:tabId/:pillId/:topicId' component={AsyncExperience} />
                    <Route path='/:localId/:tabId/:pillId' component={AsyncExperience} />
                    <Route path='/:localId/:tabId' component={AsyncExperience} />
                    <Route path='/:localId' component={AsyncExperience} />
                    <Route
                      exact
                      path='/'
                      component={() =>
                        shouldRedirect ? <Redirect to={"/t"} /> : <AsyncExperience subDomain={subDomain} />
                      }
                    />
                  </Switch>
                </Frontload>
              </Switch>
            </Router>
          </ReduxConnectIntlProvider>
        </Provider>
      </Fragment>
    )
  }
}

export default Client

import React from 'react'
import ErrorHandler from '../../modules/error/errorHandler'
import '../../stylesheets/modules/components/_circles.sass'

interface CirclesProps {
  error?: any
  overriteClass?: string
  msg?: string
}

class Circles extends React.Component<CirclesProps> {
  render() {
    const arrErrorsMsg = ErrorHandler.arrErrors(this.props.error)
    return (
      <div className={this.props.overriteClass ? this.props.overriteClass : 'col-12 loading-div'}>
        {this.props.error ?
          <i className="fas fa-times-circle" aria-hidden="true"></i> :
          <img className='loading-ball' src="https://cdn.fluxio.cloud/meshapp/ellipsis.svg" alt="Loading" />}
        {arrErrorsMsg}
        {this.props.msg ? this.props.msg : []}
      </div>
    )
  }
}

export default Circles

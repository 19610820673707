import { useState } from "react"
import AssistantPanel, { EmptyAssistantPanel } from "./assistantPanel"
import { DocumentViewerActions } from "./documentViewerActions"
import { CustomTab, CustomTabs } from "./jobDetail" // TODO: fix
import { JobsApi } from "./jobsApi"
import { FluxioPostId, ProfileName } from "./jobsSchema"
import { v4 } from "uuid"

export const Chats: React.FC<{
  postId?: FluxioPostId
  jobsClient: JobsApi
  profileName: ProfileName
  actions: DocumentViewerActions.All
}> = ({ postId, jobsClient, profileName, actions }) => {
  const init: string = v4()
  const [tab, setTab] = useState<string>(init)
  const [tabs, setTabs] = useState<string[]>([init]) // start with one tab

  if (postId === undefined) return <EmptyAssistantPanel />
  const post: FluxioPostId = postId

  const addTab = () => setTabs((prev) => [...prev, v4()])
  const removeCurrentTab = () => {
    // remove current tab from state
    setTabs((prev) => prev.filter((i) => i !== tab))
    // select the previous tab, if there's one available
    const prevTab: string | undefined = tabs[tabs.findIndex((i) => i === tab) - 1]
    if (prevTab) setTab(prevTab)
  }

  const selectTab = (key: string) => setTab(key)

  return (
    <div style={{ marginTop: "5px" }}>
      <i className='fa fa-plus' title='Add a new conversation' onClick={() => addTab()} /> {/* ugllyyyyyyy */}
      <i className='fa fa-minus' title='Remove the selected conversation' onClick={() => removeCurrentTab()} />
      <CustomTabs
        className='job-tab' // TODO: fix
        mountOnEnter={false}
        unmountOnExit={false} // this means that the components aren't unmounted, which means we can cheat and avoid moving the state up
        defaultActiveKey={tab}
        activeKey={tab}
        onSelect={(key: string) => selectTab(key)}
      >
        {tabs.map((key, idx) => (
          <CustomTab key={key} selected={tab === key} eventKey={key} title={idx}>
            <AssistantPanel profileName={profileName} postId={post} jobsClient={jobsClient} actions={actions} />
          </CustomTab>
        ))}
      </CustomTabs>
    </div>
  )
}

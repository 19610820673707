import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function contentFormulaReducer(state = initialState.contentFormulaReducer, action: any) {
  switch (action.type) {
    case types.REQUEST_FORMULA_CONTENT:
      return {
        isFetching: true,
        isInvalid: false,
        formulaId: action.formulaId,
        lastItem: action.until,
        hasContent: false
      }
    case types.RECEIVE_FORMULA_CONTENT:
      return {
        ...state,
        isFetching: false,
        isInvalid: false,
        formulaId: action.formulaId,
        formulaContent: action.formulaContent,
        hasContent: true
      }
    case types.INVALID_FORMULA_ID:
      return {
        ...state,
        isFetching: false,
        isInvalid: true,
      }
    default:
      return state
  }
}

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function verticalMenuReducer(state = initialState.verticalMenuReducer, action) {

  switch (action.type) {
    case types.ERROR_VERTICAL_MENU_POSTS:
      return {
        ...state,
        error: action.error,
        posts: action.posts,
        loading: action.loading
      }
    case types.REQUEST_VERTICAL_MENU_POSTS:
      return {
        ...state,
        error: action.error,
        loading: action.loading,
        posts: action.posts
      }
    case types.RECEIVE_VERTICAL_MENU_POSTS:
      return {
        ...state,
        error: action.error,
        loading: action.loading,
        posts: action.posts.response.content
      }
    default:
      return state
  }

}

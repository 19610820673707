import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function tourReducer(state = initialState.tourReducer, action: any) {
  switch (action.type) {
    case types.ERROR_CHANGE_TOURS:
      return {
        ...state,
        errorChangeTours: action.error,
        loadingChangeTours: false,
        successChangeTours: undefined
      }
    case types.REQUEST_CHANGE_TOURS:
      return {
        ...state,
        errorChangeTours: false,
        loadingChangeTours: true,
        successChangeTours: undefined
      }
    case types.SUCCESS_CHANGE_TOURS:
      let tours = { ...action.response }
      if (tours.commands)
        delete tours.commands
      return {
        ...state,
        errorChangeTours: false,
        loadingChangeTours: false,
        successChangeTours: action.response,
        successFetchTours: tours
      }
    case types.CHANGE_EM_TOUR_STATE:
      return {
        ...state,
        isEMTourOpen: action.isEMTourOpen,
      }
    case types.ERROR_FETCH_TOURS:
      return {
        ...state,
        errorFetchTours: action.error,
        loadingTours: false,
        successFetchTours: undefined
      }
    case types.REQUEST_TOURS:
      return {
        ...state,
        errorFetchTours: false,
        loadingTours: true,
        successFetchTours: undefined
      }
    case types.SUCCESS_FETCH_TOURS:
      return {
        ...state,
        errorFetchTours: false,
        loadingTours: false,
        successFetchTours: action.response
      }
    case types.CHANGE_EM_TOUR_STEP:
      return {
        ...state,
        emTourStep: action.emTourStep
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    default:
      return state
  }

}

import { MediaObject } from "../experienceManager/types/contentTypes"

// should match react-select value type
type OrderOption = { label: string; value: OrderKey }

export const OrderAsc: OrderOption = { label: "Created (Asc.)", value: "created-asc" }
export const OrderDesc: OrderOption = { label: "Created (Desc.)", value: "created-desc" }

export function parseOrderKey(str: string | undefined): OrderKey | undefined {
  // prettier-ignore
  return str === "created-asc" ? "created-asc" : 
         str === "created-desc" ? "created-desc" :
         undefined
}

const OrderByOptions: Array<OrderOption> = [OrderAsc, OrderDesc]
// const OrderByOptions: Array<OrderOption> = [
//   { label: "Created (Asc.)", value: "created-asc" },
//   { label: "Created (Desc.)", value: "created-desc" },
//   { label: "Alphabetical A-Z", value: "alpha-az" },
//   { label: "Alphabetical Z-A", value: "alpha-za" },
// ]

export type OrderKey = "created-asc" | "created-desc"
export const OrderMap = {
  "created-asc": OrderAsc,
  "created-desc": OrderDesc,
}

export const OrderOptions: OrderOption[] = [OrderAsc, OrderDesc]

type OrderBy = "created" | "alphabetical"
type Order = "asc" | "desc"
type FormAction = "create" | "edit"
type ServerContent = {
  items: Array<any> | undefined
  hasMore: boolean
  limit: number
  nextCursor: string
  sort: string
  totalItems: number
  media?: { [x: string]: MediaObject }
  linkedPosts?: { [x: string]: MediaObject }
}

const DefaultFilterValues = {
  search: "",
  orderBy: "created" as OrderBy,
  order: "desc" as Order,
  page: 1,
  limit: 10,
}

type FieldTextType = {
  fieldType: "text"
  name: string
  label: string
  required: boolean
}

type FieldTextAreaType = {
  fieldType: "textarea"
  name: string
  label: string
  required: boolean
}

type FieldMediaType = {
  fieldType: "media"
  name: string
  label: string
  required: boolean
}

type FieldSelectType = {
  fieldType: "select"
  name: string
  label: string
  required: boolean
  selectChoices: string[]
}

type FieldLatLonType = {
  fieldType: "latlon"
  name: string
  label: string
  required: boolean
}

type FieldPostList = {
  fieldType: "postlist"
  name: string
  label: string
  required: boolean
}

type AllFieldTypes =
  | FieldTextType
  | FieldTextAreaType
  | FieldMediaType
  | FieldSelectType
  | FieldLatLonType
  | FieldPostList

export { OrderByOptions, DefaultFilterValues }

export type {
  OrderBy,
  Order,
  OrderOption,
  FormAction,
  ServerContent,
  FieldTextType,
  FieldTextAreaType,
  FieldMediaType,
  FieldSelectType,
  FieldLatLonType,
  FieldPostList,
  AllFieldTypes,
}

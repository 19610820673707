import { ZodBranded, ZodEffects, ZodType, z } from "zod"
import { v4 } from "uuid"
import { Err, Ok, Result } from "../monads/result/result"

// TODO: this v4 uuid is badly typed, might as well use our own branded type

const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i

// https://github.com/colinhacks/zod/issues/2468
// use .uuid when this is fixed

function parseUuid<B extends string>(str: string & z.BRAND<B>, ctx: z.RefinementCtx): string & z.BRAND<B> {
  if (uuidRegex.test(str)) {
    return str
  } else {
    ctx.addIssue({ code: z.ZodIssueCode.custom, message: "Invalid uuid" })
    return z.NEVER
  }
}

export function schema<TAG extends string>(): ZodEffects<ZodBranded<z.ZodString, TAG>> {
  return z.string().brand<TAG>().transform(parseUuid)
}

export function wrap<TAG extends string>(
  schema: ZodEffects<ZodBranded<z.ZodString, TAG>>,
  u: typeof v4
): string & z.BRAND<TAG> {
  return schema.parse(u)
}

export function parse<TAG extends string>(
  schema: ZodEffects<ZodBranded<z.ZodString, TAG>>,
  u: string
): Result<string & z.BRAND<TAG>, string> {
  let p = schema.safeParse(u)
  if (p.success) {
    return Ok(p.data)
  } else {
    return Err(p.error.toString())
  }
}

export function random<TAG extends string>(schema: ZodEffects<ZodBranded<z.ZodString, TAG>>): string & z.BRAND<TAG> {
  return parse(schema, v4()).unwrap()
}

export declare type TypeOf<T extends ZodType<any, any, any>> = T["_output"]
export type { TypeOf as infer }

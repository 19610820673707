import React, { Fragment } from 'react'
//@ts-ignore no types exist for rc-color-picker
import { Panel as ColorPickerPanel } from 'rc-color-picker'
import ColorHandler from '../../../modules/colors/colorHandler'

type ColorPickerState = {
  value: string
  alpha: number
  colorPickerActiveSection: string | undefined
}

interface ColorPickerProps {
  value: string
  label: string
  colorInput: boolean
  isLoading?: boolean
  handleValueChange: Function
}

let ALPHAVALUES: Array<{ value: any; label: string }> = []
for (let alpha = 0; alpha <= 100; alpha++) {
  ALPHAVALUES = [...ALPHAVALUES, { value: alpha, label: `${alpha}%` }]
}

class colorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
  node: any

  constructor(props: ColorPickerProps) {
    super(props)
    this.state = {
      colorPickerActiveSection: undefined,
      value: "",
      alpha: 100,
    }

    this.handleColorChange = this.handleColorChange.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false)
    // convert rgb values to hex
    this.setState({
      value: this.props.value,
    })
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)
  }

  componentDidUpdate(prevProps: ColorPickerProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      this.setState({
        ...this.state,
        value: this.props.value,
      })
    }
  }

  handleClick = (e: MouseEvent) => {
    if (this.node && this.node.contains(e.target)) {
      return
    }
    //Close color picker
    this.toggleColorPicker("")
  }

  handleColorChange(colorString: any) {
    if (colorString.color !== this.state.value || colorString.alpha !== this.state.alpha) {
      const rgbConvertion: any = ColorHandler.hex2rgb(colorString.color)
      const rgbColor = `rgba(${rgbConvertion[0]}, ${rgbConvertion[1]}, ${rgbConvertion[2]}, ${
        colorString.alpha * 0.01
      })`
      this.setState(
        {
          ...this.state,
          value: rgbColor,
          alpha: colorString.alpha,
        },
        () => {
          this.props.handleValueChange(rgbColor)
        }
      )
    }
  }

  handleInput(prop: string, value: any) {
    this.setState(
      {
        ...this.state,
        [prop]: value,
      },
      () => this.props.handleValueChange(value)
    )
  }

  toggleColorPicker(section: string) {
    this.setState({
      ...this.state,
      colorPickerActiveSection: section,
    })
  }

  handleAlphaValue(colorString: string) {
    if (colorString && colorString.startsWith("rgba")) {
      let hexObj = ColorHandler.rbga2hex(colorString)
      if (hexObj.hexColor !== this.state.value || hexObj.alphaValue !== this.state.alpha) {
        return hexObj.alphaValue
      }
    } else if (colorString && colorString.startsWith("#")) {
      if (this.state.value !== colorString) {
        return 100
      }
    }
  }

  render() {
    return (
      <div className='color-picker-container'>
        {this.props.label && (
          <label htmlFor='' className='group-label m-0'>
            {this.props.label}
          </label>
        )}
        <div className='d-flex' style={{ position: "relative" }}>
          <div
            className='color-preview'
            style={{ background: this.props.value }}
            onClick={() => this.toggleColorPicker("section")}
          />
          {this.props.colorInput && (
            <input
              className='color-preview-input form-control'
              value={this.state.value}
              onChange={(e: any) => this.handleInput("value", e.target.value)}
              type='text'
            />
          )}
          {this.state.colorPickerActiveSection === "section" && (
            <Fragment>
              <div className='color-picker-backdrop' />
              <div
                className='color-picker-wrap'
                ref={(node) => (this.node = node)}
                onClick={() => this.state.colorPickerActiveSection && this.toggleColorPicker("section")}
              >
                <ColorPickerPanel
                  alpha={this.handleAlphaValue(this.state.value)}
                  color={this.state.value}
                  onChange={!this.props.isLoading && this.handleColorChange}
                  mode='RGB'
                />
                {this.props.isLoading && (
                  <div className='color-picker-loader'>
                    <i className='fas fa-spinner fa-spin' />
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}

export default colorPicker
/**
 * Link Controller Fields
 */
export const LINK_ENABLE = "behaviour-link-enable"
export const LINK_LABEL = "behaviour-link-label"
export const LINK_MAPPED_VALUE = "behaviour-link-mappedValue"
export const LINK_CUSTOM_URL = "behaviour-link-customUrl"
export const LINK_LINKED_OBJECT = "behaviour-link-linkedObject"
export const LINK_TARGET = "behaviour-link-target"

/**
 * Background Fields
 */
export const IMAGE_SRC = "image-src"
export const IMAGE_SRC_ACTIVE = "behaviour-active-image-src"
export const IMAGE_SRC_HOVER = "behaviour-hover-image-src"
export const IMAGE_ALT = "image-alt"
export const IMAGE_ALT_ACTIVE = "behaviour-active-image-alt"
export const IMAGE_ALT_HOVER = "behaviour-hover-image-alt"

/**
 * Text Fields
 */
export const CUSTOM_TEXT = "customText"
export const CONTENT_SRC_TEXT = "contentSrc"
export const MAPPED_VALUE = "mappedValue"
export const FORM_FIELD = "formField"
export const EXTRA_WEIGHTS = "extraWeights"
export const TAG = "tag"
export const SAFE_CUSTOM_TEXT_TAG = "safeCustomTextTag"

/**
 * Media Fields
 */
export const SRC = "src"
export const TABLET_SRC = "tablet-src"
export const MOBILE_SRC = "mobile-src"
export const LINK_TYPE = "linkType"
export const CONTROLS = "controls"
export const THUMBNAIL = "thumbnail"
export const PLAY_PAUSE_ASSET = "playPauseAsset"
export const EMBED_ENABLE = "embedEnable"
export const EMBED = "embed"
export const SRC_ACTIVE = "behaviour-active-src"
export const SRC_HOVER = "behaviour-hover-src"
export const IMAGE_ENABLE = "image-enable"
export const IMAGE_ENABLE_ACTIVE = "behaviour-active-image-enable"
export const IMAGE_ENABLE_HOVER = "behaviour-hover-image-enable"
export const VIDEO_ENABLE = "video-enable"
export const VIDEO_ENABLE_ACTIVE = "behaviour-active-video-enable"
export const VIDEO_ENABLE_HOVER = "behaviour-hover-video-enable"
export const CONTENT_SRC_MEDIA = "contentSrc"
export const CONTENT_SRC_ACTIVE = "behaviour-active-contentSrc"
export const CONTENT_SRC_HOVER = "behaviour-hover-contentSrc"
export const IMAGE_MAPPED_VALUE = "image-mappedValue"
export const IMAGE_MAPPED_VALUE_ACTIVE = "behaviour-active-image-mappedValue"
export const IMAGE_MAPPED_VALUE_HOVER = "behaviour-hover-image-mappedValue"
export const VIDEO_MAPPED_VALUE = "video-mappedValue"
export const VIDEO_MAPPED_VALUE_ACTIVE = "behaviour-active-video-mappedValue"
export const VIDEO_MAPPED_VALUE_HOVER = "behaviour-hover-video-mappedValue"

/**
 * Others
 */
export const CUSTOM_ID = "id"
export const CUSTOM_CLASS = "class"

/**
 * Custom Attributes Fields
 */
export const CUSTOM_ATTRIBUTES_ENABLE = "custom-attributes"
export const CUSTOM_ATTRIBUTES_MAPPED_FIELD = "custom-attribute-mappedField"
export const CUSTOM_ATTRIBUTES_VALUE = "custom-attribute-value"
export const CUSTOM_ATTRIBUTES_CONTENTSRC = "custom-attribute-contentSrc"

import { ZodType, z } from "zod"
import { optionFromNullable } from "../monads/option/option"
import { Parser } from "../Parser"

export type Json = string | number | boolean | undefined | null | Json[] | { [key: string]: Json }
export type JsonObject = { [key: string]: Json }

export interface JsonClientI {
  post<A>(r: { url: string; headers?: Record<string, string>; body?: Json; decoder: ZodType<A, any, any> }): Promise<A>
  patch<A>(r: { url: string; headers?: Record<string, string>; body?: Json; decoder: ZodType<A, any, any> }): Promise<A>
  get<A>(r: { url: string; headers?: Record<string, string>; decoder: ZodType<A, any, any> }): Promise<A>
  delete<A>(r: { url: string; headers?: Record<string, string>; decoder: ZodType<A, any, any> }): Promise<A>
  proxied<A>(r: { url: string; name: string; body?: Json; decoder: ZodType<A, any, any> }): Promise<A>
  proxied1<A>(r: { url: string; name: string; body?: Json; decoder: Parser<A> }): Promise<A>
}

class JsonClientBase implements JsonClientI {
  token?: string
  bearerHeader: Record<string, string>
  constructor(token?: string) {
    this.token = token
    this.bearerHeader = this.token ? { Authorization: `Bearer ${this.token}` } : {}
  }

  proxied<A>(r: { url: string; name: string; body?: Json; decoder: ZodType<A, any, any> }): Promise<A> {
    let req = new Request(r.url, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        ...this.bearerHeader,
      }),
      body: optionFromNullable(r.body)
        .map((inner) => ({ name: r.name, request: inner }))
        .map((v) => JSON.stringify(v))
        .unwrapOrUndefined(),
    })
    // TODO: handle in-band errors
    return this.__decodeJsonBody(
      req,
      z.object({ response: r.decoder }).transform((e) => e.response!)
    )
  }

  proxied1<A>(r: { url: string; name: string; body?: Json; decoder: Parser<A> }): Promise<A> {
    let req = new Request(r.url, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        ...this.bearerHeader,
      }),
      body: optionFromNullable(r.body)
        .map((inner) => ({ name: r.name, request: inner }))
        .map((v) => JSON.stringify(v))
        .unwrapOrUndefined(),
    })
    // TODO: handle in-band errors
    return this.__decodeJsonBody(
      req,
      z.object({ response: z.unknown() }).transform((e) => r.decoder.parseUnsafe(e.response))
    )
  }

  post<A>(r: { url: string; headers?: Record<string, string>; body?: Json; decoder: ZodType<A> }): Promise<A> {
    let req = new Request(r.url, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        ...this.bearerHeader,
        ...(r.headers || {}),
      }),
      body: optionFromNullable(r.body)
        .map((v) => JSON.stringify(v))
        .unwrapOrUndefined(),
    })

    return this.__decodeJsonBody(req, r.decoder)
  }

  patch<A>(r: { url: string; headers?: Record<string, string>; body?: Json; decoder: ZodType<A> }): Promise<A> {
    let req = new Request(r.url, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        ...this.bearerHeader,
        ...(r.headers || {}),
      }),
      body: optionFromNullable(r.body)
        .map((v) => JSON.stringify(v))
        .unwrapOrUndefined(),
    })

    return this.__decodeJsonBody(req, r.decoder)
  }
  get<A>(r: { url: string; headers?: Record<string, string>; decoder: ZodType<A> }): Promise<A> {
    let req = new Request(r.url, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        ...this.bearerHeader,
        ...(r.headers || {}),
      }),
    })

    return this.__decodeJsonBody(req, r.decoder)
  }
  delete<A>(r: { url: string; headers?: Record<string, string>; decoder: ZodType<A> }): Promise<A> {
    let req = new Request(r.url, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        ...this.bearerHeader,
        ...(r.headers || {}),
      }),
    })

    return this.__decodeJsonBody(req, r.decoder)
  }

  __decodeJsonBody<A>(req: RequestInfo, decoder: ZodType<A, any, any>, init?: RequestInit): Promise<A> {
    return fetch(req, init).then((r) => {
      if (r.status === 200) {
        return r.json().then(decoder.parse)
      }
      // try to parse error response, if it works throw the error
      else
        return r
          .json()
          .then(z.object({ response: z.object({ error: z.string() }) }).parse)
          .then(
            (err) => {
              throw err.response
            },
            () => {
              throw r
            }
          )
    })
  }
}

export class JsonClient {
  static authorized(token: string): JsonClientI {
    return new JsonClientBase(token)
  }
}

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function profileLibraryReducer(state = initialState.profileLibraryReducer, action) {
  switch (action.type) {
    case types.GET_PROFILE_LIBRARY:
      return {
        ...state,
        profile: action.profile,
        loading: true
      }
    case types.RECEIVE_PROFILE_LIBRARY:
      return {
        profile: action.profile,
        content: action.content,
        loading: false
      }
    case types.ERROR_GET_PROFILE_LIBRARY:
      return {
        error: action.error,
        loading: false
      }
    default:
      return state
  }
}

import { z } from "zod"

import * as StringType from "../../../lib/brand/StringType"
import { InstantSchema } from "../../../lib/brand/Instant"
import { UuidType } from "../../../lib/brand/UuidType"
import { SDFormSchema } from "../../../lib/server-driven-forms/sdftypes"

let RunIdTagSchema = StringType.schema<"RunIdTag">()
type RunIdTag = StringType.infer<typeof RunIdTagSchema>

let IndexedIdSchema = StringType.schema<"IndexedId">()
export type IndexedId = StringType.infer<typeof IndexedIdSchema>

let ProcessRunIdSchema = UuidType.schema<"ProcessRunId">()
type ProcessRunId = UuidType.infer<typeof ProcessRunIdSchema>

let ProcessNameSchema = StringType.schema<"ProcessName">()
// eslint-disable-next-line
type ProcessName = StringType.infer<typeof ProcessNameSchema>
export const mkProcessName = (s: string) => StringType.wrap(ProcessNameSchema, s)

let ProfileNameSchema = StringType.schema<"ProfileName">()
type ProfileName = StringType.infer<typeof ProfileNameSchema>
export const mkProfileName = (s: string) => StringType.wrap(ProfileNameSchema, s)

let ViewIdSchema = StringType.schema<"ViewId">()
export type ViewId = StringType.infer<typeof ViewIdSchema>
export const mkViewId = (s: string) => StringType.wrap(ViewIdSchema, s)

export const AgentIdSchema = UuidType.schema<"AgentId">()
export type AgentId = UuidType.infer<typeof AgentIdSchema>
export const parseAgentId = (s: string) => UuidType.parse(AgentIdSchema, s)
export const randomAgentId = () => UuidType.random(AgentIdSchema)

let SiteIdSchema = UuidType.schema<"SiteId">()
// eslint-disable-next-line
type SiteId = UuidType.infer<typeof SiteIdSchema>

export const FluxioPostIdSchema = UuidType.schema<"FluxioPostId">()
export type FluxioPostId = UuidType.infer<typeof FluxioPostIdSchema>
export const parseFluxiPostId = (s: string) => UuidType.parse(FluxioPostIdSchema, s)
export const randomFluxioPostId = () => UuidType.random(FluxioPostIdSchema)

let FrameworkProcessIdSchema = UuidType.schema<"FrameworkProcessId">()
type FrameworkProcessId = UuidType.infer<typeof FrameworkProcessIdSchema>

let ContextKeySchema = StringType.schema<"ContextKey">()
// eslint-disable-next-line
type ContextKey = StringType.infer<typeof ContextKeySchema>

let ProcessStepSchema = z.object({ name: z.string(), produces: z.optional(ContextKeySchema) })

let ProcessRunStateSchema = z.discriminatedUnion("status", [
  z.object({ status: z.literal("running") }),
  z.object({ status: z.literal("cancelling") }),
  z.object({ status: z.literal("completed"), completedAt: InstantSchema }),
  z.object({ status: z.literal("cancelled"), completedAt: InstantSchema }),
])

let ProgressSchema = z.union([z.literal("creating-content"), z.object({ step: z.number(), of: z.number() })])

let ShortRunSchema = z.object({
  runId: ProcessRunIdSchema,
  processName: ProcessNameSchema,
  startingInfo: z.optional(z.string()),
  processId: FrameworkProcessIdSchema,
  startedAt: InstantSchema,
  state: ProcessRunStateSchema,
  idTag: z.optional(RunIdTagSchema),
  failed: z.optional(z.object({ error: z.string() })),
  preview: z.optional(z.string()),
  progress: z.optional(ProgressSchema),
  userContextTitles: z.array(z.string()),
  profileContextKeys: z.array(ContextKeySchema),
})

type ShortRun = z.infer<typeof ShortRunSchema>

let ProcessSchema = z.object({
  name: ProcessNameSchema,
  description: z.optional(z.string()),
  steps: z.array(ProcessStepSchema),
  hasTagId: z.optional(z.boolean()),
  produces: ContextKeySchema,
  requires: z.array(ContextKeySchema),
})

type Process = z.infer<typeof ProcessSchema>

let UserContextSchema = z.discriminatedUnion("type", [
  z.object({ type: z.literal("post"), id: FluxioPostIdSchema }),
  z.object({ type: z.literal("manual"), title: z.string(), text: z.string() }),
])

type UserContext = z.infer<typeof UserContextSchema>

let StartProcessInputSchema = z.object({
  startingInfo: z.optional(z.string()),
  createPageInSite: z.optional(SiteIdSchema),
  idTag: z.optional(z.string()),
  context: z.array(UserContextSchema),
})

type StartProcessInput = z.infer<typeof StartProcessInputSchema>

let StartProcessRequestSchema = z.intersection(
  z.object({
    workspace: z.string(),
    processes: z.array(z.string()),
  }),
  StartProcessInputSchema
)

let StartProcessProxiedInputSchema = z.object({
  processName: ProcessNameSchema,
  startingInfo: z.optional(z.string()),
  siteId: z.optional(z.string()),
  idTag: z.optional(z.string()),
})

type StartProcessProxiedInput = z.infer<typeof StartProcessProxiedInputSchema>

let StartProcessProxiedRequestSchema = z.intersection(
  z.object({ profileName: ProfileNameSchema, processName: z.string() }),
  StartProcessInputSchema
)

type StartProcessProxiedRequest = z.infer<typeof StartProcessProxiedRequestSchema>

type StartProcessRequest = z.infer<typeof StartProcessRequestSchema>

let ShortSiteSchema = z.object({
  name: z.string(),
  uuid: SiteIdSchema,
  host: z.string(),
})

type ShortSite = z.infer<typeof ShortSiteSchema>

export const JobRunIdSchema = UuidType.schema<"JobRunId">()
export type JobRunId = UuidType.infer<typeof JobRunIdSchema>
export const parseJobRunId = (s: string) => UuidType.parse(JobRunIdSchema, s)

export const JobIdSchema = StringType.schema<"JobId">()
export type JobId = StringType.infer<typeof JobIdSchema>
export const mkJobId = (s: string) => StringType.wrap(JobIdSchema, s)

let JobStatusSchema = z.discriminatedUnion("type", [
  z.object({ type: z.literal("completed"), at: z.string() }),
  z.object({ type: z.literal("running") }),
  z.object({ type: z.literal("cancelled"), reason: z.string() }),
  z.object({ type: z.literal("failed"), error: z.string() }),
])

export type JobStatus = StringType.infer<typeof JobStatusSchema>

let TaskStatusSchema = z.discriminatedUnion("type", [
  z.object({ type: z.literal("pending") }),
  z.object({ type: z.literal("completed"), at: z.string() }),
  z.object({ type: z.literal("running"), started: z.string() }),
  z.object({ type: z.literal("cancelled"), reason: z.string() }),
  z.object({ type: z.literal("failed"), error: z.string() }),
])

export type TaskStatus = StringType.infer<typeof TaskStatusSchema>

export const JobInputSchema = z.object({
  key: z.string(),
  value: z.string(),
})
export type JobInput = StringType.infer<typeof JobInputSchema>

export const JobRunSchema = z.object({
  id: JobRunIdSchema,
  job: JobIdSchema,
  profile: ProfileNameSchema,
  started: InstantSchema,
  completed: z.optional(InstantSchema),
  error: z.optional(z.string()),
  status: JobStatusSchema,
  input: z.array(JobInputSchema),
  name: z.string(),
})

export type JobRun = z.infer<typeof JobRunSchema>

export const JobTagSchema = z.object({ id: z.string(), label: z.string() })
export type JobTag = z.infer<typeof JobTagSchema>

export const JobTaskRunSchema = z.object({
  shortRun: z.optional(ShortRunSchema),
})

export type JobTaskRun = z.infer<typeof JobTaskRunSchema>

// TaskRunId in the backend
export const JobTaskIdSchema = StringType.schema<"JobTaskId">()
export type JobTaskId = StringType.infer<typeof JobTaskIdSchema>
export const JobTaskIdDecoder = StringType.decoder<"JobTaskId">()
export const mkJobTaskId = (s: string) => StringType.wrap(JobTaskIdSchema, s)

export const FullJobSchema = z.object({
  id: JobIdSchema,
  tags: z.array(JobTagSchema),
  name: z.string(),
  description: z.string(),
  previewDescription: z.string(),
  image: z.string(),
})
export type FullJob = StringType.infer<typeof FullJobSchema>

export const JobTaskSchema = z.object({
  label: z.string(),
  id: JobTaskIdSchema,
  started: z.optional(InstantSchema),
  completed: z.optional(InstantSchema),
  error: z.optional(z.string()),
  type: z.optional(z.string()),
  run: z.optional(JobTaskRunSchema),
  status: z.discriminatedUnion("type", [
    z.object({ type: z.literal("pending") }),
    z.object({ type: z.literal("running"), start: InstantSchema }),
    z.object({ type: z.literal("completed"), start: InstantSchema, end: InstantSchema }),
    z.object({
      type: z.literal("cancelled"),
      start: z.optional(InstantSchema),
      end: z.optional(InstantSchema),
      reason: z.string(),
    }),
    z.object({ type: z.literal("failed"), start: InstantSchema, end: InstantSchema, error: z.string() }),
  ]),
})

export type JobTask = z.infer<typeof JobTaskSchema>

export const ContextPreviewSchema = z.object({ text: z.string() })
export type ContextPreview = z.infer<typeof ContextPreviewSchema>

export const JobRunDetailSchema = z.object({
  id: JobRunIdSchema,
  job: JobIdSchema,
  profile: ProfileNameSchema,
  started: InstantSchema,
  completed: z.optional(InstantSchema),
  error: z.optional(z.string()),
  tasks: z.array(JobTaskSchema),
})

//TODO sourceId type is not working
const basePostTreeSchema = z.object({
  id: FluxioPostIdSchema,
  tags_profile: z.optional(z.array(z.string())),
  title: z.string(),
  description: z.optional(z.string()),
  url: z.optional(z.string()),
  sourceId: z.optional(z.string()),
  is_context: z.optional(z.boolean()),
  _quiz: z.optional(z.object({ form: z.optional(SDFormSchema) })),
})

type PostTreeI = z.input<typeof basePostTreeSchema> & {
  items?: PostTreeI[]
}
export type PostTree = z.output<typeof basePostTreeSchema> & {
  items?: PostTree[]
}

export const PostTreeSchema: z.ZodType<PostTree, z.ZodTypeDef, PostTreeI> = basePostTreeSchema.extend({
  items: z.optional(z.lazy(() => z.array(PostTreeSchema))),
})

export type BasePostEdit = z.infer<typeof basePostEditSchema>

//simplified edit fields
export const basePostEditSchema = z.object({
  title: z.optional(z.string()),
  description: z.optional(z.string()),
  url: z.optional(z.string()),
})

export function mkBasePostFromPost(post: PostTree): BasePostEdit {
  return { title: post.title, description: post.description, url: post.url }
}

export type JobRunDetail = z.infer<typeof JobRunDetailSchema>

export const RunningJobsSchema = z.object({ runningJobs: z.array(z.object({ id: JobRunIdSchema })) })

export type RunningJobs = z.infer<typeof RunningJobsSchema>

export type ChatMessage = {
  type: "user" | "assistant" | "error"
  message: string
}

// implementation with recursive dicts, not needed yet
// type AiInputValue = { type: "string"; value: string } | { type: "dict"; value: { key: string; value: AiInputValue }[] }
// export const AiInputValueEntriesSchema: ZodType<{ key: string; value: AiInputValue }[]> = z.array(
//   z.object({
//     key: z.string(),
//     value: z.lazy(() => AiInputValueSchema),
//   })
// )
// export const AiInputValueSchema: ZodType<AiInputValue> = z.discriminatedUnion("type", [
//   z.object({ type: z.literal("string"), value: z.string() }),
//   z.object({ type: z.literal("dict"), value: AiInputValueEntriesSchema }),
// ])

export const AiInputSchema = z.object({
  _pretty: z.literal(true),
  value: z.array(z.object({ key: z.string(), value: z.object({ type: z.literal("string"), value: z.string() }) })),
})

export type AiInput = z.infer<typeof AiInputSchema>

export const TaskOutputReadySchema = z.object({ ready: z.boolean(), error: z.boolean(), value: z.optional(z.string()) })
export type TaskOutputReady = z.infer<typeof TaskOutputReadySchema>

export const DocumentViewSchema = z.object({ id: ViewIdSchema, name: z.string() })
export type DocumentView = z.infer<typeof DocumentViewSchema>

export {
  type RunIdTag,
  RunIdTagSchema,
  type ProcessRunId,
  ProcessRunIdSchema,
  type FrameworkProcessId,
  FrameworkProcessIdSchema,
  type ShortRun,
  ShortRunSchema,
  type Process,
  ProcessSchema,
  type StartProcessRequest,
  StartProcessRequestSchema,
  type StartProcessInput,
  StartProcessInputSchema,
  type ShortSite,
  ShortSiteSchema,
  type StartProcessProxiedRequest,
  StartProcessProxiedRequestSchema,
  type StartProcessProxiedInput,
  StartProcessProxiedInputSchema,
  type ProfileName,
  ProfileNameSchema,
  type UserContext,
  UserContextSchema,
  type ProcessName,
  ProcessNameSchema,
}

import React from 'react'
import ErrorBoundary from '../shared-components/error/ErrorBoundary';
import * as inputType from './formComponentTypes'
import ColorPicker from './components/colorPicker'
import StylesColorPicker from './components/stylesColorPicker'
import InputHandler from './components/inputHandler'
import Select from 'react-select'
import Animated from 'react-select/animated'
import * as selectStyles from '../../modules/constants/select'
import SelectController from '../../areas/skynet/experienceManager/finder/inputs/SelectController';


class FormComponentHandler extends React.Component {
  /**
   * PROPS
   * @param {*} type type of input to render
   * @param {*} value value to handle inside the input
   * @param {*} handleValueChange callback for when the value is changed inside the input
   * @param {*} callBack function to call when the input finishes processing
   * @param {*} delay await time to call the callback 
   */
  render() {
    switch (this.props.type) {
      case inputType.COLOR_PICKER:
        return (
          <ErrorBoundary>
            <ColorPicker
              value={this.props.value}
              label={this.props.label}
              colorInput={this.props.colorInput}
              handleValueChange={this.props.handleValueChange}
              isLoading={this.props.isLoading}
            />
          </ErrorBoundary>
        )
      case inputType.STYLES_COLOR_PICKER:
        return (
          <ErrorBoundary>
            <StylesColorPicker
              value={this.props.value}
              label={this.props.label}
              uElement={this.props.uElement}
              colorResponsiveData={this.props.colorResponsiveData}
              selectedController={this.props.selectedController}
              foundationStylesList={this.props.foundationStylesList}
              colorInput={this.props.colorInput}
              customTrigger={this.props.customTrigger}
              handleValueChange={this.props.handleValueChange}
              finderType={this.props.finderType}
              bobKey={this.props.bobKey}
            />
          </ErrorBoundary>
        )
      case inputType.INPUT_HANDLER:
        return (
          <ErrorBoundary>
            <InputHandler
              value={this.props.value}
              inputType={this.props.inputType}
              inputName={this.props.inputName}
              inputClassName={this.props.inputClassName}
              handleValueChange={this.props.handleValueChange}
              delay={this.props.delay}
              onBlur={this.props.onBlur}
              placeholder={this.props.placeholder}
              inputId={this.props.inputId}
              inputStyle={this.props.inputStyle}
              onKeyPress={this.props.onKeyPress}
              onClick={this.props.onClick}
              disabled={this.props.disabled}
              converter={this.props.converter}
              autocomplete={this.props.autocomplete}
              enableDoubleClick={this.props.enableDoubleClick}
            />
          </ErrorBoundary>
        )
      case inputType.SELECT:
        return (
          <ErrorBoundary>
            <SelectController
              value={this.props.value}
              selectedValue={this.props.selectedValue}
              options={this.props.options}
              onChange={this.props.handleValueChange}
            />
          </ErrorBoundary>
        )
      case inputType.SELECT_MULTI:
        return (
          <ErrorBoundary>
            <Select
              className="default-select-container"
              classNamePrefix="default-select"
              isMulti
              placeholder={this.props.placeholder}
              components={Animated()}
              options={this.props.options}
              onChange={this.props.handleValueChange}
              value={this.props.value}
              styles={selectStyles.defaultSelect}
            />
          </ErrorBoundary>
        )
      default:
        return
    }
  }
}

export default FormComponentHandler

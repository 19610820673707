import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function mqlReducer(state = initialState.mqlReducer, action) {
  switch (action.type) {
    case types.GET_QUERY_MQL:
      return {
        ...state,
        query: action.query,
        error: false,
        loading: true
      }
    case types.RECEIVE_MQL_QUERY:
      return {
        ...state,
        response: action.content,
        query: action.query,
        loading: false,
        error: false
      }
    case types.INVALID_MQL_QUERY:
      return {
        ...state,
        error: action.error,
        query: action.query,
        loading: false
      }
    case types.FINAL_MQL_QUERY:
      return {
        ...state,
        query: action.query
      }
    case types.CLEAR_FINAL_MQL_QUERY:
      return {
        ...state,
        query: undefined
      }
    default:
      return state;
  }
}

import React, { useEffect, useState } from "react"
import { ProfileName, FluxioPostId, ViewId, PostTree } from "./jobsSchema"
import PostTreeC from "./postTreeC"
import { JobsApi } from "./jobsApi"
import TwoLayout from "./twoLayout"
import { PostTreeUtil } from "./postTreeUtil"
import { DocumentViewerActions } from "./documentViewerActions"
import ContentTreePanel from "./contentTreePanel"
import { Chats } from "./chats"

interface OwnProps {
  jobsClient: JobsApi
  viewId: ViewId
  profileName: ProfileName
}

type DocumentViewerProps = OwnProps

function DocumentViewer(props: DocumentViewerProps): JSX.Element {
  const [postTree, setPostTree] = useState<PostTree[] | undefined>(undefined)

  useEffect(() => {
    if (!props.viewId) return
    else {
      props.jobsClient.viewDocument(props.viewId).then((s) => setPostTree(s.posts))
    }
  }, [props.viewId, props.jobsClient])

  const [selectedPost, setSelectedPost] = useState<FluxioPostId | undefined>(undefined)

  const actions: DocumentViewerActions.All = {
    addAsChild(post: FluxioPostId, description: string): Promise<void> {
      const newPost = { title: "", description: description }
      return props.jobsClient.createPostChild(post, newPost).then((created) => {
        const child: PostTree = { id: created.id, ...newPost }
        setPostTree((prev) => PostTreeUtil.appendChild(prev ?? [], post, child))
      })
    },
    removePost(post: FluxioPostId): Promise<void> {
      return props.jobsClient.deletePost(post).then(() => setPostTree((prev) => PostTreeUtil.remove(prev ?? [], post)))
    },
  }

  const handleSelectPost = (postId?: FluxioPostId) => {
    setSelectedPost(postId ? postId : undefined)
  }

  const updatePost = (id: FluxioPostId, p: PostTree) => {
    setPostTree((prev) => PostTreeUtil.replacePost(prev ?? [], id, p))
  }

  // request failed
  if (postTree === undefined) return <></>

  //return header with a dropdown of runs to choose. then bellow we will show the recurrent component for rendering a post tree
  return (
    <div className='jobs-main-panel'>
      <TwoLayout
        contentTreePanel={<ContentTreePanel postTree={postTree}></ContentTreePanel>}
        content={
          <ViewContent
            key={props.viewId} //review this for bug
            posts={postTree}
            jobsClient={props.jobsClient}
            viewId={props.viewId}
            profileName={props.profileName}
            handleSelectPost={handleSelectPost}
            selectedPost={selectedPost}
            updatePost={updatePost}
            actions={actions}
          ></ViewContent>
        }
        assistantPanel={
          <Chats
            profileName={props.profileName}
            postId={selectedPost}
            jobsClient={props.jobsClient}
            actions={actions}
          />
        }
      ></TwoLayout>

      <div></div>
    </div>
  )
}

interface ViewContentProps {
  posts: PostTree[]
  profileName: ProfileName
  viewId: ViewId
  jobsClient: JobsApi
  handleSelectPost: (postId?: FluxioPostId) => void
  selectedPost?: FluxioPostId
  updatePost: (i: FluxioPostId, p: PostTree) => void
  actions: DocumentViewerActions.RemovePost
}

function ViewContent(props: ViewContentProps): JSX.Element {
  return (
    <div className='main-panel-full'>
      {props.posts.map((post, pkey) => (
        <div key={post.id} className='topic-tree-background'>
          <PostTreeC
            key={pkey}
            post={post}
            jobsClient={props.jobsClient}
            profileName={props.profileName}
            handleSelectPost={props.handleSelectPost}
            selectedPost={props.selectedPost}
            updatePost={props.updatePost}
            actions={props.actions}
          ></PostTreeC>
        </div>
      ))}
    </div>
  )
}
export default DocumentViewer

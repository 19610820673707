import { useEffect, useState } from "react"
import { FullJob, JobId, JobTag } from "./jobsSchema"
import { JobsNavigation } from "./jobsNavigation"
import { useLocation } from "react-router"

type JobsCatalogueProps = {
  client: {
    listJobs(search?: string): Promise<FullJob[]>
    listTags(): Promise<JobTag[]>
  }
  nav: JobsNavigation
  openJobDetail: (jobId: JobId) => void
  handleGoBackToJCatalogue: (searchQuery?: string) => void
  searchQuery?: string
}

type JobCardProps = {
  job: FullJob
  openJobDetail: (jobId: JobId) => void
  idx: number
  filter?: { searchQuery?: string; filterTags?: string }
}

function renderJob(jobItem: FullJob, idx: number, props: JobsCatalogueProps): JSX.Element {
  return <JobCard key={jobItem.id} idx={idx} job={jobItem} {...props}></JobCard>
}

function renderTag(tag: JobTag, tagIdx: number): JSX.Element {
  return (
    <div key={tagIdx}>
      <p>{tag.label}</p>
    </div>
  )
}

export function JobCard(props: JobCardProps) {
  const job = props.job

  //let tags = job.tags.flatMap((s) => s.label)

  return (
    <div key={props.idx} className='card '>
      <div className='card-media-box'>
        <img
          className='card-media'
          alt='job cover'
          src={job.image}
          style={{ width: "100%", height: "150px", objectFit: "cover" }}
        ></img>
      </div>
      <div className='card-body'>
        <h2 className='title'>{job.name}</h2>
        <p className='description'> {job.previewDescription}</p>
      </div>

      <div className='actions'>
        <button className='buttonDoJob' onClick={() => props.openJobDetail(job.id)} style={{ width: "100%" }}>
          Do<span className='dojob-color'>Job</span>
        </button>
      </div>
    </div>
  )
}

function filterTagSideBar(tags: JobTag[]): JSX.Element {
  return (
    <div style={{}} className='jobs-right-sidebar'>
      <h4>filter by</h4>
      <div>{tags.map((j, jdx) => renderTag(j, jdx))}</div>
    </div>
  )
}
export function JobsCatalogue(props: JobsCatalogueProps): JSX.Element {
  const [jobs, setJobs] = useState<FullJob[] | undefined>([])
  const [tags, setTags] = useState<JobTag[] | undefined>([])
  const location = useLocation()
  // Extract the search query from the URL and set it as the initial value.
  const initialSearchQuery = new URLSearchParams(location.search).get("search") || ""

  const filtersOn: boolean = false
  const [searchQuery, setSearchQuery] = useState<string>(initialSearchQuery)

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSearch = () => {
    // Push the search query to the URL.
    //history.push(`/jobs/catalogue?search=${searchQuery}`)
    props.handleGoBackToJCatalogue(searchQuery)
  }
  useEffect(() => {
    props.client.listTags().then((t) => setTags(t))
  }, [props.client])

  useEffect(() => {
    props.client.listJobs(searchQuery).then((e) => setJobs(e))
  }, [props.client, searchQuery])

  return (
    <div className='jobs-main-panel'>
      <div className='jobs-library main-panel main-panel-100'>
        <div style={{}}>
          <form
            onSubmit={(e) => {
              e.preventDefault()
            }}
            className='input-group'
          >
            <input
              name='search'
              value={searchQuery}
              onChange={handleSearchInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch()
                }
              }}
              placeholder='Search'
              type='text'
              className='form-control'
            />

            <div className='input-group-append'>
              <div
                onClick={() => {
                  handleSearch()
                }}
                className='input-group-text'
              >
                <i className='fas fa-search'></i>
              </div>
            </div>
          </form>
        </div>

        {jobs && <div className={`jobs-grid`}>{jobs.map((j, jdx) => renderJob(j, jdx, props))}</div>}
      </div>
      {filtersOn && tags && filterTagSideBar(tags)}
    </div>
  )
}

export default JobsCatalogue

export type MapKey = string | number | symbol
export type Map<K extends MapKey, V> = Partial<{ [key in K]: V }>

export class Objects {
  static set<OBJ extends object, K extends keyof OBJ, V extends OBJ[K]>(obj: OBJ, key: K, value: V): OBJ {
    return { ...obj, [key]: value }
  }

  static mapValues<K extends MapKey, V, V2>(obj: Map<K, V>, f: (v: V, k: K) => V2): Map<K, V2> {
    const nVals: Map<K, V2> = {}
    for (const k in obj) {
      const v: V | undefined = obj[k]
      if (v !== undefined) nVals[k] = f(v, k)
    }
    return nVals
  }
}

export class Arrays {
  static updateAt<A>(arr: A[], idx: number, f: (a: A) => A): A[] {
    return arr.map((a, _idx) => (idx === _idx ? f(a) : a))
  }

  static removeAt<A>(arr: A[], idx: number): A[] {
    return arr.filter((a, _idx) => idx !== _idx)
  }
  // like updateAt but also typechecks the value, useful to narrow into a union type
  static udpateAtWhen<A, B>(arr: A[], idx: number, check: (a: any) => a is B, f: (b: B) => A): A[] {
    return arr.map((a, _idx) => (idx === _idx && check(a) ? f(a) : a))
  }

  static setAt<A>(arr: A[], idx: number, v: A): A[] {
    return this.updateAt(arr, idx, () => v)
  }

  static intRange(from: number, toExclusive: number): number[] {
    const len = toExclusive - from
    if (len <= 0) return []
    return Array.from({ length: len }, (x, i) => from + i)
  }
}

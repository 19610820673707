export const ENGLISH_TRANSLATION = {
  lang: 'en',
  messages: {
      'session.logIn': 'Login',
      'session.logOut': 'Logout',
      'session.language': 'Language',
      'app.translatedMessage': 'English',
      'app.loginWithoutCredentials': 'Proceed without login',
  }
};

/*
 * sessionActions
 */
export const CREDENTIALS_TOKEN = "CREDENTIALS_TOKEN"
export const UNVERIFIED_USER_INFO = "UNVERIFIED_USER_INFO"
export const VERIFIED_USER_INFO = "VERIFIED_USER_INFO"
export const CLEAR_VERIFIED_USER_INFO = "CLEAR_VERIFIED_USER_INFO"
export const SUCCESSFUL_LOGIN = "SUCCESSFUL_LOGIN"
export const LOGGING_IN = "LOGGING_IN"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const TO_VALIDATE_FACEBOOK = "TO_VALIDATE_FACEBOOK"
export const LOGIN_FACEBOOK = "LOGIN_FACEBOOK"
export const SUCCESSFUL_REGISTER = "SUCCESSFUL_REGISTER"
export const REGISTERING = "REGISTERING"
export const REGISTER_ERROR = "REGISTER_ERROR"

export const LOADING_RESET_PASSWORD = "LOADING_RESET_PASSWORD"
export const SUCCESS_RESET_PASSWORD = "SUCCESS_RESET_PASSWORD"
export const FAILED_RESET_PASSWORD = "FAILED_RESET_PASSWORD"
export const CLEAR_RESET_PASSWORD = "CLEAR_RESET_PASSWORD"

export const LOADING_CREATE_PASSWORD = "LOADING_CREATE_PASSWORD"
export const SUCCESS_CREATE_PASSWORD = "SUCCESS_CREATE_PASSWORD"
export const FAILED_CREATE_PASSWORD = "FAILED_CREATE_PASSWORD"
export const CLEAR_CREATE_PASSWORD = "CLEAR_CREATE_PASSWORD"

export const ERROR_USER_INFO = "ERROR_USER_INFO"
export const REQUEST_USER_INFO = "REQUEST_USER_INFO"
export const SUCCESS_USER_INFO = "SUCCESS_USER_INFO"
export const CLEAR_USER_INFO = "CLEAR_USER_INFO"

export const ERROR_USER_FIRST_LOGIN = "ERROR_USER_FIRST_LOGIN"
export const REQUEST_USER_FIRST_LOGIN = "REQUEST_USER_FIRST_LOGIN"
export const SUCCESS_USER_FIRST_LOGIN = "SUCCESS_USER_FIRST_LOGIN"
export const CLEAR_USER_FIRST_LOGIN = "CLEAR_USER_FIRST_LOGIN"

export const ERROR_GET_REGISTER_LIMIT = "ERROR_GET_REGISTER_LIMIT"
export const LOADING_GET_REGISTER_LIMIT = "LOADING_GET_REGISTER_LIMIT"
export const SUCCESS_GET_REGISTER_LIMIT = "SUCCESS_GET_REGISTER_LIMIT"
export const CLEAR_GET_REGISTER_LIMIT = "CLEAR_GET_REGISTER_LIMIT"

export const ERROR_GOOGLE_LOGIN = "ERROR_GOOGLE_LOGIN"
export const LOADING_GOOGLE_LOGIN = "LOADING_GOOGLE_LOGIN"
export const SUCCESS_GOOGLE_LOGIN = "SUCCESS_GOOGLE_LOGIN"
export const CLEAR_GOOGLE_LOGIN = "CLEAR_GOOGLE_LOGIN"

export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR"

/*
 * localeActions
 */
export const LOCALE_SELECTED = "LOCALE_SELECTED"

/*
 * contentActions
 */
export const INVALID_HUB_ID = "INVALID_HUB_ID"
export const REQUEST_HUB_CONTENT = "REQUEST_HUB_CONTENT"
export const RECEIVE_HUB_CONTENT = "RECEIVE_HUB_CONTENT"
export const CLEAN_HUB_CONTENT = "CLEAN_HUB_CONTENT"
export const INVALID_FORMULA_ID = "INVALID_FORMULA_ID"
export const REQUEST_FORMULA_CONTENT = "REQUEST_FORMULA_CONTENT"
export const RECEIVE_FORMULA_CONTENT = "RECEIVE_FORMULA_CONTENT"
export const INVALID_POWERWALL = "INVALID_POWERWALL"
export const REQUEST_POWERWALL_CONTENT = "REQUEST_POWERWALL_CONTENT"
export const RECEIVE_POWERWALL_CONTENT = "RECEIVE_POWERWALL_CONTENT"

export const CONTENT_FETCHED = "CONTENT_FETCHED"
export const FETCHING_CONTENT = "FETCHING_CONTENT"

export const LIVE_EDIT_FORMULA = "LIVE_EDIT_FORMULA"

/*
 * google services
 */
export const REQUEST_GOOGLE_FONTS_LIST = "REQUEST_GOOGLE_FONTS_LIST"
export const RECEIVE_GOOGLE_FONTS_LIST = "RECEIVE_GOOGLE_FONTS_LIST"
export const ERROR_GOOGLE_SERVICES = "ERROR_GOOGLE_SERVICES"

/*
 * google services
 */
export const REQUEST_BUNNY_FONTS_LIST = "REQUEST_BUNNY_FONTS_LIST"
export const RECEIVE_BUNNY_FONTS_LIST = "RECEIVE_BUNNY_FONTS_LIST"
export const ERROR_FETCHING_BUNNY_FONTS_LIST = "ERROR_FETCHING_BUNNY_FONTS_LIST"

/*
 * fontAwesome services
 */
export const REQUEST_FONTAWESOME_FONTS_LIST = "REQUEST_FONTAWESOME_FONTS_LIST"
export const RECEIVE_FONTAWESOME_FONTS_LIST = "RECEIVE_FONTAWESOME_FONTS_LIST"
export const ERROR_FONTAWESOME_SERVICES = "ERROR_FONTAWESOME_SERVICES"

/*
 * networkActions
 */
export const GET_NETWORK_LIBRARY = "GET_NETWORK_LIBRARY"
export const RECEIVE_NETWORK_LIBRARY = "RECEIVE_NETWORK_LIBRARY"
export const ERROR_GET_NETWORK_LIBRARY = "ERROR_GET_NETWORK"
export const GET_PERSON_LIBRARY_CONTENT = "GET_PERSON_LIBRARY_CONTENT"
export const RECEIVE_PERSON_LIBRARY_CONTENT = "RECEIVE_PERSON_LIBRARY_CONTENT"
export const ERROR_GET_PERSON_LIBRARY_CONTENT = "ERROR_GET_PERSON_LIBRARY_CONTENT"

/*
 * userProfiles
 */
export const GET_USER_PROFILES = "GET_USER_PROFILES"
export const RECEIVE_USER_PROFILES = "RECEIVE_USER_PROFILES"
export const ERROR_GET_USER_PROFILES = "ERROR_GET_USER_PROFILES"
export const CLEAR_USER_PROFILES = "CLEAR_USER_PROFILES"

/*
 * Profile
 */
export const GET_PROFILE = "GET_PROFILE"
export const RECEIVE_PROFILE = "RECEIVE_PROFILE"
export const ERROR_GET_PROFILE = "ERROR_GET_PROFILE"
export const CLEAR_PROFILE_ERROR = "CLEAR_PROFILE_ERROR"

/*
 * ProfileLibrary
 */
export const GET_PROFILE_LIBRARY = "GET_PROFILE_LIBRARY"
export const RECEIVE_PROFILE_LIBRARY = "RECEIVE_PROFILE_LIBRARY"
export const ERROR_GET_PROFILE_LIBRARY = "ERROR_GET_PROFILE_LIBRARY"

/*
 * ProfilePowerWall
 */
export const GET_PROFILE_LIBRARY_POWERWALL = "GET_PROFILE_LIBRARY_POWERWALL"
export const RECEIVE_PROFILE_LIBRARY_POWERWALL = "RECEIVE_PROFILE_LIBRARY_POWERWALL"
export const ERROR_GET_PROFILE_LIBRARY_POWERWALL = "ERROR_GET_PROFILE_LIBRARY_POWERWALL"

/*
 * MQL
 */
export const INVALID_MQL_QUERY = "INVALID_MQL_QUERY"
export const GET_QUERY_MQL = "GET_QUERY_MQL"
export const RECEIVE_MQL_QUERY = "RECEIVE_MQL_QUERY"
export const FINAL_MQL_QUERY = "FINAL_MQL_QUERY"
export const CLEAR_FINAL_MQL_QUERY = "CLEAR_FINAL_MQL_QUERY"
export const REQUEST_WITH_FINAL_QUERY = "REQUEST_WITH_FINAL_QUERY"

/*
 * Profile Xformula
 */
export const REQUEST_PROFILE_XFORMULA = "REQUEST_PROFILE_XFORMULA"
export const RECEIVE_PROFILE_XFORMULA = "RECEIVE_PROFILE_XFORMULA"
export const ERROR_PROFILE_XFORMULA = "ERROR_PROFILE_XFORMULA"

export const REQUEST_PROFILE_SUB_XFORMULA = "REQUEST_PROFILE_SUB_XFORMULA"
export const RECEIVE_PROFILE_SUB_XFORMULA = "RECEIVE_PROFILE_SUB_XFORMULA"
export const ERROR_PROFILE_SUB_XFORMULA = "ERROR_PROFILE_SUB_XFORMULA"

export const CHANGED_FORMULA_GALLERY = "CHANGED_FORMULA_GALLERY"

/*
 * xFilter
 */

// export const REQUEST_ADD_XFILTER = 'REQUEST_ADD_XFILTER'
// export const REQUEST_EDIT_XFILTER = 'REQUEST_EDIT_XFILTER'
// export const REQUEST_REMOVE_XFILTER = 'REQUEST_REMOVE_XFILTER'
// export const CONFIRMED_XFILTER_ACTION = 'CONFIRMED_XFILTER_ACTION'
// export const ERROR_XFILTER = 'ERROR_XFILTER'
export const NEW_MQL_QUERY_PARAMETERS = "NEW_MQL_QUERY_PARAMETERS"
export const CHANGED_MQL_FILTER_QUERY = "CHANGED_MQL_FILTER_QUERY"
export const CHANGE_XFILTER_MODE = "CHANGE_XFILTER_MODE"
export const ADD_FILTER = "ADD_FILTER"
export const REMOVE_FILTER = "REMOVE_FILTER"
export const EDIT_FILTER = "EDIT_FILTER"
export const UNKOWN_FILTER = "UNKOWN_FILTER"
export const RESET_FILTER = "RESET_FILTER"
export const APPLY_FILTER = "APPLY_FILTER"
export const MIX_FILTER = "MIX_FILTER"
export const FETCH_FILTER = "FETCH_FILTER"
export const UPPER_FILTER_DATA = "UPPER_FILTER_DATA"

/*
 * Add Tag
 */
export const REQUEST_ADD_TAG = "REQUEST_ADD_TAG"
export const CONFIRMED_ADD_TAG = "CONFIRMED_ADD_TAG"
export const REQUEST_REMOVE_TAG = "REQUEST_REMOVE_TAG"
export const CONFIRMED_REMOVE_TAG = "CONFIRMED_REMOVE_TAG"
export const ERROR_ADD_TAG = "ERROR_ADD_TAG"
export const CLEAR_REDUX_ADD_TAG = "CLEAR_REDUX_ADD_TAG"

export const REQUEST_ADD_FORMULA_TAG = "REQUEST_ADD_FORMULA_TAG"
export const CONFIRMED_ADD_FORMULA_TAG = "CONFIRMED_ADD_FORMULA_TAG"
export const ERROR_ADD_FORMULA_TAG = "ERROR_ADD_FORMULA_TAG"

export const ERROR_STAGING_PAGE_EXPERIENCE_MANAGER = "ERROR_STAGING_PAGE_EXPERIENCE_MANAGER"
export const REQUEST_STAGING_PAGE_EXPERIENCE_MANAGER = "REQUEST_STAGING_PAGE_EXPERIENCE_MANAGER"
export const SUCCESS_STAGING_PAGE_EXPERIENCE_MANAGER = "SUCCESS_STAGING_PAGE_EXPERIENCE_MANAGER"

export const ERROR_FETCH_STAGING_OBJECT_TEMPLATE_BY_ID = "ERROR_FETCH_STAGING_OBJECT_TEMPLATE_BY_ID"
export const REQUEST_STAGING_OBJECT_TEMPLATE_BY_ID = "REQUEST_STAGING_OBJECT_TEMPLATE_BY_ID"
export const SUCCESS_STAGING_OBJECT_TEMPLATE_BY_ID = "SUCCESS_STAGING_OBJECT_TEMPLATE_BY_ID"

export const ERROR_FETCH_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID = "ERROR_FETCH_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID"
export const REQUEST_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID = "REQUEST_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID"
export const SUCCESS_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID = "SUCCESS_STAGING_PUBLISHED_OBJECT_TEMPLATE_BY_ID"

export const ERROR_FETCH_STAGING_SYMBOL_BY_ID = "ERROR_FETCH_STAGING_SYMBOL_BY_ID"
export const REQUEST_STAGING_SYMBOL_BY_ID = "REQUEST_STAGING_SYMBOL_BY_ID"
export const SUCCESS_STAGING_SYMBOL_BY_ID = "SUCCESS_STAGING_SYMBOL_BY_ID"

export const REQUEST_REMOVE_FORMULA_TAG = "REQUEST_REMOVE_FORMULA_TAG"
export const CONFIRMED_REMOVE_FORMULA_TAG = "CONFIRMED_REMOVE_FORMULA_TAG"
export const ERROR_REMOVE_FORMULA_TAG = "ERROR_REMOVE_FORMULA_TAG"

export const REQUEST_EDIT_FORMULA_TAG = "REQUEST_EDIT_FORMULA_TAG"
export const CONFIRM_EDIT_FORMULA_TAG = "CONFIRM_EDIT_FORMULA_TAG"
export const ERROR_EDIT_FORMULA_TAG = "ERROR_EDIT_FORMULA_TAG"
export const CLEAR_EDIT_FORMULA_TAG = "CLEAR_EDIT_FORMULA_TAG"

export const REQUEST_REMOVE_SINGLE_FORMULA_TAG = "REQUEST_REMOVE_SINGLE_FORMULA_TAG"
export const CONFIRMED_REMOVE_SINGLE_FORMULA_TAG = "CONFIRMED_REMOVE_SINGLE_FORMULA_TAG"
export const ERROR_REMOVE_SINGLE_FORMULA_TAG = "ERROR_REMOVE_SINGLE_FORMULA_TAG"
export const CLEAR_REMOVE_SINGLE_FORMULA_TAG = "CLEAR_REMOVE_SINGLE_FORMULA_TAG"

export const REQUEST_CREATE_FORMULA_TAG = "REQUEST_CREATE_FORMULA_TAG"
export const CONFIRMED_CREATE_FORMULA_TAG = "CONFIRMED_CREATE_FORMULA_TAG"
export const ERROR_CREATE_FORMULA_TAG = "ERROR_CREATE_FORMULA_TAG"
export const CLEAR_CREATE_FORMULA_TAG = "CLEAR_CREATE_FORMULA_TAG"

export const REQUEST_SPECIFIC_FORMULA_TAGS = "REQUEST_SPECIFIC_FORMULA_TAGS"
export const CONFIRMED_SPECIFIC_FORMULA_TAGS = "CONFIRMED_SPECIFIC_FORMULA_TAGS"
export const ERROR_FETCH_SPECIFIC_FORMULA_TAGS = "ERROR_FETCH_SPECIFIC_FORMULA_TAGS"

/*
 * Meta Manager Formula/Experience
 * TODO split these 2M badboys
 */
export const INVALID_META_MANAGER_FORMULA = "INVALID_META_MANAGER_FORMULA"
export const REQUEST_META_MANAGER_FORMULA = "REQUEST_META_MANAGER_FORMULA"
export const RECEIVE_META_MANAGER_FORMULA = "RECEIVE_META_MANAGER_FORMULA"
export const INVALID_META_MANAGER_EXPERIENCE = "INVALID_META_MANAGER_EXPERIENCE"
export const REQUEST_META_MANAGER_EXPERIENCE = "REQUEST_META_MANAGER_EXPERIENCE"
export const RECEIVE_META_MANAGER_EXPERIENCE = "RECEIVE_META_MANAGER_EXPERIENCE"
export const INVALID_META_MANAGER_SUB_FORMULA = "INVALID_META_MANAGER_SUB_FORMULA"
export const REQUEST_META_MANAGER_SUB_FORMULA = "REQUEST_META_MANAGER_SUB_FORMULA"
export const RECEIVE_META_MANAGER_SUB_FORMULA = "RECEIVE_META_MANAGER_SUB_FORMULA"
export const CLEAR_META_MANAGER_FORMULA_INFO_RELATED = "CLEAR_META_MANAGER_FORMULA_INFO_RELATED"
export const CLEAR_META_MANAGER_FORMULA_INFO = "CLEAR_META_MANAGER_FORMULA_INFO"
export const INVALID_META_MANAGER_FORMULA_INFO = "INVALID_META_MANAGER_FORMULA_INFO"
export const REQUEST_META_MANAGER_FORMULA_INFO = "REQUEST_META_MANAGER_FORMULA_INFO"
export const RECEIVE_META_MANAGER_FORMULA_INFO = "RECEIVE_META_MANAGER_FORMULA_INFO"
export const INVALID_META_MANAGER_SUB_FORMULA_INFO = "INVALID_META_MANAGER_SUB_FORMULA_INFO"
export const REQUEST_META_MANAGER_SUB_FORMULA_INFO = "REQUEST_META_MANAGER_SUB_FORMULA_INFO"
export const RECEIVE_META_MANAGER_SUB_FORMULA_INFO = "RECEIVE_META_MANAGER_SUB_FORMULA_INFO"
export const INVALID_META_MANAGER_SUB_FORMULA_INFO_RELATED = "INVALID_META_MANAGER_SUB_FORMULA_INFO_RELATED"
export const REQUEST_META_MANAGER_SUB_FORMULA_INFO_RELATED = "REQUEST_META_MANAGER_SUB_FORMULA_INFO_RELATED"
export const RECEIVE_META_MANAGER_SUB_FORMULA_INFO_RELATED = "RECEIVE_META_MANAGER_SUB_FORMULA_INFO_RELATED"
export const INVALID_META_MANAGER_SUB_FORMULA_INFO_RELATED_OR = "INVALID_META_MANAGER_SUB_FORMULA_INFO_RELATED_OR"
export const REQUEST_META_MANAGER_SUB_FORMULA_INFO_RELATED_OR = "REQUEST_META_MANAGER_SUB_FORMULA_INFO_RELATED_OR"
export const RECEIVE_META_MANAGER_SUB_FORMULA_INFO_RELATED_OR = "RECEIVE_META_MANAGER_SUB_FORMULA_INFO_RELATED_OR"
export const INVALID_META_MANAGER_FORMULA_CONTENT = "INVALID_META_MANAGER_FORMULA_CONTENT"
export const REQUEST_META_MANAGER_FORMULA_CONTENT = "REQUEST_META_MANAGER_FORMULA_CONTENT"
export const RECEIVE_META_MANAGER_FORMULA_CONTENT = "RECEIVE_META_MANAGER_FORMULA_CONTENT"
export const INVALID_META_MANAGER_FORMULA_SUB_CONTENT = "INVALID_META_MANAGER_FORMULA_SUB_CONTENT"
export const REQUEST_META_MANAGER_FORMULA_SUB_CONTENT = "REQUEST_META_MANAGER_FORMULA_SUB_CONTENT"
export const RECEIVE_META_MANAGER_FORMULA_SUB_CONTENT = "RECEIVE_META_MANAGER_FORMULA_SUB_CONTENT"
export const INVALID_META_MANAGER_SAVE_FORMULA_QUERY = "INVALID_META_MANAGER_SAVE_FORMULA_QUERY"
export const REQUEST_META_MANAGER_SAVE_FORMULA_QUERY = "REQUEST_META_MANAGER_SAVE_FORMULA_QUERY"
export const RECEIVE_META_MANAGER_SAVE_FORMULA_QUERY = "RECEIVE_META_MANAGER_SAVE_FORMULA_QUERY"

export const HANDLE_NEW_PAGE_MODAL = "HANDLE_NEW_PAGE_MODAL"

/*
 * Meta Manager Components
 */
export const INVALID_META_MANAGER_COMPONENTS_FIRST_SECTION = "INVALID_META_MANAGER_COMPONENTS_FIRST_SECTION"
export const REQUEST_META_MANAGER_COMPONENTS_FIRST_SECTION = "REQUEST_META_MANAGER_COMPONENTS_FIRST_SECTION"
export const RECEIVE_META_MANAGER_COMPONENTS_FIRST_SECTION = "RECEIVE_META_MANAGER_COMPONENTS_FIRST_SECTION"
export const SELECTED_COMPONENT = "SELECTED_COMPONENT"

/*
 * Meta Manager ExpBuilder Formula
 */
export const REQUEST_FORMULA_LIST_EXPBUILDER = "REQUEST_FORMULA_LIST_EXPBUILDER"
export const RECEIVE_FORMULA_LIST_EXPBUILDER = "RECEIVE_FORMULA_LIST_EXPBUILDER"
export const INVALID_FORMULA_LIST_EXPBUILDER = "INVALID_FORMULA_LIST_EXPBUILDER"
/*
 * Meta Manager ExpBuilder Component
 */
export const REQUEST_COMPONENT_LIST_EXPBUILDER = "REQUEST_COMPONENT_LIST_EXPBUILDER"
export const RECEIVE_COMPONENT_LIST_EXPBUILDER = "RECEIVE_COMPONENT_LIST_EXPBUILDER"
export const INVALID_COMPONENT_LIST_EXPBUILDER = "INVALID_COMPONENT_LIST_EXPBUILDER"

/*
 * Meta Manager ExpBuilder Component
 */
export const INVALID_VOTING_ACTION = "INVALID_VOTING_ACTION"
export const REQUEST_VOTING_ACTION = "REQUEST_VOTING_ACTION"
export const RECEIVE_VOTING_ACTION = "RECEIVE_VOTING_ACTION"

/*
 * Breadcrumbs
 */
export const ADD_BREADCRUMB = "ADD_BREADCRUMB"
export const REMOVE_BREADCRUMB = "REMOVE_BREADCRUMB"
export const ERROR_BREADCRUMB = "ERROR_BREADCRUMB"
export const HOME_BREADCRUMB = "HOME_BREADCRUMB"
export const CLEAR_BREADCRUMBS = "CLEAR_BREADCRUMBS"
export const SELECTED_FORMULA = "SELECTED_FORMULA"
export const CLEAR_SELECTED_FORMULA = "CLEAR_SELECTED_FORMULA"

/*
 * Profile selection
 */
export const PROFILE_SELECTED = "PROFILE_SELECTED"
export const PROFILE_SELECTED_INFO = "PROFILE_SELECTED_INFO"

/*
 * Board Content
 */
export const CLEAR_ALL_POST_CONTENT = "CLEAR_ALL_POST_CONTENT"
export const ERROR_ALL_POST_CONTENT = "ERROR_ALL_POST_CONTENT"
export const FETCH_ALL_POST_CONTENT = "FETCH_ALL_POST_CONTENT"
export const RECEIVE_ALL_POST_CONTENT = "RECEIVE_ALL_POST_CONTENT"

export const ADD_POST_LIST_ITEM = "ADD_POST_LIST_ITEM"
export const UPDATE_POST_LIST_ITEM = "UPDATE_POST_LIST_ITEM"
export const DELETE_POST_LIST_ITEM = "DELETE_POST_LIST_ITEM"

export const ERROR_ALL_POST_SUB_CONTENT = "ERROR_ALL_POST_SUB_CONTENT"
export const FETCH_ALL_POST_SUB_CONTENT = "FETCH_ALL_POST_SUB_CONTENT"
export const RECEIVE_ALL_POST_SUB_CONTENT = "RECEIVE_ALL_POST_SUB_CONTENT"

export const MQLS_TO_CHECK_AND_GET = "MQLS_TO_CHECK_AND_GET"
export const MQLS_TO_CHECK_AND_GET_COMPLETE = "MQLS_TO_CHECK_AND_GET_COMPLETE"

/*
 * Cache system
 */
export const CACHE_INITIAL_META_INFO = "CACHE_INITIAL_META_INFO"

/*
 * Line selector System
 */
export const SELECTED_LINE = "SELECTED_LINE"

/*
 * Formula Tags
 */
export const REQUEST_FORMULA_TAGS = "REQUEST_FORMULA_TAGS"
export const RECEIVE_FORMULA_TAGS = "RECEIVE_FORMULA_TAGS"
export const ERROR_FETCHING_FORMULA_TAGS = "ERROR_FETCHING_FORMULA_TAGS"
export const CLEAR_FORMULA_TAGS_ACTIONS = "CLEAR_FORMULA_TAGS_ACTIONS"

/*
 * Formula Avatar
 */
export const REQUEST_FORMULA_AVATAR_UPLOAD = "REQUEST_FORMULA_AVATAR_UPLOAD"
export const CONFIRMED_FORMULA_AVATAR_UPLOAD = "CONFIRMED_FORMULA_AVATAR_UPLOAD"
export const ERROR_FORMULA_AVATAR_UPLOAD = "ERROR_FORMULA_AVATAR_UPLOAD"
export const REQUEST_FORMULA_AVATAR_REMOVE = "REQUEST_FORMULA_AVATAR_REMOVE"
export const CONFIRMED_FORMULA_AVATAR_REMOVE = "CONFIRMED_FORMULA_AVATAR_REMOVE"
export const ERROR_FORMULA_AVATAR_REMOVE = "ERROR_FORMULA_AVATAR_REMOVE"
export const CLEAR_FORMULA_AVATAR_UPLOAD = "CLEAR_FORMULA_AVATAR_UPLOAD"

/*
 * Formula MEtadata Update
 */
export const REQUEST_FORMULA_METADATA_UPDATE = "REQUEST_FORMULA_METADATA_UPDATE"
export const CONFIRMED_FORMULA_METADATA_UPDATE = "CONFIRMED_FORMULA_METADATA_UPDATE"
export const ERROR_FORMULA_METADATA_UPDATE = "ERROR_FORMULA_METADATA_UPDATE"
export const CLEAR_FORMULA_METADATA_UPDATE = "CLEAR_FORMULA_METADATA_UPDATE"

export const UPDATE_FORMULA_METADATA_LOCAL = "UPDATE_FORMULA_METADATA_LOCAL"

/*
 * Formula Create
 */
export const REQUEST_FORMULA_CREATE = "REQUEST_FORMULA_CREATE"
export const CONFIRMED_FORMULA_CREATE = "CONFIRMED_FORMULA_CREATE"
export const ERROR_FORMULA_CREATE = "ERROR_FORMULA_CREATE"
export const CLEAR_FORMULA_CREATE = "CLEAR_FORMULA_CREATE"
export const CLEAR_FORMULA_CREATE_WORKBENCH = "CLEAR_FORMULA_CREATE_WORKBENCH"

/*
 * Formula Delete
 */
export const REQUEST_FORMULA_DELETE = "REQUEST_FORMULA_DELETE"
export const CONFIRMED_FORMULA_DELETE = "CONFIRMED_FORMULA_DELETE"
export const ERROR_FORMULA_DELETE = "ERROR_FORMULA_DELETE"
export const CLEAR_FORMULA_DELETE = "CLEAR_FORMULA_DELETE"

/*
 * Profile Tags
 */
export const REQUEST_PROFILE_TAGS = "REQUEST_PROFILE_TAGS"
export const RECEIVE_PROFILE_TAGS = "RECEIVE_PROFILE_TAGS"
export const ERROR_REQUEST_PROFILE_TAGS = "ERROR_REQUEST_PROFILE_TAGS"

export const SUBMIT_PROFILE_TAG = "SUBMIT_PROFILE_TAG"
export const SUCCESS_SUBMIT_PROFILE_TAG = "SUCCESS_SUBMIT_PROFILE_TAG"
export const ERROR_SUBMIT_PROFILE_TAG = "ERROR_SUBMIT_PROFILE_TAG"
export const CLEAR_SUBMIT_PROFILE_TAG = "CLEAR_SUBMIT_PROFILE_TAG"

export const REQUEST_EDIT_PROFILE_TAG = "REQUEST_EDIT_PROFILE_TAG"
export const CONFIRMED_EDIT_PROFILE_TAG = "CONFIRMED_EDIT_PROFILE_TAG"
export const ERROR_EDIT_PROFILE_TAG = "ERROR_EDIT_PROFILE_TAG"
export const CLEAR_EDIT_PROFILE_TAG = "CLEAR_EDIT_PROFILE_TAG"

export const REQUEST_DELETE_PROFILE_TAG = "REQUEST_DELETE_PROFILE_TAG"
export const CONFIRMED_DELETE_PROFILE_TAG = "CONFIRMED_DELETE_PROFILE_TAG"
export const ERROR_DELETE_PROFILE_TAG = "ERROR_DELETE_PROFILE_TAG"
export const CLEAR_DELETE_PROFILE_TAG = "CLEAR_DELETE_PROFILE_TAG"

export const REQUEST_DELETE_MULTI_PROFILE_TAGS = "REQUEST_DELETE_MULTI_PROFILE_TAGS"
export const CONFIRMED_DELETE_MULTI_PROFILE_TAGS = "CONFIRMED_DELETE_MULTI_PROFILE_TAGS"
export const ERROR_DELETE_MULTI_PROFILE_TAGS = "ERROR_DELETE_MULTI_PROFILE_TAGS"
export const CLEAR_DELETE_MULTI_PROFILE_TAGS = "CLEAR_DELETE_MULTI_PROFILE_TAGS"

export const REQUEST_DELETE_ITEM_PROFILE_TAGS = "REQUEST_DELETE_ITEM_PROFILE_TAGS"
export const CONFIRMED_DELETE_ITEM_PROFILE_TAGS = "CONFIRMED_DELETE_ITEM_PROFILE_TAGS"
export const ERROR_DELETE_ITEM_PROFILE_TAGS = "ERROR_DELETE_ITEM_PROFILE_TAGS"

export const SUBMIT_ITEM_PROFILE_TAGS = "SUBMIT_ITEM_PROFILE_TAGS"
export const CONFIRMED_SUBMIT_ITEM_PROFILE_TAGS = "CONFIRMED_SUBMIT_ITEM_PROFILE_TAGS"
export const ERROR_SUBMIT_ITEM_PROFILE_TAGS = "ERROR_SUBMIT_ITEM_PROFILE_TAGS"

/*
 * Confirm Acount
 */
export const INVALID_ACOUNT_CONFIRMATION = "INVALID_ACOUNT_CONFIRMATION"
export const REQUEST_CONFIRM_ACOUNT = "REQUEST_CONFIRM_ACOUNT"
export const RECEIVE_ACOUNT_SUCCESS = "RECEIVE_ACOUNT_SUCCESS"

/*
 * Formula expression
 */
export const CREATE_FORMULA_EXPRESSION = "CREATE_FORMULA_EXPRESSION"
export const CONFIRM_CREATE_FORMULA_EXPRESSION = "CONFIRM_CREATE_FORMULA_EXPRESSION"
export const ERROR_CREATE_FORMULA_EXPRESSION = "ERROR_CREATE_FORMULA_EXPRESSION"
export const CLEAR_CREATE_FORMULA_EXPRESSION = "CLEAR_CREATE_FORMULA_EXPRESSION"

export const UPDATE_FORMULA_EXPRESSION = "UPDATE_FORMULA_EXPRESSION"
export const CONFIRM_UPDATE_FORMULA_EXPRESSION = "CONFIRM_UPDATE_FORMULA_EXPRESSION"
export const ERROR_UPDATE_FORMULA_EXPRESSION = "ERROR_UPDATE_FORMULA_EXPRESSION"
export const CLEAR_UPDATE_FORMULA_EXPRESSION = "CLEAR_UPDATE_FORMULA_EXPRESSION"

export const DELETE_FORMULA_EXPRESSION = "DELETE_FORMULA_EXPRESSION"
export const CONFIRM_DELETE_FORMULA_EXPRESSION = "CONFIRM_DELETE_FORMULA_EXPRESSION"
export const ERROR_DELETE_FORMULA_EXPRESSION = "ERROR_DELETE_FORMULA_EXPRESSION"
export const CLEAR_DELETE_FORMULA_EXPRESSION = "CLEAR_DELETE_FORMULA_EXPRESSION"

/*
 * Toolbox
 */
export const SETUP_PROCESS_ADD_SOURCE_TOOLBOX = "SETUP_PROCESS_ADD_SOURCE_TOOLBOX"
export const SELECTED_SERVICE_ADD_SOURCE_TOOLBOX = "SELECTED_SERVICE_ADD_SOURCE_TOOLBOX"
export const REQUEST_PREVIEW_SOURCE_LINKS = "REQUEST_PREVIEW_SOURCE_LINKS"
export const CONFIRM_PREVIEW_SOURCE_LINKS = "CONFIRM_PREVIEW_SOURCE_LINKS"
export const ERROR_PREVIEW_SOURCE_LINKS = "ERROR_PREVIEW_SOURCE_LINKS"
export const CLEAR_PREVIEW_SOURCE_LINKS = "CLEAR_PREVIEW_SOURCE_LINKS"
export const SAVE_TWITTER_SEARCH_OBJ = "SAVE_TWITTER_SEARCH_OBJ"

export const REQUEST_PROFILE_SOURCES = "REQUEST_PROFILE_SOURCES"
export const CONFIRM_REQUEST_PROFILE_SOURCES = "CONFIRM_REQUEST_PROFILE_SOURCES"
export const ERROR_REQUEST_PROFILE_SOURCES = "ERROR_REQUEST_PROFILE_SOURCES"

export const REQUEST_DELETE_PROFILE_SOURCES = "REQUEST_DELETE_PROFILE_SOURCES"
export const CONFIRM_DELETE_PROFILE_SOURCES = "CONFIRM_DELETE_PROFILE_SOURCES"
export const ERROR_DELETE_PROFILE_SOURCES = "ERROR_DELETE_PROFILE_SOURCES"

export const REQUEST_UPDATE_PROFILE_SOURCE = "REQUEST_UPDATE_PROFILE_SOURCE"
export const CONFIRM_UPDATE_PROFILE_SOURCE = "CONFIRM_UPDATE_PROFILE_SOURCE"
export const ERROR_UPDATE_PROFILE_SOURCE = "ERROR_UPDATE_PROFILE_SOURCE"

export const CLEAR_PROFILE_SOURCES = "CLEAR_PROFILE_SOURCES"

export const REQUEST_SEARCH_PROFILE_SOURCES = "REQUEST_SEARCH_PROFILE_SOURCES"
export const ERROR_REQUEST_SEARCH_PROFILE_SOURCES = "ERROR_REQUEST_SEARCH_PROFILE_SOURCES"

export const REQUEST_CREATE_SOCIAL_SOURCE = "REQUEST_CREATE_SOCIAL_SOURCE"
export const CONFIRM_CREATE_SOCIAL_SOURCE = "CONFIRM_CREATE_SOCIAL_SOURCE"
export const ERROR_CREATE_SOCIAL_SOURCE = "ERROR_CREATE_SOCIAL_SOURCE"

export const STORE_PREVIEW_SOURCE_LINKS = "STORE_PREVIEW_SOURCE_LINKS"
export const CLEAR_STORE_PREVIEW_SOURCE_LINKS = "CLEAR_STORE_PREVIEW_SOURCE_LINKS"
export const REMOVE_FROM_STORE_PREVIEW_SOURCE_LINKS = "REMOVE_FROM_STORE_PREVIEW_SOURCE_LINKS"

export const CLEAR_CREATE_SOCIAL_SOURCE = "CLEAR_CREATE_SOCIAL_SOURCE"
export const CLEAR_DELETE_SOCIAL_SOURCE = "CLEAR_DELETE_SOCIAL_SOURCE"

export const SETUP_TOOLBOX_NAVIGATION = "SETUP_TOOLBOX_NAVIGATION"

export const UPDATE_PROFILE_SOURCE_DETAIL = "UPDATE_PROFILE_SOURCE_DETAIL"

export const REQUEST_SOURCE_SUB_ITEMS = "REQUEST_SOURCE_SUB_ITEMS"
export const CONFIRM_REQUEST_SOURCE_SUB_ITEMS = "CONFIRM_REQUEST_SOURCE_SUB_ITEMS"
export const ERROR_REQUEST_SOURCE_SUB_ITEMS = "ERROR_REQUEST_SOURCE_SUB_ITEMS"

export const DELETE_SOURCE_SUB_ITEMS = "DELETE_SOURCE_SUB_ITEMS"
export const CONFIRM_DELETE_SOURCE_SUB_ITEMS = "CONFIRM_DELETE_SOURCE_SUB_ITEMS"
export const ERROR_DELETE_SOURCE_SUB_ITEMS = "ERROR_DELETE_SOURCE_SUB_ITEMS"

export const CLEAR_SOURCE_SUB_ITEMS = "CLEAR_SOURCE_SUB_ITEMS"
export const CLEAR_DELETE_SUB_ITEMS = "CLEAR_DELETE_SUB_ITEMS"

/*
 * Vertical Menu
 */
export const ERROR_VERTICAL_MENU_POSTS = "ERROR_VERTICAL_MENU_POSTS"
export const REQUEST_VERTICAL_MENU_POSTS = "REQUEST_VERTICAL_MENU_POSTS"
export const RECEIVE_VERTICAL_MENU_POSTS = "RECEIVE_VERTICAL_MENU_POSTS"

/*
 * Profile Tags
 */
export const GET_POST_PROFILE_TAGS_BY_POST_TO_EDIT = "GET_POST_PROFILE_TAGS_BY_POST_TO_EDIT"
export const CONFIRM_GET_POST_PROFILE_TAGS_BY_POST_TO_EDIT = "CONFIRM_GET_POST_PROFILE_TAGS_BY_POST_TO_EDIT"
export const ERROR_GET_POST_PROFILE_TAGS_BY_POST_TO_EDIT = "ERROR_GET_POST_PROFILE_TAGS_BY_POST_TO_EDIT"
export const CLEAN_GET_POST_PROFILE_TAGS_BY_POST_TO_EDIT = "CLEAN_GET_POST_PROFILE_TAGS_BY_POST_TO_EDIT"

/*
 * Feedly Sources
 */
export const FEEDLY_SUB_SOURCE_ADD = "FEEDLY_SUB_SOURCE_ADD"
export const FEEDLY_SUB_SOURCE_UPDATE = "FEEDLY_SUB_SOURCE_UPDATE"
export const FEEDLY_SUB_SOURCES_CLEAR = "FEEDLY_SUB_SOURCES_CLEAR"

/*
 * Board Actions
 */
export const ERROR_REQUEST_EXPERIENCE_ORDER_CHANGE = "ERROR_REQUEST_EXPERIENCE_ORDER_CHANGE"
export const REQUEST_EXPERIENCE_ORDER_CHANGE = "REQUEST_EXPERIENCE_ORDER_CHANGE"
export const CLEAR_REDUX_POST_ORDERING = "CLEAR_REDUX_POST_ORDERING"
export const SUCCESS_CHANGE_EXPERIENCE_ORDER = "SUCCESS_CHANGE_EXPERIENCE_ORDER"
export const ERROR_REQUEST_EXPERIENCE_ORDER_DELETE = "ERROR_REQUEST_EXPERIENCE_ORDER_DELETE"
export const REQUEST_EXPERIENCE_ORDER_DELETE = "REQUEST_EXPERIENCE_ORDER_DELETE"
export const SUCCESS_DELETE_EXPERIENCE_ORDER = "SUCCESS_DELETE_EXPERIENCE_ORDER"

/*
 * Board Actions
 */
export const GET_ROUTES_USED = "GET_ROUTES_USED"
export const ADD_ROUTE_TAB_ID = "ADD_ROUTE_TAB_ID"
export const ADD_ROUTE_TOPIC_ID = "ADD_ROUTE_TOPIC_ID"
export const ADD_ROUTE_PILL_ID = "ADD_ROUTE_PILL_ID"
export const ADD_ROUTE_POST_ID = "ADD_ROUTE_POST_ID"
export const GET_LAST_ROUTE_USED = "GET_LAST_ROUTE_USED"

/*
 * Skynet
 */
export const DISPLAY_SOURCES_TAGS_LIST = "DISPLAY_SOURCES_TAGS_LIST"

export const SELECTED_FORMULA_TO_EDIT = "SELECTED_FORMULA_TO_EDIT"
export const CREATE_NEW_FORMULA = "CREATE_NEW_FORMULA"
export const CLEAR_FORMULA_BENCH = "CLEAR_FORMULA_BENCH"
export const REQUEST_SOURCES_LIST = "REQUEST_SOURCES_LIST"
export const RECEIVE_SOURCES_LIST = "RECEIVE_SOURCES_LIST"
export const ERROR_FETCH_SOURCES_LIST = "ERROR_FETCH_SOURCES_LIST"
export const SELECTED_GROUP = "SELECTED_GROUP"
export const ADD_SOURCES_TO_GROUP = "ADD_SOURCES_TO_GROUP"
export const ADD_TAGS_TO_GROUP = "ADD_TAGS_TO_GROUP"
export const CLEAR_ADD_SOURCES_TO_GROUP = "CLEAR_ADD_SOURCES_TO_GROUP"
export const CLEAR_ADD_TAGS_TO_GROUP = "CLEAR_ADD_TAGS_TO_GROUP"
export const CLEAR_SELECTED_GROUP = "CLEAR_SELECTED_GROUP"

export const REQUEST_FORMAT_TAGS_LIST = "REQUEST_FORMAT_TAGS_LIST"
export const RECEIVE_FORMAT_TAGS_LIST = "RECEIVE_FORMAT_TAGS_LIST"
export const ERROR_FETCH_FORMAT_TAGS_LIST = "ERROR_FETCH_FORMAT_TAGS_LIST"

export const REQUEST_GENERATED_TAGS_LIST = "REQUEST_GENERATED_TAGS_LIST"
export const RECEIVE_GENERATED_TAGS_LIST = "RECEIVE_GENERATED_TAGS_LIST"
export const ERROR_FETCH_GENERATED_TAGS_LIST = "ERROR_FETCH_GENERATED_TAGS_LIST"

export const REQUEST_PROFILE_TAGS_LIST = "REQUEST_PROFILE_TAGS_LIST"
export const RECEIVE_PROFILE_TAGS_LIST = "RECEIVE_PROFILE_TAGS_LIST"
export const ERROR_FETCH_PROFILE_TAGS_LIST = "ERROR_FETCH_PROFILE_TAGS_LIST"

export const REQUEST_NATIVE_TAGS_LIST = "REQUEST_NATIVE_TAGS_LIST"
export const RECEIVE_NATIVE_TAGS_LIST = "RECEIVE_NATIVE_TAGS_LIST"
export const ERROR_FETCH_NATIVE_TAGS_LIST = "ERROR_FETCH_NATIVE_TAGS_LIST"

export const REQUEST_CATEGORY_TAGS_LIST = "REQUEST_CATEGORY_TAGS_LIST"
export const RECEIVE_CATEGORY_TAGS_LIST = "RECEIVE_CATEGORY_TAGS_LIST"
export const ERROR_FETCH_CATEGORY_TAGS_LIST = "ERROR_FETCH_CATEGORY_TAGS_LIST"

export const REQUEST_SITES_LIST = "REQUEST_SITES_LIST"
export const RECEIVE_SITES_LIST = "RECEIVE_SITES_LIST"
export const ERROR_FETCH_SITES_LIST = "ERROR_FETCH_SITES_LIST"

export const UPDATE_SITES_LIST = "UPDATE_SITES_LIST"

export const REQUEST_GROUPS_LIST = "REQUEST_GROUPS_LIST"
export const RECEIVE_GROUPS_LIST = "RECEIVE_GROUPS_LIST"
export const ERROR_FETCH_GROUPS_LIST = "ERROR_FETCH_GROUPS_LIST"
export const CLEAR_GROUPS_LIST = "CLEAR_GROUPS_LIST"

export const REQUEST_OBJECT_TEMPLATES_CATEGORIES_LIST = "REQUEST_OBJECT_TEMPLATES_CATEGORIES_LIST"
export const RECEIVE_OBJECT_TEMPLATES_CATEGORIES_LIST = "RECEIVE_OBJECT_TEMPLATES_CATEGORIES_LIST"
export const ERROR_FETCH_OBJECT_TEMPLATES_CATEGORIES_LIST = "ERROR_FETCH_OBJECT_TEMPLATES_CATEGORIES_LIST"

export const REQUEST_OBJECT_TEMPLATES_CATEGORIES_PUBLISH_LIST = "REQUEST_OBJECT_TEMPLATES_CATEGORIES_PUBLISH_LIST"
export const RECEIVE_OBJECT_TEMPLATES_CATEGORIES_PUBLISH_LIST = "RECEIVE_OBJECT_TEMPLATES_CATEGORIES_PUBLISH_LIST"
export const ERROR_FETCH_OBJECT_TEMPLATES_CATEGORIES_PUBLISH_LIST =
  "ERROR_FETCH_OBJECT_TEMPLATES_CATEGORIES_PUBLISH_LIST"

export const REQUEST_CREATE_GROUP = "REQUEST_CREATE_GROUP"
export const SUCCESS_CREATE_GROUP = "SUCCESS_CREATE_GROUP"
export const ERROR_CREATE_GROUP = "ERROR_CREATE_GROUP"
export const CLEAR_CREATED_GROUP = "CLEAR_CREATED_GROUP"

export const REQUEST_DELETE_GROUP = "REQUEST_DELETE_GROUP"
export const SUCCESS_DELETE_GROUP = "SUCCESS_DELETE_GROUP"
export const ERROR_DELETE_GROUP = "ERROR_DELETE_GROUP"
export const CLEAR_DELETED_GROUP = "CLEAR_DELETED_GROUP"

export const REQUEST_UPDATE_GROUP = "REQUEST_UPDATE_GROUP"
export const SUCCESS_UPDATE_GROUP = "SUCCESS_UPDATE_GROUP"
export const ERROR_UPDATE_GROUP = "ERROR_UPDATE_GROUP"
export const CLEAR_UPDATED_GROUP = "CLEAR_UPDATED_GROUP"

export const REQUEST_GROUP_INFO = "REQUEST_GROUP_INFO"
export const RECEIVE_GROUP_INFO = "RECEIVE_GROUP_INFO"
export const ERROR_FETCH_GROUP_INFO = "ERROR_FETCH_GROUP_INFO"
export const CLEAR_GROUP_INFO = "CLEAR_GROUP_INFO"

export const REQUEST_CREATE_OBJECT_TEMPLATE = "REQUEST_CREATE_OBJECT_TEMPLATE"
export const SUCCESS_CREATE_OBJECT_TEMPLATE = "SUCCESS_CREATE_OBJECT_TEMPLATE"
export const ERROR_CREATE_OBJECT_TEMPLATE = "ERROR_CREATE_OBJECT_TEMPLATE"
export const CLEAR_CREATED_OBJECT_TEMPLATE = "CLEAR_CREATED_OBJECT_TEMPLATE"

export const REQUEST_OBJECT_TEMPLATES_LIST = "REQUEST_OBJECT_TEMPLATES_LIST"
export const RECEIVE_OBJECT_TEMPLATES_LIST = "RECEIVE_OBJECT_TEMPLATES_LIST"
export const ERROR_FETCH_OBJECT_TEMPLATES_LIST = "ERROR_FETCH_OBJECT_TEMPLATES_LIST"

export const REQUEST_OBJECT_TEMPLATES_PLATFORM_LIST = "REQUEST_OBJECT_TEMPLATES_PLATFORM_LIST"
export const RECEIVE_OBJECT_TEMPLATES_PLATFORM_LIST = "RECEIVE_OBJECT_TEMPLATES_PLATFORM_LIST"
export const ERROR_FETCH_OBJECT_TEMPLATES_PLATFORM_LIST = "ERROR_FETCH_OBJECT_TEMPLATES_PLATFORM_LIST"

export const ADD_OBJECT_TEMPLATE = "ADD_OBJECT_TEMPLATE"
export const CLEAR_ADD_OBJECT_TEMPLATE = "CLEAR_ADD_OBJECT_TEMPLATE"

export const ERROR_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER = "ERROR_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER"
export const REQUEST_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER = "REQUEST_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER"
export const SUCCESS_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER = "SUCCESS_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER"
export const CLEAR_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER = "CLEAR_CREATE_OBJECT_TEMPLATE_EXPERIENCE_MANAGER"

export const ERROR_DELETE_OBJECT_TEMPLATE = "ERROR_DELETE_OBJECT_TEMPLATE"
export const REQUEST_DELETE_OBJECT_TEMPLATE = "REQUEST_DELETE_OBJECT_TEMPLATE"
export const SUCCESS_DELETE_OBJECT_TEMPLATE = "SUCCESS_DELETE_OBJECT_TEMPLATE"
export const CLEAR_DELETE_OBJECT_TEMPLATE = "CLEAR_DELETE_OBJECT_TEMPLATE"

export const ERROR_UPDATE_OBJECT_TEMPLATE = "ERROR_UPDATE_OBJECT_TEMPLATE"
export const REQUEST_UPDATE_OBJECT_TEMPLATE = "REQUEST_UPDATE_OBJECT_TEMPLATE"
export const SUCCESS_UPDATE_OBJECT_TEMPLATE = "SUCCESS_UPDATE_OBJECT_TEMPLATE"

export const ERROR_OBJECT_TEMPLATE_TO_CLONE = "ERROR_OBJECT_TEMPLATE_TO_CLONE"
export const REQUEST_OBJECT_TEMPLATE_TO_CLONE = "REQUEST_OBJECT_TEMPLATE_TO_CLONE"
export const SUCCESS_OBJECT_TEMPLATE_TO_CLONE = "SUCCESS_OBJECT_TEMPLATE_TO_CLONE"
export const CLEAR_OBJECT_TEMPLATE_TO_CLONE = "CLEAR_OBJECT_TEMPLATE_TO_CLONE"

export const ERROR_MOVE_OBJECT_TEMPLATE = "ERROR_MOVE_OBJECT_TEMPLATE"
export const REQUEST_MOVE_OBJECT_TEMPLATE = "REQUEST_MOVE_OBJECT_TEMPLATE"
export const SUCCESS_MOVE_OBJECT_TEMPLATE = "SUCCESS_MOVE_OBJECT_TEMPLATE"
export const CLEAR_MOVE_OBJECT_TEMPLATE = "CLEAR_MOVE_OBJECT_TEMPLATE"

export const ERROR_SAVE_COOKIE_BAR_SETTINGS = "ERROR_SAVE_COOKIE_BAR_SETTINGS"
export const REQUEST_SAVE_COOKIE_BAR_SETTINGS = "REQUEST_SAVE_COOKIE_BAR_SETTINGS"
export const SUCCESS_SAVE_COOKIE_BAR_SETTINGS = "SUCCESS_SAVE_COOKIE_BAR_SETTINGS"

export const ERROR_OBJECT_TEMPLATE_SCREEN_SHOT = "ERROR_OBJECT_TEMPLATE_SCREEN_SHOT"
export const REQUEST_OBJECT_TEMPLATE_SCREEN_SHOT = "REQUEST_OBJECT_TEMPLATE_SCREEN_SHOT"
export const SUCCESS_OBJECT_TEMPLATE_SCREEN_SHOT = "SUCCESS_OBJECT_TEMPLATE_SCREEN_SHOT"
export const CLEAR_OBJECT_TEMPLATE_SCREEN_SHOT = "CLEAR_PUBLISH_OBJECT_TEMPLATE"

export const ERROR_GET_PLATFORM_PAGE_TEMPLATES = "ERROR_GET_PLATFORM_PAGE_TEMPLATES"
export const REQUEST_GET_PLATFORM_PAGE_TEMPLATES = "REQUEST_GET_PLATFORM_PAGE_TEMPLATES"
export const SUCCESS_GET_PLATFORM_PAGE_TEMPLATES = "SUCCESS_GET_PLATFORM_PAGE_TEMPLATES"
export const CLEAR_GET_PLATFORM_PAGE_TEMPLATES = "CLEAR_GET_PLATFORM_PAGE_TEMPLATES"

export const ERROR_CREATE_FLUXIO_POST = "ERROR_CREATE_FLUXIO_POST"
export const REQUEST_CREATE_FLUXIO_POST = "REQUEST_CREATE_FLUXIO_POST"
export const SUCCESS_CREATE_FLUXIO_POST = "SUCCESS_CREATE_FLUXIO_POST"
export const CLEAN_CREATE_FLUXIO_POST = "CLEAN_CREATE_FLUXIO_POST"

export const ERROR_UPDATE_FLUXIO_POST = "ERROR_UPDATE_FLUXIO_POST"
export const REQUEST_UPDATE_FLUXIO_POST = "REQUEST_UPDATE_FLUXIO_POST"
export const SUCCESS_UPDATE_FLUXIO_POST = "SUCCESS_UPDATE_FLUXIO_POST"
export const CLEAN_UPDATE_FLUXIO_POST = "CLEAN_UPDATE_FLUXIO_POST"

export const ERROR_DELETE_FLUXIO_POST = "ERROR_DELETE_FLUXIO_POST"
export const REQUEST_DELETE_FLUXIO_POST = "REQUEST_DELETE_FLUXIO_POST"
export const SUCCESS_DELETE_FLUXIO_POST = "SUCCESS_DELETE_FLUXIO_POST"
export const CLEAN_DELETE_FLUXIO_POST = "CLEAN_DELETE_FLUXIO_POST"

export const REQUEST_INPUT_SITES_LIST = "REQUEST_INPUT_SITES_LIST"
export const RECEIVE_INPUT_SITES_LIST = "RECEIVE_INPUT_SITES_LIST"
export const ERROR_FETCH_INPUT_SITES_LIST = "ERROR_FETCH_INPUT_SITES_LIST"

export const REQUEST_SINGLE_SITE = "REQUEST_SINGLE_SITE"
export const RECEIVE_SINGLE_SITE = "RECEIVE_SINGLE_SITE"
export const ERROR_FETCH_SINGLE_SITE = "ERROR_FETCH_SINGLE_SITE"

export const EDIT_SINGLE_SITE_PAGES_REDUX = "EDIT_SINGLE_SITE_PAGES_REDUX"

export const REQUEST_UPDATE_SINGLE_SITE = "REQUEST_UPDATE_SINGLE_SITE"
export const RECEIVE_UPDATE_SINGLE_SITE = "RECEIVE_UPDATE_SINGLE_SITE"
export const ERROR_UPDATE_SINGLE_SITE = "ERROR_UPDATE_SINGLE_SITE"
export const CLEAR_UPDATE_SINGLE_SITE = "CLEAR_UPDATE_SINGLE_SITE"

export const REQUEST_UPDATE_SINGLE_SITE_DOMAIN = "REQUEST_UPDATE_SINGLE_SITE_DOMAIN"
export const RECEIVE_UPDATE_SINGLE_SITE_DOMAIN = "RECEIVE_UPDATE_SINGLE_SITE_DOMAIN"
export const ERROR_UPDATE_SINGLE_SITE_DOMAIN = "ERROR_UPDATE_SINGLE_SITE_DOMAIN"
export const CLEAR_UPDATE_SINGLE_SITE_DOMAIN = "CLEAR_UPDATE_SINGLE_SITE_DOMAIN"

export const UPDATE_SINGLE_SITE = "UPDATE_SINGLE_SITE"

export const REQUEST_DELETE_SINGLE_SITE = "REQUEST_DELETE_SINGLE_SITE"
export const RECEIVE_DELETE_SINGLE_SITE = "RECEIVE_DELETE_SINGLE_SITE"
export const ERROR_DELETE_SINGLE_SITE = "ERROR_DELETE_SINGLE_SITE"
export const CLEAR_DELETE_SINGLE_SITE = "CLEAR_DELETE_SINGLE_SITE"

export const REQUEST_UPDATE_SINGLE_SITE_SETTINGS = "REQUEST_UPDATE_SINGLE_SITE_SETTINGS"
export const RECEIVE_UPDATE_SINGLE_SITE_SETTINGS = "RECEIVE_UPDATE_SINGLE_SITE_SETTINGS"
export const ERROR_UPDATE_SINGLE_SITE_SETTINGS = "ERROR_UPDATE_SINGLE_SITE_SETTINGS"
export const CLEAR_UPDATE_SINGLE_SITE_SETTINGS = "CLEAR_UPDATE_SINGLE_SITE_SETTINGS"

export const REQUEST_UPDATE_SINGLE_SITE_SETTINGS_MEDIA = "REQUEST_UPDATE_SINGLE_SITE_SETTINGS_MEDIA"
export const RECEIVE_UPDATE_SINGLE_SITE_SETTINGS_MEDIA = "RECEIVE_UPDATE_SINGLE_SITE_SETTINGS_MEDIA"
export const ERROR_UPDATE_SINGLE_SITE_SETTINGS_MEDIA = "ERROR_UPDATE_SINGLE_SITE_SETTINGS_MEDIA"
export const CLEAR_UPDATE_SINGLE_SITE_SETTINGS_MEDIA = "CLEAR_UPDATE_SINGLE_SITE_SETTINGS_MEDIA"

export const REQUEST_UPDATE_SINGLE_PAGE_SETTINGS_MEDIA = "REQUEST_UPDATE_SINGLE_PAGE_SETTINGS_MEDIA"
export const RECEIVE_UPDATE_SINGLE_PAGE_SETTINGS_MEDIA = "RECEIVE_UPDATE_SINGLE_PAGE_SETTINGS_MEDIA"
export const ERROR_UPDATE_SINGLE_PAGE_SETTINGS_MEDIA = "ERROR_UPDATE_SINGLE_PAGE_SETTINGS_MEDIA"
export const CLEAR_UPDATE_SINGLE_PAGE_SETTINGS_MEDIA = "CLEAR_UPDATE_SINGLE_PAGE_SETTINGS_MEDIA"

export const REQUEST_FETCH_SINGLE_SITE_SETTINGS = "REQUEST_FETCH_SINGLE_SITE_SETTINGS"
export const RECEIVE_FETCH_SINGLE_SITE_SETTINGS = "RECEIVE_FETCH_SINGLE_SITE_SETTINGS"
export const ERROR_FETCH_SINGLE_SITE_SETTINGS = "ERROR_FETCH_SINGLE_SITE_SETTINGS"
export const CLEAR_FETCH_SINGLE_SITE_SETTINGS = "CLEAR_FETCH_SINGLE_SITE_SETTINGS"

export const EDIT_SINGLE_SITE_SETTINGS_REDUX = "EDIT_SINGLE_SITE_REDUX"

export const REQUEST_SINGLE_SITE_PAGES = "REQUEST_SINGLE_SITE_PAGES"
export const RECEIVE_SINGLE_SITE_PAGES = "RECEIVE_SINGLE_SITE_PAGES"
export const ERROR_FETCH_SINGLE_SITE_PAGES = "ERROR_FETCH_SINGLE_SITE_PAGES"

export const REQUEST_SINGLE_SITE_OUTDATED_PAGES = "REQUEST_SINGLE_SITE_OUTDATED_PAGES"
export const RECEIVE_SINGLE_SITE_OUTDATED_PAGES = "RECEIVE_SINGLE_SITE_OUTDATED_PAGES"
export const ERROR_FETCH_SINGLE_SITE_OUTDATED_PAGES = "ERROR_FETCH_SINGLE_SITE_OUTDATED_PAGES"

export const REQUEST_CREATE_SITE = "REQUEST_CREATE_SITE"
export const SUCCESS_CREATE_SITE = "SUCCESS_CREATE_SITE"
export const ERROR_CREATE_SITE = "ERROR_CREATE_SITE"
export const CLEAR_CREATED_SITE = "CLEAR_CREATED_SITE"

/*
 * Experience Manager
 */
export const ERROR_FETCH_OBJECT_TEMPLATE_BY_ID = "ERROR_FETCH_OBJECT_TEMPLATE_BY_ID"
export const REQUEST_OBJECT_TEMPLATE_BY_ID = "REQUEST_OBJECT_TEMPLATE_BY_ID"
export const SUCCESS_FETCH_OBJECT_TEMPLATE_BY_ID = "SUCCESS_FETCH_OBJECT_TEMPLATE_BY_ID"

export const ERROR_FETCH_HOSTS = "ERROR_FETCH_HOSTS"
export const REQUEST_HOSTS = "REQUEST_HOSTS"
export const RECEIVE_HOSTS = "RECEIVE_HOSTS"
export const CLEAR_FETCHED_HOSTS = "CLEAR_FETCHED_HOSTS"

export const ERROR_FETCH_HOST = "ERROR_FETCH_HOST"
export const FETCHING_HOST = "FETCHING_HOST"
export const SUCCESS_FETCH_HOST = "SUCCESS_FETCH_HOST"

export const ERROR_CREATE_HOST = "ERROR_CREATE_HOST"
export const REQUEST_CREATE_HOST = "REQUEST_CREATE_HOST"
export const SUCCESS_CREATE_HOST = "SUCCESS_CREATE_HOST"

export const ERROR_UPDATE_HOST = "ERROR_UPDATE_HOST"
export const REQUEST_UPDATE_HOST = "REQUEST_UPDATE_HOST"
export const SUCCESS_UPDATE_HOST = "SUCCESS_UPDATE_HOST"

export const CLEAR_HOST_INFO_ACTIONS = "CLEAR_HOST_INFO_ACTIONS"

export const ERROR_FETCH_AUDIENCES = "ERROR_FETCH_AUDIENCES"
export const REQUEST_AUDIENCES = "REQUEST_AUDIENCES"
export const RECEIVE_AUDIENCES = "RECEIVE_AUDIENCES"

export const ERROR_CREATE_AUDIENCE = "ERROR_CREATE_AUDIENCE"
export const REQUEST_CREATE_AUDIENCE = "REQUEST_CREATE_AUDIENCE"
export const SUCCESS_CREATE_AUDIENCE = "SUCCESS_CREATE_AUDIENCE"

export const ERROR_UPDATE_AUDIENCE = "ERROR_UPDATE_AUDIENCE"
export const REQUEST_UPDATE_AUDIENCE = "REQUEST_UPDATE_AUDIENCE"
export const SUCCESS_UPDATE_AUDIENCE = "SUCCESS_UPDATE_AUDIENCE"

export const ERROR_DELETE_AUDIENCE = "ERROR_DELETE_AUDIENCE"
export const REQUEST_DELETE_AUDIENCE = "REQUEST_DELETE_AUDIENCE"
export const SUCCESS_DELETE_AUDIENCE = "SUCCESS_DELETE_AUDIENCE"

export const CLEAR_AUDIENCE_INFO_ACTIONS = "CLEAR_AUDIENCE_INFO_ACTIONS"

export const ERROR_FETCH_HUBS = "ERROR_FETCH_HUBS"
export const REQUEST_HUBS = "REQUEST_HUBS"
export const RECEIVE_HUBS = "RECEIVE_HUBS"

export const ERROR_FETCH_DOMAIN_PAGES = "ERROR_FETCH_DOMAIN_PAGES"
export const RECEIVE_DOMAIN_PAGES = "RECEIVE_DOMAIN_PAGES"
export const REQUEST_DOMAIN_PAGES = "REQUEST_DOMAIN_PAGES"
export const CLEAR_DOMAIN_PAGES = "CLEAR_DOMAIN_PAGES"

export const ERROR_EDIT_HUB_TEMPLATE = "ERROR_EDIT_HUB_TEMPLATE"
export const REQUEST_EDIT_HUB_TEMPLATE = "REQUEST_EDIT_HUB_TEMPLATE"
export const EDITED_HUB_TEMPLATE = "EDITED_HUB_TEMPLATE"

export const EDIT_HUB_TEMPLATE_INTERNAL_REDUX = "EDIT_HUB_TEMPLATE_INTERNAL_REDUX"

export const REPLACE_EXPERIENCE = "REPLACE_EXPERIENCE"

export const SELECTED_HUB_HANDLER_TO_EDIT = "SELECTED_HUB_HANDLER_TO_EDIT"
export const CLEAR_HUB_HANDLER_PARENTID = "CLEAR_HUB_HANDLER_PARENTID"

export const ERROR_FETCHING_FORMULAS_LIST = "ERROR_FETCHING_FORMULAS_LIST"
export const REQUEST_FORMULAS_LIST = "REQUEST_FORMULAS_LIST"
export const RECEIVE_FORMULAS_LIST = "RECEIVE_FORMULAS_LIST"
export const CLEAR_FORMULAS_LIST = "CLEAR_FORMULAS_LIST"

export const ERROR_SEARCH_BY_POSTS_EXPERIENCE_MANAGER = "ERROR_SEARCH_BY_POSTS_EXPERIENCE_MANAGER"
export const REQUEST_SEARCH_BY_POSTS_EXPERIENCE_MANAGER = "REQUEST_SEARCH_BY_POSTS_EXPERIENCE_MANAGER"
export const SUCCESS_SEARCH_BY_POSTS_EXPERIENCE_MANAGER = "SUCCESS_SEARCH_BY_POSTS_EXPERIENCE_MANAGER"
export const CLEAR_SEARCH_BY_POSTS_EXPERIENCE_MANAGER = "CLEAR_SEARCH_BY_POSTS_EXPERIENCE_MANAGER"

export const SELECTED_FINDER = "SELECTED_FINDER"
export const SELECTED_FINDER_TYPE = "SELECTED_FINDER_TYPE"
export const REPLACE_FINDER_OBJECT = "REPLACE_FINDER_OBJECT"
export const CLEAR_SELECTED_FINDER = "CLEAR_SELECTED_FINDER"

export const FINDER_TYPE_PUBLIC_PAGE_REDUCER = "FINDER_TYPE_PUBLIC_PAGE_REDUCER"

export const SELECTED_BOB_ELEMENT_EM = "SELECTED_BOB_ELEMENT_EM"
export const ELEMENT_SELECTOR_STATE = "ELEMENT_SELECTOR_STATE"
export const OBJECT_SELECTOR_STATE = "OBJECT_SELECTOR_STATE"

export const TOGGLE_FINDER_PREVIEW = "TOGGLE_FINDER_PREVIEW"

export const SELECTED_FORMULA_TO_PREVIEW = "SELECTED_FORMULA_TO_PREVIEW"

export const ADD_BULK_POSTS_EXPERIENCE_MANAGER = "ADD_BULK_POSTS_EXPERIENCE_MANAGER"
export const ADD_SINGLE_POST_EXPERIENCE_MANAGER = "ADD_SINGLE_POST_EXPERIENCE_MANAGER"
export const ADD_SINGLE_POST_BY_EMBED_EXPERIENCE_MANAGER = "ADD_SINGLE_POST_BY_EMBED_EXPERIENCE_MANAGER"
export const REMOVE_SINGLE_POST_EXPERIENCE_MANAGER = "REMOVE_SINGLE_POST_EXPERIENCE_MANAGER"
export const CLEAR_POSTS_EXPERIENCE_MANAGER = "CLEAR_POSTS_EXPERIENCE_MANAGER"
export const CLEAR_EDITED_POSTS_EXPERIENCE_MANAGER = "CLEAR_EDITED_POSTS_EXPERIENCE_MANAGER"

export const EXPERIENCE_MANAGER_EVENT_PILE = "EXPERIENCE_MANAGER_EVENT_PILE"
export const CLEAR_EXPERIENCE_MANAGER_EVENT_PILE = "CLEAR_EXPERIENCE_MANAGER_EVENT_PILE"

export const ERROR_EDITING_EXPERIENCE_MANAGER_TEMPLATE = "ERROR_EDITING_EXPERIENCE_MANAGER_TEMPLATE"
export const REQUEST_EDIT_EXPERIENCE_MANAGER_TEMPLATE = "REQUEST_EDIT_EXPERIENCE_MANAGER_TEMPLATE"
export const SUCCESS_EDIT_EXPERIENCE_MANAGER_TEMPLATE = "SUCCESS_EDIT_EXPERIENCE_MANAGER_TEMPLATE"

export const ERROR_CLONE_PAGE_EXPERIENCE_MANAGER = "ERROR_CLONE_PAGE_EXPERIENCE_MANAGER"
export const REQUEST_CLONE_PAGE_EXPERIENCE_MAGAGER = "REQUEST_CLONE_PAGE_EXPERIENCE_MAGAGER"
export const SUCCESS_CLONE_PAGE_EXPERIENCE_MANAGER = "SUCCESS_CLONE_PAGE_EXPERIENCE_MANAGER"
export const CLEAR_CLONE_PAGE_EXPERIENCE_MANAGER = "CLEAR_CLONE_PAGE_EXPERIENCE_MANAGER"

export const EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER = "EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER"
export const CLEAR_EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER = "CLEAR_EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER"

export const ERROR_INSERTING_OBJECT_EXPERIENCE_MANAGER = "ERROR_INSERTING_OBJECT_EXPERIENCE_MANAGER"
export const REQUEST_INSERT_OBJECT_EXPERIENCE_MANAGER = "REQUEST_INSERT_OBJECT_EXPERIENCE_MANAGER"
export const SUCCESS_INSERT_OBJECT_EXPERIENCE_MANAGER = "SUCCESS_INSERT_OBJECT_EXPERIENCE_MANAGER"

export const ERROR_CREATE_OBJECT_EXPERIENCE_MANAGER = "ERROR_CREATE_OBJECT_EXPERIENCE_MANAGER"
export const REQUEST_CREATE_OBJECT_EXPERIENCE_MANAGER = "REQUEST_CREATE_OBJECT_EXPERIENCE_MANAGER"
export const SUCCESS_CREATE_OBJECT_EXPERIENCE_MANAGER = "SUCCESS_CREATE_OBJECT_EXPERIENCE_MANAGER"
export const CLEAR_CREATE_OBJECT_EXPERIENCE_MANAGER = "CLEAR_CREATE_OBJECT_EXPERIENCE_MANAGER"

export const ERROR_INSERTING_FIRST_OBJECT_EXPERIENCE_MANAGER = "ERROR_INSERTING_FIRST_OBJECT_EXPERIENCE_MANAGER"
export const REQUEST_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER = "REQUEST_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER"
export const SUCCESS_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER = "SUCCESS_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER"

export const CLEAR_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER = "CLEAR_INSERT_FIRST_OBJECT_EXPERIENCE_MANAGER"

export const ERROR_ADDING_TEMPLATE_TO_OBJECT = "ERROR_ADDING_TEMPLATE_TO_OBJECT"
export const REQUEST_ADD_TEMPLATE_TO_OBJECT = "REQUEST_ADD_TEMPLATE_TO_OBJECT"
export const SUCCESS_ADD_TEMPLATE_TO_OBJECT = "SUCCESS_ADD_TEMPLATE_TO_OBJECT"

export const ERROR_ADDING_FORMULA_TO_OBJECT = "ERROR_ADDING_FORMULA_TO_OBJECT"
export const REQUEST_ADD_FORMULA_TO_OBJECT = "REQUEST_ADD_FORMULA_TO_OBJECT"
export const SUCCESS_ADD_FORMULA_TO_OBJECT = "SUCCESS_ADD_FORMULA_TO_OBJECT"

export const ERROR_CHANGE_COMPONENT_EXPERIENCE_MANAGER = "ERROR_CHANGE_COMPONENT_EXPERIENCE_MANAGER"
export const REQUEST_CHANGE_COMPONENT_EXPERIENCE_MANAGER = "REQUEST_CHANGE_COMPONENT_EXPERIENCE_MANAGER"
export const SUCCESS_CHANGE_COMPONENT_EXPERIENCE_MANAGER = "SUCCESS_CHANGE_COMPONENT_EXPERIENCE_MANAGER"
export const CLEAR_CHANGE_COMPONENT_EXPERIENCE_MANAGER = "CLEAR_CHANGE_COMPONENT_EXPERIENCE_MANAGER"

export const ERROR_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER = "ERROR_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER"
export const REQUEST_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER = "REQUEST_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER"
export const SUCCESS_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER = "SUCCESS_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER"
export const CLEAR_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER = "CLEAR_APPLY_CHANGE_COMPONENT_EXPERIENCE_MANAGER"

export const ERROR_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER = "ERROR_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER"
export const REQUEST_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER = "REQUEST_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER"
export const SUCCESS_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER = "SUCCESS_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER"
export const CLEAR_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER = "CLEAR_UNDO_CHANGE_COMPONENT_EXPERIENCE_MANAGER"

export const ERROR_VALIDATING_URI = "ERROR_VALIDATING_URI"
export const REQUEST_VALIDATE_URI = "REQUEST_VALIDATE_URI"
export const SUCCESS_VALIDATE_URI = "SUCCESS_VALIDATE_URI"
export const CLEAR_VALIDATED_URI = "CLEAR_VALIDATED_URI"

export const ERROR_VALIDATING_FIRST_LEVEL_URI = "ERROR_VALIDATING_FIRST_LEVEL_URI"
export const REQUEST_VALIDATE_FIRST_LEVEL_URI = "REQUEST_VALIDATE_FIRST_LEVEL_URI"
export const SUCCESS_VALIDATE_FIRST_LEVEL_URI = "SUCCESS_VALIDATE_FIRST_LEVEL_URI"
export const CLEAR_VALIDATED_FIRST_LEVEL_URI = "CLEAR_VALIDATED_FIRST_LEVEL_URI"

export const ERROR_FETCH_FORMULA_CONTENT_EM = "ERROR_FETCH_FORMULA_CONTENT_EM"
export const REQUEST_FORMULA_CONTENT_EM = "REQUEST_FORMULA_CONTENT_EM"
export const SUCCESS_FETCH_FORMULA_CONTENT_EM = "SUCCESS_FETCH_FORMULA_CONTENT_EM"
export const CLEAR_FETCH_FORMULA_CONTENT_EM = "CLEAR_FETCH_FORMULA_CONTENT_EM"

export const ERROR_FETCH_FORMULA_CONTENT_PREVIEW_EM = "ERROR_FETCH_FORMULA_CONTENT_PREVIEW_EM"
export const REQUEST_FORMULA_CONTENT_PREVIEW_EM = "REQUEST_FORMULA_CONTENT_PREVIEW_EM"
export const SUCCESS_FETCH_FORMULA_CONTENT_PREVIEW_EM = "SUCCESS_FETCH_FORMULA_CONTENT_PREVIEW_EM"
export const CLEAR_FETCH_FORMULA_CONTENT_PREVIEW_EM = "CLEAR_FETCH_FORMULA_CONTENT_PREVIEW_EM"

// load more formula content
export const ERROR_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM = "ERROR_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM"
export const REQUEST_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM = "REQUEST_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM"
export const SUCCESS_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM = "SUCCESS_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM"
export const CLEAR_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM = "CLEAR_FETCH_MORE_FORMULA_CONTENT_PREVIEW_EM"
// load more posts search
export const ERROR_FETCH_MORE_POST_SEARCH_PREVIEW_EM = "ERROR_FETCH_MORE_POST_SEARCH_PREVIEW_EM"
export const REQUEST_FETCH_MORE_POST_SEARCH_PREVIEW_EM = "REQUEST_FETCH_MORE_POST_SEARCH_PREVIEW_EM"
export const SUCCESS_FETCH_MORE_POST_SEARCH_PREVIEW_EM = "SUCCESS_FETCH_MORE_POST_SEARCH_PREVIEW_EM"
export const CLEAR_FETCH_MORE_POST_SEARCH_PREVIEW_EM = "CLEAR_FETCH_MORE_POST_SEARCH_PREVIEW_EM"

export const CLEAR_FETCH_OBJECT_FORMULA_CONTENT_EM = "CLEAR_FETCH_OBJECT_FORMULA_CONTENT_EM"

export const ERROR_CREATE_FORMULA_BY_POSTS_QUERY_EM = "ERROR_CREATE_FORMULA_BY_POSTS_QUERY_EM"
export const REQUEST_CREATE_FORMULA_BY_POSTS_QUERY_EM = "REQUEST_CREATE_FORMULA_BY_POSTS_QUERY_EM"
export const SUCCESS_CREATE_FORMULA_BY_POSTS_QUERY_EM = "SUCCESS_CREATE_FORMULA_BY_POSTS_QUERY_EM"

export const ERROR_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM = "ERROR_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM"
export const REQUEST_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM = "REQUEST_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM"
export const SUCCESS_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM = "SUCCESS_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM"
export const CLEAR_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM = "CLEAR_CREATE_DIRECT_FORMULA_BY_POSTS_QUERY_EM"

export const ERROR_REORDER_OBJECTS_EXPERIENCE_MANAGER = "ERROR_REORDER_OBJECTS_EXPERIENCE_MANAGER"
export const REQUEST_REORDER_OBJECTS_EXPERIENCE_MANAGER = "REQUEST_REORDER_OBJECTS_EXPERIENCE_MANAGER"
export const SUCCESS_REORDER_OBJECTS_EXPERIENCE_MANAGER = "SUCCESS_REORDER_OBJECTS_EXPERIENCE_MANAGER"

export const ERROR_MOVE_OBJECTS_EXPERIENCE_MANAGER = "ERROR_MOVE_OBJECTS_EXPERIENCE_MANAGER"
export const REQUEST_MOVE_OBJECTS_EXPERIENCE_MANAGER = "REQUEST_MOVE_OBJECTS_EXPERIENCE_MANAGER"
export const SUCCESS_MOVE_OBJECTS_EXPERIENCE_MANAGER = "SUCCESS_MOVE_OBJECTS_EXPERIENCE_MANAGER"

export const ERROR_UPDATING_OBJECT_EXPERIENCE_MANAGER = "ERROR_UPDATING_OBJECT_EXPERIENCE_MANAGER"
export const REQUEST_UPDATE_OBJECT_EXPERIENCE_MANAGER = "REQUEST_UPDATE_OBJECT_EXPERIENCE_MANAGER"
export const SUCCESS_UPDATE_OBJECT_EXPERIENCE_MANAGER = "SUCCESS_UPDATE_OBJECT_EXPERIENCE_MANAGER"
export const CLEAR_UPDATE_OBJECT_EXPERIENCE_MANAGER = "CLEAR_UPDATE_OBJECT_EXPERIENCE_MANAGER"

export const ERROR_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER = "ERROR_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER"
export const REQUEST_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER = "REQUEST_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER"
export const SUCCESS_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER = "SUCCESS_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER"
export const CLEAR_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER = "CLEAR_UPDATE_OBJECT_CONTENT_EXPERIENCE_MANAGER"

export const ERROR_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER = "ERROR_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER"
export const REQUEST_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER = "REQUEST_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER"
export const SUCCESS_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER = "SUCCESS_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER"
export const CLEAR_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER = "CLEAR_UPDATE_OBJECT_SETTINGS_EXPERIENCE_MANAGER"

export const CLEAR_UPDATE_PAGE_OBJECT_EXPERIENCE_MANAGER = "CLEAR_UPDATE_PAGE_OBJECT_EXPERIENCE_MANAGER"

export const REQUEST_UPDATE_PAGE_SETTINGS = "REQUEST_UPDATE_PAGE_SETTINGS"
export const RECEIVE_UPDATE_PAGE_SETTINGS = "RECEIVE_UPDATE_PAGE_SETTINGS"
export const ERROR_UPDATE_PAGE_SETTINGS = "ERROR_UPDATE_PAGE_SETTINGS"

export const ERROR_FETCH_IS_PAGE_PUBLISHED_STATUS = "ERROR_FETCH_IS_PAGE_PUBLISHED_STATUS"
export const REQUEST_IS_PAGE_PUBLISHED_STATUS = "REQUEST_IS_PAGE_PUBLISHED_STATUS"
export const SUCCESS_FETCH_IS_PAGE_PUBLISHED_STATUS = "SUCCESS_FETCH_IS_PAGE_PUBLISHED_STATUS"
export const CLEAR_FETCH_IS_PAGE_PUBLISHED_STATUS = "CLEAR_FETCH_IS_PAGE_PUBLISHED_STATUS"

export const ERROR_DELETING_OBJECT_FORMULA_EXPERIENCE_MANAGER = "ERROR_DELETING_OBJECT_FORMULA_EXPERIENCE_MANAGER"
export const REQUEST_DELETE_OBJECT_FORMULA_EXPERIENCE_MANAGER = "REQUEST_DELETE_OBJECT_FORMULA_EXPERIENCE_MANAGER"
export const SUCCESS_DELETE_OBJECT_FORMULA_EXPERIENCE_MANAGER = "SUCCESS_DELETE_OBJECT_FORMULA_EXPERIENCE_MANAGER"

export const ERROR_DELETING_OBJECT_EXPERIENCE_MANAGER = "ERROR_DELETING_OBJECT_EXPERIENCE_MANAGER"
export const REQUEST_DELETE_OBJECT_EXPERIENCE_MANAGER = "REQUEST_DELETE_OBJECT_EXPERIENCE_MANAGER"
export const SUCCESS_DELETE_OBJECT_EXPERIENCE_MANAGER = "SUCCESS_DELETE_OBJECT_EXPERIENCE_MANAGER"

export const ERROR_VALIDATING_OBJECT_URI = "ERROR_VALIDATING_OBJECT_URI"
export const REQUEST_VALIDATE_OBJECT_URI = "REQUEST_VALIDATE_OBJECT_URI"
export const SUCCESS_VALIDATE_OBJECT_URI = "SUCCESS_VALIDATE_OBJECT_URI"
export const CLEAR_VALIDATED_OBJECT_URI = "CLEAR_VALIDATED_OBJECT_URI"

export const DELETE_EVENT_EXPERIENCE_MANAGER = "DELETE_EVENT_EXPERIENCE_MANAGER"

export const OPEN_EDITOR_MODAL = "OPEN_EDITOR_MODAL"

export const CHANGE_EXPERIENCE_MANAGER_TYPE = "CHANGE_EXPERIENCE_MANAGER_TYPE"

export const LOG_FINDER_OPEN = "LOG_FINDER_OPEN"

export const UPDATE_SINGLE_OBJECT_PAGE_TREE = "UPDATE_SINGLE_OBJECT_PAGE_TREE"

export const VIEW_PORT_SIZE_STATE_EXPERIENCE_MANAGER = "VIEW_PORT_SIZE_STATE_EXPERIENCE_MANAGER"
export const VIEW_PORT_ORIGINAL_SIZE_STATE_EXPERIENCE_MANAGER = "VIEW_PORT_ORIGINAL_SIZE_STATE_EXPERIENCE_MANAGER"

export const SET_BEHAVIOUR_STATE = "SET_BEHAVIOUR_STATE"

export const ERROR_DELETE_HOST_OUTPUTTERS = "ERROR_DELETE_HOST_OUTPUTTERS"
export const REQUEST_DELETE_HOST_OUTPUTTERS = "REQUEST_DELETE_HOST_OUTPUTTERS"
export const SUCCESS_DELETE_HOST_OUTPUTTERS = "SUCCESS_DELETE_HOST_OUTPUTTERS"
export const CLEAR_DELETE_HOST_OUTPUTTERS = "CLEAR_DELETE_HOST_OUTPUTTERS"

export const ERROR_DELETE_ENTIRE_EXPERIENCE_MANAGER = "ERROR_DELETE_ENTIRE_EXPERIENCE_MANAGER"
export const REQUEST_DELETE_ENTIRE_EXPERIENCE_MANAGER = "REQUEST_DELETE_ENTIRE_EXPERIENCE_MANAGER"
export const SUCCESS_DELETE_ENTIRE_EXPERIENCE_MANAGER = "SUCCESS_DELETE_ENTIRE_EXPERIENCE_MANAGER"

export const CLEAR_DELETE_ENTIRE_EXPERIENCE_MANAGER = "CLEAR_DELETE_ENTIRE_EXPERIENCE_MANAGER"

export const ERROR_FETCH_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER =
  "ERROR_FETCH_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER"
export const REQUEST_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER = "REQUEST_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER"
export const SUCCESS_FETCH_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER =
  "SUCCESS_FETCH_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER"
export const CLEAR_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER = "CLEAR_COMPONENTS_CATEGORIES_EXPERIENCE_MANAGER"

export const ERROR_FETCHING_COMPONENTS_BY_CAT_FAM = "ERROR_FETCHING_COMPONENTS_BY_CAT_FAM"
export const REQUEST_COMPONENTS_BY_CAT_FAM = "REQUEST_COMPONENTS_BY_CAT_FAM"
export const SUCCESS_FETCH_COMPONENTS_BY_CAT_FAM = "SUCCESS_FETCH_COMPONENTS_BY_CAT_FAM"
export const CLEAR_COMPONENTS_LIST_BY_CAT_FAM = "CLEAR_COMPONENTS_LIST_BY_CAT_FAM"

export const CLEAR_SELECTED_COMPONENT_FAMILY = "CLEAR_SELECTED_COMPONENT_FAMILY"

export const SOCIAL_CHECK_ERROR = "SOCIAL_CHECK_ERROR"
export const SOCIAL_CHECKING = "SOCIAL_CHECKING"
export const SOCIAL_CHECKED = "SOCIAL_CHECKED"

export const VALIDATE_SOCIAL_FACEBOOK_ERROR = "VALIDATE_SOCIAL_FACEBOOK_ERROR"
export const VALIDATING_SOCIAL_FACEBOOK = "VALIDATING_SOCIAL_FACEBOOK"
export const VALIDATE_SOCIAL_FACEBOOK_SUCCESS = "VALIDATE_SOCIAL_FACEBOOK_SUCCESS"

export const VALIDATE_SOCIAL_TWITTER_ERROR = "VALIDATE_SOCIAL_TWITTER_ERROR"
export const VALIDATING_SOCIAL_TWITTER = "VALIDATING_SOCIAL_TWITTER"
export const VALIDATE_SOCIAL_TWITTER_SUCCESS = "VALIDATE_SOCIAL_TWITTER_SUCCESS"

export const VALIDATE_SOCIAL_GOOGLE_ERROR = "VALIDATE_SOCIAL_GOOGLE_ERROR"
export const VALIDATING_GOOGLE_FACEBOOK = "VALIDATING_GOOGLE_FACEBOOK"
export const VALIDATE_SOCIAL_GOOGLE_SUCCESS = "VALIDATE_SOCIAL_GOOGLE_SUCCESS"

export const CLEAR_SOCIAL_ACTIONS = "CLEAR_SOCIAL_ACTIONS"

export const UPDATE_STYLES_COLLAPSE = "UPDATE_STYLES_COLLAPSE"

export const DELETE_SOCIAL_ERROR = "DELETE_SOCIAL_ERROR"
export const DELETING_SOCIAL = "DELETING_SOCIAL"
export const DELETE_SOCIAL_SUCCESS = "DELETE_SOCIAL_SUCCESS"

export const DEFAULT_OBJECT_COLORS_EXPERIENCE_MANAGER = "DEFAULT_OBJECT_COLORS_EXPERIENCE_MANAGER"

export const INVALID_EXPERIENCE = "INVALID_ARTICLE_HUB"
export const REQUEST_EXPERIENCE = "REQUEST_EXPERIENCE"
export const RECEIVE_EXPERIENCE = "RECEIVE_EXPERIENCE"

export const ERROR_FETCH_EXPERIENCE_PREVIEW = "ERROR_FETCH_EXPERIENCE_PREVIEW"
export const REQUEST_FETCH_EXPERIENCE_PREVIEW = "REQUEST_FETCH_EXPERIENCE_PREVIEW"
export const SUCCESS_FETCH_EXPERIENCE_PREVIEW = "SUCCESS_FETCH_EXPERIENCE_PREVIEW"
export const CLEAR_FETCH_EXPERIENCE_PREVIEW = "CLEAR_FETCH_EXPERIENCE_PREVIEW"

export const ERROR_FETCH_EXPERIENCE_SYMBOL = "ERROR_FETCH_EXPERIENCE_SYMBOL"
export const REQUEST_FETCH_EXPERIENCE_SYMBOL = "REQUEST_FETCH_EXPERIENCE_SYMBOL"
export const SUCCESS_FETCH_EXPERIENCE_SYMBOL = "SUCCESS_FETCH_EXPERIENCE_SYMBOL"
export const CLEAR_FETCH_EXPERIENCE_SYMBOL = "CLEAR_FETCH_EXPERIENCE_SYMBOL"

export const ERROR_SUBMIT_FAVICON_NEW_EXPERIENCE = "ERROR_SUBMIT_FAVICON_NEW_EXPERIENCE"
export const REQUEST_SUBMIT_FAVICON_NEW_EXPERIENCE = "REQUEST_SUBMIT_FAVICON_NEW_EXPERIENCE"
export const SUCCESS_SUBMIT_FAVICON_NEW_EXPERIENCE = "SUCCESS_SUBMIT_FAVICON_NEW_EXPERIENCE"
export const CLEAR_FAVICON_NEW_EXPERIENCE = "CLEAR_FAVICON_NEW_EXPERIENCE"

export const ERROR_CHECK_CREATED_HUB = "ERROR_CHECK_CREATED_HUB"
export const REQUEST_CHECK_CREATED_HUB = "REQUEST_CHECK_CREATED_HUB"
export const SUCCESS_CHECK_CREATED_HUB = "SUCCESS_CHECK_CREATED_HUB"

export const ERROR_UPDATE_FAVICON_NEW_EXPERIENCE = "ERROR_UPDATE_FAVICON_NEW_EXPERIENCE"
export const REQUEST_UPDATE_FAVICON_NEW_EXPERIENCE = "REQUEST_UPDATE_FAVICON_NEW_EXPERIENCE"
export const SUCCESS_UPDATE_FAVICON_NEW_EXPERIENCE = "SUCCESS_UPDATE_FAVICON_NEW_EXPERIENCE"

export const CLEAR_FAVICON_UPLOAD_EM = "CLEAR_FAVICON_UPLOAD_EM"

export const CLEAR_CHECK_CREATED_EM = "CLEAR_CHECK_CREATED_EM"

export const RESPONSIVE_TAB = "RESPONSIVE_TAB"

export const COPY_OBJECT_EM = "COPY_OBJECT_EM"
export const OBJECT_TO_PASTE_EM = "OBJECT_TO_PASTE_EM"
export const PASTED_SERVER_OBJECT_EM = "PASTED_SERVER_OBJECT_EM"
export const PASTE_OBJECT_EM = "PASTE_OBJECT_EM"
export const CLEAR_PASTE_OBJECT_EM = "CLEAR_PASTE_OBJECT_EM"
export const CLEAR_COPY_OBJECT_EM = "CLEAR_COPY_OBJECT_EM"
export const CLEAR_SERVER_COPY_PASTE_EM = "CLEAR_SERVER_COPY_PASTE_EM"

export const ERROR_COPY_PASTE_STYLES_EM = "ERROR_COPY_PASTE_STYLES_EM"
export const REQUEST_COPY_PASTE_STYLES_EM = "REQUEST_COPY_PASTE_STYLES_EM"
export const SUCCESS_COPY_PASTE_STYLES_EM = "SUCCESS_COPY_PASTE_STYLES_EM"
export const CLEAR_COPY_PASTE_STYLES_EM = "CLEAR_COPY_PASTE_STYLES_EM"

export const COPY_OBJECT_TEMPLATE_EM = "COPY_OBJECT_TEMPLATE_EM"
export const PASTE_OBJECT_TEMPLATE_EM = "PASTE_OBJECT_TEMPLATE_EM"
export const CLEAR_COPY_OBJECT_TEMPLATE_EM = "CLEAR_COPY_OBJECT_TEMPLATE_EM"

export const ERROR_COPY_PASTE_EM = "ERROR_COPY_PASTE_EM"
export const REQUEST_COPY_PASTE_EM = "REQUEST_COPY_PASTE_EM"
export const SUCCESS_COPY_PASTE_EM = "SUCCESS_COPY_PASTE_EM"
export const CLEAR_COPY_PASTE_EM = "CLEAR_COPY_PASTE_EM"

export const ADD_BULK_EVENT = "ADD_BULK_EVENT"

export const ERROR_FETCHING_FORMULA_INFO = "ERROR_FETCHING_FORMULA_INFO"
export const REQUEST_FORMULA_INFO = "REQUEST_FORMULA_INFO"
export const SUCCESS_FETCH_FORMULA_INFO = "SUCCESS_FETCH_FORMULA_INFO"

export const EVENT_ID_EM = "EVENT_ID_EM"

export const AUTO_SELECT_ON_CREATE_MODE_EM = "AUTO_SELECT_ON_CREATE_MODE_EM"

export const ERROR_IMAGE_UPLOAD = "ERROR_IMAGE_UPLOAD"
export const REQUEST_IMAGE_UPLOAD = "REQUEST_IMAGE_UPLOAD"
export const SUCCESS_IMAGE_UPLOAD = "SUCCESS_IMAGE_UPLOAD"

export const CLEAR_UPLOAD_IMAGE = "CLEAR_UPLOAD_IMAGE"

export const NEW_OBJECT_ORDER = "NEW_OBJECT_ORDER"
export const EMPTY_NEW_OBJECT_ORDER = "EMPTY_NEW_OBJECT_ORDER"

export const ERROR_REQUEST_ALL_ADMINS = "ERROR_REQUEST_ALL_ADMINS"
export const REQUEST_ALL_ADMINS = "REQUEST_ALL_ADMINS"
export const SUCCESS_REQUEST_ALL_ADMINS = "SUCCESS_REQUEST_ALL_ADMINS"

export const ERROR_FETCH_TOURS = "ERROR_FETCH_TOURS"
export const REQUEST_TOURS = "REQUEST_TOURS"
export const SUCCESS_FETCH_TOURS = "SUCCESS_FETCH_TOURS"

export const ERROR_CHANGE_TOURS = "ERROR_CHANGE_TOURS"
export const REQUEST_CHANGE_TOURS = "REQUEST_CHANGE_TOURS"
export const SUCCESS_CHANGE_TOURS = "SUCCESS_CHANGE_TOURS"

export const OPEN_COLOR_PICKER_STYLES = "OPEN_COLOR_PICKER_STYLES"
export const COLOR_PICKER_EXPORT_SELECTION = "COLOR_PICKER_EXPORT_SELECTION"

export const ERROR_SEARCH_ADMINS = "ERROR_SEARCH_ADMINS"
export const REQUEST_SEARCH_ADMINS = "REQUEST_SEARCH_ADMINS"
export const SUCCESS_SEARCH_ADMINS = "SUCCESS_SEARCH_ADMINS"
export const CLEAR_SEARCH_ADMINS = "CLEAR_SEARCH_ADMINS"

export const ERROR_ADD_ADMIN = "ERROR_ADD_ADMIN"
export const REQUEST_ADD_ADMIN = "REQUEST_ADD_ADMIN"
export const SUCCESS_ADD_ADMIN = "SUCCESS_ADD_ADMIN"
export const CLEAR_ADD_ADMIN = "CLEAR_ADD_ADMIN"

export const ERROR_DELETE_ADMIN = "ERROR_DELETE_ADMIN"
export const REQUEST_DELETE_ADMIN = "REQUEST_DELETE_ADMIN"
export const SUCCESS_DELETE_ADMIN = "SUCCESS_DELETE_ADMIN"

export const ERROR_SUBMIT_SOURCE_TO_POST = "ERROR_SUBMIT_SOURCE_TO_POST"
export const REQUEST_SUBMIT_SOURCE_TO_POST = "REQUEST_SUBMIT_SOURCE_TO_POST"
export const SUCCESS_SUBMIT_SOURCE_TO_POST = "SUCCESS_SUBMIT_SOURCE_TO_POST"
export const CLEAR_SOURCE_TO_POST = "CLEAR_SOURCE_TO_POST"

export const QUILL_TOOLBAR_CHANGE = "QUILL_TOOLBAR_CHANGE"

export const ERROR_CHANGE_POSTS_EXPERIENCE_ORDER = "ERROR_CHANGE_POSTS_EXPERIENCE_ORDER"
export const REQUEST_CHANGE_POSTS_EXPERIENCE_ORDER = "REQUEST_CHANGE_POSTS_EXPERIENCE_ORDER"
export const SUCCESS_CHANGE_POSTS_EXPERIENCE_ORDER = "SUCCESS_CHANGE_POSTS_EXPERIENCE_ORDER"
export const CLEAR_POSTS_EXPERIENCE_ORDER = "CLEAR_POSTS_EXPERIENCE_ORDER"

export const OBJECT_SELECTOR_MODE_EM = "OBJECT_SELECTOR_MODE_EM"
export const OBJECT_SELECTOR_HOVER = "OBJECT_SELECTOR_HOVER"

export const EVENT_PILE_SUCCESS_RESPONSES_EM = "EVENT_PILE_SUCCESS_RESPONSES_EM"

export const ERROR_HUB_TO_CLONE_EXPERERIENCE_MANAGER = "ERROR_HUB_TO_CLONE_EXPERERIENCE_MANAGER"
export const REQUEST_HUB_TO_CLONE_EXPERIENCE_MANAGER = "REQUEST_HUB_TO_CLONE_EXPERIENCE_MANAGER"
export const SUCCESS_HUB_TO_CLONE_EXPERIENCE_MANAGER = "SUCCESS_HUB_TO_CLONE_EXPERIENCE_MANAGER"

export const ERROR_OG_IMAGE_UPLOAD = "ERROR_OG_IMAGE_UPLOAD"
export const REQUEST_OG_IMAGE_UPLOAD = "REQUEST_OG_IMAGE_UPLOAD"
export const SUCCESS_OG_IMAGE_UPLOAD = "SUCCESS_OG_IMAGE_UPLOAD"
export const CLEAR_OG_IMAGE_UPLOAD = "CLEAR_OG_IMAGE_UPLOAD"

export const ERROR_LAYOUTS_EXPERIENCE_MANAGER = "ERROR_LAYOUTS_EXPERIENCE_MANAGER"
export const REQUEST_LAYOUTS_EXPERIENCE_MANAGER = "REQUEST_LAYOUTS_EXPERIENCE_MANAGER"
export const SUCCESS_LAYOUTS_EXPERIENCE_MANAGER = "SUCCESS_LAYOUTS_EXPERIENCE_MANAGER"
export const CLEAR_LAYOUTS_EXPERIENCE_MANAGER = "CLEAR_LAYOUTS_EXPERIENCE_MANAGER"

export const ERROR_SELECTED_LAYOUT_EXPERIENCE_MANAGER = "ERROR_SELECTED_LAYOUT_EXPERIENCE_MANAGER"
export const REQUEST_SELECTED_LAYOUT_EXPERIENCE_MANAGER = "REQUEST_SELECTED_LAYOUT_EXPERIENCE_MANAGER"
export const SUCCESS_SELECTED_LAYOUT_EXPERIENCE_MANAGER = "SUCCESS_SELECTED_LAYOUT_EXPERIENCE_MANAGER"
export const CLEAR_SELECTED_LAYOUT_EXPERIENCE_MANAGER = "CLEAR_SELECTED_LAYOUT_EXPERIENCE_MANAGER"

export const SAVED_HUB_FOR_PREVIEW = "SAVED_HUB_FOR_PREVIEW"
export const CLEAR_SAVED_HUB_FOR_PREVIEW = "CLEAR_SAVED_HUB_FOR_PREVIEW"

export const WARNING_STATE_EM = "WARNING_STATE_EM"

export const ERROR_SET_CONTROLLER_STATE = "ERROR_SET_CONTROLLER_STATE"
export const REQUEST_SET_CONTROLLER_STATE = "REQUEST_SET_CONTROLLER_STATE"
export const SUCCESS_SET_CONTROLLER_STATE = "SUCCESS_SET_CONTROLLER_STATE"

export const ERROR_FETCH_UPLOADED_FONTS = "ERROR_FETCH_UPLOADED_FONTS"
export const REQUEST_UPLOADED_FONTS = "REQUEST_UPLOADED_FONTS"
export const SUCCESS_FETCH_UPLOADED_FONTS = "SUCCESS_FETCH_UPLOADED_FONTS"

export const ERROR_FETCH_UPLOADED_FONTS_EM = "ERROR_FETCH_UPLOADED_FONTS_EM"
export const REQUEST_UPLOADED_FONTS_EM = "REQUEST_UPLOADED_FONTS_EM"
export const SUCCESS_FETCH_UPLOADED_FONTS_EM = "SUCCESS_FETCH_UPLOADED_FONTS_EM"

export const SUCCESS_DELETE_FONT = "SUCCESS_DELETE_FONT"
export const REQUEST_DELETE_FONT = "REQUEST_DELETE_FONT"
export const ERROR_DELETE_FONT = "ERROR_DELETE_FONT"
export const CLEAR_DELETE_FONT = "CLEAR_DELETE_FONT"

export const ERROR_FETCH_PAGE_LINKS = "ERROR_FETCH_PAGE_LINKS"
export const REQUEST_FETCH_PAGE_LINKS = "REQUEST_FETCH_PAGE_LINKS"
export const SUCCESS_FETCH_PAGE_LINKS = "SUCCESS_FETCH_PAGE_LINKS"
export const CLEAR_FETCH_PAGE_LINKS = "CLEAR_FETCH_PAGE_LINKS"

export const ERROR_FETCH_MAPPED_FIELDS = "ERROR_FETCH_MAPPED_FIELDS"
export const REQUEST_FETCH_MAPPED_FIELDS = "REQUEST_FETCH_MAPPED_FIELDS"
export const SUCCESS_FETCH_MAPPED_FIELDS = "SUCCESS_FETCH_MAPPED_FIELDS"
export const CLEAR_FETCH_MAPPED_FIELDS = "CLEAR_FETCH_MAPPED_FIELDS"

export const ERROR_FETCH_LINK_MAPPED_FIELDS = "ERROR_FETCH_LINK_MAPPED_FIELDS"
export const REQUEST_FETCH_LINK_MAPPED_FIELDS = "REQUEST_FETCH_LINK_MAPPED_FIELDS"
export const SUCCESS_FETCH_LINK_MAPPED_FIELDS = "SUCCESS_FETCH_LINK_MAPPED_FIELDS"
export const CLEAR_FETCH_LINK_MAPPED_FIELDS = "CLEAR_FETCH_LINK_MAPPED_FIELDS"

/**
 * Domain Actions
 */

export const CLEAR_DOMAIN_ACTIONS = "CLEAR_DOMAIN_ACTIONS"

export const ERROR_REGISTER_DOMAIN_GOOGLE = "ERROR_REGISTER_DOMAIN_GOOGLE"
export const REQUEST_REGISTER_DOMAIN_GOOGLE = "REQUEST_REGISTER_DOMAIN_GOOGLE"
export const SUCCESS_REGISTER_DOMAIN_GOOGLE = "SUCCESS_REGISTER_DOMAIN_GOOGLE"

export const ERROR_VALIDATE_GOOGLE = "ERROR_VALIDATE_GOOGLE"
export const REQUEST_VALIDATE_GOOGLE = "REQUEST_VALIDATE_GOOGLE"
export const SUCCESS_VALIDATE_GOOGLE = "SUCCESS_VALIDATE_GOOGLE"

export const ERROR_SEND_SITEMAP = "ERROR_SEND_SITEMAP"
export const REQUEST_SEND_SITEMAP = "REQUEST_SEND_SITEMAP"
export const SUCCESS_SEND_SITEMAP = "SUCCESS_SEND_SITEMAP"

/**
 * Create Profile
 */

export const ERROR_CREATE_PROFILE = "ERROR_CREATE_PROFILE"
export const REQUEST_CREATE_PROFILE = "REQUEST_CREATE_PROFILE"
export const SUCCESS_CREATE_PROFILE = "SUCCESS_CREATE_PROFILE"
export const CLEAR_CREATE_PROFILE = "CLEAR_CREATE_PROFILE"

export const ERROR_CREATE_PROFILE_IMAGE = "ERROR_CREATE_PROFILE_IMAGE"
export const REQUEST_CREATE_PROFILE_IMAGE = "REQUEST_CREATE_PROFILE_IMAGE"
export const SUCCESS_CREATE_PROFILE_IMAGE = "SUCCESS_CREATE_PROFILE_IMAGE"

export const ERROR_UPDATE_PROFILE = "ERROR_UPDATE_PROFILE"
export const REQUEST_UPDATE_PROFILE = "REQUEST_UPDATE_PROFILE"
export const SUCCESS_UPDATE_PROFILE = "SUCCESS_UPDATE_PROFILE"
export const CLEAR_UPDATE_PROFILE = "CLEAR_UPDATE_PROFILE"

/**
 * Formula Lab
 */

export const ADD_QUERY_GROUP = "ADD_QUERY_GROUP"
export const REMOVE_QUERY_GROUP = "REMOVE_QUERY_GROUP"
export const EDIT_QUERY_GROUP = "EDIT_QUERY_GROUP"
export const CLEAN_QUERY_GROUP = "CLEAN_QUERY_GROUP"
export const ADD_BULK_QUERY_GROUP = "ADD_BULK_QUERY_GROUP"

export const SELECTED_REDUX_GROUP = "SELECTED_REDUX_GROUP"

export const COLLAPSE_STATE = "COLLAPSE_STATE"

/**
 * Marketplace
 */

// templates management
export const REQUEST_CREATE_PAGE_TEMPLATE = "REQUEST_CREATE_PAGE_TEMPLATE"
export const SUCCESS_CREATE_PAGE_TEMPLATE = "SUCCESS_CREATE_PAGE_TEMPLATE"
export const ERROR_CREATE_PAGE_TEMPLATE = "ERROR_CREATE_PAGE_TEMPLATE"

export const REQUEST_UPDATE_PAGE_TEMPLATE = "REQUEST_UPDATE_PAGE_TEMPLATE"
export const SUCCESS_UPDATE_PAGE_TEMPLATE = "SUCCESS_UPDATE_PAGE_TEMPLATE"
export const ERROR_UPDATE_PAGE_TEMPLATE = "ERROR_UPDATE_PAGE_TEMPLATE"

export const REQUEST_DELETE_PAGE_TEMPLATE = "REQUEST_DELETE_PAGE_TEMPLATE"
export const SUCCESS_DELETE_PAGE_TEMPLATE = "SUCCESS_DELETE_PAGE_TEMPLATE"
export const ERROR_DELETE_PAGE_TEMPLATE = "ERROR_DELETE_PAGE_TEMPLATE"

export const MODAL_TEMPLATE_ACTIONS = "MODAL_TEMPLATE_ACTIONS"

export const REQUEST_PING_PAGE_TEMPLATE = "REQUEST_PING_PAGE_TEMPLATE"
export const SUCCESS_PING_PAGE_TEMPLATE = "SUCCESS_PING_PAGE_TEMPLATE"
export const ERROR_PING_PAGE_TEMPLATE = "ERROR_PING_PAGE_TEMPLATE"

export const CLEAR_PING_PAGE_TEMPLATE = "CLEAR_PING_PAGE_TEMPLATE"

export const REQUEST_FETCH_PAGE_TEMPLATE = "REQUEST_FETCH_PAGE_TEMPLATE"
export const RECEIVE_FETCH_PAGE_TEMPLATE = "RECEIVE_FETCH_PAGE_TEMPLATE"
export const ERROR_FETCH_PAGE_TEMPLATE = "ERROR_FETCH_PAGE_TEMPLATE"

export const REQUEST_FETCH_OWNED_PAGE_TEMPLATE_LIST = "REQUEST_FETCH_OWNED_PAGE_TEMPLATE_LIST"
export const RECEIVE_FETCH_OWNED_PAGE_TEMPLATE_LIST = "RECEIVE_FETCH_OWNED_PAGE_TEMPLATE_LIST"
export const ERROR_FETCH_OWNED_PAGE_TEMPLATE_LIST = "ERROR_FETCH_OWNED_PAGE_TEMPLATE_LIST"

export const REQUEST_FETCH_INPUT_OWN_PAGE_TEMPLATE_LIST = "REQUEST_FETCH_INPUT_OWN_PAGE_TEMPLATE_LIST"
export const RECEIVE_FETCH_INPUT_OWN_PAGE_TEMPLATE_LIST = "RECEIVE_FETCH_INPUT_OWN_PAGE_TEMPLATE_LIST"
export const ERROR_FETCH_INPUT_OWN_PAGE_TEMPLATE_LIST = "ERROR_FETCH_INPUT_OWN_PAGE_TEMPLATE_LIST"

export const REQUEST_REPLACE_PAGE_TEMPLATE = "REQUEST_REPLACE_PAGE_TEMPLATE"
export const RECEIVE_REPLACE_PAGE_TEMPLATE = "RECEIVE_REPLACE_PAGE_TEMPLATE"
export const ERROR_REPLACE_PAGE_TEMPLATE = "ERROR_REPLACE_PAGE_TEMPLATE"

export const CLEAR_REPLACE_PAGE_TEMPLATE = "CLEAR_REPLACE_PAGE_TEMPLATE"

// templates usage
export const REQUEST_ADD_PAGE_TEMPLATE = "REQUEST_ADD_PAGE_TEMPLATE"
export const SUCCESS_ADD_PAGE_TEMPLATE = "SUCCESS_ADD_PAGE_TEMPLATE"
export const ERROR_ADD_PAGE_TEMPLATE = "ERROR_ADD_PAGE_TEMPLATE"
export const CLEAR_ADD_PAGE_TEMPLATE = "CLEAR_ADD_PAGE_TEMPLATE"

export const REQUEST_PURCHASE_PAGE_TEMPLATE = "REQUEST_PURCHASE_PAGE_TEMPLATE"
export const SUCCESS_PURCHASE_PAGE_TEMPLATE = "SUCCESS_PURCHASE_PAGE_TEMPLATE"
export const ERROR_PURCHASE_PAGE_TEMPLATE = "ERROR_PURCHASE_PAGE_TEMPLATE"

export const REQUEST_REMOVE_PAGE_TEMPLATE = "REQUEST_REMOVE_PAGE_TEMPLATE"
export const SUCCESS_REMOVE_PAGE_TEMPLATE = "SUCCESS_REMOVE_PAGE_TEMPLATE"
export const ERROR_REMOVE_PAGE_TEMPLATE = "ERROR_REMOVE_PAGE_TEMPLATE"

export const REQUEST_CREATE_PAGE_FROM_TEMPLATE = "REQUEST_CREATE_PAGE_FROM_TEMPLATE"
export const SUCCESS_CREATE_PAGE_FROM_TEMPLATE = "SUCCESS_CREATE_PAGE_FROM_TEMPLATE"
export const ERROR_CREATE_PAGE_FROM_TEMPLATE = "ERROR_CREATE_PAGE_FROM_TEMPLATE"

export const REQUEST_FETCH_ACQUIRED_PAGE_TEMPLATE_LIST = "REQUEST_FETCH_ACQUIRED_PAGE_TEMPLATE_LIST"
export const RECEIVE_FETCH_ACQUIRED_PAGE_TEMPLATE_LIST = "RECEIVE_FETCH_ACQUIRED_PAGE_TEMPLATE_LIST"
export const ERROR_FETCH_ACQUIRED_PAGE_TEMPLATE_LIST = "ERROR_FETCH_ACQUIRED_PAGE_TEMPLATE_LIST"

export const REQUEST_FETCH_INPUT_ACQUIRED_PAGE_TEMPLATE_LIST = "REQUEST_FETCH_INPUT_ACQUIRED_PAGE_TEMPLATE_LIST"
export const RECEIVE_FETCH_INPUT_ACQUIRED_PAGE_TEMPLATE_LIST = "RECEIVE_FETCH_INPUT_ACQUIRED_PAGE_TEMPLATE_LIST"
export const ERROR_FETCH_INPUT_ACQUIRED_PAGE_TEMPLATE_LIST = "ERROR_FETCH_INPUT_ACQUIRED_PAGE_TEMPLATE_LIST"

export const REQUEST_FETCH_PAGE_TEMPLATE_LIST = "REQUEST_FETCH_PAGE_TEMPLATE_LIST"
export const RECEIVE_FETCH_PAGE_TEMPLATE_LIST = "RECEIVE_FETCH_PAGE_TEMPLATE_LIST"
export const ERROR_FETCH_PAGE_TEMPLATE_LIST = "ERROR_FETCH_PAGE_TEMPLATE_LIST"

export const REQUEST_FETCH_PAGE_TEMPLATE_CATEGORIES = "REQUEST_FETCH_PAGE_TEMPLATE_CATEGORIES"
export const RECEIVE_FETCH_PAGE_TEMPLATE_CATEGORIES = "RECEIVE_FETCH_PAGE_TEMPLATE_CATEGORIES"
export const ERROR_FETCH_PAGE_TEMPLATE_CATEGORIES = "ERROR_FETCH_PAGE_TEMPLATE_CATEGORIES"

export const SET_TEMPLATE_LIST_FILTERS = "SET_TEMPLATE_LIST_FILTERS"

// Publish
export const ERROR_PUBLISH_PAGE = "ERROR_PUBLISH_PAGE"
export const REQUEST_PUBLISH_PAGE = "REQUEST_PUBLISH_PAGE"
export const SUCCESS_PUBLISH_PAGE = "SUCCESS_PUBLISH_PAGE"
export const CLEAR_PUBLISH_PAGE = "CLEAR_PUBLISH_PAGE"
// Unpublish
export const ERROR_UNPUBLISH_PAGE = "ERROR_UNPUBLISH_PAGE"
export const REQUEST_UNPUBLISH_PAGE = "REQUEST_UNPUBLISH_PAGE"
export const SUCCESS_UNPUBLISH_PAGE = "SUCCESS_UNPUBLISH_PAGE"
export const CLEAR_UNPUBLISH_PAGE = "CLEAR_UNPUBLISH_PAGE"

export const ERROR_PUBLISH_SITE_PAGES = "ERROR_PUBLISH_SITE_PAGES"
export const REQUEST_PUBLISH_SITE_PAGES = "REQUEST_PUBLISH_SITE_PAGES"
export const SUCCESS_PUBLISH_SITE_PAGES = "SUCCESS_PUBLISH_SITE_PAGES"
export const CLEAR_PUBLISH_SITE_PAGES = "CLEAR_PUBLISH_SITE_PAGES"

export const ERROR_PUBLISH_SITE_PAGES_STATUS = "ERROR_PUBLISH_SITE_PAGES_STATUS"
export const REQUEST_PUBLISH_SITE_PAGES_STATUS = "REQUEST_PUBLISH_SITE_PAGES_STATUS"
export const SUCCESS_PUBLISH_SITE_PAGES_STATUS = "SUCCESS_PUBLISH_SITE_PAGES_STATUS"
export const CLEAR_PUBLISH_SITE_PAGES_STATUS = "CLEAR_PUBLISH_SITE_PAGES_STATUS"

export const ERROR_PUBLISH_OBJECT_TEMPLATE = "ERROR_PUBLISH_OBJECT_TEMPLATE"
export const REQUEST_PUBLISH_OBJECT_TEMPLATE = "REQUEST_PUBLISH_OBJECT_TEMPLATE"
export const SUCCESS_PUBLISH_OBJECT_TEMPLATE = "SUCCESS_PUBLISH_OBJECT_TEMPLATE"
export const CLEAR_PUBLISH_OBJECT_TEMPLATE = "CLEAR_PUBLISH_OBJECT_TEMPLATE"

export const ERROR_UNPUBLISH_OBJECT_TEMPLATE = "ERROR_UNPUBLISH_OBJECT_TEMPLATE"
export const REQUEST_UNPUBLISH_OBJECT_TEMPLATE = "REQUEST_UNPUBLISH_OBJECT_TEMPLATE"
export const SUCCESS_UNPUBLISH_OBJECT_TEMPLATE = "SUCCESS_UNPUBLISH_OBJECT_TEMPLATE"
export const CLEAR_UNPUBLISH_OBJECT_TEMPLATE = "CLEAR_UNPUBLISH_OBJECT_TEMPLATE"

// Global Styles v2
export const GLOBAL_STYLE_VIEW = "GLOBAL_STYLE_VIEW"

export const REQUEST_FETCH_GLOBAL_STYLE_LIST = "REQUEST_FETCH_GLOBAL_STYLE_LIST"
export const SUCCESS_FETCH_GLOBAL_STYLE_LIST = "SUCCESS_FETCH_GLOBAL_STYLE_LIST"
export const ERROR_FETCH_GLOBAL_STYLE_LIST = "ERROR_FETCH_GLOBAL_STYLE_LIST"
export const CLEAR_FETCH_GLOBAL_STYLE_LIST = "CLEAR_FETCH_GLOBAL_STYLE_LIST"

export const UPDATE_GLOBAL_STYLE_LIST = "UPDATE_GLOBAL_STYLE_LIST"

export const REQUEST_CREATE_GLOBAL_STYLE_V2 = "REQUEST_CREATE_GLOBAL_STYLE_V2"
export const SUCCESS_CREATE_GLOBAL_STYLE_V2 = "SUCCESS_CREATE_GLOBAL_STYLE_V2"
export const ERROR_CREATE_GLOBAL_STYLE_V2 = "ERROR_CREATE_GLOBAL_STYLE_V2"
export const CLEAR_CREATE_GLOBAL_STYLE_V2 = "CLEAR_CREATE_GLOBAL_STYLE_V2"

export const REQUEST_UPDATE_GLOBAL_STYLE_V2 = "REQUEST_UPDATE_GLOBAL_STYLE_V2"
export const SUCCESS_UPDATE_GLOBAL_STYLE_V2 = "SUCCESS_UPDATE_GLOBAL_STYLE_V2"
export const ERROR_UPDATE_GLOBAL_STYLE_V2 = "ERROR_UPDATE_GLOBAL_STYLE_V2"
export const CLEAR_UPDATE_GLOBAL_STYLE_V2 = "CLEAR_UPDATE_GLOBAL_STYLE_V2"

export const REQUEST_ATTACH_GLOBAL_STYLE_V2 = "REQUEST_ATTACH_GLOBAL_STYLE_V2"
export const SUCCESS_ATTACH_GLOBAL_STYLE_V2 = "SUCCESS_ATTACH_GLOBAL_STYLE_V2"
export const ERROR_ATTACH_GLOBAL_STYLE_V2 = "ERROR_ATTACH_GLOBAL_STYLE_V2"
export const CLEAR_ATTACH_GLOBAL_STYLE_V2 = "CLEAR_ATTACH_GLOBAL_STYLE_V2"

export const REQUEST_DETACH_GLOBAL_STYLE_V2 = "REQUEST_DETACH_GLOBAL_STYLE_V2"
export const SUCCESS_DETACH_GLOBAL_STYLE_V2 = "SUCCESS_DETACH_GLOBAL_STYLE_V2"
export const ERROR_DETACH_GLOBAL_STYLE_V2 = "ERROR_DETACH_GLOBAL_STYLE_V2"
export const CLEAR_DETACH_GLOBAL_STYLE_V2 = "CLEAR_DETACH_GLOBAL_STYLE_V2"

export const SAVE_ATTACHED_GLOBAL_STYLE = "SAVE_ATTACHED_GLOBAL_STYLE"
export const CLEAR_SAVED_ATTACHED_GLOBAL_STYLE = "CLEAR_SAVED_ATTACHED_GLOBAL_STYLE"
export const SET_GLOBAL_STYLE_BEHAVIOUR = "SET_GLOBAL_STYLE_BEHAVIOUR"

export const EDIT_LIST_SINGLE_GLOBAL_STYLE = "EDIT_LIST_SINGLE_GLOBAL_STYLE"

export const SUCCESS_FETCH_GLOBAL_STYLE_TEMPLATE_USAGE_LIST = "SUCCESS_FETCH_GLOBAL_STYLE_TEMPLATE_USAGE_LIST"
export const REQUEST_GLOBAL_STYLE_TEMPLATE_USAGE_LIST = "REQUEST_GLOBAL_STYLE_TEMPLATE_USAGE_LIST"
export const ERROR_FETCH_GLOBAL_STYLE_TEMPLATE_USAGE_LIST = "ERROR_FETCH_GLOBAL_STYLE_TEMPLATE_USAGE_LIST"
export const CLEAN_GLOBAL_STYLE_TEMPLATE_USAGE_LIST = "CLEAN_GLOBAL_STYLE_TEMPLATE_USAGE_LIST"

export const SUCCESS_FETCH_GLOBAL_STYLE_PAGE_USAGE_LIST = "SUCCESS_FETCH_GLOBAL_STYLE_PAGE_USAGE_LIST"
export const REQUEST_GLOBAL_STYLE_PAGE_USAGE_LIST = "REQUEST_GLOBAL_STYLE_PAGE_USAGE_LIST"
export const ERROR_FETCH_GLOBAL_STYLE_PAGE_USAGE_LIST = "ERROR_FETCH_GLOBAL_STYLE_PAGE_USAGE_LIST"
export const CLEAN_GLOBAL_STYLE_PAGE_USAGE_LIST = "CLEAN_GLOBAL_STYLE_PAGE_USAGE_LIST"

export const SUCCESS_FETCH_GLOBAL_STYLE_SYMBOL_USAGE_LIST = "SUCCESS_FETCH_GLOBAL_STYLE_SYMBOL_USAGE_LIST"
export const REQUEST_GLOBAL_STYLE_SYMBOL_USAGE_LIST = "REQUEST_GLOBAL_STYLE_SYMBOL_USAGE_LIST"
export const ERROR_FETCH_GLOBAL_STYLE_SYMBOL_USAGE_LIST = "ERROR_FETCH_GLOBAL_STYLE_SYMBOL_USAGE_LIST"
export const CLEAN_GLOBAL_STYLE_SYMBOL_USAGE_LIST = "CLEAN_GLOBAL_STYLE_SYMBOL_USAGE_LIST"

export const SUCCESS_FETCH_HANDLE_GLOBAL_STYLE_COMPATIBILITY = "SUCCESS_FETCH_HANDLE_GLOBAL_STYLE_COMPATIBILITY"
export const REQUEST_HANDLE_GLOBAL_STYLE_COMPATIBILITY = "REQUEST_HANDLE_GLOBAL_STYLE_COMPATIBILITY"
export const ERROR_FETCH_HANDLE_GLOBAL_STYLE_COMPATIBILITY = "ERROR_FETCH_HANDLE_GLOBAL_STYLE_COMPATIBILITY"
export const CLEAN_HANDLE_GLOBAL_STYLE_COMPATIBILITY = "CLEAN_HANDLE_GLOBAL_STYLE_COMPATIBILITY"

export const STYLESHEET_VIEW = "STYLESHEET_VIEW"

export const REQUEST_FETCH_STYLESHEET_LIST = "REQUEST_FETCH_STYLESHEET_LIST"
export const SUCCESS_FETCH_STYLESHEET_LIST = "SUCCESS_FETCH_STYLESHEET_LIST"
export const ERROR_FETCH_STYLESHEET_LIST = "ERROR_FETCH_STYLESHEET_LIST"
export const CLEAR_FETCH_STYLESHEET_LIST = "CLEAR_FETCH_STYLESHEET_LIST"

export const REQUEST_FETCH_STYLESHEET_INFO = "REQUEST_FETCH_STYLESHEET_INFO"
export const SUCCESS_FETCH_STYLESHEET_INFO = "SUCCESS_FETCH_STYLESHEET_INFO"
export const ERROR_FETCH_STYLESHEET_INFO = "ERROR_FETCH_STYLESHEET_INFO"
export const CLEAR_FETCH_STYLESHEET_INFO = "CLEAR_FETCH_STYLESHEET_INFO"

export const EDIT_STYLESHEET_INFO = "EDIT_STYLESHEET_INFO"

export const REQUEST_ATTACH_STYLESHEET_LABEL = "REQUEST_ATTACH_STYLESHEET_LABEL"
export const SUCCESS_ATTACH_STYLESHEET_LABEL = "SUCCESS_ATTACH_STYLESHEET_LABEL"
export const ERROR_ATTACH_STYLESHEET_LABEL = "ERROR_ATTACH_STYLESHEET_LABEL"
export const CLEAR_ATTACH_STYLESHEET_LABEL = "CLEAR_ATTACH_STYLESHEET_LABEL"

export const REQUEST_DETTACH_STYLESHEET_LABEL = "REQUEST_DETTACH_STYLESHEET_LABEL"
export const SUCCESS_DETTACH_STYLESHEET_LABEL = "SUCCESS_DETTACH_STYLESHEET_LABEL"
export const ERROR_DETTACH_STYLESHEET_LABEL = "ERROR_DETTACH_STYLESHEET_LABEL"
export const CLEAR_DETTACH_STYLESHEET_LABEL = "CLEAR_DETTACH_STYLESHEET_LABEL"

export const REQUEST_UPDATE_STYLESHEET_LABEL = "REQUEST_UPDATE_STYLESHEET_LABEL"
export const SUCCESS_UPDATE_STYLESHEET_LABEL = "SUCCESS_UPDATE_STYLESHEET_LABEL"
export const ERROR_UPDATE_STYLESHEET_LABEL = "ERROR_UPDATE_STYLESHEET_LABEL"
export const CLEAR_UPDATE_STYLESHEET_LABEL = "CLEAR_UPDATE_STYLESHEET_LABEL"

export const REQUEST_ATTACH_STYLESHEET_LABEL_CONTENT = "REQUEST_ATTACH_STYLESHEET_LABEL_CONTENT"
export const SUCCESS_ATTACH_STYLESHEET_LABEL_CONTENT = "SUCCESS_ATTACH_STYLESHEET_LABEL_CONTENT"
export const ERROR_ATTACH_STYLESHEET_LABEL_CONTENT = "ERROR_ATTACH_STYLESHEET_LABEL_CONTENT"
export const CLEAR_ATTACH_STYLESHEET_LABEL_CONTENT = "CLEAR_ATTACH_STYLESHEET_LABEL_CONTENT"

export const REQUEST_DETTACH_STYLESHEET_LABEL_CONTENT = "REQUEST_DETTACH_STYLESHEET_LABEL_CONTENT"
export const SUCCESS_DETTACH_STYLESHEET_LABEL_CONTENT = "SUCCESS_DETTACH_STYLESHEET_LABEL_CONTENT"
export const ERROR_DETTACH_STYLESHEET_LABEL_CONTENT = "ERROR_DETTACH_STYLESHEET_LABEL_CONTENT"
export const CLEAR_DETTACH_STYLESHEET_LABEL_CONTENT = "CLEAR_DETTACH_STYLESHEET_LABEL_CONTENT"

export const REQUEST_ATTACH_GS_STYLESHEET_LABEL = "REQUEST_ATTACH_GS_STYLESHEET_LABEL"
export const SUCCESS_ATTACH_GS_STYLESHEET_LABEL = "SUCCESS_ATTACH_GS_STYLESHEET_LABEL"
export const ERROR_ATTACH_GS_STYLESHEET_LABEL = "ERROR_ATTACH_GS_STYLESHEET_LABEL"
export const CLEAR_ATTACH_GS_STYLESHEET_LABEL = "CLEAR_ATTACH_GS_STYLESHEET_LABEL"

export const REQUEST_DETTACH_GS_STYLESHEET_LABEL = "REQUEST_DETTACH_GS_STYLESHEET_LABEL"
export const SUCCESS_DETTACH_GS_STYLESHEET_LABEL = "SUCCESS_DETTACH_GS_STYLESHEET_LABEL"
export const ERROR_DETTACH_GS_STYLESHEET_LABEL = "ERROR_DETTACH_GS_STYLESHEET_LABEL"
export const CLEAR_DETTACH_GS_STYLESHEET_LABEL = "CLEAR_DETTACH_GS_STYLESHEET_LABEL"

export const FOUNDATION_STYLE_VIEW = "FOUNDATION_STYLE_VIEW"

export const REQUEST_FETCH_FOUNDATION_STYLE_LIST = "REQUEST_FETCH_FOUNDATION_STYLE_LIST"
export const SUCCESS_FETCH_FOUNDATION_STYLE_LIST = "SUCCESS_FETCH_FOUNDATION_STYLE_LIST"
export const ERROR_FETCH_FOUNDATION_STYLE_LIST = "ERROR_FETCH_FOUNDATION_STYLE_LIST"
export const CLEAR_FETCH_FOUNDATION_STYLE_LIST = "CLEAR_FETCH_FOUNDATION_STYLE_LIST"

export const UPDATE_FOUNDATION_STYLE_LIST = "UPDATE_FOUNDATION_STYLE_LIST"

export const REQUEST_CREATE_FOUNDATION_STYLE = "REQUEST_CREATE_FOUNDATION_STYLE"
export const SUCCESS_CREATE_FOUNDATION_STYLE = "SUCCESS_CREATE_FOUNDATION_STYLE"
export const ERROR_CREATE_FOUNDATION_STYLE = "ERROR_CREATE_FOUNDATION_STYLE"
export const CLEAR_CREATE_FOUNDATION_STYLE = "CLEAR_CREATE_FOUNDATION_STYLE"

export const REQUEST_UPDATE_FOUNDATION_STYLE_V2 = "REQUEST_UPDATE_FOUNDATION_STYLE_V2"
export const SUCCESS_UPDATE_FOUNDATION_STYLE_V2 = "SUCCESS_UPDATE_FOUNDATION_STYLE_V2"
export const ERROR_UPDATE_FOUNDATION_STYLE_V2 = "ERROR_UPDATE_FOUNDATION_STYLE_V2"
export const CLEAR_UPDATE_FOUNDATION_STYLE_V2 = "CLEAR_UPDATE_FOUNDATION_STYLE_V2"

export const REQUEST_ATTACH_FOUNDATION_STYLE_V2 = "REQUEST_ATTACH_FOUNDATION_STYLE_V2"
export const SUCCESS_ATTACH_FOUNDATION_STYLE_V2 = "SUCCESS_ATTACH_FOUNDATION_STYLE_V2"
export const ERROR_ATTACH_FOUNDATION_STYLE_V2 = "ERROR_ATTACH_FOUNDATION_STYLE_V2"
export const CLEAR_ATTACH_FOUNDATION_STYLE_V2 = "CLEAR_ATTACH_FOUNDATION_STYLE_V2"

export const REQUEST_DETACH_FOUNDATION_STYLE_V2 = "REQUEST_DETACH_FOUNDATION_STYLE_V2"
export const SUCCESS_DETACH_FOUNDATION_STYLE_V2 = "SUCCESS_DETACH_FOUNDATION_STYLE_V2"
export const ERROR_DETACH_FOUNDATION_STYLE_V2 = "ERROR_DETACH_FOUNDATION_STYLE_V2"
export const CLEAR_DETACH_FOUNDATION_STYLE_V2 = "CLEAR_DETACH_FOUNDATION_STYLE_V2"

export const SAVE_ATTACHED_FOUNDATION_STYLE = "SAVE_ATTACHED_FOUNDATION_STYLE"
export const CLEAR_SAVED_ATTACHED_FOUNDATION_STYLE = "CLEAR_SAVED_ATTACHED_FOUNDATION_STYLE"
export const SET_FOUNDATION_STYLE_BEHAVIOUR = "SET_FOUNDATION_STYLE_BEHAVIOUR"

export const EDIT_LIST_SINGLE_FOUNDATION_STYLE = "EDIT_LIST_SINGLE_FOUNDATION_STYLE"

export const SUCCESS_FETCH_FOUNDATION_STYLE_TEMPLATE_USAGE_LIST = "SUCCESS_FETCH_FOUNDATION_STYLE_TEMPLATE_USAGE_LIST"
export const REQUEST_FOUNDATION_STYLE_TEMPLATE_USAGE_LIST = "REQUEST_FOUNDATION_STYLE_TEMPLATE_USAGE_LIST"
export const ERROR_FETCH_FOUNDATION_STYLE_TEMPLATE_USAGE_LIST = "ERROR_FETCH_FOUNDATION_STYLE_TEMPLATE_USAGE_LIST"
export const CLEAN_FOUNDATION_STYLE_TEMPLATE_USAGE_LIST = "CLEAN_FOUNDATION_STYLE_TEMPLATE_USAGE_LIST"

export const SUCCESS_FETCH_FOUNDATION_STYLE_PAGE_USAGE_LIST = "SUCCESS_FETCH_FOUNDATION_STYLE_PAGE_USAGE_LIST"
export const REQUEST_FOUNDATION_STYLE_PAGE_USAGE_LIST = "REQUEST_FOUNDATION_STYLE_PAGE_USAGE_LIST"
export const ERROR_FETCH_FOUNDATION_STYLE_PAGE_USAGE_LIST = "ERROR_FETCH_FOUNDATION_STYLE_PAGE_USAGE_LIST"
export const CLEAN_FOUNDATION_STYLE_PAGE_USAGE_LIST = "CLEAN_FOUNDATION_STYLE_PAGE_USAGE_LIST"

export const SUCCESS_FETCH_FOUNDATION_STYLE_SYMBOL_USAGE_LIST = "SUCCESS_FETCH_FOUNDATION_STYLE_SYMBOL_USAGE_LIST"
export const REQUEST_FOUNDATION_STYLE_SYMBOL_USAGE_LIST = "REQUEST_FOUNDATION_STYLE_SYMBOL_USAGE_LIST"
export const ERROR_FETCH_FOUNDATION_STYLE_SYMBOL_USAGE_LIST = "ERROR_FETCH_FOUNDATION_STYLE_SYMBOL_USAGE_LIST"
export const CLEAN_FOUNDATION_STYLE_SYMBOL_USAGE_LIST = "CLEAN_FOUNDATION_STYLE_SYMBOL_USAGE_LIST"

export const SUCCESS_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS = "SUCCESS_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS"
export const REQUEST_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS = "REQUEST_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS"
export const ERROR_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS = "ERROR_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS"
export const CLEAR_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS = "CLEAR_FETCH_CUSTOM_ATTRIBUTES_MAPPED_FIELDS"

export const SUCCESS_SET_OBJECT_MAPPED_FIELD = "SUCCESS_SET_OBJECT_MAPPED_FIELD"
export const SUCCESS_SET_OBJECT_CPSRC = "SUCCESS_SET_OBJECT_CPSRC"
export const REQUEST_SET_OBJECT_MAPPED_FIELD = "REQUEST_SET_OBJECT_MAPPED_FIELD"
export const REQUEST_SET_OBJECT_CPSRC = "REQUEST_SET_OBJECT_CPSRC"
export const ERROR_SET_OBJECT_MAPPED_FIELD = "ERROR_SET_OBJECT_MAPPED_FIELD"
export const ERROR_SET_OBJECT_CPSRC = "ERROR_SET_OBJECT_CPSRC"
export const CLEAR_SET_OBJECT_MAPPED_FIELD = "CLEAR_SET_OBJECT_MAPPED_FIELD"
export const CLEAR_SET_OBJECT_CPSRC = "CLEAR_SET_OBJECT_CPSRC"

export const SUCCESS_UNSET_OBJECT_MAPPED_FIELD = "SUCCESS_UNSET_OBJECT_MAPPED_FIELD"
export const SUCCESS_UNSET_OBJECT_CPSRC = "SUCCESS_UNSET_OBJECT_CPSRC"
export const REQUEST_UNSET_OBJECT_MAPPED_FIELD = "REQUEST_UNSET_OBJECT_MAPPED_FIELD"
export const REQUEST_UNSET_OBJECT_CPSRC = "REQUEST_UNSET_OBJECT_CPSRC"
export const ERROR_UNSET_OBJECT_MAPPED_FIELD = "ERROR_UNSET_OBJECT_MAPPED_FIELD"
export const ERROR_UNSET_OBJECT_CPSRC = "ERROR_UNSET_OBJECT_CPSRC"
export const CLEAR_UNSET_OBJECT_MAPPED_FIELD = "CLEAR_UNSET_OBJECT_MAPPED_FIELD"
export const CLEAR_UNSET_OBJECT_CPSRC = "CLEAR_UNSET_OBJECT_CPSRC"

export const SET_EXPERIENCE_MANAGER_EDITOR_TYPE = "SET_EXPERIENCE_MANAGER_EDITOR_TYPE"

/**
 * PageTreeFinder
 */
export const SELECTED_SYMBOL_INSTANCE = "SELECTED_SYMBOL_INSTANCE"

export const PAGE_TREE_FINDER_CONTROLLER = "PAGE_TREE_FINDER_CONTROLLER"

export const PAGE_TREE_EDIT_SYMBOL_STATE = "PAGE_TREE_EDIT_SYMBOL_STATE"
export const HANDLE_SYMBOL_TREE = "HANDLE_SYMBOL_TREE"
export const CLEAR_HANDLE_SYMBOL_TREE = "CLEAR_HANDLE_SYMBOL_TREE"

export const ADD_SYMBOL_OBJECT = "ADD_SYMBOL_OBJECT"
export const CLEAR_ADD_SYMBOL_OBJECT = "CLEAR_ADD_SYMBOL_OBJECT"

export const SYMBOL_CONTENT_TYPE = "SYMBOL_CONTENT_TYPE"

export const RECEIVE_CREATE_SYMBOL_INSTANCE = "RECEIVE_CREATE_SYMBOL_INSTANCE"
export const REQUEST_CREATE_SYMBOL_INSTANCE = "REQUEST_CREATE_SYMBOL_INSTANCE"
export const ERROR_CREATE_SYMBOL_INSTANCE = "ERROR_CREATE_SYMBOL_INSTANCE"
export const CLEAR_CREATE_SYMBOL_INSTANCE = "CLEAR_CREATE_SYMBOL_INSTANCE"

export const RECEIVE_SYMBOLS_LIST = "RECEIVE_SYMBOLS_LIST"
export const REQUEST_SYMBOLS_LIST = "REQUEST_SYMBOLS_LIST"
export const ERROR_SYMBOLS_LIST = "ERROR_SYMBOLS_LIST"

export const RECEIVE_SET_SYMBOL = "RECEIVE_SET_SYMBOL"
export const REQUEST_SET_SYMBOL = "REQUEST_SET_SYMBOL"
export const ERROR_SET_SYMBOL = "ERROR_SET_SYMBOL"

export const RECEIVE_UNSET_SYMBOL = "RECEIVE_UNSET_SYMBOL"
export const REQUEST_UNSET_SYMBOL = "REQUEST_UNSET_SYMBOL"
export const ERROR_UNSET_SYMBOL = "ERROR_UNSET_SYMBOL"
export const CLEAR_UNSET_SYMBOL = "CLEAR_UNSET_SYMBOL"
export const CLEAR_SET_SYMBOL = "CLEAR_SET_SYMBOL"

export const REQUEST_DELETE_SYMBOL = "REQUEST_DELETE_SYMBOL"
export const RECEIVE_DELETE_SYMBOL = "RECEIVE_DELETE_SYMBOL"
export const ERROR_DELETE_SYMBOL = "ERROR_DELETE_SYMBOL"
export const CLEAR_DELETE_SYMBOL = "CLEAR_DELETE_SYMBOL"

export const REQUEST_CHANGE_SYMBOL_LABEL = "REQUEST_CHANGE_SYMBOL_LABEL"
export const RECEIVE_CHANGE_SYMBOL_LABEL = "RECEIVE_CHANGE_SYMBOL_LABEL"
export const ERROR_CHANGE_SYMBOL_LABEL = "ERROR_CHANGE_SYMBOL_LABEL"
export const CLEAR_CHANGE_SYMBOL_LABEL = "CLEAR_CHANGE_SYMBOL_LABEL"

export const REQUEST_SYMBOL_SCREENSHOT = "REQUEST_SYMBOL_SCREENSHOT"
export const RECEIVE_SYMBOL_SCREENSHOT = "RECEIVE_SYMBOL_SCREENSHOT"
export const ERROR_SYMBOL_SCREENSHOT = "ERROR_SYMBOL_SCREENSHOT"
export const CLEAR_SYMBOL_SCREENSHOT = "CLEAR_SYMBOL_SCREENSHOT"

export const ERROR_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER = "ERROR_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER"
export const REQUEST_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER = "REQUEST_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER"
export const SUCCESS_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER = "SUCCESS_UPDATE_INSTANCE_CONTENT_EXPERIENCE_MANAGER"

export const ERROR_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER = "ERROR_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER"
export const REQUEST_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER = "REQUEST_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER"
export const SUCCESS_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER = "SUCCESS_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER"
export const CLEAR_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER = "CLEAR_DELETE_INSTANCE_CONTENT_EXPERIENCE_MANAGER"

export const PAGE_TREE_FINDER_EXPANDED = "PAGE_TREE_FINDER_EXPANDED"

/**
 * FormBuilder
 */
export const REQUEST_FETCH_FORMS_LIST = "REQUEST_FETCH_FORMS_LIST"
export const SUCCESS_FETCH_FORMS_LIST = "SUCCESS_FETCH_FORMS_LIST"
export const ERROR_FETCH_FORMS_LIST = "ERROR_FETCH_FORMS_LIST"
export const CLEAR_FETCH_FORMS_LIST = "CLEAR_FETCH_FORMS_LIST"

export const REQUEST_FETCH_FORM = "REQUEST_FETCH_FORM"
export const SUCCESS_FETCH_FORM = "SUCCESS_FETCH_FORM"
export const ERROR_FETCH_FORM = "ERROR_FETCH_FORM"
export const CLEAR_FETCH_FORM = "CLEAR_FETCH_FORM"

/**
 * FormBuilder v2
 */
export const REQUEST_PROFILE_FORMS_LIST = "REQUEST_PROFILE_FORMS_LIST"
export const SUCCESS_PROFILE_FORMS_LIST = "SUCCESS_PROFILE_FORMS_LIST"
export const ERROR_PROFILE_FORMS_LIST = "ERROR_PROFILE_FORMS_LIST"
export const CLEAR_PROFILE_FORMS_LIST = "CLEAR_PROFILE_FORMS_LIST"

export const REQUEST_PROFILE_FORM = "REQUEST_PROFILE_FORM"
export const SUCCESS_PROFILE_FORM = "SUCCESS_PROFILE_FORM"
export const ERROR_PROFILE_FORM = "ERROR_PROFILE_FORM"
export const CLEAR_PROFILE_FORM = "CLEAR_PROFILE_FORM"

export const REQUEST_PROFILE_FORM_MAPPED_VALUES = "REQUEST_PROFILE_FORM_MAPPED_VALUES"
export const SUCCESS_PROFILE_FORM_MAPPED_VALUES = "SUCCESS_PROFILE_FORM_MAPPED_VALUES"
export const ERROR_PROFILE_FORM_MAPPED_VALUES = "ERROR_PROFILE_FORM_MAPPED_VALUES"
export const CLEAR_PROFILE_FORM_MAPPED_VALUES = "CLEAR_PROFILE_FORM_MAPPED_VALUES"

export const REQUEST_UPDATE_PROFILE_FORM = "REQUEST_UPDATE_PROFILE_FORM"
export const SUCCESS_UPDATE_PROFILE_FORM = "SUCCESS_UPDATE_PROFILE_FORM"
export const ERROR_UPDATE_PROFILE_FORM = "ERROR_UPDATE_PROFILE_FORM"
export const CLEAR_UPDATE_PROFILE_FORM = "CLEAR_UPDATE_PROFILE_FORM"

export const REQUEST_UPDATE_PROFILE_FORM_FIELD = "REQUEST_UPDATE_PROFILE_FORM_FIELD"
export const SUCCESS_UPDATE_PROFILE_FORM_FIELD = "SUCCESS_UPDATE_PROFILE_FORM_FIELD"
export const ERROR_UPDATE_PROFILE_FORM_FIELD = "ERROR_UPDATE_PROFILE_FORM_FIELD"
export const CLEAR_UPDATE_PROFILE_FORM_FIELD = "CLEAR_UPDATE_PROFILE_FORM_FIELD"

export const REQUEST_UPDATE_PROFILE_FORM_SUBMITTER = "REQUEST_UPDATE_PROFILE_FORM_SUBMITTER"
export const SUCCESS_UPDATE_PROFILE_FORM_SUBMITTER = "SUCCESS_UPDATE_PROFILE_FORM_SUBMITTER"
export const ERROR_UPDATE_PROFILE_FORM_SUBMITTER = "ERROR_UPDATE_PROFILE_FORM_SUBMITTER"
export const CLEAR_UPDATE_PROFILE_FORM_SUBMITTER = "CLEAR_UPDATE_PROFILE_FORM_SUBMITTER"

export const REQUEST_ATTACH_PROFILE_FORM = "REQUEST_ATTACH_PROFILE_FORM"
export const SUCCESS_ATTACH_PROFILE_FORM = "SUCCESS_ATTACH_PROFILE_FORM"
export const ERROR_ATTACH_PROFILE_FORM = "ERROR_ATTACH_PROFILE_FORM"
export const CLEAR_ATTACH_PROFILE_FORM = "CLEAR_ATTACH_PROFILE_FORM"

export const REQUEST_DETTACH_PROFILE_FORM = "REQUEST_DETTACH_PROFILE_FORM"
export const SUCCESS_DETTACH_PROFILE_FORM = "SUCCESS_DETTACH_PROFILE_FORM"
export const ERROR_DETTACH_PROFILE_FORM = "ERROR_DETTACH_PROFILE_FORM"
export const CLEAR_DETTACH_PROFILE_FORM = "CLEAR_DETTACH_PROFILE_FORM"

/**
 * Settings Panel
 */
export const ERROR_UPLOAD_FONT = "ERROR_UPLOAD_FONT"
export const REQUEST_UPLOAD_FONT = "REQUEST_UPLOAD_FONT"
export const SUCCESS_UPLOAD_FONT = "SUCCESS_UPLOAD_FONT"
export const CLEAR_UPLOAD_FONT = "CLEAR_UPLOAD_FONT"

/**
 * Public Page Types
 */
export const CHANGE_DATA_SET = "CHANGE_DATA_SET"

export const RECEIVE_DATASET = "RECEIVE_DATASET"
export const ERROR_FETCH_DATASET = "ERROR_FETCH_DATASET"
export const FETCHING_DATASET = "FETCHING_DATASET"

export const RECEIVE_LOADMORE_DATASET = "RECEIVE_LOADMORE_DATASET"
export const ERROR_FETCH_LOADMORE_DATASET = "ERROR_FETCH_LOADMORE_DATASET"
export const FETCHING_LOADMORE_DATASET = "FETCHING_LOADMORE_DATASET"

export const RECEIVE_DATASET_LIST = "RECEIVE_DATASET_LIST"
export const ERROR_FETCH_DATASET_LIST = "ERROR_FETCH_DATASET_LIST"
export const FETCHING_DATASET_LIST = "FETCHING_DATASET_LIST"
export const CLEAN_DATASET_LIST = "CLEAN_DATASET_LIST"

export const RECEIVE_DATASET_FILTERS_LIST = "RECEIVE_DATASET_FILTERS_LIST"
export const ERROR_FETCH_DATASET_FILTERS_LIST = "ERROR_FETCH_DATASET_FILTERS_LIST"
export const FETCHING_DATASET_FILTERS_LIST = "FETCHING_DATASET_FILTERS_LIST"

export const SUCCESS_ADD_DATASET_TO_OBJECT = "SUCCESS_ADD_DATASET_TO_OBJECT"
export const ERROR_ADD_DATASET_TO_OBJECT = "ERROR_ADD_DATASET_TO_OBJECT"
export const REQUEST_ADD_DATASET_TO_OBJECT = "REQUEST_ADD_DATASET_TO_OBJECT"
export const CLEAR_ADD_DATASET_TO_OBJECT = "CLEAR_ADD_DATASET_TO_OBJECT"

export const CHANGE_MODAL_STATE = "CHANGE_MODAL_STATE"
export const CHANGE_INFO_STATE = "CHANGE_INFO_STATE"
export const CHANGE_LESSONS_MODAL_STATE = "CHANGE_LESSONS_MODAL_STATE"

export const CHANGE_EM_TOUR_STATE = "CHANGE_EM_TOUR_STATE"
export const CHANGE_EM_TOUR_STEP = "CHANGE_EM_TOUR_STEP"

//InlineContent
export const CHANGE_CUSTOM_TOOLBAR_ID = "CHANGE_CUSTOM_TOOLBAR_ID"

export const CLEAR_IL_MEDIA_UPLOAD = "CLEAR_IL_MEDIA_UPLOAD"
export const ERROR_IL_MEDIA_UPLOAD = "ERROR_IL_MEDIA_UPLOAD"
export const REQUEST_IL_MEDIA_UPLOAD = "REQUEST_IL_MEDIA_UPLOAD"
export const SUCCESS_IL_MEDIA_UPLOAD = "SUCCESS_IL_MEDIA_UPLOAD"

export const UPDATE_ADDITIONAL_SETTINGS_ICONTENT = "UPDATE_ADDITIONAL_SETTINGS_ICONTENT"

//CmsContent
export const CLEAR_CREATE_FORMULA = "CLEAR_CREATE_FORMULA"
export const ERROR_CREATE_FORMULA = "ERROR_CREATE_FORMULA"
export const REQUEST_CREATE_FORMULA = "REQUEST_CREATE_FORMULA"
export const SUCCESS_CREATE_FORMULA = "SUCCESS_CREATE_FORMULA"

export const CLEAR_UPDATE_FORMULA = "CLEAR_UPDATE_FORMULA"
export const ERROR_UPDATE_FORMULA = "ERROR_UPDATE_FORMULA"
export const REQUEST_UPDATE_FORMULA = "REQUEST_UPDATE_FORMULA"
export const SUCCESS_UPDATE_FORMULA = "SUCCESS_UPDATE_FORMULA"

export const CLEAR_SET_OBJECT_FORMULA = "CLEAR_SET_OBJECT_FORMULA"
export const ERROR_SET_OBJECT_FORMULA = "ERROR_SET_OBJECT_FORMULA"
export const REQUEST_SET_OBJECT_FORMULA = "REQUEST_SET_OBJECT_FORMULA"
export const SUCCESS_SET_OBJECT_FORMULA = "SUCCESS_SET_OBJECT_FORMULA"

export const CLEAR_SET_INSTANCE_FORMULA = "CLEAR_SET_INSTANCE_FORMULA"
export const ERROR_SET_INSTANCE_FORMULA = "ERROR_SET_INSTANCE_FORMULA"
export const REQUEST_SET_INSTANCE_FORMULA = "REQUEST_SET_INSTANCE_FORMULA"
export const SUCCESS_SET_INSTANCE_FORMULA = "SUCCESS_SET_INSTANCE_FORMULA"

export const CLEAR_UNSET_OBJECT_FORMULA = "CLEAR_UNSET_OBJECT_FORMULA"
export const ERROR_UNSET_OBJECT_FORMULA = "ERROR_UNSET_OBJECT_FORMULA"
export const REQUEST_UNSET_OBJECT_FORMULA = "REQUEST_UNSET_OBJECT_FORMULA"
export const SUCCESS_UNSET_OBJECT_FORMULA = "SUCCESS_UNSET_OBJECT_FORMULA"

export const CLEAR_UNSET_INSTANCE_FORMULA = "CLEAR_UNSET_INSTANCE_FORMULA"
export const ERROR_UNSET_INSTANCE_FORMULA = "ERROR_UNSET_INSTANCE_FORMULA"
export const REQUEST_UNSET_INSTANCE_FORMULA = "REQUEST_UNSET_INSTANCE_FORMULA"
export const SUCCESS_UNSET_INSTANCE_FORMULA = "SUCCESS_UNSET_INSTANCE_FORMULA"

export const CLEAR_UPDATE_OBJECT_CMS_SETTINGS = "CLEAR_UPDATE_OBJECT_CMS_SETTINGS"
export const ERROR_UPDATE_OBJECT_CMS_SETTINGS = "ERROR_UPDATE_OBJECT_CMS_SETTINGS"
export const REQUEST_UPDATE_OBJECT_CMS_SETTINGS = "REQUEST_UPDATE_OBJECT_CMS_SETTINGS"
export const SUCCESS_UPDATE_OBJECT_CMS_SETTINGS = "SUCCESS_UPDATE_OBJECT_CMS_SETTINGS"

/**
 * Media Library
 */
export const ERROR_FETCH_MEDIA = "ERROR_FETCH_MEDIA"
export const REQUEST_FETCH_MEDIA = "REQUEST_FETCH_MEDIA"
export const SUCCESS_FETCH_MEDIA = "SUCCESS_FETCH_MEDIA"

export const ERROR_DELETE_MEDIA = "ERROR_DELETE_MEDIA"
export const REQUEST_DELETE_MEDIA = "REQUEST_DELETE_MEDIA"
export const SUCCESS_DELETE_MEDIA = "SUCCESS_DELETE_MEDIA"
export const CLEAN_DELETE_MEDIA = "CLEAN_DELETE_MEDIA"

export const ERROR_DELETE_SINGLE_MEDIA = "ERROR_DELETE_SINGLE_MEDIA"
export const REQUEST_DELETE_SINGLE_MEDIA = "REQUEST_DELETE_SINGLE_MEDIA"
export const SUCCESS_DELETE_SINGLE_MEDIA = "SUCCESS_DELETE_SINGLE_MEDIA"
export const CLEAN_DELETE_SINGLE_MEDIA = "CLEAN_DELETE_SINGLE_MEDIA"

export const ERROR_UPDATE_META_SINGLE_MEDIA = "ERROR_UPDATE_META_SINGLE_MEDIA"
export const REQUEST_UPDATE_META_SINGLE_MEDIA = "REQUEST_UPDATE_META_SINGLE_MEDIA"
export const SUCCESS_UPDATE_META_SINGLE_MEDIA = "SUCCESS_UPDATE_META_SINGLE_MEDIA"
export const CLEAN_UPDATE_META_SINGLE_MEDIA = "CLEAN_UPDATE_META_SINGLE_MEDIA"

export const ERROR_BULK_UPDATE_META_SINGLE_MEDIA = "ERROR_BULK_UPDATE_META_SINGLE_MEDIA"
export const REQUEST_BULK_UPDATE_META_SINGLE_MEDIA = "REQUEST_BULK_UPDATE_META_SINGLE_MEDIA"
export const SUCCESS_BULK_UPDATE_META_SINGLE_MEDIA = "SUCCESS_BULK_UPDATE_META_SINGLE_MEDIA"
export const CLEAN_BULK_UPDATE_MEDIA_META = "CLEAN_BULK_UPDATE_MEDIA_META"

export const ERROR_UPLOAD_MEDIA = "ERROR_UPLOAD_MEDIA"
export const REQUEST_UPLOAD_MEDIA = "REQUEST_UPLOAD_MEDIA"
export const SUCCESS_UPLOAD_MEDIA = "SUCCESS_UPLOAD_MEDIA"
export const CLEAN_UPLOAD_MEDIA = "CLEAN_UPLOAD_MEDIA"

export const ERROR_BULK_UPLOAD_MEDIA = "ERROR_BULK_UPLOAD_MEDIA"
export const REQUEST_BULK_UPLOAD_MEDIA = "REQUEST_BULK_UPLOAD_MEDIA"
export const SUCCESS_BULK_UPLOAD_MEDIA = "SUCCESS_BULK_UPLOAD_MEDIA"
export const CLEAN_BULK_UPLOAD_MEDIA = "CLEAN_BULK_UPLOAD_MEDIA"

export const ERROR_REPLACE_SINGLE_MEDIA = "ERROR_REPLACE_SINGLE_MEDIA"
export const REQUEST_REPLACE_SINGLE_MEDIA = "REQUEST_REPLACE_SINGLE_MEDIA"
export const SUCCESS_REPLACE_SINGLE_MEDIA = "SUCCESS_REPLACE_SINGLE_MEDIA"
export const CLEAN_REPLACE_SINGLE_MEDIA = "CLEAN_REPLACE_SINGLE_MEDIA"

export const TRIGGER_MEDIA_LIBRARY_FORM = "TRIGGER_MEDIA_LIBRARY_FORM"
export const TRIGGER_MEDIA_LIBRARY_STATE = "TRIGGER_MEDIA_LIBRARY_STATE"

export const SELECTED_MEDIA = "SELECTED_MEDIA"

export const BOB_SELECTED_MEDIA = "BOB_SELECTED_MEDIA"

export const SEND_SELECTED_MEDIA_ID = "SEND_SELECTED_MEDIA_ID"

export const UPDATE_PAGE_OBJECT_WITH_MEDIA = "UPDATE_PAGE_OBJECT_WITH_MEDIA"

export const UPDATE_PAGE_OBJECT_WITH_MEDIA_LIST = "UPDATE_PAGE_OBJECT_WITH_MEDIA_LIST"
export const CLEAN_PAGE_OBJECT_WITH_MEDIA_LIST = "CLEAN_PAGE_OBJECT_WITH_MEDIA_LIST"

export const UPDATE_PAGE_OBJECT_WITH_POST_LIST = "UPDATE_PAGE_OBJECT_WITH_POST_LIST"
export const CLEAN_PAGE_OBJECT_WITH_POST_LIST = "CLEAN_PAGE_OBJECT_WITH_POST_LIST"

export const ERROR_MEDIA_USAGE_BY_PAGE = "ERROR_MEDIA_USAGE_BY_PAGE"
export const REQUEST_MEDIA_USAGE_BY_PAGE = "REQUEST_MEDIA_USAGE_BY_PAGE"
export const SUCCESS_MEDIA_USAGE_BY_PAGE = "SUCCESS_MEDIA_USAGE_BY_PAGE"
export const CLEAN_MEDIA_USAGE_BY_PAGE = "CLEAN_MEDIA_USAGE_BY_PAGE"

export const ERROR_MEDIA_USAGE_BY_POST = "ERROR_MEDIA_USAGE_BY_POST"
export const REQUEST_MEDIA_USAGE_BY_POST = "REQUEST_MEDIA_USAGE_BY_POST"
export const SUCCESS_MEDIA_USAGE_BY_POST = "SUCCESS_MEDIA_USAGE_BY_POST"
export const CLEAN_MEDIA_USAGE_BY_POST = "CLEAN_MEDIA_USAGE_BY_POST"

export const ERROR_MEDIA_USAGE_BY_TEMPLATE = "ERROR_MEDIA_USAGE_BY_TEMPLATE"
export const REQUEST_MEDIA_USAGE_BY_TEMPLATE = "REQUEST_MEDIA_USAGE_BY_TEMPLATE"
export const SUCCESS_MEDIA_USAGE_BY_TEMPLATE = "SUCCESS_MEDIA_USAGE_BY_TEMPLATE"
export const CLEAN_MEDIA_USAGE_BY_TEMPLATE = "CLEAN_MEDIA_USAGE_BY_TEMPLATE"

export const ERROR_MEDIA_USAGE_BY_SYMBOL = "ERROR_MEDIA_USAGE_BY_SYMBOL"
export const REQUEST_MEDIA_USAGE_BY_SYMBOL = "REQUEST_MEDIA_USAGE_BY_SYMBOL"
export const SUCCESS_MEDIA_USAGE_BY_SYMBOL = "SUCCESS_MEDIA_USAGE_BY_SYMBOL"
export const CLEAN_MEDIA_USAGE_BY_SYMBOL = "CLEAN_MEDIA_USAGE_BY_SYMBOL"

export const DELETED_MEDIA_LIST = "DELETED_MEDIA_LIST"
export const CLEAR_DELETED_MEDIA_LIST = "CLEAR_DELETED_MEDIA_LIST"

export const UPDATED_REPLACED_MEDIA_LIST = "UPDATED_REPLACED_MEDIA_LIST"
export const CLEAR_UPDATED_REPLACED_MEDIA_LIST = "CLEAR_UPDATED_REPLACED_MEDIA_LIST"

export const MEDIA_LIBRARY_BULK_UPLOAD_FORM = "MEDIA_LIBRARY_BULK_UPLOAD_FORM"

/**
 * Library Actions
 */
export const FILTER_BY_TYPE_GS = "FILTER_BY_TYPE_GS"
export const FILTER_BY_TYPE_FOUNDATION = "FILTER_BY_TYPE_FOUNDATION"

/**
 * Content Library Actions
 */
export const TOGGLE_CONTENT_FORM_STATE = "TOGGLE_CONTENT_FORM_STATE"
export const TOGGLE_DYNAMIC_CONTENT_FORM_STATE = "TOGGLE_DYNAMIC_CONTENT_FORM_STATE"
export const TOGGLE_CONTENT_LIBRARY_PANEL = "TOGGLE_CONTENT_LIBRARY_PANEL"

export const RECEIVE_CONTENT_LIBRARY = "RECEIVE_CONTENT_LIBRARY"
export const ERROR_FETCH_CONTENT_LIBRARY = "ERROR_FETCH_CONTENT_LIBRARY"
export const FETCHING_CONTENT_LIBRARY = "FETCHING_CONTENT_LIBRARY"
export const CLEAN_CONTENT_LIBRARY = "CLEAN_CONTENT_LIBRARY"

export const VALID_CONTENT_PAGE_POST_LINK = "VALID_CONTENT_PAGE_POST_LINK"
export const INVALID_CONTENT_PAGE_POST_LINK = "INVALID_CONTENT_PAGE_POST_LINK"
export const REQUEST_VALIDATE_CONTENT_PAGE_POST_LINK = "REQUEST_VALIDATE_CONTENT_PAGE_POST_LINK"
export const CLEAN_VALIDATE_CONTENT_PAGE_POST_LINK = "CLEAN_VALIDATE_CONTENT_PAGE_POST_LINK"

export const SUCCESS_FETCH_POSTS_TO_TEST_CONTENT_PAGE = "SUCCESS_FETCH_POSTS_TO_TEST_CONTENT_PAGE"
export const ERROR_FETCH_POSTS_TO_TEST_CONTENT_PAGE = "ERROR_FETCH_POSTS_TO_TEST_CONTENT_PAGE"
export const REQUEST_POSTS_TO_TEST_CONTENT_PAGE = "REQUEST_POSTS_TO_TEST_CONTENT_PAGE"

export const SUCCESS_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE = "SUCCESS_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE"
export const ERROR_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE = "ERROR_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE"
export const REQUEST_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE = "REQUEST_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE"
export const CLEAN_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE = "CLEAN_SET_DEFAULT_POST_TO_TEST_CONTENT_PAGE"

export const SUCCESS_FETCH_PREVIEW_CONTENT_PAGE_POST = "SUCCESS_FETCH_PREVIEW_CONTENT_PAGE_POST"
export const ERROR_FETCH_PREVIEW_CONTENT_PAGE_POST = "ERROR_FETCH_PREVIEW_CONTENT_PAGE_POST"
export const REQUEST_PREVIEW_CONTENT_PAGE_POST = "REQUEST_PREVIEW_CONTENT_PAGE_POST"
export const CLEAN_FETCH_PREVIEW_CONTENT_PAGE_POST = "CLEAN_FETCH_PREVIEW_CONTENT_PAGE_POST"

export const SUCCESS_FETCH_PUBLIC_CONTENT_PAGE_POST = "SUCCESS_FETCH_PUBLIC_CONTENT_PAGE_POST"
export const ERROR_FETCH_PUBLIC_CONTENT_PAGE_POST = "ERROR_FETCH_PUBLIC_CONTENT_PAGE_POST"
export const REQUEST_PUBLIC_CONTENT_PAGE_POST = "REQUEST_PUBLIC_CONTENT_PAGE_POST"
export const CLEAN_FETCH_PUBLIC_CONTENT_PAGE_POST = "CLEAN_FETCH_PUBLIC_CONTENT_PAGE_POST"

export const SUCCESS_DETACH_CONTENT_PAGE_POST = "SUCCESS_DETACH_CONTENT_PAGE_POST"
export const ERROR_DETACH_CONTENT_PAGE_POST = "ERROR_DETACH_CONTENT_PAGE_POST"
export const REQUEST_DETACH_CONTENT_PAGE_POST = "REQUEST_DETACH_CONTENT_PAGE_POST"
export const CLEAN_DETACH_CONTENT_PAGE_POST = "CLEAN_DETACH_CONTENT_PAGE_POST"

export const SUCCESS_FETCH_CONTENT_PAGE_LIST_BY_POST = "SUCCESS_FETCH_CONTENT_PAGE_LIST_BY_POST"
export const ERROR_FETCH_CONTENT_PAGE_LIST_BY_POST = "ERROR_FETCH_CONTENT_PAGE_LIST_BY_POST"
export const REQUEST_FETCH_CONTENT_PAGE_LIST_BY_POST = "REQUEST_FETCH_CONTENT_PAGE_LIST_BY_POST"
export const CLEAN_FETCH_CONTENT_PAGE_LIST_BY_POST = "CLEAN_FETCH_CONTENT_PAGE_LIST_BY_POST"

export const SUCCESS_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE = "SUCCESS_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE"
export const ERROR_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE = "ERROR_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE"
export const REQUEST_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE = "REQUEST_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE"
export const CLEAN_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE = "CLEAN_FETCH_CONTENT_PAGE_LIST_BY_WORKSPACE"

export const ERROR_CREATE_POST = "ERROR_CREATE_POST"
export const REQUEST_CREATE_POST = "REQUEST_CREATE_POST"
export const SUCCESS_CREATE_POST = "SUCCESS_CREATE_POST"
export const CLEAN_CREATE_POST = "CLEAN_CREATE_POST"

export const ERROR_UPDATE_POST = "ERROR_UPDATE_POST"
export const REQUEST_UPDATE_POST = "REQUEST_UPDATE_POST"
export const SUCCESS_UPDATE_POST = "SUCCESS_UPDATE_POST"
export const CLEAN_UPDATE_POST = "CLEAN_UPDATE_POST"

export const ERROR_UPDATE_POST_WITH_SCHEMA = "ERROR_UPDATE_POST_WITH_SCHEMA"
export const REQUEST_UPDATE_POST_WITH_SCHEMA = "REQUEST_UPDATE_POST_WITH_SCHEMA"
export const SUCCESS_UPDATE_POST_WITH_SCHEMA = "SUCCESS_UPDATE_POST_WITH_SCHEMA"
export const CLEAN_UPDATE_POST_WITH_SCHEMA = "CLEAN_UPDATE_POST_WITH_SCHEMA"

export const ADD_MEDIA_TO_CONTENT_LIBRARY_RESPONSE = "ADD_MEDIA_TO_CONTENT_LIBRARY_RESPONSE"

export const DRAFT_POST = "DRAFT_POST"

export const ERROR_FETCH_SINGLE_POST = "ERROR_FETCH_SINGLE_POST"
export const REQUEST_SINGLE_POST = "REQUEST_SINGLE_POST"
export const SUCCESS_FETCH_SINGLE_POST = "SUCCESS_FETCH_SINGLE_POST"
export const CLEAN_FETCH_SINGLE_POST = "CLEAN_FETCH_SINGLE_POST"

/**
 * Single Sign On
 */
export const ERROR_SSO_LOGIN = "ERROR_SSO_LOGIN"
export const REQUEST_SSO_LOGIN = "REQUEST_SSO_LOGIN"
export const SUCCESS_SSO_LOGIN = "SUCCESS_SSO_LOGIN"
export const CLEAN_SSO_LOGIN = "CLEAN_SSO_LOGIN"

export const ERROR_SSO_REGISTER = "ERROR_SSO_REGISTER"
export const REQUEST_SSO_REGISTER = "REQUEST_SSO_REGISTER"
export const SUCCESS_SSO_REGISTER = "SUCCESS_SSO_REGISTER"
export const CLEAN_SSO_REGISTER = "CLEAN_SSO_REGISTER"

export const ERROR_SSO_EMAIL_CONFIRMATION = "ERROR_SSO_EMAIL_CONFIRMATION"
export const REQUEST_SSO_EMAIL_CONFIRMATION = "REQUEST_SSO_EMAIL_CONFIRMATION"
export const SUCCESS_SSO_EMAIL_CONFIRMATION = "SUCCESS_SSO_EMAIL_CONFIRMATION"
export const CLEAN_SSO_EMAIL_CONFIRMATION = "CLEAN_SSO_EMAIL_CONFIRMATION"

export const ERROR_SSO_NEW_CONFIRMATION_LINK = "ERROR_SSO_NEW_CONFIRMATION_LINK"
export const REQUEST_SSO_NEW_CONFIRMATION_LINK = "REQUEST_SSO_NEW_CONFIRMATION_LINK"
export const SUCCESS_SSO_NEW_CONFIRMATION_LINK = "SUCCESS_SSO_NEW_CONFIRMATION_LINK"
export const CLEAN_SSO_NEW_CONFIRMATION_LINK = "CLEAN_SSO_NEW_CONFIRMATION_LINK"

export const ERROR_SSO_COMMUNITY_REGISTRATION_PAGE_INFO = "ERROR_SSO_COMMUNITY_REGISTRATION_PAGE_INFO"
export const REQUEST_SSO_COMMUNITY_REGISTRATION_PAGE_INFO = "REQUEST_SSO_COMMUNITY_REGISTRATION_PAGE_INFO"
export const SUCCESS_SSO_COMMUNITY_REGISTRATION_PAGE_INFO = "SUCCESS_SSO_COMMUNITY_REGISTRATION_PAGE_INFO"
export const CLEAN_SSO_COMMUNITY_REGISTRATION_PAGE_INFO = "CLEAN_SSO_COMMUNITY_REGISTRATION_PAGE_INFO"

export const ERROR_SSO_COMMUNITY_REGISTRATION = "ERROR_SSO_COMMUNITY_REGISTRATION"
export const REQUEST_SSO_COMMUNITY_REGISTRATION = "REQUEST_SSO_COMMUNITY_REGISTRATION"
export const SUCCESS_SSO_COMMUNITY_REGISTRATION = "SUCCESS_SSO_COMMUNITY_REGISTRATION"
export const CLEAN_SSO_COMMUNITY_REGISTRATION = "CLEAN_SSO_COMMUNITY_REGISTRATION"

export const ERROR_SSO_ACTIVATE_ACCOUNT = "ERROR_SSO_ACTIVATE_ACCOUNT"
export const REQUEST_SSO_ACTIVATE_ACCOUNT = "REQUEST_SSO_ACTIVATE_ACCOUNT"
export const SUCCESS_SSO_ACTIVATE_ACCOUNT = "SUCCESS_SSO_ACTIVATE_ACCOUNT"
export const CLEAN_SSO_ACTIVATE_ACCOUNT = "CLEAN_SSO_ACTIVATE_ACCOUNT"

export const ERROR_SSO_NEW_REGISTER_CONFIRMATION = "ERROR_SSO_NEW_REGISTER_CONFIRMATION"
export const REQUEST_SSO_NEW_REGISTER_CONFIRMATION = "REQUEST_SSO_NEW_REGISTER_CONFIRMATION"
export const SUCCESS_SSO_NEW_REGISTER_CONFIRMATION = "SUCCESS_SSO_NEW_REGISTER_CONFIRMATION"
export const CLEAN_SSO_NEW_REGISTER_CONFIRMATION = "CLEAN_SSO_NEW_REGISTER_CONFIRMATION"

//Components inner functions

export const TOGGLE_MODAL = "TOGGLE_MODAL"
export const MODALV4 = "MODALV4"

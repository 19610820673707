import { objValueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { isRight } from "fp-ts/Either"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { FColor } from "../../../../../modules/shared-modules/foundationStyles/foundationStylesTypes"
import {
  GSShadowCodec,
  StylesShadowCodec,
  ShadowType,
  ShadowCSS,
  StylesShadow,
  GSShadow,
  Shadow,
  ShadowOpt,
  ShadowProps,
  ShadowPropsOpt,
} from "./shadowStyle"
import { ColorLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetTypes"
import { handleBobStylesheetLabel } from "../../../../../modules/shared-modules/stylesheet/stylesheetUtils"
import { get2WithNull, get2WithNull4Enable, get3WithNull4Enable } from "../bobUtils"

export function boxShadowCss(css: Partial<ShadowCSS>): string {
  if (css.hasOwnProperty("box-shadow")) return `box-shadow: ${(css as { "box-shadow": string })["box-shadow"]};`
  return ""
}

export function textShadowCss(css: Partial<ShadowCSS>): string {
  if (css.hasOwnProperty("text-shadow")) return `text-shadow: ${(css as { "text-shadow": string })["text-shadow"]};`
  return ""
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined = undefined, // TODO: fix when working on other bobs,
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  const styles = StylesShadowCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState, foundationStyle, stylesheetLabel)
  console.warn(decoderErrors(styles))
  return {}
}

export function globalStyleCssRenderUnsafe(
  gsObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined = undefined, // TODO: Work on when foundation is added to global styles
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  const gs = GSShadowCodec.decode(gsObj)
  if (isRight(gs)) return globalStyleCssRender(gs.right, breakpoint, behaviourState, foundationStyle, stylesheetLabel)
  console.warn(decoderErrors(gs))
  return {}
}

export function cssRender(
  stylesObj: StylesShadow,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.shadow, foundationStyle, stylesheetLabel)
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.shadow,
        mergeBob2(stylesObj?.behaviour?.[behaviourState]?.shadow, stylesObj.shadow, stylesheetLabel),
        foundationStyle,
        stylesheetLabel
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(
        stylesObj.shadow,
        mergeBob2(stylesObj?.[breakpoint]?.shadow, stylesObj.shadow, stylesheetLabel),
        foundationStyle,
        stylesheetLabel
      )
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.shadow,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.shadow,
          stylesObj?.behaviour?.[behaviourState]?.shadow,
          stylesObj?.[breakpoint]?.shadow,
          stylesObj.shadow,
          stylesheetLabel
        ),
        foundationStyle,
        stylesheetLabel
      )
    }
  }
}

export function globalStyleCssRender(
  stylesObj: GSShadow,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return render(stylesObj, foundationStyle, stylesheetLabel)
    }
    //hover | active
    else {
      return renderOpt(
        merge2(stylesObj?.behaviour?.[behaviourState], stylesObj, stylesheetLabel),
        foundationStyle,
        stylesheetLabel
      )
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderOpt(merge2(stylesObj?.[breakpoint], stylesObj, stylesheetLabel), foundationStyle, stylesheetLabel)
    }
    //hover | active
    else {
      return renderOpt(
        merge3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState],
          stylesObj.behaviour?.[behaviourState],
          stylesObj?.[breakpoint],
          stylesObj,
          stylesheetLabel
        ),
        foundationStyle,
        stylesheetLabel
      )
    }
  }
}

export function renderBob(
  shadowObj: Shadow,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  if (!shadowObj.enable) return {}

  return render(shadowObj, foundationStyle, stylesheetLabel)
}

export function renderBobOpt(
  defaultShadowObj: Shadow,
  shadowObj: ShadowOpt,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  if (shadowObj?.enable === false) {
    if (defaultShadowObj.enable) return cssProperty(`none`, undefined)

    return {}
  }

  if (shadowObj?.enable) return renderOpt(shadowObj, foundationStyle, stylesheetLabel)

  return {}
}

export function render(
  shadowObj: ShadowProps,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined
): ShadowCSS {
  const isBox = ` ${shadowObj.spread}px`
  const cssValue = `${shadowObj.inset ? "inset " : ""}${shadowObj.offsetH}px ${shadowObj.offsetV}px ${
    shadowObj.blur
  }px${isBox} ${handleBobStylesheetLabel(stylesheetLabel, shadowObj.color)}`
  // TODO: foundation color reverted
  // }px${isBox} ${foundationStyle ? foundationStyle.color : shadowObj.color} !important`
  return cssProperty(cssValue, stylesheetLabel)
}

export function renderOpt(
  shadowObj: ShadowPropsOpt,
  foundationStyle: FColor | undefined,
  stylesheetLabel: ColorLabel | undefined
): Partial<ShadowCSS> {
  if (
    objValueExists(shadowObj, "inset") ||
    objValueExists(shadowObj, "offsetH") ||
    objValueExists(shadowObj, "offsetV") ||
    objValueExists(shadowObj, "blur") ||
    objValueExists(shadowObj, "color") ||
    objValueExists(shadowObj, "spread") ||
    stylesheetLabel
  ) {
    const isBox = ` ${shadowObj.spread}px`
    const cssValue = `${shadowObj.inset ? "inset " : ""}${shadowObj.offsetH}px ${shadowObj.offsetV}px ${
      shadowObj.blur
    }px${isBox} ${handleBobStylesheetLabel(stylesheetLabel, shadowObj.color)}`
    // TODO: foundation color reverted
    // }px${isBox} ${foundationStyle ? foundationStyle.color : shadowObj.color} !important`
    return cssProperty(cssValue, stylesheetLabel)
  }
  return {}
}

export function cssProperty(cssValue: string, stylesheetLabel: ColorLabel | undefined): ShadowCSS {
  return { "box-shadow": cssValue }
}

/**
 *
 * @param shadowObj
 * @param defaultShadowObj
 * @returns Shadow
 *
 */
export function mergeBob2(
  shadowObj: ShadowOpt | undefined,
  defaultShadowObj: Shadow,
  stylesheetLabel: ColorLabel | undefined
): ShadowOpt {
  if (!shadowObj) {
    return {
      enable: undefined,
      inset: undefined,
      offsetH: undefined,
      offsetV: undefined,
      blur: undefined,
      color: undefined,
      spread: undefined,
    }
  }

  const enable = get2WithNull4Enable(shadowObj?.enable, defaultShadowObj.enable)
  //if one of the values is set in the breakpoint we render the entire shadow property
  if (
    objValueExists(shadowObj, "inset") ||
    objValueExists(shadowObj, "offsetH") ||
    objValueExists(shadowObj, "offsetV") ||
    objValueExists(shadowObj, "blur") ||
    objValueExists(shadowObj, "color") ||
    objValueExists(shadowObj, "spread") ||
    stylesheetLabel
  ) {
    const inset = get2WithNull(shadowObj?.inset, defaultShadowObj.inset)
    const offsetH = get2WithNull(shadowObj?.offsetH, defaultShadowObj.offsetH)
    const offsetV = get2WithNull(shadowObj?.offsetV, defaultShadowObj.offsetV)
    const blur = get2WithNull(shadowObj?.blur, defaultShadowObj.blur)
    const color = get2WithNull(shadowObj?.color, defaultShadowObj.color)
    const spread = get2WithNull(shadowObj?.spread, defaultShadowObj.spread)

    return {
      enable,
      inset,
      offsetH,
      offsetV,
      blur,
      color,
      spread,
    }
  }
  return {
    enable: shadowObj?.enable,
    inset: undefined,
    offsetH: undefined,
    offsetV: undefined,
    blur: undefined,
    color: undefined,
    spread: undefined,
  }
}

/**
 *
 * @param shadowObj
 * @param defaultShadowObj
 * @returns ShadowProps
 *
 */
export function merge2(
  shadowObj: ShadowPropsOpt | undefined,
  defaultShadowObj: ShadowProps,
  stylesheetLabel: ColorLabel | undefined
): ShadowPropsOpt {
  if (!shadowObj) {
    return {
      inset: undefined,
      offsetH: undefined,
      offsetV: undefined,
      blur: undefined,
      color: undefined,
      spread: undefined,
    }
  }

  if (
    objValueExists(shadowObj, "inset") ||
    objValueExists(shadowObj, "offsetH") ||
    objValueExists(shadowObj, "offsetV") ||
    objValueExists(shadowObj, "blur") ||
    objValueExists(shadowObj, "color") ||
    objValueExists(shadowObj, "spread") ||
    stylesheetLabel
  ) {
    const inset = get2WithNull(shadowObj?.inset, defaultShadowObj.inset)
    const offsetH = get2WithNull(shadowObj?.offsetH, defaultShadowObj.offsetH)
    const offsetV = get2WithNull(shadowObj?.offsetV, defaultShadowObj.offsetV)
    const blur = get2WithNull(shadowObj?.blur, defaultShadowObj.blur)
    const color = get2WithNull(shadowObj?.color, defaultShadowObj.color)
    const spread = get2WithNull(shadowObj?.spread, defaultShadowObj.spread)

    return {
      inset,
      offsetH,
      offsetV,
      blur,
      color,
      spread,
    }
  }
  return {
    inset: undefined,
    offsetH: undefined,
    offsetV: undefined,
    blur: undefined,
    color: undefined,
    spread: undefined,
  }
}

/**
 *
 * @param shadowObj
 * @param shadowDefaultBreakpoint
 * @param defaultShadowObj
 * @returns Shadow
 */
export function mergeBob3(
  shadowObj: ShadowOpt | undefined,
  shadowDesktopBehaviour: ShadowOpt | undefined,
  shadowDefaultBreakpoint: ShadowOpt | undefined,
  defaultShadowObj: Shadow,
  stylesheetLabel: ColorLabel | undefined
): ShadowOpt {
  if (!shadowObj) {
    return {
      enable: undefined,
      inset: undefined,
      offsetH: undefined,
      offsetV: undefined,
      blur: undefined,
      color: undefined,
      spread: undefined,
    }
  }
  const enable = get3WithNull4Enable(
    shadowObj?.enable,
    shadowDesktopBehaviour?.enable,
    shadowDefaultBreakpoint?.enable,
    defaultShadowObj.enable
  )
  if (
    objValueExists(shadowObj, "inset") ||
    objValueExists(shadowObj, "offsetH") ||
    objValueExists(shadowObj, "offsetV") ||
    objValueExists(shadowObj, "blur") ||
    objValueExists(shadowObj, "color") ||
    objValueExists(shadowObj, "spread") ||
    stylesheetLabel
  ) {
    const inset =
      shadowObj?.inset ?? shadowDesktopBehaviour?.inset ?? shadowDefaultBreakpoint?.inset ?? defaultShadowObj.inset
    const offsetH =
      shadowObj?.offsetH ??
      shadowDesktopBehaviour?.offsetH ??
      shadowDefaultBreakpoint?.offsetH ??
      defaultShadowObj.offsetH
    const offsetV =
      shadowObj?.offsetV ??
      shadowDesktopBehaviour?.offsetV ??
      shadowDefaultBreakpoint?.offsetV ??
      defaultShadowObj.offsetV
    const blur =
      shadowObj?.blur ?? shadowDesktopBehaviour?.blur ?? shadowDefaultBreakpoint?.blur ?? defaultShadowObj.blur
    const color =
      shadowObj?.color ?? shadowDesktopBehaviour?.color ?? shadowDefaultBreakpoint?.color ?? defaultShadowObj.color
    const spread =
      shadowObj?.spread ?? shadowDesktopBehaviour?.spread ?? shadowDefaultBreakpoint?.spread ?? defaultShadowObj.spread

    return {
      enable,
      inset,
      offsetH,
      offsetV,
      blur,
      color,
      spread,
    }
  }
  return {
    enable: shadowObj?.enable,
    inset: undefined,
    offsetH: undefined,
    offsetV: undefined,
    blur: undefined,
    color: undefined,
    spread: undefined,
  }
}

/**
 *
 * @param shadowObj
 * @param shadowDefaultBreakpoint
 * @param defaultShadowObj
 * @returns Shadow
 */
export function merge3(
  shadowObj: ShadowPropsOpt | undefined,
  shadowDesktopBehaviour: ShadowPropsOpt | undefined,
  shadowDefaultBreakpoint: ShadowPropsOpt | undefined,
  defaultShadowObj: ShadowProps,
  stylesheetLabel: ColorLabel | undefined
): ShadowPropsOpt {
  if (!shadowObj) {
    return {
      inset: undefined,
      offsetH: undefined,
      offsetV: undefined,
      blur: undefined,
      color: undefined,
      spread: undefined,
    }
  }

  if (
    objValueExists(shadowObj, "inset") ||
    objValueExists(shadowObj, "offsetH") ||
    objValueExists(shadowObj, "offsetV") ||
    objValueExists(shadowObj, "blur") ||
    objValueExists(shadowObj, "color") ||
    objValueExists(shadowObj, "spread") ||
    stylesheetLabel
  ) {
    const inset =
      shadowObj?.inset ?? shadowDesktopBehaviour?.inset ?? shadowDefaultBreakpoint?.inset ?? defaultShadowObj.inset
    const offsetH =
      shadowObj?.offsetH ??
      shadowDesktopBehaviour?.offsetH ??
      shadowDefaultBreakpoint?.offsetH ??
      defaultShadowObj.offsetH
    const offsetV =
      shadowObj?.offsetV ??
      shadowDesktopBehaviour?.offsetV ??
      shadowDefaultBreakpoint?.offsetV ??
      defaultShadowObj.offsetV
    const blur =
      shadowObj?.blur ?? shadowDesktopBehaviour?.blur ?? shadowDefaultBreakpoint?.blur ?? defaultShadowObj.blur
    const color =
      shadowObj?.color ?? shadowDesktopBehaviour?.color ?? shadowDefaultBreakpoint?.color ?? defaultShadowObj.color
    const spread =
      shadowObj?.spread ?? shadowDesktopBehaviour?.spread ?? shadowDefaultBreakpoint?.spread ?? defaultShadowObj.spread

    return {
      inset,
      offsetH,
      offsetV,
      blur,
      color,
      spread,
    }
  }
  return {
    inset: undefined,
    offsetH: undefined,
    offsetV: undefined,
    blur: undefined,
    color: undefined,
    spread: undefined,
  }
}


export type { ShadowCSS, StylesShadow, GSShadow, ShadowProps, ShadowPropsOpt, Shadow, ShadowOpt, ShadowType }

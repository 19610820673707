import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function filterSelectorReducer(state = initialState.filterSelectorReducer, action) {
  switch (action.type) {
    case types.SELECTED_LINE:
      return {
        lineSelected: action.lineID,
      }
    default:
      return state
  }
}

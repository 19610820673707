import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function xFilterReducer(state = initialState.xFilterReducer, action) {
  switch (action.type) {
    // case types.REQUEST_ADD_XFILTER:
    //   return {
    //     ...state,
    //     xfilter: action.xfilter,
    //     xFilterId: action.xFilterId
    //   }
    // case types.REQUEST_EDIT_XFILTER:
    //   return {
    //     ...state,
    //     content: action.content
    //   }
    // case types.REQUEST_REMOVE_XFILTER:
    //   return {
    //     ...state,
    //     error: action.content
    //   }
    // case types.CONFIRMED_XFILTER_ACTION:
    //   return {
    //     ...state,
    //     content: action.content
    //   }
    // case types.ERROR_XFILTER:
    //   return {
    //     ...state,
    //     error: action.error
    //   }
    // case types.NEW_MQL_QUERY_PARAMETERS:
    //   return {
    //     ...state,
    //     sorter: action.sorter,
    //     logic: action.logic,
    //     query: action.query
    //   }
    case types.CHANGED_MQL_FILTER_QUERY:
      return {
        ...state,
        mqlFilterQuery: action.query
      }
    case types.CHANGE_XFILTER_MODE:
      return {
        ...state,
        mode: action.mode
      }
    default:
      return state;
  }
}

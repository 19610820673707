import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function addTagReducer(state = initialState.addTagReducer, action) {
  switch (action.type) {
    case types.REQUEST_ADD_TAG:
      return {
        loadingAddTag: true
      }
    case types.CONFIRMED_ADD_TAG:
      return {
        addedTag: action.tag, // used in collapse extra info
        createdTag: action.response, // used in post modal editor
        loadingAddTag: false
      }
    case types.ERROR_ADD_TAG:
      return {
        errorAddTag: action.error,
        loadingAddTag: false
      }
    case types.CLEAR_REDUX_ADD_TAG:
      return {
        errorAddTag: false,
        loadingAddTag: false,
        addedTag: action.actionToClear === 'add' ? false : state.addedTag,
        createdTag: action.actionToClear === 'create' ? false : state.createdTag,
      }
    default:
      return state
  }
}

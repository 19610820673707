import cloneDeep from "lodash/cloneDeep"

export function deepLevelHubObj(obj) {
  let object
  if (obj.hasOwnProperty("response")) {
    object = cloneDeep(obj.response)
  } else {
    object = obj
  }

  if (object.content && object.content.constructor === Array) {
    let objectsArr = []
    let itemsArr = []
    let sortedObjArr = []
    for (let i = 0; i < object.content.length; i++) {
      // Weird problem with null content inside the array
      if (object.content[i]) {
        /**
         * We only order objects, items dont need
         * to be addressed, we just store them to mix
         * them again after we order the objects
         */
        if (
          object.content[i].type === "object" ||
          object.content[i].type === "instance" ||
          object.content[i].type === "root"
        ) {
          objectsArr = [...objectsArr, object.content[i]]
          this.recursiveObj(object.content[i])
        } else {
          itemsArr = [...itemsArr, object.content[i]]
        }
      }
    }
    /**
     * The order is just read when the array length
     * is bigger then 1, since its useless to do it
     * with just 1 item.
     */
    if (objectsArr.length > 1) {
      sortedObjArr = [
        ...objectsArr.sort((a, b) => {
          return a.order - b.order
        }),
        ...itemsArr,
      ]
    }
    /**
     * With the sortedObjArr populated we replace the content with
     * the new sorted array, since we made a immutable copy for the entire
     * hub we dont care if we mutable this variable here
     */
    if (sortedObjArr.length > 0) {
      object.content = sortedObjArr
    }
  }
  return { response: object }
}

export function recursiveObj(object) {
  if (object.content && object.content.constructor === Array) {
    let objectsArr = []
    let itemsArr = []
    let sortedObjArr = []
    for (let i = 0; i < object.content.length; i++) {
      // Weird problem with null content inside the array
      if (object.content[i]) {
        /**
         * We only order objects, items dont need
         * to be addressed, we just store them to mix
         * them again after we order the objects
         */
        if (
          object.content[i].type === "object" ||
          object.content[i].type === "instance" ||
          object.content[i].type === "root"
        ) {
          objectsArr = [...objectsArr, object.content[i]]
          this.recursiveObj(object.content[i])
        } else {
          itemsArr = [...itemsArr, object.content[i]]
        }
      }
    }
    /**
     * The order is just read when the array length
     * is bigger then 1, since its useless to do it
     * with just 1 item.
     */
    if (objectsArr.length > 1) {
      sortedObjArr = [
        ...objectsArr.sort((a, b) => {
          return a.order - b.order
        }),
        ...itemsArr,
      ]
    }
    /**
     * With the sortedObjArr populated we replace the content with
     * the new sorted array, since we made a immutable copy for the entire
     * hub we dont care if we mutable this variable here
     */
    if (sortedObjArr.length > 0) {
      object.content = sortedObjArr
    }
  }
}

export const MOBILE = 766
export const MOBILE_VIEW = 400
export const TABLET = 767
export const TABLET_VIEW = 830
export const DESKTOP = 1200
export const FULL_SCREEN = '100%_NO_BARS'

type ViewPortSizeState = string | number
type ViewPortScaleState = number
type ViewPortOriginalSizeState = number

export type { ViewPortSizeState, ViewPortScaleState, ViewPortOriginalSizeState }
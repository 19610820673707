import React, { useState } from "react"
import { JobRun, JobRunId } from "./jobsSchema"
import Tree, { ItemId, RenderItemParams, TreeData, TreeItem, mutateTree } from "@atlaskit/tree"
import { useLoader } from "../../../lib/hooks/useLoader"
import { JobsApi } from "./jobsApi"
import { isErr, isOk } from "../../../lib/monads/result/result"

interface NavigationPanelProps {
  jobsClient: JobsApi
  handleJumpToDocument: (jobRunId: JobRunId) => void
}

function runsToTreeData(runsList: JobRun[]): TreeData {
  //NO SUB-LEVELS FOR NOW- Tree is a temporary solution
  const convertItemToTreeItem = (node: JobRun): TreeItem => {
    const itemId = node.id

    return {
      id: itemId,
      children: [],
      hasChildren: false,
      data: { id: node.id, title: node.name }, // You can add more data as needed
      isExpanded: true,
    }
  }
  const constructTree = (node: JobRun, items: TreeItem[]): TreeItem[] => {
    const item: TreeItem = convertItemToTreeItem(node)
    const newItems = [...items, item]
    const childrenItems: TreeItem[] = []

    return newItems.concat(childrenItems)
  }

  // Assume the root is known or static
  const rootId = "root" // You can set it as needed
  const rootItem: TreeItem = {
    id: rootId,
    children: runsList.map((item) => convertItemToTreeItem(item).id),
    hasChildren: true,
    data: { id: "rootData" }, // You can add more data for the root
  }

  const items = { [rootItem.id]: rootItem }

  runsList.forEach((item) => {
    const convertedItems: TreeItem[] = constructTree(item, [])
    convertedItems.forEach((cI) => (items[cI.id] = cI))
  })

  return {
    rootId: rootItem.id,
    items,
  }
}
function alphOrder(jobRuns: JobRun[]): JobRun[] {
  const compareByName = (a: JobRun, b: JobRun) => a.name.localeCompare(b.name)

  // Sort the array based on the 'name' property
  const sortedJobRuns = jobRuns.slice().sort(compareByName)

  return sortedJobRuns
}

const PADDING_PER_LEVEL = 16

/* POSTID reflects on the list of Assistants + topics + the inputs for the job selected and maybe something showing which is selected?*/
const NavigationPanel: React.FC<NavigationPanelProps> = (props) => {
  const [treeData, setTreeData] = useState<TreeData>(runsToTreeData([])) //??????? not sure the correct order yet

  const documentRuns = useLoader(
    () =>
      props.jobsClient
        .profileJobRuns()
        .then((jr) => jr.filter((j) => j.name !== "--"))
        .then((jr) => alphOrder(jr))
        .then((jrSorted) => setTreeData(runsToTreeData(jrSorted))),
    [props.jobsClient]
  )

  if (documentRuns && isErr(documentRuns)) {
    ;<div className='l-panel-container'>
      <div className='l-panel-header'>
        <DocTreeHeader />
      </div>
      <div className='l-panel-content'>
        <span>Error Loading Documents</span>
      </div>
    </div>
  }
  if (!documentRuns || !isOk(documentRuns)) {
    return (
      <div className='l-panel-container'>
        <div className='l-panel-header'>
          <DocTreeHeader />
        </div>
        <div className='l-panel-content'>
          <span>Loading Documents</span>
        </div>
      </div>
    )
  }

  const renderItem = ({ item, depth, onExpand, onCollapse, provided }: RenderItemParams) => {
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={`tree-item`}
        onClick={() => props.handleJumpToDocument(item.data.id)}
      >
        <span className='tree-text'>{item.data ? item.data.title : ""}</span>
      </div>
    )
  }

  const onExpand = (itemId: ItemId) => {
    setTreeData(mutateTree(treeData, itemId, { isExpanded: true }))
  }

  const onColapse = (itemId: ItemId) => {
    setTreeData(mutateTree(treeData, itemId, { isExpanded: false }))
  }

  return (
    <div className='l-panel-container'>
      <div className='l-panel-header'>
        <DocTreeHeader />
      </div>
      <div className='l-panel-content'>
        <Tree
          tree={treeData}
          renderItem={renderItem}
          onExpand={onExpand}
          onCollapse={onColapse}
          offsetPerLevel={PADDING_PER_LEVEL}
          isDragEnabled={false}
        />
      </div>
    </div>
  )
}

//Header with context option

function DocTreeHeader(): JSX.Element {
  return (
    <>
      <i className='fas fa-folder'></i>
      <span> {""}</span>
    </>
  )
}

export default NavigationPanel

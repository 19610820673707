import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function formulaTagsReducer(state = initialState.formulaTagsReducer, action) {
  switch (action.type) {
    case types.REQUEST_SPECIFIC_FORMULA_TAGS:
      return {
        ...state,
        loadingSpecificFT: true,
        createdTag: undefined,
      }
    case types.CONFIRMED_SPECIFIC_FORMULA_TAGS:
      return {
        ...state,
        loadingSpecificFT: false,
        SFTcontent: action.content
      }
    case types.ERROR_FETCH_SPECIFIC_FORMULA_TAGS:
      return {
        ...state,
        loadingSpecificFT: false,
        errorSepecificFT: action.error
      }

    /* -- * -- * -- * -- * -- * -- * -- * -- */

    case types.REQUEST_REMOVE_SINGLE_FORMULA_TAG:
      return {
        ...state,
        loadingRemoveFormulaTag: true,
        confirmedRemoveFormulaTag: undefined,
        errorRemoveFormulaTag: false
      }
    case types.CONFIRMED_REMOVE_SINGLE_FORMULA_TAG:
      return {
        ...state,
        loadingRemoveFormulaTag: false,
        confirmedRemoveFormulaTag: action.tag,
        errorRemoveFormulaTag: false
      }
    case types.ERROR_REMOVE_SINGLE_FORMULA_TAG:
      return {
        ...state,
        loadingRemoveFormulaTag: false,
        errorRemoveFormulaTag: action.error,
        confirmedRemoveFormulaTag: undefined
      }
    case types.CLEAR_REMOVE_SINGLE_FORMULA_TAG:
      return {
        ...state,
        loadingRemoveFormulaTag: false,
        errorRemoveFormulaTag: false,
        confirmedRemoveFormulaTag: undefined
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    case types.REQUEST_FORMULA_TAGS:
      return {
        ...state,
        loadingFormulaTags: true,
      }
    case types.RECEIVE_FORMULA_TAGS:
      return {
        ...state,
        loadingFormulaTags: false,
        formulaTags: action.content,
      }
    case types.ERROR_FETCHING_FORMULA_TAGS:
      return {
        ...state,
        loadingFormulaTags: false,
        errorFormulaTags: action.error,
      }

    /* -- * -- * -- * -- * -- * -- * -- * -- */

    case types.CLEAR_FORMULA_TAGS_ACTIONS:
      return {
        ...state,
        confirmedSubmitFormulaTag: undefined,
        loadingSubmitFormulaTag: false,
        errorSubmitFormulaTag: false,
        confirmedEditFormulaTag: undefined,
        loadingEditFormulaTag: false,
        errorEditFormulaTag: false,
        confirmedRemoveFormulaTag: undefined,
        loadingRemoveFormulaTag: false,
        errorRemoveFormulaTag: false,
        confirmedSubmitProfileTag: undefined,
        loadingSubmitProfileTag: false,
        errorSubmitProfileTag: false,
        confirmedEditProfileTag: undefined,
        loadingEditProfileTag: false,
        errorEditProfileTag: false,
        confirmedDeleteProfileTag: undefined,
        loadingDeleteProfileTag: false,
        errorDeleteProfileTag: false,
      }

    case types.REQUEST_CREATE_FORMULA_TAG:
      return {
        ...state,
        loadingSubmitFormulaTag: true,
        confirmedSubmitFormulaTag: undefined,
        errorSubmitFormulaTag: false
      }
    case types.CONFIRMED_CREATE_FORMULA_TAG:
      return {
        ...state,
        confirmedSubmitFormulaTag: action.tag,
        loadingSubmitFormulaTag: false,
        errorSubmitFormulaTag: false
      }
    case types.ERROR_CREATE_FORMULA_TAG:
      return {
        ...state,
        errorSubmitFormulaTag: action.error,
        loadingSubmitFormulaTag: false,
        confirmedSubmitFormulaTag: undefined,
      }
    case types.CLEAR_CREATE_FORMULA_TAG:
      return {
        ...state,
        errorSubmitFormulaTag: false,
        loadingSubmitFormulaTag: false,
        confirmedSubmitFormulaTag: undefined,
      }

    /* -- * -- * -- * -- * -- * -- * -- * -- */

    case types.REQUEST_EDIT_FORMULA_TAG:
      return {
        ...state,
        loadingEditFormulaTag: true,
        confirmedEditFormulaTag: undefined,
        errorEditFormulaTag: undefined
      }
    case types.CONFIRM_EDIT_FORMULA_TAG:
      return {
        ...state,
        confirmedEditFormulaTag: action.content,
        loadingEditFormulaTag: false,
        errorEditFormulaTag: undefined
      }
    case types.ERROR_EDIT_FORMULA_TAG:
      return {
        ...state,
        errorEditFormulaTag: action.error,
        loadingEditFormulaTag: false,
        confirmedEditFormulaTag: undefined,
      }
    case types.CLEAR_EDIT_FORMULA_TAG:
      return {
        ...state,
        errorEditFormulaTag: false,
        loadingEditFormulaTag: false,
        confirmedEditFormulaTag: undefined,
      }

    /* -- * -- * -- * -- * -- * -- * -- * -- */

    case types.RECEIVE_PROFILE_TAGS:
      return {
        ...state,
        loadingProfileTags: false,
        profileTags: action.profileTags,
      }
    case types.REQUEST_PROFILE_TAGS:
      return {
        ...state,
        loadingProfileTags: true,
        errorProfileTags: false
      }
    case types.ERROR_REQUEST_PROFILE_TAGS:
      return {
        ...state,
        loadingProfileTags: false,
        errorProfileTags: action.error
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    case types.SUBMIT_PROFILE_TAG:
      return {
        ...state,
        loadingSubmitProfileTag: true,
        confirmedSubmitProfileTag: undefined,
        errorSubmitProfileTag: false
      }
    case types.SUCCESS_SUBMIT_PROFILE_TAG:
      return {
        ...state,
        loadingSubmitProfileTag: false,
        confirmedSubmitProfileTag: action.response,
        errorSubmitProfileTag: false
      }
    case types.ERROR_SUBMIT_PROFILE_TAG:
      return {
        ...state,
        loadingSubmitProfileTag: false,
        errorSubmitProfileTag: action.error,
        confirmedSubmitProfileTag: undefined
      }
    case types.CLEAR_SUBMIT_PROFILE_TAG:
      return {
        ...state,
        loadingSubmitProfileTag: false,
        errorSubmitProfileTag: false,
        confirmedSubmitProfileTag: false
      }

    /* -- * -- * -- * -- * -- * -- * -- * -- */

    case types.CONFIRMED_EDIT_PROFILE_TAG:
      return {
        ...state,
        confirmedEditProfileTag: action.response,
        loadingEditProfileTag: false,
        errorEditProfileTag: undefined
      }
    case types.ERROR_EDIT_PROFILE_TAG:
      return {
        ...state,
        errorEditProfileTag: action.error,
        loadingEditProfileTag: false,
        confirmedEditProfileTag: undefined
      }
    case types.REQUEST_EDIT_PROFILE_TAG:
      return {
        ...state,
        loadingEditProfileTag: true,
        errorEditProfileTag: false,
        confirmedEditProfileTag: undefined
      }
    case types.CLEAR_EDIT_PROFILE_TAG:
      return {
        ...state,
        loadingEditProfileTag: true,
        errorEditProfileTag: false,
        confirmedEditProfileTag: undefined
      }

    /* -- * -- * -- * -- * -- * -- * -- * -- */

    case types.CONFIRMED_DELETE_PROFILE_TAG:
      return {
        ...state,
        confirmedDeleteProfileTag: action.response,
        loadingDeleteProfileTag: false,
        errorDeleteProfileTag: false
      }
    case types.ERROR_DELETE_PROFILE_TAG:
      return {
        ...state,
        errorDeleteProfileTag: action.error,
        loadingDeleteProfileTag: false,
        confirmedDeleteProfileTag: undefined
      }
    case types.REQUEST_DELETE_PROFILE_TAG:
      return {
        ...state,
        loadingDeleteProfileTag: true,
        confirmedDeleteProfileTag: undefined,
        errorDeleteProfileTag: false
      }
    case types.CLEAR_DELETE_PROFILE_TAG:
      return {
        ...state,
        loadingDeleteProfileTag: false,
        confirmedDeleteProfileTag: undefined,
        errorDeleteProfileTag: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    case types.CONFIRMED_DELETE_MULTI_PROFILE_TAGS:
      return {
        ...state,
        confirmedDeleteMultiProfileTags: action.response,
        loadingDeleteMultiProfileTags: false,
        errorDeleteMultiProfileTags: false
      }
    case types.ERROR_DELETE_MULTI_PROFILE_TAGS:
      return {
        ...state,
        errorDeleteMultiProfileTags: action.error,
        loadingDeleteMultiProfileTags: false,
        confirmedDeleteMultiProfileTags: undefined
      }
    case types.REQUEST_DELETE_MULTI_PROFILE_TAGS:
      return {
        ...state,
        loadingDeleteMultiProfileTags: true,
        confirmedDeleteMultiProfileTags: undefined,
        errorDeleteMultiProfileTags: false
      }
    case types.CLEAR_DELETE_MULTI_PROFILE_TAGS:
      return {
        ...state,
        loadingDeleteMultiProfileTags: false,
        confirmedDeleteMultiProfileTags: undefined,
        errorDeleteMultiProfileTags: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.CONFIRMED_FORMULA_AVATAR_UPLOAD:
      return {
        ...state,
        loadingUploadAvatar: false,
        errorUploadAvatar: false,
        uploadAvatarSuccess: action.url
      }
    case types.REQUEST_FORMULA_AVATAR_UPLOAD:
      return {
        ...state,
        loadingUploadAvatar: true,
        errorUploadAvatar: false,
        uploadAvatarSuccess: undefined
      }
    case types.ERROR_FORMULA_AVATAR_UPLOAD:
      return {
        ...state,
        loadingUploadAvatar: false,
        errorUploadAvatar: action.error,
        uploadAvatarSuccess: undefined
      }
    case types.CLEAR_FORMULA_AVATAR_UPLOAD:
      return {
        ...state,
        loadingUploadAvatar: false,
        errorUploadAvatar: false,
        uploadAvatarSuccess: undefined
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    default:
      return state
  }
}

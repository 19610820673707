import React from "react"
import { ApiError } from "../../../lib/jsonRequest/ApiError"
import { AppNavigation } from "./DoJobBoard"

export const ShowApiError: React.FC<{ error: ApiError }> = (props) => {
  return (
    <div className='d-flex justify-content-center align-items-center h-100'>
      <div className='component-error'>
        <h2>Error</h2>
        <span>{props.error.error}</span>
        <span>
          <a href={AppNavigation.userDashboard}>Go back</a>
        </span>
      </div>
    </div>
  )
}

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function profileReducer(state = initialState.profile, action) {
  switch (action.type) {
    case types.GET_PROFILE:
      return {
        ...state,
        loading: true,
        profile: action.profile,
        content: undefined,
        error: false,
      }
    case types.RECEIVE_PROFILE:
      return {
        ...state,
        loading: false,
        profile: action.profile,
        content: action.content,
        error: false,
      }
    case types.ERROR_GET_PROFILE:
      return {
        ...state,
        loading: false,
        error: action.error,
        profile: undefined,
        content: undefined,
      }
    case types.CLEAR_PROFILE_ERROR:
      return {
        ...state,
        error: undefined,
      }
    default:
      return state
  }
}

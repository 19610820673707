export function createMQLQuery(condition = "or", tagsObject: any, display = true) {
  /**
   * Negation system
   * nin
   * !=
   */
  let condIn = "in"
  let condE = "="
  if (!display) {
    condIn = "nin"
    condE = "!="
  }

  let EQ,
    SQ,
    FQ,
    CQ,
    PQ,
    QQ,
    NQ: any = false

  if (tagsObject.entityId && tagsObject.entityId.length > 0) {
    let entityIdQuery = `(entityId ${condIn} [`
    tagsObject.entityId.map((query: any, idx: any) => {
      return (entityIdQuery += `"${query}" `)
    })
    EQ = entityIdQuery.trim() + "])"
  }

  if (tagsObject.sourceId && tagsObject.sourceId.length > 0) {
    let sourceIdQuery = ""
    tagsObject.sourceId.map((query: any, idx: any) => {
      return (sourceIdQuery += `(sourceId ${condE} "${query}") `)
    })
    SQ = sourceIdQuery.trim()
  }

  if (tagsObject.formatTag && tagsObject.formatTag.length > 0) {
    let formatTagQuery = `(formatTag ${condIn} [`
    tagsObject.formatTag.map((query: any, idx: any) => {
      return (formatTagQuery += `"${query.value ? query.value : query}" `)
    })
    FQ = formatTagQuery.trim() + "])"
  }
  if (tagsObject.formatTags && tagsObject.formatTags.length > 0) {
    let formatTagQuery = `(formatTag ${condIn} [`
    tagsObject.formatTags.map((query: any, idx: any) => {
      return (formatTagQuery += `"${query.value ? query.value : query}" `)
    })
    FQ = formatTagQuery.trim() + "])"
  }

  if (tagsObject.contentTag && tagsObject.contentTag.length > 0) {
    let contentTagQuery = ""
    tagsObject.contentTag.map((query: any, idx: any) => {
      return (contentTagQuery += `(contentTag ${condE} "${query.value ? query.value : query}") `)
    })
    CQ = contentTagQuery.trim()
  }
  if (tagsObject.contentTags && tagsObject.contentTags.length > 0) {
    let contentTagQuery = ""
    tagsObject.contentTags.map((query: any, idx: any) => {
      return (contentTagQuery += `(contentTag ${condE} "${query.value ? query.value : query}") `)
    })
    CQ = contentTagQuery.trim()
  }

  if (tagsObject.profileTag && tagsObject.profileTag.length > 0) {
    let profileTagQuery = ""
    tagsObject.profileTag.map((query: any, idx: any) => {
      return (profileTagQuery += `(profileTag ${condE} "${query.value ? query.value : query}") `)
    })
    PQ = profileTagQuery.trim()
  }
  if (tagsObject.profileTags && tagsObject.profileTags.length > 0) {
    let profileTagQuery = ""
    tagsObject.profileTags.map((query: any, idx: any) => {
      return (profileTagQuery += `(profileTag ${condE} "${query.value ? query.value : query}") `)
    })
    PQ = profileTagQuery.trim()
  }

  if (tagsObject.categoryTag && tagsObject.categoryTag.length > 0) {
    let categoryTagQuery = ""
    tagsObject.categoryTag.map((query: any, idx: any) => {
      return (categoryTagQuery += `(categoryTag ${condE} "${query.value ? query.value : query}") `)
    })
    QQ = categoryTagQuery.trim()
  }
  if (tagsObject.categoryTags && tagsObject.categoryTags.length > 0) {
    let categoryTagQuery = ""
    tagsObject.categoryTags.map((query: any, idx: any) => {
      return (categoryTagQuery += `(categoryTag ${condE} "${query.value ? query.value : query}") `)
    })
    QQ = categoryTagQuery.trim()
  }

  if (tagsObject.nativeTags && tagsObject.nativeTags.length > 0) {
    let nativeTagQuery = ""
    tagsObject.nativeTags.map((query: any, idx: any) => {
      return (nativeTagQuery += `(contentTag ${condE} "${query.value ? query.value : query}") `)
    })
    NQ = nativeTagQuery.trim()
  }

  let mapQueries = [EQ, SQ, FQ, CQ, PQ, QQ, NQ]
  let finalQuery = `(${condition} `

  // eslint-disable-next-line
  mapQueries.map((query: any, idx: any) => {
    if (query) finalQuery += `${query} `
  })

  let finalMergeQuery = finalQuery.trim() + ")"

  return finalMergeQuery === `(${condition})` ? "" : finalMergeQuery
}

export function newQueryBuilder(condition = "or", tagsObject: any) {
  let EQ,
    SQ,
    FQ,
    CQ = false

  if (tagsObject.sources.length > 0) {
    let sourceIdQuery = ""
    for (let query of tagsObject.sources) {
      if (typeof query === "string") {
        sourceIdQuery += `(sourceId = "${query}") `
      } else {
        sourceIdQuery += `(sourceId = "${query.id}") `
      }
    }
    SQ = sourceIdQuery.trim()
  }

  return { EQ, SQ, FQ, CQ }
}

export function queryByPosts(obj: any) {
  let postsToQuery = "(postId in ["
  if (obj.content.formula.posts) {
    for (let post of obj.content.formula.posts) {
      postsToQuery += `"${post.id}" `
    }
  }
  postsToQuery = postsToQuery.trim()
  return (postsToQuery += "])")
}

import apiEnv from "./apiEnv"
import axios from "axios"
import { queryByPosts } from "../../store/shared-store/actions/mql/mqlHandler"
import { v4 as uuidv4 } from "uuid"
import { Json } from "../../lib/jsonRequest/JsonRequest"

export interface Token {
  access_token: string
}

export function getHubs(
  profileId: any,
  token: any,
  orderby = "created_at",
  limit = 12,
  order = "asc",
  search: any,
  page = 1
) {
  let url = `${apiEnv()}v1/profiles/${profileId}/hubs?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}`
  if (search) {
    url = `${apiEnv()}v1/profiles/${profileId}/hosts?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}&search=${search}`
  }
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function stagingPage(pageId: any, cache = false) {
  let domainUrl = `${apiEnv()}v1/published?pageId=${pageId}`
  if (cache) {
    domainUrl = `${apiEnv()}v1/published?pageId=${pageId}&cache=${cache}`
  }
  const request = new Request(domainUrl, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function reOrderObjects(profileId: any, object: any, eventId: any, token: any) {
  let domainUrl = `${apiEnv()}v1/profiles/${profileId}/objects/${object.parentId}/order?eventId=${eventId}`
  const request = new Request(domainUrl, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify({
      children: object.arrOrderedChilds,
      symbolId: object.symbolId,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function moveObject(profileId: any, object: any, eventId: any, token: any) {
  let domainUrl = `${apiEnv()}v1/profiles/${profileId}/objects/${object.uuid}/move/${
    object.parentId
  }?eventId=${eventId}`
  const request = new Request(domainUrl, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify({
      children: object.arrOrderedChilds,
      symbolId: object.symbolId,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function fetchHub(profile: any, uuid: any, token: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/walls2/${uuid}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      return response.json()
    })
    .catch((error: any) => {
      throw error
    })
}

export function searchByPosts(
  profileId: any,
  token: any,
  orderby = "created_at",
  limit = 20,
  order = "asc",
  search: any,
  until = ""
) {
  let url = `${apiEnv()}v1/mql/content/search?limit=${limit}&orderby=${orderby}&order=${order}&search=${search}&until=${until}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify({
      mql: `(profileLibrary in [${profileId}])`,
      profile: profileId,
      sort: "created",
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function createHost(
  profileId: any,
  name: any,
  url: any,
  txtRecord: any,
  themeColor: any,
  cookie: any,
  loader: any,
  token: any
) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/hosts`
  let obj = {
    name,
    url,
    txtRecordToken: txtRecord,
    "theme-color": themeColor,
    cookieWarning: cookie,
    loader,
  }
  const request = new Request(urlApi, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function fetchComponentsCatFam(
  profileId: any,
  categories: any,
  families: any,
  search: any,
  token: any,
  orderby = "created_at",
  limit = 50,
  order = "asc",
  page = 1
) {
  let urlApi
  if (search !== "") {
    urlApi = `${apiEnv()}v1/profiles/${profileId}/components2/search?search=${search}&limit=${limit}&orderby=${orderby}&order=${order}&page=${page}`
  } else {
    urlApi = `${apiEnv()}v1/profiles/${profileId}/components2/search?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}`
  }
  let obj = { categories, families }
  const request = new Request(urlApi, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function submitFavicon(
  profileId: any,
  favicon: any,
  apple: any,
  microsoft: any,
  android: any,
  seoImage: any,
  token: any
) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/objects/favicon`
  let favicons = new FormData()
  if (favicon !== "") {
    favicons.append("web", favicon)
  }
  if (apple !== "") {
    favicons.append("apple", apple)
  }
  if (microsoft !== "") {
    favicons.append("ms", microsoft)
  }
  if (android !== "") {
    favicons.append("android", android)
  }
  if (seoImage !== "") {
    favicons.append("og:image", seoImage)
  }
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token.access_token}`,
      "MeshApp-host": "meshapp",
    },
  }
  return axios
    .post(urlApi, favicons, headers)
    .then((response: any) => {
      if (response.status === 200) {
        return response.data
      }
      return response.then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function uploadOGImage(profileId: any, image: any, token: any) {
  let avatarForm = new FormData()
  avatarForm.append("image", image)
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token.access_token}`,
      "MeshApp-host": "meshapp",
    },
  }
  return axios
    .post(`${apiEnv()}v1/profiles/${profileId}/images`, avatarForm, headers)
    .then((response: any) => {
      if (response.status === 200) {
        return response
      }
      return response.then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function uploadIcon(profileId: any, image: any, templateId: any, token: any) {
  let avatarForm = new FormData()
  avatarForm.append("icon", image)
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token.access_token}`,
      "MeshApp-host": "meshapp",
    },
  }
  return axios
    .post(`${apiEnv()}v1/profiles/${profileId}/templates/${templateId}/icon`, avatarForm, headers)
    .then((response: any) => {
      if (response.status === 200) {
        return response
      }
      return response.then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function deleteUploadIcon(profileId: any, templateId: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/templates/${templateId}/icon`
  const request = new Request(urlApi, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function updateHost(
  profileId: any,
  hostId: any,
  cookie: any,
  color: any,
  url: any,
  token: any,
  objectId = false
) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/hosts/${hostId}`
  if (objectId) {
    urlApi = `${apiEnv()}v1/profiles/${profileId}/hosts/${hostId}/default/${objectId}`
  }
  let obj = { cookieWarning: cookie, "theme-color": color }
  const request = new Request(urlApi, {
    method: objectId ? "POST" : "PATCH",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function getHosts(
  profileId: string,
  limit = 10,
  orderby = "created_at",
  order = "asc",
  search: any,
  page: any,
  token: any
) {
  let url = `${apiEnv()}v1/profiles/${profileId}/hosts?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}`

  if (search) {
    url = `${apiEnv()}v1/profiles/${profileId}/hosts?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}&search=${search}`
  }
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function createAudience(profileId: any, name: any, type: any, min: any, max: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/audiences`
  let obj = { name, type, min, max }
  const request = new Request(urlApi, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function updateAudience(profileId: any, audienceId: any, name: any, type: any, min: any, max: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/audiences/${audienceId}`
  let obj = { name, type, min, max }
  const request = new Request(urlApi, {
    method: "PATCH",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function deleteAudience(profileId: any, audienceId: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/audiences/${audienceId}`
  const request = new Request(urlApi, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function getAudiences(
  profileId: any,
  limit = 10,
  orderby = "created_at",
  order = "asc",
  search: any,
  page: any,
  token: any
) {
  let url = `${apiEnv()}v1/profiles/${profileId}/audiences?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}`
  if (search) {
    url = `${apiEnv()}v1/profiles/${profileId}/audiences?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}&search=${search}`
  }
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function editHubTemplate(profileId: any, token: any, obj: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/templates`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function getCNAMEOutputters(urlCname: any, token: any) {
  let body = {
    url: urlCname,
  }
  let url = `${apiEnv()}v1/hosts/url/reserve`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function validateMeshAppUrl(urlMeshapp: any, token: any) {
  let body = {
    url: urlMeshapp,
  }
  let url = `${apiEnv()}v1/hosts/url/validate`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function validateUri(profileId: any, objectId: any, uri: any, token: any) {
  let body = { uri }
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${objectId}/uri`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function validateFirstLevelUri(profileId: any, hostId: any, uri: any, token: any) {
  let body = { uri, hostId }
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/uri`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function validateObjectUri(profileId: any, objectId: any, uri: any, token: any) {
  let body = { uri }
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${objectId}/uri`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function publishPage(profileId: any, pageId: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/pages/${pageId}/publish2`
  const request = new Request(urlApi, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function unpublishPage(profileId: any, pageId: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/pages/${pageId}/publish`
  const request = new Request(urlApi, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function publishSitePages(siteId: any, exceptions: any, profileId: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/sites/${siteId}/publishOutdated`
  let body = { exceptions }
  const request = new Request(urlApi, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function publishSitePagesStatus(siteId: any, profileId: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/sites/${siteId}/publishingStatus`
  const request = new Request(urlApi, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

/**
 * Deprecated ?
 * @param {*} profileId
 * @param {*} objectId - This is the parent ID of the object to be created
 * @param {*} uuid - The id created by the object itself
 * @param {*} order
 * @param {*} createBulk - This is a condition for when a child is going to be created by a new object itself
 * not yet avaiable in the backend
 * @param {*} uri
 * @param {*} name
 * @param {*} componentId
 * @param {*} formulaId
 * @param {*} token
 */
export function createObject(profileId: any, object: any, publish: any, eventId: any, token: any, hubUuid: any) {
  /**
   * The uuid is used for the formulaId, but if the object
   * is created without a formula we send the objectUuid instead
   */
  let obj = {
    uuid: object.objectUuid,
    uri: object.uri,
    order: parseInt(object.order, 10),
    name: object.objectLabel,
    description: object.description,
    objectId: object.parentObjectUuid, //API INCONSISTENCY: same as parentId, on object create we're using objectId
    formulaId: object.uuid,
    bulkCreate: true,
    firstObjectId: object.type !== "objectTemplate" ? hubUuid : object.content[0].objectUuid,
    styles: {
      componentId: object.styles.component.uuid,
      bobs: object.styles.bobs,
      component: object.styles.component,
      uuid: object.styles.uuid,
    },
  }
  let url = `${apiEnv()}v1/profiles/${profileId}/objects3?publish2=${publish}&eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

/**
 *
 * @param {*} profileId
 * @param {*} objectId - This is the parent ID of the object to be created
 * @param {*} uuid - The id created by the object itself
 * @param {*} order
 * @param {*} createBulk - This is a condition for when a child is going to be created by a new object itself
 * not yet avaiable in the backend
 * @param {*} uri
 * @param {*} name
 * @param {*} componentId
 * @param {*} formulaId
 * @param {*} token
 */
export function createObjectServerSide(profileId: any, obj: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects3?eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function updateObject(profileId: any, object: any, eventId: any, token: any) {
  let obj = {
    uri: object.uri,
    order: parseInt(object.order),
    name: object.label,
    description: object.description ? object.description : undefined,
    parentId: object.parentObjectUuid,
    hidden: object.hidden,
    symbolId: object.fromSymbolTree,
    isContentBlock: object.isContentBlock ? object.isContentBlock : false,
    hiddenConditionField: object.hiddenConditionField
      ? object.hiddenConditionField
      : object.hiddenConditionField === ""
      ? null
      : undefined,
    isSection: object.isSection,
  }
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${object.objectUuid}?eventId=${eventId}`
  const request = new Request(url, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function updateObjectContent(profileId: any, object: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${object.objectUuid}/content?eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(object.content),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function updateObjectSettings(profileId: any, object: any, eventId: any, token: any) {
  let obj = { settings: object.settings }
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${object.objectUuid}/settings?eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function updatePageObject(profileId: any, pageObject: any, eventId: any, token: any) {
  let obj = {
    uri: pageObject.uri,
    name: pageObject.objects[pageObject.rootId].label,
    description: pageObject.description,
    hidden: pageObject.hidden,
  }

  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${pageObject.rootId}?eventId=${eventId}`
  const request = new Request(url, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function updatePageSettings(pageId: string, settings: { [key: string]: string }, profile: string, token: any) {
  let body = { settings }
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/pages/${pageId}/settings/`, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

function doJsonRequest(path: string, verb: string, body: Json, token: Token): Promise<any> {
  const request = new Request(`${apiEnv()}${path}`, {
    method: verb,
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function setContentPage(profileName: string, pageObjectId: string, token: Token) {
  const path = `v1/profiles/${profileName}/setContentPage/${pageObjectId}`
  return doJsonRequest(path, "POST", {}, token)
}

export function getObjects(
  profileId: any,
  token: any,
  search: any,
  page = 1,
  limit = 10,
  orderby = "created_at",
  order = "asc"
) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects?page=${page}&limit=${limit}&orderby=${orderby}&order=${order}`
  if (search && search !== "")
    url = `${apiEnv()}v1/profiles/${profileId}/objects?search=${search}&page=${page}&limit=${limit}&orderby=${orderby}&order=${order}`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function getObjectTree(profileId: any, objectId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${objectId}/tree`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function createFirstObject(
  profileId: any,
  hostId: any,
  siteId: any,
  uuid: any,
  order = 0,
  uri: any,
  name: any,
  articleLayout: any,
  pageTemplate: any,
  token: any
) {
  let obj = {
    uri: uri,
    order: order,
    name: name,
    hostId: hostId,
    siteId: siteId,
    uuid: uuid,
    articleLayout: articleLayout,
  }
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/host`
  /**
   * When the pageTemplate exists, the action was
   * made from a template, so this new page will have the template
   * has the default structure
   */
  if (pageTemplate && pageTemplate !== "") {
    url = `${apiEnv()}v1/profiles/${profileId}/pages/templates/${pageTemplate.uuid}`
  }
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function clonePage(
  pageId: any,
  hostId: any,
  siteId: any,
  uuid: any,
  order = 0,
  uri: any,
  name: any,
  articleLayout: any,
  pageTemplate: any,
  eventId: any,
  profileId: any,
  token: any,
  isTemplate?: boolean
) {
  let obj = {
    uri: uri,
    order: order,
    name: name,
    hostId: hostId,
    siteId: siteId,
    uuid: uuid,
    articleLayout: articleLayout,
  }
  let url = `${apiEnv()}v1/profiles/${profileId}/pages/${pageId}/clone2?eventId=${eventId}&template=${
    isTemplate ? true : false
  }`
  /**
   * When the pageTemplate exists, the action was
   * made from a template, so this new page will have the template
   * has the default structure
   */
  if (pageTemplate && pageTemplate !== "") {
    url = `${apiEnv()}v1/profiles/${profileId}/pages/templates/${pageTemplate.uuid}?eventId=${eventId}`
  }
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function addChildOf(profileId: any, objectId: any, subObjectId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${objectId}/objects/${subObjectId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function addFormulaToObject(profileId: any, object: any, publish: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${object.objectUuid}/formulas/${
    object.content.formula.uuid
  }?publish2=${publish}&eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function addDataSetToObject(profileId: any, object: any, datasetId: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${object.objectUuid}/dataset/${datasetId}?eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function addComponentToObject(profileId: any, object: any, publish: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${object.objectUuid}/components2/${
    object.styles.component.uuid
  }?publish2=${publish}&eventId=${eventId}`
  let obj = {
    keepStyles: object.keepStyles,
  }
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function applyComponentToObjectServerSide(profileId: any, object: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${object.objectUuid}/applyComponentChange2?eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function addComponentToObjectServerSide(profileId: any, object: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${object.objectUuid}/components2/${
    object.newComponentId
  }/temporaryState2?keepStyles=${object.keepStyles}&eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function clearAddComponentToObject(profileId: any, objectId: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${objectId}/clearComponentState?eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function undoAddComponentToObject(profileId: any, objectId: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${objectId}/cancelComponentChange2?eventId=${eventId}`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function fetchFormulaContentEM(profileId: any, formulaId: any, token: any, until: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/formulas/${formulaId}/content?until=${until}`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function fetchMoreFormulaContentEM(profileId: any, formulaId: any, token: any, until: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/formulas/${formulaId}/content?until=${until}`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function fetchFormulaInfo(profileId: any, formulaId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/formulas/${formulaId}`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function checkBehaviourUrl(profileId: any, templateId: any, firstObjectUuid: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/templates/${templateId}/behaviourUrl/${firstObjectUuid}`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function fetchObjectFormulaContentEM(profileId: any, objectId: any, formulaId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${objectId}/formula/${formulaId}`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function submitFormulaWithQuery(profileId: any, object: any, publish: any, eventId: any, token: any) {
  let query = queryByPosts(object)

  let objToSubmit = {
    name: object.content.formula.label,
    description: "",
    isPublic: false,
    uri: uuidv4(),
    limit: object.content.formula.posts.length,
    objectFormula: true,
    object: object.objectUuid,
    expression: {
      expression: query,
      sort: "experience_order",
      limit: object.content.formula.posts.length,
      id: "",
    },
    symbolId: object.fromSymbolTree,
  }
  /**
   * We check for an uuid since this is the id for the formula
   * used in this object.
   * When this (uuid) exists the selection of posts performs an update (PATCH) to the current
   * formula in order to save the changes made.
   * If it doesnt have an uuid it means we are dealing with a new formula (POST)
   */
  if (object.content.formula.uuid) {
    const request = new Request(
      `${apiEnv()}v1/profiles/${profileId}/formulas/${
        object.content.formula.uuid
      }?publish2=${publish}&eventId=${eventId}`,
      {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          "MeshApp-host": "meshapp",
          Authorization: `Bearer ${token.access_token}`,
        }),
        body: JSON.stringify(objToSubmit),
      }
    )
    return fetch(request)
      .then((response: any) => {
        if (response.status === 200) {
          return response.json()
        }
        return response.json().then((response: any) => {
          throw response
        })
      })
      .catch((error: any) => {
        throw error
      })
  } else {
    const request = new Request(`${apiEnv()}v1/profiles/${profileId}/formulas?publish2=${publish}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "MeshApp-host": "meshapp",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(objToSubmit),
    })
    return fetch(request)
      .then((response: any) => {
        if (response.status === 200) {
          return response.json()
        }
        return response.json().then((response: any) => {
          throw response
        })
      })
      .catch((error: any) => {
        throw error
      })
  }
}

export function createTemplate(profileId: any, objContent: any, token: any) {
  let obj = {
    name: objContent.name,
    "color-first": objContent.colorFirst,
    "color-second": objContent.colorSecond,
    "color-background": objContent.colorBg,
    "color-backgroundAlt": objContent.colorBgAlt,
    "behaviour-url": objContent.behaviourUrl,
    "size-row": objContent.sizeRow,
    "size-col": objContent.sizeCol,
    bob: objContent.template.bob ? JSON.stringify(objContent.template.bob) : "",
    content: objContent.template.content ? JSON.stringify(objContent.template.content) : "",
    "font-size": objContent.fontSize,
    "font-color": objContent.fontColor,
    "font-family": objContent.fontFamily,
    "font-urls": objContent.fontUrls,
    wrap: objContent.wrap,
    flow: objContent.flow,
    limit: objContent.limit,
    "behaviour-action": objContent.behaviourActions,
    "behaviour-display": objContent.behaviourDisplay,
    preview: objContent.preview,
    icon: objContent.icon,
  }
  let url = `${apiEnv()}v1/profiles/${profileId}/templates`
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(obj),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function fetchFormulas(
  profileId: any,
  token: any,
  orderby = "name",
  limit = 20,
  order = "asc",
  search: any,
  page = 1
) {
  let url = `${apiEnv()}v1/profiles/${profileId}/formulas?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}`
  if (search) {
    url = `${apiEnv()}v1/profiles/${profileId}/formulas?limit=${limit}&orderby=${orderby}&order=${order}&search=${search}`
  }
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function componentsFamilies(
  profileId: any,
  token: any,
  orderby = "name",
  limit = 20,
  order = "asc",
  search: any,
  page = 1
) {
  let url = `${apiEnv()}v1/profiles/${profileId}/components-families?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}`
  if (search) {
    url = `${apiEnv()}v1/profiles/${profileId}/components-families?limit=${limit}&orderby=${orderby}&order=${order}&search=${search}`
  }
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function componentsCategories(
  profileId: any,
  token: any,
  orderby = "order",
  limit = 50,
  order = "asc",
  search: any,
  page = 1
) {
  let url = `${apiEnv()}v1/profiles/${profileId}/components/categories2?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}`
  if (search) {
    url = `${apiEnv()}v1/profiles/${profileId}/components/categories2?limit=${limit}&orderby=${orderby}&order=${order}&search=${search}`
  }
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function componentsFromFamilies(
  profileId: any,
  familyId: any,
  token: any,
  orderby = "name",
  limit = 20,
  order = "asc",
  search: any,
  page = 1
) {
  let url = `${apiEnv()}v1/profiles/${profileId}/components-families/${familyId}/components?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}`
  if (search) {
    url = `${apiEnv()}v1/profiles/${profileId}/components-families/${familyId}/components?limit=${limit}&orderby=${orderby}&order=${order}&search=${search}`
  }
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function componentById(componentUuid: any) {
  let url = `${apiEnv()}v1/components/${componentUuid}`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function fetchTemplates(
  profileId: any,
  token: any,
  orderby: string = "created_at",
  limit: number = 20,
  order: string = "asc",
  search: any,
  page = 1
) {
  let url = `${apiEnv()}v1/components?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}`
  if (search) {
    url = `${apiEnv()}v1/components?limit=${limit}&orderby=${orderby}&order=${order}&search=${search}`
  }
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function articleLayout(profileId: any, templateId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/templates/${templateId}/articleLayout`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function deleteObjectFormula(profileId: any, object: any, publish: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${object.objectUuid}/formulas/${
    object.content.formula.uuid
  }?publish2=${publish}&eventId=${eventId}`
  const request = new Request(url, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function deleteObject(profileId: any, object: any, publish: any, eventId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${object.objectUuid}?publish2=${publish}&parentId=${
    object.parentId
  }&eventId=${eventId}`
  if (object.fromSymbolTree) {
    url = `${apiEnv()}v1/profiles/${profileId}/objects/${object.objectUuid}?publish2=${publish}&parentId=${
      object.parentId
    }&eventId=${eventId}&symbolId=${object.fromSymbolTree}`
  }
  const request = new Request(url, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function deleteEntireExperience(profileId: any, objectId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${objectId}/home`
  const request = new Request(url, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function checkHub(id: any, uuid: any, token: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${id}/walls2/${uuid}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function editExperienceManagerTemplate(profileId: any, object: any, publish: any, eventId: any, token: any) {
  // 'icon': content.icon,
  let body = {
    name: object.styles.component.name,
    bob: object.styles.bobs,
    preview: object.styles.component.preview,
    symbolId: object.fromSymbolTree,
  }
  let url = `${apiEnv()}v1/profiles/${profileId}/styles/${object.styles.uuid}?publish2=${publish}&eventId=${eventId}`
  const request = new Request(url, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function requestLayouts(profile: any, token: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/layouts`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function resetHubCache(profile: any, hubId: any, token: any) {
  const request = new Request(`${apiEnv()}/v1/profiles/${profile}/objects/${hubId}/reset2`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function requestSelectedLayout(profile: any, layoutId: any, token: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/layouts/${layoutId}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function uploadedFonts(profile: any, token: any, page: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/settings/fonts?page=${page}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function deleteFont(fontId: any, profile: any, token: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/settings/fonts/${fontId}`, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function uploadedFontsEM(profile: any, token: any, limit: number, page: number, search?: string) {
  const url = `${apiEnv()}v1/profiles/${profile}/customFonts2?limit=${limit}&page=${page}${
    search ? `search=${search}` : ""
  }`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function requestDeleteHost(profile: any, hostId: any, token: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/hosts/${hostId}`, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function registerDomainGoogle(profile: any, hostId: any, token: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/hosts/${hostId}/site/google`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function sendSitemap(profile: any, hostId: any, token: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/hosts/${hostId}/sitemap/google`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function validateGoogle(profile: any, hostId: any, token: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/hosts/${hostId}/site/google/validate`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function sourceToPost(profile: any, url: any, token: any) {
  let body = {
    url,
  }
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/sources/singlePost`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function postsExperienceOrder(profile: any, object: any, publish: any, eventId: any, token: any) {
  let arrPosts = object.content.formula.posts
  let posts = arrPosts.map((post: any, idx: any) => {
    return { id: post.id, order: idx }
  })
  const request = new Request(
    `${apiEnv()}v1/profiles/${profile}/items/experienceOrder?publish2=${publish}&eventId=${eventId}`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify({
        items: [...posts],
        symbolId: object.fromSymbolTree,
      }),
    }
  )
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function copyPasteSS(
  copiedId: any,
  pastedParentObj: any,
  order: any,
  withContent: any,
  eventId: any,
  profile: any,
  token: any
) {
  let body = {
    order,
    withContent,
    parentId: pastedParentObj.objectUuid,
  }
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/objects/${copiedId}/copypaste3?eventId=${eventId}`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function copyPasteStylesSS(copiedId: any, pastedId: any, profile: any, token: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${profile}/objects/${copiedId}/copyPasteStyles/${pastedId}`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function enableBehaviourState(
  stylesId: any,
  bobId: any,
  behaviourType: any,
  stylesObject: any,
  profileId: any,
  token: any
) {
  const request = new Request(
    `${apiEnv()}v1/profiles/${profileId}/styles/${stylesId}/bob/${bobId}/behaviours/${behaviourType}`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token.access_token}`,
      }),
      body: JSON.stringify(stylesObject),
    }
  )
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function createObjectTemplate(
  profileId: any,
  templateUuid: any,
  createFrom: any,
  lastChildToParentId: any,
  afterBrotherId: any,
  eventId: any,
  token: any
) {
  let url = `${apiEnv()}v1/profiles/${profileId}/objects/${createFrom}/${templateUuid}?eventId=${eventId}`
  let body = {
    lastChildToParentId,
    afterBrotherId,
  }
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function publishObjectTemplate(profileId: any, oTemplateId: any, categoryName: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/oTemplates/${oTemplateId}/publish/platform`
  let body = {
    categoryName: categoryName,
  }
  const request = new Request(urlApi, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function unpublishObjectTemplate(profileId: any, oTemplateId: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/oTemplates/${oTemplateId}/publish/platform`
  const request = new Request(urlApi, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function updateObjectTemplate(object: any, profileId: any, token: any, eventId: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/oTemplates/${object.uuid}?eventId=${eventId}`
  let body = {
    name: object.label,
  }
  const request = new Request(url, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function deleteObjectTemplate(profileId: any, objectId: any, token: any) {
  let url = `${apiEnv()}v1/profiles/${profileId}/oTemplates/${objectId}`
  const request = new Request(url, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function disableBehaviourState(stylesId: any, bobId: any, behaviourType: any, profileId: any, token: any) {
  const request = new Request(
    `${apiEnv()}v1/profiles/${profileId}/styles/${stylesId}/bob/${bobId}/behaviours/${behaviourType}`,
    {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token.access_token}`,
      }),
    }
  )
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function stagingObjectTemplateById(templateId: any, token: any) {
  const request = new Request(`${apiEnv()}v1/oTemplates/${templateId}/printPreview2?token=${token}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function stagingPublishedObjectTemplateById(publishedOTemplateId: any, token: any) {
  const request = new Request(
    `${apiEnv()}v1/publishedOTemplates/${publishedOTemplateId}/printPreview2?token=${token}`,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }
  )
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function stagingSymbolById(symbolId: any, token: any) {
  const request = new Request(`${apiEnv()}v1/symbols/${symbolId}/printPreview?token=${token}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function isPagePublished(pageId: any, profileId: any, token: any) {
  const request = new Request(`${apiEnv()}v1/profiles/${profileId}/pages/${pageId}/status`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function objectTemplateScreenShot(profileId: any, oTemplateId: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/oTemplates/${oTemplateId}/preview`
  const request = new Request(urlApi, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function getPlatformPageTemplatesApi(profileId: any, token: any) {
  let urlApi = `${apiEnv()}v1/platform/pageTemplates`
  const request = new Request(urlApi, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function createFluxioPost(post: any, profileId: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/content/posts`
  let body = {
    title: post.title,
    picture: post.image,
    description: post.description,
    message: post.message,
    external_link: post.url,
    experience_order: post.experience_order !== "" ? post.experience_order : null,
    profileTags: post.profileTags,
  }
  const request = new Request(urlApi, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function updateFluxioPost(post: any, profileId: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/content/posts/${post.sourceId}`
  let body = {
    title: post.title,
    picture: post.image,
    description: post.description,
    message: post.message,
    external_link: post.url,
    experience_order: post.experience_order !== "" ? post.experience_order : null,
    profileTags: post.profileTags,
  }
  const request = new Request(urlApi, {
    method: "PATCH",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function deleteFluxioPost(postId: any, profileId: any, token: any) {
  let urlApi = `${apiEnv()}v1/profiles/${profileId}/content/posts/${postId}`
  const request = new Request(urlApi, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function fetchPageLinks(
  profileId: any,
  objectId: string,
  token: any,
  orderby = "created_at",
  limit = 20,
  order = "asc",
  search = "",
  page = 1
) {
  const url = `${apiEnv()}v1/profiles/${profileId}/objects/${objectId}/linkPages?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}${
    search !== "" ? `&search=${search}` : ""
  }`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "MeshApp-host": `${profileId}`,
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function getDomainPages(
  hostId: string,
  profile: string,
  token: any,
  orderby = "created_at",
  limit = 20,
  order = "asc",
  search = "",
  page = 1
) {
  const url = `${apiEnv()}v1/profiles/${profile}/hosts/${hostId}/pages?limit=${limit}&orderby=${orderby}&order=${order}&page=${page}${
    search !== "" ? `&search=${search}` : ""
  }`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function fetchMappedFieldsApi(
  profile: string,
  bobType: string,
  variant: string,
  postId: string | undefined,
  token: any
) {
  const url = `${apiEnv()}v1/profiles/${profile}/mappedValues?bobType=${bobType}&variant=${variant}${
    postId ? `&postId=${postId}` : ""
  }`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function fetchMappedFieldsExtraApi(profile: string, fieldType: string, token: any) {
  const url = `${apiEnv()}v1/profiles/${profile}/mappedValues?fieldType=${fieldType}`
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function setObjectMappedFieldApi(profile: string, objectId: string, field: string, token: any) {
  const url = `${apiEnv()}v1/profiles/${profile}/objects/${objectId}/mappedField`
  const body = { mappedField: field }
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function unsetObjectMappedFieldApi(profile: string, objectId: string, token: any) {
  const url = `${apiEnv()}v1/profiles/${profile}/objects/${objectId}/mappedField`
  const request = new Request(url, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function setObjectCPSrcApi(profile: string, objectId: string, token: any) {
  const url = `${apiEnv()}v1/profiles/${profile}/objects/${objectId}/contentPageSrc`
  const body = {}
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
    body: JSON.stringify(body),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

export function unsetObjectCPSrcApi(profile: string, objectId: string, token: any) {
  const url = `${apiEnv()}v1/profiles/${profile}/objects/${objectId}/contentPageSrc`
  const request = new Request(url, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token.access_token}`,
    }),
  })
  return fetch(request)
    .then((response: any) => {
      if (response.status === 200) {
        return response.json()
      }
      return response.json().then((response: any) => {
        throw response
      })
    })
    .catch((error: any) => {
      throw error
    })
}

import React, { useEffect, useState } from "react"
import { ProfileName, JobRunId, FluxioPostId, PostTree } from "./jobsSchema"
import PostTreeC from "./postTreeC"
import { JobsApi } from "./jobsApi"
import TwoLayout from "./twoLayout"
import { DocumentViewerActions } from "./documentViewerActions"
import { PostTreeUtil } from "./postTreeUtil"
import ContentTreePanel from "./contentTreePanel"
import { Chats } from "./chats"
import NavigationPanel from "./navigationPanel"

interface OwnProps {
  jobsClient: JobsApi
  jobRunId?: JobRunId
  profileName: ProfileName
  handleJumpToDocument: (jobRunId: JobRunId) => void
}

type DocumentRunViewerProps = OwnProps

function DocumentRunViewer(props: DocumentRunViewerProps): JSX.Element {
  const [postTree, setPostTree] = useState<PostTree[] | undefined>(undefined)

  useEffect(() => {
    if (!props.jobRunId) return
    else {
      props.jobsClient.jobRunContent(props.jobRunId).then((s) => setPostTree(s.posts))
    }
  }, [props.jobRunId, props.jobsClient])

  const [selectedPost, setSelectedPost] = useState<FluxioPostId | undefined>(undefined)

  const handleSelectPost = (postId?: FluxioPostId) => {
    setSelectedPost(postId ? postId : undefined)
  }

  const actions: DocumentViewerActions.All = {
    addAsChild(post: FluxioPostId, description: string): Promise<void> {
      const newPost = { title: "", description: description }
      return props.jobsClient.createPostChild(post, newPost).then((created) => {
        const child: PostTree = { id: created.id, ...newPost }
        setPostTree((prev) => PostTreeUtil.appendChild(prev ?? [], post, child))
      })
    },
    removePost(post: FluxioPostId): Promise<void> {
      return props.jobsClient.deletePost(post).then(() => setPostTree((prev) => PostTreeUtil.remove(prev ?? [], post)))
    },
  }

  const updatePost = (id: FluxioPostId, p: PostTree) => {
    setPostTree((prev) => PostTreeUtil.replacePost(prev ?? [], id, p))
  }

  if (!props.jobRunId) {
    return (
      <div className='jobs-main-panel'>
        <TwoLayout
          contentTreePanel={
            <NavigationPanel
              jobsClient={props.jobsClient}
              handleJumpToDocument={props.handleJumpToDocument}
            ></NavigationPanel>
          }
          content={<span>Select a document ....</span>}
          assistantPanel={
            <Chats
              profileName={props.profileName}
              postId={selectedPost}
              jobsClient={props.jobsClient}
              actions={actions}
            />
          }
        ></TwoLayout>

        <div></div>
      </div>
    )
  }
  // request failed
  if (postTree === undefined) return <></>
  //return header with a dropdown of runs to choose. then bellow we will show the recurrent component for rendering a post tree
  return (
    <div className='jobs-main-panel'>
      <TwoLayout
        contentTreePanel={<ContentTreePanel postTree={postTree}></ContentTreePanel>}
        content={
          <RunContent
            posts={postTree}
            jobsClient={props.jobsClient}
            jobRunId={props.jobRunId}
            profileName={props.profileName}
            handleSelectPost={handleSelectPost}
            selectedPost={selectedPost}
            updatePost={updatePost}
            actions={actions}
          ></RunContent>
        }
        assistantPanel={
          <Chats
            profileName={props.profileName}
            postId={selectedPost}
            jobsClient={props.jobsClient}
            actions={actions}
          />
        }
      ></TwoLayout>

      <div></div>
    </div>
  )
}

interface RunContentProps {
  posts: PostTree[]
  profileName: ProfileName
  jobRunId: JobRunId
  jobsClient: JobsApi
  handleSelectPost: (postId?: FluxioPostId) => void
  selectedPost?: FluxioPostId
  updatePost: (id: FluxioPostId, p: PostTree) => void
  actions: DocumentViewerActions.RemovePost
}

function RunContent(props: RunContentProps): JSX.Element {
  return (
    <div className='main-panel-full'>
      {props.posts.map((post, pkey) => (
        <div key={post.id} className='topic-tree-background'>
          <PostTreeC
            key={pkey}
            post={post}
            jobsClient={props.jobsClient}
            profileName={props.profileName}
            handleSelectPost={props.handleSelectPost}
            selectedPost={props.selectedPost}
            updatePost={props.updatePost}
            actions={props.actions}
          ></PostTreeC>
        </div>
      ))}
    </div>
  )
}
export default DocumentRunViewer

import { Workspace } from "../skynet/profileTypes"

export class Access {
  static _defaultAccessFluxio = true
  static _defaultAccessDoJob = true

  static canAccessFluxio(ws: Workspace): boolean {
    return ws?.plans ? ws.plans.allowFluxio : this._defaultAccessFluxio
  }

  static canAccessDoJob(ws: Workspace): boolean {
    return ws?.plans ? ws.plans.allowDoJob : this._defaultAccessDoJob
  }

  static canAccessSingleApp(ws: Workspace): boolean {
    return this.canAccessFluxio(ws) !== this.canAccessDoJob(ws)
  }

  static access(ws: Workspace): { fluxio: boolean; doJob: boolean } {
    return {
      fluxio: this.canAccessFluxio(ws),
      doJob: this.canAccessDoJob(ws),
    }
  }
}

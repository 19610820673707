import * as types from "../shared-store/actions/actionTypes"
import initialState from "../initialState"
import cloneDeep from "lodash/cloneDeep"
import { AnyAction } from "redux"

export default function skynetReducer(
  state = initialState.skynetReducer,
  action: AnyAction
) {
  switch (action.type) {
    case types.SELECTED_FORMULA_TO_EDIT:
      return {
        ...state,
        formula: action.formula,
        createFormula: false,
      }
    case types.CREATE_NEW_FORMULA:
      return {
        ...state,
        createFormula: true,
        formula: undefined,
      }
    case types.CLEAR_FORMULA_BENCH:
      return {
        ...state,
        formula: undefined,
        createFormula: false,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_SOURCES_LIST:
      return {
        ...state,
        loadingSources: true,
        errorSources: false,
      }
    case types.RECEIVE_SOURCES_LIST:
      return {
        ...state,
        sourcesList: action.response,
        loadingSources: false,
        errorSources: false,
      }
    case types.ERROR_FETCH_SOURCES_LIST:
      return {
        ...state,
        loadingSources: false,
        errorSources: true,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_FORMAT_TAGS_LIST:
      return {
        ...state,
        loadingFormatTags: true,
        errorFormatTags: false,
        formatTagsList: undefined,
        formatTagsQueryFetched: action.query,
      }
    case types.RECEIVE_FORMAT_TAGS_LIST:
      return {
        ...state,
        formatTagsList: action.response,
        loadingFormatTags: false,
        errorFormatTags: false,
        formatTagsQueryFetched: action.query,
      }
    case types.ERROR_FETCH_FORMAT_TAGS_LIST:
      return {
        ...state,
        loadingFormatTags: false,
        errorFormatTags: true,
        formatTagsList: undefined,
        formatTagsQueryFetched: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_GENERATED_TAGS_LIST:
      return {
        ...state,
        loadingGeneratedTags: true,
        errorGeneratedTags: false,
        generatedTagsList: undefined,
        generatedTagsQueryFetched: action.query,
      }
    case types.RECEIVE_GENERATED_TAGS_LIST:
      return {
        ...state,
        generatedTagsList: action.response,
        loadingGeneratedTags: false,
        errorGeneratedTags: false,
        generatedTagsQueryFetched: action.query,
      }
    case types.ERROR_FETCH_GENERATED_TAGS_LIST:
      return {
        ...state,
        loadingGeneratedTags: false,
        errorGeneratedTags: true,
        generatedTagsList: undefined,
        generatedTagsQueryFetched: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_PROFILE_TAGS_LIST:
      return {
        ...state,
        loadingProfileTags: true,
        errorProfileTags: false,
        profileTagsList: undefined,
        profileTagsQueryFetched: action.query,
      }
    case types.RECEIVE_PROFILE_TAGS_LIST:
      return {
        ...state,
        profileTagsList: action.response,
        loadingProfileTags: false,
        errorProfileTags: false,
        profileTagsQueryFetched: action.query,
      }
    case types.ERROR_FETCH_PROFILE_TAGS_LIST:
      return {
        ...state,
        loadingProfileTags: false,
        errorProfileTags: true,
        profileTagsList: undefined,
        profileTagsQueryFetched: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_NATIVE_TAGS_LIST:
      return {
        ...state,
        loadingNativeTags: true,
        errorNativeTags: false,
        nativeTagsList: undefined,
        nativeTagsQueryFetched: action.query,
      }
    case types.RECEIVE_NATIVE_TAGS_LIST:
      return {
        ...state,
        nativeTagsList: action.response,
        loadingNativeTags: false,
        errorNativeTags: false,
        nativeTagsQueryFetched: action.query,
      }
    case types.ERROR_FETCH_NATIVE_TAGS_LIST:
      return {
        ...state,
        loadingNativeTags: false,
        errorNativeTags: true,
        nativeTagsList: undefined,
        nativeTagsQueryFetched: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_CATEGORY_TAGS_LIST:
      return {
        ...state,
        loadingCategoryTags: true,
        errorCategoryTags: false,
        categoryTagsList: undefined,
        categoryTagsQueryFetched: action.query,
      }
    case types.RECEIVE_CATEGORY_TAGS_LIST:
      return {
        ...state,
        categoryTagsList: action.response,
        loadingCategoryTags: false,
        errorCategoryTags: false,
        categoryTagsQueryFetched: action.query,
      }
    case types.ERROR_FETCH_CATEGORY_TAGS_LIST:
      return {
        ...state,
        loadingCategoryTags: false,
        errorCategoryTags: true,
        categoryTagsList: undefined,
        categoryTagsQueryFetched: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.group,
        selectedGroupListType: action.listType,
        selectedGroupTags: action.tags,
        selectedGroupParentId: action.groupParentId,
      }
    case types.CLEAR_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: undefined,
        selectedGroupListType: undefined,
        selectedGroupTags: undefined,
        selectedGroupParentId: undefined,
      }
    case types.ADD_SOURCES_TO_GROUP:
      return {
        ...state,
        sourcesToAdd: action.sourceToAdd,
      }
    case types.CLEAR_ADD_SOURCES_TO_GROUP:
      return {
        ...state,
        sourcesToAdd: undefined,
      }
    case types.ADD_TAGS_TO_GROUP:
      return {
        ...state,
        tagsToAdd: action.tagToAdd,
      }
    case types.CLEAR_ADD_TAGS_TO_GROUP:
      return {
        ...state,
        tagsToAdd: undefined,
      }
    case types.DISPLAY_SOURCES_TAGS_LIST:
      return {
        ...state,
        displaySTList: action.displayBool,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_SITES_LIST:
      return {
        ...state,
        sitesList: undefined,
        loadingSitesList: true,
        errorSitesList: undefined,
      }
    case types.RECEIVE_SITES_LIST:
      /**
       * use server response to populate the siteList
       * or update the siteList with an edited site object
       * else no response and no updateObj, add new site object to the siteList
       */
      let sitesList
      if (action.response) sitesList = action.response
      if (!action.response && !action.updateObject) {
        //@ts-ignore
        sitesList = cloneDeep(state.sitesList)
        //@ts-ignore
        if (state.createdSite)
          sitesList.data =
            sitesList.data && sitesList.data.length > 0
              ? //@ts-ignore
                [...sitesList.data, state.createdSite]
              : //@ts-ignore
                [state.createdSite]
      }
      return {
        ...state,
        sitesList: sitesList,
        loadingSitesList: false,
        errorSitesList: undefined,
      }
    case types.ERROR_FETCH_SITES_LIST:
      return {
        ...state,
        sitesList: undefined,
        loadingSitesList: false,
        errorSitesList: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.UPDATE_SITES_LIST:
      //@ts-ignore
      let sitesListClone = cloneDeep(state.sitesList)
      let list = sitesListClone.data
      for (let key in list) {
        if (list[key].uuid === action.updatedSingleSite.uuid) list[key] = action.updatedSingleSite
      }
      return {
        ...state,
        sitesList: sitesListClone,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_GROUPS_LIST:
      return {
        ...state,
        groupsList: undefined,
        loadingGroupsList: true,
        errorGroupsList: undefined,
      }
    case types.RECEIVE_GROUPS_LIST:
      let groupsList
      if (action.response) groupsList = action.response
      if (!action.response && !action.updateObject) {
        //@ts-ignore
        groupsList = cloneDeep(state.groupsList)
        //@ts-ignore
        if (state.createdSite)
          groupsList.data =
            groupsList.data && groupsList.data.length > 0
              ? //@ts-ignore
                [...groupsList.data, state.createdSite]
              : //@ts-ignore
                [state.createdSite]
      }
      return {
        ...state,
        groupsList: groupsList,
        loadingGroupsList: false,
        errorGroupsList: undefined,
      }
    case types.ERROR_FETCH_GROUPS_LIST:
      return {
        ...state,
        groupsList: undefined,
        loadingGroupsList: false,
        errorGroupsList: action.error,
      }
    case types.CLEAR_GROUPS_LIST:
      return {
        ...state,
        groupsList: undefined,
        loadingGroupsList: false,
        errorGroupsList: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_OBJECT_TEMPLATES_CATEGORIES_LIST:
      return {
        ...state,
        objectTemplatesCategoriesList: undefined,
        loadingObjectTemplatesCategoriesList: true,
        errorObjectTemplatesCategoriesList: undefined,
      }
    case types.RECEIVE_OBJECT_TEMPLATES_CATEGORIES_LIST:
      return {
        ...state,
        objectTemplatesCategoriesList: action.response,
        loadingObjectTemplatesCategoriesList: false,
        errorObjectTemplatesCategoriesList: undefined,
      }
    case types.ERROR_FETCH_OBJECT_TEMPLATES_CATEGORIES_LIST:
      return {
        ...state,
        objectTemplatesCategoriesList: undefined,
        loadingObjectTemplatesCategoriesList: false,
        errorObjectTemplatesCategoriesList: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_OBJECT_TEMPLATES_CATEGORIES_PUBLISH_LIST:
      return {
        ...state,
        objectTemplatesCategoriesPublishList: undefined,
        loadingObjectTemplatesCategoriesPublishList: true,
        errorObjectTemplatesCategoriesPublishList: undefined,
      }
    case types.RECEIVE_OBJECT_TEMPLATES_CATEGORIES_PUBLISH_LIST:
      return {
        ...state,
        objectTemplatesCategoriesPublishList: action.response,
        loadingObjectTemplatesCategoriesPublishList: false,
        errorObjectTemplatesCategoriesPublishList: undefined,
      }
    case types.ERROR_FETCH_OBJECT_TEMPLATES_CATEGORIES_PUBLISH_LIST:
      return {
        ...state,
        objectTemplatesCategoriesPublishList: undefined,
        loadingObjectTemplatesCategoriesPublishList: false,
        errorObjectTemplatesCategoriesPublishList: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_CREATE_GROUP:
      return {
        ...state,
        createdGroup: undefined,
        loadingCreateGroup: true,
        errorCreatingGroup: undefined,
      }
    case types.SUCCESS_CREATE_GROUP:
      return {
        ...state,
        createdGroup: action.response,
        loadingCreateGroup: false,
        errorCreatingGroup: undefined,
      }
    case types.ERROR_CREATE_GROUP:
      return {
        ...state,
        createdGroup: undefined,
        loadingCreateGroup: false,
        errorCreatingGroup: action.error,
      }
    case types.CLEAR_CREATED_GROUP:
      return {
        ...state,
        createdGroup: undefined,
        loadingCreateGroup: false,
        errorCreatingGroup: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_DELETE_GROUP:
      return {
        ...state,
        deletedGroup: undefined,
        loadingDeleteGroup: true,
        errorDeletingGroup: undefined,
      }
    case types.SUCCESS_DELETE_GROUP:
      return {
        ...state,
        deletedGroup: action.response,
        loadingDeleteGroup: false,
        errorDeletingGroup: undefined,
      }
    case types.ERROR_DELETE_GROUP:
      return {
        ...state,
        deletedGroup: undefined,
        loadingDeleteGroup: false,
        errorDeletingGroup: action.error,
      }
    case types.CLEAR_DELETED_GROUP:
      return {
        ...state,
        deletedGroup: undefined,
        loadingDeleteGroup: false,
        errorDeletingGroup: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_UPDATE_GROUP:
      return {
        ...state,
        updatedGroup: undefined,
        loadingUpdateGroup: true,
        errorUpdatingGroup: undefined,
      }
    case types.SUCCESS_UPDATE_GROUP:
      return {
        ...state,
        updatedGroup: action.response,
        loadingUpdateGroup: false,
        errorUpdatingGroup: undefined,
      }
    case types.ERROR_UPDATE_GROUP:
      return {
        ...state,
        updatedGroup: undefined,
        loadingUpdateGroup: false,
        errorUpdatingGroup: action.error,
      }
    case types.CLEAR_UPDATED_GROUP:
      return {
        ...state,
        updatedGroup: undefined,
        loadingUpdateGroup: false,
        errorUpdatingGroup: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_CREATE_OBJECT_TEMPLATE:
      return {
        ...state,
        createdObjectTemplate: undefined,
        loadingCreateObjectTemplate: true,
        errorCreatingObjectTemplate: undefined,
      }
    case types.SUCCESS_CREATE_OBJECT_TEMPLATE:
      return {
        ...state,
        createdObjectTemplate: action.response,
        loadingCreateObjectTemplate: false,
        errorCreatingObjectTemplate: undefined,
      }
    case types.ERROR_CREATE_OBJECT_TEMPLATE:
      return {
        ...state,
        createdObjectTemplate: undefined,
        loadingCreateObjectTemplate: false,
        errorCreatingObjectTemplate: action.error,
      }
    case types.CLEAR_CREATED_OBJECT_TEMPLATE:
      return {
        ...state,
        createdObjectTemplate: undefined,
        loadingCreateObjectTemplate: false,
        errorCreatingObjectTemplate: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_OBJECT_TEMPLATES_LIST:
      return {
        ...state,
        objectTemplatesList: undefined,
        loadingObjectTemplatesList: true,
        errorObjectTemplatesList: undefined,
      }
    case types.RECEIVE_OBJECT_TEMPLATES_LIST:
      return {
        ...state,
        objectTemplatesList: action.response,
        loadingObjectTemplatesList: false,
        errorObjectTemplatesList: undefined,
      }
    case types.ERROR_FETCH_OBJECT_TEMPLATES_LIST:
      return {
        ...state,
        objectTemplatesList: undefined,
        loadingObjectTemplatesList: false,
        errorObjectTemplatesList: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_OBJECT_TEMPLATES_PLATFORM_LIST:
      return {
        ...state,
        objectTemplatesPlatformList: undefined,
        loadingObjectTemplatesPlatformList: true,
        errorObjectTemplatesPlatformList: undefined,
      }
    case types.RECEIVE_OBJECT_TEMPLATES_PLATFORM_LIST:
      return {
        ...state,
        objectTemplatesPlatformList: action.response,
        loadingObjectTemplatesPlatformList: false,
        errorObjectTemplatesPlatformList: undefined,
      }
    case types.ERROR_FETCH_OBJECT_TEMPLATES_PLATFORM_LIST:
      return {
        ...state,
        objectTemplatesPlatformList: undefined,
        loadingObjectTemplatesPlatformList: false,
        errorObjectTemplatesPlatformList: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_MOVE_OBJECT_TEMPLATE:
      return {
        ...state,
        loadingMoveObjectTemplate: false,
        errorMoveObjectTemplate: action.error,
        movedObjectTemplate: undefined,
      }
    case types.REQUEST_MOVE_OBJECT_TEMPLATE:
      return {
        ...state,
        loadingMoveObjectTemplate: true,
        errorMoveObjectTemplate: false,
        movedObjectTemplate: undefined,
      }
    case types.SUCCESS_MOVE_OBJECT_TEMPLATE:
      return {
        ...state,
        loadingMoveObjectTemplate: false,
        errorMoveObjectTemplate: false,
        movedObjectTemplate: action.response,
      }
    case types.CLEAR_MOVE_OBJECT_TEMPLATE:
      return {
        ...state,
        loadingMoveObjectTemplate: false,
        errorMoveObjectTemplate: false,
        movedObjectTemplate: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_GROUP_INFO:
      return {
        ...state,
        groupInfo: undefined,
        loadingGroupInfo: true,
        errorGroupInfo: undefined,
      }
    case types.RECEIVE_GROUP_INFO:
      return {
        ...state,
        groupInfo: action.response,
        loadingGroupInfo: false,
        errorGroupInfo: undefined,
      }
    case types.ERROR_FETCH_GROUP_INFO:
      return {
        ...state,
        groupInfo: undefined,
        loadingGroupInfo: false,
        errorGroupInfo: action.error,
      }
    case types.CLEAR_GROUP_INFO:
      return {
        ...state,
        groupInfo: undefined,
        loadingGroupInfo: false,
        errorGroupInfo: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_INPUT_SITES_LIST:
      return {
        ...state,
        loadingInputSitesList: true,
        errorInputSitesList: undefined,
      }
    case types.RECEIVE_INPUT_SITES_LIST:
      let inputSitesDataArray = action.response
      if (action.page !== 1) {
        //@ts-ignore
        let clonedInputSitesList = cloneDeep(state.inputSitesList)
        clonedInputSitesList.data = [...clonedInputSitesList.data, ...action.response.data]
        inputSitesDataArray = clonedInputSitesList
      }
      return {
        ...state,
        inputSitesList: inputSitesDataArray,
        loadingInputSitesList: false,
        errorInputSitesList: undefined,
        inputSitesListPage: action.page,
      }
    case types.ERROR_FETCH_INPUT_SITES_LIST:
      return {
        ...state,
        loadingInputSitesList: false,
        errorInputSitesList: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_SINGLE_SITE:
      return {
        ...state,
        singleSite: undefined,
        loadingSingleSite: true,
        errorSingleSite: undefined,
        singleSiteId: action.siteId,
      }
    case types.RECEIVE_SINGLE_SITE:
      return {
        ...state,
        singleSite: action.response,
        loadingSingleSite: false,
        errorSingleSite: undefined,
        singleSiteId: action.siteId,
      }
    case types.ERROR_FETCH_SINGLE_SITE:
      return {
        ...state,
        singleSite: undefined,
        loadingSingleSite: false,
        errorSingleSite: action.error,
        singleSiteId: action.siteId,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.EDIT_SINGLE_SITE_PAGES_REDUX:
      /**
       * TODO: this logic shouldn't exist.
       * Problem here is when we fetch a single site,
       * we populate it's pages list with the list that is inside the single site reponse,
       * and when we paginate the pages list we're populating that same list with a list from another endpoint response.
       *
       * The pages list should allways be populated by the same endpoint response list
       */
      return {
        ...state,
        //@ts-ignore
        singleSite: { ...state.singleSite, pages: action.sitePages[0] },
        singleSitePages: action.sitePages[1],
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.EDIT_SINGLE_SITE_SETTINGS_REDUX:
      return {
        ...state,
        singleSiteSettings: action.siteSettings,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_UPDATE_SINGLE_SITE:
      return {
        ...state,
        singleSiteUpdate: undefined,
        loadingSingleSiteUpdate: true,
        errorSingleSiteUpdate: undefined,
        singleSiteId: action.siteId,
      }
    case types.RECEIVE_UPDATE_SINGLE_SITE:
      return {
        ...state,
        singleSiteUpdate: action.response,
        loadingSingleSiteUpdate: false,
        errorSingleSiteUpdate: undefined,
        singleSiteId: action.siteId,
      }
    case types.ERROR_UPDATE_SINGLE_SITE:
      return {
        ...state,
        singleSiteUpdate: undefined,
        loadingSingleSiteUpdate: false,
        errorSingleSiteUpdate: action.error,
        singleSiteId: action.siteId,
      }
    case types.CLEAR_UPDATE_SINGLE_SITE:
      return {
        ...state,
        singleSiteUpdate: undefined,
        loadingSingleSiteUpdate: false,
        errorSingleSiteUpdate: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_UPDATE_SINGLE_SITE_DOMAIN:
      return {
        ...state,
        singleSiteDomainUpdate: undefined,
        loadingSingleSiteDomainUpdate: true,
        errorSingleSiteDomainUpdate: undefined,
        singleSiteDomainId: action.siteId,
      }
    case types.RECEIVE_UPDATE_SINGLE_SITE_DOMAIN:
      return {
        ...state,
        singleSiteDomainUpdate: action.response,
        loadingSingleSiteDomainUpdate: false,
        errorSingleSiteDomainUpdate: undefined,
        singleSiteDomainId: action.siteId,
      }
    case types.ERROR_UPDATE_SINGLE_SITE_DOMAIN:
      return {
        ...state,
        singleSiteDomainUpdate: undefined,
        loadingSingleSiteDomainUpdate: false,
        errorSingleSiteDomainUpdate: action.error,
        singleSiteDomainId: action.siteId,
      }
    case types.CLEAR_UPDATE_SINGLE_SITE_DOMAIN:
      return {
        ...state,
        singleSiteDomainUpdate: undefined,
        loadingSingleSiteDomainUpdate: false,
        errorSingleSiteDomainUpdate: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.UPDATE_SINGLE_SITE:
      return {
        ...state,
        singleSite: action.updatedSingleSite,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_DELETE_SINGLE_SITE:
      return {
        ...state,
        singleSiteDelete: undefined,
        loadingSingleSiteDelete: true,
        errorSingleSiteDelete: undefined,
        singleSiteDeleteId: action.siteId,
      }
    case types.RECEIVE_DELETE_SINGLE_SITE:
      /**
       * update singleSite props inside redux
       */
      return {
        ...state,
        singleSiteDelete: action.response,
        loadingSingleSiteDelete: false,
        errorSingleSiteDelete: undefined,
        singleSiteDeleteId: action.siteId,
      }
    case types.ERROR_DELETE_SINGLE_SITE:
      return {
        ...state,
        singleSiteDelete: undefined,
        loadingSingleSiteDelete: false,
        errorSingleSiteDelete: action.error,
        singleSiteDeleteId: action.siteId,
      }
    case types.CLEAR_DELETE_SINGLE_SITE:
      return {
        ...state,
        singleSiteDelete: undefined,
        loadingSingleSiteDelete: false,
        errorSingleSiteDelete: undefined,
        singleSiteDeleteId: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_FETCH_SINGLE_SITE_SETTINGS:
      return {
        ...state,
        singleSiteSettings: undefined,
        loadingSingleSiteSettings: true,
        errorSingleSiteSettings: undefined,
      }
    case types.RECEIVE_FETCH_SINGLE_SITE_SETTINGS:
      return {
        ...state,
        singleSiteSettings: action.response,
        loadingSingleSiteSettings: false,
        errorSingleSiteSettings: undefined,
      }
    case types.ERROR_FETCH_SINGLE_SITE_SETTINGS:
      return {
        ...state,
        singleSiteSettings: undefined,
        loadingSingleSiteSettings: false,
        errorSingleSiteSettings: action.error,
      }
    case types.CLEAR_FETCH_SINGLE_SITE_SETTINGS:
      return {
        ...state,
        singleSiteSettings: undefined,
        loadingSingleSiteSettings: false,
        errorSingleSiteSettings: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_UPDATE_SINGLE_SITE_SETTINGS:
      return {
        ...state,
        updateSingleSiteSettings: undefined,
        loadingUpdateSingleSiteSettings: true,
        errorUpdateSingleSiteSettings: undefined,
      }
    case types.RECEIVE_UPDATE_SINGLE_SITE_SETTINGS:
      return {
        ...state,
        updateSingleSiteSettings: action.response,
        loadingUpdateSingleSiteSettings: false,
        errorUpdateSingleSiteSettings: undefined,
      }
    case types.ERROR_UPDATE_SINGLE_SITE_SETTINGS:
      return {
        ...state,
        updateSingleSiteSettings: undefined,
        loadingUpdateSingleSiteSettings: false,
        errorUpdateSingleSiteSettings: action.error,
      }
    case types.CLEAR_UPDATE_SINGLE_SITE_SETTINGS:
      return {
        ...state,
        updateSingleSiteSettings: undefined,
        loadingUpdateSingleSiteSettings: false,
        errorUpdateSingleSiteSettings: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_UPDATE_SINGLE_SITE_SETTINGS_MEDIA:
      return {
        ...state,
        updateSingleSiteSettingsMedia: undefined,
        loadingUpdateSingleSiteSettingsMedia: true,
        errorUpdateSingleSiteSettingsMedia: undefined,
      }
    case types.RECEIVE_UPDATE_SINGLE_SITE_SETTINGS_MEDIA:
      return {
        ...state,
        updateSingleSiteSettingsMedia: action.response,
        loadingUpdateSingleSiteSettingsMedia: false,
        errorUpdateSingleSiteSettingsMedia: undefined,
      }
    case types.ERROR_UPDATE_SINGLE_SITE_SETTINGS_MEDIA:
      return {
        ...state,
        updateSingleSiteSettingsMedia: undefined,
        loadingUpdateSingleSiteSettingsMedia: false,
        errorUpdateSingleSiteSettingsMedia: action.error,
      }
    case types.CLEAR_UPDATE_SINGLE_SITE_SETTINGS_MEDIA:
      return {
        ...state,
        updateSingleSiteSettingsMedia: undefined,
        loadingUpdateSingleSiteSettingsMedia: false,
        errorUpdateSingleSiteSettingsMedia: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_UPDATE_SINGLE_PAGE_SETTINGS_MEDIA:
      return {
        ...state,
        updateSinglePageSettingsMedia: undefined,
        loadingUpdateSinglePageSettingsMedia: true,
        errorUpdateSinglePageSettingsMedia: undefined,
      }
    case types.RECEIVE_UPDATE_SINGLE_PAGE_SETTINGS_MEDIA:
      return {
        ...state,
        updateSinglePageSettingsMedia: action.response,
        loadingUpdateSinglePageSettingsMedia: false,
        errorUpdateSinglePageSettingsMedia: undefined,
      }
    case types.ERROR_UPDATE_SINGLE_PAGE_SETTINGS_MEDIA:
      return {
        ...state,
        updateSinglePageSettingsMedia: undefined,
        loadingUpdateSinglePageSettingsMedia: false,
        errorUpdateSinglePageSettingsMedia: action.error,
      }
    case types.CLEAR_UPDATE_SINGLE_PAGE_SETTINGS_MEDIA:
      return {
        ...state,
        updateSinglePageSettingsMedia: undefined,
        loadingUpdateSinglePageSettingsMedia: false,
        errorUpdateSinglePageSettingsMedia: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_SINGLE_SITE_PAGES:
      return {
        ...state,
        singleSitePages: undefined,
        loadingSingleSitePages: true,
        errorSingleSitePages: undefined,
        singleSiteIdPages: action.siteId,
      }
    case types.RECEIVE_SINGLE_SITE_PAGES:
      return {
        ...state,
        singleSitePages: action.response,
        loadingSingleSitePages: false,
        errorSingleSitePages: undefined,
        singleSiteIdPages: action.siteId,
      }
    case types.ERROR_FETCH_SINGLE_SITE_PAGES:
      return {
        ...state,
        singleSitePages: undefined,
        loadingSingleSitePages: false,
        errorSingleSitePages: action.error,
        singleSiteIdPages: action.siteId,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_SINGLE_SITE_OUTDATED_PAGES:
      return {
        ...state,
        singleSiteOutdatedPages: undefined,
        loadingSingleSiteOutdatedPages: true,
        errorSingleSiteOutdatedPages: undefined,
      }
    case types.RECEIVE_SINGLE_SITE_OUTDATED_PAGES:
      return {
        ...state,
        singleSiteOutdatedPages: action.response,
        loadingSingleSiteOutdatedPages: false,
        errorSingleSiteOutdatedPages: undefined,
      }
    case types.ERROR_FETCH_SINGLE_SITE_OUTDATED_PAGES:
      return {
        ...state,
        singleSiteOutdatedPages: undefined,
        loadingSingleSiteOutdatedPages: false,
        errorSingleSiteOutdatedPages: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_CREATE_SITE:
      return {
        ...state,
        createdSite: undefined,
        loadingCreateSite: true,
        errorCreatingSite: undefined,
      }
    case types.SUCCESS_CREATE_SITE:
      return {
        ...state,
        createdSite: action.response,
        loadingCreateSite: false,
        errorCreatingSite: undefined,
      }
    case types.ERROR_CREATE_SITE:
      return {
        ...state,
        createdSite: undefined,
        loadingCreateSite: false,
        errorCreatingSite: action.error,
      }
    case types.CLEAR_CREATED_SITE:
      return {
        ...state,
        createdSite: undefined,
        loadingCreateSite: false,
        errorCreatingSite: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_SAVE_COOKIE_BAR_SETTINGS:
      return {
        ...state,
        cookieBarSettings: undefined,
        loadingCookieBarSettings: true,
        errorCookieBarSettings: undefined,
      }
    case types.SUCCESS_SAVE_COOKIE_BAR_SETTINGS:
      return {
        ...state,
        cookieBarSettings: action.response,
        loadingCookieBarSettings: false,
        errorCookieBarSettings: undefined,
      }
    case types.ERROR_SAVE_COOKIE_BAR_SETTINGS:
      return {
        ...state,
        cookieBarSettings: undefined,
        loadingCookieBarSettings: false,
        errorCookieBarSettings: action.error,
      }
    default:
      return state
  }
}

import React from "react"
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels"

interface LayoutProps {
  content: JSX.Element
  assistantPanel: JSX.Element
  contentTreePanel: JSX.Element
}

const TwoLayout: React.FC<LayoutProps> = ({ content, assistantPanel, contentTreePanel }) => {
  return (
    <PanelGroup autoSaveId='doc' direction='horizontal'>
      <Panel style={{ overflow: "auto" }} defaultSizePercentage={15} order={1}>
        {/*Actions Panel / Assistant*/}
        {contentTreePanel}
      </Panel>
      <PanelResizeHandle id={"h-l-handle"} className='resize-handle-outer'>
        <div className='h-handle-container'>
          {" "}
          <i className='fas fa-arrows-alt-h h-handle-icon'></i>{" "}
        </div>
        <div className='resize-handle-line'></div>
      </PanelResizeHandle>
      <Panel style={{ overflow: "auto" }} order={2}>
        {content}
      </Panel>
      <PanelResizeHandle id={"h-r-handle"} className='resize-handle-outer'>
        <div className='h-handle-container'>
          <i className='fas fa-arrows-alt-h h-handle-icon' />
        </div>
        <div className='resize-handle-line'></div>
      </PanelResizeHandle>
      <Panel style={{ overflow: "auto" }} defaultSizePercentage={25} order={3}>
        {/*Actions Panel / Assistant */}
        {assistantPanel}
      </Panel>
    </PanelGroup>
  )
}

export default TwoLayout

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function profileNetworkReducer(state = initialState.profileReducer, action) {
  switch (action.type) {
    case types.GET_NETWORK_LIBRARY:
      return {
        ...state,
        page: action.page,
        loading: true
      }
    case types.RECEIVE_NETWORK_LIBRARY:
      return {
        page: action.page,
        content: action.content,
        loading: false
      }
    case types.ERROR_GET_NETWORK_LIBRARY:
      return {
        error: action.error,
        loading: false
      }
    default:
      return state
  }
}

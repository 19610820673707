import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function inlineContentReducer(state: any = initialState.inlineContentReducer, action: any) {
  switch (action.type) {
    case types.CHANGE_CUSTOM_TOOLBAR_ID:
      return {
        ...state,
        toolbarId: action.toolbarId
      }
    case types.ERROR_IL_MEDIA_UPLOAD:
      return {
        ...state,
        [`errorUploadImage-${action.uploadId}`]: action.error,
        [`successUploadImage-${action.uploadId}`]: undefined,
        [`loadingUploadImage-${action.uploadId}`]: false,
      }
    case types.REQUEST_IL_MEDIA_UPLOAD:
      return {
        ...state,
        [`errorUploadImage-${action.uploadId}`]: false,
        [`successUploadImage-${action.uploadId}`]: undefined,
        [`loadingUploadImage-${action.uploadId}`]: true,
      }
    case types.SUCCESS_IL_MEDIA_UPLOAD:
      return {
        ...state,
        [`errorUploadImage-${action.uploadId}`]: false,
        [`successUploadImage-${action.uploadId}`]: action.response,
        [`loadingUploadImage-${action.uploadId}`]: false,
      }
    case types.CLEAR_IL_MEDIA_UPLOAD:
      return {
        ...state,
        [`errorUploadImage-${action.uploadId}`]: false,
        [`successUploadImage-${action.uploadId}`]: undefined,
        [`loadingUploadImage-${action.uploadId}`]: false,
      }
    case types.QUILL_TOOLBAR_CHANGE:
      return {
        ...state,
        quillChanges: action.quillChanges
      }
    case types.UPDATE_ADDITIONAL_SETTINGS_ICONTENT:
      return {
        ...state,
        additionalSettingsIContent: action.additionalSettingsIContent
      }
    default:
      return state
  }
}
class ComponentNames {
  static types(type: string) {
    switch (type) {
      case 'card_modal':
        return 'Card Modal'
      case 'card_expand':
        return 'Card Expand'
      case 'card_left':
        return 'Card Left'
      case 'card_right':
        return 'Card Right'
      case 'card_top':
        return 'Card Top'
      case 'grid_container':
        return 'grid Container'
      case 'navbar':
        return 'Navigation Bar'
      case 'text_block':
        return 'Text Block'
      default:
        return type
    }
  }
}

export default ComponentNames
import React, { useState, useRef, useEffect, MutableRefObject } from "react"
import { Toasts } from "../toasts/toasts"

interface FaEffectButtonProps {
  action: () => Promise<void>
  title: string
  failMsg: string
  classDefault: string
  classRunning?: string
  style?: React.CSSProperties
}

// A fontawesome button that performs an action, shows a spinner while running, and runs a toast if the action fails
export const FaEffectButton: React.FC<FaEffectButtonProps> = ({
  action,
  title,
  failMsg,
  classDefault,
  classRunning,
  style,
}) => {
  const [running, setRunning] = useState(false)
  const actualClassRunning = classRunning ?? "fas fa-spinner fa-spin"

  // if the component is unmounted we don't need to stop the spinner
  const isMounted = useIsMounted()

  if (running) return <i className={actualClassRunning} title={title}></i>

  return (
    <i
      className={classDefault}
      style={style}
      title={title}
      onClick={(e) => {
        setRunning(true)
        Toasts.onApiError(
          failMsg,
          action().then(() => isMounted && setRunning(false))
        ).catch(() => isMounted && setRunning(false))
      }}
    ></i>
  )
}

function useIsMounted(): MutableRefObject<boolean> {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  return isMounted
}

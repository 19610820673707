class LocalStorageHandler {
  static getValue(field: any) {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(field)
    } else return null;
  }

  static setValue(field: any, value: any) {
    if (typeof window !== 'undefined') {
      return localStorage.setItem(field, value);
    } else return null;
  }
}

export default LocalStorageHandler
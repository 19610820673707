import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function symbolsReducer(state: any = initialState.symbolsReducer, action: any) {
  switch (action.type) {
    case types.SELECTED_SYMBOL_INSTANCE:
      return {
        ...state,
        selectedSymbolInstanceId: action.id,
        selectedSymbolInstanceObject: action.object,
      }
    case types.RECEIVE_SYMBOLS_LIST:
      return {
        ...state,
        loadingFetchSymbolsList: false,
        errorFetchSymbolsList: false,
        successFetchSymbolsList: action.response,
      }
    case types.REQUEST_SYMBOLS_LIST:
      return {
        ...state,
        loadingFetchSymbolsList: true,
        errorFetchSymbolsList: false,
        successFetchSymbolsList: undefined,
      }
    case types.ERROR_SYMBOLS_LIST:
      return {
        ...state,
        loadingFetchSymbolsList: false,
        errorFetchSymbolsList: action.error,
        successFetchSymbolsList: undefined,
      }
    case types.ADD_SYMBOL_OBJECT:
      return {
        ...state,
        addSymbolObject: action.object,
        addSymbolType: action.addBehaviourType
      }
    case types.CLEAR_ADD_SYMBOL_OBJECT:
      return {
        ...state,
        addSymbolObject: undefined,
        addSymbolType: undefined
      }
    case types.SYMBOL_CONTENT_TYPE:
      return {
        ...state,
        symbolContentType: action.contentType
      }
    case types.HANDLE_SYMBOL_TREE:
      return {
        ...state,
        symbolTree: action.symbol,
        symbolInstance: action.symbolInstance
      }
    case types.CLEAR_HANDLE_SYMBOL_TREE:
      return {
        ...state,
        symbolTree: undefined,
        symbolInstance: undefined
      }
    /** */
    case types.REQUEST_DELETE_SYMBOL:
      return {
        ...state,
        loadingDeleteSymbol: action.symbol,
        errorDeleteSymbol: undefined,
        successDeleteSymbol: undefined,
      }
    case types.RECEIVE_DELETE_SYMBOL:
      return {
        ...state,
        loadingDeleteSymbol: false,
        errorDeleteSymbol: undefined,
        successDeleteSymbol: action.response,
      }
    case types.ERROR_DELETE_SYMBOL:
      return {
        ...state,
        loadingDeleteSymbol: false,
        errorDeleteSymbol: action.error,
        successDeleteSymbol: undefined,
      }
    case types.CLEAR_DELETE_SYMBOL:
      return {
        ...state,
        loadingDeleteSymbol: false,
        errorDeleteSymbol: undefined,
        successDeleteSymbol: undefined,
      }
    /** */
    case types.REQUEST_CHANGE_SYMBOL_LABEL:
      return {
        ...state,
        loadingChangeSymbolName: action.objectId,
        errorChangeSymbolName: undefined,
        successChangeSymbolName: undefined,
      }
    case types.RECEIVE_CHANGE_SYMBOL_LABEL:
      return {
        ...state,
        loadingChangeSymbolName: false,
        errorChangeSymbolName: undefined,
        successChangeSymbolName: action.response,
      }
    case types.ERROR_CHANGE_SYMBOL_LABEL:
      return {
        ...state,
        loadingChangeSymbolName: false,
        errorChangeSymbolName: action.error,
        successChangeSymbolName: undefined,
      }
    case types.CLEAR_CHANGE_SYMBOL_LABEL:
      return {
        ...state,
        loadingChangeSymbolName: false,
        errorChangeSymbolName: undefined,
        successChangeSymbolName: undefined,
      }
    /** */
    case types.REQUEST_SYMBOL_SCREENSHOT:
      return {
        ...state,
        loadingSymbolScreenshot: true,
        errorSymbolScreenshot: undefined,
        successSymbolScreenshot: undefined,
      }
    case types.RECEIVE_SYMBOL_SCREENSHOT:
      return {
        ...state,
        loadingSymbolScreenshot: false,
        errorSymbolScreenshot: undefined,
        successSymbolScreenshot: action.response,
      }
    case types.ERROR_SYMBOL_SCREENSHOT:
      return {
        ...state,
        loadingSymbolScreenshot: false,
        errorSymbolScreenshot: action.error,
        successSymbolScreenshot: undefined,
      }
    case types.CLEAR_SYMBOL_SCREENSHOT:
      return {
        ...state,
        loadingSymbolScreenshot: false,
        errorSymbolScreenshot: undefined,
        successSymbolScreenshot: undefined,
      }
    default:
      return state;
  }
}
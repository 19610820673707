import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function metaManagerReducer(state = initialState.metaManagerReducer, action) {
  switch (action.type) {
    //first section MMFormula
    case types.REQUEST_META_MANAGER_FORMULA:
      return {
        ...state,
        profile: action.profile,
        loadingMetaManagerContent: true
      }
    case types.RECEIVE_META_MANAGER_FORMULA:
      return {
        ...state,
        profile: action.profile,
        metaManagerContent: action.content,
        loadingMetaManagerContent: false
      }
    case types.INVALID_META_MANAGER_FORMULA:
      return {
        ...state,
        profile: action.profile,
        error: action.error,
        loadingMetaManagerContent: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    //first section mmExperience
    case types.REQUEST_META_MANAGER_EXPERIENCE:
      return {
        ...state,
        profile: action.profile,
        loadingMetaManagerContent: true
      }
    case types.RECEIVE_META_MANAGER_EXPERIENCE:
      return {
        ...state,
        profile: action.profile,
        metaManagerContent: action.content,
        loadingMetaManagerContent: false
      }
    case types.INVALID_META_MANAGER_EXPERIENCE:
      return {
        ...state,
        profile: action.profile,
        error: action.error,
        loadingMetaManagerContent: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    //first section MMFormula Sub
    case types.REQUEST_META_MANAGER_SUB_FORMULA:
      return {
        ...state,
        profile: action.profile,
        loadingMetaManagerSubContent: true
      }
    case types.RECEIVE_META_MANAGER_SUB_FORMULA:
      return {
        ...state,
        profile: action.profile,
        metaManagerSubContent: action.content,
        loadingMetaManagerSubContent: false
      }
    case types.INVALID_META_MANAGER_SUB_FORMULA:
      return {
        ...state,
        profile: action.profile,
        error: action.error,
        loadingMetaManagerSubContent: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    //MMFormula tags/sources
    case types.REQUEST_META_MANAGER_FORMULA_INFO:
      return {
        ...state,
        metaManagerFormulaInfoProfile: action.profile,
        loadingMetaManagerFormulaInfo: true
      }
    case types.RECEIVE_META_MANAGER_FORMULA_INFO:
    case types.RECEIVE_META_MANAGER_SUB_FORMULA_INFO:
      return {
        ...state,
        metaManagerFormulaInfoProfile: action.profile,
        metaManagerFormulaInfo: action.content,
        loadingMetaManagerFormulaInfo: false,
        metaContentRequestID: action.uniqueId,
      }
    case types.CLEAR_META_MANAGER_FORMULA_INFO_RELATED:
      return {
        ...state,
        metaManagerFormulaInfoRelated: null,
        loadingMetaManagerFormulaInfoRelated: false
      }
    case types.CLEAR_META_MANAGER_FORMULA_INFO:
      return {
        ...state,
        metaManagerFormulaInfoProfile: null,
        metaManagerFormulaInfo: null,
        loadingMetaManagerFormulaInfo: false,
        metaContentRequestID: null,
      }
    case types.INVALID_META_MANAGER_FORMULA_INFO:
    case types.INVALID_META_MANAGER_SUB_FORMULA_INFO:
      return {
        ...state,
        metaManagerFormulaInfoProfile: action.profile,
        error: action.error,
        loadingMetaManagerFormulaInfo: false,
        metaManagerFormulaInfo: null
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    //MMFormula content
    case types.REQUEST_META_MANAGER_FORMULA_CONTENT:
      return {
        ...state,
        profile: action.profile,
        loadingMetaManagerFormulaContent: true
      }
    case types.RECEIVE_META_MANAGER_FORMULA_CONTENT:
    case types.RECEIVE_META_MANAGER_FORMULA_SUB_CONTENT:
      if (state.formulaId === action.formulaId && state.metaManagerFormulaContent) {
        let newObject = {
          response: {
            content: [...state.metaManagerFormulaContent.response.content, ...action.content.response.content],
            hasMore: action.content.response.hasMore
          }
        }
        if (action.filter) {
          newObject = {
            response: {
              content: [...action.content.response.content],
              hasMore: action.content.response.hasMore
            }
          }
        }
        return {
          ...state,
          profile: action.profile,
          metaManagerFormulaContent: newObject,
          loadingMetaManagerFormulaContent: false,
          formulaId: action.formulaId
        }
      }
      return {
        ...state,
        profile: action.profile,
        metaManagerFormulaContent: action.content,
        loadingMetaManagerFormulaContent: false,
        formulaId: action.formulaId
      }
    case types.INVALID_META_MANAGER_FORMULA_CONTENT:
      return {
        ...state,
        profile: action.profile,
        errorMetaManagerFormulaContent: action.error,
        loadingMetaManagerFormulaContent: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    //MMFormula subContent
    case types.REQUEST_META_MANAGER_FORMULA_SUB_CONTENT:
      return {
        ...state,
        profile: action.profile,
        loadingMetaManagerFormulaContent: true
      }
    case types.INVALID_META_MANAGER_FORMULA_SUB_CONTENT:
      return {
        ...state,
        profile: action.profile,
        error: action.error,
        loadingMetaManagerFormulaContent: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    //MMFormula sub tags/sources
    case types.REQUEST_META_MANAGER_SUB_FORMULA_INFO:
      return {
        ...state,
        profile: action.profile,
        loadingMetaManagerFormulaInfo: true
      }
    // case types.INVALID_META_MANAGER_SUB_FORMULA_INFO:
    //   return {
    //     ...state,
    //     profile: action.profile,
    //     error: action.profile,
    //     loadingMetaManagerFormulaInfo: false
    //   }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    //"Related" tags for mql query
    case types.REQUEST_META_MANAGER_SUB_FORMULA_INFO_RELATED:
      return {
        ...state,
        profile: action.profile,
        MQLQuery: action.MQLQuery,
        loadingMetaManagerFormulaInfoRelated: true,
        errorMetaManagerFormulaInfoRelated: null
      }
    case types.RECEIVE_META_MANAGER_SUB_FORMULA_INFO_RELATED:
      return {
        ...state,
        profile: action.profile,
        MQLQuery: action.MQLQuery,
        metaManagerFormulaInfoRelated: action.content,
        metaContentRequestID: action.uniqueId,
        loadingMetaManagerFormulaInfoRelated: false
      }
    case types.INVALID_META_MANAGER_SUB_FORMULA_INFO_RELATED:
      return {
        ...state,
        profile: action.profile,
        MQLQuery: action.MQLQuery,
        errorMetaManagerFormulaInfoRelatedOR: action.error,
        loadingMetaManagerFormulaInfoRelatedOR: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    //"Related" tags for mql query in OR mode
    case types.REQUEST_META_MANAGER_SUB_FORMULA_INFO_RELATED_OR:
      return {
        ...state,
        profile: action.profile,
        MQLQueryRelatedOR: action.MQLQuery,
        loadingMetaManagerFormulaInfoRelatedOR: true,
        errorMetaManagerFormulaInfoRelatedOR: null
      }
    case types.RECEIVE_META_MANAGER_SUB_FORMULA_INFO_RELATED_OR:
      return {
        ...state,
        profile: action.profile,
        MQLQueryRelatedOR: action.MQLQuery,
        metaManagerFormulaInfoRelatedOR: action.content,
        metaContentRequestID: action.uniqueId,
        loadingMetaManagerFormulaInfoRelatedOR: false
      }
    case types.INVALID_META_MANAGER_SUB_FORMULA_INFO_RELATED_OR:
      return {
        ...state,
        profile: action.profile,
        MQLQueryRelatedOR: action.MQLQuery,
        errorMetaManagerFormulaInfoRelatedOR: action.error,
        loadingMetaManagerFormulaInfoRelatedOR: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    //first section MMComponents
    case types.REQUEST_META_MANAGER_COMPONENTS_FIRST_SECTION:
      return {
        ...state,
        profile: action.profile,
        loadingMetaManagerComponentsFirstSection: true
      }
    case types.RECEIVE_META_MANAGER_COMPONENTS_FIRST_SECTION:
      return {
        ...state,
        profile: action.profile,
        metaManagerContentComponentsFirstSection: action.content,
        loadingMetaManagerComponentsFirstSection: false
      }
    case types.INVALID_META_MANAGER_COMPONENTS_FIRST_SECTION:
      return {
        ...state,
        profile: action.profile,
        error: action.error,
        loadingMetaManagerComponentsFirstSection: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    //ExpBuilder Formula Selector
    case types.REQUEST_FORMULA_LIST_EXPBUILDER:
      return {
        ...state,
        profile: action.profile,
        loadingEB: true
      }
    case types.RECEIVE_FORMULA_LIST_EXPBUILDER:
      return {
        ...state,
        profile: action.profile,
        formulaContent: action.content,
        loadingEB: false
      }
    case types.INVALID_FORMULA_LIST_EXPBUILDER:
      return {
        ...state,
        profile: action.profile,
        error: action.error,
        loadingEB: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    //ExpBuilder Component Selector
    case types.REQUEST_COMPONENT_LIST_EXPBUILDER:
      return {
        ...state,
        profile: action.profile,
        loadingEB: true
      }
    case types.RECEIVE_COMPONENT_LIST_EXPBUILDER:
      return {
        ...state,
        profile: action.profile,
        componentContent: action.content,
        loadingEB: false
      }
    case types.INVALID_COMPONENT_LIST_EXPBUILDER:
      return {
        ...state,
        profile: action.profile,
        error: action.error,
        loadingEB: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */

    //Components - selection
    case types.SELECTED_COMPONENT:
      return {
        ...state,
        component: action.component
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.INVALID_META_MANAGER_SAVE_FORMULA_QUERY:
      return {
        ...state,
        profile: action.profile,
        error: action.error
      }
    case types.REQUEST_META_MANAGER_SAVE_FORMULA_QUERY:
      return {
        ...state,
        profile: action.profile,
        query: action.query
      }
    case types.RECEIVE_META_MANAGER_SAVE_FORMULA_QUERY:
      return {
        ...state,
        profile: action.profile,
        query: action.query,
        response: action.response
      }
    case types.CACHE_INITIAL_META_INFO:
      return {
        ...state,
        profile: action.profile,
        cacheMetaObj: action.obj,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_FORMULA_CREATE:
      return {
        ...state,
        loadingCreateFormula: true,
        formulaCreated: false,
        formulaCreatedWorkbench: false,
        errorCreateFormula: false
      }
    case types.ERROR_FORMULA_CREATE:
      return {
        ...state,
        errorCreateFormula: action.error,
        loadingCreateFormula: false,
        formulaCreated: false,
        formulaCreatedWorkbench: false,
      }
    case types.CONFIRMED_FORMULA_CREATE:
      return {
        ...state,
        formulaCreated: action.content,
        formulaCreatedWorkbench: action.content,
        loadingCreateFormula: false,
        errorCreateFormula: false
      }
    case types.CLEAR_FORMULA_CREATE:
      return {
        ...state,
        formulaCreated: false,
        loadingCreateFormula: false,
        errorCreateFormula: false
      }
    case types.CLEAR_FORMULA_CREATE_WORKBENCH:
      return {
        ...state,
        formulaCreatedWorkbench: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_FORMULA_DELETE:
      return {
        ...state,
        loadingDeleteFormula: true,
        confirmedDeleteFormula: undefined,
        errorDeleteFormula: false
      }
    case types.CONFIRMED_FORMULA_DELETE:
      return {
        ...state,
        loadingDeleteFormula: false,
        confirmedDeleteFormula: action.content,
        errorDeleteFormula: false
      }
    case types.ERROR_FORMULA_DELETE:
      return {
        ...state,
        loadingDeleteFormula: false,
        confirmedDeleteFormula: undefined,
        errorDeleteFormula: true
      }
    case types.CLEAR_FORMULA_DELETE:
      return {
        ...state,
        loadingDeleteFormula: false,
        confirmedDeleteFormula: undefined,
        errorDeleteFormula: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_FORMULA_METADATA_UPDATE:
      return {
        ...state,
        loadingUpdateFormula: true,
        formulaUpdated: undefined,
        errorUpdateFormula: undefined
      }
    case types.CONFIRMED_FORMULA_METADATA_UPDATE:
      return {
        ...state,
        loadingUpdateFormula: false,
        formulaUpdated: action.formulaUpdate,
        errorUpdateFormula: false
      }
    case types.ERROR_FORMULA_METADATA_UPDATE:
      return {
        ...state,
        loadingUpdateFormula: false,
        formulaUpdated: undefined,
        errorUpdateFormula: action.error
      }
    case types.CLEAR_FORMULA_METADATA_UPDATE:
      return {
        ...state,
        loadingUpdateFormula: false,
        formulaUpdated: undefined,
        errorUpdateFormula: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.UPDATE_FORMULA_METADATA_LOCAL:
      return {
        ...state,
        formulaUpdatedLocal: action.formulaUpdateLocal
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.CONFIRM_CREATE_FORMULA_EXPRESSION:
      return {
        ...state,
        expressionCreated: action.expressionCreated,
        loadingCreatingExpression: false,
        errorCreatingExpression: false
      }
    case types.CREATE_FORMULA_EXPRESSION:
      return {
        ...state,
        expressionCreated: undefined,
        loadingCreatingExpression: true,
        errorCreatingExpression: false
      }
    case types.ERROR_CREATE_FORMULA_EXPRESSION:
      return {
        ...state,
        expressionCreated: undefined,
        loadingCreatingExpression: false,
        errorCreatingExpression: true
      }
    case types.CLEAR_CREATE_FORMULA_EXPRESSION:
      return {
        ...state,
        expressionCreated: undefined,
        loadingCreatingExpression: false,
        errorCreatingExpression: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.UPDATE_FORMULA_EXPRESSION:
      return {
        ...state,
        expressionUpdated: undefined,
        loadingUpdatingExpression: true,
        errorUpdatingExpression: false
      }
    case types.CONFIRM_UPDATE_FORMULA_EXPRESSION:
      return {
        ...state,
        expressionUpdated: action.expressionUpdated,
        loadingUpdatingExpression: false,
        errorUpdatingExpression: false
      }
    case types.ERROR_UPDATE_FORMULA_EXPRESSION:
      return {
        ...state,
        expressionUpdated: undefined,
        loadingUpdatingExpression: false,
        errorUpdatingExpression: true
      }
    case types.CLEAR_UPDATE_FORMULA_EXPRESSION:
      return {
        ...state,
        expressionUpdated: undefined,
        loadingUpdatingExpression: false,
        errorUpdatingExpression: false
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.DELETE_FORMULA_EXPRESSION:
      return {
        ...state,
        expressionDeleted: undefined,
        loadingDeleteExpression: true,
        errorDeleteExpression: false
      }
    case types.CONFIRM_DELETE_FORMULA_EXPRESSION:
      return {
        ...state,
        expressionDeleted: action.deletedExpression,
        loadingDeleteExpression: false,
        errorDeleteExpression: false
      }
    case types.ERROR_DELETE_FORMULA_EXPRESSION:
      return {
        ...state,
        expressionDeleted: undefined,
        loadingDeleteExpression: false,
        errorDeleteExpression: true
      }
    case types.CLEAR_DELETE_FORMULA_EXPRESSION:
      return {
        ...state,
        expressionDeleted: undefined,
        loadingDeleteExpression: false,
        errorDeleteExpression: false
      }
    default:
      return state
  }
}

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function fontAwesomeServicesReducer(state = initialState.fontAwesomeServices, action) {
  switch (action.type) {
    case types.REQUEST_FONTAWESOME_FONTS_LIST:
      return {
        ...state,
        fetching: true
      }
    case types.RECEIVE_FONTAWESOME_FONTS_LIST:
      return {
        ...state,
        list: action.fontList,
        fetching: false
      }
    case types.ERROR_FONTAWESOME_SERVICES:
      return {
        ...state,
        fetching: false,
        error: action.error
      }
    default:
      return state
  }
}

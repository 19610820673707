import * as types from "../shared-store/actions/actionTypes"
import initialState from "../initialState"

export default function formBuilderV2Reducer(state: any = initialState.formBuilderV2Reducer, action: any) {
  switch (action.type) {
    case types.CLEAR_PROFILE_FORMS_LIST:
      return {
        ...state,
        errorProfileFormsList: false,
        loadingProfileFormsList: false,
        successProfileFormsList: undefined,
      }
    case types.ERROR_PROFILE_FORMS_LIST:
      return {
        ...state,
        errorProfileFormsList: action.error,
        loadingProfileFormsList: false,
        successProfileFormsList: undefined,
      }
    case types.REQUEST_PROFILE_FORMS_LIST:
      return {
        ...state,
        errorProfileFormsList: false,
        loadingProfileFormsList: true,
        successProfileFormsList: undefined,
      }
    case types.SUCCESS_PROFILE_FORMS_LIST:
      return {
        ...state,
        errorProfileFormsList: false,
        loadingProfileFormsList: false,
        successProfileFormsList: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.CLEAR_PROFILE_FORM:
      return {
        ...state,
        errorProfileForm: false,
        loadingProfileForm: false,
        successProfileForm: undefined,
      }
    case types.ERROR_PROFILE_FORM:
      return {
        ...state,
        errorProfileForm: action.error,
        loadingProfileForm: false,
        successProfileForm: undefined,
      }
    case types.REQUEST_PROFILE_FORM:
      return {
        ...state,
        errorProfileForm: false,
        loadingProfileForm: true,
        successProfileForm: undefined,
      }
    case types.SUCCESS_PROFILE_FORM:
      return {
        ...state,
        errorProfileForm: false,
        loadingProfileForm: false,
        successProfileForm: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.CLEAR_PROFILE_FORM_MAPPED_VALUES:
      return {
        ...state,
        errorProfileFormMappedValues: false,
        loadingProfileFormMappedValues: false,
        successProfileFormMappedValues: undefined,
      }
    case types.ERROR_PROFILE_FORM_MAPPED_VALUES:
      return {
        ...state,
        errorProfileFormMappedValues: action.error,
        loadingProfileFormMappedValues: false,
        successProfileFormMappedValues: undefined,
      }
    case types.REQUEST_PROFILE_FORM_MAPPED_VALUES:
      return {
        ...state,
        errorProfileFormMappedValues: false,
        loadingProfileFormMappedValues: true,
        successProfileFormMappedValues: undefined,
      }
    case types.SUCCESS_PROFILE_FORM_MAPPED_VALUES:
      return {
        ...state,
        errorProfileFormMappedValues: false,
        loadingProfileFormMappedValues: false,
        successProfileFormMappedValues: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.CLEAR_UPDATE_PROFILE_FORM:
      return {
        ...state,
        errorUpdateProfileForm: false,
        loadingUpdateProfileForm: false,
        successUpdateProfileForm: undefined,
      }
    case types.ERROR_UPDATE_PROFILE_FORM:
      return {
        ...state,
        errorUpdateProfileForm: action.error,
        loadingUpdateProfileForm: false,
        successUpdateProfileForm: undefined,
      }
    case types.REQUEST_UPDATE_PROFILE_FORM:
      return {
        ...state,
        errorUpdateProfileForm: false,
        loadingUpdateProfileForm: true,
        successUpdateProfileForm: undefined,
      }
    case types.SUCCESS_UPDATE_PROFILE_FORM:
      return {
        ...state,
        errorUpdateProfileForm: false,
        loadingUpdateProfileForm: false,
        successUpdateProfileForm: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.CLEAR_UPDATE_PROFILE_FORM_FIELD:
      return {
        ...state,
        errorUpdateProfileFormField: false,
        loadingUpdateProfileFormField: false,
        successUpdateProfileFormField: undefined,
      }
    case types.ERROR_UPDATE_PROFILE_FORM_FIELD:
      return {
        ...state,
        errorUpdateProfileFormField: action.error,
        loadingUpdateProfileFormField: false,
        successUpdateProfileFormField: undefined,
      }
    case types.REQUEST_UPDATE_PROFILE_FORM_FIELD:
      return {
        ...state,
        errorUpdateProfileFormField: false,
        loadingUpdateProfileFormField: true,
        successUpdateProfileFormField: undefined,
      }
    case types.SUCCESS_UPDATE_PROFILE_FORM_FIELD:
      return {
        ...state,
        errorUpdateProfileFormField: false,
        loadingUpdateProfileFormField: false,
        successUpdateProfileFormField: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.CLEAR_UPDATE_PROFILE_FORM_SUBMITTER:
      return {
        ...state,
        errorUpdateProfileFormSubmitter: false,
        loadingUpdateProfileFormSubmitter: false,
        successUpdateProfileFormSubmitter: undefined,
      }
    case types.ERROR_UPDATE_PROFILE_FORM_SUBMITTER:
      return {
        ...state,
        errorUpdateProfileFormSubmitter: action.error,
        loadingUpdateProfileFormSubmitter: false,
        successUpdateProfileFormSubmitter: undefined,
      }
    case types.REQUEST_UPDATE_PROFILE_FORM_SUBMITTER:
      return {
        ...state,
        errorUpdateProfileFormSubmitter: false,
        loadingUpdateProfileFormSubmitter: true,
        successUpdateProfileFormSubmitter: undefined,
      }
    case types.SUCCESS_UPDATE_PROFILE_FORM_SUBMITTER:
      return {
        ...state,
        errorUpdateProfileFormSubmitter: false,
        loadingUpdateProfileFormSubmitter: false,
        successUpdateProfileFormSubmitter: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.CLEAR_ATTACH_PROFILE_FORM:
      return {
        ...state,
        errorAttachProfileForm: false,
        loadingAttachProfileForm: false,
        successAttachProfileForm: undefined,
      }
    case types.ERROR_ATTACH_PROFILE_FORM:
      return {
        ...state,
        errorAttachProfileForm: action.error,
        loadingAttachProfileForm: false,
        successAttachProfileForm: undefined,
      }
    case types.REQUEST_ATTACH_PROFILE_FORM:
      return {
        ...state,
        errorAttachProfileForm: false,
        loadingAttachProfileForm: true,
        successAttachProfileForm: undefined,
      }
    case types.SUCCESS_ATTACH_PROFILE_FORM:
      return {
        ...state,
        errorAttachProfileForm: false,
        loadingAttachProfileForm: false,
        successAttachProfileForm: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.CLEAR_DETTACH_PROFILE_FORM:
      return {
        ...state,
        errorDettachProfileForm: false,
        loadingDettachProfileForm: false,
        successDettachProfileForm: undefined,
      }
    case types.ERROR_DETTACH_PROFILE_FORM:
      return {
        ...state,
        errorDettachProfileForm: action.error,
        loadingDettachProfileForm: false,
        successDettachProfileForm: undefined,
      }
    case types.REQUEST_DETTACH_PROFILE_FORM:
      return {
        ...state,
        errorDettachProfileForm: false,
        loadingDettachProfileForm: true,
        successDettachProfileForm: undefined,
      }
    case types.SUCCESS_DETTACH_PROFILE_FORM:
      return {
        ...state,
        errorDettachProfileForm: false,
        loadingDettachProfileForm: false,
        successDettachProfileForm: action.response,
      }
    default:
      return state
  }
}

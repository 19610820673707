import React from 'react'

interface OwnProps {
  postTags: Array<string>
}

interface ReduxProps {

}

type TagManagerState = {

}

type TagManagerProps = OwnProps & ReduxProps

class TagManager extends React.Component<TagManagerProps, TagManagerState>{
  constructor(props: TagManagerProps) {
    super(props)
    this.state = {

    }
  }

  render() {
    return this.props.postTags.map((tag: string, idx: number) => {
      return (
        <i key={idx} className="tag">{tag}</i>
      )
    })
  }
}

export default TagManager
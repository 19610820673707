import * as types from "../shared-store/actions/actionTypes"
import initialState from "../initialState"

export default function externalServicesReducer(state: any = initialState.externalServicesReducer, action: any) {
  switch (action.type) {
    case types.REQUEST_BUNNY_FONTS_LIST:
      return {
        ...state,
        errorFetchingBunnyFontsList: false,
        receiveBunnyFontsListForFilter: undefined,
        loadingBunnyFontsList: true,
      }
    case types.RECEIVE_BUNNY_FONTS_LIST:
      let fontsForSelect = []
      let response = typeof action.response === "string" ? JSON.parse(action.response) : action.response
      for (const key in response) {
        fontsForSelect.push({
          value: response[key].familyName,
          label: response[key].familyName,
          variants: response[key].weights,
        })
      }
      return {
        ...state,
        errorFetchingBunnyFontsList: false,
        receiveBunnyFontsListForSelect: fontsForSelect,
        receiveBunnyFontsListForFilter: response,
        loadingBunnyFontsList: false,
      }
    case types.ERROR_FETCHING_BUNNY_FONTS_LIST:
      return {
        ...state,
        errorFetchingBunnyFontsList: action.error,
        receiveBunnyFontsListForFilter: undefined,
        loadingBunnyFontsList: false,
      }
    default:
      return state
  }
}

import React from 'react'
import { ErrorBoundaryProps, ErrorBoundaryState } from './interface';

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    /**
     * don't show errors for public pages on production environment
     */
    if (
      !process.env.REACT_APP_STAGING &&
      process.env.NODE_ENV === `production` &&
      this.props.expManager &&
      !this.props.expManager.enable
    )
      return

    // show error
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  renderMsg() {
    return (
      <details style={{ whiteSpace: "pre-wrap" }}>
        <strong>{this.state.error && this.state.error.toString()}</strong>
        {this.state.errorInfo.componentStack}
      </details>
    )
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className='component-error'>
          <h2>Component Crashed</h2>
          <span>An error ocurred!</span>
          <span>Show the below details to Fluxio support.</span>
          {this.renderMsg()}
        </div>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

export default ErrorBoundary;

import React, { Fragment } from "react"
import { Modal } from "react-bootstrap"

class ConfirmAction extends React.Component {
  constructor() {
    super()
    this.state = {
      modalState: false,
    }
  }

  /**
   * send confirmation signal to parent when hasConfirmation is true
   * disableConfirmation is used when we want to conditionaly use the confirmation modal
   * @param {*} hasConfirmation user confirmed or canceled the process
   */
  handleModal(e, hasConfirmation = false) {
    if (!this.props.disableConfirmation) {
      e && e.stopPropagation()
      e && e.preventDefault()
      this.setState(
        {
          ...this.state,
          modalState: !this.state.modalState,
        },
        () => {
          if (this.props.onClick) this.props.onClick(e)
          if (hasConfirmation) this.props.hasConfirmation()
        }
      )
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.children ? (
          <div className={this.props.className ? this.props.className : ""} onClick={(e) => this.handleModal(e)}>
            {this.props.children}
          </div>
        ) : (
          <button onClick={(e) => this.handleModal(e)} className={`btn btn-fluxio btn-em ${this.props.btnClassName}`}>
            {this.props.btnLabel}
          </button>
        )}
        <Modal
          className='big-picture modal-fluxio modal-fluxio-em'
          size='lg'
          show={this.state.modalState}
          onHide={() => this.props.closeModal()}
        >
          <Modal.Header>
            <Modal.Title>Please confirm your action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.props.message}</p>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-fluxio btn-ghost' onClick={(e) => this.handleModal(e, false)}>
              Cancel
            </button>
            <button className='btn btn-fluxio btn-primary' onClick={(e) => this.handleModal(e, true)}>
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
}

export default ConfirmAction

class CheckDuplicated {
  /**
   * 
   * @param {Array} content 
   * @param {object} value 
   */
  static posts(content, value) {
    if (!content || !value)
      return false

    let foundDuplicated = false
    foundDuplicated = content.filter(tag => tag.id === value.id)
    return foundDuplicated.length > 0 ? true : false
  }
}

export default CheckDuplicated
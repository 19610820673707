import React, { Dispatch, SetStateAction, useState } from "react"
import { AgentId, AgentIdSchema, ChatMessage, FluxioPostId, JobTaskId, ProfileName, parseAgentId } from "./jobsSchema"
import ChatConversation, { ChatItem, dropNonMensages } from "./chatConversation"
import { ContextInput, ContextV, JobRequests, JobsApi } from "./jobsApi"
import { SelectedContext } from "../../../lib/server-driven-forms/FormRender2"
import { match } from "ts-pattern"
import { Arrays } from "../../../lib/immutable"
import { DocumentViewerActions } from "./documentViewerActions"
import { ButtonGroup, Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap"
import { optionFromNullable } from "../../../lib/monads/option/option"
import { Numbers } from "../../../lib/numbers"
import ConfirmAction from "../marketplace/components/confirmAction"
import { UuidType } from "../../../lib/brand/UuidType"

export function EmptyAssistantPanel(): JSX.Element {
  return (
    <div className='r-panel-container'>
      <div className='r-panel-nav'>
        {/*TABS?*/} <span>Select a topic</span>
      </div>
      <div className='r-panel-header'></div>
      <div className='r-panel-content'>{/*ASSIsTANT / job form receives input and state*/}</div>
    </div>
  )
}

// a placeholder for a message
// holds its own text, can update itself and remove itself once completed
export interface StreamingReponseState {
  type: "streaming-response"
  task: JobTaskId
  loading: boolean
  completed(text: string): void
}

export interface ResponseLoader {
  type: "response-loader"
}

function makeStreaming(
  idx: number,
  task: JobTaskId,
  setItems: (f: (prev: ChatItem[]) => ChatItem[]) => void
): StreamingReponseState {
  return {
    type: "streaming-response",
    task: task,
    loading: true,
    // once completed replace the streaming response with a message with the full text
    completed(text: string): void {
      const message: ChatMessage = {
        type: "assistant",
        message: text,
      }
      setItems((prev) => Arrays.setAt(prev, idx, message))
    },
  }
}

interface AssistantPanelProps {
  postId?: FluxioPostId // TODO: this could be not optional
  jobsClient: JobsApi
  profileName: ProfileName
  actions: DocumentViewerActions.All
}

/* POSTID reflects on the list of Assistants + topics + the inputs for the job selected and maybe something showing which is selected?*/
const AssistantPanel: React.FC<AssistantPanelProps> = (props) => {
  const [contextManager, setContextManager] = useState<boolean>(false)
  const [chatMessages, setChatMessages] = useState<ChatItem[]>([])
  const [contextInputValues, setContextInputValues] = useState<ContextInput[]>([])
  const [contextValues, setContextValues] = useState<ContextV[]>([])
  const [selectedAgent, setSelectedAgent] = useState<AgentId | undefined>(undefined)

  const updateContext = (v: ContextInput[]) => {
    const out: ContextV[] = v.map((p) =>
      match<ContextInput, ContextV>(p)
        .with({ type: "manual" }, (p) => ({ type: "manual", value: p.value }))
        .with({ type: "post" }, (p) => ({ type: "post", value: p.value.sourceId }))
        .exhaustive()
    )
    setContextInputValues(v)
    setContextValues(out)
  }

  const manageContextClick = () => {
    setContextManager((prevState) => !prevState)
  }

  const actions: AssistantHeaderProps["actions"] = {
    test2: () => {
      if (props.postId) {
        // store the current index so that we know what state to rewrite later
        const newIdx = chatMessages.length
        // XXX put 4 loaders at the end, kinda weird that we have to know it creates 4 placeholders
        setChatMessages((prev) => [
          ...prev,
          { type: "response-loader" },
          { type: "response-loader" },
          { type: "response-loader" },
          { type: "response-loader" },
        ])
        props.jobsClient
          .startJob(JobRequests.businessOverview2(props.postId, contextValues, dropNonMensages(chatMessages)))
          .then((taskIds) => {
            setChatMessages((prev) =>
              taskIds.reduce((acc, taskId, offset) => {
                const index: number = newIdx + offset
                return Arrays.setAt(acc, index, makeStreaming(index, taskId, setChatMessages))
              }, prev)
            )
          })
      }
    },

    systemThinkingShort: () => {
      if (props.postId) {
        // store the current index so that we know what state to rewrite later
        const newIdx = chatMessages.length
        // XXX put 1 loaders at the end, kinda weird that we have to know it creates 1 placeholders
        setChatMessages((prev) => [...prev, { type: "response-loader" }])
        props.jobsClient
          .startJob(JobRequests.systemThinkingShort(props.postId, contextValues, dropNonMensages(chatMessages)))
          .then((taskIds) => {
            setChatMessages((prev) =>
              taskIds.reduce((acc, taskId, offset) => {
                const index: number = newIdx + offset
                return Arrays.setAt(acc, index, makeStreaming(index, taskId, setChatMessages))
              }, prev)
            )
          })
      }
    },
    systemThinkingLong: () => {
      if (props.postId) {
        // store the current index so that we know what state to rewrite later
        const newIdx = chatMessages.length
        // XXX put 1 loaders at the end, kinda weird that we have to know it creates 1 placeholders
        setChatMessages((prev) => [...prev, { type: "response-loader" }])
        props.jobsClient
          .startJob(JobRequests.systemThinkingLong(props.postId, contextValues, dropNonMensages(chatMessages)))
          .then((taskIds) => {
            setChatMessages((prev) =>
              taskIds.reduce((acc, taskId, offset) => {
                const index: number = newIdx + offset
                return Arrays.setAt(acc, index, makeStreaming(index, taskId, setChatMessages))
              }, prev)
            )
          })
      }
    },
    initiatives: () => {
      if (props.postId) {
        // store the current index so that we know what state to rewrite later
        const newIdx = chatMessages.length
        // XXX put 2 loaders at the end, kinda weird that we have to know it creates 1 placeholders
        setChatMessages((prev) => [...prev, { type: "response-loader" }, { type: "response-loader" }])
        props.jobsClient
          .startJob(JobRequests.initiatives(props.postId, contextValues, dropNonMensages(chatMessages)))
          .then((taskIds) => {
            setChatMessages((prev) =>
              taskIds.reduce((acc, taskId, offset) => {
                const index: number = newIdx + offset
                return Arrays.setAt(acc, index, makeStreaming(index, taskId, setChatMessages))
              }, prev)
            )
          })
      }
    },
    pickAgent: (i: AgentId | undefined) => setSelectedAgent(i),
    clearAllChatMessages: () => {
      setChatMessages([])
    },
  }

  if (!props.postId) return <EmptyAssistantPanel />
  return (
    <div className='r-panel-container'>
      {/* commented for now because i did't see tabs were supposed to be here
      <div className='r-panel-nav'>{TABS}</div>
      <div className='panel-separator'></div>
    */}
      <div className='r-panel-header'>
        <AssistantHeader
          contextManagerShow={contextManager}
          manageContextClick={manageContextClick}
          actions={actions}
          agent={selectedAgent}
        ></AssistantHeader>
      </div>
      <div className='panel-separator'></div>

      <div className='r-panel-content'>
        {/*ASSIsTANT / job form receives input and state*/}
        {contextManager && (
          <AssistantContextM
            profileName={props.profileName}
            jobsClient={props.jobsClient}
            values={contextInputValues}
            updateContext={updateContext}
          ></AssistantContextM>
        )}
        {!contextManager && (
          <AssistantPost
            profileName={props.profileName}
            jobsClient={props.jobsClient}
            postId={props.postId}
            setMessages={setChatMessages}
            messages={chatMessages}
            context={contextValues}
            actions={props.actions}
            agent={{ selected: selectedAgent }}
          />
        )}
      </div>
    </div>
  )
}

//https://static-media.fluxio.cloud/dojobdemo/yYMMrPC2_2.png
//https://static-media.fluxio.cloud/dojobdemo/iNSiwo3H.png

const styleBtnFluxio2 = (
  <style type='text/css'>
    {
      // TODO: fix
      `
    .btn-fluxio2 {
      background-color: var(--fluxio-blue);
      color: white;
      font-family: 'Space Grotesk', sans-serif;
    }
    `
    }
  </style>
)

//Header with context option
interface AssistantHeaderProps {
  contextManagerShow: boolean
  manageContextClick: () => void
  actions: {
    test2(): void
    systemThinkingShort(): void
    systemThinkingLong(): void
    pickAgent(i: AgentId | undefined): void
    clearAllChatMessages(): void
    initiatives(): void
  }
}

const SelectJob: React.FC<{ actions: AssistantHeaderProps["actions"] }> = ({ actions }) => {
  const [selectedIdx, setSelectedIdx] = useState<number | undefined>(undefined)

  const jobs: { name: string; action: () => void }[] = [
    { name: "Business overview", action: () => actions.test2() },
    { name: "System Thinking (Short)", action: () => actions.systemThinkingShort() },
    { name: "System Thinking (Long)", action: () => actions.systemThinkingLong() },
    { name: "Initiatives", action: () => actions.initiatives() },
  ]

  const startDisabled = selectedIdx === undefined
  const startLabel = startDisabled ? "Select a job first" : "Start"
  const selectLabel = selectedIdx === undefined ? "" : jobs[selectedIdx].name

  return (
    <ButtonGroup>
      <button
        disabled={startDisabled}
        className='btn btn-fluxio2 btn-primary'
        onClick={() => selectedIdx && jobs[selectedIdx].action()}
      >
        {startLabel}
      </button>
      {styleBtnFluxio2}
      <DropdownButton
        onSelect={(e) => optionFromNullable(e).andThen(Numbers.parseIntOption).forEach(setSelectedIdx)}
        variant='fluxio2'
        as={ButtonGroup}
        id='select-job'
        title={selectLabel}
      >
        {jobs.map((it, idx) => (
          <Dropdown.Item key={idx} eventKey={idx}>
            {it.name}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </ButtonGroup>
  )
}

function aId(number: number): AgentId {
  return UuidType.parse(AgentIdSchema, `00000000-0000-0000-0000-00000000000${number}`).unwrap()
}

const defaultAgent = { name: "Default Agent", id: undefined }
const agents: { name: string; id: AgentId | undefined }[] = [
  defaultAgent,
  { name: "Social Media Specialist", id: aId(0) },
  { name: "Blank", id: aId(1) },
  { name: "Corporate Identity Specialist", id: aId(2) },
  { name: "Attention Audit", id: aId(3) },
  { name: "System Prompt", id: aId(4) },
  { name: "OWL", id: aId(5) },
  { name: "MidJourney", id: aId(6) },
]

const SelectAgent: React.FC<{
  agent: AgentId | undefined
  pickAgent(i: AgentId | undefined): void
}> = ({ agent, pickAgent }) => {
  const selected: { name: string; id: AgentId | undefined } = agents.find((i) => i.id === agent) ?? defaultAgent

  return (
    <Dropdown
      onSelect={(e) =>
        pickAgent(
          optionFromNullable(e)
            .andThen((e) => parseAgentId(e).ok())
            .unwrapOrUndefined()
        )
      }
    >
      {styleBtnFluxio2}
      <Dropdown.Toggle variant='fluxio2' id='dropdown-basic'>
        {selected.name}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {agents.map((i, idx) => (
          <Dropdown.Item key={idx} eventKey={i.id}>
            {i.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

//Header with context option
interface AssistantHeaderProps {
  contextManagerShow: boolean
  manageContextClick: () => void
  actions: {
    test2(): void // TODO: rename
    systemThinkingShort(): void
    systemThinkingLong(): void
    pickAgent(i: AgentId | undefined): void
    clearAllChatMessages(): void
    initiatives(): void
  }
  agent: AgentId | undefined
}

function AssistantHeader(props: AssistantHeaderProps): JSX.Element {
  return (
    <Container>
      <Row style={{ alignItems: "center" }}>
        <Col>
          <Row>
            <SelectJob actions={props.actions} />
          </Row>
          <Row>
            <SelectAgent agent={props.agent} pickAgent={props.actions.pickAgent} />
          </Row>
        </Col>
        <Col xs lg='1'>
          <ConfirmAction
            title='Clear all chat messages'
            className=''
            message='Are you sure you want to clear this chat?'
            hasConfirmation={() => props.actions.clearAllChatMessages()}
          >
            <i title='Clear all chat messages' className='fas fa-times' style={{ cursor: "pointer" }}></i>
          </ConfirmAction>
        </Col>
        <Col xs lg='1'>
          <img
            title='Manage Knowledge Context'
            alt='pokemon hoothoot representing knowledge as context'
            className={`hoot-hoot ${props.contextManagerShow ? `show` : ``}`}
            style={{ maxHeight: "36px", cursor: "pointer" }}
            src='https://static-media.fluxio.cloud/dojobdemo/yYMMrPC2_2.png'
            onClick={props.manageContextClick}
          />
        </Col>
      </Row>
    </Container>
  )
}
interface AssistantContextMProps {
  profileName: ProfileName
  jobsClient: JobsApi
  updateContext: (values: ContextInput[]) => void
  values: ContextInput[]
}
function AssistantContextM(props: AssistantContextMProps): JSX.Element {
  return (
    <div>
      <span>Post Tree + Ancestors</span>
      {/* Render add context + list of selected*/}
      <SelectedContext
        key={0}
        field={{ type: "select-context", id: "chat-context", label: "Context" }}
        profile={props.profileName}
        cl={props.jobsClient}
        value={props.values}
        setValue={props.updateContext}
      ></SelectedContext>
    </div>
  )
}
//ASSISTAN CHAT WITH POSTID
interface AssistantPostProps {
  postId: FluxioPostId
  jobsClient: JobsApi
  profileName: ProfileName
  messages: ChatItem[]
  setMessages: Dispatch<SetStateAction<ChatItem[]>>
  context: ContextV[]
  actions: DocumentViewerActions.All
  agent: {
    selected: AgentId | undefined
  }
}
function AssistantPost(props: AssistantPostProps): JSX.Element {
  return (
    <ChatConversation
      postId={props.postId}
      profileName={props.profileName}
      jobsClient={props.jobsClient}
      messages={props.messages}
      setMessages={props.setMessages}
      context={props.context}
      actions={props.actions}
      agent={props.agent}
    />
  )
}

export default AssistantPanel

import React from 'react'

class ErrorHandler {
  static arrErrors(errorObj: any) {
    if (!errorObj) {
      return false
    }

    if (typeof errorObj !== 'object' && errorObj !== null) {
      return [<p key='error' className='error'>Unknown error.</p>]
    }

    let arrErrorMsgs: Array<Object> = []
    //Check if we are dealing with an array or an object
    if (errorObj.constructor === Array) {
      for (let errorMsg of errorObj) {
        arrErrorMsgs = [...arrErrorMsgs, <p key={errorMsg} className='msg'>{errorMsg}</p>]
      }
    }
    else {
      //Enter in every object position
      for (let errorType in errorObj) {
        if (typeof errorObj[errorType] === 'string' || typeof errorObj[errorType] === 'number') {
          arrErrorMsgs = [...arrErrorMsgs, <p key={errorType} className='msg'><strong>{errorObj[errorType]}</strong></p>]
        }
        else if (errorObj[errorType].constructor === Array) {
          for (let errorMsg of errorObj[errorType]) {
            arrErrorMsgs = [...arrErrorMsgs, <p key={errorMsg} className='msg'>{errorMsg}</p>]
          }
        }

      }
    }

    if (arrErrorMsgs.length === 0 && typeof errorObj === 'object') {
      return [<p key='error' className='error'>Fail to Fetch content - {JSON.stringify(errorObj)}</p>]
    }

    return arrErrorMsgs
  }
}

export const renderSingleError = (
  field: string,
  errorList: { [key: string]: Array<string> } | undefined
): string | boolean => {
  if (errorList) {
    const fields = Object.keys(errorList)
    if (fields.includes(field)) {
      if (errorList[field] && errorList[field][0]) return errorList[field][0]
    }
  }
  return false
}

export default ErrorHandler
import { ResponsiveData } from "../experienceManager/finder/inputs/bobControllerTypes"
import { SingleObject } from "../experienceManager/types/singleObjectTypes"
import { ViewPortSizeState, DESKTOP, MOBILE } from "../experienceManager/viewPortTypes/viewPortTypes"

type Breakpoint = "desktop" | "tablet" | "mobile" | "fullscreen"

/**
 * check for prop value existance
 * @param obj
 * @param prop
 */
export function objValueExists(obj: any, prop: string) {
  return obj.hasOwnProperty(prop) && obj[prop] !== null && obj[prop] !== undefined
}

/**
 * Check if value exists
 * @param value
 */
export function valueExists(value: any) {
  return value || value === false || value === 0 || value === ""
}

export function isNullUndefined<A>(value: A): boolean {
  return typeof value === "undefined" || value === null
}

export function isDesktop<A>(breakpoint: number, ifDesktop: A, orElse: A): A {
  return getDeviceSize(breakpoint) === "desktop" ? ifDesktop : orElse
}

export function isTablet<A>(breakpoint: number, isTablet: A, orElse: A): A {
  return getDeviceSize(breakpoint) === "tablet" ? isTablet : orElse
}

export function isMobile<A>(breakpoint: number, isMobile: A, orElse: A): A {
  return getDeviceSize(breakpoint) === "mobile" ? isMobile : orElse
}

export function getDeviceSize(viewPortSizeState: ViewPortSizeState): Breakpoint {
  if (viewPortSizeState >= DESKTOP) {
    return "desktop"
  }
  if (viewPortSizeState > MOBILE) {
    return "tablet"
  }
  if (viewPortSizeState === "100%_NO_BARS") return "fullscreen"
  return "mobile"
}

export function getComposedUuid(obj: SingleObject): string {
  if (!obj) return ""
  if (obj.selectedInstanceId) {
    return `${obj.uuid}-${obj.selectedInstanceId}`
  }
  return obj.uuid
}

export function clamp(min: number, max: number, x: number): number {
  if (x < min) return min
  else if (x > max) return max
  else return x
}

export function loadThreshold(imgBytes?: number): number {
  const min = 250
  const max = 1000
  if (!imgBytes) {
    return min
  }
  return clamp(min, max, (imgBytes / 400) * 100 + 250)
}

export function shouldBeNull(
  obj: ResponsiveData<any>,
  shouldParseInt: boolean = false,
  shouldToString: boolean = false
) {
  if (obj.breakpoint !== "desktop" || obj.state !== "default") {
    if (!obj.isDiferent) {
      return null
    } else return shouldParseInt ? parseFloat(obj.value) : shouldToString ? obj.value.toString() : obj.value
  }
  return obj.value && shouldParseInt
    ? parseFloat(obj.value)
    : obj.value && shouldToString
    ? obj.value.toString()
    : obj.value
}

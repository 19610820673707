import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function votingReducer(state = initialState.votingReducer, action: any) {

  switch (action.type) {
    case types.INVALID_VOTING_ACTION:
      return {
        ...state,
        id: action.id,
        error: action.error,
        loading: false
      }
    case types.REQUEST_VOTING_ACTION:
      return {
        ...state,
        id: action.id,
        vote: action.vote,
        loading: true
      }
    case types.RECEIVE_VOTING_ACTION:
      return {
        ...state,
        id: action.id,
        vote: action.vote,
        response: action.response,
        loading: false
      }
    default:
      return state
  }

}

import { ZodType, ZodTypeDef } from "zod"
import { None, Option, Some } from "./monads"

import * as D from "io-ts/Decoder"

export interface Parser<A> {
  parseOption(a: any): Option<A>
  parseUnsafe(a: any): A
  map<B>(f: (a: A) => B): Parser<B>
}

interface ParserBase<A> {
  parseOption(a: any): Option<A>
  parseUnsafe(a: any): A
}

export class MkParser {
  static void(): Parser<void> {
    return this.mkParser({
      parseOption: (a: any) => Some(undefined),
      parseUnsafe: (a: any) => {
        return
      },
    })
  }

  static any(): Parser<any> {
    return this.mkParser({
      parseOption: (a: any) => Some(a),
      parseUnsafe: (a: any) => a,
    })
  }

  private static mkParser<A>(parse: ParserBase<A>): Parser<A> {
    return {
      parseOption: parse.parseOption,
      parseUnsafe: parse.parseUnsafe,
      map: <B>(f: (a: A) => B) =>
        MkParser.mkParser({
          parseOption: (a: any) => parse.parseOption(a).map(f),
          parseUnsafe: (a: any) => f(parse.parseUnsafe(a)),
        }),
    }
  }

  // warning: A can still be inferred as any which is fucked up
  static zod<A, D extends ZodTypeDef, B>(p: ZodType<A, D, B>): Parser<A> {
    return this.mkParser({
      parseOption: (a: any) => {
        const r = p.safeParse(a)
        return r.success ? Some(r.data) : None
      },
      parseUnsafe: (a: any) => p.parse(a),
    })
  }

  static decoder<A>(p: D.Decoder<unknown, A>): Parser<A> {
    return this.mkParser({
      parseOption: (a: any) => {
        const r = p.decode(a)
        return r._tag === "Right" ? Some(r.right) : None
      },
      parseUnsafe: (a: any) => {
        const r = p.decode(a)
        if (r._tag === "Left") throw new Error(D.draw(r.left))
        return r.right
      },
    })
  }
}

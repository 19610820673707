import * as defaultObj from "../experienceManager/defaultObj/defaultObj"
import JSONpath from "jsonpath"
import cloneDeep from "lodash/cloneDeep"
import set from "lodash/set"
import * as componentIconTypes from "../experienceManager/componentIconTypes/componentIconTypes"
import ComponentNames from "../../../areas/public-page/shared-public-page/fluxio-components/componentNames"
import * as inlineContentTypes from "../experienceManager/finder/content/inlineContent/inlineContentTypes"
import { handleInstanceOverrides, handleFilterSymbolObject } from "./symbols"
import { CMS, WRITE } from "../experienceManager/finder/inputs/textController/textContentHelper"
import { DummyPost } from "../experienceManager/finder/content/contentPageHelper"
import { Component } from "../experienceManager/types/componentTypes"
import { SingleObject } from "../experienceManager/types/singleObjectTypes"
import {
  FormField,
  FormSettings,
  Formula,
  ObjectContent,
  ObjectContentOverride,
  Post,
} from "../experienceManager/types/objectContentTypes"
import { EMEditorType, ExpManager, PageTypes } from "../experienceManager/types/pageTypes"
import { CONTENT_TYPE_INSTANCE, CONTENT_TYPE_SYMBOL, Instance } from "../experienceManager/finder/symbols/symbolTypes"
import { Styles } from "../experienceManager/types/stylesTypes"
import { FColor } from "../foundationStyles/foundationStylesTypes"
import { FontLabel } from "../stylesheet/stylesheetTypes"
import { stringOrUndefined } from "../../../lib/typecheck/typeschek"

/**
 * Build path from string recurvely
 * : denotes an object ex:'from.contacts:picture.large'
 * @param {*} obj
 * @param {*} path
 */
export function cleanPath(obj: Post | FormField, path: string) {
  return multiIndex(obj, path.split(":"))
}

export function multiIndex(obj: any, path: Array<string>): any {
  return path.length ? multiIndex(obj[path[0]], path.slice(1)) : obj
}

//Probably temp just to get the $.message field
export function getPostStringByMappedValue(post: Post, mappedField: string) {
  const value = JSONpath.query(post, mappedField)
  return value && value.length > 0 ? value[0] : undefined
}

export function datasetRender(
  post: Post | undefined,
  customAttributesEnable: boolean,
  customAttributesMappedField: string | undefined,
  customAttributesValue: string | undefined,
  customAttributesContentSrc: string | undefined
): { "data-trackinfo": string } | {} {
  if (customAttributesEnable) {
    if (post && customAttributesContentSrc === "post" && customAttributesMappedField) {
      const postFieldValue = getPostStringByMappedValue(post, customAttributesMappedField)
      if (postFieldValue && postFieldValue !== "") {
        return { "data-trackinfo": postFieldValue }
      }
    }
    if (post && customAttributesContentSrc === "custom" && customAttributesValue) {
      return { "data-trackinfo": customAttributesValue }
    }
  }
  return {}
}

/**
 * get post string value from text mapped value
 * @param {*} post
 * @param {*} obj
 * @param {*} field
 */
export function getStringField(
  post: Post,
  obj: SingleObject,
  pageResponse: PageTypes,
  field: string,
  overrides?: ObjectContentOverride
): string {
  if (obj.content?.inlineContent && obj.content.inlineContent.safeInlineContent) {
    /**
     * Check first the contentSrc
     * If its customText we know we need to use the inlineContent
     * If its post we use the post content
     */
    const contentSrc = getOverrideInlineContentValues(`${field}.${inlineContentTypes.CONTENT_SRC_TEXT}`, {
      inlineContent: obj.content.inlineContent,
      overrides,
    })
    if (contentSrc === WRITE) {
      return getOverrideInlineContentValues(`${field}.${inlineContentTypes.CUSTOM_TEXT}`, {
        inlineContent: obj.content.inlineContent,
        overrides,
      })
    } else if (contentSrc === CMS) {
      const mappedValue = getOverrideInlineContentValues(`${field}.${inlineContentTypes.MAPPED_VALUE}`, {
        inlineContent: obj.content.inlineContent,
        overrides,
      })
      if (mappedValue?.startsWith("$")) {
        let value = JSONpath.query(post, mappedValue)
        return value && value.length > 0 ? value[0] : ""
      }
      return (mappedValue && cleanPath(post, mappedValue)) || ""
    } else {
      //contentSrc === CONTENT_PAGE
      const mappedValue = getOverrideInlineContentValues(`${field}.${inlineContentTypes.MAPPED_VALUE}`, {
        inlineContent: obj.content.inlineContent,
        overrides,
      })
      const postObj = pageResponse.contentPage?.content || DummyPost
      if (mappedValue?.startsWith("$") && postObj) {
        let value = JSONpath.query(postObj, mappedValue)
        return value && value.length > 0 ? value[0] : ""
      }
      return (mappedValue && cleanPath(postObj as Post, mappedValue)) || ""
    }
  }
  //Use old logic since no inlineContent is available
  //DEPRECATED
  else {
    const styles = obj.styles ? obj.styles : obj
    // @ts-ignore TODO: styles should allways be of type Styles, using the obj gives the SingleObject type
    const template = styles.bobs && styles.bobs[field] ? styles.bobs[field] : styles
    if (template.mappedValue === "customText") return template.customText
    let mappedValueCheck = "mappedValue"
    if (!template.mappedValue && template.mappedvalue) {
      mappedValueCheck = "mappedvalue"
    }
    if (template && template[mappedValueCheck]) {
      if (template[mappedValueCheck].startsWith("$")) {
        let value = JSONpath.query(post, template[mappedValueCheck])
        return value && value.length > 0 ? value[0] : ""
      }
      return cleanPath(post, template[mappedValueCheck]) || ""
    }
    return "Missing Bob Text"
  }
}
/**
 * get post string value from text mapped value
 * @param {*} post
 * @param {*} obj
 * @param {*} field
 */
export function getStringFieldV2(
  post: Post,
  obj: SingleObject,
  pageResponse: PageTypes,
  field: string,
  overrides?: ObjectContentOverride
): string {
  /**
   * Check first the contentSrc
   * If its customText we know we need to use the inlineContent
   * If its post we use the post content
   */
  const contentSrc = getOverrideInlineContentValues(`${field}.${inlineContentTypes.CONTENT_SRC_TEXT}`, {
    inlineContent: obj.content.inlineContent,
    overrides,
  })
  if (contentSrc === WRITE) {
    return getOverrideInlineContentValues(`${field}.${inlineContentTypes.CUSTOM_TEXT}`, {
      inlineContent: obj.content.inlineContent,
      overrides,
    })
  } else if (contentSrc === CMS) {
    const mappedValue = getOverrideInlineContentValues(`${field}.${inlineContentTypes.MAPPED_VALUE}`, {
      inlineContent: obj.content.inlineContent,
      overrides,
    })
    if (mappedValue?.startsWith("$")) {
      let value = JSONpath.query(post, mappedValue)
      return value && value.length > 0 ? value[0] : ""
    }
    return (mappedValue && cleanPath(post, mappedValue)) || ""
  } else {
    //contentSrc === CONTENT_PAGE
    const mappedValue = getOverrideInlineContentValues(`${field}.${inlineContentTypes.MAPPED_VALUE}`, {
      inlineContent: obj.content.inlineContent,
      overrides,
    })
    const postObj = pageResponse.contentPage?.content || DummyPost
    if (mappedValue?.startsWith("$") && postObj) {
      let value = JSONpath.query(postObj, mappedValue)
      return value && value.length > 0 ? value[0] : ""
    }
    return (mappedValue && cleanPath(postObj as Post, mappedValue)) || ""
  }
}

/**
 *
 * @param formFieldProp
 * @param form
 * @param field
 * @param content
 * @returns
 */
export function getFormField(
  form: SingleObject["content"]["form"],
  formFieldProp: keyof Omit<FormField, "selectOptions">,
  content: SingleObject["content"],
  bobProp: string
): string | boolean {
  const formField: string | undefined = content.inlineContent[`${bobProp}.formField`]
  const fieldValue = formField && form?.fields[formField][formFieldProp]

  return fieldValue ? fieldValue : ""

  // TODO: backend is not handling contentSrc for now
  // let placeholder = content.inlineContent[`${bobProp}.${formFieldProp}`]

  // if (
  //   getOverrideInlineContentValues(`${bobProp}.contentSrc`, {
  //     inlineContent: content.inlineContent,
  //     overrides: content.overrides,
  //   }) === FORM
  // ) {
  //   const formField: string = content.inlineContent[`${bobProp}.formField`]
  //   placeholder = form?.fields[formField][formFieldProp]
  // }

  // return placeholder
}

export function getFormProp(
  form: SingleObject["content"]["form"],
  formFieldProp: keyof Omit<FormSettings, "submitAction" | "captcha" | "fields">,
  content: SingleObject["content"],
  bobProp: string
): string | number | boolean {
  return form?.[formFieldProp] || ""

  // TODO: backend is not handling contentSrc for now
  // let placeholder = content.inlineContent[`${bobProp}.${formFieldProp}`]

  // if (
  //   getOverrideInlineContentValues(`${bobProp}.contentSrc`, {
  //     inlineContent: content.inlineContent,
  //     overrides: content.overrides,
  //   }) === FORM
  // ) {
  //   placeholder = form?.[formFieldProp]
  // }

  // return placeholder
}

/**
 * get post string value from media mapped value
 * @param {*} post
 * @param {*} obj
 * @param {*} field
 */
export function getMediaStringField(
  post: Post,
  content: ObjectContent,
  field: string,
  overrides?: ObjectContentOverride
): string | undefined {
  let bobContent: string | undefined = getOverrideInlineContentString(`${field}.image-mappedValue`, {
    inlineContent: content,
    overrides,
  })
  let contentSrc = getOverrideInlineContentString(`${field}.contentSrc`, { inlineContent: content, overrides })
  if (contentSrc === "custom") {
    return getOverrideInlineContentString(`${field}.src`, { inlineContent: content, overrides })
  }
  if (bobContent) {
    if (bobContent.startsWith("$")) {
      let value = JSONpath.query(post, bobContent)
      return stringOrUndefined(value && value.length > 0 ? value[0] : undefined)
    }
    return stringOrUndefined(cleanPath(post, bobContent))
  }
  return "Missing Bob Text"
}

/**
 * Check if an object is the root of the objects tree
 *
 * @param {*} objectType
 */
export function isRootObject(objectType: SingleObject["type"]) {
  return objectType === "pageRoot" || objectType === "objectTemplate" || objectType === "symbol"
}

/**
 * get object from different list based on object type
 *
 * @param {*} parentObject
 * @param {*} objectId
 * @param {*} pageResponse
 */
// TODO: seperate functions for singleObject/instance
export function handleObjectType(
  parentObject: any,
  objectId: SingleObject["uuid"],
  pageResponse: PageTypes,
  emEditorType: EMEditorType
) {
  if (emEditorType === "symbol") {
    return pageResponse.objects[objectId]
  }
  if (parentObject.fromSymbolTree || parentObject.isInstanceOf) {
    let symbol = cloneDeep(pageResponse.symbols[parentObject.fromSymbolTree || parentObject.isInstanceOf])
    const instance = pageResponse.objects[parentObject.selectedInstanceId || parentObject.uuid]
    symbol.objects = handleInstanceOverrides(instance, symbol.objects)
    return symbol.objects[objectId]
  }
  return pageResponse.objects[objectId]
}

/**
 * use instance coorresponding symbol object
 * used in componentHandler main rende, TODO: review
 *
 * @param {*} obj
 * @param {*} pageResponse
 */
// TODO: seperate functions for singleObject/instance
export function handleInstanceObject(obj: any, pageResponse: PageTypes) {
  if (obj.type === "instance") {
    let symbol = cloneDeep(pageResponse.symbols[obj.isInstanceOf])
    let symbolObj = handleFilterSymbolObject(obj, pageResponse.symbols, symbol)
    obj = handleInstanceOverrides(obj, undefined, symbolObj)
  }
  return obj
}

/**
 * for symbol objects use complex uri
 * @param {*} obj
 */
export function handleObjectUri(obj: SingleObject): string {
  if (obj.fromSymbolTree && obj.selectedInstanceId) {
    return `${obj.uri}_${obj.selectedInstanceId}`
  }
  return obj.uri ?? ""
}

/**
 * Check wich objects list to use
 * If we'r dealing with normal objects use the objects list
 * If we'r dealing we symbol objects use the symbols list
 * @param {*} pageObj
 * @param {*} object
 */
export function handleObjectsListType(pageObj: PageTypes, object: SingleObject, emEditorType: EMEditorType) {
  if (emEditorType === "symbol") return pageObj.objects
  return object.fromSymbolTree ? pageObj.symbols[object.fromSymbolTree].objects : pageObj.objects
}

export function handlePostLink(mappedValue: keyof Component["bob"]["mappedValues"], post: Post) {
  if (post && mappedValue && mappedValue.startsWith("$")) {
    let value = JSONpath.query(post, mappedValue)
    return value && value.length > 0 ? value[0] : ""
  }
  return undefined
}

export function getCPMappedValue(mappedValue: keyof Component["bob"]["mappedValues"], post: Post | undefined) {
  const cuttedMappedValue = mappedValue.slice(0, -3)
  //TODO get content from post or content page
  if (post && cuttedMappedValue && cuttedMappedValue.startsWith("$")) {
    let value = JSONpath.query(post, cuttedMappedValue)
    return value && value.length > 0 ? value[0] : ""
  }
  return undefined
}

/**
 * Returns true if obj has componentX as a child
 * @param {*} childName
 * @param {*} childsList
 * @param {*} obj
 * @param {*} pageResponse
 */
export function hasChildOfType(
  childName: string,
  childsList: Array<string>,
  obj: SingleObject,
  pageResponse: PageTypes,
  emEditorType: EMEditorType
) {
  let childsOfType = childsList.filter((componentId) => {
    //First we get all the object childrens
    let objectById = handleObjectType(obj, componentId, pageResponse, emEditorType)
    let objectStyleComponentId
    //We then need to check if its an instance or not, to properly get its style component Id
    if (objectById.isInstanceOf) {
      //Go to symbols
      let symbolTree = pageResponse.symbols[objectById.isInstanceOf]
      //Return the style Id
      objectStyleComponentId = symbolTree.objects[symbolTree.rootId].styles.component
    } else {
      //Return the style Id
      objectStyleComponentId = objectById.styles.component
    }
    return pageResponse.components[objectStyleComponentId].name === childName
  })
  return childsOfType.length > 0
}

/**
 * forceWrite is used to write the css rules even
 * if there are no css props to write inside the rule,
 * used in the controller for example
 * @param bobTemplate
 * @param bobType
 * @param objectId
 * @param forceWrite
 * @returns
 */
// TODO: Styles bob type should be completed
export function writeCustomCssSelectors(
  bobTemplate: any,
  bobType: any,
  objectId: SingleObject["uuid"],
  forceWrite: boolean
) {
  const defaultCss = bobTemplate?.customCss?.default
  const mobileCss = bobTemplate?.customCss?.mobile
  const tabletCss = bobTemplate?.customCss?.tablet
  const desktopCss = bobTemplate?.customCss?.desktop

  const mobileMediaQuery = "@media all and (max-width: 768px)"
  const tabletMediaQuery = "@media all and (max-width: 992px) and (min-width: 767px)"
  const desktopMediaQuery = "@media all and (min-width: 992px)"

  if (bobType.startsWith("background")) {
    const backgroundCssProperties = {
      default: {
        container: forceWrite || defaultCss?.container ? `#${objectId}{\n${defaultCss?.container || ""}\n}` : "",
        innerContainer:
          forceWrite || defaultCss?.innerContainer
            ? `#${objectId} > .${bobTemplate.wrap}{\n${defaultCss?.innerContainer || ""}\n}`
            : "",
      },
      mobile: {
        container:
          forceWrite || mobileCss?.container
            ? `${mobileMediaQuery}{\n  #${objectId}{\n${mobileCss?.container || ""}\n  }\n}`
            : "",
        innerContainer:
          forceWrite || mobileCss?.innerContainer
            ? `${mobileMediaQuery}{\n  #${objectId} > .${bobTemplate.wrap}{\n${mobileCss?.innerContainer || ""}\n  }\n}`
            : "",
      },
      tablet: {
        container:
          forceWrite || tabletCss?.container
            ? `${tabletMediaQuery}{\n  #${objectId}{\n${tabletCss?.container || ""}\n  }\n}`
            : "",
        innerContainer:
          forceWrite || tabletCss?.innerContainer
            ? `${tabletMediaQuery}{\n  #${objectId} > .${bobTemplate.wrap}{\n${tabletCss?.innerContainer || ""}\n  }\n}`
            : "",
      },
      desktop: {
        container:
          forceWrite || desktopCss?.container
            ? `${desktopMediaQuery}{\n  #${objectId}{\n${desktopCss?.container || ""}\n  }\n}`
            : "",
        innerContainer:
          forceWrite || desktopCss?.innerContainer
            ? `${desktopMediaQuery}{\n  #${objectId} > .${bobTemplate.wrap}{\n${
                desktopCss?.innerContainer || ""
              }\n  }\n}`
            : "",
      },
    }
    return backgroundCssProperties
  }

  if (bobType.startsWith("media")) {
    const mediaCssProperties = {
      default: {
        container: forceWrite || defaultCss?.container ? `#${objectId}{\n${defaultCss?.container || ""}\n}` : "",
        image: forceWrite || defaultCss?.image ? `#${objectId} > img{\n${defaultCss?.image || ""}\n}` : "",
      },
      mobile: {
        container:
          forceWrite || mobileCss?.container
            ? `${mobileMediaQuery}{\n  #${objectId}{\n${mobileCss?.container || ""}\n  }\n}`
            : "",
        image:
          forceWrite || mobileCss?.image
            ? `${mobileMediaQuery}{\n  #${objectId} > img{\n${mobileCss?.image || ""}\n  }\n}`
            : "",
      },
      tablet: {
        container:
          forceWrite || tabletCss?.container
            ? `${tabletMediaQuery}{\n  #${objectId}{\n${tabletCss?.container || ""}\n  }\n}`
            : "",
        image:
          forceWrite || tabletCss?.image
            ? `${tabletMediaQuery}{\n  #${objectId} > img{\n${tabletCss?.image || ""}\n  }\n}`
            : "",
      },
      desktop: {
        container:
          forceWrite || desktopCss?.container
            ? `${desktopMediaQuery}{\n  #${objectId}{\n${desktopCss?.container || ""}\n  }\n}`
            : "",
        image:
          forceWrite || desktopCss?.image
            ? `${desktopMediaQuery}{\n  #${objectId} > img{\n${desktopCss?.image || ""}\n  }\n}`
            : "",
      },
    }
    return mediaCssProperties
  }

  if (bobType.startsWith("text")) {
    const textCssProperties = {
      default: {
        text: forceWrite || defaultCss?.text ? `#${objectId}{\n${defaultCss?.text || ""}\n}` : "",
      },
      mobile: {
        text:
          forceWrite || mobileCss?.text
            ? `${mobileMediaQuery}{\n  #${objectId}{\n${mobileCss?.text || ""}\n  }\n}`
            : "",
      },
      tablet: {
        text:
          forceWrite || tabletCss?.text
            ? `${tabletMediaQuery}{\n  #${objectId}{\n${tabletCss?.text || ""}\n  }\n}`
            : "",
      },
      desktop: {
        text:
          forceWrite || desktopCss?.text
            ? `${desktopMediaQuery}{\n  #${objectId}{\n${desktopCss?.text || ""}\n  }\n}`
            : "",
      },
    }
    return textCssProperties
  }

  if (bobType.startsWith("icon")) {
    const iconCssProperties = {
      default: {
        container: forceWrite || defaultCss?.container ? `#${objectId}{\n${defaultCss?.container || ""}\n}` : "",
        icon: forceWrite || defaultCss?.icon ? `#${objectId} > .bob-icon{\n${defaultCss?.icon || ""}\n}` : "",
      },
      mobile: {
        container:
          forceWrite || mobileCss?.container
            ? `${mobileMediaQuery}{\n  #${objectId}{\n${mobileCss?.container || ""}\n  }\n}`
            : "",
        icon:
          forceWrite || mobileCss?.icon
            ? `${mobileMediaQuery}{\n  #${objectId} > .bob-icon{\n${mobileCss?.icon || ""}\n  }\n}`
            : "",
      },
      tablet: {
        container:
          forceWrite || tabletCss?.container
            ? `${tabletMediaQuery}{\n  #${objectId}{\n${tabletCss?.container || ""}\n  }\n}`
            : "",
        icon:
          forceWrite || tabletCss?.icon
            ? `${tabletMediaQuery}{\n  #${objectId} > .bob-icon{\n${tabletCss?.icon || ""}\n  }\n}`
            : "",
      },
      desktop: {
        container:
          forceWrite || desktopCss?.container
            ? `${desktopMediaQuery}{\n  #${objectId}{\n${desktopCss?.container || ""}\n  }\n}`
            : "",
        icon:
          forceWrite || desktopCss?.icon
            ? `${desktopMediaQuery}{\n  #${objectId} > .bob-icon{\n${desktopCss?.icon || ""}\n  }\n}`
            : "",
      },
    }
    return iconCssProperties
  }

  return ""
}

export function handleVerticalAlign(verticalAlign: string) {
  let verticalStyle = {
    display: "flex !important",
    justifyContent: "center !important",
    flexDirection: "column !important",
    alignSelf: "center !important",
  }
  if (!verticalAlign) return verticalStyle

  if (verticalAlign === "middle") {
    return verticalStyle
  }
  if (verticalAlign === "top") {
    verticalStyle.justifyContent = "flex-start !important"
    verticalStyle.alignSelf = "flex-start !important"
    return verticalStyle
  }
  if (verticalAlign === "bottom") {
    verticalStyle.justifyContent = "flex-end !important"
    verticalStyle.alignSelf = "flex-end !important"
    return verticalStyle
  }
}

export function getOverrideInlineContentValues(
  inlineField: string,
  content: {
    inlineContent: ObjectContent["inlineContent"]
    overrides?: ObjectContentOverride
  }
): any {
  return content.overrides?.inlineContent?.[inlineField] ?? content.inlineContent[inlineField]
}

export function getOverrideInlineContentString(
  inlineField: string,
  content: {
    inlineContent: ObjectContent["inlineContent"]
    overrides?: ObjectContentOverride
  }
): string | undefined {
  return stringOrUndefined(content.overrides?.inlineContent?.[inlineField] ?? content.inlineContent[inlineField])
}

export function getResponsiveOverrideInlineContentValues(
  inlineField: string,
  content: {
    inlineContent: ObjectContent["inlineContent"]
    overrides?: ObjectContentOverride
  }
): string | undefined {
  return content.overrides?.inlineContent?.[inlineField] ?? content.inlineContent[inlineField]
}

export function handleComponentLinks(
  inlineContent: ObjectContent["inlineContent"],
  bobKey: string,
  pageResponse: PageTypes,
  overrides?: ObjectContentOverride | undefined,
  post?: Post
) {
  const url = getOverrideInlineContentValues(`${bobKey}.behaviour-link-customUrl`, { inlineContent, overrides })
  const action = getOverrideInlineContentValues(`${bobKey}.behaviour-link-mappedValue`, {
    inlineContent,
    overrides,
  })
  if (action) {
    /**
     * If it ends with -cp it means we have to use info related to the content page
     */
    const postLink = action.endsWith("-cp")
      ? getCPMappedValue(action, pageResponse.contentPage?.content)
      : // @ts-ignore TODO: review object post type vs contentPage post type
        handlePostLink(action, post)
    if (url || postLink) {
      if (action === "page") {
        return `/${url}`
      }
      if (action === "custom") {
        if (url && (url.startsWith("//") || url.startsWith("/") || url.startsWith("http") || url.startsWith("mailto")))
          return `${url}`
        return `//${url}`
      }
      return postLink ? postLink : url
    }
  }
  return undefined
}

export function handleLeanSettings(template: any) {
  let lean = template.lean
  if (!lean.enable) return `none`
  let leanClipPath = `polygon(${lean.topleftx}% ${lean.toplefty}%, ${lean.toprightx}% ${lean.toprighty}%, ${lean.bottomrightx}% ${lean.bottomrighty}%, ${lean.bottomleftx}% ${lean.bottomlefty}%) !important`
  return leanClipPath
}

export function handleAlignment(template: any, flexDirection?: string, self = false) {
  if (!template.alignment) return {}
  let horizontalValue =
    template.alignment.horizontal === "left"
      ? "flex-start"
      : template.alignment.horizontal === "right"
      ? "flex-end"
      : template.alignment.horizontal
  let verticalValue =
    template.alignment.vertical === "top"
      ? "flex-start"
      : template.alignment.vertical === "bottom"
      ? "flex-end"
      : template.alignment.vertical
  return {
    justifyContent: flexDirection === "column" ? verticalValue : horizontalValue,
    alignItems: flexDirection === "column" ? horizontalValue : verticalValue,
    alignSelf: self && flexDirection === "column" ? horizontalValue : verticalValue,
    display: "flex",
    flexDirection: flexDirection,
  }
}

export function handleTemplateNulls(template: any, defaultTemplate?: any, prop?: string) {
  if (defaultTemplate && prop) {
    for (let key in defaultTemplate[prop]) {
      if (!template.hasOwnProperty(key) || template[key] === null) {
        template[key] = defaultTemplate[prop][key]
      }
    }
  }
  return template
}

export function handleHeight(template: any, defaultTemplate?: any) {
  // no height prop
  if (!template.height) return { height: "" }

  let heightTemplate = { ...template.height }
  // when nulls exist they must fallback to default template value
  heightTemplate = handleTemplateNulls(heightTemplate, defaultTemplate, "height")

  // enable is off
  if (!heightTemplate.enable) {
    return { height: "auto !important" }
  }
  let height = `${heightTemplate.value}${heightTemplate.unit} !important`

  return { height }
}

export function handleWidth(template: any, defaultTemplate?: any) {
  // no height prop
  if (!template.width) return { width: "" }

  let widthTemplate = { ...template.width }
  // when nulls exist they must fallback to default template value
  widthTemplate = handleTemplateNulls(widthTemplate, defaultTemplate, "width")

  // enable is off
  if (!widthTemplate.enable) {
    return { width: "auto !important" }
  }
  let width = `${widthTemplate.value}${widthTemplate.unit} !important`

  return { width }
}

/**
 *
 * @param {*} template Object
 *
 */
export function handleBondaries(template: any) {
  if (template && template.spacing && !template.spacing.enable)
    return {
      "margin-top": "0 !important",
      "margin-bottom": "0 !important",
      "margin-right": "0 !important",
      "margin-left": "0 !important",
      "padding-top": "0 !important",
      "padding-bottom": "0 !important",
      "padding-right": "0 !important",
      "padding-left": "0 !important",
    }

  let boundaryObj = template.spacing || template
  let boundaryStyle = {}
  if (boundaryObj.margin) {
    for (let side in boundaryObj.margin) {
      boundaryStyle = {
        ...boundaryStyle,
        [`margin-${side}`]: boundaryObj.margin[side] + "px !important",
      }
    }
  }
  if (boundaryObj.padding) {
    for (let side in boundaryObj.padding) {
      boundaryStyle = {
        ...boundaryStyle,
        [`padding-${side}`]: boundaryObj.padding[side] + "px !important",
      }
    }
  }

  return boundaryStyle
}

export function capitalize(stringToUper: string) {
  if (typeof stringToUper !== "string") return ""
  return stringToUper.charAt(0).toUpperCase() + stringToUper.slice(1)
}

export function handleBondaries_DEPRECATED(template: any) {
  if ((template && template.spacing && !template.spacing.enable) || (template && !template.enable))
    return {
      marginTop: "0 !important",
      marginBottom: "0 !important",
      marginRright: "0 !important",
      marginLeft: "0 !important",
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    }

  let boundaryObj = template.spacing || template
  let boundaryStyle = {}
  if (boundaryObj.margin) {
    for (let side in boundaryObj.margin) {
      boundaryStyle = {
        ...boundaryStyle,
        [`margin${capitalize(side)}`]: boundaryObj.margin[side] + "px !important",
      }
    }
  }
  if (boundaryObj.padding) {
    for (let side in boundaryObj.padding) {
      boundaryStyle = {
        ...boundaryStyle,
        [`padding${capitalize(side)}`]: boundaryObj.padding[side] + "px !important",
      }
    }
  }

  return boundaryStyle
}

export function handleRadius(template: any, defaultTemplate?: any) {
  if (!template.radius) return { borderRadius: "" }

  let radiusTemplate = { ...template.radius }
  // when nulls exist they must fallback to default template value
  radiusTemplate = handleTemplateNulls(radiusTemplate, defaultTemplate, "radius")

  if (!radiusTemplate.enable)
    return {
      borderTopLeftRadius: "0 !important",
      borderTopRightRadius: "0 !important",
      borderBottomLeftRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
    }

  let borderTopLeftRadius = `${radiusTemplate.topLeft}px !important`
  let borderTopRightRadius = `${radiusTemplate.topRight}px !important`
  let borderBottomLeftRadius = `${radiusTemplate.bottomLeft}px !important`
  let borderBottomRightRadius = `${radiusTemplate.bottomRight}px !important`
  return {
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
  }
}

export function handleBorder(template: any, defaultTemplate?: any) {
  if (!template.border) return { borderTop: "", borderBottom: "", borderLeft: "", borderRight: "" }

  let borderTemplate = { ...template.border }
  // when nulls exist they must fallback to default template value
  borderTemplate = handleTemplateNulls(borderTemplate, defaultTemplate, "border")

  if (!borderTemplate.enable) {
    /**
     * When the border is changed and the component is rerendered, if the property that
     * reads has the shortand "border: value", the next render must have the same value,
     * if the rendered value is now separated between top, bottom, left, right, react throws
     * a silent error, to prevent it, we send every border side separated.
     */
    return {
      borderTop: "none !important",
      borderBottom: "none !important",
      borderLeft: "none !important",
      borderRight: "none !important",
    }
  }

  let borderTop = borderTemplate.topEnable
    ? `${borderTemplate.topWidth}px ${borderTemplate.topStyle} ${borderTemplate.topColor} !important`
    : "none !important"
  let borderRight = borderTemplate.rightEnable
    ? `${borderTemplate.rightWidth}px ${borderTemplate.rightStyle} ${borderTemplate.rightColor} !important`
    : "none !important"
  let borderBottom = borderTemplate.bottomEnable
    ? `${borderTemplate.bottomWidth}px ${borderTemplate.bottomStyle} ${borderTemplate.bottomColor} !important`
    : "none !important"
  let borderLeft = borderTemplate.leftEnable
    ? `${borderTemplate.leftWidth}px ${borderTemplate.leftStyle} ${borderTemplate.leftColor} !important`
    : "none !important"

  return { borderTop, borderRight, borderBottom, borderLeft }
}

export function handleShadow(template: any, order?: number, defaultTemplate?: any) {
  if (!template.shadow) return { boxShadow: "" }

  let boxShadowTemplate = { ...template.shadow }
  // when nulls exist they must fallback to default template value
  boxShadowTemplate = handleTemplateNulls(boxShadowTemplate, defaultTemplate, "shadow")
  if (
    template.hasOwnProperty("enable") &&
    (!template.boxShadow || !template.boxShadow.enable) && // LEGACY
    !boxShadowTemplate.enable
  )
    return { boxShadow: "none !important" }
  let boxShadow
  // LEGACY
  if (template.boxShadow)
    boxShadow = `${template.boxShadow.inset ? "inset " : ""}${template.boxShadow.offsetH}px ${
      template.boxShadow.offsetV
    }px ${template.boxShadow.blur}px ${template.boxShadow.spread}px ${template.boxShadow.color} !important`
  if (boxShadowTemplate)
    boxShadow = `${boxShadowTemplate.inset ? "inset " : ""}${boxShadowTemplate.offsetH}px ${
      boxShadowTemplate.offsetV
    }px ${boxShadowTemplate.blur}px ${boxShadowTemplate.spread}px ${boxShadowTemplate.color} !important`

  /**
   * Workaround for when the sibling is right under this object
   * and the shadow doesnt render hover it.
   */
  if (order || order === 0) {
    let zIndex = 20 - order
    if (zIndex <= 0) {
      zIndex = 1
    }
    /**
     * When the scroll is enable we cant force position relative.
     * For this we must use the value for the scroll, since
     * its the selected option by the user
     */
    return {
      boxShadow,
      position: template.scroll?.enable ? template.scroll.value + " !important" : "relative !important",
      zIndex: zIndex + " !important",
    }
  }

  return { boxShadow }
}

export function handleTextShadow(template: any, defaultTemplate?: any) {
  // no shadow prop
  if (!template.shadow) return { textShadow: "" }

  let shadowTemplate = { ...template.shadow }
  // when nulls exist they must fallback to default template value
  shadowTemplate = handleTemplateNulls(shadowTemplate, defaultTemplate, "shadow")

  // enable is off
  if (shadowTemplate.hasOwnProperty("enable") && !shadowTemplate.enable) {
    return { textShadow: "none !important" }
  }
  let textShadow = `${shadowTemplate.offsetH}px ${shadowTemplate.offsetV}px ${shadowTemplate.blur}px ${shadowTemplate.color} !important`

  return { textShadow }
}

/**
 * Returns filter and overlay objects
 * Filter object should be aplied directly to an image/backgroundImage element
 * Overlay object should be aplied to an element that overlays the image
 */
export function handleImageFilter(template: any, defaultTemplate?: any) {
  // when filter is not present we must not write css
  // so that the default css is used instead and not overriten
  if (!template.filter)
    return {
      filter: {
        WebkitFilter: "",
        filter: "",
      },
      overlay: {
        backgroundColor: "",
      },
    }

  let filterTemplate = { ...template.filter }
  // when nulls exist they must fallback to default template value
  filterTemplate = handleTemplateNulls(filterTemplate, defaultTemplate, "filter")

  // filter is disabled
  if (
    (!template.image || !template.image.filterEnable) && // LEGACY
    !filterTemplate.enable
  )
    return {
      filter: {
        WebkitFilter: "none !important",
        filter: "none !important",
      },
      overlay: {
        backgroundColor: "transparent !important",
      },
    }

  // filter is enabled
  let imageFilter = ""
  let colorOverlay
  // LEGACY
  if (template.image) {
    let blur = `${
      template.image.filterBlur && template.image.filterBlur !== 0 ? `blur(${template.image.filterBlur}px)` : ``
    }`
    let opacity = `${
      template.image.filterOpacity && template.image.filterOpacity !== 0
        ? `opacity(${template.image.filterOpacity}%)`
        : ``
    }`
    let brightness = `${template.image.filterBrightness ? `brightness(${template.image.filterBrightness}%)` : ``}`
    let contrast = `${template.image.filterContrast ? `contrast(${template.image.filterContrast}%)` : ``}`
    let grayscale = `${
      template.image.filterGrayscale && template.image.filterGrayscale !== 0
        ? `grayscale(${template.image.filterGrayscale}%)`
        : ``
    }`
    let hueRotate = `${
      template.image.filterHueRotate && template.image.filterHueRotate !== 0
        ? `hue-rotate(${template.image.filterHueRotate}deg)`
        : ``
    }`
    let invert = `${
      template.image.filterInvert && template.image.filterInvert !== 0 ? `invert(${template.image.filterInvert}%)` : ``
    }`
    let saturate = `${
      template.image.filterSaturate && template.image.filterSaturate !== 0
        ? `saturate(${template.image.filterSaturate}%)`
        : ``
    }`
    let sepia = `${
      template.image.filterSepia && template.image.filterSepia !== 0 ? `sepia(${template.image.filterSepia}%)` : ``
    }`
    let shadow = `${
      template.image.filterShadow && template.image.filterShadow !== 0
        ? `box-shadow(${template.image.filterShadow}%)`
        : ``
    }`

    if (
      (blur && blur !== "") ||
      (brightness && brightness !== "") ||
      (opacity && opacity !== "") ||
      (contrast && contrast !== "") ||
      (grayscale && grayscale !== "") ||
      (hueRotate && hueRotate !== "") ||
      (invert && invert !== "") ||
      (saturate && saturate !== "") ||
      (sepia && sepia !== "") ||
      (shadow && shadow !== "")
    )
      imageFilter = `${blur} ${opacity} ${brightness} ${contrast} ${grayscale} ${hueRotate} ${invert} ${saturate} ${sepia} ${shadow} !important`
    colorOverlay = `${
      template.image.filterOverlay && template.image.filterOverlay !== ""
        ? template.image.filterOverlay + " !important"
        : ""
    }`
  }
  // NEW VERSION
  if (filterTemplate) {
    let blur = `${filterTemplate.blur || filterTemplate.blur === 0 ? `blur(${filterTemplate.blur}px)` : ``}`
    // let opacity = `${filterTemplate.opacity || filterTemplate.opacity === 0 ? `opacity(${filterTemplate.opacity}%)` : ``}`
    let brightness = `${filterTemplate.brightness ? `brightness(${filterTemplate.brightness}%)` : ``}`
    let contrast = `${filterTemplate.contrast ? `contrast(${filterTemplate.contrast}%)` : ``}`
    let grayscale = `${
      filterTemplate.grayscale || filterTemplate.grayscale === 0 ? `grayscale(${filterTemplate.grayscale}%)` : ``
    }`
    let hueRotate = `${
      filterTemplate.hueRotate || filterTemplate.hueRotate === 0 ? `hue-rotate(${filterTemplate.hueRotate}deg)` : ``
    }`
    let invert = `${filterTemplate.invert || filterTemplate.invert === 0 ? `invert(${filterTemplate.invert}%)` : ``}`
    let saturate = `${
      filterTemplate.saturate || filterTemplate.saturate === 0 ? `saturate(${filterTemplate.saturate}%)` : ``
    }`
    let sepia = `${filterTemplate.sepia || filterTemplate.sepia === 0 ? `sepia(${filterTemplate.sepia}%)` : ``}`
    // let shadow = `${filterTemplate.shadow && filterTemplate.shadow !== 0 ? `box-shadow(${filterTemplate.shadow}%)` : ``}`

    if (
      (blur && blur !== "") ||
      (brightness && brightness !== "") ||
      (contrast && contrast !== "") ||
      (grayscale && grayscale !== "") ||
      (hueRotate && hueRotate !== "") ||
      (invert && invert !== "") ||
      (saturate && saturate !== "") ||
      (sepia && sepia !== "")
    )
      imageFilter = `${blur} ${brightness} ${contrast} ${grayscale} ${hueRotate} ${invert} ${saturate} ${sepia}  !important`
    colorOverlay = `${
      template.filter.overlay && template.filter.overlay !== "" ? template.filter.overlay + " !important" : ""
    }`
  }

  return {
    filter: {
      WebkitFilter: imageFilter,
      filter: imageFilter,
    },
    overlay: {
      backgroundColor: colorOverlay,
    },
  }
}

export function handleBackgroundColor(template: any, foundationStyle?: FColor, defaultTemplate?: any) {
  // TODO: handle foundationStyle type when added to other bobs
  // no color prop
  // gradient must be false
  if (!template.colors || (template.colors && template.colors.isGradient)) return { backgroundColor: "" }

  let backgroundColorTemplate = { ...template.colors }
  // when nulls exist they must fallback to default template value
  backgroundColorTemplate = handleTemplateNulls(backgroundColorTemplate, defaultTemplate, "colors")

  // enable is off
  if (!backgroundColorTemplate.enable) {
    return { backgroundColor: "transparent !important" }
  }

  // const color = foundationStyle ? foundationStyle.color : backgroundColorTemplate.colorFirst
  const color = backgroundColorTemplate.colorFirst
  const backgroundColor = `${color} !important`
  return { backgroundColor }
}

export function handleBackgroundImage(
  template: any,
  defaultTemplate?: any,
  imageSrc?: string,
  foundationStyle?: Array<FColor> // TODO: fix when aplyint to other bobs
) {
  let backgroundImage
  let backgroundImageTemplate = template.image || {}
  let backgroundColorsTemplate = template.colors || {}

  // no image prop
  if (!template.image) backgroundImage = ""

  // image styles
  if (template.image && imageSrc && imageSrc !== "") {
    backgroundImageTemplate = { ...template.image }
    // when nulls exist they must fallback to default template value
    backgroundImageTemplate = handleTemplateNulls(backgroundImageTemplate, defaultTemplate, "image")
    backgroundImage = `url(${imageSrc}) !important`
  }

  // gradient styles, may combine gradient and image style together
  if (template.colors) {
    backgroundColorsTemplate = { ...template.colors }
    // when nulls exist they must fallback to default template value
    backgroundColorsTemplate = handleTemplateNulls(backgroundColorsTemplate, defaultTemplate, "colors")
    if (backgroundColorsTemplate.isGradient)
      // ${foundationStyle?.[0] ? foundationStyle[0].color : backgroundColorsTemplate.colorFirst},
      //     ${foundationStyle?.[1] ? foundationStyle[1].color : backgroundColorsTemplate.colorSecond})
      backgroundImage = `linear-gradient(
        ${backgroundColorsTemplate.gradientAngle ? backgroundColorsTemplate.gradientAngle : 0}deg,           
        ${backgroundColorsTemplate.colorFirst}, 
        ${backgroundColorsTemplate.colorSecond})
        ${backgroundImageTemplate.enable ? `, url(${imageSrc})` : ""} !important`
  }

  // enable is off
  if (!backgroundImageTemplate.enable && !backgroundColorsTemplate.isGradient) {
    return { backgroundImage: "none !important" }
  }

  return { backgroundImage }
}

export function handleBackground(template: any) {
  let backgroundRepeat = template.background.repeat
  let backgroundSize = template.background.size
  let backgroundPosition = template.background.position
  let minHeight = template.background.height + "vh"
  let objectFit = template.background.size
  let objectPosition = template.background.position
  let backgroundImage = {
    minHeight,
    backgroundRepeat,
    backgroundPosition,
    backgroundSize,
  }
  let img = { minHeight, objectFit, objectPosition }
  return { backgroundImage, img }
}

export function handleFlow(template: any, expManager?: any) {
  //When inside EM we dont fix the navbar
  // fixed, magnetic, static
  // LEGACY
  if (expManager || !template.scroll?.enable) {
    return { position: "static !important" }
  } else if (
    template.flow === "fixed" || // LEGACY
    template.scroll?.value === "fixed"
  ) {
    return {
      position: "fixed !important",
      zIndex: "999 !important",
      width: "100% !important",
      top: template.scroll?.value ? "0 !important" : undefined, // fix for bobBackground
    }
  } else if (
    template.flow === "magnetic" || // LEGACY
    template.scroll?.value === "magnetic"
  ) {
    return {
      position: "sticky !important",
      top: "0 !important",
      zIndex: "999 !important",
    }
  } else if (
    template.flow === "static" || // LEGACY
    template.scroll?.value === "static"
  ) {
    return { position: "static !important" }
  }
  return { position: "static !important" }
}

/**
 * handle column gutters
 * @param {*} template bob template
 * @param {*} noGuttersDefault tells if the bob html element has no-gutters by default
 */
export function handleGutts(template: any, noGuttersDefault: boolean) {
  /**
   * when gutters prop doenst exist
   * we want to use the FE defaults
   */
  if (!template.grid.hasOwnProperty("gutters")) return !noGuttersDefault ? "" : "no-gutters"
  // else use the gutters value
  return `${template.grid.gutters ? "" : "no-gutters"}`
}

/**
 * Builds import statement for google fonts
 * @param {*} template
 * @param {*} fontWeight
 * @param {*} isPublic
 */
export function dynamicFontLoader(
  template: any,
  fontWeight: any,
  label: FontLabel | undefined,
  isPublic = false,
  isStateTemplate = false
) {
  /**
   * Legacy check due to old components, fontType may not exist
   */
  if (template.hasOwnProperty("fontType") && template.fontType !== "google-fonts" && !isStateTemplate) return ""
  if (!template.fontFamily) return ""

  let weightsString,
    fontWeightString = isPublic ? "wght@300,400,700,900" : "wght@100,200,300,400,500,600,700,800,900"
  let italWght = ""
  let nonItalWght = ""
  if (fontWeight && isPublic) {
    weightsString = "ital,wght@"
    fontWeight.forEach((element: any) => {
      if (element.italic) {
        italWght += `${italWght === "" ? "" : ","}1,` + element.weight
      } else {
        nonItalWght += `${nonItalWght === "" ? "" : ","}0,` + element.weight
      }
    })
    weightsString += nonItalWght + `${nonItalWght && italWght ? "," : ""}` + italWght
  }
  return `@import url('https://fonts.bunny.net/css?family=${label?.fontFamily || template.fontFamily}:${
    weightsString ? weightsString : fontWeightString
  }&display=swap');`
}

export function clearFont(font: string) {
  if (font) {
    let noPlus = font.replace("+", " ")
    return noPlus.split(":")[0]
  }
  return "Roboto"
}

/**
 * build an object with the google fonts families as keys
 * so that it's lighter when searching for a specific font object
 * @param {*} fontList google api response list
 */
export function googleFontsListForFilter(fontList: any) {
  let fontsForFilter = {}
  for (let font in fontList) {
    let fontForFilter = fontList[font]
    fontsForFilter = {
      ...fontsForFilter,
      [fontForFilter.family]: {
        value: fontForFilter.family,
        label: fontForFilter.family,
        variants: fontForFilter.variants,
      },
    }
  }
  return fontsForFilter
}

export function customFontsListForFilter(fontList: any) {
  let fontsForFilter: any = {}
  // loop through fonts list
  for (let font in fontList) {
    const fontForFilter = fontList[font]
    let fontFamily = fontForFilter.fontFamily
    let variants: any = []
    // loop through each font weight
    for (let weight in fontForFilter.weights) {
      const fontWeight = fontForFilter.weights[weight]
      variants = [...variants, fontWeight.weight]
      fontsForFilter = {
        ...fontsForFilter,
        [fontFamily]: {
          ...fontWeight,
          value: fontFamily,
          label: fontWeight.originalFontFamily,
          variants,
        },
      }
    }
  }
  return fontsForFilter
}

/**
 * This function will evaluate what content must be returned
 * by the toReturn string, it can return only posts or objects or everything
 * keeping in check if static is being used
 * When fallbackPost is true a dummy post is return when content is empty
 * @param {object} obj
 * @param {string} toReturn
 * @param {object} objects
 * @param {boolean} fallbackPost
 */
export function getContent(
  obj: SingleObject,
  toReturn: "posts" | "all" | "objects",
  overrides?: ObjectContentOverride,
  objects?: any,
  fallbackPost = true,
  contentPageContent?: Post[]
) {
  //Workaround for new post location
  //TODO send posts inside formula object
  const FItems: any =
    (overrides?.formula && overrides.formula.posts) ??
    (obj.content.formula && obj.content.formula.posts ? obj.content.formula.posts : obj.content ? obj.content : [])

  const Items = obj.content.contentSrc === "cp" && contentPageContent !== undefined ? contentPageContent : FItems

  if (toReturn === "posts") {
    //Return dummy post
    return Items.length > 0 ? Items : fallbackPost && [defaultObj.main.staticPost]
  }
  if (toReturn === "all") {
    const Objects = filterBy(obj.children, objects)
    return Items.length > 0
      ? [...Objects, ...Items]
      : fallbackPost
      ? [...Objects, defaultObj.main.staticPost]
      : [...Objects]
  }
  if (toReturn === "objects") {
    return filterBy(obj.children, objects)
  }
}

export function filterBy(arrObjectUuid: any, objects: Array<SingleObject>) {
  if (arrObjectUuid && arrObjectUuid.constructor === Array)
    return arrObjectUuid.map((objectUuid) => objects[objectUuid])
  return []
}

export function templateIdsReplace(originalTemplate: Styles, newTemplate: Styles) {
  let cloneTemplate = cloneDeep(originalTemplate)
  // replace styles props
  cloneTemplate.uuid = newTemplate.uuid
  // replace bob's ids
  for (let key in cloneTemplate.bobs) {
    if (newTemplate.bobs[key]) cloneTemplate.bobs[key].uuid = newTemplate.bobs[key].uuid
  }
  return cloneTemplate
}

export function replaceCustomContent(arrCC: any, originalTemplate: Styles) {
  if (arrCC.length === 0) return originalTemplate

  let clonedOriginalTemplate = cloneDeep(originalTemplate)
  for (let customOption of arrCC) {
    set(clonedOriginalTemplate.bobs, customOption.objPath, customOption.value)
  }
  return clonedOriginalTemplate
}

// map contents based on number of columns
// maintain the same pattern of columns if there are multiple lines
export function handleColumns(idx: number, counter: any, backgroundTemplate: any) {
  let columns = "12"
  let columnsTablet
  let columnsMobile
  let nthCol = backgroundTemplate.grid.columns && backgroundTemplate.grid.columns.length
  let nthColTablet = handleDeprecatedCols("tablet", backgroundTemplate, nthCol)
  let nthColMobile = handleDeprecatedCols("mobile", backgroundTemplate, nthCol)
  counter.desktop = idx % nthCol !== 0 ? counter.desktop + 1 : 0
  counter.tablet = idx % nthColTablet !== 0 ? counter.tablet + 1 : 0
  counter.mobile = idx % nthColMobile !== 0 ? counter.mobile + 1 : 0
  columns = backgroundTemplate.grid.columns
    ? backgroundTemplate.grid.columns[counter.desktop]
    : backgroundTemplate.grid.cols

  /**
   * write desktop as a default value on other breakpoints
   *
   * DEPRECATED:
   * Due to the mobile/tablet columns being defaulted to 12 on the past,
   * we need to handle the bobs that were using this behaviour as if the behaviour still exists,
   * instead of defaulting to the desktop styles.
   * The objects that are edited and republished will have the 'newBehaviour' prop so that the fallback stops working for this ones.
   *
   * This should only be true to objects that were never written with the colsMobile and colTablet props on the grid object.
   * This ones were migrated so that the mobile.grid.columns values is equal to the mobileCols value.
   * So no need to fallback to 12
   */
  if (
    !backgroundTemplate.grid.newBehaviour &&
    JSON.stringify(backgroundTemplate.grid.columns) !== '["12"]' &&
    !backgroundTemplate.grid.columnsTablet &&
    !backgroundTemplate.grid.columnsMobile &&
    !backgroundTemplate.tablet?.grid?.columns &&
    !backgroundTemplate.mobile?.grid?.columns
  ) {
    columnsTablet = "12"
    columnsMobile = "12"
  } else {
    columnsTablet = columns
    columnsMobile = columns
  }

  /**
   * Check for legacy data and render that, if new structure is not present
   * Write breakpoint value if it exists
   */
  //Mobile
  if (backgroundTemplate.mobile && backgroundTemplate.mobile.grid?.columns) {
    columnsMobile = backgroundTemplate.mobile.grid.columns[counter.mobile]
  }
  //Deprecated
  else if (backgroundTemplate.grid.columnsMobile) {
    columnsMobile = backgroundTemplate.grid.columnsMobile[counter.mobile]
  }
  //Tablet
  if (backgroundTemplate.tablet && backgroundTemplate.tablet.grid?.columns) {
    columnsTablet = backgroundTemplate.tablet.grid.columns[counter.tablet]
  }
  //Deprecated
  else if (backgroundTemplate.grid.columnsTablet) {
    columnsTablet = backgroundTemplate.grid.columnsTablet[counter.tablet]
  }
  return { columns, columnsTablet, columnsMobile, counter }
}

export function handleDeprecatedCols(type: "tablet" | "mobile", template: any, nthCol: any) {
  if (type === "tablet")
    return template.tablet?.grid?.columns
      ? template.tablet.grid.columns.length
      : template.grid.columnsTablet
      ? template.grid.columnsTablet.length
      : nthCol
  if (type === "mobile")
    return template.mobile?.grid?.columns
      ? template.mobile.grid.columns.length
      : template.grid.columnsMobile
      ? template.grid.columnsMobile.length
      : nthCol
  return undefined
}

export function newComponentIcons(componentName: string, color: string) {
  if (componentName === "container") {
    return <i style={{ color: color }} className='fas fa-border-all'></i>
  }
  if (componentName === "text") {
    return <i style={{ color: color }} className='fas fa-shapes'></i>
  }
  if (componentName === "media_v2") {
    return <i style={{ color: color }} className='fas fa-shapes'></i>
  }
  if (componentName === "grid") {
    return <i style={{ color: color }} className='fas fa-border-all'></i>
  }
  if (componentName === "form") {
    return <i style={{ color: color }} className='fas fa-border-none'></i>
  }
  if (componentName === "input") {
    return <i style={{ color: color }} className='fas fa-border-none'></i>
  }
  if (componentName === "submit") {
    return <i style={{ color: color }} className='fas fa-border-none'></i>
  }
  return <i style={{ color: color }} className='fa fa-question-circle'></i>
}

export function handleComponentCategoryIcon(category: string, isFromSymbol: boolean, replaceColor?: string) {
  if (category === componentIconTypes.STRUCTURE)
    return (
      <i
        style={{ color: `${replaceColor ? replaceColor : isFromSymbol ? "#FD7900" : "#000"}` }}
        className='fas fa-border-all'
      ></i>
    )
  if (category === componentIconTypes.ELEMENTS)
    return (
      <i
        style={{ color: `${replaceColor ? replaceColor : isFromSymbol ? "#FD7900" : "#000"}` }}
        className='fas fa-shapes'
      ></i>
    )
  if (category === componentIconTypes.NAVIGATION)
    return (
      <i
        style={{ color: `${replaceColor ? replaceColor : isFromSymbol ? "#FD7900" : "#000"}` }}
        className='fas fa-compass'
      ></i>
    )
  if (category === componentIconTypes.CONTENT)
    return (
      <i
        style={{ color: `${replaceColor ? replaceColor : isFromSymbol ? "#FD7900" : "#000"}` }}
        className='fas fa-boxes'
      ></i>
    )
  // default value
  return (
    <i
      style={{ color: `${replaceColor ? replaceColor : isFromSymbol ? "#FD7900" : "#000"}` }}
      className='fas fa-question'
    ></i>
  )
}

export function overridesHasFormulas(clonedSingleObject: SingleObject, pageResponse: PageTypes): boolean {
  if (
    !clonedSingleObject.content?.overrides ||
    (clonedSingleObject.content?.overrides && Object.keys(clonedSingleObject.content.overrides).length === 0)
  ) {
    return false
  }
  let hasRootSymbolObjFormula = false
  if (clonedSingleObject.isInstanceOf) {
    const instanceSymbolRootId = pageResponse.symbols[clonedSingleObject.isInstanceOf]?.rootId
    //eslint-disable-next-line
    Object.keys(clonedSingleObject.content.overrides).map((objectKey) => {
      if (instanceSymbolRootId === objectKey && clonedSingleObject.content.overrides?.[objectKey].formula)
        hasRootSymbolObjFormula = true
    })
  }
  return hasRootSymbolObjFormula
}

export function isParentContentBlock(parentObj: SingleObject, overrides?: ObjectContentOverride): boolean {
  return overrides?.isContentBlock ?? parentObj.isContentBlock
}

export function handleContentBlock(
  singleObject: SingleObject,
  post: Post | undefined,
  parentObj: SingleObject,
  pageResponse: PageTypes,
  overrides?: ObjectContentOverride
) {
  /**
   * Send content to child, and grandchild, etc
   *
   * if post already exists on child we shouldn't inject it again.
   * if isContentBlockChild still doenst exist it's because the post is being injected for the first time to the child,
   * so allways inject it, this way we dont run the filter on page start
   */
  if ((!isParentContentBlock(parentObj, overrides) && !parentObj.isContentBlockChild) || !post) return singleObject

  let clonedSingleObject: SingleObject = cloneDeep(singleObject)
  // const FormComponents =
  //   parentObj.label.toLocaleLowerCase() === componentType.FORM ||
  //   clonedSingleObject.label.toLocaleLowerCase() === componentType.FORM_INPUT ||
  //   clonedSingleObject.label.toLocaleLowerCase() === componentType.FORM_SUBMIT
  // if (FormComponents) {
  //   clonedSingleObject.content.form = parentObj.content.form
  // } else
  if (clonedSingleObject.type === "instance") {
    clonedSingleObject.isContentBlockChild = true
    //write override if it doesnt exist
    if (!overridesHasFormulas(clonedSingleObject, pageResponse) && clonedSingleObject.isInstanceOf) {
      //get uuid for object to write the override
      const instanceSymbolRootId = pageResponse.symbols[clonedSingleObject.isInstanceOf]?.rootId
      if (instanceSymbolRootId) {
        const parentObjFormula = overrides?.formula ?? parentObj.content.formula
        const existingOverrides = clonedSingleObject.content.overrides?.[instanceSymbolRootId] ?? {}
        clonedSingleObject.content.overrides = {
          [instanceSymbolRootId]: {
            ...existingOverrides,
            formula: {
              uuid: parentObjFormula?.uuid ?? "",
              label: parentObjFormula?.label ?? "",
              name: parentObjFormula?.name ?? "",
              mql: parentObjFormula?.mql ?? "",
              objectFormula: parentObjFormula?.objectFormula ?? false,
              hasMore: parentObjFormula?.hasMore ?? false,
              totalItems: parentObjFormula?.totalItems ?? 1,
              nextCursor: parentObjFormula?.nextCursor ?? "",
              posts: [post],
              postIds: parentObjFormula?.postIds ?? [],
              type: parentObjFormula?.type ?? "expression",
              media: parentObjFormula?.media,
              linkedPosts: parentObjFormula?.linkedPosts,
            },
          },
        }
      }
    }
    //update override if it has formula else create formula at that override
    else {
      //check if the overrride has formula
      let hasFormula: any = false
      let localOverrides = clonedSingleObject.content.overrides
      if (localOverrides) {
        //eslint-disable-next-line
        Object.keys(localOverrides).map((objectKey) => {
          if (localOverrides?.[objectKey]?.formula) hasFormula = objectKey
        })
        let overrideFormula = localOverrides[hasFormula].formula
        if (hasFormula && overrideFormula) {
          overrideFormula.posts = [...overrideFormula.posts, post]
        }
      } else if (clonedSingleObject.isInstanceOf) {
        //get uuid for object to write the override
        const instanceSymbolRootId = pageResponse.symbols[clonedSingleObject.isInstanceOf]?.rootId
        if (instanceSymbolRootId) {
          const parentObjFormula = overrides?.formula ?? parentObj.content.formula
          const existingOverrides = clonedSingleObject.content.overrides?.[instanceSymbolRootId] ?? {}
          clonedSingleObject.content.overrides = {
            [instanceSymbolRootId]: {
              ...existingOverrides,
              formula: {
                uuid: parentObjFormula?.uuid ?? "",
                label: parentObjFormula?.label ?? "",
                name: parentObjFormula?.name ?? "",
                mql: parentObjFormula?.mql ?? "",
                objectFormula: parentObjFormula?.objectFormula ?? false,
                hasMore: parentObjFormula?.hasMore ?? false,
                totalItems: parentObjFormula?.totalItems ?? 1,
                nextCursor: parentObjFormula?.nextCursor ?? "",
                posts: [post],
                postIds: parentObjFormula?.postIds ?? [],
                type: parentObjFormula?.type ?? "expression",
                media: parentObjFormula?.media,
                linkedPosts: parentObjFormula?.linkedPosts,
              },
            },
          }
        }
      }
    }
  }
  //not instance
  else {
    //Check if object has posts
    const objectPosts = clonedSingleObject.content?.formula?.posts
    if (post) {
      const isPostInjected =
        !objectPosts || (objectPosts && objectPosts.filter((obj: any) => obj.id === post.id).length > 0)

      if (!clonedSingleObject.isContentBlockChild || !isPostInjected) {
        /**
         * check for content.formula existance
         * if it doenst create it so we can add posts inside it
         */
        if (clonedSingleObject.content.formula)
          clonedSingleObject.content.formula.posts = clonedSingleObject.content.formula.posts
            ? [...clonedSingleObject.content.formula.posts, post]
            : [post]
        else {
          const parentObjFormula = overrides?.formula ?? parentObj.content.formula
          clonedSingleObject.content.formula = {
            uuid: parentObjFormula?.uuid ?? "",
            label: parentObjFormula?.label ?? "",
            name: parentObjFormula?.name ?? "",
            mql: parentObjFormula?.mql ?? "",
            objectFormula: parentObjFormula?.objectFormula ?? false,
            hasMore: parentObjFormula?.hasMore ?? false,
            totalItems: parentObjFormula?.totalItems ?? 1,
            nextCursor: parentObjFormula?.nextCursor ?? "",
            posts: [post],
            postIds: parentObjFormula?.postIds ?? [],
            type: parentObjFormula?.type ?? "expression",
            media: parentObjFormula?.media,
            linkedPosts: parentObjFormula?.linkedPosts,
          }
        }

        // write isContentBlockChild for every child of a content block
        clonedSingleObject.isContentBlockChild = true
        // handle postList field
        clonedSingleObject.content.formula = handlePostListField(
          singleObject.content,
          clonedSingleObject.content.formula,
          post,
          pageResponse
        )
      }
    }
  }
  return clonedSingleObject
}

/**
 * handle postList field
 * use a postList from a post
 *
 * @param content
 * @param formula
 * @param post
 * @param pageResponse
 * @returns
 */
function handlePostListField(
  content: ObjectContent,
  formula: Formula,
  post: Post | undefined,
  pageResponse: PageTypes
): Formula | undefined {
  if (content.mappedField || content.contentSrc === "field") {
    // post and postList metadata must exist
    if (post && post._meta_post_ids_at && content.mappedField) {
      // Check for mappedField in _meta_post_ids_at
      if (!post._meta_post_ids_at.includes(content.mappedField)) {
        console.warn("PostList mappedField doens't exist in _meta_post_ids_at")
        return undefined
      }
      // Use post postList when it exists
      // Post postList exists
      const postListIdsLocation = content.mappedField
      if (postListIdsLocation) {
        const postsList = pageResponse.linkedPosts
        const dynamicPostsList = formula.linkedPosts
        const postIds = post[postListIdsLocation] as Array<keyof PageTypes["linkedPosts"]>
        // Get postList
        if (postsList && postIds && postIds.length > 0) {
          const posts = postIds.map((postId) => {
            if (postsList[postId] === undefined && dynamicPostsList) return dynamicPostsList[postId]
            return postsList[postId]
          })
          formula.posts = posts
        }
      }
      // Post postList doens't exists
      else return undefined
    }
    //Post doens't exist
    else return undefined
  }

  return formula
}

export function handleDataSetAsContentBlock(singleObject: SingleObject, posts: Array<Post>, parentObj: SingleObject) {
  let clonedSingleObject = cloneDeep(singleObject)
  /**
   * Send content to child, and grandchild, etc
   *
   * if post already exists on child we shouldn't inject it again.
   * if isContentBlockChild still doenst exist it's because the post is being injected for the first time to the child,
   * so allways inject it, this way we dont run the filter on page start
   */
  let postsExist =
    clonedSingleObject.content && clonedSingleObject.content.formula && clonedSingleObject.content.formula.posts
  const postExists =
    !postsExist ||
    (postsExist &&
      clonedSingleObject.content.formula &&
      clonedSingleObject.content.formula.posts.filter((obj) => obj.id === posts[0].id).length > 0)
  if (posts) {
    if (!clonedSingleObject.isContentBlockChild || !postExists) {
      /**
       * check for content.formula existance
       * if it doenst create it so we can add posts inside it
       */
      if (clonedSingleObject.content.formula)
        clonedSingleObject.content.formula.posts = clonedSingleObject.content.formula.posts
          ? [...clonedSingleObject.content.formula.posts, ...posts]
          : [...posts]
      //@ts-ignore TODO: review spread bellow
      else clonedSingleObject.content.formula = { ...clonedSingleObject.content.formula, posts: [...posts] }
      clonedSingleObject.isContentBlockChild = true
    }
  }

  return clonedSingleObject
}

export function isSubDomainPreview(domain: string) {
  /**
   * Since checking for the subdomain can be quiet heavy, we just
   * use includes() to see if its the fluxio.cloud domain and with a "-preview" string
   */
  if (domain.includes("fluxio.cloud") && domain.includes("-preview")) return true
  return false
}

export function handleObjectName(label: string, singleObject: SingleObject, contentHub: PageTypes) {
  if (isDefaultLabel(label)) {
    if (singleObject.type === "instance" || singleObject.type === "symbol") {
      return "Symbol"
    }

    if (singleObject.type === "object") {
      return ComponentNames.types(contentHub.components[singleObject.styles.component]?.name)
    }
  }
  return label
}

export function getInstanceName(singleObject: Instance) {
  if (singleObject.type === "instance") return singleObject.label
  return "!!!Wrong object provided!!!"
}

export function isDefaultLabel(label: string) {
  return label.toLowerCase() === "new object"
}

/**
 * block set as symbol if object has template events
 */
export function checkTemplateEvents(eventPile: Array<any>, obj: any, contentHub: PageTypes) {
  // when dealing with an instance, if events exist,
  // those events id's are refering to the symbol object that made style changes
  let newObj = obj
  if (obj.isInstanceOf) newObj = handleFilterSymbolObject(obj, contentHub.symbols, false)
  if (eventPile && eventPile.length > 0) {
    for (let index in eventPile) {
      if (eventPile[index].type === "template" && eventPile[index].content.objectUuid === newObj.objectUuid) return true
    }
  }
  return false
}

export function isInSymbolEditMode(
  symbolObj: { symbolObj: SingleObject },
  symbolContentType: typeof CONTENT_TYPE_INSTANCE | typeof CONTENT_TYPE_SYMBOL,
  emType: EMEditorType
) {
  if (symbolObj && symbolContentType === CONTENT_TYPE_SYMBOL) return true
  if (emType === "symbol") return true
  return false
}

export function isInInstanceEditMode(
  symbolObj: { symbolObj: SingleObject },
  symbolContentType: typeof CONTENT_TYPE_INSTANCE | typeof CONTENT_TYPE_SYMBOL
) {
  if (symbolObj && symbolContentType === CONTENT_TYPE_INSTANCE) return true
  return false
}

export function disableBodyScroll(expManager: ExpManager, disableScroll: boolean) {
  // disabled body scroll when modal is open
  if (expManager.enable) {
    let boardPreview = document.getElementById("board-preview-frame") as HTMLIFrameElement
    if (boardPreview) {
      let iframeBody = boardPreview.contentWindow?.document.body
      if (disableScroll) {
        if (iframeBody) iframeBody.style.overflowY = "hidden"
      } else {
        if (iframeBody) iframeBody.style.overflowY = "auto"
      }
    }
  } else {
    if (disableScroll) document.body.style.overflowY = "hidden"
    else document.body.style.overflowY = "auto"
  }
}

import { useEffect, useState } from "react"
import cookie from "react-cookies"
import { Route, RouteComponentProps, Switch, useRouteMatch } from "react-router"
import profileApi from "../../../api/profileApi"
import { Workspace } from "../profileTypes"
import PostSearch from "../content/PostSearch"
import { JobsApi } from "../jobs/jobsApi"
import { mkProfileName } from "../jobs/jobsSchema"

interface BleepRouteProps {
  bleep: string
}

function Bleep(props: BleepRouteProps): JSX.Element {
  return <div>beep {props.bleep}</div>
}

interface Token {
  access_token: string
}

function useWorkspace(profile: string, redirect: AppRedirect): { ws: Workspace; token: Token } | undefined {
  const [state, setState] = useState<{ ws: Workspace; token: Token } | undefined>(undefined)

  useEffect(() => {
    if (cookie.load("_fluxio_user_token")) {
      const token = JSON.parse(window.atob(cookie.load("_fluxio_user_token"))) as Token
      profileApi
        .Profile(token, profile)
        .then((e) => setState({ ws: e as Workspace, token: token }))
        .catch(() => redirect.login())
    } else {
      redirect.login()
    }
  }, [profile, redirect])

  return state
}

interface AppRedirect {
  login: () => void
}

// TODO: test that login works, we seem to only use the access_token
export function mkAppRedirect(r: RouteComponentProps<any>) {
  const push = r.history.push
  return {
    login: () => push("/login"),
  }
}

export function DoJobRoutes(props: { profile: string; redirect: AppRedirect }): JSX.Element {
  let { path } = useRouteMatch()
  const workspace: { ws: Workspace; token: Token } | undefined = useWorkspace(props.profile, props.redirect)

  if (!workspace) {
    return <div>Loading...</div>
  }

  const client = new JobsApi(workspace.ws.name, workspace.token.access_token)

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <div>ok</div>
      </Route>
      <Route path={`${path}/beep/:bleep`} render={(r) => <Bleep {...r.match.params} />}></Route>
      <Route
        path={`${path}/ps`}
        render={(r) => (
          <PostSearch
            profile={mkProfileName(props.profile)}
            onPostSelected={(posts) => console.log(posts)}
            client={client}
            selected={[]}
          />
        )}
      ></Route>
    </Switch>
  )
}

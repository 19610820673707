import { BRAND, ZodBranded, ZodType, z } from "zod"
import * as D from "io-ts/Decoder"

/*
    A string newtype without validation.
*/
export function schema<TAG extends string>(): ZodBranded<z.ZodString, TAG> {
  return z.string().brand<TAG>()
}

export function decoder<TAG extends string>(): D.Decoder<unknown, string & BRAND<TAG>> {
  return D.Functor.map(D.string, (u) => u as string & z.BRAND<TAG>)
}

export function wrap<TAG extends string>(schema: ZodBranded<z.ZodString, TAG>, u: string): string & z.BRAND<TAG> {
  return u as string & z.BRAND<TAG>
}

export declare type TypeOf<T extends ZodType<any, any, any>> = T["_output"]
export type { TypeOf as infer }

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function xFormulaReducer(state = initialState.xFormulaReducer, action) {
  switch (action.type) {
    case types.REQUEST_PROFILE_SUB_XFORMULA:
    case types.REQUEST_PROFILE_XFORMULA:
      return {
        ...state,
        loading: true,
        profile: action.profile,
        error: false
      }
    case types.RECEIVE_PROFILE_XFORMULA:
    case types.RECEIVE_PROFILE_SUB_XFORMULA:
      return {
        ...state,
        loading: false,
        profile: action.profile,
        content: action.content,
        error: false
      }
    case types.ERROR_PROFILE_SUB_XFORMULA:
    case types.ERROR_PROFILE_XFORMULA:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case types.CHANGED_FORMULA_GALLERY:
      return {
        ...state,
        formulaGalleryChanges: [action.formulaGalleryChanges],
      }
    default:
      return state;
  }
}

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function academyReducer(state: any = initialState.academyReducer, action: any) {
  switch (action.type) {
    case types.CHANGE_MODAL_STATE:
      return {
        ...state,
        modalState: action.modalState,
        modalContent: action.modalContent
      }
    case types.CHANGE_INFO_STATE:
      return {
        ...state,
        infoState: action.infoState,
        infoType: action.infoType
      }
    case types.CHANGE_LESSONS_MODAL_STATE:
      return {
        ...state,
        lessonsModalState: action.lessonsModalState
      }
    default:
      return state
  }
}
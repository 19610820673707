import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function ssoReducer(state = initialState.ssoReducer, action: any) {
  switch (action.type) {
    case types.ERROR_SSO_LOGIN:
      return {
        ...state,
        errorSsoLogin: action.error,
        loadingSsoLogin: false,
        successSsoLogin: undefined
      }
    case types.REQUEST_SSO_LOGIN:
      return {
        ...state,
        errorSsoLogin: false,
        loadingSsoLogin: true,
        successSsoLogin: undefined
      }
    case types.SUCCESS_SSO_LOGIN:
      return {
        ...state,
        errorSsoLogin: false,
        loadingSsoLogin: false,
        successSsoLogin: action.response
      }
    case types.CLEAN_SSO_LOGIN:
      return {
        ...state,
        errorSsoLogin: false,
        loadingSsoLogin: false,
        successSsoLogin: undefined
      }
    /** */
    case types.ERROR_SSO_REGISTER:
      return {
        ...state,
        errorSsoRegister: action.error,
        loadingSsoRegister: false,
        successSsoRegister: undefined
      }
    case types.REQUEST_SSO_REGISTER:
      return {
        ...state,
        errorSsoRegister: false,
        loadingSsoRegister: true,
        successSsoRegister: undefined
      }
    case types.SUCCESS_SSO_REGISTER:
      return {
        ...state,
        errorSsoRegister: false,
        loadingSsoRegister: false,
        successSsoRegister: action.response
      }
    case types.CLEAN_SSO_REGISTER:
      return {
        ...state,
        errorSsoRegister: false,
        loadingSsoRegister: false,
        successSsoRegister: undefined
      }
    /** */
    case types.ERROR_SSO_EMAIL_CONFIRMATION:
      return {
        ...state,
        errorSsoEmailConfirmation: action.error,
        loadingSsoEmailConfirmation: false,
        successSsoEmailConfirmation: undefined
      }
    case types.REQUEST_SSO_EMAIL_CONFIRMATION:
      return {
        ...state,
        errorSsoEmailConfirmation: false,
        loadingSsoEmailConfirmation: true,
        successSsoEmailConfirmation: undefined
      }
    case types.SUCCESS_SSO_EMAIL_CONFIRMATION:
      return {
        ...state,
        errorSsoEmailConfirmation: false,
        loadingSsoEmailConfirmation: false,
        successSsoEmailConfirmation: action.response
      }
    case types.CLEAN_SSO_EMAIL_CONFIRMATION:
      return {
        ...state,
        errorSsoEmailConfirmation: false,
        loadingSsoEmailConfirmation: false,
        successSsoEmailConfirmation: undefined
      }
    /** */
    case types.ERROR_SSO_NEW_CONFIRMATION_LINK:
      return {
        ...state,
        errorSsoNewConfirmationLink: action.error,
        loadingSsoNewConfirmationLink: false,
        successSsoNewConfirmationLink: undefined
      }
    case types.REQUEST_SSO_NEW_CONFIRMATION_LINK:
      return {
        ...state,
        errorSsoNewConfirmationLink: false,
        loadingSsoNewConfirmationLink: true,
        successSsoNewConfirmationLink: undefined
      }
    case types.SUCCESS_SSO_NEW_CONFIRMATION_LINK:
      return {
        ...state,
        errorSsoNewConfirmationLink: false,
        loadingSsoNewConfirmationLink: false,
        successSsoNewConfirmationLink: action.response
      }
    case types.CLEAN_SSO_NEW_CONFIRMATION_LINK:
      return {
        ...state,
        errorSsoNewConfirmationLink: false,
        loadingSsoNewConfirmationLink: false,
        successSsoNewConfirmationLink: undefined
      }
    /** */
    case types.ERROR_SSO_COMMUNITY_REGISTRATION_PAGE_INFO:
      return {
        ...state,
        errorSsoCommunityRegistrationPageInfo: action.error,
        loadingSsoCommunityRegistrationPageInfo: false,
        successSsoCommunityRegistrationPageInfo: undefined
      }
    case types.REQUEST_SSO_COMMUNITY_REGISTRATION_PAGE_INFO:
      return {
        ...state,
        errorSsoCommunityRegistrationPageInfo: false,
        loadingSsoCommunityRegistrationPageInfo: true,
        successSsoCommunityRegistrationPageInfo: undefined
      }
    case types.SUCCESS_SSO_COMMUNITY_REGISTRATION_PAGE_INFO:
      return {
        ...state,
        errorSsoCommunityRegistrationPageInfo: false,
        loadingSsoCommunityRegistrationPageInfo: false,
        successSsoCommunityRegistrationPageInfo: action.response
      }
    case types.CLEAN_SSO_COMMUNITY_REGISTRATION_PAGE_INFO:
      return {
        ...state,
        errorSsoCommunityRegistrationPageInfo: false,
        loadingSsoCommunityRegistrationPageInfo: false,
        successSsoCommunityRegistrationPageInfo: undefined
      }
    /** */
    case types.ERROR_SSO_COMMUNITY_REGISTRATION:
      return {
        ...state,
        errorSsoCommunityRegistration: action.error,
        loadingSsoCommunityRegistration: false,
        successSsoCommunityRegistration: undefined
      }
    case types.REQUEST_SSO_COMMUNITY_REGISTRATION:
      return {
        ...state,
        errorSsoCommunityRegistration: false,
        loadingSsoCommunityRegistration: true,
        successSsoCommunityRegistration: undefined
      }
    case types.SUCCESS_SSO_COMMUNITY_REGISTRATION:
      return {
        ...state,
        errorSsoCommunityRegistration: false,
        loadingSsoCommunityRegistration: false,
        successSsoCommunityRegistration: action.response
      }
    case types.CLEAN_SSO_COMMUNITY_REGISTRATION:
      return {
        ...state,
        errorSsoCommunityRegistration: false,
        loadingSsoCommunityRegistration: false,
        successSsoCommunityRegistration: undefined
      }
    /** */
    case types.ERROR_SSO_ACTIVATE_ACCOUNT:
      return {
        ...state,
        errorSsoActivateAccount: action.error,
        loadingSsoActivateAccount: false,
        successSsoActivateAccount: undefined
      }
    case types.REQUEST_SSO_ACTIVATE_ACCOUNT:
      return {
        ...state,
        errorSsoActivateAccount: false,
        loadingSsoActivateAccount: true,
        successSsoActivateAccount: undefined
      }
    case types.SUCCESS_SSO_ACTIVATE_ACCOUNT:
      return {
        ...state,
        errorSsoActivateAccount: false,
        loadingSsoActivateAccount: false,
        successSsoActivateAccount: action.response
      }
    case types.CLEAN_SSO_ACTIVATE_ACCOUNT:
      return {
        ...state,
        errorSsoActivateAccount: false,
        loadingSsoActivateAccount: false,
        successSsoActivateAccount: undefined
      }
    /** */
    case types.ERROR_SSO_NEW_REGISTER_CONFIRMATION:
      return {
        ...state,
        errorSsoNewRegisterConfirmation: action.error,
        loadingSsoNewRegisterConfirmation: false,
        successSsoNewRegisterConfirmation: undefined
      }
    case types.REQUEST_SSO_NEW_REGISTER_CONFIRMATION:
      return {
        ...state,
        errorSsoNewRegisterConfirmation: false,
        loadingSsoNewRegisterConfirmation: true,
        successSsoNewRegisterConfirmation: undefined
      }
    case types.SUCCESS_SSO_NEW_REGISTER_CONFIRMATION:
      return {
        ...state,
        errorSsoNewRegisterConfirmation: false,
        loadingSsoNewRegisterConfirmation: false,
        successSsoNewRegisterConfirmation: action.response
      }
    case types.CLEAN_SSO_NEW_REGISTER_CONFIRMATION:
      return {
        ...state,
        errorSsoNewRegisterConfirmation: false,
        loadingSsoNewRegisterConfirmation: false,
        successSsoNewRegisterConfirmation: undefined
      }
    /** */
    default:
      return state
  }
}
import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'
import { PORTUGUESE_TRANSLATION } from '../shared-store/messages/pt'
import { ENGLISH_TRANSLATION } from '../shared-store/messages/en'

export default function localeReducer(state = initialState.locale, action) {
  switch (action.type) {
    case types.LOCALE_SELECTED:
      switch (action.locale) {
        case 'pt':
          return { ...initialState.locale, lang: PORTUGUESE_TRANSLATION.lang, messages: PORTUGUESE_TRANSLATION.messages };
        default:
          return { ...initialState.locale, lang: ENGLISH_TRANSLATION.lang, messages: ENGLISH_TRANSLATION.messages };
      }
    default:
      return state;
  }
};

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function filterReducer(state = initialState.filterReducer, action) {
  switch (action.type) {
    case types.ADD_FILTER:
      return {
        ...state,
        add: true,
        edit: false,
        remove: false,
        unknow: false,
        reset: false,
        apply: false,
        filter: action.filter
      }
    case types.EDIT_FILTER:
      return {
        ...state,
        add: false,
        edit: true,
        remove: false,
        unknow: false,
        reset: false,
        apply: false,
        filter: action.filter
      }
    case types.REMOVE_FILTER:
      return {
        ...state,
        add: false,
        edit: false,
        remove: true,
        unknow: false,
        reset: false,
        apply: false,
        filter: action.filter
      }
    case types.UNKOWN_FILTER:
      return {
        ...state,
        add: false,
        edit: false,
        remove: false,
        unknow: true,
        reset: false,
        apply: false,
        filter: action.filter
      }
    case types.RESET_FILTER:
      return {
        ...state,
        add: false,
        edit: false,
        remove: false,
        unknow: false,
        reset: true,
        apply: false,
        filter: action.filter
      }
    case types.APPLY_FILTER:
      return {
        ...state,
        add: false,
        edit: false,
        remove: false,
        unknow: false,
        reset: false,
        apply: true,
        filter: action.filter
      }
    case types.MIX_FILTER:
      return {
        ...state,
        childID: action.childID,
      }
    case types.FETCH_FILTER:
      return {
        ...state,
        childID: action.childID,
        action: action.action,
      }
    case types.UPPER_FILTER_DATA:
      return {
        ...state,
        sortValue: action.sortValue,
        limitValue: action.limitValue,
      }
    default:
      return state;
  }
}

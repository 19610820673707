import React, { useEffect, useState } from "react"
import { PostTree } from "./jobsSchema"
import Tree, { ItemId, RenderItemParams, TreeData, TreeItem, mutateTree } from "@atlaskit/tree"

interface ContentTreePanelProps {
  postTree: PostTree[]
}

function postTreeToTreeData(postTreeList: PostTree[]): TreeData {
  const convertItemToTreeItem = (node: PostTree): TreeItem => {
    const itemId = node.id

    const childrenIds: ItemId[] = node.items ? node.items.map((item) => convertItemToTreeItem(item).id) : []

    return {
      id: itemId,
      children: childrenIds,
      hasChildren: !!node.items,
      data: { id: node.id, title: node.title, description: node.description ? node.description : "" }, // You can add more data as needed
      isExpanded: true,
    }
  }
  const constructTree = (node: PostTree, items: TreeItem[]): TreeItem[] => {
    const item: TreeItem = convertItemToTreeItem(node)
    const newItems = [...items, item]
    const childrenItems: TreeItem[] = node.items ? node.items.flatMap((item) => constructTree(item, newItems)) : []

    return newItems.concat(childrenItems)
  }

  // Assume the root is known or static
  const rootId = "root" // You can set it as needed
  const rootItem: TreeItem = {
    id: rootId,
    children: postTreeList.map((item) => convertItemToTreeItem(item).id),
    hasChildren: true,
    data: { id: "rootData" }, // You can add more data for the root
  }

  const items = { [rootItem.id]: rootItem }

  postTreeList.forEach((item) => {
    const convertedItems: TreeItem[] = constructTree(item, [])
    convertedItems.forEach((cI) => (items[cI.id] = cI))
  })

  return {
    rootId: rootItem.id,
    items,
  }
}

const PADDING_PER_LEVEL = 16

/* POSTID reflects on the list of Assistants + topics + the inputs for the job selected and maybe something showing which is selected?*/
const ContentTreePanel: React.FC<ContentTreePanelProps> = (props) => {
  const [treeData, setTreeData] = useState<TreeData>(postTreeToTreeData(props.postTree))

  useEffect(() => {
    setTreeData(postTreeToTreeData(props.postTree))
  }, [props.postTree])

  const getIcon = (item: TreeItem, onExpand: (itemId: ItemId) => void, onCollapse: (itemId: ItemId) => void) => {
    if (item.children && item.children.length > 0) {
      return item.isExpanded ? (
        <i className='fas fa-chevron-down' onClick={() => onCollapse(item.id)}></i>
      ) : (
        <i className='fas fa-chevron-right' onClick={() => onExpand(item.id)}></i>
      )
    }
    return []
  }
  const handleItemClick = (itemId: ItemId) => {
    //Scroll into view to the postId with Ref post-itemId
    //SCROLL INTO VIEW ???
    const targetElement = document.getElementById(`post-item-${itemId}`)
    // Scroll into view
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  const renderItem = ({ item, depth, onExpand, onCollapse, provided }: RenderItemParams) => {
    const getSubDescription = (description: string): string => {
      const doc = new DOMParser().parseFromString(description, "text/html")
      const cleanDescription = doc.body.textContent || ""
      // const cleanDescription = DOMPurify.sanitize(description)
      const lenght = cleanDescription.length > 40 ? 40 : cleanDescription.length
      return lenght === 0 ? "{No Title}" : cleanDescription.substring(0, lenght) + "..."
    }

    const title = item.data
      ? item.data.title && item.data.title !== ""
        ? item.data.title
        : item.data.description && item.data.description !== ""
        ? getSubDescription(item.data.description)
        : "{No Title}"
      : "{No Title}"

    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={`tree-item`}
        onClick={() => handleItemClick(item.id)}
      >
        <span className='tree-icon'>{getIcon(item, onExpand, onCollapse)}</span>
        <span className='tree-text subtle'>{title}</span>
      </div>
    )
  }

  const onExpand = (itemId: ItemId) => {
    setTreeData(mutateTree(treeData, itemId, { isExpanded: true }))
  }

  const onColapse = (itemId: ItemId) => {
    setTreeData(mutateTree(treeData, itemId, { isExpanded: false }))
  }

  return (
    <div className='l-panel-container'>
      <div className='l-panel-header'>
        <DocTreeHeader />
      </div>
      <div className='l-panel-content'>
        <Tree
          tree={treeData}
          renderItem={renderItem}
          onExpand={onExpand}
          onCollapse={onColapse}
          offsetPerLevel={PADDING_PER_LEVEL}
          isDragEnabled={false}
        />
      </div>
    </div>
  )
}

//Header with context option

function DocTreeHeader(): JSX.Element {
  return (
    <>
      <i className='fas fa-file-alt'></i>
      <span> {""}</span>
    </>
  )
}

export default ContentTreePanel

import { ZodType } from "zod"
import { FormRender, SDFResponseHandler, mkVoidSDFResponseHandler } from "./FormRender"
import { SDForm } from "./sdftypes"
import { useEffect, useState } from "react"
import { ProvideInjectValues } from "./provideInjectValues"
import { Json } from "../jsonRequest/JsonRequest"
import { SDFSelect } from "./sdftypes"
import { ShortSite } from "../../areas/skynet/jobs/jobsSchema"

export type KnownForm = "start-tiktok" | "start-instagram" | "start-social-competitor-single"

type SDFormLoaderProps<ServerResponse = unknown> = {
  form: KnownForm
  client: {
    listSitesDestinations(): Promise<[]>
    form(formName: KnownForm): Promise<SDForm> // load form description
    submitForm(endpoint: String, json: Json, decoder?: ZodType<ServerResponse>): Promise<ServerResponse> // submit form values
  }
  responseHandler?: SDFResponseHandler<ServerResponse>
  injectValues: ProvideInjectValues
}

function getSiteSelect(siteList: ShortSite[], fieldId: string, fieldLabel: string): SDFSelect {
  return {
    id: fieldId,
    type: "select",
    label: fieldLabel,
    options: siteList.map((s) => ({ value: s.uuid, label: s.name })),
  }
}

export function fillSelects(form: SDForm, client: { listSitesDestinations(): Promise<[]> }): Promise<SDForm> {
  const formWithSites = form.fields.find((f) => f.type === "select-sites") ?? false

  if (formWithSites) {
    //get site list

    return client.listSitesDestinations().then((siteList) => {
      const adaptedFields = form.fields.map((f) => {
        if (f.type === "select-sites") {
          return getSiteSelect(siteList, f.id, f.label)
        } else {
          return f
        }
      })

      return { ...form, fields: adaptedFields }
    })
  } else {
    return Promise.resolve(form)
  }
}

// Loads and renders a know form from the API
export function FormLoader<ServerResponse = unknown>(props: SDFormLoaderProps<ServerResponse>): JSX.Element {
  const [form, setForm] = useState<SDForm | undefined>(undefined)
  useEffect(() => {
    props.client
      .form(props.form)
      .then((e) => fillSelects(e, props.client))
      .then((e) => setForm(e))
  }, [props.form, props.client])

  const responseHandler: SDFResponseHandler<ServerResponse> = props.responseHandler ?? mkVoidSDFResponseHandler()

  if (form)
    return (
      <FormRender<ServerResponse>
        form={form}
        client={props.client}
        provideInjectValues={props.injectValues}
        responseHandler={responseHandler}
      />
    )
  else return <></>
}

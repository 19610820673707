import React, { ReactElement } from "react"
import FormComponentHandler from "../formComponentHandler"
import { connect } from "react-redux"
import { bindActionCreators, Dispatch } from "redux"
import * as experienceManagerActions from "../../../store/actions/experienceManagerActions"
// import FoundationStylesUtils from "modules/shared-modules/foundationStyles/foundationStylesUtils"
import { SelectedController } from "modules/shared-modules/experienceManager/types/reduxTypes"
import { ResponsiveData } from "modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { PageTypes } from "modules/shared-modules/experienceManager/types/pageTypes"
import {
  getAttachedStylesheetLabel,
  handleLabelController,
} from "../../../modules/shared-modules/stylesheet/stylesheetUtils"
import Devices from "../../../areas/skynet/experienceManager/utils/devices"
import { ApplicationState } from "../../../store/ApplicationState"
import { ColorLabel } from "../../../modules/shared-modules/stylesheet/stylesheetTypes"
import GlobalStylesUtils from "../../../modules/shared-modules/globalStyles/globalStylesUtils"
import { GS_SHADOW } from "../../../modules/shared-modules/globalStyles/globalStylesTypes"
// import { FColor } from "modules/shared-modules/foundationStyles/foundationStylesTypes"

let ALPHAVALUES: { value: number; label: string }[] = []
for (let alpha = 0; alpha <= 100; alpha++) {
  ALPHAVALUES = [...ALPHAVALUES, { value: alpha, label: `${alpha}%` }]
}

interface Actions {
  actions: typeof experienceManagerActions
}

interface OwnProps {
  value: string
  uElement: string
  handleValueChange: (value: string) => void
  label: string
  customTrigger?: ReactElement
  colorInput: boolean
  selectedController: SelectedController
  colorResponsiveData: ResponsiveData<string>
  foundationStylesList: PageTypes["foundations"]
  finderType: "styles" | "content"
  bobKey: string
}

interface ReduxProps {
  selectedBobEM: ApplicationState["experienceManagerReducer"]["selectedBobEM"]
  pageResponse: ApplicationState["contentHubReducer"]["hubContent"]
  exportedColorElement: ApplicationState["experienceManagerReducer"]["exportedColorElement"]
  exportedColor: ApplicationState["experienceManagerReducer"]["exportedColor"]
  finderSelectedObject: ApplicationState["publicPageReducer"]["finderSelectedObject"]
  viewPortSizeState: ApplicationState["publicPageReducer"]["viewPortSizeState"]
  controllerState: ApplicationState["experienceManagerReducer"]["controllerState"]
}

type ColorPickerProps = ReduxProps & OwnProps & Actions

interface ColorPickerState {
  value: string
}

class colorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
  constructor(props: ColorPickerProps) {
    super(props)
    this.state = {
      value: this.props.value,
    }
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
    })
  }

  componentDidUpdate(prevProps: ColorPickerProps) {
    // props value changed
    if (this.state.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      })
    }
    // colorpicker wrap value change
    if (this.props.uElement === this.props.exportedColorElement && this.props.exportedColor) {
      this.props.actions.exportColorPicker(undefined, undefined)
      this.props.handleValueChange(this.props.exportedColor)
    }
  }

  handleInput(value: string, shouldToggle = true) {
    this.setState(
      {
        ...this.state,
        value,
      },
      () => {
        this.props.handleValueChange(value)
        shouldToggle && this.toggleColorPicker()
      }
    )
  }

  toggleColorPicker() {
    this.props.actions.openColorPicker(
      this.props.uElement,
      this.state.value,
      this.props.colorResponsiveData,
      this.props.selectedController,
      this.props.foundationStylesList,
      "colorPicker"
    )
  }

  // Color/Alpha Selector
  renderColorSelector() {
    // TODO: FIX ME
    // TODO: foundation color reverted
    // let selectedBob = this.props.selectedBobEM
    // let selectedBobTemplate = selectedBob?.objectSelected?.styles?.bobs?.[selectedBob.bobOpen]
    // let attachedFoundation
    // if (this.props.foundationStylesList && selectedBobTemplate) {
    // selectedBob = this.props.selectedBobEM
    // selectedBobTemplate = selectedBob?.objectSelected?.styles?.bobs?.[selectedBob.bobOpen]
    // attachedFoundation = getResponsiveAttachedFoundationStyle(
    //   selectedBobTemplate,
    //   this.props.foundationStylesList,
    //   this.props.selectedController.controller,
    //   this.props.selectedController.property,
    //   this.props.colorResponsiveData.state,
    //   this.props.colorResponsiveData.breakpoint
    // ) as FColor
    // }

    let labelController =
      this.props.bobKey && this.props.finderSelectedObject.styles.bobs[this.props.bobKey]
        ? handleLabelController(
            this.props.selectedController.controller,
            this.props.finderSelectedObject.styles.bobs[this.props.bobKey],
            Devices.handleSelectedBreakpoint(this.props.viewPortSizeState),
            this.props.controllerState || "default"
          )
        : {}
    const globalStyle =
      this.props.selectedController.controller === "shadow"
        ? GlobalStylesUtils.getAttachedGlobalStyle(
            this.props.pageResponse,
            this.props.finderSelectedObject,
            this.props.bobKey,
            GS_SHADOW
          )
        : undefined

    const attachedLabel = getAttachedStylesheetLabel(
      labelController,
      this.props.pageResponse,
      this.props.selectedController?.property,
      globalStyle
    ) as ColorLabel

    return (
      <div className='form-group'>
        <div className='box'>
          <div className='box-row'>
            {this.props.label && (
              <div className='inner-box' style={{ position: "relative" }}>
                <label htmlFor='' className='group-label m-0'>
                  {this.props.label}
                </label>
              </div>
            )}
            <div className='inner-box' style={{ position: "relative" }}>
              <div className='d-flex'>
                {!this.props.customTrigger ? (
                  <div
                    className='color-preview'
                    style={{
                      background: attachedLabel?.value || this.props.value,
                      // TODO: foundation color reverted
                      // background: attachedFoundation ? attachedFoundation.color : this.props.value,
                    }}
                    onClick={() => this.toggleColorPicker()}
                  />
                ) : (
                  <div className='is-clickable' onClick={() => this.toggleColorPicker()}>
                    {this.props.customTrigger}
                  </div>
                )}
                {this.props.colorInput && (
                  <FormComponentHandler
                    disabled={attachedLabel}
                    type='input_handler'
                    delay={1000}
                    value={attachedLabel?.name || this.state.value}
                    handleValueChange={(value: string) => this.handleInput(value, false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.renderColorSelector()
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators({ ...experienceManagerActions }, dispatch),
  }
}

function mapStateToProps(state: ApplicationState): ReduxProps {
  return {
    pageResponse: state.contentHubReducer.hubContent,

    selectedBobEM: state.experienceManagerReducer.selectedBobEM,
    finderSelectedObject: state.publicPageReducer.finderSelectedObject,

    exportedColorElement: state.experienceManagerReducer.exportedColorElement,
    exportedColor: state.experienceManagerReducer.exportedColor,

    viewPortSizeState: state.publicPageReducer.viewPortSizeState,
    controllerState: state.experienceManagerReducer.controllerState,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(colorPicker)

import React from "react"
import AvatarTextMaker from "../words/AvatarTextMaker"
import StringToColor from "../colors/stringToColor"

interface LetterPlaceholderProps {
  phrase: string
  squareWidth: string
  squareHeight: string
  fontColor: string
  radius: string
  className: any
  numberOfLetters: number
  fontSize: string
  backgroundColor?: string
}

class LetterPlaceholder extends React.Component<LetterPlaceholderProps> {
  render() {
    return (
      <div
        style={{
          borderRadius: this.props.radius,
          width: this.props.squareWidth,
          height: this.props.squareHeight,
          backgroundColor: this.props.backgroundColor
            ? this.props.backgroundColor
            : StringToColor.generate(this.props.phrase),
        }}
        className={`letter-placeholder ${this.props.className ? this.props.className : ""}`}
      >
        <p
          style={{
            color: this.props.fontColor,
            fontSize: this.props.fontSize,
          }}
        >
          {AvatarTextMaker.generate4Initials(this.props.phrase, this.props.numberOfLetters)}
        </p>
      </div>
    )
  }
}

export default LetterPlaceholder

import { Link } from "react-router-dom"
import DoJobSVG from "./dojoblog.svg"
import { Workspace } from "../profileTypes"

import { AppNavigation } from "./DoJobBoard"
import { Access } from "../../authentication/Access"

export function ToggleApp(props: { profile: Workspace }): JSX.Element {
  // if this profile can only use one app, hide the switcher
  if (Access.canAccessSingleApp(props.profile)) {
    return <></>
  }

  return (
    // TODO: não gosto que isto não pareça clickable
    <div className='my-lg-0'>
      <ul className='navbar-nav mr-auto'>
        <li className='nav-item icon-only dropdown'>
          <i
            className='dropdown-toggle caret-off fas fa-th-large'
            style={{ color: "rgba(255, 255, 255, 0.7)" }}
            data-toggle='dropdown'
          ></i>
          <div
            className='dropdown-menu dropdown-menu-left'
            style={{ padding: "5px", maxHeight: "calc(100vh - 200px)" }}
            aria-labelledby='navbarDropdown'
          >
            <div style={{ fontSize: "11px", color: "#5E6C84" }}>Switch To</div>
            <Link to={AppNavigation.profileProjects(props.profile.name)} className='dropdown-item'>
              <img
                className=''
                style={{ maxWidth: "30px", objectFit: "cover" }}
                src='https://meshapp-cdn.s3.eu-west-1.amazonaws.com/fluxio/Logo.svg'
                alt='fluxio.png'
              />
              Fluxio
            </Link>

            <Link to={AppNavigation.profileDoJob(props.profile.name)} className='dropdown-item'>
              {<img className='' style={{ maxWidth: "30px", objectFit: "cover" }} src={DoJobSVG} alt='dojob logo' />}
              DoJob
            </Link>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default ToggleApp

import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'
import size from 'lodash/size'
import findIndex from 'lodash/findIndex'
import { v4 as uuidv4 } from 'uuid'

export default function toolBoxReducer(state = initialState.toolBoxReducer, action) {
  switch (action.type) {
    case types.SETUP_PROCESS_ADD_SOURCE_TOOLBOX:
      return {
        ...state,
        setup: action.setup
      }
    case types.SELECTED_SERVICE_ADD_SOURCE_TOOLBOX:
      return {
        ...state,
        service: action.service
      }
    case types.REQUEST_PREVIEW_SOURCE_LINKS:
      return {
        ...state,
        loadingLinkPreview: true,
        errorLinkPreview: false
      }
    case types.SAVE_TWITTER_SEARCH_OBJ:
      return {
        ...state,
        twitterSearchObj: action.twitterSearchObj
      }
    case types.CONFIRM_PREVIEW_SOURCE_LINKS:

      let links = Object.assign({}, action.links)

      let subFeeds = []
      let podcastFeeds = []

      if (state.service === 'rss') {

        for (let mainF in links) {
          let parentId = uuidv4()

          let main = links[mainF]
          main.innerId = parentId

          if (size(main.feeds) === 0 && !main.error) {
            main.error = ('There is no content coming from ' + main.name)
          }

          for (let innerF in main.feeds) {
            let inner = main.feeds[innerF]
            inner.selectedToAdd = true
            inner.parentId = parentId
            inner.feedId = uuidv4()
            subFeeds.push(inner)
          }

        }

      }

      if (state.service === 'podcast') {
        let results = links.results
        let arr = []

        results.map(item => {
          let obj = {}
          obj.name = item.collectionName
          obj.image = item.artworkUrl100
          obj.description = item.artistName

          if (item.feedUrl) {
            return arr[item.feedUrl] = obj
          }

          return null

        })

        links = arr

      }

      if (state.service === 'twitter-search') {

        let results = links.tweets
        let arr = []

        if (!results || results.length === 0) {
          links = []
        } else {

          results.map((item, idx) => {

            let obj = {}
            obj.name = item.user.name ? item.user.name : ''
            obj.image = item.user.profile_image_url_https ? item.user.profile_image_url_https : ''
            obj.description = item.text ? item.text : ''

            arr[`tweet-${(idx + 1)}`] = obj

            return null

          })

          links = arr

        }

      }

      return {
        ...state,
        loadingLinkPreview: false,
        errorLinkPreview: false,
        linkPreviews: links,
        feedlySources: [...state.feedlySources, ...subFeeds, ...podcastFeeds]
      }
    case types.ERROR_PREVIEW_SOURCE_LINKS:
      return {
        ...state,
        loadingLinkPreview: false,
        errorLinkPreview: true
      }
    case types.CLEAR_PREVIEW_SOURCE_LINKS:
      return {
        ...state,
        loadingLinkPreview: false,
        errorLinkPreview: false,
        linkPreviews: null
      }
    case types.STORE_PREVIEW_SOURCE_LINKS:
      return {
        ...state,
        linkPreviewsStore: action.links
      }
    case types.CLEAR_STORE_PREVIEW_SOURCE_LINKS:
      return {
        ...state,
        linkPreviewsStore: undefined
      }
    case types.REMOVE_FROM_STORE_PREVIEW_SOURCE_LINKS:
      let sourceToRemove = action.sourceToRemove

      let previews = Object.assign({}, state.linkPreviewsStore)
      let feedlySources = Object.assign({}, state.feedlySources)

      let arrToGo = {}
      let completeSource = undefined
      let feedlyToGo = []

      for (let preview in previews) {
        if (preview !== sourceToRemove) {
          arrToGo[preview] = previews[preview]
        } else {
          completeSource = previews[preview]
        }
      }

      for (let feed in feedlySources) {
        if (completeSource) {
          if (feedlySources[feed].parentId !== completeSource.innerId) {
            feedlyToGo.push(feedlySources[feed])
          }
        }
      }

      return {
        ...state,
        linkPreviewsStore: arrToGo,
        feedlySources: feedlyToGo
      }
    case types.REQUEST_PROFILE_SOURCES:
      return {
        ...state,
        loadingProfileSources: true,
        profileSources: undefined,
        detailProfileSources: undefined,
        errorProfileSources: false
      }
    case types.CONFIRM_REQUEST_PROFILE_SOURCES:

      let sourcesToParse = Object.assign({}, action.sources)

      if (sourcesToParse.data) {

        for (let source in sourcesToParse.data) {
          sourcesToParse.data[source].detail = false
        }

      }

      return {
        ...state,
        loadingProfileSources: false,
        profileSources: action.sources,
        detailProfileSources: sourcesToParse,
        errorProfileSources: false
      }
    case types.ERROR_REQUEST_PROFILE_SOURCES:
      return {
        ...state,
        loadingProfileSources: false,
        profileSources: undefined,
        detailProfileSources: undefined,
        errorProfileSources: action.error
      }
    case types.UPDATE_PROFILE_SOURCE_DETAIL:

      let sources = Object.assign({}, state.detailProfileSources)

      for (let source in sources.data) {

        if (sources.data[source]._id === action.detailSource._id) {
          sources.data[source].detail = action.detailSource.detail
        } else {
          sources.data[source].detail = false
        }

      }

      return {
        ...state,
        detailProfileSources: sources,
      }
    case types.REQUEST_DELETE_PROFILE_SOURCES:
      return {
        ...state,
        loadingDeleteProfileSource: true,
        deletedProfileSource: undefined,
        errorDeleteProfileSource: false
      }
    case types.CONFIRM_DELETE_PROFILE_SOURCES:
      return {
        ...state,
        loadingDeleteProfileSource: false,
        deletedProfileSource: action.response,
        errorDeleteProfileSource: false
      }
    case types.ERROR_DELETE_PROFILE_SOURCES:
      return {
        ...state,
        loadingDeleteProfileSource: false,
        deletedProfileSource: undefined,
        errorDeleteProfileSource: true
      }
    case types.REQUEST_UPDATE_PROFILE_SOURCE:
      return {
        ...state,
        loadingUpdateProfileSource: true,
        updatedProfileSource: undefined,
        errorUpdateProfileSource: false
      }
    case types.CONFIRM_UPDATE_PROFILE_SOURCE:
      return {
        ...state,
        loadingUpdateProfileSource: false,
        updatedProfileSource: action.response,
        errorUpdateProfileSource: false
      }
    case types.ERROR_UPDATE_PROFILE_SOURCE:
      return {
        ...state,
        loadingUpdateProfileSource: false,
        UpdatedProfileSource: undefined,
        errorUpdateProfileSource: true
      }
    case types.CLEAR_PROFILE_SOURCES:
      return {
        ...state,
        loadingUpdateProfileSource: false,
        UpdatedProfileSource: undefined,
        errorUpdateProfileSource: false
      }
    case types.REQUEST_CREATE_SOCIAL_SOURCE:
      return {
        ...state,
        loadingCreateSocialSource: true,
        createdSocialSource: undefined,
        errorCreateSocialSource: false
      }
    case types.CONFIRM_CREATE_SOCIAL_SOURCE:
      return {
        ...state,
        loadingCreateSocialSource: false,
        createdSocialSource: action.response,
        errorCreateSocialSource: false
      }
    case types.ERROR_CREATE_SOCIAL_SOURCE:
      return {
        ...state,
        loadingCreateSocialSource: false,
        createdSocialSource: undefined,
        errorCreateSocialSource: action.error
      }
    case types.CLEAR_CREATE_SOCIAL_SOURCE:
      return {
        ...state,
        loadingCreateSocialSource: false,
        createdSocialSource: undefined,
        errorCreateSocialSource: false
      }
    case types.CLEAR_DELETE_SOCIAL_SOURCE:
      return {
        ...state,
        loadingDeleteProfileSource: false,
        deletedProfileSource: undefined,
        errorDeleteProfileSource: false
      }
    case types.FEEDLY_SUB_SOURCE_ADD:
      return {
        ...state,
        feedlySources: [...state.feedlySources, action.feedly_source]
      }
    case types.FEEDLY_SUB_SOURCES_CLEAR:
      return {
        ...state,
        feedlySources: []
      }
    case types.FEEDLY_SUB_SOURCE_UPDATE:

      let arr = Object.assign([], state.feedlySources)

      let index = findIndex(arr, (o) => { return o.feedId === action.feedly_source.feedId })

      let item = Object.assign({}, arr[index])

      item.selectedToAdd = !item.selectedToAdd

      arr.splice(index, 1, item)

      return {
        ...state,
        feedlySources: [...arr]
      }
    case types.REQUEST_SOURCE_SUB_ITEMS:
      return {
        ...state,
        sourceSubItems: [...state.sourceSubItems],
        loadingSourceSubItems: true,
        errorSourceSubItems: false,
        activeSourceDetail: action.activeSourceDetail
      }
    case types.CONFIRM_REQUEST_SOURCE_SUB_ITEMS:

      let arrResponse = []
      let hasMore = !(action.currentPage === action.response.last_page)

      for (let key in action.response.data) {
        let feed = Object.assign({}, action.response.data[key])
        if (feed.sourceId === state.activeSourceDetail) {
          arrResponse.push(feed)
        }
      }

      return {
        ...state,
        sourceSubItems: [...state.sourceSubItems, ...arrResponse],
        loadingSourceSubItems: false,
        errorSourceSubItems: false,
        hasMoreSubLinks: hasMore
      }
    case types.ERROR_REQUEST_SOURCE_SUB_ITEMS:
      return {
        ...state,
        sourceSubItems: [...state.sourceSubItems],
        loadingSourceSubItems: false,
        errorSourceSubItems: action.error
      }
    case types.CLEAR_SOURCE_SUB_ITEMS:
      return {
        ...state,
        sourceSubItems: [],
        loadingSourceSubItems: false,
        errorSourceSubItems: false,
        activeSourceDetail: undefined
      }
    case types.CONFIRM_DELETE_SOURCE_SUB_ITEMS:

      let arrToGoDelete = []

      for (let key in state.sourceSubItems) {
        let link = Object.assign({}, state.sourceSubItems[key])
        if (link.uuid !== action.linkToDelete.uuid) {
          arrToGoDelete.push(link)
        }
      }

      return {
        ...state,
        sourceSubItems: [...arrToGoDelete],
        sourceSubLinkDeleted: true,
        sourceSubLinkDeletedError: false
      }
    case types.ERROR_DELETE_SOURCE_SUB_ITEMS:
      return {
        ...state,
        sourceSubLinkDeleted: false,
        sourceSubLinkDeletedError: action.error
      }
    case types.CLEAR_DELETE_SUB_ITEMS:
      return {
        ...state,
        sourceSubLinkDeleted: false,
        sourceSubLinkDeletedError: false
      }
    default:
      return state
  }
}
import { FluxioPostId, PostTree } from "./jobsSchema"

export class PostTreeUtil {
  static replacePost(trees: PostTree[], id: FluxioPostId, p: PostTree): PostTree[] {
    return trees.map((pt) => {
      if (pt.id === id) {
        // found the post, replace
        return p
      } else {
        // recurse
        const items = PostTreeUtil.replacePost(pt.items ?? [], id, p)
        return { ...pt, items: items }
      }
    })
  }

  // this doesn't early-return, could be optmized
  static appendChild(trees: PostTree[], parent: FluxioPostId, child: PostTree): PostTree[] {
    return trees.map((pt) => {
      if (pt.id === parent) {
        // found the parent, append
        const items = [...(pt.items ?? []), child]
        return { ...pt, items: items }
      } else {
        // recurse
        const items = PostTreeUtil.appendChild(pt.items ?? [], parent, child)
        return { ...pt, items: items }
      }
    })
  }

  static remove(trees: PostTree[], it: FluxioPostId): PostTree[] {
    return trees.flatMap((pt) => {
      if (pt.id === it) {
        // found it, return empty list
        return []
      } else {
        // recurse
        const items = PostTreeUtil.remove(pt.items ?? [], it)
        return [{ ...pt, items: items }]
      }
    })
  }
}

import { toast } from "react-toastify"
import { ApiErrorModule } from "../jsonRequest/ApiError"

export class Toasts {
  static success(label: String): void {
    toast.success(`${label}`, {
      autoClose: 5000,
      hideProgressBar: true,
      position: toast.POSITION.TOP_RIGHT,
    })
  }

  static handleError<A>(label: string): (e: any) => A {
    return (e) => {
      toast.error(`${label}`, {
        autoClose: 5000,
        hideProgressBar: false,
        position: toast.POSITION.TOP_RIGHT,
      })
      throw e
    }
  }

  static forApiError<A>(label: string): (e: any) => A {
    return (e) => {
      const msg = ApiErrorModule.isApiError(e) ? e.error : ""

      const content = (
        <>
          <p>
            <strong>{label}</strong>
          </p>
          {msg ?? <p>{msg}</p>}
        </>
      )

      toast.error(content, {
        autoClose: 5000,
        hideProgressBar: false,
        position: toast.POSITION.TOP_RIGHT,
      })
      throw e
    }
  }

  /**
   * Wrap a promise to trigger a toast that shows an ApiError message. Should be used like:
   *
   * Toasts.onApiError("Some label", somePromise)
   *    .then(something)
   *
   * The error is rethrown, callers can still call `catch` to handle failure.
   */
  static onApiError<A>(label: string, promise: Promise<A>): Promise<A> {
    return promise.then(
      (e) => e,
      (err) => this.forApiError(label)(err) as A
    )
  }
}

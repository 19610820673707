import { objValueExists, valueExists } from "../../../../../modules/shared-modules/utilities/utils"
import { isRight } from "fp-ts/lib/Either"
import {
  type,
  union,
  Type,
  TypeOf,
  intersection,
  null as nullC,
  number as numberC,
  boolean as booleanC,
  partial,
} from "io-ts"
import {
  BehaviourState_,
  Breakpoint,
} from "../../../../../modules/shared-modules/experienceManager/finder/inputs/bobControllerTypes"
import { decoderErrors } from "../codec/codecUtils"
import { get2WithNull4Enable, get3WithNull4Enable } from "../bobUtils"

//Temp to handle nulls
const nullable = <A>(t: Type<A>) => union([t, nullC])

const RadiusCodec = type({
  bottomLeft: numberC,
  bottomRight: numberC,
  enable: booleanC,
  topLeft: numberC,
  topRight: numberC,
})
const RadiusOptCodec = partial({
  bottomLeft: nullable(numberC),
  bottomRight: nullable(numberC),
  enable: nullable(booleanC),
  topLeft: nullable(numberC),
  topRight: nullable(numberC),
})

const StylesRadiusCodec = intersection([
  type({ radius: RadiusCodec }),
  partial({
    behaviour: partial({
      active: partial({ radius: RadiusOptCodec }),
      hover: partial({ radius: RadiusOptCodec }),
    }),
    mobile: intersection([
      partial({ radius: RadiusOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ radius: RadiusOptCodec }),
          hover: partial({ radius: RadiusOptCodec }),
        }),
      }),
    ]),
    tablet: intersection([
      partial({ radius: RadiusOptCodec }),
      partial({
        behaviour: partial({
          active: partial({ radius: RadiusOptCodec }),
          hover: partial({ radius: RadiusOptCodec }),
        }),
      }),
    ]),
  }),
])

type Radius = TypeOf<typeof RadiusCodec>
type RadiusProps = TypeOf<typeof RadiusCodec>
type RadiusOpt = TypeOf<typeof RadiusOptCodec>
type RadiusPropsOpt = TypeOf<typeof RadiusOptCodec>
type StylesRadius = TypeOf<typeof StylesRadiusCodec>
type RadiusCSS = {
  "border-top-left-radius": string
  "border-top-right-radius": string
  "border-bottom-left-radius": string
  "border-bottom-right-radius": string
}

export function responsiveStyle(radiusObj: any) {
  if (radiusObj.hasOwnProperty("enable") && !objValueExists(radiusObj, "enable")) return ""

  if (radiusObj.hasOwnProperty("enable") && !radiusObj.enable) return { "border-radius": "0 !important" }

  return {
    "border-top-left-radius": `${radiusObj.topLeft}px !important`,
    "border-top-right-radius": `${radiusObj.topRight}px !important`,
    "border-bottom-left-radius": `${radiusObj.bottomLeft}px !important`,
    "border-bottom-right-radius": `${radiusObj.bottomRight}px !important`,
  }
}

export function cssRenderUnsafe(
  stylesObj: any,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<RadiusCSS> {
  const styles = StylesRadiusCodec.decode(stylesObj)
  if (isRight(styles)) return cssRender(styles.right, breakpoint, behaviourState)
  console.warn(`RadiusStyle ${breakpoint} ${behaviourState} ${decoderErrors(styles)}`)
  return {}
}

export function cssRender(
  stylesObj: StylesRadius,
  breakpoint: Breakpoint,
  behaviourState: BehaviourState_
): Partial<RadiusCSS> {
  if (breakpoint === "desktop") {
    if (behaviourState === "default") {
      return renderBob(stylesObj.radius)
    }
    //hover | active
    else {
      return renderBobOpt(stylesObj.radius, mergeBob2(stylesObj?.behaviour?.[behaviourState]?.radius, stylesObj.radius))
    }
  }
  //tablet | mobile
  else {
    if (behaviourState === "default") {
      return renderBobOpt(stylesObj.radius, mergeBob2(stylesObj?.[breakpoint]?.radius, stylesObj.radius))
    }
    //hover | active
    else {
      return renderBobOpt(
        stylesObj.radius,
        mergeBob3(
          stylesObj?.[breakpoint]?.behaviour?.[behaviourState]?.radius,
          stylesObj?.behaviour?.[behaviourState]?.radius,
          stylesObj?.[breakpoint]?.radius,
          stylesObj.radius
        )
      )
    }
  }
}

export function renderBob(radiusObj: Radius): Partial<RadiusCSS> {
  if (!radiusObj.enable) return {}

  return render(radiusObj)
}

/**
 * Renders ColorsOpt css for breakpoints/state templates
 * or empty for non written style props
 *
 * @param radiusObj
 * @param foundationStyle
 * @returns
 */
export function renderBobOpt(defaultRadiusObj: Radius, radiusObj: RadiusOpt | undefined): Partial<RadiusCSS> {
  if (radiusObj?.enable === false) {
    if (defaultRadiusObj.enable)
      return {
        "border-top-left-radius": "0px",
        "border-top-right-radius": "0px",
        "border-bottom-left-radius": "0px",
        "border-bottom-right-radius": "0px",
      }

    return {}
  }

  if (radiusObj?.enable) return renderOpt(radiusObj)

  return {}
}

/**
 * Renders RadiusOpt css for breakpoints/state templates
 * Returns color
 * or empty for non written style props
 *
 * @param radiusObj
 * @param foundationStyle
 * @returns
 */
export function renderOpt(radiusObj: RadiusOpt | undefined): Partial<RadiusCSS> {
  let css = {}
  if (valueExists(radiusObj?.bottomLeft)) css = { ...css, "border-bottom-left-radius": `${radiusObj?.bottomLeft}px` }
  if (valueExists(radiusObj?.bottomRight)) css = { ...css, "border-bottom-right-radius": `${radiusObj?.bottomRight}px` }
  if (valueExists(radiusObj?.topLeft)) css = { ...css, "border-top-left-radius": `${radiusObj?.topLeft}px` }
  if (valueExists(radiusObj?.topRight)) css = { ...css, "border-top-right-radius": `${radiusObj?.topRight}px` }
  return css
}

export function render(radiusObj: RadiusProps): RadiusCSS {
  return {
    "border-top-left-radius": `${radiusObj.topLeft}px`,
    "border-top-right-radius": `${radiusObj.topRight}px`,
    "border-bottom-left-radius": `${radiusObj.bottomLeft}px`,
    "border-bottom-right-radius": `${radiusObj.bottomRight}px`,
  }
}

export function mergeBob2(radiusObj: RadiusOpt | undefined, defaultRadiusObj: Radius): RadiusOpt {
  const enable = get2WithNull4Enable(radiusObj?.enable, defaultRadiusObj.enable)

  return {
    enable,
    bottomLeft: radiusObj?.bottomLeft,
    bottomRight: radiusObj?.bottomRight,
    topLeft: radiusObj?.topLeft,
    topRight: radiusObj?.topRight,
  }
}

export function mergeBob3(
  radiusObj: RadiusOpt | undefined,
  radiusDesktopBehaviour: RadiusOpt | undefined,
  radiusDefaultBreakpoint: RadiusOpt | undefined,
  defaultRadiusObj: Radius
): RadiusOpt {
  const enable = get3WithNull4Enable(
    radiusObj?.enable,
    radiusDesktopBehaviour?.enable,
    radiusDefaultBreakpoint?.enable,
    defaultRadiusObj.enable
  )

  return {
    enable,
    bottomLeft: radiusObj?.bottomLeft,
    bottomRight: radiusObj?.bottomRight,
    topLeft: radiusObj?.topLeft,
    topRight: radiusObj?.topRight,
  }
}

export type { StylesRadius, RadiusProps, RadiusPropsOpt, Radius, RadiusOpt, RadiusCSS }


//
//  Created by Ricardo Pimentel
//  Copyright © 2017 MeshApp. All rights reserved.
//
import React from 'react';
import ReactDOM from 'react-dom'
import Client from './client'
import ResizeObserver from 'resize-observer-polyfill'
// import LogRocket from 'logrocket'
// LogRocket.init('902t73/fluxio')
/**
 * Fix for old browsers and off the spec, like safari
 */
window.ResizeObserver = ResizeObserver

ReactDOM.render(<Client />, document.getElementById('root'))
//registerServiceWorker()

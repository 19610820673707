import { InstanceContent } from "../../types/objectContentTypes"
import { SingleObject } from "../../types/singleObjectTypes"

interface Symbol {
  objects: { [objectId: string]: SingleObject }
  rootId: string
  uuid: string
}

interface Instance {
  uuid: SingleObject['uuid']
  uri: SingleObject['uri']
  type: SingleObject['type']
  parentId: SingleObject['parentId']
  label: SingleObject['label']
  description: SingleObject['description']
  hidden: SingleObject['hidden']
  isContentBlock: SingleObject['isContentBlock']
  children: Array<string>
  content: InstanceContent
  isInstanceOf: string
}

export const CONTENT_TYPE_INSTANCE = 'instance'
export const CONTENT_TYPE_SYMBOL = 'symbol'

type ContentTypeSymbol = typeof CONTENT_TYPE_INSTANCE | typeof CONTENT_TYPE_SYMBOL

type SymbolObject = { symbolObj: SingleObject }

export type {
  Symbol,
  Instance,
  ContentTypeSymbol,
  SymbolObject
}
import {
  getOverrideInlineContentValues,
  handleComponentLinks,
} from "../../../../modules/shared-modules/utilities/components"
import {
  ObjectContent,
  ObjectContentOverride,
  Post,
} from "../../../../modules/shared-modules/experienceManager/types/objectContentTypes"
import { PageTypes } from "../../../../modules/shared-modules/experienceManager/types/pageTypes"
import { GoogleEvent } from "../analytics/analytics"

// Returns the values ready to be added to the <a>
export function getLinkAttributes(
  inlineField: string,
  content: {
    inlineContent: ObjectContent["inlineContent"]
    overrides?: ObjectContentOverride
  },
  pageResponse: PageTypes,
  post?: Post
): { href: string; target: string; rel: string } | {} {
  const isPostPath = getOverrideInlineContentValues(`${inlineField}.behaviour-link-mappedValue`, content)?.startsWith(
    "$"
  )
  let linkAttrs: any = {}
  //(templateStyles.behaviour && (templateStyles.behaviour.link.enable && ((templateStyles.behaviour.link.customUrl && templateStyles.behaviour.link.customUrl !== '') || isPostPath)))
  if (
    getOverrideInlineContentValues(`${inlineField}.behaviour-link-enable`, content) &&
    ((getOverrideInlineContentValues(`${inlineField}.behaviour-link-customUrl`, content) &&
      getOverrideInlineContentValues(`${inlineField}.behaviour-link-customUrl`, content) !== "") ||
      isPostPath)
  ) {
    linkAttrs["href"] = handleComponentLinks(content.inlineContent, inlineField, pageResponse, content.overrides, post)
    linkAttrs["target"] = getOverrideInlineContentValues(`${inlineField}.behaviour-link-target`, content)
    linkAttrs["rel"] = linkAttrs["href"] && linkAttrs["href"].startsWith("http") ? "noopener" : ""
  }
  return linkAttrs
}

// Returns the label to classify the event in google analytics
// According to this doc: https://fluxio.atlassian.net/wiki/spaces/DEV/pages/329449473/Analytics
export function analyticsLinkLabel(
  inlineField: string,
  content: {
    inlineContent: ObjectContent["inlineContent"]
    overrides?: ObjectContentOverride
  },
  pageResponse: PageTypes,
  post?: Post
): string {
  const mappedValue = getOverrideInlineContentValues(`${inlineField}.behaviour-link-mappedValue`, content)
  const url = handleComponentLinks(content.inlineContent, inlineField, pageResponse, content.overrides, post)
  let linkAnalyticsLabel = ""
  if (mappedValue?.startsWith("$")) linkAnalyticsLabel === `cms:${url}`
  if (mappedValue === "custom") linkAnalyticsLabel = `url:${url}`
  if (mappedValue === "page") linkAnalyticsLabel = `page:${url}`
  if (mappedValue === "anchor") linkAnalyticsLabel = `anchor:${url}`

  return linkAnalyticsLabel
}

export function clickEvent(
  e: any,
  tag: string,
  field: string,
  inlineContent: ObjectContent["inlineContent"],
  overrides: ObjectContentOverride | undefined,
  pageResponse: PageTypes,
  post: Post | undefined
) {
  if (tag === "a") {
    const linkLabel = analyticsLinkLabel(
      field,
      {
        inlineContent: inlineContent,
        overrides: overrides,
      },
      pageResponse,
      post
    )
    //get parent id to properly know who has the link configured
    const parentId = e.target.parentElement?.id
    if (parentId) {
      GoogleEvent("link_click", linkLabel, "Link Clicked", 1)
    }
  }
}

export function emPreviewClass(isExperienceManage: boolean, styleId: string): string {
  return isExperienceManage ? `spacing-preview-${styleId}` : ""
}

export function emScrollId(isExperienceManager: boolean, objectUuid: string): { "data-id": string } | {} {
  return isExperienceManager ? { "data-id": `object-${objectUuid}` } : {}
}

export function showIfWritten(object: any, key: string) {
  return key in object ? `${key}: ${object[key]};` : ""
}

export function get2<A>(x: A | undefined, y: A): A {
  return typeof x === "undefined" ? y : x
}

export function get2WithNull<A>(x: A | null | undefined, y: A): A {
  return typeof x === "undefined" || x === null ? y : x
}

export function get2WithNull4Enable<A>(x: A, y: boolean): A | boolean {
  return typeof x === "undefined" || x === null ? (y === true ? y : x) : x
}

export function get3WithNull<A>(x: A | null | undefined, y: A, k: A, z: A): A {
  return typeof x === "undefined" || x === null ? y ?? k ?? z : x
}

export function get3WithNull4Enable<A>(x: A | null | undefined, y: A, k: A, z: boolean): A | boolean {
  return typeof x === "undefined" || x === null ? y ?? k ?? (z === true ? z : k) : x
}

/**
 * Deals with default value if condition is true
 *
 * @param x state/breakpoint value
 * @param y default value
 * @param c condition
 * @returns x | y
 */
export function get2WithNullConditional<A>(x: A | null | undefined, y: A, c: boolean): A | undefined | null {
  if (c) return get2WithNull(x, y)
  return x
}

/**
 * Deals with default value if condition is true
 *
 * @param x state/breakpoint value
 * @param y default value
 * @param c condition
 * @returns x | y
 */
export function get3WithNullConditional<A>(
  x: A | null | undefined,
  y: A,
  k: A,
  z: A,
  c: boolean
): A | undefined | null {
  if (c) get3WithNull(x, y, k, z)
  return x
}

export function renderImportant(shouldRenderImportant: boolean): string {
  return shouldRenderImportant ? " !important" : ""
}
import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function boardReducer(state = initialState.boardReducer, action) {
  switch (action.type) {
    case types.CLEAR_ALL_POST_CONTENT:
      return {
        ...state,
        allPostContent: null,
        infiniteScrollClear: action.uniqueId
      }
    case types.ERROR_ALL_POST_CONTENT:
    case types.ERROR_ALL_POST_SUB_CONTENT:
      return {
        ...state,
        allPostContentProfile: action.profile,
        allPostContentQuery: action.query,
        allPostContentUntil: action.until,
        allPostContentError: action.error,
        allPostContentLoading: false,
        allPostContentFilter: action.filter,
        allPostContentFormulaId: action.formulaId,
        allPostContentUniqueId: null
      }
    case types.FETCH_ALL_POST_CONTENT:
    case types.FETCH_ALL_POST_SUB_CONTENT:
      return {
        ...state,
        allPostContentProfile: action.profile,
        allPostContentQuery: action.query,
        allPostContentUntilFetch: action.until,
        allPostContentLoading: true,
        allPostContentFilter: action.filter,
        allPostContentFormulaId: action.formulaId,
        allPostContentError: null,
        allPostContentUniqueId: null
      }
    case types.RECEIVE_ALL_POST_CONTENT:
    case types.RECEIVE_ALL_POST_SUB_CONTENT:
      //check if its coming from a filter query
      if (action.filter) {
        // infinite load filter mode
        if (state.allPostContent && action.profile === state.allPostContentProfile && action.until !== state.allPostContentUntil && state.allPostContent.response.hasMore) {
          let allPostContentObjectIL = {
            response: {
              content: action.content.response.content ? [...state.allPostContent.response.content, ...action.content.response.content] : [...state.allPostContent.response.content],
              hasMore: action.content.response.hasMore
            }
          }
          return {
            ...state,
            allPostContentProfile: action.profile,
            allPostContentQuery: action.query,
            allPostContentUntil: action.until,
            allPostContent: allPostContentObjectIL,
            allPostContentLoading: false,
            allPostContentFilter: action.filter,
            allPostContentFormulaId: action.formulaId,
            allPostContentUniqueId: action.uniqueId,
            allPostContentHasMore: action.hasMore,
            allPostContentTotalItems: action.totalItems,
          }
        }
        /* END IF */
        let allPostContentObject = {
          response: {
            content: action.content.response.content ? [...action.content.response.content] : null,
            hasMore: action.content.response.hasMore
          }
        }
        return {
          ...state,
          allPostContentProfile: action.profile,
          allPostContentQuery: action.query,
          allPostContentUntil: action.until,
          allPostContent: allPostContentObject,
          allPostContentLoading: false,
          allPostContentFilter: action.filter,
          allPostContentFormulaId: action.formulaId,
          allPostContentUniqueId: action.uniqueId,
          allPostContentHasMore: action.hasMore,
          allPostContentTotalItems: action.totalItems,
        }
      }
      //check if its a load more content request
      if (state.allPostContent && action.profile === state.allPostContentProfile && action.until !== state.allPostContentUntil) {
        //rebuild the object with the aditional posts
        let allPostContentObject = {
          response: {
            content: [...state.allPostContent.response.content, ...action.content.response.content],
            hasMore: action.content.response.hasMore
          }
        }
        return {
          ...state,
          allPostContentProfile: action.profile,
          allPostContentQuery: action.query,
          allPostContentUntil: action.until,
          allPostContent: allPostContentObject,
          allPostContentLoading: false,
          allPostContentFilter: action.filter,
          allPostContentFormulaId: action.formulaId,
          allPostContentHasMore: action.hasMore,
          allPostContentTotalItems: action.totalItems,
        }
      }
      return {
        ...state,
        allPostContentProfile: action.profile,
        allPostContentQuery: action.query,
        allPostContentUntil: action.until,
        allPostContent: action.content,
        allPostContentLoading: false,
        allPostContentFilter: action.filter,
        allPostContentFormulaId: action.formulaId,
        allPostContentHasMore: action.hasMore,
        allPostContentTotalItems: action.totalItems,
      }
    case types.ADD_POST_LIST_ITEM:
      let postsListToAdd = { ...state.allPostContent }
      postsListToAdd.response.content = [action.newPost, ...postsListToAdd.response.content]
      return {
        ...state,
        allPostContent: postsListToAdd
      }
    case types.UPDATE_POST_LIST_ITEM:
      let postsListToUpdate = { ...state.allPostContent }
      postsListToUpdate.response.content = postsListToUpdate.response.content.map(post => post.id === action.newPost.id ? action.newPost : post)
      return {
        ...state,
        allPostContent: postsListToUpdate
      }
    case types.DELETE_POST_LIST_ITEM:
      let postsListToDelete = { ...state.allPostContent }
      postsListToDelete.response.content = postsListToDelete.response.content.filter(post => post.sourceId !== action.postId)
      return {
        ...state,
        allPostContent: postsListToDelete
      }
    case types.PROFILE_SELECTED:
      return {
        ...state,
        profile: action.profile
      }
    case types.PROFILE_SELECTED_INFO:
      return {
        ...state,
        profileObj: action.profileObj
      }
    case types.MQLS_TO_CHECK_AND_GET:
      return {
        ...state,
        checkMeMQL: action.obj
      }
    case types.MQLS_TO_CHECK_AND_GET_COMPLETE:
      return {
        ...state,
        checkMeMQLComplete: action.obj
      }
    case types.ERROR_REQUEST_EXPERIENCE_ORDER_CHANGE:
      return {
        ...state,
        errorChangeExpOrder: action.error,
        loadingChangeExpOrder: false,
        responseChangeExpOrder: undefined
      }
    case types.REQUEST_EXPERIENCE_ORDER_CHANGE:
      return {
        ...state,
        errorChangeExpOrder: undefined,
        loadingChangeExpOrder: true,
        responseChangeExpOrder: undefined
      }
    case types.SUCCESS_CHANGE_EXPERIENCE_ORDER:
      return {
        ...state,
        errorChangeExpOrder: undefined,
        loadingChangeExpOrder: false,
        responseChangeExpOrder: action.response
      }
    case types.CLEAR_REDUX_POST_ORDERING:
      return {
        ...state,
        errorChangeExpOrder: undefined,
        loadingChangeExpOrder: false,
        responseChangeExpOrder: undefined,
        errorDeleteExpOrder: undefined,
        loadingDeleteExpOrder: false,
        responseDeleteExpOrder: undefined,
      }
    case types.ERROR_REQUEST_EXPERIENCE_ORDER_DELETE:
      return {
        ...state,
        errorDeleteExpOrder: action.error,
        loadingDeleteExpOrder: false,
        responseDeleteExpOrder: undefined
      }
    case types.REQUEST_EXPERIENCE_ORDER_DELETE:
      return {
        ...state,
        errorDeleteExpOrder: undefined,
        loadingDeleteExpOrder: true,
        responseDeleteExpOrder: undefined
      }
    case types.SUCCESS_DELETE_EXPERIENCE_ORDER:
      return {
        ...state,
        errorDeleteExpOrder: undefined,
        loadingDeleteExpOrder: false,
        responseDeleteExpOrder: action.response
      }
    default:
      return state
  }
}

import { Instant } from "../brand/Instant"

function startedAgo(startedAt: Instant): string {
  const startTime = new Date(startedAt).getTime()
  const currentTime = new Date().getTime() //??? CHECK TIMEZONES

  if (isNaN(startTime) || isNaN(currentTime)) {
    return "-"
  }

  if (startTime >= currentTime) {
    return "-"
  }

  const timeDifference = Math.abs(currentTime - startTime)
  const days = Math.floor(timeDifference / (1000 * 60 * 60) / 24)
  const hours = Math.floor(timeDifference / (1000 * 60 * 60))
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor(timeDifference / 1000)

  let time = ""
  if (days > 0) time = days + "d ago"
  else if (hours > 0) {
    time = hours + "h ago"
  } else if (minutes > 0) {
    time = minutes + "m ago"
  } else {
    time = seconds + "s ago"
  }

  return time
}

export const RelativeTimestamp: React.FC<{ at: Instant }> = (props) => {
  return (
    <span title={props.at} data-for='pipeline-action-tooltip' data-place='bottom'>
      {startedAgo(props.at)}
    </span>
  )
}

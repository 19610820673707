export class Promises {
  static void(): Promise<void> {
    return Promise.resolve()
  }

  static pure<A>(a: A): Promise<A> {
    return this.void().then(() => a)
  }

  static sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
}

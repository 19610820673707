import cloneDeep from 'lodash/cloneDeep'
import { responsiveStyle as responsiveStyleBorder } from "../../../areas/public-page/shared-public-page/bob/bobStyles/borderStyle"
import { responsiveStyle as responsiveStyleBoundary } from "../../../areas/public-page/shared-public-page/bob/bobStyles/boundaryStyle"
import { responsiveStyle as responsiveStyleFilter} from "../../../areas/public-page/shared-public-page/bob/bobStyles/filterStyle"
import { responsiveStyle as responsiveStyleRadius} from "../../../areas/public-page/shared-public-page/bob/bobStyles/radiusStyle"
import { responsiveStyle as ShadowStyleResponsiveStyle } from "../../../areas/public-page/shared-public-page/bob/bobStyles/shadowStyle"
import { TextStyleState } from "../../../modules/shared-modules/design/globalStyles/text/textStyleAccess"
import { BoundaryState } from "../../../modules/shared-modules/experienceManager/finder/inputs/boundaryController/boundaryHelper"
import { ShadowState } from "../../../modules/shared-modules/experienceManager/finder/inputs/shadowController/shadowHelper"
import { shouldBeNull } from "../utilities/utils"
import * as gsType from "./globalStylesTypes"
import { nGlobalStyle } from "./globalStylesTypes"

class GlobalStylesResponsiveHandler {
  /**
   *
   * @param bobObj
   * @param breakpoint
   * @param optionalProperties
   * This param is used to send aditional values that are needed for a specific style
   * The object must be named with the correct property name and the values inside an object, like so:
   * [property]: { valueName: value, valueName: value, etc}
   */
  static responsiveProperties(
    bobObj: any,
    objType: string,
    breakpoint: "mobile" | "tablet",
    optionalProperties: any = {}
  ) {
    let cssStylesObj = {}
    //We have properties to render
    if (bobObj[breakpoint]) {
      cssStylesObj = {
        ...cssStylesObj,
        ...this.stylePropertyHandler(objType, bobObj[breakpoint], optionalProperties),
      }
    }
    return cssStylesObj
  }

  static stylePropertyHandler(objType: string, styleObj: any, optionalProperties: any) {
    switch (objType) {
      case gsType.GS_BOUNDARY:
        return responsiveStyleBoundary(styleObj)
      case gsType.GS_SHADOW:
        return ShadowStyleResponsiveStyle(styleObj, optionalProperties)
      case gsType.GS_BORDER:
        return responsiveStyleBorder(styleObj)
      case gsType.GS_BORDER_RADIUS:
        return responsiveStyleRadius(styleObj)
      case gsType.GS_FILTER:
        return responsiveStyleFilter(styleObj, optionalProperties)
      default:
        return {}
    }
  }

  static writeShadowControllerToGlobalStyle(shadowObj: ShadowState, shadowGS?: nGlobalStyle): nGlobalStyle {
    let clonedShadowGS = cloneDeep(shadowGS) as nGlobalStyle
    /**
     * TODO: Make universal object with this properties, and conditional properties, like enable
     */
    const shadow = {
      blur: shouldBeNull(shadowObj.blur, true),
      color: shouldBeNull(shadowObj.color),
      inset: shouldBeNull(shadowObj.inset),
      offsetH: shouldBeNull(shadowObj.offsetH, true),
      offsetV: shouldBeNull(shadowObj.offsetV, true),
      spread: shouldBeNull(shadowObj.spread, true),
    }
    const state = shadowObj.blur.state
    const breakpoint = shadowObj.blur.breakpoint

    if (breakpoint === "desktop") {
      //state hover/active
      if (state === "hover" || state === "active") {
        clonedShadowGS = {
          ...clonedShadowGS,
          behaviour: {
            [state]: {
              ...clonedShadowGS.behaviour?.[state],
              ...shadow,
            },
          },
        }
      }
      //state default
      else {
        clonedShadowGS = {
          ...clonedShadowGS,
          ...shadow,
        }
      }
    }
    //Responsive
    else {
      //state hover/active
      if (state === "hover" || state === "active") {
        clonedShadowGS = {
          ...clonedShadowGS,
          [breakpoint]: {
            ...clonedShadowGS[breakpoint],
            behaviour: {
              [state]: {
                ...clonedShadowGS[breakpoint]?.behaviour?.[state],
                ...shadow,
              },
            },
          },
        }
      }
      //state default
      else {
        clonedShadowGS = {
          ...clonedShadowGS,
          [breakpoint]: {
            ...clonedShadowGS[breakpoint],
            ...shadow,
          },
        }
      }
    }
    return clonedShadowGS
  }

  static writeBoundaryControllerToGlobalStyle(boundaryObj: BoundaryState, boundaryGS?: nGlobalStyle): nGlobalStyle {
    let clonedBoundaryGS = cloneDeep(boundaryGS) as nGlobalStyle
    /**
     * TODO: Make universal object with this properties, and conditional properties, like enable
     */
    const boundary = {
      margin: {
        top: shouldBeNull(boundaryObj["margin-top"], typeof boundaryObj["margin-top"] === "number"),
        bottom: shouldBeNull(boundaryObj["margin-bottom"], typeof boundaryObj["margin-bottom"] === "number"),
        left: shouldBeNull(boundaryObj["margin-left"], typeof boundaryObj["margin-left"] === "number"),
        right: shouldBeNull(boundaryObj["margin-right"], typeof boundaryObj["margin-right"] === "number"),
      },
      padding: {
        top: shouldBeNull(boundaryObj["padding-top"], true),
        bottom: shouldBeNull(boundaryObj["padding-bottom"], true),
        left: shouldBeNull(boundaryObj["padding-left"], true),
        right: shouldBeNull(boundaryObj["padding-right"], true),
      },
    }
    const state = boundaryObj["margin-top"].state
    const breakpoint = boundaryObj["margin-top"].breakpoint

    if (breakpoint === "desktop") {
      //state hover/active
      if (state === "hover" || state === "active") {
        clonedBoundaryGS = {
          ...clonedBoundaryGS,
          behaviour: {
            [state]: {
              ...clonedBoundaryGS.behaviour?.[state],
              ...boundary,
            },
          },
        }
      }
      //state default
      else {
        clonedBoundaryGS = {
          ...clonedBoundaryGS,
          ...boundary,
        }
      }
    }
    //Responsive
    else {
      //state hover/active
      if (state === "hover" || state === "active") {
        clonedBoundaryGS = {
          ...clonedBoundaryGS,
          [breakpoint]: {
            ...clonedBoundaryGS[breakpoint],
            behaviour: {
              [state]: {
                ...clonedBoundaryGS[breakpoint]?.behaviour?.[state],
                ...boundary,
              },
            },
          },
        }
      }
      //state default
      else {
        clonedBoundaryGS = {
          ...clonedBoundaryGS,
          [breakpoint]: {
            ...clonedBoundaryGS[breakpoint],
            ...boundary,
          },
        }
      }
    }
    return clonedBoundaryGS
  }


  static writeTextControllerToGlobalStyle(textObj: TextStyleState, textGS?: nGlobalStyle): nGlobalStyle {
    let clonedTextGS = JSON.parse(JSON.stringify(textGS)) as nGlobalStyle
    /**
     * TODO: Make universal object with this properties, and conditional properties, like enable
     */
    const text = {
      fontType: shouldBeNull(textObj.fontType),
      fontFamily: shouldBeNull(textObj.family),
      fontSize: shouldBeNull(textObj.size, true),
      fontWeight: shouldBeNull(textObj.weight, true),
      letterSpacing: shouldBeNull(textObj.letterSpacing, true),
      lineHeight: shouldBeNull(textObj.lineHeight, true),
    }
    const state = textObj.family.state
    const breakpoint = textObj.family.breakpoint

    if (breakpoint === "desktop") {
      //state hover/active
      if (state === "hover" || state === "active") {
        clonedTextGS = {
          ...clonedTextGS,
          behaviour: {
            [state]: {
              ...clonedTextGS.behaviour?.[state],
              ...text,
            },
          },
        }
      }
      //state default
      else {
        clonedTextGS = {
          ...clonedTextGS,
          ...text,
        }
      }
    }
    //Responsive
    else {
      //state hover/active
      if (state === "hover" || state === "active") {
        clonedTextGS = {
          ...clonedTextGS,
          [breakpoint]: {
            ...clonedTextGS[breakpoint],
            behaviour: {
              [state]: {
                ...clonedTextGS[breakpoint]?.behaviour?.[state],
                ...text,
              },
            },
          },
        }
      }
      //state default
      else {
        clonedTextGS = {
          ...clonedTextGS,
          [breakpoint]: {
            ...clonedTextGS[breakpoint],
            ...text,
          },
        }
      }
    }
    return clonedTextGS
  }
}

export default GlobalStylesResponsiveHandler
import cloneDeep from 'lodash/cloneDeep'
import { Post } from "../experienceManager/types/objectContentTypes"
import { PageTypes } from "../experienceManager/types/pageTypes"
import { SingleObject } from "../experienceManager/types/singleObjectTypes"


export function handleObjectTreeUuids(objectTree: any, instanceUuid: string, pageObjects: any, shouldReturn: boolean) {
  if (objectTree.children.length > 0) {
    for (let childObject of objectTree.children) {
      pageObjects[childObject].objectUuid = instanceUuid + "_" + pageObjects[childObject].objectUuid
      if (pageObjects[childObject].children.length > 0) {
        handleObjectTreeUuids(pageObjects[childObject], instanceUuid, pageObjects, false)
      }
    }
  }
  if (shouldReturn) return objectTree
}

export function getSymbolObject(instance: SingleObject, pageResponse: PageTypes): SingleObject {
  const instanceObject = pageResponse.objects[instance.uuid]
  if (instanceObject.isInstanceOf) {
    const symbolObject = pageResponse.symbols[instanceObject.isInstanceOf]
    return {
      ...symbolObject.objects[symbolObject.rootId],
      selectedInstanceId: instance.uuid,
      isContentBlockChild: instance.isContentBlockChild,
    }
  }
  console.warn("No symbol found!")
  return instance
}

export function handleFilterSymbolObject(instance: any, symbolsList: any, symbolEditMode: boolean) {
  //we failed as a society
  if (!symbolsList) {
    let clonedItem = cloneDeep(instance)
    clonedItem.styles.component.name = "NO_SYMBOL"
    return clonedItem
  }

  if (!symbolEditMode) {
    const SymbolToUse = symbolsList[instance.isInstanceOf]
    return {
      ...SymbolToUse.objects[SymbolToUse.rootId],
      hidden: instance.hidden,
      selectedInstanceId: instance.objectUuid,
      isContentBlockChild: instance.isContentBlockChild,
    }
  }

  const symbolToUse = symbolsList[instance.isInstanceOf]
  return {
    ...symbolToUse.objects[symbolToUse.rootId],
    selectedInstanceId: instance.objectUuid,
    isContentBlockChild: instance.isContentBlockChild,
  }
}

export function getObjectFromSymbolObject(pageResponse: PageTypes, singleObject: SingleObject): SingleObject {
  if (singleObject.fromSymbolTree) {
    const mainSymbol = pageResponse.symbols[singleObject.fromSymbolTree]
    const originalObject = mainSymbol.objects[singleObject.uuid]
    return originalObject ?? singleObject
  }
  return singleObject
}

export function handleWriteInstanceIdToChilds(
  symbolObj: any,
  instance: any,
  symbolEditMode: boolean,
  shouldReturn: boolean
) {
  if (symbolObj.objects) {
    for (let childObj in symbolObj.objects) {
      symbolObj.objects[childObj] = { ...symbolObj.objects[childObj], selectedInstanceId: instance.objectUuid }
    }
  }
  if (shouldReturn) return symbolObj.objects[symbolObj.rootId]
}

export function handleFilterInstanceObj(pageObj: any, instanceId: string) {
  return pageObj.objects[instanceId]
}

export function handleDOMid(field: string, id: string, instanceId: string | undefined, post?: Post) {
  const postId = post?.id ? `-${post.id}` : ""
  return instanceId ? `${field}-${id}-${instanceId}${postId}` : `${field}-${id}${postId}`
}

/**
 * handle unique ids for symbol/instance objects
 * objId + symbolObjId + instanceId
 * @param object
 * @param symbolsList
 */
export function handleUniqueid(object: any, symbolsList: any) {
  // is instance
  if (object.isInstanceOf) {
    let symbol = symbolsList[object.isInstanceOf]
    /**
     * TODO: quickfix. Paste object should inject objects and symbols to the contentHub,
     * because thos objects could come from another page
     */
    if (!symbol) return object.uuid // temporary
    let symbolObj = symbol.objects[symbol.rootId]
    return `${symbolObj.uuid}_${object.isInstanceOf}_${object.uuid}`
  }
  // is symbol obj
  if (object.fromSymbolTree && object.selectedInstanceId)
    return `${object.uuid}_${object.fromSymbolTree}_${object.selectedInstanceId}`
  // is normal obj
  return object.uuid
}

/**
 * handle instance content overrides
 * when instance has overrides symbol(being rendered/used) must use those
 * can handle all symbol objects, or a single symbol object
 * @param instance
 * @param symbolObjects Use an objectsList to be returned
 * @param singleObject Or a singleObject to be returned
 */
export function handleInstanceOverrides(instance: any, objectList?: any, singleObject?: any) {
  // instance has overrides
  if (instance?.content?.overrides) {
    // loop throught each object overrides
    for (let objectKey in instance.content.overrides) {
      // handle object inlineContent overrides
      if (instance?.content.overrides[objectKey]?.inlineContent) {
        if (singleObject && singleObject.uuid === objectKey)
          singleObject.content.inlineContent = {
            ...singleObject.content.inlineContent,
            ...instance.content.overrides[objectKey].inlineContent,
          }
        if (objectList && objectList[objectKey])
          objectList[objectKey].content.inlineContent = {
            ...objectList[objectKey].content.inlineContent,
            ...instance.content.overrides[objectKey].inlineContent,
          }
      }
      // handle object formula overrides
      if (instance?.content.overrides[objectKey]?.formula) {
        if (singleObject && singleObject.uuid === objectKey)
          singleObject.content.formula = {
            ...singleObject.content.formula,
            ...instance.content.overrides[objectKey].formula,
          }
        if (objectList && objectList[objectKey])
          objectList[objectKey].content.formula = {
            ...objectList[objectKey].content.formula,
            ...instance.content.overrides[objectKey].formula,
          }
      }
      // handle other overrides
      if (instance?.content.overrides[objectKey]?.hasOwnProperty("isContentBlock")) {
        if (singleObject && singleObject.uuid === objectKey)
          singleObject.isContentBlock = instance.content.overrides[objectKey].isContentBlock
        if (objectList && objectList[objectKey])
          objectList[objectKey].isContentBlock = instance.content.overrides[objectKey].isContentBlock
      }
    }
  }
  return singleObject || objectList
}

/**
 * When in symbol edit mode and content type is symbol we must manipulate the instance object,
 * so it doens have written overrides,
 * this way the render of the symbol will not use the instance overrides contents
 * @param instance
 * @param contentType
 */
export function handleContentType(instance: any, contentType: string) {
  if (contentType === "symbol")
    if (instance.content?.overrides) {
      // loop throught each object overrides
      for (let objectKey in instance.content.overrides) {
        // handle object overrides
        if (instance?.content.overrides[objectKey]) {
          delete instance.content.overrides[objectKey]
        }
      }
    }
  return instance
}

import { MediaItemId, RawUrl, mkMediaItemId, mkRawUrl } from "../../../api/shared-api/types/cmsContentTypes"
import { MediaObject } from "../experienceManager/types/contentTypes"
import { PageTypes } from "../experienceManager/types/pageTypes"
import * as StringType from "../../../lib/brand/StringType"

let ProfileNameSchema = StringType.schema<"ProfileName">()
type ProfileName = StringType.infer<typeof ProfileNameSchema>
export const mkProfileName = (s: string) => StringType.wrap(ProfileNameSchema, s)

/**
 *
 * @param profile string
 * @param media any
 * This function manages to preview for the mediaLibrary
 * @param original boolean optional
 */
export function handleLink(profile: string, media: MediaObject, original?: boolean): string {
  const useOriginal:Boolean = (original!==undefined)? original : false;

  if (media?.itemType === "image")
    return `https://static-media.fluxio.cloud/${media.profileName ? media.profileName : profile}/${media.id}${
      media.extension ? `${hasVersion(media.version)}.${useOriginal? media.fallbackExtension : media.extension}` : `${hasVersion(media.version)}`
    }`
  if (media?.itemType === "video")
    return `https://static-media.fluxio.cloud/${media.profileName ? media.profileName : profile}/${media.id}${
      media.extension ? `${hasVersion(media.version)}.${media.extension}` : `${hasVersion(media.version)}`
    }`
  if (media?.itemType === "other")
    return `https://static-media.fluxio.cloud/${media.profileName ? media.profileName : profile}/${media.id}${
      media.extension ? `${hasVersion(media.version)}.${media.extension}` : `${hasVersion(media.version)}`
    }`
  console.warn("unhandled itemType")
  return "https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png"
}

/**
 *
 * @param pageResponse
 * @param mediaId
 * This function renders the final media in the bobX render
 */
export function handleSrcLink(
  pageResponse: PageTypes,
  mediaIdOrUrl: string | undefined // TODO: type
): {
  mediaSrc: RawUrl
  mediaAlt: string
  fallbackExtension?: string
  mediaInfo?: MediaObject
} {
  const placeholder = mkRawUrl("https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png")
  const placeholderAlt = "Fluxio"
  //Direct link
  if (!mediaIdOrUrl?.startsWith("media")) return { mediaSrc: mkRawUrl(mediaIdOrUrl ?? ""), mediaAlt: "" }

  //If the media object is not available we return a placeholder
  const cutMediaId = mkMediaItemId(mediaIdOrUrl.replace(/media-/g, ""))
  const mediaObject = pageResponse.media[cutMediaId]
  if (!mediaObject) {
    console.warn(`Missing media object for ${cutMediaId} asset`)
    return { mediaSrc: placeholder, mediaAlt: placeholderAlt }
  }

  //With no profile available we stop and return a placeholder
  const profile = mkProfileName(mediaObject.profileName || pageResponse.profileName) // should be typed way before this
  if (!profile) {
    console.warn("Missing Profile Name for asset handler")
    return { mediaSrc: placeholder, mediaAlt: placeholderAlt }
  }

  //if the logic got here than we can return the proper url for the asset
  return {
    mediaSrc: makeMediaUrl(profile, cutMediaId, mediaObject.version, mediaObject.extension),
    mediaAlt: mediaObject.alt,
    fallbackExtension: mediaObject.fallbackExtension,
    mediaInfo: {
      ...mediaObject,
      id: cutMediaId,
      profileName: profile,
    },
  }
}

export function makeMediaUrl(
  profileName: ProfileName,
  mediaItemId: MediaItemId,
  version: number | undefined,
  ext: string
): RawUrl {
  return mkRawUrl(`https://static-media.fluxio.cloud/${profileName}/${mediaItemId}${showVersion(version)}.${ext}`)
}

/**
 *
 * @param pageResponse
 * @param mediaId
 * This function renders the final media in the bobX render
 */
export function handleContentSrcLink(mediaList: any, profile: string, mediaId: string) {
  const placeholder = "https://cdn.fluxio.cloud/fluxio/fluxio_placeholder.png"
  const placeholderAlt = "Fluxio"
  //No media
  if (!mediaId) return { mediaSrc: placeholder, mediaAlt: placeholderAlt }
  //Direct link
  if (!mediaId?.startsWith("media")) return { mediaSrc: mediaId, mediaAlt: placeholderAlt }

  //no media present
  if (!mediaList) {
    console.warn(`Missing medias for ${mediaId} asset`)
    return { mediaSrc: placeholder, mediaAlt: placeholderAlt }
  }
  //If the media object is not available we return a placeholder
  const cutMediaId = mediaId.replace(/media-/g, "")
  const mediaObject = mediaList[cutMediaId]
  if (!mediaObject) {
    console.warn(`Missing media object for ${cutMediaId} asset`)
    return { mediaSrc: placeholder, mediaAlt: placeholderAlt }
  }

  //With no profile available we stop and return a placeholder
  if (!profile) {
    console.warn("Missing Profile Name for asset handler")
    return { mediaSrc: placeholder, mediaAlt: placeholderAlt }
  }

  //if the logic got here than we can return the proper url for the asset
  return {
    mediaSrc: `https://static-media.fluxio.cloud/${profile}/${cutMediaId}${hasVersion(mediaObject.version)}.${
      mediaObject.extension
    }`,
    mediaAlt: mediaObject.alt,
  }
}

export function cutMediaString(mediaStr: string) {
  return mediaStr.replace(/media-/g, "")
}

/**
 *
 * @param pageResponse
 * @param mediaId
 * @param field
 * IF the field param is not sent, this function returns the entire object
 */
export function handleMediaFields(pageResponse: PageTypes, mediaId: string | undefined, field?: keyof MediaObject) {
  const defaultMsg = (fieldMsg: string) => `Missing field ${fieldMsg}`

  if (!mediaId) {
    //console.warn(defaultMsg("mediaId"))
    return undefined
  }
  //Direct link
  if (!mediaId?.startsWith("media")) return mediaId

  const profile = pageResponse.profileName
  if (!profile) return defaultMsg("profileName")

  //If the media object is not available we return a placeholder
  const cutMediaId = mediaId.replace(/media-/g, "")
  const mediaObject = pageResponse.media[cutMediaId]
  if (!mediaObject) return "Missing or Deleted Media"

  //if no field is defined, we send the entire object
  if (!field) return { ...mediaObject, id: cutMediaId, profile: profile }

  //No field available
  if (!mediaObject[field]) return defaultMsg(field)

  //Return field
  return mediaObject[field]
}
/**
 *
 * @param mediaList medialist that doesnt come from the pageResponse
 * @param profile
 * @param mediaId
 * @param field
 * IF the field param is not sent, this function returns the entire object
 */
export function handleContentMediaFields(mediaList: any, mediaId: string | undefined, field?: string) {
  const defaultMsg = (fieldMsg: string) => `Missing field ${fieldMsg}`

  if (!mediaList) return defaultMsg("Medias")
  //Direct link
  if (!mediaId?.startsWith("media")) return mediaId

  //If the media object is not available we return a placeholder
  const cutMediaId = mediaId.replace(/media-/g, "")
  const mediaObject = mediaList[cutMediaId]
  if (!mediaObject) return defaultMsg("Media Object")

  //if no field is defined, we send the entire object
  if (!field)
    return {
      ...mediaObject,
      id: cutMediaId,
      profile: mediaObject.profileName,
    }

  //No field available
  if (!mediaObject[field]) return defaultMsg(field)

  //Return field
  return mediaObject[field]
}

export function writeMediaToPage(pageObject: any, mediaObject: any) {
  const mediaObj = {
    [mediaObject.id]: {
      ...mediaObject,
    },
  }
  pageObject.media = {
    ...pageObject.media,
    ...mediaObj,
  }
  return pageObject
}

export function getMediaId(mediaSrc: string) {
  if (mediaSrc.startsWith("media")) return mediaSrc.replace(/media-/g, "")
  return mediaSrc
}

/**
 *
 * @param mediaObj
 * This method should only be used when the mediaObj comes from the handleMediaFields()
 */
export function handleMediaSrc(mediaObj: any) {
  //When handleMediaFields() couldnt resolve the object
  if (typeof mediaObj === "string") return mediaObj
  return `https://static-media.fluxio.cloud/${mediaObj.profile || mediaObj.profileName}/${mediaObj.id}${hasVersion(
    mediaObj.version
  )}.${mediaObj.extension}`
}

export function hasVersion(version?: number) {
  if (version) return `_${version}`
  return ""
}

export function showVersion(version?: number) {
  return hasVersion(version)
}

export function dataURLtoFile(dataurl: string, filename: string) {
  let arr = dataurl.split(","),
    //@ts-ignore
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

import { History } from "history"
import { useEffect, useState } from "react"
import cookie from "react-cookies"
import profileApi from "../../api/profileApi"
import { Workspace } from "../../areas/skynet/profileTypes"
import { AppNavigation } from "../../areas/skynet/sidebar/DoJobBoard"

interface Token {
  access_token: string
}

// the capability of performing redirects
export interface AppRedirect {
  redirect: (path: string) => void
}

export function mkAppRedirect(r: History): AppRedirect {
  return {
    redirect: (p) => r.push(p),
  }
}

export function getTokenAndWorkspace(profileName: string): Promise<{ ws: Workspace; token: Token }> {
  if (cookie.load("_fluxio_user_token")) {
    const token = JSON.parse(window.atob(cookie.load("_fluxio_user_token"))) as Token
    return profileApi.Profile(token, profileName).then((e) => ({ token, ws: e as Workspace }))
  } else return Promise.reject("couldn't load token")
}

export function useWorkspace(profile: string, redirect: AppRedirect): Workspace | undefined {
  const [state, setState] = useState<{ ws: Workspace; token: Token } | undefined>(undefined)

  useEffect(() => {
    getTokenAndWorkspace(profile)
      .then(setState)
      .catch(() => redirect.redirect(AppNavigation.login))
  }, [profile, redirect])

  return state?.ws
}

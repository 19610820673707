class avatarTextMaker {

  static generateInitials(string) {

    if (!string) {
      return ''
    }

    let arr = string.split(' ')

    let strToReturn = ''

    if (arr.length >= 3) {

      for (var i = 0; i <= 2; i++) {
        let arrIn = arr[i].split('')
        strToReturn += arrIn[0]
      }

    }

    if (arr.length === 1) {

      let arrOne = string.split('')

      if (arrOne.length === 1) {

        strToReturn = (arrOne[0])

      } else if (arrOne.length === 2) {

        strToReturn = (arrOne[0] + arrOne[1])

      }

      strToReturn = (arrOne[0] + arrOne[1] + arrOne[2])

    }

    if (arr.length === 2) {

      let arrTwo = string.split('')

      if (arrTwo.length === 1) {

        strToReturn = (arrTwo[0])

      } else if (arrTwo.length === 2) {

        strToReturn = (arrTwo[0] + arrTwo[1])

      }

      strToReturn = (arrTwo[0] + arrTwo[1] + arrTwo[2])

    }

    return strToReturn.toUpperCase()

  }

  static generate4Initials(stringToCut, number) {

    if (!stringToCut)
      return ''

    return stringToCut.substring(0, number ? number : 1).toUpperCase()

  }

}



export default avatarTextMaker

import React, { Fragment } from "react"
import FormComponentHandler from "../../../../../components/forms/formComponentHandler"

class SelectController extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      selectedValue: props.selectedValue ? props.selectedValue : props.value,
      searchTerm: "",
    }
    this.scrollEnd = this.scrollEnd.bind(this)
  }

  UNSAFE_componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value && nextProps.value !== this.state.selectedValue) {
      this.setState({
        ...this.state,
        selectedValue: nextProps.value,
      })
    } else if (nextProps.value && !this.state.selectedValue) {
      this.setState({
        ...this.state,
        selectedValue: nextProps.value,
      })
    }
  }

  scrollEnd(e) {
    if (this.props.loadMore)
      if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight)
        //visible height + pixel scrolled = total height
        this.props.loadMore()
  }

  handleClick = (e) => {
    if (this.node && this.node.contains(e.target)) return
    this.setState({
      ...this.state,
      isOpen: false,
    })
  }

  handleItemClick(value) {
    this.setState(
      {
        selectedValue: value,
        isOpen: false,
      },
      () => this.props.onChange(this.state.selectedValue)
    )
  }

  handleSearch(value) {
    let searchResult = undefined
    if (value && value !== "") {
      value = value.toLowerCase()
      searchResult = this.props.options.filter((option) => {
        // option can have search terms associated with it
        if (option.searchTerms && option.searchTerms.length > 0) return option.searchTerms.includes(value)
        return option.value.toLowerCase().includes(value)
      })
    }
    this.setState({
      ...this.state,
      searchOptions: searchResult,
      searchNoOptions: searchResult && searchResult.length === 0 ? true : false,
    })
  }

  handleDropdownClick() {
    if (!this.props.disabled && this.clearableHasNoValue()) {
      this.setState({
        isOpen: !this.state.isOpen,
      })
    }
  }

  clearableHasNoValue() {
    return !this.props.isClearable || (this.props.isClearable && !this.state.selectedValue.value)
  }

  renderSearch() {
    if (!this.props.search) return

    return (
      <Fragment>
        <FormComponentHandler
          type='input_handler'
          inputType='text'
          value={this.state.searchTerm}
          inputName='select-search'
          delay={1000}
          handleValueChange={(value) => this.handleSearch(value)}
          placeholder={this.props.searchPlaceholder}
        />
        {this.state.searchNoOptions ? <span className='text-muted small'>No results</span> : ""}
      </Fragment>
    )
  }

  renderOptions() {
    // use searched options result when needed.
    let options = !this.state.searchOptions ? this.props.options : this.state.searchOptions
    if (options && options.length > 0)
      return options.map((option, idx) => {
        return (
          <p key={idx} className='item' onClick={() => this.handleItemClick(option)}>
            {option.icon && option.icon}
            {option.label}
            {this.props.customActionIcon ? (
              <i
                className={`custom-action ${this.props.customActionIcon}`}
                onClick={(e) =>
                  this.props.onChangeCustomAction && [e.stopPropagation(), this.props.onChangeCustomAction(option)]
                }
              />
            ) : (
              ""
            )}
          </p>
        )
      })
    else
      return (
        <p className='item ' disabled>
          Empty
        </p>
      )
  }

  render() {
    return (
      <div
        className={`em-dropdown${this.props.customClass ? ` ${this.props.customClass}` : ""}`}
        ref={(node) => (this.node = node)}
        title={this.props.title ?? "Select Option"}
      >
        <button
          className='toggle'
          type='button'
          onClick={() => this.handleDropdownClick()}
          disabled={this.props.disabled}
        >
          {this.state.selectedValue && this.state.selectedValue.label && this.state.selectedValue.label !== ""
            ? [this.state.selectedValue.icon, this.state.selectedValue.label]
            : this.props.placeholder}
          <div>
            {this.props.customActionIcon && this.state.selectedValue ? (
              <i
                className={`custom-action mr-2 ${this.props.customActionIcon}`}
                onClick={(e) =>
                  this.props.onChangeCustomAction && [
                    e.stopPropagation(),
                    this.props.onChangeCustomAction(this.state.selectedValue),
                  ]
                }
              />
            ) : (
              ""
            )}
            {this.props.isClearable && this.state.selectedValue.value && (
              <i
                className={`fas fa-times`}
                onClick={(e) => this.handleItemClick({ label: undefined, value: undefined })}
              />
            )}
            {this.clearableHasNoValue() && <i className='fas fa-chevron-down'></i>}
          </div>
        </button>
        <div className={`menu ${this.state.isOpen ? "isOpen" : "notOpen"}`} onScroll={this.scrollEnd}>
          {this.renderSearch()}
          {this.renderOptions()}
        </div>
      </div>
    )
  }
}
export default SelectController

import { ResponsiveData, ResponsiveBreakpoint, BehaviourState_ } from "../experienceManager/finder/inputs/bobControllerTypes"
import { objValueExists } from "./utils"


export function handleStateValue(
  stateObj: any,
  breakpoint: ResponsiveData<any>["breakpoint"],
  state: ResponsiveData<any>["state"] = "default",
  field: string
): ResponsiveData<any> {
  let responsiveObj: ResponsiveData<any>

  // handle responsive or desktop obj
  let obj = breakpoint !== "desktop" ? stateObj[breakpoint] : stateObj
  /**
   * handle the default value.
   * Should be the default breakpoint(desktop) state value,
   * or if it doenst exist should be the breakpoint value,
   * else the default(desktop) value
   */
  let defaultValue = stateObj[state]?.[field] || obj[field] || stateObj[field]
  /**
   * We have the value for this state
   */
  if (obj[state] && obj[state].hasOwnProperty(field) && obj[state][field] !== null && obj[state][field] !== undefined) {
    responsiveObj = {
      value: obj[state][field],
      isDiferent: true,
      isUnset: false,
      breakpoint,
      state: state,
      defaultValue: defaultValue,
    }
  } else {
    /**
     * We dont have the value so we return the default value
     */
    responsiveObj = {
      value: defaultValue,
      isDiferent: false,
      isUnset: true,
      breakpoint,
      state: state,
      defaultValue: defaultValue,
    }
  }

  return responsiveObj
}

export function handleResponsiveValue(
  stateObj: any,
  breakpoint: ResponsiveData<any>["breakpoint"],
  field: string
): ResponsiveData<any> {
  let responsiveObj: ResponsiveData<any>
  /**
   * We have the value for this breakpoint
   */
  if (
    stateObj[breakpoint] &&
    stateObj[breakpoint].hasOwnProperty(field) &&
    stateObj[breakpoint][field] !== null &&
    stateObj[breakpoint][field] !== undefined
  ) {
    responsiveObj = {
      value: stateObj[breakpoint][field],
      isDiferent: true,
      isUnset: false,
      breakpoint,
      state: "default",
      defaultValue: stateObj[field],
    }
  } else {
    /**
     * We dont have the value so we return the default value
     */
    responsiveObj = {
      value: stateObj[field],
      isDiferent: false,
      isUnset: true,
      breakpoint,
      state: "default",
      defaultValue: stateObj[field],
    }
  }

  return responsiveObj
}

export function getValue(
  selectedBreakpoint: ResponsiveBreakpoint,
  stateObj: any,
  field: string,
  selectedState?: BehaviourState_
): ResponsiveData<any> {
  /**
   * handle state values
   */
  if (selectedState && (selectedState === "hover" || selectedState === "active")) {
    return handleStateValue(stateObj, selectedBreakpoint.breakpoint, selectedState, field)
  }

  /**
   * handle responsive values
   */
  if (selectedBreakpoint.type === "responsive") {
    if (selectedBreakpoint.breakpoint === "mobile" || selectedBreakpoint.breakpoint === "tablet") {
      return handleResponsiveValue(stateObj, selectedBreakpoint.breakpoint, field)
    }
  }

  /**
   * Object for Default Styles Option
   */
  let responsiveObj: ResponsiveData<any>
  // value/prop exists/is written
  if (objValueExists(stateObj, field))
    responsiveObj = {
      value: stateObj[field],
      defaultValue: stateObj[field],
      isDiferent: false,
      isUnset: false,
      breakpoint: "desktop",
      state: "default",
    }
  // value/prop doenst exist, is unseted
  else
    responsiveObj = {
      value: stateObj[field],
      defaultValue: stateObj[field],
      isDiferent: false,
      isUnset: true,
      breakpoint: "desktop",
      state: "default",
    }

  return responsiveObj
}

/**
 * writes all values for a breakpoint(tablet/mobile) when one value is being written on that breakpoint and the others still don't exist
 * for this we write the isDiferent=true on every prop, so that bobs use the prop value in that breakpoint
 *
 * this is needed if, for example, we change shadow color in tablet, the other props must be written too with the desktop values,
 * else the css being rendered is not valid due to the other props being null
 *
 * TODO: check performance
 *
 * @param properties properties to handle
 * @param propToIgnore ignore prop value being changed, it's already being writen in state
 */
export function handleBreakpointValues(properties: any, propToIgnore: string) {
  if (properties[propToIgnore].breakpoint !== "desktop") {
    let values = {}
    for (let prop in properties) {
      // @ts-ignore
      if (prop !== propToIgnore && properties[prop].hasOwnProperty("isDiferent"))
        // @ts-ignore
        values = { ...values, [prop]: { ...properties[prop], isDiferent: true, isUnset: false } }
    }
    return values
  }
  return {}
}
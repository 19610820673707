import { JobsApi } from "./jobsApi"
import React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { JobsNavigation } from "./jobsNavigation"
import { mkProvideInjectValues } from "../../../lib/server-driven-forms/provideInjectValues"
import { JobId, JobRunId, ViewId, mkJobId, mkViewId, parseJobRunId } from "./jobsSchema"
import JobsCatalogue from "./jobsCatalogue"
import JobDetail from "./jobDetail"
import DocumentRunViewer from "./documentRunViewer"
import DocumentViewer from "./documentViewer"

interface OwnProps {
  jobsClient: JobsApi
}

type JobsManagerProps = OwnProps & RouteComponentProps<RouteParams>

interface JobsManagerState {
  nav: JobsNavigation
  selectedRunId?: JobRunId
}

export interface RouteParams {
  // TODO: move this somewhere global
  page: string
  subpage: string
  search: string
}

export type JobsPage =
  | "runs"
  | "catalogue"
  | "apify-tiktok"
  | "apify-instagram"
  | "social-competitor"
  | "process-runs"
  | "processes"
  | `runs/${JobRunId}`
  | `catalogue/${JobId}`
  | `document/${ViewId}`
  | `documentRun/${JobRunId}`

class JobsManagerView extends React.Component<JobsManagerProps, JobsManagerState> {
  constructor(props: JobsManagerProps) {
    super(props)
    this.state = {
      nav: new JobsNavigation(props.jobsClient.profileName, "catalogue"),
    }

    this.handleOptionClick = this.handleOptionClick.bind(this)

    //listjobs
  }

  componentDidMount(): void {
    // redirect to default page if not set in the router
    if (this.state.nav.shouldRedirect(this.props)) {
      this.state.nav.redirectDefault(this.props)
    }
  }

  componentDidUpdate(): void {
    // redirect to default page if not set in the router
    if (this.state.nav.shouldRedirect(this.props)) {
      this.state.nav.redirectDefault(this.props)
    }
  }

  handleRunClick = (runId: JobRunId) => {
    // Set the selected run ID in the state and update the URL
    this.setState({ selectedRunId: runId })
    const detailPage: JobsPage = `runs/${runId}`
    // Use the navigation object to redirect to the detail view

    this.state.nav.redirect(this.props, detailPage)
    // this.props.history.push(`/jobs/runs/${runId}`)
  }

  openJobDetail = (jobId: JobId) => {
    // Set the selected job ID and  update the URL
    const detailPage: JobsPage = `catalogue/${jobId}`
    this.state.nav.redirect(this.props, detailPage)
  }

  handleGoBackToJCatalogue = (searchQuery?: string) => {
    const detailPage: JobsPage = `catalogue`
    if (searchQuery) this.state.nav.redirectSearch(this.props, detailPage, searchQuery)
    else this.state.nav.redirect(this.props, detailPage)
  }

  handleJumpToRunDocument = (jobRunId: JobRunId) => {
    const documentPage: JobsPage = `documentRun/${jobRunId}`

    this.state.nav.redirect(this.props, documentPage)
  }

  handleOptionClick(option: JobsPage) {
    this.state.nav.redirect(this.props, option)
  }

  render(): JSX.Element {
    /*const mkButton = (title: string, page: JobsPage) => (
      <button
        style={{
          backgroundColor: "transparent",
          padding: "3px 6px",
          borderRadius: "0px",
          marginBottom: "10px",
          border: page === this.props.match.params.page ? "solid" : "none",
        }}
        className='tree-item'
        onClick={() => this.handleOptionClick(page)}
      >
        {title}
      </button>
    )*/

    return this.renderResults()
    /* <div className='work-row row'>
        <div className='col-2 fh-screen'>
          <div className='panel'>
            <h2 className='panel-header'>
              <span></span>
            </h2>
            <div className=''>
              {mkButton("My Runs", "runs")}
              <br />
              {mkButton("Jobs Catalogue (Coming Soon)", "catalogue")}
              <br />
              {mkButton("Social Competior Analysis", "social-competitor")}
              <br />
              {mkButton("Apify - TikTok", "apify-tiktok")}
              <br />
              {mkButton("Apify - Instagram", "apify-instagram")}
              <br />
              {mkButton("Process Runs (admin)", "process-runs")}
              <br />
              {mkButton("Process List (admin)", "processes")}
            </div>
          </div>
        </div>
        <div className='col-10'>
          <div className='jobs-library main-panel fh-screen'>{this.renderResults()}</div>
        </div>
      </div>*/
  }

  renderResults(): JSX.Element {
    let page = this.props.match.params.page
    let subpage = this.props.match.params.subpage
    const cl = this.props.jobsClient
    const nav = this.state.nav
    const inject = mkProvideInjectValues(cl.profileName)

    const searchQuery = new URLSearchParams(this.props.location.search).get("search") || ""

    switch (page) {
      case "document":
        if (subpage) {
          const viewId: ViewId = mkViewId(subpage)
          return <DocumentViewer jobsClient={cl} viewId={viewId} profileName={cl.profileNameT}></DocumentViewer>
        }
        return <></>
      case "documentRun":
        if (subpage) {
          const jobRunId: JobRunId = parseJobRunId(subpage).unwrap()
          return (
            <DocumentRunViewer
              jobsClient={cl}
              jobRunId={jobRunId}
              profileName={cl.profileNameT}
              handleJumpToDocument={this.handleJumpToRunDocument}
            ></DocumentRunViewer>
          )
        }
        return (
          <DocumentRunViewer
            jobsClient={cl}
            profileName={cl.profileNameT}
            handleJumpToDocument={this.handleJumpToRunDocument}
          ></DocumentRunViewer>
        )
      case "catalogue": // this is the real jobs now!!!
        if (subpage) {
          const jobId: JobId = mkJobId(subpage)
          return (
            <JobDetail
              jobsClient={cl}
              history={this.props.history}
              location={this.props.location}
              match={this.props.match}
              jobId={jobId}
              nav={nav}
              profileName={cl.profileNameT}
              handleGoBackToJCatalogue={this.handleGoBackToJCatalogue}
              handleJumpToDocument={this.handleJumpToRunDocument}
              injectValues={inject}
              searchQuery={searchQuery}
            ></JobDetail>
          )
        }
        return (
          <JobsCatalogue
            client={cl}
            nav={nav}
            openJobDetail={this.openJobDetail}
            handleGoBackToJCatalogue={this.handleGoBackToJCatalogue}
            searchQuery={searchQuery}
            {...this.props}
          ></JobsCatalogue>
        )
      default:
        return (
          <div className='page-container'>
            <div className='alert alert-light w-75 ml-auto mr-auto mt-5' role='alert'>
              <div className='alert-group small p-4'>
                <i className='alert-graphic fas fa-info-circle'></i>
                <div className='alert-message'>
                  <p className='alert-description'>Nothing to return</p>
                </div>
              </div>
            </div>
          </div>
        )
    }
  }
}

export default withRouter(JobsManagerView)

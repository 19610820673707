import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function marketplaceReducer(state = initialState.marketplaceReducer, action) {
  switch (action.type) {
    case types.REQUEST_CREATE_PAGE_TEMPLATE:
      return {
        ...state,
        createdPageTemplate: undefined,
        loadingCreatedPageTemplate: true,
        errorCreatedPageTemplate: undefined,
      }
    case types.SUCCESS_CREATE_PAGE_TEMPLATE:
      return {
        ...state,
        createdPageTemplate: action.response,
        loadingCreatedPageTemplate: false,
        errorCreatedPageTemplate: undefined,
      }
    case types.ERROR_CREATE_PAGE_TEMPLATE:
      return {
        ...state,
        createdPageTemplate: undefined,
        loadingCreatedPageTemplate: false,
        errorCreatedPageTemplate: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_UPDATE_PAGE_TEMPLATE:
      return {
        ...state,
        updatedPageTemplate: undefined,
        loadingUpdatedPageTemplate: true,
        errorUpdatedPageTemplate: undefined,
      }
    case types.SUCCESS_UPDATE_PAGE_TEMPLATE:
      return {
        ...state,
        updatedPageTemplate: action.response,
        loadingUpdatedPageTemplate: false,
        errorUpdatedPageTemplate: undefined,
      }
    case types.ERROR_UPDATE_PAGE_TEMPLATE:
      return {
        ...state,
        updatedPageTemplate: undefined,
        loadingUpdatedPageTemplate: false,
        errorUpdatedPageTemplate: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_DELETE_PAGE_TEMPLATE:
      return {
        ...state,
        deletedPageTemplate: undefined,
        loadingDeletedPageTemplate: true,
        errorDeletedPageTemplate: undefined,
        deletedPageTemplateId: action.pageTemplateId,
      }
    case types.SUCCESS_DELETE_PAGE_TEMPLATE:
      return {
        ...state,
        deletedPageTemplate: action.response,
        loadingDeletedPageTemplate: false,
        errorDeletedPageTemplate: undefined,
        deletedPageTemplateId: action.pageTemplateId,
      }
    case types.ERROR_DELETE_PAGE_TEMPLATE:
      return {
        ...state,
        deletedPageTemplate: undefined,
        loadingDeletedPageTemplate: false,
        errorDeletedPageTemplate: action.error,
        deletedPageTemplateId: action.pageTemplateId,
      }
    case types.CLEAR_PING_PAGE_TEMPLATE:
      //TODO Wrong pingedPageTemplate action/reducer
      return {
        ...state,
        // created
        createdPageTemplate: undefined,
        loadingCreatedPageTemplate: false,
        errorCreatedPageTemplate: undefined,
        // removed
        removedPageTemplate: undefined,
        loadingRemovedPageTemplate: false,
        errorRemovedPageTemplate: undefined,
        removedPageTemplateId: undefined,
        // deleted
        deletedPageTemplate: undefined,
        loadingDeletePageTemplate: false,
        errorDeletePageTemplate: undefined,
        deletedPageTemplateId: undefined,
        // pinged
        pingedPageTemplate: undefined,
        loadingPingedPageTemplate: false,
        errorPingedPageTemplate: undefined,
        pingedPageTemplateId: undefined,
        pingedPageTemplateAttempts: undefined,
        // updated
        updatedPageTemplate: undefined,
        loadingUpdatedPageTemplate: false,
        errorUpdatedPageTemplate: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_PING_PAGE_TEMPLATE:
      return {
        ...state,
        pingedPageTemplate: undefined,
        loadingPingedPageTemplate: true,
        errorPingedPageTemplate: undefined,
        pingedPageTemplateId: action.pageTemplateId,
        pingedPageTemplateAttempts: state.pingedPageTemplateAttempts ? state.pingedPageTemplateAttempts + 1 : 1
      }
    case types.SUCCESS_PING_PAGE_TEMPLATE:
      return {
        ...state,
        pingedPageTemplate: action.response,
        loadingPingedPageTemplate: false,
        errorPingedPageTemplate: undefined,
        pingedPageTemplateId: action.pageTemplateId
      }
    case types.ERROR_PING_PAGE_TEMPLATE:
      return {
        ...state,
        pingedPageTemplate: undefined,
        loadingPingedPageTemplate: false,
        errorPingedPageTemplate: action.error,
        pingedPageTemplateId: action.pageTemplateId
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_FETCH_PAGE_TEMPLATE:
      return {
        ...state,
        fetchedPageTemplate: undefined,
        loadingFetchedPageTemplate: true,
        errorFetchedPageTemplate: undefined,
      }
    case types.RECEIVE_FETCH_PAGE_TEMPLATE:
      return {
        ...state,
        fetchedPageTemplate: action.response,
        loadingFetchedPageTemplate: false,
        errorFetchedPageTemplate: undefined,
      }
    case types.ERROR_FETCH_PAGE_TEMPLATE:
      return {
        ...state,
        fetchedPageTemplate: undefined,
        loadingFetchedPageTemplate: false,
        errorFetchedPageTemplate: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_FETCH_OWNED_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedOwnedPageTemplateList: undefined,
        loadingFetchedOwnedPageTemplateList: true,
        errorFetchedOwnedPageTemplateList: undefined,
      }
    case types.RECEIVE_FETCH_OWNED_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedOwnedPageTemplateList: action.response,
        loadingFetchedOwnedPageTemplateList: false,
        errorFetchedOwnedPageTemplateList: undefined,
      }
    case types.ERROR_FETCH_OWNED_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedOwnedPageTemplateList: undefined,
        loadingFetchedOwnedPageTemplateList: false,
        errorFetchedOwnedPageTemplateList: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_FETCH_INPUT_OWN_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedInputOwnPageTemplateList: undefined,
        loadingFetchedInputOwnPageTemplateList: true,
        errorFetchedInputOwnPageTemplateList: undefined,
      }
    case types.RECEIVE_FETCH_INPUT_OWN_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedInputOwnPageTemplateList: action.response,
        loadingFetchedInputOwnPageTemplateList: false,
        errorFetchedInputOwnPageTemplateList: undefined,
      }
    case types.ERROR_FETCH_INPUT_OWN_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedInputOwnPageTemplateList: undefined,
        loadingFetchedInputOwnPageTemplateList: false,
        errorFetchedInputOwnPageTemplateList: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_ADD_PAGE_TEMPLATE:
      return {
        ...state,
        addedPageTemplate: undefined,
        loadingAddedPageTemplate: true,
        errorAddedPageTemplate: undefined,
        addedPageTemplateId: action.pageTemplateId
      }
    case types.SUCCESS_ADD_PAGE_TEMPLATE:
      return {
        ...state,
        addedPageTemplate: action.response,
        loadingAddedPageTemplate: false,
        errorAddedPageTemplate: undefined,
        addedPageTemplateId: action.pageTemplateId
      }
    case types.ERROR_ADD_PAGE_TEMPLATE:
      return {
        ...state,
        addedPageTemplate: undefined,
        loadingAddedPageTemplate: false,
        errorAddedPageTemplate: action.error,
        addedPageTemplateId: action.pageTemplateId
      }
    case types.CLEAR_ADD_PAGE_TEMPLATE:
      return {
        ...state,
        addedPageTemplate: undefined,
        loadingAddedPageTemplate: false,
        errorAddedPageTemplate: undefined,
        addedPageTemplateId: undefined
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_PURCHASE_PAGE_TEMPLATE:
      return {
        ...state,
        purchasedPageTemplate: undefined,
        loadingPurchasedPageTemplate: true,
        errorPurchasedPageTemplate: undefined,
      }
    case types.SUCCESS_PURCHASE_PAGE_TEMPLATE:
      return {
        ...state,
        purchasedPageTemplate: action.response,
        loadingPurchasedPageTemplate: false,
        errorPurchasedPageTemplate: undefined,
      }
    case types.ERROR_PURCHASE_PAGE_TEMPLATE:
      return {
        ...state,
        purchasedPageTemplate: undefined,
        loadingPurchasedPageTemplate: false,
        errorPurchasedPageTemplate: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_REMOVE_PAGE_TEMPLATE:
      return {
        ...state,
        removedPageTemplate: undefined,
        loadingRemovedPageTemplate: true,
        errorRemovedPageTemplate: undefined,
        removedPageTemplateId: action.pageTemplateId
      }
    case types.SUCCESS_REMOVE_PAGE_TEMPLATE:
      return {
        ...state,
        removedPageTemplate: action.response,
        loadingRemovedPageTemplate: false,
        errorRemovedPageTemplate: undefined,
        removedPageTemplateId: action.pageTemplateId
      }
    case types.ERROR_REMOVE_PAGE_TEMPLATE:
      return {
        ...state,
        removedPageTemplate: undefined,
        loadingRemovedPageTemplate: false,
        errorRemovedPageTemplate: action.error,
        removedPageTemplateId: action.pageTemplateId
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_CREATE_PAGE_FROM_TEMPLATE:
      return {
        ...state,
        createdPageFromTemplate: undefined,
        loadingCreatedPageFromTemplate: true,
        errorCreatedPageFromTemplate: undefined,
      }
    case types.SUCCESS_CREATE_PAGE_FROM_TEMPLATE:
      return {
        ...state,
        createdPageFromTemplate: action.response,
        loadingCreatedPageFromTemplate: false,
        errorCreatedPageFromTemplate: undefined,
      }
    case types.ERROR_CREATE_PAGE_FROM_TEMPLATE:
      return {
        ...state,
        createdPageFromTemplate: undefined,
        loadingCreatedPageFromTemplate: false,
        errorCreatedPageFromTemplate: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_FETCH_ACQUIRED_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedAcquiredPageTemplateList: undefined,
        loadingFetchedAcquiredPageTemplateList: true,
        errorFetchedAcquiredPageTemplateList: undefined,
      }
    case types.RECEIVE_FETCH_ACQUIRED_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedAcquiredPageTemplateList: action.response,
        loadingFetchedAcquiredPageTemplateList: false,
        errorFetchedAcquiredPageTemplateList: undefined,
      }
    case types.ERROR_FETCH_ACQUIRED_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedAcquiredPageTemplateList: undefined,
        loadingFetchedAcquiredPageTemplateList: false,
        errorFetchedAcquiredPageTemplateList: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_FETCH_INPUT_ACQUIRED_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedInputAcquiredPageTemplateList: undefined,
        loadingInputFetchedAcquiredPageTemplateList: true,
        errorInputFetchedAcquiredPageTemplateList: undefined,
      }
    case types.RECEIVE_FETCH_INPUT_ACQUIRED_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedInputAcquiredPageTemplateList: action.response,
        loadingFetchedInputAcquiredPageTemplateList: false,
        errorFetchedInputAcquiredPageTemplateList: undefined,
      }
    case types.ERROR_FETCH_INPUT_ACQUIRED_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedInputAcquiredPageTemplateList: undefined,
        loadingFetchedInputAcquiredPageTemplateList: false,
        errorFetchedInputAcquiredPageTemplateList: action.error,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.REQUEST_FETCH_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedPageTemplateList: undefined,
        loadingFetchedPageTemplateList: true,
        errorFetchedPageTemplateList: undefined,
      }
    case types.RECEIVE_FETCH_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedPageTemplateList: action.response,
        loadingFetchedPageTemplateList: false,
        errorFetchedPageTemplateList: undefined,
      }
    case types.ERROR_FETCH_PAGE_TEMPLATE_LIST:
      return {
        ...state,
        fetchedPageTemplateList: undefined,
        loadingFetchedPageTemplateList: false,
        errorFetchedPageTemplateList: action.error,
      }
    case types.MODAL_TEMPLATE_ACTIONS:
      return {
        ...state,
        modalTemplateActions: action.isOpen,
        selectedModalTemplate: action.selectedTemplate
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_REPLACE_PAGE_TEMPLATE:
      return {
        ...state,
        errorReplacePageTemplate: action.error,
        loadingReplacePageTemplate: false,
        replacePageTemplate: undefined,
        replacePageTemplateId: action.templatePageId,
      }
    case types.RECEIVE_REPLACE_PAGE_TEMPLATE:
      return {
        ...state,
        errorReplacePageTemplate: undefined,
        loadingReplacePageTemplate: false,
        replacePageTemplate: action.response,
        replacePageTemplateId: action.templatePageId,
      }
    case types.REQUEST_REPLACE_PAGE_TEMPLATE:
      return {
        ...state,
        errorReplacePageTemplate: undefined,
        loadingReplacePageTemplate: true,
        replacePageTemplate: undefined,
        replacePageTemplateId: action.templatePageId,
      }
    case types.CLEAR_REPLACE_PAGE_TEMPLATE:
      return {
        ...state,
        errorReplacePageTemplate: undefined,
        loadingReplacePageTemplate: undefined,
        replacePageTemplate: undefined,
        replacePageTemplateId: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_FETCH_PAGE_TEMPLATE_CATEGORIES:
      return {
        ...state,
        errorFetchedPageTemplateCategories: action.error,
        loadingFetchedPageTemplateCategories: false,
        fetchedPageTemplateCategories: undefined,
      }
    case types.RECEIVE_FETCH_PAGE_TEMPLATE_CATEGORIES:
      return {
        ...state,
        errorFetchedPageTemplateCategories: undefined,
        loadingFetchedPageTemplateCategories: false,
        fetchedPageTemplateCategories: action.response,
      }
    case types.REQUEST_FETCH_PAGE_TEMPLATE_CATEGORIES:
      return {
        ...state,
        errorFetchedPageTemplateCategories: undefined,
        loadingFetchedPageTemplateCategories: true,
        fetchedPageTemplateCategories: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.SET_TEMPLATE_LIST_FILTERS:
      return {
        ...state,
        templateListFilters: action.filters
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    default:
      return state
  }
}

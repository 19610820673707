interface nGlobalStyle {
  uuid: string
  type: typeof GS_SHADOW | typeof GS_BOUNDARY | typeof GS_TEXT | string // TODO: string is only being used to support old code
  name: string
  labels?: { [key: string]: string }
  mobile?: {
    [prop: string]: any
    behaviour?: {
      active?: { [prop: string]: any }
      hover?: { [prop: string]: any }
    }
  }
  tablet?: {
    [prop: string]: any
    behaviour?: {
      active?: { [prop: string]: any }
      hover?: { [prop: string]: any }
    }
  }
  behaviour?: {
    active?: { [prop: string]: any }
    hover?: { [prop: string]: any }
  }
  compatibility?: {
    oldBobs: boolean
    newBobs: boolean
  }
}

interface GlobalStyleViews {
  type: "create" | "edit" | "list" | ""
}
type GlobalStyleView = "create" | "edit" | "list"

export const GS_BOUNDARY = "GSpacing"
export const GS_SHADOW = "GShadow"
export const GS_SCREEN_FLOW = "GExpansion"
export const GS_HEIGHT = "GDimension"
export const GS_BORDER_RADIUS = "GCorner"
export const GS_BORDER = "GBorder"
export const GS_BG_COLORS = "GBackgroundColor"
export const GS_COLUMNS = "GColumns"
export const GS_ALIGNMENT = "GAlignment"
export const GS_FILTER = "GFilter"
export const GS_TEXT = "GText"
export const GS_TEXT_COLOR = "GColor"
export const GS_ICON = "GIcon"

// NGS(new global styles)
export const NGS_CLASS_SUFIX = "_ngs"

export type {
  nGlobalStyle,
  GlobalStyleViews,
  GlobalStyleView
}